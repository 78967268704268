import React, { FC, useCallback, useEffect, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { Text } from '@mtsbank/ui-kit'
import { BankInfo } from '@open-api/ump/transfer-by-phone'
import { RootState } from '@root/reducers'
import { SelectProps } from '@root/types/form/types'
import { CustomOption } from '@components/SBP/CustomOption'
import { AutoCompleteField } from '@components/FormFields/AutoCompleteField'
import { AutoChangeParams, AutocompleteType } from '@components/FormFields/type'
import { selectBankList, selectBankListSuggestions } from '@selectors/sbp'
import { BankSuggestion, FieldsValues } from '@components/SBP/types'
import { fetchTransferByPhoneConstraints } from '@reducers/sbp/sbp'
import { Loading } from '@components/Loading/Loading'
import { validateField } from '@utils/formValidators/formValidators'
import { sendFieldGtm } from '@root/utils/gtm/sbp/events'
import { DEFAULT_BANK, MTS_BANK_ID, PRODUCT_TYPE } from '@components/SBP/constants'
import { EventFieldNames } from '@root/utils/gtm/sbp/types'

const IS_BANK_FOUND = false

export interface RecipientBankFieldProps extends Omit<SelectProps, 'onChange'> {
  onChange: (value: BankSuggestion) => void
  onBlur?: () => void
}

export const RecipientBankField: FC<RecipientBankFieldProps> = ({ onChange, validators, onBlur, ...recipientBank }) => {
  const dispatch = useDispatch()
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<FieldsValues>()

  const bankListSuggestion = useSelector<RootState, BankSuggestion[]>(selectBankListSuggestions)
  const [bankSuggestions, setSuggestions] = useState<BankSuggestion[]>([DEFAULT_BANK])
  const [previousRecipientBank, setPreviousRecipientBank] = useState<string>(null)

  const [isBankFound, setIsBankFound] = useState(IS_BANK_FOUND)
  const [isFetched, setFetched] = useState(false)
  const bankList = useSelector<RootState, BankInfo[]>(selectBankList)
  const isFetchTransferByPhoneConstraints = !errors.recipientBank && touched.recipientBank && !isFetched

  useEffect(() => {
    if (bankList.length > 0 && !errors.recipientPhone) {
      setSuggestions(bankListSuggestion)
    } else {
      setSuggestions([DEFAULT_BANK])
      setFieldValue(recipientBank.name, DEFAULT_BANK)
    }
  }, [bankList.length, bankListSuggestion, errors.recipientPhone, recipientBank.name, setFieldValue])

  useEffect(() => {
    if (isFetchTransferByPhoneConstraints) {
      dispatch(
        fetchTransferByPhoneConstraints({
          productType: PRODUCT_TYPE,
          isInternalTransfer: values.recipientBank.value === MTS_BANK_ID,
          recipientBankId: values.recipientBank.value,
        })
      )

      setFetched(true)
    }
  }, [dispatch, isFetchTransferByPhoneConstraints, values.recipientBank.value])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: AutoChangeParams) => {
      const filteredBanks = bankListSuggestion?.filter((suggestion) =>
        suggestion.label.toLowerCase().includes(value.newValue.toLowerCase())
      )

      const bankForSuggestions = isEmpty(filteredBanks) || errors.recipientPhone ? [DEFAULT_BANK] : filteredBanks

      onChange({ label: value.newValue, value: values.recipientBank.value })

      setSuggestions(bankForSuggestions)

      setIsBankFound(Boolean(filteredBanks.length))
    },
    [bankListSuggestion, errors.recipientPhone, onChange, values.recipientBank.value]
  )

  const renderSuggestion = useCallback(
    (suggestion: BankSuggestion) => {
      if (!isBankFound && !isEmpty(bankList) && !suggestion.value && !errors.recipientPhone) {
        return <Text sizemob="md">Нет результатов</Text>
      }

      return suggestion.value ? <CustomOption value={suggestion.value} label={suggestion.label} /> : <Loading />
    },
    [bankList, errors.recipientPhone, isBankFound]
  )

  const handleClear = useCallback(() => {
    setFieldValue(recipientBank.name, DEFAULT_BANK)
    setSuggestions(bankListSuggestion)
    sendFieldGtm(EventFieldNames.CLEAR_GTM, recipientBank.name)
    setIsBankFound(IS_BANK_FOUND)
  }, [bankListSuggestion, recipientBank.name, setFieldValue])

  const handleValidate = useCallback(
    (bankSuggestion: BankSuggestion) => {
      const error = validateField(bankSuggestion.value, validators)
      const selectedBank = bankSuggestion.value

      if (!error) {
        if (previousRecipientBank !== selectedBank) {
          setPreviousRecipientBank(selectedBank)
          setFetched(false)
        }
      }

      return error
    },
    [previousRecipientBank, validators]
  )

  const inputProps = {
    ...recipientBank,
    onClear: handleClear,
    onBlur,
    value: values[recipientBank.name].label,
  }

  const handleSelect = useCallback(
    (bankSuggestion: BankSuggestion) => {
      if (onChange) {
        onChange(bankSuggestion)
      }

      setFieldValue(recipientBank.name, bankSuggestion)
      setFieldTouched(recipientBank.name, true, false)
    },
    [onChange, recipientBank.name, setFieldTouched, setFieldValue]
  )

  const handleSuggestionValue = useCallback(() => values[recipientBank.name].label || '', [recipientBank.name, values])

  return (
    <Field
      {...recipientBank}
      component={AutoCompleteField}
      autocompleteType={AutocompleteType}
      inputProps={inputProps}
      onChange={handleChange}
      suggestions={values.recipientPhone && !isEmpty(bankList) ? bankSuggestions : [DEFAULT_BANK]}
      shouldRenderSuggestions={() => true}
      renderSuggestion={renderSuggestion}
      getSuggestionValue={handleSuggestionValue}
      onSuggestionSelected={handleSelect}
      validate={handleValidate}
    />
  )
}
