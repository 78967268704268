import { Dispatch, SetStateAction } from 'react'
import { BadgeProps as KitBadgeProps } from '@mtsbank/ui-kit'
import { OpenDeposit } from '@open-api/ump/invest-deposits-deposit-v1'
import { conditionList } from '@root/__mocks__/depositConstructor'
import { config } from '@components/DepositConstructor/config'
import { ProductConditionV2 } from '@open-api/ump/invest-deposits-deposit'

export enum DepositOpeningSteps {
  OPTIONS = 1,
  ACCOUNTS = 2,
  RESULT = 3,
}

export enum DepositCurrency {
  RUB = 'RUR', // Российский рубль
  RUR = 'RUR', // Российский рубль 2
  USD = 'USD', // Доллар США
  EUR = 'EUR', // Евро
  CNY = 'CNY', // Китайский Юань
  AED = 'AED', // Дирхам ОАЭ
}

export enum PayPeriod {
  END_OF_TERM = 'ENDOFTERM', // "В конце срока"
  EVERY_MONTH = 'EVERYMONTH', // "Ежемесячно"
  INSTANTLY = 'INSTANTLY', // 'В начале срока'
}

export enum BadgeType {
  CURRENCY = 'currency',
  PAY_PERIOD = 'payPeriod',
}

export enum PromoCodeErrors {
  PATTERN_ERROR = -2,
  UNKNOWN_ERROR = -1,
  ALREADY_USED = 2,
  EXPIRED = 3,
  UNAVAILABLE_FOR_CURRENT_PARAMETERS = 4,
  CONDITIONS_FAILED = 5,
}

export enum CashTransactions {
  ADD = 'enableAddCash',
  GET = 'enableGetCash',
}

export interface QueryParams {
  step?: number
  prodCode?: string
}

export interface CurrencySelectionProps {
  currencyList: CurrencyItem[]
  defaultCurrency: string
  onClick: () => void
}

interface CurrencyItem {
  code: string
  countryFlagUrl: string
  fullName: string
}

export interface BadgeProps<T> extends Omit<KitBadgeProps, 'onClick'> {
  badgeType: BadgeType
  badgeItems: BadgeItem<T>[]
  activeValue: T
  onClick: (badgeCode: T) => void
}

export interface BadgeItem<T> {
  code: T
}

export interface InitialStatus {
  formSnapshot?: FormValues
  maxRate: boolean
  conditionsSnapshot?: ConditionItem[]
}

type InitialValuesSecondStep = typeof config.initialValues

export interface FormValues
  extends Omit<
      typeof conditionList.data.configurationInfo,
      'isPromocodeAvailable' | 'isGetMaxRateAvailable' | 'currency' | 'payPeriod'
    >,
    InitialValuesSecondStep {
  currency: DepositCurrency
  payPeriod: PayPeriod
  hasPromoCode?: boolean
  promoCode?: string
}

export interface FormStatus {
  status: InitialStatus
  setStatus: (status: InitialStatus) => InitialStatus
}

export interface FilteredConditions {
  resetPromoCode: () => void
  conditions: ConditionItem[]
  setConditions: Dispatch<SetStateAction<ConditionItem[]>>
}

export interface FilterParams {
  currency?: DepositCurrency
  amount?: number
  term?: number
  payPeriod?: PayPeriod
  addCash?: boolean
  getCash?: boolean
}

export interface YieldOptions {
  finalRate: number
  yieldRate: number
  yieldAmount: number
  totalAmount: number
}

export interface AmountRange {
  minAmount: number
  maxAmount: number
}
export type FormFields = typeof config.fields
export type Modals = typeof config.modals
export type ModalNames = keyof typeof config.modals

export interface DepositItemInfo {
  label: string
  value: string | boolean | number
  modalName?: ModalNames
}

export type ConditionItem = ProductConditionV2
export type Segment = ConditionItem['segments'][0]
export type GetAmountRange = (conditions: ConditionItem[]) => AmountRange

export type MaxRateCondition = (conditions: ConditionItem[], amount: number) => ConditionItem
export type CashTransactionState = (
  conditions: ConditionItem[],
  cashTransaction: CashTransactions
) => {
  checked: boolean
  disabled: boolean
}

export type Filter<T> = (condition: ConditionItem, value: T) => boolean
export type FilterConditions<T> = (conditions: ConditionItem[], value: T) => ConditionItem[]

export type CalculateYield = (values: FormValues, condition: ConditionItem) => number

export enum CountryState {
  BIRTH = 'birth',
  RESIDENT = 'resident',
}

export type OpenDepositParams = OpenDeposit

export enum PayType {
  CAPITALIZATION = 'CAPITALIZATION',
  PAYMENT = 'PAYMENT',
}

export enum RefundType {
  ACTIVECARD = 'ACTIVECARD',
  ACTIVEACCOUNT = 'ACTIVEACCOUNT',
}

export enum RadioName {
  CAPITALIZATION = 'capitalization',
  ADD_CASH = 'addCash',
  GET_CASH = 'getCash',
  WITHOUT = 'without',
}
