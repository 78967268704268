/**
 * Функция перезаписывает одинаковые значения
 *
 * В функции происходит приведение первого символа к нижнему регистру, и сравнение с дефолтом
 * для предотвращения мерджа аттрибутов конфигом аксиоса - node_modules/axios/lib/helpers/parseHeaders.js
 *
 * Приоритетом является последнее значение
 *
 * @example:
 * Передано
 * {
 *   platform: web - дефолт
 *   Platform: WEB - из вызова ump метода с глобальным конфигом
 * }
 *
 * На выходе:
 * {
 *   Platform: WEB
 * }
 */
export const parseHeaders = (headers: Record<string, unknown>) =>
  Object.entries(headers).reduce((previousValue, [key, value]) => {
    const firstLetterLowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1)

    if (previousValue[firstLetterLowerCaseKey]) {
      delete previousValue[firstLetterLowerCaseKey]
    }

    return { ...previousValue, [key]: value }
  }, {})
