import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { paymentApi, processingApi } from '@api/dot-net/api'
import { PaymentServiceState } from '@reducers/mobilePayment/paymentServiceInfo/type'
import { ServiceInfo } from '@generated/api'
import { RootState } from '@root/store'
import { selectPaymentServiceByPhone, selectPaymentServiceByServiceId } from '@selectors/mobilePayment'

const NAMESPACE = 'paymentServiceInfo'

export enum ServiceKeyType {
  DEFAULT,
  PHONE,
  SERVICE_ID,
}
export const getServiceKeyByType = (serviceKeyType: ServiceKeyType, key: string) =>
  `${ServiceKeyType[serviceKeyType] || ServiceKeyType[ServiceKeyType.DEFAULT]}_${key}`

const initialState: PaymentServiceState = {
  list: {},
  latest: null,
  selected: null,
  default: {
    phoneNumber: null,
    inProgress: false,
    error: null,
    data: null,
    key: null,
  },
}

export const getPaymentServiceByServiceId = createAsyncThunk(
  `${NAMESPACE}.byId`,
  async (serviceId: string) => {
    const response = await processingApi.apiProcessingGetServiceByIdGet(serviceId)

    return response
  },
  {
    condition: (serviceId: string, { getState }) => {
      const response = selectPaymentServiceByServiceId(serviceId)(getState() as RootState)

      return !response.data && !response.error && !response.inProgress
    },
  }
)

export const getPaymentService = createAsyncThunk(
  `${NAMESPACE}.byPhone`,
  async (phoneNumber: string) => {
    const response = await paymentApi.apiPaymentServiceInfoGet(phoneNumber)

    return response
  },
  {
    condition: (phone: string, { getState }) => {
      const response = selectPaymentServiceByPhone(phone)(getState() as RootState)

      return !response.data && !response.error && !response.inProgress
    },
  }
)

const paymentServiceInfo = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    clearPaymentServiceInfo(state) {
      state.list = {}
    },
    setLatestServiceInfoKey(state, action: PayloadAction<string>) {
      state.latest = action.payload
    },
    setSelectedServiceInfoKey(state, action: PayloadAction<string>) {
      state.selected = action.payload
    },
    setPaymentServiceInfo(state, action: PayloadAction<ServiceInfo>) {
      const key = getServiceKeyByType(ServiceKeyType.SERVICE_ID, action.payload.serviceId)

      state.list[key] = {
        key,
        phoneNumber: null,
        inProgress: false,
        error: null,
        data: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentService.pending, (state, action) => {
      const key = getServiceKeyByType(ServiceKeyType.PHONE, action.meta.arg)

      state.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: true,
        error: null,
        data: null,
      }
    })
    builder.addCase(getPaymentService.rejected, (state, action) => {
      const key = getServiceKeyByType(ServiceKeyType.PHONE, action.meta.arg)

      state.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: false,
        error: action.error,
        data: null,
      }
    })
    builder.addCase(getPaymentService.fulfilled, (state, action) => {
      const key = getServiceKeyByType(ServiceKeyType.PHONE, action.meta.arg)

      state.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: false,
        data: { ...action.payload.data },
        error: null,
      }
    })

    builder.addCase(getPaymentServiceByServiceId.pending, (state, action) => {
      const key = getServiceKeyByType(ServiceKeyType.SERVICE_ID, action.meta.arg)

      state.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: true,
        error: null,
        data: null,
      }
    })
    builder.addCase(getPaymentServiceByServiceId.rejected, (state, action) => {
      const key = getServiceKeyByType(ServiceKeyType.SERVICE_ID, action.meta.arg)

      state.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: false,
        error: action.error,
        data: null,
      }
    })
    builder.addCase(getPaymentServiceByServiceId.fulfilled, (state, action) => {
      const key = getServiceKeyByType(ServiceKeyType.SERVICE_ID, action.meta.arg)

      state.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: false,
        data: { ...action.payload.data },
        error: null,
      }
    })
  },
})

export { paymentServiceInfo }

export const { clearPaymentServiceInfo, setLatestServiceInfoKey, setSelectedServiceInfoKey, setPaymentServiceInfo } =
  paymentServiceInfo.actions
export const paymentServiceReducer = paymentServiceInfo.reducer
