export class TypedStorage {
  public static removeItem(key: string): void {
    try {
      localStorage.removeItem(key)
    } catch (ex) {
      /**/
    }
  }

  public static getObject<T>(key: string): T | null {
    try {
      return JSON.parse(localStorage.getItem(key)) as T
    } catch (ex) {
      return null
    }
  }

  public static getString(key: string): string {
    try {
      return localStorage.getItem(key)
    } catch (ex) {
      return null
    }
  }

  public static setItem<T>(key: string, value: T | string): void {
    try {
      const data: string = typeof value === 'object' ? JSON.stringify(value) : (value as string)

      localStorage.setItem(key, data)
    } catch (ex) {
      /**/
    }
  }
}
