import React, { FC, useCallback, useEffect, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
// todo проверить импорт, тянется вся библиотека или нет
import { DebouncedFunc } from 'lodash'
import { useDidMount } from '@mtsbank/ui-kit/'
import { PhoneInputField } from '@components/FormFields/PhoneInputField'
import { FieldsValues, FormFields } from '@components/SBP/types'
import { validateField } from '@utils/formValidators/formValidators'

import { selectTransferList } from '@selectors/sbp'
import { FormatAliasPhone, formatPhoneNumber } from '@utils/formatPhoneNumber'
import { getRecommendedBank } from '@components/SBP/utils'
import { DEFAULT_BANK } from '@components/SBP/constants'
import { sendFieldGtm } from '@root/utils/gtm/sbp/events'
import { EventFieldNames } from '@root/utils/gtm/sbp/types'

interface RecipientPhoneFieldProps {
  formFields: FormFields
  onChange: (value: string) => void
  onBlur?: () => void
  debouncedFetchTransferList: DebouncedFunc<() => void>
}

export const RecipientPhoneField: FC<RecipientPhoneFieldProps> = ({
  formFields,
  onChange,
  debouncedFetchTransferList,
  onBlur,
}) => {
  const {
    values,
    dirty,
    errors: formikErrors,
    touched,
    setValues,
    setFieldTouched,
    initialValues,
  } = useFormikContext<FieldsValues>()
  const transferList = useSelector(selectTransferList)
  const [isFetched, setFetched] = useState(false)
  const defaultPhoneNum = formatPhoneNumber(initialValues.recipientPhone, FormatAliasPhone.DIGIT11)
  const [previousRecipientPhone, setPreviousRecipientPhone] = useState(defaultPhoneNum || '')

  const isFetchTransferList =
    !formikErrors.recipientPhone &&
    touched.recipientPhone &&
    !isFetched &&
    dirty &&
    formatPhoneNumber(values.recipientPhone)

  useDidMount(() => {
    if (defaultPhoneNum) {
      setFetched(true)
      setFieldTouched(formFields.recipientPhone.name, true)
    }
  })

  useEffect(() => {
    if (isFetchTransferList) {
      debouncedFetchTransferList()

      setFetched(true)
    }
  }, [debouncedFetchTransferList, isFetchTransferList])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const recipientPhone = event.target.value
      const formattedRecipientPhone = formatPhoneNumber(recipientPhone, FormatAliasPhone.DIGIT11)

      onChange(recipientPhone)

      if (previousRecipientPhone === formattedRecipientPhone) {
        const recommendedBank = getRecommendedBank(transferList)

        setValues((previousValues) => ({
          ...previousValues,
          [formFields.recipientBank.name]: recommendedBank,
          [formFields.recipientPhone.name]: recipientPhone,
        }))
      }

      if (isFetched && previousRecipientPhone !== formattedRecipientPhone) {
        debouncedFetchTransferList.cancel()
      }
    },
    [
      transferList,
      debouncedFetchTransferList,
      formFields.recipientBank.name,
      formFields.recipientPhone.name,
      isFetched,
      onChange,
      previousRecipientPhone,
      setValues,
    ]
  )

  const handleClear = useCallback(() => {
    setValues({
      ...values,
      [formFields.recipientPhone.name]: '',
      [formFields.recipientBank.name]: DEFAULT_BANK,
    })
    sendFieldGtm(EventFieldNames.CLEAR_GTM, formFields.recipientPhone.name)
  }, [formFields.recipientBank.name, formFields.recipientPhone.name, setValues, values])

  const handleValidate = useCallback(
    (value: string) => {
      const recipientPhone = formatPhoneNumber(value, FormatAliasPhone.DIGIT11)
      const error = validateField(value, formFields.recipientPhone.validators) || !recipientPhone

      if (!error) {
        if (previousRecipientPhone !== recipientPhone) {
          setPreviousRecipientPhone(recipientPhone)
          setFetched(false)
        }
      }

      return error
    },
    [formFields.recipientPhone.validators, previousRecipientPhone]
  )

  return (
    <Field
      {...formFields.recipientPhone}
      component={PhoneInputField}
      onChange={handleChange}
      validate={handleValidate}
      onClear={handleClear}
      onBlur={onBlur}
    />
  )
}
