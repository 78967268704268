import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ActivationServiceRequestV2,
  ActivationServiceResponse,
  DocumentDto,
  DocumentsRequest,
  ReasonDto,
} from '@open-api/ump/credit-holidays'
import { creditHolidaysApi } from '@api/ump/credit-holidays'
import {
  CheckAvailabilityRequestWithMultipleCodes,
  CreditHolidaysError,
  CreditHolidaysPage,
  CreditHolidaysProgramName,
  CreditHolidaysProgramStatus,
  CreditHolidaysReducer,
  LoansState,
  ProgramsState,
  ReasonsState,
  ProgramsResponseState,
  InfoForPrograms,
  CheckAvailabilityResponseWithSvcCode,
  CreditHolidaysReasons,
} from '@reducers/creditHolidays/types'
import { dateWithoutTime } from '@root/utils/date'
import { MILLISECONDS_PER_DAY } from '@root/constants'
import { formatDate } from '@root/utils/formatDate/formatDate'
import { eventLabels } from '@utils/gtm/baseFunctions'
import { screenNamePrefixes } from '@root/hooks/useDataLayer/constants'
import { ECreditHolidaysStepName } from '@components/CreditHolidays/types'

export const MODAL_REASONS_ID = 'reasonsModal'
const NAMESPACE = 'creditHolidays'

const KK353SvcCodes = [CreditHolidaysProgramName.EMERGENCY_FZ_353, CreditHolidaysProgramName.REDUCED_INCOME]

const UNEXPECTED_ERROR: CreditHolidaysError = {
  heading: `Что-то с\u00a0сервером`,
  text: 'Но\u00a0мы\u00a0уже всё чиним.\nПопробуйте ещё раз чуть позже',
  eventLabel: eventLabels.serverError,
  screenName: screenNamePrefixes.CREDIT_HOLIDAYS + ECreditHolidaysStepName.ERROR,
}
const NO_AVAILABLE_PROGRAMS_ERROR: CreditHolidaysError = {
  heading: 'Отсрочка платежа и Льготный период недоступны',
  text: '',
  disclaimerText: 'Если вы являетесь участником специальной военной операции, свяжитесь с нами в чате приложения',
}
const ACTIVATION_ERROR: CreditHolidaysError = {
  heading: 'Не\u00a0удалось отправить заявку',
  text: 'Что-то с\u00a0интернетом или нашим сервером.\nПопробуйте ещё раз',
  eventLabel: eventLabels.formSendingFail,
  screenName: screenNamePrefixes.CREDIT_HOLIDAYS + ECreditHolidaysStepName.ERROR,
}

export const ACTIVATION_ERROR_REASONS: CreditHolidaysError = {
  heading: 'Не\u00a0сможем подключить каникулы️ \uD83D\uDE41',
  text: `{link href="javascript:void(0);" id="${MODAL_REASONS_ID}"}Возможные причины{/link}`,
  eventLabel: eventLabels.creditHolidaysActivationFail,
  screenName: screenNamePrefixes.CREDIT_HOLIDAYS + ECreditHolidaysStepName.REJECT,
}

const PROGRAMS = {
  [CreditHolidaysProgramName.DEFERMENT_PAYMENT]: {
    title: 'Отсрочка платежа',
    description: 'Программа банка: без платежей по\u00a0кредиту на\u00a0период отсрочки',
  },
  [CreditHolidaysProgramName.GRACE_PERIOD]: {
    title: 'Льготный период для участников СВО по\u00a0закону \u2116377-ФЗ',
    description: 'Можно не\u00a0платить по\u00a0кредиту на\u00a0протяжении всего участия в\u00a0СВО',
  },
  [CreditHolidaysProgramName.GRACE_PERIOD_FZ_353]: {
    title: 'Льготный период по\u00a0закону \u2116\u00a0353-\u2060ФЗ',
    description:
      'Можно не\u00a0платить по\u00a0кредиту от\u00a01\u00a0до\u00a06 месяцев,\u00a0если\u00a0за\u00a0предыдущие 2\u00a0месяца доход снизился более чем на\u00a030% по\u00a0сравнению со\u00a0среднемесячным доходом за\u00a0последние 12\u00a0месяцев или вы\u00a0находитесь в\u00a0зоне\u00a0ЧС',
  },
}

const initialState: CreditHolidaysReducer = {
  step: CreditHolidaysPage.SELECT_CREDIT,
  inProgress: true,
  error: null,
  programs: {
    data: null,
    inProgress: true,
  },
  documents: {
    data: null,
    inProgress: true,
  },
  originalError: null,
  loans: {
    data: null,
    inProgress: true,
  },
  availability: {
    data: {
      activationId: null,
      requestNum: null,
    },
    inProgress: true,
  },
  availabilitySVO: {
    data: null,
    inProgress: true,
  },
  reasons: {
    data: null,
    inProgress: false,
  },
  activation: {
    data: null,
    inProgress: false,
  },
  isOpenModal: false,
}

const fetchPrograms = async (
  loanId: string,
  delay: string,
  svcCodeOne: string,
  svcCodeTwo: string,
  svcCodeThree: string
): Promise<ProgramsResponseState['data']> => {
  const currentTimestamp = dateWithoutTime(new Date())
  const {
    data: { info, texts },
  } = await creditHolidaysApi.getInfoForPrograms(loanId, delay, svcCodeOne, svcCodeTwo, svcCodeThree)

  return {
    programs: info?.reduce((carry, program) => {
      const { svcCode, term } = program
      const termMS = (term || 0) * MILLISECONDS_PER_DAY
      const programTexts = texts?.find(({ svcCode: code }) => svcCode === code)
      const statusText =
        typeof programTexts !== 'undefined'
          ? programTexts?.text.replace('ДД.ММ.ГГГГ', formatDate(programTexts.date))
          : ''

      return [
        ...carry,
        {
          ...program,
          ...PROGRAMS[svcCode],
          activationDate: '',
          statusText,
          activationDateSVO: '',
          startInSVO: '',
          startDate: currentTimestamp - termMS,
          term: termMS,
          terms: [
            {
              term: 3,
              checked: true,
            },
            {
              term: 6,
            },
          ],
        },
      ]
    }, []),
  }
}

const fetchCredits = createAsyncThunk(`${NAMESPACE}/fetchCredits`, async (): Promise<LoansState['data']> => {
  const {
    data: { loan },
  } = await creditHolidaysApi.getInfo()

  return loan
})

const fetchInfoForPrograms = createAsyncThunk(
  `${NAMESPACE}/fetchInfoForPrograms`,
  async (params: InfoForPrograms): Promise<ProgramsResponseState['data']> => {
    const { delay, loanId, ...svcCodes } = params
    const { svcCodeOne, svcCodeTwo, svcCodeThree } = svcCodes

    const fetchProgramsData = await fetchPrograms(loanId, String(delay), svcCodeOne, svcCodeTwo, svcCodeThree)

    return fetchProgramsData
  }
)

const fetchAvailability = createAsyncThunk(
  `${NAMESPACE}/fetchAvailability`,
  async (
    params: CheckAvailabilityRequestWithMultipleCodes,
    { dispatch }
  ): Promise<CheckAvailabilityResponseWithSvcCode> => {
    const { svcCode, ...options } = params
    const isKK353SvcCode = KK353SvcCodes.includes(svcCode as CreditHolidaysProgramName)

    const availabilityData = await creditHolidaysApi.checkAvailability({ svcCode, ...options })
    const statusAvailability = availabilityData.data.status

    if (statusAvailability === CreditHolidaysProgramStatus.FAIL) {
      dispatch(setReasons({ reasonsData: availabilityData.data.reasonsForRefusal?.map(({ reason }) => reason) }))
      if (isKK353SvcCode) {
        dispatch(setError({ error: ACTIVATION_ERROR_REASONS }))
        dispatch(setStep(CreditHolidaysPage.ERROR))
      } else {
        dispatch(setModal(true))
      }
    }

    return {
      data: availabilityData.data,
      svcCode,
    }
  }
)

const fetchReasons = createAsyncThunk(
  `${NAMESPACE}/fetchReasons`,
  async (svcCode: string, { dispatch }): Promise<ReasonDto[]> => {
    const {
      data: { reasons },
    } = await creditHolidaysApi.getReasons(svcCode)

    dispatch(setModal(false))

    return reasons
  }
)

const fetchServiceActivation = createAsyncThunk(
  `${NAMESPACE}/fetchServiceActivation`,
  async (params: ActivationServiceRequestV2, { dispatch }): Promise<ActivationServiceResponse> => {
    const { data } = await creditHolidaysApi.activationService(params)

    const statusActivation = data.status

    if (statusActivation === CreditHolidaysProgramStatus.FAIL) {
      dispatch(setReasons({ reasonsData: data.reasonsForRefusal.map(({ reason }) => reason) }))
    }

    return data
  }
)

const postDocuments = createAsyncThunk(
  `${NAMESPACE}/postDocuments`,
  async (params: DocumentsRequest): Promise<ActivationServiceResponse['status']> => {
    const {
      data: { status },
    } = await creditHolidaysApi.documents(params)

    return status
  }
)

const createCheckedReducer: (
  getContext: <T>(
    state: CreditHolidaysReducer,
    action: PayloadAction<T>
  ) => LoansState['data'] | ProgramsState['data'] | ReasonsState['data'],
  field: string
) => <T>(state: CreditHolidaysReducer, action: PayloadAction<T | string>) => void =
  (getContext, field) => (state, action) => {
    const context = getContext(state, action)

    context?.forEach((option: { checked?: boolean }) => {
      option.checked = option[field] === action.payload
    })
  }

const creditHolidays = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    checkCredit: createCheckedReducer((state) => state.loans.data, 'agreementNum'),
    checkReason: createCheckedReducer((state) => state.reasons.data, 'reasonCode'),
    checkProgram: createCheckedReducer((state) => state.programs.data, 'svcCode'),
    checkTerm: (state, action: PayloadAction<{ svcCode: string; term: number }>) => {
      const { svcCode, term } = action.payload

      const program = state.programs.data.find(({ svcCode: code }) => code === svcCode)

      program.terms.forEach((program) => {
        const { term: programTerm } = program

        program.checked = programTerm === term
      })
    },
    setDocument: (state, action: PayloadAction<DocumentDto[]>) => {
      const document = action.payload

      const program = state.programs.data.find(({ svcCode: code }) => code === 'KK.353')

      program.attachedDocument = document
    },
    setActivationDate: (state, action: PayloadAction<{ svcCode: string; date: number }>) => {
      const { svcCode, date } = action.payload
      const program = state.programs.data.find(({ svcCode: code }) => code === svcCode)

      program.activationDate = date
    },
    setStartDateSVO: (state, action: PayloadAction<{ svcCode: string; date: number }>) => {
      const { svcCode, date } = action.payload
      const program = state.programs.data.find(({ svcCode: code }) => code === svcCode)

      program.startInSVO = date
    },
    setTerm: (
      state,
      action: PayloadAction<{ svcCode: string; term: number; emergencyTerm?: number; reasonCode?: string }>
    ) => {
      const { svcCode, term, emergencyTerm, reasonCode } = action.payload
      const program = state.programs.data.find(({ svcCode: code }) => code === svcCode)
      const FZ353SvcCode =
        reasonCode === CreditHolidaysReasons.REDUCED_INCOME
          ? CreditHolidaysProgramName.REDUCED_INCOME
          : CreditHolidaysProgramName.EMERGENCY_FZ_353

      if (svcCode === CreditHolidaysProgramName.GRACE_PERIOD) {
        program.term = term

        return
      }

      if (FZ353SvcCode === CreditHolidaysProgramName.EMERGENCY_FZ_353) {
        program.emergencyTerm = emergencyTerm
      } else {
        program.reducedIncomeTerm = term
      }
    },
    setSvoStatus: (state, action: PayloadAction<{ svcCode: CreditHolidaysProgramName; status?: string }>) => {
      const { svcCode, status } = action.payload
      const program = state.programs.data.find(({ svcCode: code }) => code === svcCode)

      program.status = status
    },
    setError: (state, action: PayloadAction<{ error: CreditHolidaysError }>) => {
      const { error } = action.payload

      state.error = error
    },
    setReasons: (state, action: PayloadAction<{ reasonsData: string[] }>) => {
      const { reasonsData } = action.payload

      state.availability.data.reasons = reasonsData
    },
    setMilitaryDoc: (state, action: PayloadAction<{ svcCode: string; document: string }>) => {
      const { svcCode, document } = action.payload

      const program = state.programs.data.find(({ svcCode: code }) => code === svcCode)

      program.militaryDoc = document
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
    setModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postDocuments.pending, (state) => {
      state.documents.inProgress = true
      state.inProgress = true
      state.error = null
    })
    builder.addCase(postDocuments.rejected, (state, action) => {
      state.error = ACTIVATION_ERROR
      state.originalError = action.error
      state.documents.inProgress = false
      state.inProgress = false
      state.step = CreditHolidaysPage.ERROR
    })
    builder.addCase(postDocuments.fulfilled, (state, action) => {
      state.documents.data = action.payload
      state.documents.inProgress = false
      state.inProgress = false
      state.step = CreditHolidaysPage.SUCCESS
    })
    builder.addCase(fetchInfoForPrograms.pending, (state) => {
      state.programs.inProgress = true
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchInfoForPrograms.rejected, (state, action) => {
      state.error = NO_AVAILABLE_PROGRAMS_ERROR
      state.originalError = action.error
      state.programs.inProgress = false
      state.inProgress = false
      state.step = CreditHolidaysPage.ERROR
    })
    builder.addCase(fetchInfoForPrograms.fulfilled, (state, action) => {
      state.programs.data = action.payload.programs
      state.programs.inProgress = false
      state.inProgress = false
      state.step = CreditHolidaysPage.SELECT_PROGRAM
    })
    builder.addCase(fetchCredits.pending, (state) => {
      state.loans.inProgress = true
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchCredits.rejected, (state, action) => {
      state.error = UNEXPECTED_ERROR
      state.originalError = action.error
      state.loans.inProgress = false
      state.inProgress = false
      state.step = CreditHolidaysPage.ERROR
    })
    builder.addCase(fetchCredits.fulfilled, (state, action) => {
      state.loans.data = action.payload
      state.loans.inProgress = false
      state.inProgress = false
      state.step = CreditHolidaysPage.SELECT_CREDIT
    })
    builder.addCase(fetchReasons.pending, (state) => {
      state.reasons.inProgress = true
      state.error = null
    })
    builder.addCase(fetchReasons.rejected, (state, action) => {
      state.error = UNEXPECTED_ERROR
      state.originalError = action.error
      state.reasons.inProgress = false
      state.step = CreditHolidaysPage.ERROR
    })
    builder.addCase(fetchReasons.fulfilled, (state, action) => {
      state.reasons.data = action.payload
      state.reasons.inProgress = false
    })
    builder.addCase(fetchAvailability.pending, (state) => {
      state.availability.inProgress = true
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchAvailability.rejected, (state, action) => {
      state.error = UNEXPECTED_ERROR
      state.originalError = action.error
      state.availability.inProgress = false
      state.inProgress = false
      state.step = CreditHolidaysPage.ERROR
    })
    builder.addCase(fetchAvailability.fulfilled, (state, action) => {
      const { svcCode } = action.payload

      const convertedSvcCode = svcCode.replace(/(?:_ES|_RI)/g, '')

      state.programs.data.find(({ svcCode: svcCodeFromProgram }) => svcCodeFromProgram === convertedSvcCode).status =
        action.payload.data.status
      state.inProgress = false
      state.availability.data.activationId = action.payload.data.activationId
      state.availability.data.requestNum = action.payload.data.requestNum
      state.availability.inProgress = false
    })
    builder.addCase(fetchServiceActivation.pending, (state) => {
      state.inProgress = true
      state.activation.inProgress = true
      state.error = null
    })
    builder.addCase(fetchServiceActivation.rejected, (state, action) => {
      state.error = ACTIVATION_ERROR
      state.originalError = action.error
      state.activation.inProgress = false
      state.inProgress = false
      state.step = CreditHolidaysPage.ERROR
    })
    builder.addCase(fetchServiceActivation.fulfilled, (state, action) => {
      const { reasonsForRefusal } = action.payload

      state.activation.data = action.payload
      state.activation.inProgress = false
      state.inProgress = false

      if (reasonsForRefusal) {
        state.error = ACTIVATION_ERROR_REASONS
        state.step = CreditHolidaysPage.ERROR
      } else {
        state.step = CreditHolidaysPage.SUCCESS
      }
    })
  },
})

export const {
  checkProgram,
  checkReason,
  checkCredit,
  setStep,
  setTerm,
  setError,
  setReasons,
  setModal,
  setMilitaryDoc,
  setStartDateSVO,
  setActivationDate,
  setDocument,
} = creditHolidays.actions
export { fetchCredits, fetchInfoForPrograms, fetchReasons, fetchServiceActivation, fetchAvailability, postDocuments }
export default creditHolidays.reducer
