import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { regionApi } from '@api/dot-net/api'
import { RegionsState } from '@reducers/regions/types'
import { Region } from '@generated/api'
import { selectRegions } from '@selectors/regions'
import { RootState } from '@root/store'

const initialState: RegionsState = {
  regions: [],
  isLoaded: false,
  selectedRegion: undefined,
  error: null,
}
// todo в оригинале в .apiRegionGet().then не было обработчика ошибок и в стейте поля error
// поэтому нужно добавить обработчик либо убрать в стейте .error и обработчики .rejected & .pending

const fetchRegions = createAsyncThunk<Region[]>(
  'regions/fetchRegions',
  async (): Promise<Region[]> => {
    const response = await regionApi.apiRegionGet()

    return response.data
  },
  {
    condition(arg, { getState }): boolean | undefined {
      const response = selectRegions(getState() as RootState)

      return !response.regions.length && !response.error && !response.isLoaded
    },
  }
)

const regions = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    selectRegion(state, action: PayloadAction<Region>) {
      return {
        ...state,
        selectedRegion: { ...action.payload },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegions.pending, (state) => {
      state.isLoaded = true
      state.error = null
    })
    builder.addCase(fetchRegions.rejected, (state, action) => {
      state.error = action.error
      state.isLoaded = false
    })
    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      state.regions = action.payload || []
      state.isLoaded = false
    })
  },
})

export { fetchRegions }
export const { selectRegion } = regions.actions

export default regions.reducer
