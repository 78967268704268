import { addMatchingCardsToResults } from '@utils/creditCardType/lib/addMatchingCardsToResults'
import { clone } from '@utils/creditCardType/lib/clone'
import { cardTypes } from '@utils/creditCardType/cardTypes'
import { findBestMatch } from '@utils/creditCardType/lib/findBestMatch'
import { isValidInputType } from '@utils/creditCardType/lib/isValidInputType'
import { CardCollection, CardType, CreditCardType } from '../type'

const customCards = {} as CardCollection

function findType(cardType: string | number): CreditCardType {
  return customCards[cardType] || cardTypes[cardType]
}

const cardNames: Record<string, CardType> = {
  VISA: CardType.VISA,
  MASTERCARD: CardType.MASTERCARD,
  UNIONPAY: CardType.UNIONPAY,
  MAESTRO: CardType.MAESTRO,
  MIR: CardType.MIR,
}

const ORIGINAL_TEST_ORDER = [cardNames.VISA, cardNames.MASTERCARD, cardNames.UNIONPAY, cardNames.MAESTRO, cardNames.MIR]

const testOrder = clone(ORIGINAL_TEST_ORDER) as string[]

function getAllCardTypes(): CreditCardType[] {
  return testOrder.map((cardType) => clone(findType(cardType)))
}

export function creditCardType(cardNumber: string): Array<CreditCardType> {
  const results = [] as CreditCardType[]

  if (!isValidInputType(cardNumber)) {
    return results
  }

  if (cardNumber.length === 0) {
    return getAllCardTypes()
  }

  testOrder.forEach((cardType) => {
    const cardConfiguration = findType(cardType)

    addMatchingCardsToResults(cardNumber, cardConfiguration, results)
  })

  const bestMatch = findBestMatch(results)

  if (bestMatch) {
    return [bestMatch]
  }

  return results
}
