import React, { FC } from 'react';
import { Icon, InlineFlex, theme, Text } from '@mtsbank/ui-kit';
import { SmartText } from '@mtsbank/common';
import { CardWrapper, StyledNotification, NotificationContent } from './styled';
type CardProps = {
  name: string;
  link: string;
  status: string;
  statusCode: string;
};
export const Card: FC<CardProps> = ({
  name,
  link,
  status,
  statusCode
}) => {
  const isApprovedCard = statusCode !== 'IN_PROGRESS';
  const handleRelocate = () => {
    window.location.href = link;
  };
  return <CardWrapper onClick={!isApprovedCard ? handleRelocate : () => {}}>
      <SmartText fontSizeMob={theme.fonts.font_sizes.par.md} lineHeightMob={theme.fonts.line_heights.par.md} color={theme.colors.neutral.g300} marginBottomMob={theme.spacings.xs3}>
        Заявка на кредитную карту
      </SmartText>
      <InlineFlex fillWidth justify="space-between">
        <SmartText fontWeightMob={theme.fonts.font_weights.medium} fontSizeMob={theme.fonts.font_sizes.par.lg} lineHeightMob={theme.fonts.line_heights.par.lg}>
          {status}
        </SmartText>
        <Icon icon="payment/card" />
      </InlineFlex>
      {isApprovedCard && <div onClick={handleRelocate}>
          <StyledNotification icon={{
        icon: 'icon-24/SuperInfo',
        color: theme.colors.blue.primary,
        padding: theme.spacings.xs5
      }}>
            <NotificationContent>
              <Text size="md">Перейти к подписанию</Text>
              <Icon icon="baseX32/ic-arrow-right" height={28} width={28} color={theme.colors.neutral.g400} />
            </NotificationContent>
          </StyledNotification>
        </div>}
    </CardWrapper>;
};