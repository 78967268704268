import { Dictionary } from '@reduxjs/toolkit'

export type ConfigParamsByEventAndField = Record<EventFieldNames, Dictionary<Dictionary<string>>>

export enum EventFieldNames {
  SHOW_ERROR_GTM,
  SUCCESS_FILLED_GTM,
  SELECT_GTM,
  CLEAR_GTM,
}

export enum FormFieldNames {
  PAYMENT_SOURCE = 'paymentSource',
  AMOUNT = 'amount',
  USER_MESSAGE = 'userMessage',
  RECIPIENT_BANK = 'recipientBank',
  RECIPIENT_PHONE = 'recipientPhone',
}
