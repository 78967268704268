import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { OtpRequestV2, OtpResponse } from '@open-api/ump/otp-sender'
import { otpSenderApi } from '@api/ump/otp-sender'
import { OtpSenderState } from '@reducers/otpSender/type'
import { isAxiosError } from '@root/types/reducers'
import { isOtpResponse } from '@components/OtpSender/types'

const NAMESPACE = 'otpSender'

const initialState: OtpSenderState = {
  inProgress: false,
  isCodeResend: false,
  enabled: false,
  codeChecked: false,
  error: null,
}

const otpSend = createAsyncThunk<OtpResponse, OtpRequestV2>(
  `${NAMESPACE}/otpSend`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await otpSenderApi.sendSMSCodeV2(params)

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const otpCheckCode = createAsyncThunk<OtpResponse, OtpRequestV2>(
  `${NAMESPACE}/otpCheckCode`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await otpSenderApi.checkSMSCodeV2(params)

      return response.data
    } catch (error) {
      if (isAxiosError<OtpResponse>(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const otpSender = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetOtpSender(state) {
      state.inProgress = false
      state.isCodeResend = false
      state.enabled = false
      state.codeChecked = false
      state.error = null
    },
    setCodeChecked(state) {
      // todo удалить, когда будет готова аналитика https://jira.mtsbank.ru/browse/SITE-2890
      state.codeChecked = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(otpSend.pending, (state) => {
      state.inProgress = true
      state.enabled = true
      state.error = null
      state.codeChecked = false
    })
    builder.addCase(otpSend.rejected, (state, action) => {
      state.inProgress = false
      state.error = (isOtpResponse<OtpResponse>(action.payload) && action.payload?.meta) || action.error
    })
    builder.addCase(otpSend.fulfilled, (state) => {
      state.inProgress = false
    })
    builder.addCase(otpCheckCode.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(otpCheckCode.rejected, (state, action) => {
      state.inProgress = false
      state.error = (isOtpResponse<OtpResponse>(action.payload) && action.payload?.meta) || action.error
    })
    builder.addCase(otpCheckCode.fulfilled, (state) => {
      state.inProgress = false
      state.codeChecked = true
    })
  },
})

export const { resetOtpSender, setCodeChecked } = otpSender.actions
export { otpSend, otpCheckCode }
export const otpSenderReducer = otpSender.reducer
