export type Format =
  | 'DD.MM.YYYY'
  | 'DD Month YYYY'
  | 'Month YYYY'
  | 'MM/YY'
  | 'DD Month'
  | 'DD/MM/YYYY HH:MM:SS'
  | 'YYYYMM'
  | 'DD Month YYYY H:M'
  | 'YYYY-MM-DD hh:mm:ss'
  | 'DD.MM.YYYY HH:MM'
  | 'DD.MM'
  | 'YYYY-MM-DD'

export const enum LocaleNames {
  DEFAULT,
  GENITIVE,
}

export type LocaleConfig =
  | string
  | {
      format: string
      locale?: LocaleNames
    }

export type DateFormatOptionConfig = { [key in Format]: LocaleConfig }
