import { actionGroups, eventActions, eventLabels, events, sendGmtEventPerPage } from '../baseFunctions'

/** Клик по элементу На главную на экране "Для перевода нужна карта МТС Банка" */
export const gotToMainClickGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.naGlavnuu,
    actionGroup: actionGroups.interactions,
  })

/** Клик по кнопке Оформить карту на экране "Для перевода нужна карта МТС Банка" */
export const getBankCardClickGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.oformitKartu,
    actionGroup: actionGroups.interactions,
  })
