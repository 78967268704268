export enum Dependence {
  LOANS = 'LOANS',
}

export interface NavigationMenu {
  url: string
  text: string
  display?: PaymentZone
  target?: string
  dependence?: Dependence
  gtmEvent?: () => void
}

export enum PaymentZone {
  AUTHORIZED = 'authorized',
  UNAUTHORIZED = 'unauthorized',
}
