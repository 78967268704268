import { ButtonProps as ButtonPropsKit } from '@mtsbank/ui-kit/build/components/atoms/Button/Button'
import { ButtonsConfig, Errors, Titles } from '@components/Modals/AntifrodError/type'
import { OFFICES_AND_ATMS } from '@root/constants'
import { departmentOnMapClickGtm, goToMainClickGtm, transferByCardClickGtm } from '@utils/gtm/modal/events'
import { ModalTypes } from '@root/utils/gtm/modal/types'
import { paymentHost } from '@api/dot-net/api'

const basicButtonProps: ButtonPropsKit = { as: 'a', size: 'lg', fluidmob: true, className: 'textAlignCenter' }

export const buttonsConfig: ButtonsConfig = {
  branchesMap: {
    ...basicButtonProps,
    text: 'Отделения на карте',
    href: OFFICES_AND_ATMS,
    onClick: () => departmentOnMapClickGtm(ModalTypes.ERROR),
  },
  mainPage: {
    ...basicButtonProps,
    text: 'На главную',
    href: '/',
    onClick: () => goToMainClickGtm(ModalTypes.ERROR),
  },
  transferToCard: {
    ...basicButtonProps,
    text: 'Перевести по карте',
    href: `${paymentHost}/transfer/CardToCard`,
    onClick: () => transferByCardClickGtm(),
  },
}

export const errors: Errors = {
  1109: { title: Titles.BLOCKED_ACCOUNT, buttons: [buttonsConfig.branchesMap, buttonsConfig.mainPage] },
  3012: { title: Titles.SBERBANK_BANNED, buttons: [buttonsConfig.mainPage] },
  1110: { title: Titles.TEMPORARILY_UNAVAILABLE, buttons: [buttonsConfig.mainPage] },
  3011: { title: Titles.TEMPORARILY_UNAVAILABLE, buttons: [buttonsConfig.transferToCard] },
  3010: { title: Titles.TEMPORARILY_UNAVAILABLE, buttons: [buttonsConfig.mainPage] },
}
