import keys from 'lodash/fp/keys'
import includes from 'lodash/includes'
import isNil from 'lodash/isNil'
import { formatMoney } from '@mtsbank/ui-kit'
import {
  AmountParams,
  BalanceValidation,
  CommonValidation,
  DepositAccountsParams,
  Params,
  ValidateAmountPayPhone,
  ValidateMapping,
  ValidationType,
  Validators,
} from '@utils/formValidators/types'

import { PaymentMethods } from '@root/components/PaymentMethodSelector/paymentDetector/AvailablePaymentMethodDetector'
import getCardType from '@utils/creditCardType'
import { CardType } from '@utils/creditCardType/type'
import { toNumber } from '@utils/format'
import { messages } from './messages'
import { validationRules } from './rules'

export const isEmpty = (value: string): ValidationType => {
  const isValid = typeof value === 'string' ? Boolean(value.trim()) : Boolean(value)

  return !isValid ? messages.required : undefined
}

const validateLength: CommonValidation = (value, params) => {
  const { min, max } = params

  const isMinValid = min && value ? value.trim().length >= min : true
  const isMaxValid = max && value ? value.trim().length <= max : true

  const minErrorMessage = !isMinValid ? messages.min(min) : undefined
  const maxErrorMessage = !isMaxValid ? messages.max(max) : undefined
  const errorMessage = minErrorMessage && maxErrorMessage ? messages.minMax(min, max) : undefined

  return errorMessage || minErrorMessage || maxErrorMessage
}

const validateBalance: BalanceValidation = (value, params) => {
  if (value === null) {
    return 'Не загрузился баланс'
  }

  const { min, max } = params

  const isMinValid = min ? value >= min : true
  const isMaxValid = max ? value <= max : true

  const minErrorMessage = !isMinValid ? messages.balance.min : undefined
  const maxErrorMessage = !isMaxValid ? messages.balance.max(max) : undefined

  const errorMessage = minErrorMessage && maxErrorMessage ? messages.balance.minMax(min, max) : undefined

  return errorMessage || minErrorMessage || maxErrorMessage
}

export const validateAmountValues = (value: string | number, minMaxAmount: AmountParams) => {
  const { min, max, balance, withFee } = minMaxAmount

  const amount = typeof value === 'string' ? toNumber(value) : value

  if (amount > 0 && amount < min) {
    return messages.repaymentAmount.min(min)
  }

  if (amount > max) {
    return messages.repaymentAmount.max(max)
  }

  if (amount > balance) {
    return withFee ? messages.repaymentAmount.balanceWithFee : messages.repaymentAmount.balance
  }
}

export function cardTypeValidation(value: string) {
  if (/[^0-9-\s]+/.test(value)) {
    return false
  }
  const type = getCardType(value)

  return Boolean(type) && type !== CardType.UNIONPAY
}

export function lushValidation(value: string) {
  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) {
    return false
  }

  let nCheck = 0
  let bEven = false

  value = value.replace(/\D/g, '')

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n)
    let nDigit = parseInt(cDigit, 10)

    // eslint-disable-next-line no-cond-assign
    if (bEven && (nDigit *= 2) > 9) nDigit -= 9

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}

const validatePhone = (phoneNum: string) => {
  if (phoneNum && validationRules.minPhoneNumber > phoneNum.length) {
    return messages.required
  }
}

const validateOrderNumber = (orderNumber) => {
  if (validationRules.minOrderNumber > orderNumber.length) {
    return messages.required
  }
}

const cvcValidate = (cvc) => {
  if (validationRules.minCVC > cvc.length) {
    return messages.required
  }
}

const paymentMethodSelectorValidate = (method) => {
  if (!includes(PaymentMethods, method)) {
    return messages.required
  }
}

const validateAmountPayPhone: ValidateAmountPayPhone = ({ amount, min, max, currency = 'RUB' }) => {
  let clearValue = Number(amount)

  if (typeof amount === 'string') {
    clearValue = Number(amount.replace(',', '.').replace(/[^.0-9]/gim, ''))
  }

  if (clearValue < min) {
    return messages.paymentAmount.min(formatMoney(min, currency))
  }

  if (clearValue > max) {
    return messages.paymentAmount.max(formatMoney(max, currency))
  }
}

export const validateEmail = (value: string) => {
  if (!value || !/\S+@\S+\.[a-zA-Z]{2,}/.test(value) || value.length >= 100) {
    // todo проверить еще на лишние символы, цифры
    return messages.email.required
  }

  return ''
}

const requiredIsNil = (value: string | number): ValidationType => (isNil(value) ? messages.required : undefined)

const validatePublicOfficialOrRelatives = (value: boolean) => {
  if (!value) {
    return 'Сервис недоступен для публичных должностных лиц'
  }
}

const validateDepositAccount = (balance: string | number, params: DepositAccountsParams) => {
  // todo отрефакторить и обьединить с validateAmountValues
  const { amount, currency } = params

  const amountNum = typeof amount === 'string' ? toNumber(amount) : amount
  const balanceNum = typeof balance === 'string' ? toNumber(balance) : balance

  if (amountNum > 0 && amountNum > balanceNum) {
    const lackAmount = `${formatMoney(amountNum - balanceNum, currency)}`

    return messages.depositAccount.min(lackAmount)
  }

  return undefined
}

export const validatorsMapping: ValidateMapping = {
  required: isEmpty,
  requiredIsNil,
  length: validateLength,
  balance: validateBalance,
  default: () => undefined,
  amountValues: validateAmountValues,
  phone: validatePhone,
  orderNumber: validateOrderNumber,
  amountPayPhone: validateAmountPayPhone,
  cvc: cvcValidate,
  paymentMethodSelector: paymentMethodSelectorValidate,
  email: validateEmail,
  publicOfficialOrRelatives: validatePublicOfficialOrRelatives,
  depositAccount: validateDepositAccount,
}

// todo отрефакторить валидацию, включая сами валидаторы, сообщения об ощибке, возможность добавления новых валидаторов
export const validateField = (value: string | number | boolean, validators: Validators): ValidationType => {
  let errorMessage: string

  if (!validators) {
    return undefined
  }

  for (let i = 0; i < validators.length; i++) {
    if (typeof validators[i] === 'object') {
      const [key]: string[] = keys(validators[i])
      const validate = validatorsMapping[key] || validatorsMapping.default

      errorMessage = validate(value, <Params>validators[i][key])
    } else {
      const validate = validatorsMapping[<string>validators[i]] || validatorsMapping.default

      errorMessage = validate(value)
    }

    if (errorMessage) {
      return errorMessage
    }
  }

  return undefined
}

export function checkSnils(value: string): boolean {
  if (!/^\d{3}-\d{3}-\d{3}\s\d{2}$/.test(value)) {
    return false
  }
  value = value.replace(/\D/g, '')
  const checkSum = parseInt(value.slice(9), 10)

  const splittedValue = value.substring(0, 9).split('')

  const sum = splittedValue.reduce((acc, next, index) => acc + Number(next) * (9 - index), 0)

  return (
    (sum < 100 && sum === checkSum) ||
    ((sum === 100 || sum === 101) && checkSum === 0) ||
    (sum > 101 && (sum % 101 === checkSum || (sum % 101 === 100 && checkSum === 0)))
  )
}

export function checkSnilsOnlyChecksum(value: string): boolean {
  let res = ''

  if (typeof value === 'number') {
    res = `${value}`
  } else if (typeof value === 'string') {
    res = value.replace(/\D/g, '')
  } else {
    return false
  }
  if (res.length !== 11) {
    return false
  }
  res = `${res.slice(0, 3)}-${res.slice(3, 6)}-${res.slice(6, 9)} ${res.slice(-2)}`

  return checkSnils(res)
}

export const isLatinAndСyrillicSymbols = (value: string) => {
  const ru = /[а-яё]+/i.test(value)
  const en = /[a-z]+/i.test(value)

  return !(ru && en)
}
