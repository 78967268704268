import { formatMoney } from '@mtsbank/ui-kit'
import { convertPennyToBanknote } from '@components/SBP/ResultPage/utils'

export const messages = {
  // Base
  required: 'Заполните поле',
  min: (min) => `Значение должно быть не менее ${min} символов`,
  max: (max) => `Значение должно быть не более ${max} символов`,
  minMax: (min, max) => `Значение должно быть не менее ${min} символов и не более ${max} символов`,

  // Custom
  balance: {
    min: 'На счёте недостаточно средств. Выберите другой счёт или измените сумму пополнения',
    max: (max) => `Сумма должна быть меньше максимальной суммы ${max} `,
    minMax: (min, max) => `Сумма должна быть больше минимальной суммы ${min} и не превышать максимальную сумму ${max}`,
  },
  repaymentAmount: {
    balance: 'На\u00A0счёте недостаточно средств. Выберите другой счёт или измените сумму платежа',
    balanceWithFee: 'С\u00A0учётом комиссии недостаточно средств',
    min: (value: number) => `Минимальная сумма\u00A0\u2014 ${formatMoney(convertPennyToBanknote(value), 'RUB')}`,
    max: (value: number) => `Максимальная сумма\u00A0\u2014 ${formatMoney(convertPennyToBanknote(value), 'RUB')}`, // todo отрефакторить: value в min & max должен быть только вывод текста без трансформаций
  },
  creditCard: {
    format: 'Укажите правильный номер карты',
    invalidExpiryDate: 'Укажите правильный срок действия',
    expired: 'Дата действия карты должна быть больше текущей',
    isDublicateNumberCard: 'Номер карты получателя, совпадает с\u00A0номером карты отправителя',
  },
  paymentAmount: {
    min: (value) => `Минимальная сумма платежа ${value}`,
    max: (value) => `Максимальная сумма платежа ${value}`,
  },
  email: {
    required: 'Проверьте адрес электронной почты',
  },
  age: {
    min: (value) => `Некорректная дата. Отправителем не\u00A0может быть лицо младше ${value}\u00A0лет`,
  },
  person: {
    name: 'Неверный формат ФИО. Проверьте, что ФИО содержит буквы латиницы, либо кириллицы. Допускаются пробелы и\u00A0дефисы',
  },
  depositAccount: {
    min: (value: string) => `Не\u00A0хватает ${value}. Пополните счет и возвращайтесь`,
  },
}
