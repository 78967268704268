import { Dictionary } from '@reduxjs/toolkit'

export enum DataLayerEvents {
  TABBAR_SHOW,
  TABBAR_PRODUCT_SHOW,
  TABBAR_PRODUCT_SEEING,
  TABBAR_PRODUCT_CLICK,
}

export type ConfigParamsByEvent = Record<DataLayerEvents, Dictionary<string>>

export interface Values {
  name: string
}
