import { document } from '@mtsbank/ui-kit'
import { PersonalOffersProtoComponentProps } from '@open-api/ump/st-personal-offers'
import {
  actionGroups,
  eventActions,
  eventCategories,
  eventContents,
  eventLabels,
  events,
  EventType,
  screenNames,
  sendGmtEvent,
} from '../baseFunctions'
import { translit } from '../translit'
import { convertBooleanToStringGtm } from '../utils'
import { getProductTypeOfferGtm } from './utils'
import { DataLayerEvents } from './types'
import { configParamsByEvent, configParamsByProduct } from './config'

// Показ главной страницы
export const mainLoadedGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.otkrylGlavnayaPaymentsAz,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клик на номер телефона в верхнем правом углу
export const mobileAndProfileClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaNomerProfilya,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Мобильная связь" в выпадающем меню при клике на номер телефона клиента
export const mobileClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaMobSvyaz,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Профиль" в выпадающем меню при клике на номер телефона клиента
export const profileClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaProfile,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на кнопку "Выйти"
export const logOutClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaVyhod,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на кнопку "Платежи" в строке меню
export const paymentsClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaMtsPlatezhi,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// добавил ивент от себя, походу аналитики забыли
// Клик на кнопку "Автоплатежи" в строке меню
export const autoPaymentsCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaMtsAvtoplatezhi,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на кнопку "История" в строке меню
export const historyClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaMtsIstoriya,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на кнопку "Банковские продукты" в строке меню
export const bankProductsClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaMtsBankovskieProducty,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на кнопку "Предложения" в строке меню
export const offersProductsClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaMtsOffers,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Показ виджета "Мой телефон"
export const showMyPhoneGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetMoiTelefon,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.moiTelefon,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Мой телефон"
export const seeingMyPhoneGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetMoiTelefon,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.moiTelefon,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клик на виджет "Мой телефон"
export const myPhoneClickNewGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetMoiTelefon,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.moiTelefon,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ баннера
export const showBannerGtm = (bannerName: string) =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.bannerShow,
    eventLabel: eventLabels.bannerShow,
    screenName: screenNames.newMain,
    eventContext: translit(bannerName),
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр баннера
export const seeingBannerGtm = (bannerName: string) =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.bannerView,
    eventLabel: eventLabels.bannerProsmotr,
    screenName: screenNames.newMain,
    eventContext: translit(bannerName),
    actionGroup: actionGroups.nonInteractions,
  })

// Клик на баннер
export const bannerClickGtm = (bannerName: string) =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.bannerClick,
    eventLabel: eventLabels.clickNaBanner,
    screenName: screenNames.newMain,
    eventContext: translit(bannerName),
    actionGroup: actionGroups.interactions,
  })

// Показ виджета "Карты"
export const showCardsWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetKredity,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.kartyVidzhetShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Карты"
export const seeingCardsWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetKredity,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.kartyVidzhetProsmotr,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клики на виджет "Карты"
export const cardsWidgetClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetKredity,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaKartyVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ виджета "Счета"
export const showAccountsWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetScheta,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.schetaVidzhetShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Счета"
export const seeingAccountsWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetScheta,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.schetaVidzhetShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клики на виджет "Счета"
export const accountsWidgetClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetScheta,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaSchetaVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ виджета "Кредиты"
export const showCreditsWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetKredity,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.kredityVidzhetShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Кредиты"
export const seeingCreditsWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetKredity,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.kredityVidzhetProsmotr,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клики на виджет "Кредиты"
export const creditsWidgetClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetKredity,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaKredityVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Просмотр виджета "Деньги на покупку"
export const seeingMoneyForPurchaseWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetDengiNaPokupku,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.dengiNaPokupkuVidzhetShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клики на виджет "Деньги на покупку"
export const moneyForPurchaseWidgetClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetDengiNaPokupku,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaDengiNaPokupkuVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ виджета "Вклады"
export const showDepositsWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetVklady,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.vkladyVidzhetShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Вклады"
export const seeingDepositsWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetVklady,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.vkladyVidzhetProsmotr,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клики на виджет "Вклады"
export const depositsWidgetClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetVklady,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaVkladyVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ кнопки "Открыть новый продукт"
export const showOpenNewProductCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonShow,
    eventLabel: eventLabels.noviyProductButtonShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр кнопки "Открыть новый продукт"
export const seeingOpenNewProductCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonView,
    eventLabel: eventLabels.noviyProductButtonProsmotr,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клик на кнопку "Открыть новый продукт"
export const openNewProductCLickNewGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaNoviyProductButton,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ виджета "Рекомендации"
export const showRecommendationsGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.recomendaciiVidzhetShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Рекомендации"
export const seeingRecommendationsGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.recomendaciiVidzhetProsmotr,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клики на виджет "Рекомендации"
export const recommendationsCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaRecomendaciiVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ виджета "Шаблоны и автоплатежи"
export const showTemplatesAndAutoPaymentsGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.shablonyAvtoplatezhiVidzhetShow,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Шаблоны и автоплатежи"
export const seeingTemplatesAndAutoPaymentsGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.shablonyAvtoplatezhiVidzhetProsmotr,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клик на "Счет на оплату "виджет "Шаблоны и автоплатежи"
export const apsCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaSchetNaOplatyVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Шаблоны "виджет "Шаблоны и автоплатежи"
export const templatesCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaShablonyVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Автоплатежи" виджет "Шаблоны и автоплатежи"
export const autoPaymentsInOtherClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaAvtoplatezhiVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Создать новый" виджет "Шаблоны и автоплатежи"
export const createNewCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaSozdatNoviyVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ виджета "Кешбэк и акции"
export const showCashBackSectionGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.keshbekAkciiVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Кешбэк и акции"
export const seeingCashBackSectionGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.keshbekAkciiVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Клик "MTS Cashback" на виджет "Кешбэк и акции"
export const cashBackClickNewGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.keshbekAkciiVidzhetCashback,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// такой кнопки пока нет
// Клик "Акции и скидки" на виджет "Кешбэк и акции"
// sendGmtEvent({
//   event: events.mtsPaymentSpa,
//   eventCategory: eventCategories.glavnayaPaymentsAz,
//   eventAction: eventActions.buttonClick,
//   eventLabel: eventLabels.keshbekAkciiVidzhet,
//   screenName: screenNames.newMain,
//   actionGroup: actionGroups.interactions,
// })

// Показ виджета "Еще"
export const showOtherGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.escheVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр виджета "Еще"
export const seeingOtherGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.escheVidzhetProsmotr,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// добавил ивент от себя, походу аналитики забыли
// Клик "История операций" на виджет "Еще"
export const historyInOtherClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaIstoriyaOperaciyVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик "Комиссии и лимиты" на виджет "Еще"
export const limitsClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaKomisiiILimityVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клики "Справки и выписки" на виджет "Еще"
export const referencesClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaSpravkiIVypiskiVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клики "Офисы и банкоматы" на виджет "Еще"
export const officesAndATMsClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaOficyIAtmVidzhet,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

export const mainPageOfferGtm = (
  event: DataLayerEvents,
  { numberPattern, offerId, productType, priority, product, FA }: PersonalOffersProtoComponentProps
) => {
  const productKey = getProductTypeOfferGtm(productType, product)

  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventValue: (priority ?? '').toString(),
    screenName: window.location.pathname,
    eventContent: eventContents.EntryPoint,
    eventContext: numberPattern ?? null,
    filterName: offerId ?? null,
    prCat: productType ?? null,
    productName: product ?? null,
    formId: convertBooleanToStringGtm(FA),
    ...configParamsByProduct[productKey],
    ...configParamsByEvent[event],
  })
}

// Показ виджета  "Премиум"
export const showPremiumGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.premium,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Показ виджета  "Премиум" для потенциальных клиентов
export const showPremiumPotentialGtm = (productData: EventType) =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.widgetPremiumPotential,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.nonInteractions,
    ...productData,
  })

// Клик по виджету "Премиум"
export const premiumClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.premium,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по виджету "Премиум" для потенциальных клиентов
export const premiumClickPotentialWidgetGtm = (productData: EventType) =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaPaymentsAz,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.widgetPremiumPotential,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
    ...productData,
  })
