// tslint:disable
/**
 * dbo-reference-book
 * MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface BankListReq
 */
export interface BankListReq {
  /**
   *
   * @type {string}
   * @memberof BankListReq
   */
  bic?: string
  /**
   *
   * @type {string}
   * @memberof BankListReq
   */
  bin?: string
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  requestId?: string
  /**
   *
   * @type {Error}
   * @memberof ErrorResponse
   */
  error?: Error
}
/**
 *
 * @export
 * @interface GetTransferByPhoneConstraintsReq
 */
export interface GetTransferByPhoneConstraintsReq {
  /**
   *
   * @type {string}
   * @memberof GetTransferByPhoneConstraintsReq
   */
  srcPhone?: string
  /**
   *
   * @type {string}
   * @memberof GetTransferByPhoneConstraintsReq
   */
  productType?: string
  /**
   *
   * @type {boolean}
   * @memberof GetTransferByPhoneConstraintsReq
   */
  isInternalTransfer?: boolean
  /**
   *
   * @type {string}
   * @memberof GetTransferByPhoneConstraintsReq
   */
  recipientBankId?: string
  /**
   *
   * @type {number}
   * @memberof GetTransferByPhoneConstraintsReq
   */
  rboId?: number
}
/**
 *
 * @export
 * @interface MerchantListReq
 */
export interface MerchantListReq {
  /**
   *
   * @type {string}
   * @memberof MerchantListReq
   */
  placeDesc?: string
  /**
   *
   * @type {string}
   * @memberof MerchantListReq
   */
  mcc_code?: string
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  code?: number
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  externalCode?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  details?: string
}
/**
 *
 * @export
 * @interface TransferByPhoneConstraintsRes
 */
export interface TransferByPhoneConstraintsRes {
  /**
   *
   * @type {number}
   * @memberof TransferByPhoneConstraintsRes
   */
  maxAmount?: number
  /**
   *
   * @type {number}
   * @memberof TransferByPhoneConstraintsRes
   */
  maxMessageLength?: number
}

/**
 * ReferenceBookApi - axios parameter creator
 * @export
 */
export const ReferenceBookApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'v1'} version
     * @param {string} [bin]
     * @param {string} [bic]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBank(version: 'v1', bin?: string, bic?: string, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling getBank.')
      }
      const localVarPath = `/dbo-reference-book/{version}/bank`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (bin !== undefined) {
        localVarQueryParameter['bin'] = bin
      }

      if (bic !== undefined) {
        localVarQueryParameter['bic'] = bic
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<BankListReq>} [bankListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankList(version: 'v1', bankListReq?: Array<BankListReq>, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling getBankList.')
      }
      const localVarPath = `/dbo-reference-book/{version}/bankList`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof bankListReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(bankListReq !== undefined ? bankListReq : {})
        : bankListReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<MerchantListReq>} [merchantListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchantList(version: 'v1', merchantListReq?: Array<MerchantListReq>, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getMerchantList.'
        )
      }
      const localVarPath = `/dbo-reference-book/{version}/merchantList`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof merchantListReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(merchantListReq !== undefined ? merchantListReq : {})
        : merchantListReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'v1' | 'v2'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentLimits(version: 'v1' | 'v2', rboId?: string, authorization?: string, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getPaymentLimits.'
        )
      }
      const localVarPath = `/dbo-reference-book/{version}/bindinglimits`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboId !== undefined && rboId !== null) {
        localVarHeaderParameter['rbo_id'] = String(rboId)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<string>} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReferenceData(version: 'v1', type?: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getReferenceData.'
        )
      }
      const localVarPath = `/dbo-reference-book/{version}/reference`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (type) {
        localVarQueryParameter['type'] = type
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {string} [service]
     * @param {boolean} [reasonsForConnect]
     * @param {boolean} [reasonsForRefusal]
     * @param {boolean} [scheduleInfo]
     * @param {string} [typeOfProduct]
     * @param {boolean} [shortInfo]
     * @param {boolean} [cost]
     * @param {boolean} [needSchedule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicesBook(
      version: 'v1',
      service?: string,
      reasonsForConnect?: boolean,
      reasonsForRefusal?: boolean,
      scheduleInfo?: boolean,
      typeOfProduct?: string,
      shortInfo?: boolean,
      cost?: boolean,
      needSchedule?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getServicesBook.'
        )
      }
      const localVarPath = `/dbo-reference-book/{version}/services`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (service !== undefined) {
        localVarQueryParameter['service'] = service
      }

      if (reasonsForConnect !== undefined) {
        localVarQueryParameter['reasonsForConnect'] = reasonsForConnect
      }

      if (reasonsForRefusal !== undefined) {
        localVarQueryParameter['reasonsForRefusal'] = reasonsForRefusal
      }

      if (scheduleInfo !== undefined) {
        localVarQueryParameter['scheduleInfo'] = scheduleInfo
      }

      if (typeOfProduct !== undefined) {
        localVarQueryParameter['typeOfProduct'] = typeOfProduct
      }

      if (shortInfo !== undefined) {
        localVarQueryParameter['shortInfo'] = shortInfo
      }

      if (cost !== undefined) {
        localVarQueryParameter['cost'] = cost
      }

      if (needSchedule !== undefined) {
        localVarQueryParameter['needSchedule'] = needSchedule
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'v2'} version
     * @param {GetTransferByPhoneConstraintsReq} [getTransferByPhoneConstraintsReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferByPhoneConstraints(
      version: 'v2',
      getTransferByPhoneConstraintsReq?: GetTransferByPhoneConstraintsReq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTransferByPhoneConstraints.'
        )
      }
      const localVarPath = `/dbo-reference-book/{version}/getTransferByPhoneConstraints`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof getTransferByPhoneConstraintsReq !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getTransferByPhoneConstraintsReq !== undefined ? getTransferByPhoneConstraintsReq : {})
        : getTransferByPhoneConstraintsReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {string} [credProgramCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTypeOfProduct(version: 'v1', credProgramCode?: string, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTypeOfProduct.'
        )
      }
      const localVarPath = `/dbo-reference-book/{version}/typeOfProduct`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (credProgramCode !== undefined) {
        localVarQueryParameter['credProgramCode'] = credProgramCode
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ReferenceBookApi - functional programming interface
 * @export
 */
export const ReferenceBookApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'v1'} version
     * @param {string} [bin]
     * @param {string} [bic]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBank(
      version: 'v1',
      bin?: string,
      bic?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ReferenceBookApiAxiosParamCreator(configuration).getBank(version, bin, bic, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<BankListReq>} [bankListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankList(
      version: 'v1',
      bankListReq?: Array<BankListReq>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ReferenceBookApiAxiosParamCreator(configuration).getBankList(
        version,
        bankListReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<MerchantListReq>} [merchantListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchantList(
      version: 'v1',
      merchantListReq?: Array<MerchantListReq>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ReferenceBookApiAxiosParamCreator(configuration).getMerchantList(
        version,
        merchantListReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'v1' | 'v2'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentLimits(
      version: 'v1' | 'v2',
      rboId?: string,
      authorization?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ReferenceBookApiAxiosParamCreator(configuration).getPaymentLimits(
        version,
        rboId,
        authorization,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<string>} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReferenceData(
      version: 'v1',
      type?: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ReferenceBookApiAxiosParamCreator(configuration).getReferenceData(
        version,
        type,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {string} [service]
     * @param {boolean} [reasonsForConnect]
     * @param {boolean} [reasonsForRefusal]
     * @param {boolean} [scheduleInfo]
     * @param {string} [typeOfProduct]
     * @param {boolean} [shortInfo]
     * @param {boolean} [cost]
     * @param {boolean} [needSchedule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicesBook(
      version: 'v1',
      service?: string,
      reasonsForConnect?: boolean,
      reasonsForRefusal?: boolean,
      scheduleInfo?: boolean,
      typeOfProduct?: string,
      shortInfo?: boolean,
      cost?: boolean,
      needSchedule?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ReferenceBookApiAxiosParamCreator(configuration).getServicesBook(
        version,
        service,
        reasonsForConnect,
        reasonsForRefusal,
        scheduleInfo,
        typeOfProduct,
        shortInfo,
        cost,
        needSchedule,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'v2'} version
     * @param {GetTransferByPhoneConstraintsReq} [getTransferByPhoneConstraintsReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferByPhoneConstraints(
      version: 'v2',
      getTransferByPhoneConstraintsReq?: GetTransferByPhoneConstraintsReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferByPhoneConstraintsRes> {
      const localVarAxiosArgs = ReferenceBookApiAxiosParamCreator(configuration).getTransferByPhoneConstraints(
        version,
        getTransferByPhoneConstraintsReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'v1'} version
     * @param {string} [credProgramCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTypeOfProduct(
      version: 'v1',
      credProgramCode?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ReferenceBookApiAxiosParamCreator(configuration).getTypeOfProduct(
        version,
        credProgramCode,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ReferenceBookApi - factory interface
 * @export
 */
export const ReferenceBookApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {'v1'} version
     * @param {string} [bin]
     * @param {string} [bic]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBank(version: 'v1', bin?: string, bic?: string, options?: any): AxiosPromise<object> {
      return ReferenceBookApiFp(configuration).getBank(version, bin, bic, options)(axios, basePath)
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<BankListReq>} [bankListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankList(version: 'v1', bankListReq?: Array<BankListReq>, options?: any): AxiosPromise<object> {
      return ReferenceBookApiFp(configuration).getBankList(version, bankListReq, options)(axios, basePath)
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<MerchantListReq>} [merchantListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchantList(version: 'v1', merchantListReq?: Array<MerchantListReq>, options?: any): AxiosPromise<object> {
      return ReferenceBookApiFp(configuration).getMerchantList(version, merchantListReq, options)(axios, basePath)
    },
    /**
     *
     * @param {'v1' | 'v2'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentLimits(
      version: 'v1' | 'v2',
      rboId?: string,
      authorization?: string,
      options?: any
    ): AxiosPromise<object> {
      return ReferenceBookApiFp(configuration).getPaymentLimits(version, rboId, authorization, options)(axios, basePath)
    },
    /**
     *
     * @param {'v1'} version
     * @param {Array<string>} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReferenceData(version: 'v1', type?: Array<string>, options?: any): AxiosPromise<object> {
      return ReferenceBookApiFp(configuration).getReferenceData(version, type, options)(axios, basePath)
    },
    /**
     *
     * @param {'v1'} version
     * @param {string} [service]
     * @param {boolean} [reasonsForConnect]
     * @param {boolean} [reasonsForRefusal]
     * @param {boolean} [scheduleInfo]
     * @param {string} [typeOfProduct]
     * @param {boolean} [shortInfo]
     * @param {boolean} [cost]
     * @param {boolean} [needSchedule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicesBook(
      version: 'v1',
      service?: string,
      reasonsForConnect?: boolean,
      reasonsForRefusal?: boolean,
      scheduleInfo?: boolean,
      typeOfProduct?: string,
      shortInfo?: boolean,
      cost?: boolean,
      needSchedule?: boolean,
      options?: any
    ): AxiosPromise<object> {
      return ReferenceBookApiFp(configuration).getServicesBook(
        version,
        service,
        reasonsForConnect,
        reasonsForRefusal,
        scheduleInfo,
        typeOfProduct,
        shortInfo,
        cost,
        needSchedule,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'v2'} version
     * @param {GetTransferByPhoneConstraintsReq} [getTransferByPhoneConstraintsReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferByPhoneConstraints(
      version: 'v2',
      getTransferByPhoneConstraintsReq?: GetTransferByPhoneConstraintsReq,
      options?: any
    ): AxiosPromise<TransferByPhoneConstraintsRes> {
      return ReferenceBookApiFp(configuration).getTransferByPhoneConstraints(
        version,
        getTransferByPhoneConstraintsReq,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'v1'} version
     * @param {string} [credProgramCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTypeOfProduct(version: 'v1', credProgramCode?: string, options?: any): AxiosPromise<object> {
      return ReferenceBookApiFp(configuration).getTypeOfProduct(version, credProgramCode, options)(axios, basePath)
    },
  }
}

/**
 * ReferenceBookApi - object-oriented interface
 * @export
 * @class ReferenceBookApi
 * @extends {BaseAPI}
 */
export class ReferenceBookApi extends BaseAPI {
  /**
   *
   * @param {'v1'} version
   * @param {string} [bin]
   * @param {string} [bic]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceBookApi
   */
  public getBank(version: 'v1', bin?: string, bic?: string, options?: any) {
    return ReferenceBookApiFp(this.configuration).getBank(version, bin, bic, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {'v1'} version
   * @param {Array<BankListReq>} [bankListReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceBookApi
   */
  public getBankList(version: 'v1', bankListReq?: Array<BankListReq>, options?: any) {
    return ReferenceBookApiFp(this.configuration).getBankList(version, bankListReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {'v1'} version
   * @param {Array<MerchantListReq>} [merchantListReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceBookApi
   */
  public getMerchantList(version: 'v1', merchantListReq?: Array<MerchantListReq>, options?: any) {
    return ReferenceBookApiFp(this.configuration).getMerchantList(
      version,
      merchantListReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'v1' | 'v2'} version
   * @param {string} [rboId]
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceBookApi
   */
  public getPaymentLimits(version: 'v1' | 'v2', rboId?: string, authorization?: string, options?: any) {
    return ReferenceBookApiFp(this.configuration).getPaymentLimits(
      version,
      rboId,
      authorization,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'v1'} version
   * @param {Array<string>} [type]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceBookApi
   */
  public getReferenceData(version: 'v1', type?: Array<string>, options?: any) {
    return ReferenceBookApiFp(this.configuration).getReferenceData(version, type, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {'v1'} version
   * @param {string} [service]
   * @param {boolean} [reasonsForConnect]
   * @param {boolean} [reasonsForRefusal]
   * @param {boolean} [scheduleInfo]
   * @param {string} [typeOfProduct]
   * @param {boolean} [shortInfo]
   * @param {boolean} [cost]
   * @param {boolean} [needSchedule]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceBookApi
   */
  public getServicesBook(
    version: 'v1',
    service?: string,
    reasonsForConnect?: boolean,
    reasonsForRefusal?: boolean,
    scheduleInfo?: boolean,
    typeOfProduct?: string,
    shortInfo?: boolean,
    cost?: boolean,
    needSchedule?: boolean,
    options?: any
  ) {
    return ReferenceBookApiFp(this.configuration).getServicesBook(
      version,
      service,
      reasonsForConnect,
      reasonsForRefusal,
      scheduleInfo,
      typeOfProduct,
      shortInfo,
      cost,
      needSchedule,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'v2'} version
   * @param {GetTransferByPhoneConstraintsReq} [getTransferByPhoneConstraintsReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceBookApi
   */
  public getTransferByPhoneConstraints(
    version: 'v2',
    getTransferByPhoneConstraintsReq?: GetTransferByPhoneConstraintsReq,
    options?: any
  ) {
    return ReferenceBookApiFp(this.configuration).getTransferByPhoneConstraints(
      version,
      getTransferByPhoneConstraintsReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'v1'} version
   * @param {string} [credProgramCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReferenceBookApi
   */
  public getTypeOfProduct(version: 'v1', credProgramCode?: string, options?: any) {
    return ReferenceBookApiFp(this.configuration).getTypeOfProduct(
      version,
      credProgramCode,
      options
    )(this.axios, this.basePath)
  }
}
