import {
  LoanOrderControllerV1Api as LoanOrderV1Api,
  LoanOrderControllerV2Api as LoanOrderV2Api,
  LoanPaymentControllerApi as LoanPaymentApi,
  OrderDocumentControllerV1Api as OrderDocumentV1Api,
  OrderStatusControllerV1Api as OrderStatusV1Api,
} from '@open-api/ump/dbo-pos-issue'

export const loanOrderV1Api = new LoanOrderV1Api({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const loanOrderV2Api = new LoanOrderV2Api({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const loanPaymentApi = new LoanPaymentApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const orderDocumentV1Api = new OrderDocumentV1Api({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const orderStatusV1Api = new OrderStatusV1Api({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})
