// tslint:disable
/**
 * tsp-personal-finance
 * MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Health
 */
export interface Health {
  /**
   *
   * @type {string}
   * @memberof Health
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof Health
   */
  status?: string
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof Health
   */
  details?: { [key: string]: object }
}
/**
 *
 * @export
 * @interface ImportEntity
 */
export interface ImportEntity {
  /**
   *
   * @type {number}
   * @memberof ImportEntity
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ImportEntity
   */
  state?: string
  /**
   *
   * @type {number}
   * @memberof ImportEntity
   */
  totalFiles?: number
  /**
   *
   * @type {number}
   * @memberof ImportEntity
   */
  totalRecords?: number
  /**
   *
   * @type {number}
   * @memberof ImportEntity
   */
  loadedFiles?: number
  /**
   *
   * @type {number}
   * @memberof ImportEntity
   */
  loadedRecords?: number
  /**
   *
   * @type {string}
   * @memberof ImportEntity
   */
  startTime?: string
  /**
   *
   * @type {string}
   * @memberof ImportEntity
   */
  stateTime?: string
  /**
   *
   * @type {string}
   * @memberof ImportEntity
   */
  dataset?: string
}
/**
 *
 * @export
 * @interface ImportModel
 */
export interface ImportModel {
  /**
   *
   * @type {number}
   * @memberof ImportModel
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ImportModel
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof ImportModel
   */
  dataset?: string
}
/**
 *
 * @export
 * @interface InitInfoModel
 */
export interface InitInfoModel {
  /**
   *
   * @type {number}
   * @memberof InitInfoModel
   */
  totalFiles?: number
  /**
   *
   * @type {number}
   * @memberof InitInfoModel
   */
  totalRecords?: number
  /**
   *
   * @type {string}
   * @memberof InitInfoModel
   */
  dataset?: string
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {any}
   * @memberof InlineObject
   */
  importFile: any
}
/**
 *
 * @export
 * @interface RecomendationsRes
 */
export interface RecomendationsRes {
  /**
   *
   * @type {Array<TspDto>}
   * @memberof RecomendationsRes
   */
  data?: Array<TspDto>
}
/**
 *
 * @export
 * @interface StateTable
 */
export interface StateTable {
  /**
   *
   * @type {number}
   * @memberof StateTable
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof StateTable
   */
  readTable?: string
  /**
   *
   * @type {string}
   * @memberof StateTable
   */
  writeTable?: string
}
/**
 *
 * @export
 * @interface TspDto
 */
export interface TspDto {
  /**
   *
   * @type {string}
   * @memberof TspDto
   */
  id?: string
}

/**
 * ControllerApi - axios parameter creator
 * @export
 */
export const ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(options: any = {}): RequestArgs {
      const localVarPath = `/tsp-personal-finance`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Оформление карты
     * @param {string} [phone]
     * @param {string} [region]
     * @param {'v1' | 'v2'} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTsp(phone?: string, region?: string, version?: 'v1' | 'v2', options: any = {}): RequestArgs {
      const localVarPath = `/tsp-personal-finance/v1/recomendations`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone
      }

      if (region !== undefined) {
        localVarQueryParameter['region'] = region
      }

      if (version !== undefined) {
        localVarQueryParameter['version'] = version
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Получение рекомендаций
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTspV2(region: string, options: any = {}): RequestArgs {
      // verify required parameter 'region' is not null or undefined
      if (region === null || region === undefined) {
        throw new RequiredError('region', 'Required parameter region was null or undefined when calling getTspV2.')
      }
      const localVarPath = `/tsp-personal-finance/v2/recomendations`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (region !== undefined) {
        localVarQueryParameter['region'] = region
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveness(options: any = {}): RequestArgs {
      const localVarPath = `/tsp-personal-finance/live`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readiness(options: any = {}): RequestArgs {
      const localVarPath = `/tsp-personal-finance/read`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ControllerApi - functional programming interface
 * @export
 */
export const ControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).check(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Оформление карты
     * @param {string} [phone]
     * @param {string} [region]
     * @param {'v1' | 'v2'} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTsp(
      phone?: string,
      region?: string,
      version?: 'v1' | 'v2',
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecomendationsRes> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getTsp(phone, region, version, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Получение рекомендаций
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTspV2(region: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getTspV2(region, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveness(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Health> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).liveness(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readiness(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Health> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).readiness(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ControllerApi - factory interface
 * @export
 */
export const ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(options?: any): AxiosPromise<string> {
      return ControllerApiFp(configuration).check(options)(axios, basePath)
    },
    /**
     *
     * @summary Оформление карты
     * @param {string} [phone]
     * @param {string} [region]
     * @param {'v1' | 'v2'} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTsp(phone?: string, region?: string, version?: 'v1' | 'v2', options?: any): AxiosPromise<RecomendationsRes> {
      return ControllerApiFp(configuration).getTsp(phone, region, version, options)(axios, basePath)
    },
    /**
     *
     * @summary Получение рекомендаций
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTspV2(region: string, options?: any): AxiosPromise<object> {
      return ControllerApiFp(configuration).getTspV2(region, options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveness(options?: any): AxiosPromise<Health> {
      return ControllerApiFp(configuration).liveness(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readiness(options?: any): AxiosPromise<Health> {
      return ControllerApiFp(configuration).readiness(options)(axios, basePath)
    },
  }
}

/**
 * ControllerApi - object-oriented interface
 * @export
 * @class ControllerApi
 * @extends {BaseAPI}
 */
export class ControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public check(options?: any) {
    return ControllerApiFp(this.configuration).check(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Оформление карты
   * @param {string} [phone]
   * @param {string} [region]
   * @param {'v1' | 'v2'} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getTsp(phone?: string, region?: string, version?: 'v1' | 'v2', options?: any) {
    return ControllerApiFp(this.configuration).getTsp(phone, region, version, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получение рекомендаций
   * @param {string} region
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getTspV2(region: string, options?: any) {
    return ControllerApiFp(this.configuration).getTspV2(region, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public liveness(options?: any) {
    return ControllerApiFp(this.configuration).liveness(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public readiness(options?: any) {
    return ControllerApiFp(this.configuration).readiness(options)(this.axios, this.basePath)
  }
}

/**
 * LoaderControllerApi - axios parameter creator
 * @export
 */
export const LoaderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Удалить импорт по id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/tsp-personal-finance/import/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Окончание загрузки тсп
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finish(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling finish.')
      }
      const localVarPath = `/tsp-personal-finance/{id}/finish`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Получить список всех импортов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(options: any = {}): RequestArgs {
      const localVarPath = `/tsp-personal-finance/import`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Получить таблицу состояний
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllState(options: any = {}): RequestArgs {
      const localVarPath = `/tsp-personal-finance/state-table`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Отправка файла тсп
     * @param {number} id
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imports(id: number, inlineObject?: InlineObject, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling imports.')
      }
      const localVarPath = `/tsp-personal-finance/{id}/file`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof inlineObject !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(inlineObject !== undefined ? inlineObject : {})
        : inlineObject || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Инициализация загрузки тсп
     * @param {InitInfoModel} initInfoModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    init(initInfoModel: InitInfoModel, options: any = {}): RequestArgs {
      // verify required parameter 'initInfoModel' is not null or undefined
      if (initInfoModel === null || initInfoModel === undefined) {
        throw new RequiredError(
          'initInfoModel',
          'Required parameter initInfoModel was null or undefined when calling init.'
        )
      }
      const localVarPath = `/tsp-personal-finance`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof initInfoModel !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(initInfoModel !== undefined ? initInfoModel : {})
        : initInfoModel || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoaderControllerApi - functional programming interface
 * @export
 */
export const LoaderControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Удалить импорт по id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = LoaderControllerApiAxiosParamCreator(configuration)._delete(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Окончание загрузки тсп
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finish(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportModel> {
      const localVarAxiosArgs = LoaderControllerApiAxiosParamCreator(configuration).finish(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Получить список всех импортов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportEntity>> {
      const localVarAxiosArgs = LoaderControllerApiAxiosParamCreator(configuration).getAll(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Получить таблицу состояний
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllState(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StateTable>> {
      const localVarAxiosArgs = LoaderControllerApiAxiosParamCreator(configuration).getAllState(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Отправка файла тсп
     * @param {number} id
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imports(
      id: number,
      inlineObject?: InlineObject,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportModel> {
      const localVarAxiosArgs = LoaderControllerApiAxiosParamCreator(configuration).imports(id, inlineObject, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Инициализация загрузки тсп
     * @param {InitInfoModel} initInfoModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    init(
      initInfoModel: InitInfoModel,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportModel> {
      const localVarAxiosArgs = LoaderControllerApiAxiosParamCreator(configuration).init(initInfoModel, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoaderControllerApi - factory interface
 * @export
 */
export const LoaderControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Удалить импорт по id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<object> {
      return LoaderControllerApiFp(configuration)._delete(id, options)(axios, basePath)
    },
    /**
     *
     * @summary Окончание загрузки тсп
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finish(id: number, options?: any): AxiosPromise<ImportModel> {
      return LoaderControllerApiFp(configuration).finish(id, options)(axios, basePath)
    },
    /**
     *
     * @summary Получить список всех импортов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(options?: any): AxiosPromise<Array<ImportEntity>> {
      return LoaderControllerApiFp(configuration).getAll(options)(axios, basePath)
    },
    /**
     *
     * @summary Получить таблицу состояний
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllState(options?: any): AxiosPromise<Array<StateTable>> {
      return LoaderControllerApiFp(configuration).getAllState(options)(axios, basePath)
    },
    /**
     *
     * @summary Отправка файла тсп
     * @param {number} id
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imports(id: number, inlineObject?: InlineObject, options?: any): AxiosPromise<ImportModel> {
      return LoaderControllerApiFp(configuration).imports(id, inlineObject, options)(axios, basePath)
    },
    /**
     *
     * @summary Инициализация загрузки тсп
     * @param {InitInfoModel} initInfoModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    init(initInfoModel: InitInfoModel, options?: any): AxiosPromise<ImportModel> {
      return LoaderControllerApiFp(configuration).init(initInfoModel, options)(axios, basePath)
    },
  }
}

/**
 * LoaderControllerApi - object-oriented interface
 * @export
 * @class LoaderControllerApi
 * @extends {BaseAPI}
 */
export class LoaderControllerApi extends BaseAPI {
  /**
   *
   * @summary Удалить импорт по id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoaderControllerApi
   */
  public _delete(id: number, options?: any) {
    return LoaderControllerApiFp(this.configuration)._delete(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Окончание загрузки тсп
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoaderControllerApi
   */
  public finish(id: number, options?: any) {
    return LoaderControllerApiFp(this.configuration).finish(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получить список всех импортов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoaderControllerApi
   */
  public getAll(options?: any) {
    return LoaderControllerApiFp(this.configuration).getAll(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получить таблицу состояний
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoaderControllerApi
   */
  public getAllState(options?: any) {
    return LoaderControllerApiFp(this.configuration).getAllState(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Отправка файла тсп
   * @param {number} id
   * @param {InlineObject} [inlineObject]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoaderControllerApi
   */
  public imports(id: number, inlineObject?: InlineObject, options?: any) {
    return LoaderControllerApiFp(this.configuration).imports(id, inlineObject, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Инициализация загрузки тсп
   * @param {InitInfoModel} initInfoModel
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoaderControllerApi
   */
  public init(initInfoModel: InitInfoModel, options?: any) {
    return LoaderControllerApiFp(this.configuration).init(initInfoModel, options)(this.axios, this.basePath)
  }
}
