export interface ProductItem {
  id: string
  title: string
  amount: number
  currency: string
  icon: string
  urlDetails: string
}

export interface ProductClearanceButton {
  text: string
  urlDetails: string
}

export interface BalanceState {
  isHiddenBalance: boolean
}

export interface CardItemProps {
  productItem: ProductItem
  itemType?: ItemType
  isNonePaddingBottom?: boolean
}

export interface CardAddProps {
  item: ProductClearanceButton
  itemType: ItemType
}

export enum ForeignCurrency {
  USD = 'USD', // Доллар США
  EUR = 'EUR', // Евро
}

export enum ExCurrency {
  JPY = 'JPY', // Иена (392)
  CNY = 'CNY', // Китайский юань (156)
  GBP = 'GBP', // Фунт стерлингов (826)
  CHF = 'CHF', // Швейцарский франк (756)
}

export enum ItemType {
  Deposits,
  Accounts,
  Credits,
}

export interface NewMainProps {
  isBankClient: boolean
}

export enum StatusCode {
  SIGN_1 = 'SIGN1',
  SIGN_2 = 'SIGN2',
  WAIT_DO = 'WAIT_DO',
  WAIT_DBO = 'WAIT_DBO',
}

export enum LocalStorageFields {
  SIGN = 'sign_status_show_date',
  WAIT = 'wait_status_show_date',
}

export enum StatusIcon {
  SUCCESS = 'icon-24/SuperSuccess',
  WARNING = 'icon-24/SuperWarning',
}
