import React, { FC, useEffect } from 'react';
import { Heading, Icon, Spacer, Text, formatMoney, theme, declOfNum } from '@mtsbank/ui-kit';
import { styled } from '@mtsbank/common';
import { IncompleteOrderDtoV2, IncompleteOrderDtoV2StatusEnum as IncompleteStatusEnum } from '@open-api/ump/dbo-pos-issue';
import { SPA_POS_PATH } from '@root/constants';
import { RUB } from '@root/utils/constants';
import { WrapperBox, WrapperCard, WrapperCircle } from '@components/Styled/Wrappers';
import { LENGTH_SIDE, MONTH_ENDINGS_GENITIVE } from './constants';
interface Props {
  unproceedCredit: IncompleteOrderDtoV2;
}
export const UnproceedCredit: FC<Props> = ({
  unproceedCredit
}) => {
  const {
    name,
    statusName,
    status,
    amount,
    reqAmount,
    term,
    reqTerm
  } = unproceedCredit;
  const isNotHandled = status === IncompleteStatusEnum.QUEUED || status === IncompleteStatusEnum.EXPECTIDENTIFICATION;
  const isWaitSign = status === IncompleteStatusEnum.WAITSIGN;
  const hasAmount = isWaitSign && Boolean(amount) || !isWaitSign && Boolean(reqAmount);
  const hasTerm = isWaitSign && Boolean(term) || !isWaitSign && Boolean(reqTerm);
  return <Link href={SPA_POS_PATH}>
      <WrapperCard>
        <WrapperBox>
          <Text size="md" color={theme.colors.neutral.g300}>
            {name}
          </Text>
          <Text size="lg" fontWeight="medium" color={theme.colors.neutral.g300}>
            {statusName}
          </Text>
          <Spacer samespace space={theme.spacings.xs3} />
          {hasTerm && hasAmount && <Text size="md" color={theme.colors.neutral.g300}>
              {isWaitSign && <>
                  {formatMoney(amount, RUB)} на {term} {declOfNum(Number(term), MONTH_ENDINGS_GENITIVE)}
                </>}
              {isNotHandled && <>
                  {formatMoney(reqAmount, RUB)} на {reqTerm} {declOfNum(Number(reqTerm), MONTH_ENDINGS_GENITIVE)}
                </>}
            </Text>}
        </WrapperBox>
        <WrapperBox>
          <WrapperCircle lengthSide={LENGTH_SIDE}>
            <Icon icon="baseX24/ic-money" />
          </WrapperCircle>
        </WrapperBox>
      </WrapperCard>
    </Link>;
};
const Link = styled.a`
  text-decoration: none;
  color: inherit;
`;