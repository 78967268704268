import { ExCurrency, ForeignCurrency, LocalStorageFields, StatusCode } from '@components/NewMain/types'
import USD from '@icons/USD.svg'
import EUR from '@icons/EUR.svg'
import CHF from '@icons/CHF.svg'
import CNY from '@icons/CNY.svg'
import GBP from '@icons/GBP.svg'
import JPY from '@icons/JPY.svg'
import { LOAN_STATUS_DISPLAY_PERIOD } from './constants'

export const iconCurrencyMapping = {
  [ForeignCurrency.USD]: USD,
  [ForeignCurrency.EUR]: EUR,
  [ExCurrency.CHF]: CHF,
  [ExCurrency.CNY]: CNY,
  [ExCurrency.GBP]: GBP,
  [ExCurrency.JPY]: JPY,
}

export const getPopUpLocalStorageKey = (statusCode: string, hasWaitDboWithoutPeriod?: boolean): LocalStorageFields => {
  const dateNow = Date.now()
  const signStatusShowDate = Number(localStorage.getItem(LocalStorageFields.SIGN))
  const waitStatusShowDate = Number(localStorage.getItem(LocalStorageFields.WAIT))
  const signShowTimePassed = dateNow - signStatusShowDate
  const waitShowTimePassed = dateNow - waitStatusShowDate
  let key: LocalStorageFields

  if (hasWaitDboWithoutPeriod) {
    return
  }

  if (
    [StatusCode.SIGN_1, StatusCode.SIGN_2].includes(statusCode as StatusCode) &&
    signShowTimePassed > LOAN_STATUS_DISPLAY_PERIOD
  ) {
    key = LocalStorageFields.SIGN
  }

  if (
    [StatusCode.WAIT_DO, StatusCode.WAIT_DBO].includes(statusCode as StatusCode) &&
    waitShowTimePassed > LOAN_STATUS_DISPLAY_PERIOD
  ) {
    key = LocalStorageFields.WAIT
  }

  return key
}
