import { HistoryProtoResponseId, HistoryProtoNotificationType } from '@open-api/ump/response-history'
import { PersonalOffersProtoComponentProps } from '@open-api/ump/st-personal-offers'
import { responseHistoryApi } from '@api/ump/response-history'
import { BaseAPI } from '@generated/base'
import { PromoApi } from '@generated/api'
import { authHelper } from '@utils/authHelper/AuthHelper'
import { FormatAliasPhone, formatPhoneNumber } from '@utils/formatPhoneNumber'

const basePath = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.mtsbank.ru'

const apiClientFactory = <T extends BaseAPI>(client: string) => {
  switch (client) {
    case PromoApi.name:
      return new PromoApi(void 0, basePath)
    default:
      break
  }

  throw new Error(`Unknown client: ${client} expected!`)
}

const saveResponseHistory = (
  offer: PersonalOffersProtoComponentProps,
  responseId: HistoryProtoResponseId,
  phoneNum: string
) => {
  const date = new Date().toISOString().slice(0, -1)

  return responseHistoryApi.saveResponseHistory({
    responseData: {
      channel: HistoryProtoNotificationType.PMT,
      effDt: date,
      externalId: offer.offerId,
      responseId,
      personalInfo: {
        rboId: `${authHelper.userData?.rbo_id}`,
        addressee: formatPhoneNumber(phoneNum, FormatAliasPhone.DIGIT11),
      },
      additionalData: {
        marketingRetail: {
          deviceOs: 'undefined',
          deviceType: 'Mobile',
          numberPattern: offer.numberPattern,
        },
      },
    },
  })
}

export { apiClientFactory, saveResponseHistory }
