// tslint:disable
/**
 * service-platform/product-services/communication/otp-proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 * Contains an arbitrary serialized message along with a @type that describes the type of the serialized message.
 * @export
 * @interface GoogleProtobufAny
 */
export interface GoogleProtobufAny {
  [key: string]: object | any

  /**
   * The type of the serialized message.
   * @type {string}
   * @memberof GoogleProtobufAny
   */
  type?: string
}
/**
 *
 * @export
 * @interface OtpRequestV2
 */
export interface OtpRequestV2 {
  /**
   *
   * @type {RequestData}
   * @memberof OtpRequestV2
   */
  data?: RequestData
  /**
   *
   * @type {RequestMetaV2}
   * @memberof OtpRequestV2
   */
  meta?: RequestMetaV2
}
/**
 *
 * @export
 * @interface OtpResponse
 */
export interface OtpResponse {
  /**
   *
   * @type {ResponseData}
   * @memberof OtpResponse
   */
  data?: ResponseData
  /**
   *
   * @type {ResponseMeta}
   * @memberof OtpResponse
   */
  meta?: ResponseMeta
}
/**
 *
 * @export
 * @interface Request
 */
export interface Request {
  /**
   *
   * @type {RequestData}
   * @memberof Request
   */
  data?: RequestData
  /**
   *
   * @type {RequestMeta}
   * @memberof Request
   */
  meta?: RequestMeta
}
/**
 *
 * @export
 * @interface RequestData
 */
export interface RequestData {
  /**
   * Номер телефона клиента
   * @type {string}
   * @memberof RequestData
   */
  phone?: string
  /**
   * Текст сообщения.
   * @type {string}
   * @memberof RequestData
   */
  message?: string
  /**
   * Идентификатор кода
   * @type {string}
   * @memberof RequestData
   */
  guid?: string
  /**
   * Код подтверждения
   * @type {string}
   * @memberof RequestData
   */
  code?: string
  /**
   * The int32 value.
   * @type {number}
   * @memberof RequestData
   */
  lifeTime?: number
  /**
   *
   * @type {StringValue}
   * @memberof RequestData
   */
  action?: StringValue
}
/**
 *
 * @export
 * @interface RequestMeta
 */
export interface RequestMeta {
  /**
   * Тип сообщения.
   * @type {string}
   * @memberof RequestMeta
   */
  type?: string
  /**
   * Имя отправителя
   * @type {string}
   * @memberof RequestMeta
   */
  smsFrom?: string
  /**
   * Длина кода ОТП.
   * @type {number}
   * @memberof RequestMeta
   */
  length?: number
  /**
   * Идентификатор шаблона текста уведомления
   * @type {number}
   * @memberof RequestMeta
   */
  templateId?: number
  /**
   * Костыль для запросов приходящих с сайта Согласно документации https://confluence.mtsbank.ru/pages/viewpage.action?pageId=6449781 поле не ожидаем
   * @type {string}
   * @memberof RequestMeta
   */
  date?: string
  /**
   * The int32 value.
   * @type {number}
   * @memberof RequestMeta
   */
  error?: number
  /**
   *
   * @type {StringValue}
   * @memberof RequestMeta
   */
  message?: StringValue
}
/**
 *
 * @export
 * @interface RequestMetaV2
 */
export interface RequestMetaV2 {
  /**
   * Тип сообщения.
   * @type {string}
   * @memberof RequestMetaV2
   */
  type?: string
  /**
   * Имя отправителя
   * @type {string}
   * @memberof RequestMetaV2
   */
  smsFrom?: string
  /**
   * Длина кода ОТП.
   * @type {number}
   * @memberof RequestMetaV2
   */
  length?: number
  /**
   * Идентификатор шаблона текста уведомления
   * @type {number}
   * @memberof RequestMetaV2
   */
  templateId?: number
  /**
   * The int32 value.
   * @type {number}
   * @memberof RequestMetaV2
   */
  resendLimit?: number
  /**
   * The int32 value.
   * @type {number}
   * @memberof RequestMetaV2
   */
  resendTimeLimit?: number
  /**
   * The int32 value.
   * @type {number}
   * @memberof RequestMetaV2
   */
  checkLimit?: number
}
/**
 *
 * @export
 * @interface ResponseData
 */
export interface ResponseData {
  /**
   * Идентификатор кода
   * @type {string}
   * @memberof ResponseData
   */
  guid?: string
  /**
   * Дата операции
   * @type {string}
   * @memberof ResponseData
   */
  date?: string
  /**
   * The int32 value.
   * @type {number}
   * @memberof ResponseData
   */
  lifeTime?: number
}
/**
 *
 * @export
 * @interface ResponseMeta
 */
export interface ResponseMeta {
  /**
   * Тип сообщения.
   * @type {string}
   * @memberof ResponseMeta
   */
  type?: string
  /**
   * Дата операции
   * @type {string}
   * @memberof ResponseMeta
   */
  date?: string
  /**
   * The int32 value.
   * @type {number}
   * @memberof ResponseMeta
   */
  error?: number
  /**
   * Текст ошибки/тип операции
   * @type {string}
   * @memberof ResponseMeta
   */
  message?: string
  /**
   * The int32 value.
   * @type {number}
   * @memberof ResponseMeta
   */
  length?: number
  /**
   * The int32 value.
   * @type {number}
   * @memberof ResponseMeta
   */
  resendLimit?: number
  /**
   * The int32 value.
   * @type {number}
   * @memberof ResponseMeta
   */
  resendTimeLimit?: number
  /**
   * The int32 value.
   * @type {number}
   * @memberof ResponseMeta
   */
  checkLimit?: number
}
/**
 * The `Status` type defines a logical error model that is suitable for different programming environments, including REST APIs and RPC APIs. It is used by [gRPC](https://github.com/grpc). Each `Status` message contains three pieces of data: error code, error message, and error details. You can find out more about this error model and how to work with it in the [API Design Guide](https://cloud.google.com/apis/design/errors).
 * @export
 * @interface Status
 */
export interface Status {
  /**
   * The status code, which should be an enum value of [google.rpc.Code][google.rpc.Code].
   * @type {number}
   * @memberof Status
   */
  code?: number
  /**
   * A developer-facing error message, which should be in English. Any user-facing error message should be localized and sent in the [google.rpc.Status.details][google.rpc.Status.details] field, or localized by the client.
   * @type {string}
   * @memberof Status
   */
  message?: string
  /**
   * A list of messages that carry the error details.  There is a common set of message types for APIs to use.
   * @type {Array<GoogleProtobufAny>}
   * @memberof Status
   */
  details?: Array<GoogleProtobufAny>
}
/**
 * Wrapper message for `string`. The JSON representation for `StringValue` is JSON string.
 * @export
 * @interface StringValue
 */
export interface StringValue {
  /**
   * The string value.
   * @type {string}
   * @memberof StringValue
   */
  value?: string
}

/**
 * OtpSenderServiceApi - axios parameter creator
 * @export
 */
export const OtpSenderServiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSMSCode(request: Request, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling checkSMSCode.'
        )
      }
      const localVarPath = `/otp-sender/api/v1/sms/check`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {OtpRequestV2} otpRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSMSCodeV2(otpRequestV2: OtpRequestV2, options: any = {}): RequestArgs {
      // verify required parameter 'otpRequestV2' is not null or undefined
      if (otpRequestV2 === null || otpRequestV2 === undefined) {
        throw new RequiredError(
          'otpRequestV2',
          'Required parameter otpRequestV2 was null or undefined when calling checkSMSCodeV2.'
        )
      }
      const localVarPath = `/otp-sender/api/v2/sms/check`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof otpRequestV2 !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(otpRequestV2 !== undefined ? otpRequestV2 : {})
        : otpRequestV2 || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSMSCode(request: Request, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError('request', 'Required parameter request was null or undefined when calling sendSMSCode.')
      }
      const localVarPath = `/otp-sender/api/v1/sms/send-code`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {OtpRequestV2} otpRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSMSCodeV2(otpRequestV2: OtpRequestV2, options: any = {}): RequestArgs {
      // verify required parameter 'otpRequestV2' is not null or undefined
      if (otpRequestV2 === null || otpRequestV2 === undefined) {
        throw new RequiredError(
          'otpRequestV2',
          'Required parameter otpRequestV2 was null or undefined when calling sendSMSCodeV2.'
        )
      }
      const localVarPath = `/otp-sender/api/v2/sms/send-code`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof otpRequestV2 !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(otpRequestV2 !== undefined ? otpRequestV2 : {})
        : otpRequestV2 || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Для отправки СМС с произвольным текстом, используйте <a href=\'https://confluence.mbrd.ru/pages/viewpage.action?pageId=54370722\'>Запрос на формирование уведомления (по шаблону)</a>
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSms(request: Request, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError('request', 'Required parameter request was null or undefined when calling sendSms.')
      }
      const localVarPath = `/otp-sender/api/v1/sms/send`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OtpSenderServiceApi - functional programming interface
 * @export
 */
export const OtpSenderServiceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSMSCode(
      request: Request,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtpResponse> {
      const localVarAxiosArgs = OtpSenderServiceApiAxiosParamCreator(configuration).checkSMSCode(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {OtpRequestV2} otpRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSMSCodeV2(
      otpRequestV2: OtpRequestV2,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtpResponse> {
      const localVarAxiosArgs = OtpSenderServiceApiAxiosParamCreator(configuration).checkSMSCodeV2(
        otpRequestV2,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSMSCode(
      request: Request,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtpResponse> {
      const localVarAxiosArgs = OtpSenderServiceApiAxiosParamCreator(configuration).sendSMSCode(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {OtpRequestV2} otpRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSMSCodeV2(
      otpRequestV2: OtpRequestV2,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtpResponse> {
      const localVarAxiosArgs = OtpSenderServiceApiAxiosParamCreator(configuration).sendSMSCodeV2(otpRequestV2, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Для отправки СМС с произвольным текстом, используйте <a href=\'https://confluence.mbrd.ru/pages/viewpage.action?pageId=54370722\'>Запрос на формирование уведомления (по шаблону)</a>
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSms(request: Request, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtpResponse> {
      const localVarAxiosArgs = OtpSenderServiceApiAxiosParamCreator(configuration).sendSms(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OtpSenderServiceApi - factory interface
 * @export
 */
export const OtpSenderServiceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSMSCode(request: Request, options?: any): AxiosPromise<OtpResponse> {
      return OtpSenderServiceApiFp(configuration).checkSMSCode(request, options)(axios, basePath)
    },
    /**
     *
     * @param {OtpRequestV2} otpRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSMSCodeV2(otpRequestV2: OtpRequestV2, options?: any): AxiosPromise<OtpResponse> {
      return OtpSenderServiceApiFp(configuration).checkSMSCodeV2(otpRequestV2, options)(axios, basePath)
    },
    /**
     *
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSMSCode(request: Request, options?: any): AxiosPromise<OtpResponse> {
      return OtpSenderServiceApiFp(configuration).sendSMSCode(request, options)(axios, basePath)
    },
    /**
     *
     * @param {OtpRequestV2} otpRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSMSCodeV2(otpRequestV2: OtpRequestV2, options?: any): AxiosPromise<OtpResponse> {
      return OtpSenderServiceApiFp(configuration).sendSMSCodeV2(otpRequestV2, options)(axios, basePath)
    },
    /**
     * Для отправки СМС с произвольным текстом, используйте <a href=\'https://confluence.mbrd.ru/pages/viewpage.action?pageId=54370722\'>Запрос на формирование уведомления (по шаблону)</a>
     * @param {Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSms(request: Request, options?: any): AxiosPromise<OtpResponse> {
      return OtpSenderServiceApiFp(configuration).sendSms(request, options)(axios, basePath)
    },
  }
}

/**
 * OtpSenderServiceApi - object-oriented interface
 * @export
 * @class OtpSenderServiceApi
 * @extends {BaseAPI}
 */
export class OtpSenderServiceApi extends BaseAPI {
  /**
   *
   * @param {Request} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OtpSenderServiceApi
   */
  public checkSMSCode(request: Request, options?: any) {
    return OtpSenderServiceApiFp(this.configuration).checkSMSCode(request, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {OtpRequestV2} otpRequestV2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OtpSenderServiceApi
   */
  public checkSMSCodeV2(otpRequestV2: OtpRequestV2, options?: any) {
    return OtpSenderServiceApiFp(this.configuration).checkSMSCodeV2(otpRequestV2, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {Request} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OtpSenderServiceApi
   */
  public sendSMSCode(request: Request, options?: any) {
    return OtpSenderServiceApiFp(this.configuration).sendSMSCode(request, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {OtpRequestV2} otpRequestV2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OtpSenderServiceApi
   */
  public sendSMSCodeV2(otpRequestV2: OtpRequestV2, options?: any) {
    return OtpSenderServiceApiFp(this.configuration).sendSMSCodeV2(otpRequestV2, options)(this.axios, this.basePath)
  }

  /**
   * Для отправки СМС с произвольным текстом, используйте <a href=\'https://confluence.mbrd.ru/pages/viewpage.action?pageId=54370722\'>Запрос на формирование уведомления (по шаблону)</a>
   * @param {Request} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OtpSenderServiceApi
   */
  public sendSms(request: Request, options?: any) {
    return OtpSenderServiceApiFp(this.configuration).sendSms(request, options)(this.axios, this.basePath)
  }
}
