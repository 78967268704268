import React, { FC } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Text } from '@mtsbank/ui-kit'
import { POINT } from '@components/DebitCard/constants'
import { MarginLeftAuto } from '@components/Styled/Wrappers'

interface Props {
  croppedPan: string
}

export const Pan: FC<Props> = ({ croppedPan }) => (
  <MarginLeftAuto>{!isEmpty(croppedPan) && <Text size="md">{`${POINT.repeat(2)} ${croppedPan}`}</Text>}</MarginLeftAuto>
)
