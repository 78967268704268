import React, { FC, useEffect, useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { useDispatch, useSelector } from 'react-redux'
import { theme, useDidMount } from '@mtsbank/ui-kit'
import { classNames, StyledAccordion } from '@components/Styled/StyledAccordion'
import { ResendComponent } from '@components/NewMain/ResendComponent'
import { Skeleton } from '@components/NewMain/Skeleton'
import {
  creditsErrorGtm,
  creditsHideClickGtm,
  creditsReloadClickGtm,
  newMainWigetNames,
  showWidgetGtm,
} from '@root/utils/gtm/newMainPageEvents'
import { getConfigWithOffer } from '@root/configs/newMain'
import { selectLoanIssueList, selectDboLoansList, selectDboArrests } from '@selectors/loans'
import { fetchDboPosLimit, fetchLoanIssueList } from '@reducers/credits'
import { fetchDboLoanList } from '@root/reducers/credits/dboLoanList'
import { creditsWidgetClickGtm, seeingCreditsWidgetGtm, showCreditsWidgetGtm } from '@root/utils/gtm/mainPage/events'
import { useBlockInVisionOnce } from '@root/hooks/useBlockInVision'
import { authHelper } from '@root/utils/authHelper/AuthHelper'
import { DboArrestsType } from '@root/reducers/dboLoan/types'
import { RootState } from '@root/store'
import { fetchDboArrests } from '@root/reducers/dboLoan/dboLoan'
import { selectGetOffer } from '@root/selectors/fullApproveWidget'
import { getOffer } from '@root/reducers/fullApproveWidget/fullApproveWidget'
import { ComponentType } from '@root/reducers/fullApproveWidget/types'
import { loanOrderV2Api } from '@root/api/ump/dbo-pos-issue'
import { IncompleteOrderDtoV2 } from '@open-api/ump/dbo-pos-issue'
import { BalanceState } from '../types'
import { CreditList } from './CreditList'
import { product, statuses } from './constants'

type CreditsProps = { isBankClient: boolean } & BalanceState

export const Credits: FC<CreditsProps> = ({ isHiddenBalance, isBankClient }) => {
  const dispatch = useDispatch()
  const { data: arrestsData } = useSelector<RootState, DboArrestsType>(selectDboArrests)
  const { data: offerData } = useSelector(selectGetOffer)
  const {
    data: dboLoans,
    inProgress: isDboLoanListInProgress,
    error: isDboloanListError,
  } = useSelector(selectDboLoansList)

  const { data: loanIssueList } = useSelector(selectLoanIssueList)

  const [opened, setOpened] = useState(true)
  const [unproceedCredits, setUnproceedCredits] = useState<IncompleteOrderDtoV2[]>([])

  const isError = Boolean(isDboloanListError)
  const [issue] = [...(loanIssueList.issueList || [])].sort(
    ({ issueId: aIssueId }, { issueId: bIssueId }) => aIssueId - bIssueId
  )
  const {
    userData: { rbo_id: rboId },
  } = authHelper
  const hasOffer = !isEmpty(offerData)
  const fetchUnproceedCredits = () => {
    loanOrderV2Api
      .fetchIncompleteOrders(statuses, product)
      .then(({ data: fetchedUnproceedCredits }) => setUnproceedCredits(fetchedUnproceedCredits))
  }

  useDidMount(() => {
    if (isEmpty(dboLoans) && !isError && isBankClient && !isDboLoanListInProgress) {
      dispatch(fetchDboLoanList())
      showWidgetGtm(newMainWigetNames.kredity)
    }
  })

  useDidMount(() => {
    if (!isError && isBankClient && !isDboLoanListInProgress) {
      dispatch(fetchLoanIssueList('v3'))
    }
  })

  useDidMount(() => {
    if (rboId && !arrestsData) {
      dispatch(fetchDboArrests(String(rboId)))
    }
  })

  useDidMount(() => {
    if (rboId) {
      fetchUnproceedCredits()
    }
  })

  useDidMount(() => {
    dispatch(fetchDboPosLimit())
  })

  const handleResend = () => {
    creditsReloadClickGtm()
    dispatch(fetchDboLoanList())
    dispatch(fetchLoanIssueList('v3'))
    dispatch(
      getOffer({
        componentType: `${ComponentType.LIMIT},${ComponentType.FANTOM_CARD},${ComponentType.FANTOM_LOAN},${ComponentType.POPUP},${ComponentType.BANNERS}`,
      })
    )

    if (rboId) {
      dispatch(fetchDboArrests(String(rboId)))
      fetchUnproceedCredits()
    }
  }

  const toggleHandler = () => {
    creditsHideClickGtm(opened)

    setOpened((opened) => !opened)
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const { isVisible } = useBlockInVisionOnce(ref)

  useEffect(() => {
    if (isVisible && !isDboLoanListInProgress) {
      seeingCreditsWidgetGtm()
    }
  }, [isDboLoanListInProgress, isVisible])

  useEffect(() => {
    if (!isError && !isDboLoanListInProgress && (issue || dboLoans)) {
      showCreditsWidgetGtm()
    }
  }, [isError, isDboLoanListInProgress, issue, dboLoans])

  const AccordionContent = isError ? (
    <ResendComponent inProgress={isDboLoanListInProgress} onClick={handleResend} />
  ) : (
    <CreditList
      {...{ dboLoans, issue, isHiddenBalance, unproceedCredits }}
      productClearanceButton={getConfigWithOffer(hasOffer)}
    />
  )

  const accordionProps = {
    contentRef: ref,
    onWrapperClick: creditsWidgetClickGtm,
    className: classNames.DISABLE_DIVIDER,
    items: [{ title: 'Кредиты', text: AccordionContent, opened }],
    onToggle: toggleHandler,
  }

  if (isDboLoanListInProgress || (!dboLoans && !isError)) {
    return <Skeleton marginBottom={theme.spacings.xs2} />
  }

  if (isError) {
    creditsErrorGtm()
  }

  return AccordionContent && <StyledAccordion {...accordionProps} />
}
