import React, { ComponentProps, CSSProperties, FC } from 'react';
import { theme, Icon } from '@mtsbank/ui-kit';
import { Styled } from './styled';
type TCustomNotificationProps = {
  icon: {
    padding?: CSSProperties['padding'];
    position?: Extract<CSSProperties['alignSelf'], 'center' | 'end' | 'flex-end' | 'flex-start' | 'start'>;
  } & Pick<ComponentProps<typeof Icon>, 'icon' | 'color' | 'width' | 'height'>;
  background?: CSSProperties['backgroundColor'];
  className?: string;
  onClick?: () => void;
};

/**
 * Кастомизированный компонент Notification
 *
 * @param {Object} props - пропсы компонента
 * @param {Object} props.icon - конфиг для иконки. **С обязательным вложенным аттрибутом icon!**
 * @param {string=} props.background - конфиг для иконки
 * @param {ReactNode} props.children - описание внутри блока
 * @param {Function} props.onClick - вызывает функцию по клику на блок
 */
export const CustomNotification: FC<TCustomNotificationProps> = ({
  icon,
  background = theme.colors.neutral.g700,
  children,
  className,
  onClick
}) => {
  const {
    color = theme.colors.black,
    width = Number(theme.icon_sizes.md),
    height = Number(theme.icon_sizes.md),
    position,
    padding,
    ...iconProps
  } = icon;
  return <Styled.Block background={background} className={className} onClick={onClick}>
      <Styled.IconWrapper alignSelf={position} padding={padding}>
        <Icon color={color} width={width} height={height} {...iconProps} />
      </Styled.IconWrapper>
      <Styled.InfoWrapper>{children}</Styled.InfoWrapper>
    </Styled.Block>;
};