import React, { FC, useCallback, ChangeEvent } from 'react'
import { FieldProps } from 'formik'

import { AutoComplete, AutoCompleteProps } from '@mtsbank/ui-kit'

import { AutoChangeParams, AutocompleteType } from '@components/FormFields/type'
import { BankSuggestion } from '@components/SBP/types'
import { InputErrorMessage } from '@components/InputErrorMessage/InputErrorMessage'

// todo требуется доработать, чтобы можно было тип suggestion определялся выше, сейчас BankSuggestion - для списка банков
export interface AutoCompleteFieldProps extends FieldProps, AutoCompleteProps<BankSuggestion, unknown> {
  autocompleteType?: AutocompleteType
}

export const AutoCompleteField: FC<AutoCompleteFieldProps> = ({
  field,
  form: { setFieldValue, setFieldTouched, errors, touched },
  onChange,
  onBlur,
  inputProps,
  autocompleteType,
  ...rest
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: AutoChangeParams) => {
      if (onChange) {
        onChange(event, value)
      } else if (autocompleteType === AutocompleteType.BANK) {
        setFieldValue(field.name, { label: value.newValue, value: field.value })
        setFieldTouched(field.name, true, false)
      }
    },
    [autocompleteType, field.name, field.value, onChange, setFieldTouched, setFieldValue]
  )

  const customInputProps = {
    ...inputProps,
    onChange: handleChange,
    hasError: touched[field.name] && Boolean(errors[field.name]),
  }

  return (
    <>
      <AutoComplete {...field} {...rest} inputProps={customInputProps} />
      <InputErrorMessage name={field.name} />
    </>
  )
}
