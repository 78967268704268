import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetRefinRestuctInfoResp } from '@open-api/ump/ncpk-ps-gateway'
import { ncpkPsGatewayApi } from '@api/ump/ncpk-ps-gateway'
import { LoanRestructProps } from '@reducers/loanRestruct/types'

const NAMESPACE = 'refinRestruct'

const initialState: LoanRestructProps = {
  data: null,
  inProgress: true,
  error: null,
}

const fetchRestruct = createAsyncThunk(`${NAMESPACE}/fetchRestruct`, async (): Promise<GetRefinRestuctInfoResp> => {
  const { data } = await ncpkPsGatewayApi.getRefinRestuctInfo()

  return data
})

const loanRestruct = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    checkCredit(state, action: PayloadAction<GetRefinRestuctInfoResp>) {
      state.data.loans = state.data.loans.map((loan) => ({ ...loan, checked: loan === action.payload }))
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRestruct.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchRestruct.rejected, (state, action) => {
      state.error = action.error
      state.inProgress = false
    })
    builder.addCase(fetchRestruct.fulfilled, (state, action) => {
      state.data = action.payload
      state.inProgress = false
    })
  },
})

export const { checkCredit } = loanRestruct.actions
export { fetchRestruct }
export default loanRestruct.reducer
