import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { Button, useDidMount } from '@mtsbank/ui-kit'
import { selectGetOffer, selectLimit } from '@root/selectors/fullApproveWidget'
import { authHelper } from '@root/utils/authHelper/AuthHelper'
import { ComponentType } from '@root/reducers/fullApproveWidget/types'
import { FullApproveWidget } from '@root/components/FullApproveWidget/FullApproveWidget'
import { FAOffers } from '@root/components/FullApproveWidget/types'
import { openNewProductCLickGtm } from '@root/utils/gtm/newMainPageEvents'
import { BANK_PRODUCTS_PATH, localStorageFields } from '@root/constants'
import { Cards } from '@components/NewMain/Cards/Cards'
import { MyPhone } from '@components/NewMain/MyPhone/MyPhone'
import { Accounts } from '@components/NewMain/Accounts/Accounts'
import { Credits } from '@components/NewMain/Credits/Credits'
import { Deposits } from '@components/NewMain/Deposits/Deposits'
import { getOffer } from '@reducers/fullApproveWidget/fullApproveWidget'
import { Premium } from '@components/NewMain/Premium/Premium'
import { MoneyForPurchase } from '@components/NewMain/MoneyForPurchase/MoneyForPurchase'

interface ICardsSidebarProps {
  isShowNewProductButton?: boolean
}

export const CardsSidebar: FC<ICardsSidebarProps> = ({ isShowNewProductButton }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const offerLimit = useSelector(selectLimit)
  const { inProgress } = useSelector(selectGetOffer)
  const { isBankClient } = authHelper
  const [isHiddenBalance, setIsHidden] = useState(false)

  useDidMount(() => {
    const isBalanceHiddenStorage: string = window.localStorage.getItem(localStorageFields.isBalanceHidden)

    if (isBalanceHiddenStorage) {
      setIsHidden(Boolean(JSON.parse(isBalanceHiddenStorage)))
    }
  })

  useEffect(() => {
    if (authHelper.isAuthenticated()) {
      dispatch(
        getOffer({
          componentType: `${ComponentType.LIMIT},${ComponentType.FANTOM_CARD},${ComponentType.FANTOM_LOAN},${ComponentType.POPUP},${ComponentType.BANNERS},${ComponentType.MORE}`,
        })
      )
    }
  }, [dispatch])

  const handleClick = useCallback(() => {
    openNewProductCLickGtm()

    router.push(BANK_PRODUCTS_PATH)
  }, [router])

  const fullApproveProps: FAOffers = {
    offerLimit,
    isLoading: inProgress,
    componentType: ComponentType.LIMIT,
  }

  return (
    <>
      <FullApproveWidget {...fullApproveProps} />
      <MyPhone />
      <Premium />
      <Cards isHiddenBalance={isHiddenBalance} />
      {isBankClient && <Accounts isHiddenBalance={isHiddenBalance} />}
      <Credits isHiddenBalance={isHiddenBalance} isBankClient={isBankClient} />
      {/* // ToDo: скрытие виджета в рамках задачи POSC-3811 
      {isBankClient && <MoneyForPurchase />} */}
      {isBankClient && <Deposits isHiddenBalance={isHiddenBalance} />}
      {isShowNewProductButton && (
        <Button fluid size="lg" onClick={handleClick}>
          Открыть новый продукт
        </Button>
      )}
    </>
  )
}
