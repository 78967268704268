import {
  actionGroups,
  eventActions,
  eventCategories,
  eventLabels,
  events,
  EventType,
  screenNames,
  sendGmtEvent,
} from '@utils/gtm/baseFunctions'

export const premiumPotentialProductData: EventType = {
  prCat: 'premium',
  productName: 'premium_package',
  productId: 'premium_package',
}

// Просмотр главной "Премиум"
export const seeingPremiumWidgetGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.premium,
    screenName: screenNames.premiumMain,
    actionGroup: actionGroups.nonInteractions,
  })

// Просмотр привилегии "Премиум"
export const seeingPremiumPrivilegeGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.elementView,
    eventLabel: eventLabels.premium,
    screenName: screenNames.premiumService(serviceId),
    actionGroup: actionGroups.nonInteractions,
  })

// Клик на "Премиальное Обслуживание" виджет "премиум"
export const premiumPackageClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.clickNaPremiumPackage,
    screenName: screenNames.premiumMain,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Номер телефона" виджет "премиум"
export const premiumManagerPhoneClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaPremiumManagerPhone,
    screenName: screenNames.premiumMain,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Адрес эл почты" виджет "премиум"
export const premiumManagerEmailClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaPremiumManagerEmail,
    screenName: screenNames.premiumMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по одной из привилегий виджет "премиум"
export const premiumPrivilegeClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaPremiumPrivilege,
    screenName: screenNames.premiumMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по "Лимиты и комиссии" виджет "премиум"
export const premiumCommissionClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaPremiumCommission,
    screenName: screenNames.premiumMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по "Тарифы пакета Премиум" виджет "премиум"
export const premiumTariffClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaPremiumTariff,
    screenName: screenNames.premiumMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по "назад" страница "привилегия премиум"
export const premiumPrivilegeBackClickGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.nazad,
    screenName: screenNames.premiumService(serviceId),
    actionGroup: actionGroups.interactions,
  })

// Клик по "назад" страница "Лимиты и комиссии" премиум
export const premiumCommissionBackClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.nazad,
    screenName: screenNames.premiumMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по "получить qr код" страница "БЗ премиум"
export const premiumGetQrCodeClickGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPaymentSpa,
    eventCategory: eventCategories.glavnayaVidzhetPremium,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaPremiumQrCode,
    screenName: screenNames.premiumService(serviceId),
    actionGroup: actionGroups.interactions,
  })

// Просмотр страницы "Премиум для потенциальных клиентов"
export const seeingPremiumPotentialPageGtm = () =>
  sendGmtEvent({
    event: events.mtsPageview,
    eventCategory: eventCategories.otkrytiePremiumPotencial,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.premiumPotentialScreen,
    screenName: screenNames.premiumOffer,
    actionGroup: actionGroups.nonInteractions,
    ...premiumPotentialProductData,
  })

// Клик на кнопку "Стать премиум-клиентом" в разделе ПУ Премиум для потенциальных клиентов
export const premiumGetPackageClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPremiumPotencial,
    eventCategory: eventCategories.otkrytiePremiumPotencial,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaGetPackage,
    screenName: screenNames.premiumOffer,
    actionGroup: actionGroups.interactions,
    ...premiumPotentialProductData,
  })

// Клик на привелегию в разделе ПУ Премиум для потенциальных клиентов
export const premiumPotentialPrivilegeClickGtm = (gtmId: string) =>
  sendGmtEvent({
    event: events.mtsPremiumPotencial,
    eventCategory: eventCategories.otkrytiePremiumPotencial,
    eventAction: eventActions.widgetClick,
    eventLabel: eventLabels.clickNaPremiumPrivilege,
    screenName: screenNames.premiumOffer,
    actionGroup: actionGroups.interactions,
    eventContent: gtmId,
    ...premiumPotentialProductData,
  })

// Клик на кнопку "Подключить" внутри виджета привилегии внутри раздела ПУ Премиум для потенциальных клиентов в авторизованной зоне Payment
export const premiumGetPackagePrivilegeClickGtm = (gtmId: string) =>
  sendGmtEvent({
    event: events.mtsPremiumPotencial,
    eventCategory: eventCategories.otkrytiePremiumPotencial,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaGetPackagePrivilege,
    screenName: screenNames.premiumOffer,
    actionGroup: actionGroups.interactions,
    eventContent: gtmId,
    ...premiumPotentialProductData,
  })

// Клик на кнопку "Ознакомьтесь с условиями" в разделе ПУ Премиум для потенциальных клиентов
export const premiumTermsClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPremiumPotencial,
    eventCategory: eventCategories.otkrytiePremiumPotencial,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaPremiumTerms,
    screenName: screenNames.premiumOffer,
    actionGroup: actionGroups.interactions,
    ...premiumPotentialProductData,
  })
