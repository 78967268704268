import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GatewayProtoGetCardListMainRsp } from '@open-api/ump/debit-cards'
import { debitCardsApi } from '@api/ump/debit-cards'

import { baseInitialState } from '@root/fetches/baseInitialState'
import { InitialState } from '@reducers/debitCards/type'

const NAMESPACE = 'cardList'

const initialState: InitialState = {
  ...baseInitialState,
  inProgress: true,
  inProgressResend: false,
}

interface Props {
  isResend?: boolean
}

export const fetchGetCardListMain = createAsyncThunk<GatewayProtoGetCardListMainRsp, Props>(
  `${NAMESPACE}/fetchGetCardList`,
  async () => {
    const response = await debitCardsApi.getCardListMain('WEB')

    return response.data
  }
)

const cardList = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setInProgressResend(state, action) {
      state.inProgressResend = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCardListMain.pending, (state, action) => {
      state.data = {}
      state.inProgress = !action.meta.arg.isResend
      state.error = !action.meta.arg.isResend ? null : state.error
      state.inProgressResend = action.meta.arg.isResend
    })
    builder.addCase(fetchGetCardListMain.rejected, (state, action) => {
      state.error = action.error
      state.inProgress = false
      state.inProgressResend = false
    })
    builder.addCase(fetchGetCardListMain.fulfilled, (state, action) => {
      state.data = { ...action.payload }
      state.inProgress = false
      state.inProgressResend = false
      state.error = null
    })
  },
})

export const { setInProgressResend } = cardList.actions
export const cardListReducer = cardList.reducer
