import qrCode from '@asserts/images/gos/qrCode.svg'
import mobPhones from '@asserts/images/gos/mobPhones.png'
import { AUTH_PATH } from '@root/constants'

export const config = {
  hatBanner: {
    header: 'Госуслуги не выходя из\u00A0дома',
    description: 'Быстро зарегистрируйте или подтвердите учётную запись на\u00A0Госуслугах через онлайн\u2011банк',
    background: { src: '/spa/static/images/backgroundGOS.jpg', alt: 'Фон' },
    button: {
      href: AUTH_PATH,
      text: 'Войти',
    },
  },
  statusConfirmation: {
    header: 'Что необходимо для\u00A0подтверждения статуса на\u00A0Госуслугах?',
    necessaryItems: [
      { id: 1, text: 'Быть клиентом МТС Банка' },
      { id: 2, text: 'СНИЛС' },
      { id: 3, text: 'Войти в личный кабинет МТС Деньги' },
      // todo как приложение будет готово, добавить к строке с id 3: " или воспользоваться приложением"
    ],
    blockWithImages: {
      text: 'Простая и удобная регистрация, надежные и безопасные переводы',
      qrCode: { src: qrCode, alt: 'QR-код' },
      appStore: {
        icon: 'mobileApp/appStore123x40',
        href: 'https://redirect.appmetrica.yandex.com/serve/171382091404111410',
      },
      googlePlay: {
        icon: 'mobileApp/googlePlay123x40',
        href: 'https://redirect.appmetrica.yandex.com/serve/99324499714926841',
      },
      mobPhones: { src: mobPhones, alt: 'Скриншот' },
    },
  },
  questionsAndAnswers: {
    header: 'Вопросы и ответы',
    items: [
      {
        title: 'Почему вход в личный кабинет на сайте МТС Денег, а не МТС Банка?',
        title4gtm: 'pochemu_vhod_v_lichnyi_kabinet_na_saite_mts_deneg_a_ne_mts_banka',
        text: 'Мы объединяем онлайн-банк (личный кабинет МТС Банка на {link href="https://www.mtsbank.ru/" target="_blank"}mtsbank.ru{/link}) и личный кабинет на сайте МТС Деньги.\n Для клиентов банка в личном кабинете сайта МТС Деньги доступны все продукты и сервисы, в том числе регистрация или подтверждение учётной записи на Госуслугах.',
        isOpened: false,
      },
      {
        title: 'Я не являюсь клиентом МТС Банка, могу ли я здесь зарегистрироваться на Госуслугах?',
        title4gtm: 'ya_ne_yavlyaus_klientom_mts_banka_mogu_li_ya_zdes_zaregistrirovatsya_na_gosuslugah',
        text: 'Зарегистрироваться могут только клиенты, так как банк уже подтвердил их личность. Посмотреть все банковские продукты и сервисы и стать клиентом можно на сайте {link href="https://www.mtsbank.ru/" target="_blank"}mtsbank.ru{/link}',
        isOpened: false,
      },
    ],
  },
}
