import { Dictionary } from '@reduxjs/toolkit'
import { actionGroups, eventActions, eventCategories, eventLabels } from '../baseFunctions'
import { ConfigParamsByEvent, DataLayerEvents, OfferProducGtm } from './types'
import { MainEvenCategories } from './constants'

export const configParamsByEvent: ConfigParamsByEvent = {
  [DataLayerEvents.BANNER_CAROUSEL_SHOW]: {
    eventAction: eventActions.bannerShow,
    actionGroup: actionGroups.nonInteractions,
    eventCategory: eventCategories.glavnayaPaymentsAz,
  },
  [DataLayerEvents.BANNER_CAROUSEL_CLICK]: {
    eventAction: eventActions.bannerClick,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.glavnayaPaymentsAz,
  },
  [DataLayerEvents.BANNER_CAROUSEL_CLOSE_CLICK]: {
    eventAction: eventActions.bannerClose,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.glavnayaPaymentsAz,
  },
  [DataLayerEvents.BANNER_CAROUSEL_SEEING]: {
    eventAction: eventActions.bannerView,
    actionGroup: actionGroups.nonInteractions,
    eventCategory: eventCategories.glavnayaPaymentsAz,
  },
  [DataLayerEvents.LIMIT_FA_SHOW]: {
    eventAction: eventActions.widgetShow,
    eventLabel: eventLabels.mneOdobreno,
    actionGroup: actionGroups.nonInteractions,
    eventCategory: eventCategories.glavnayaPaymentsAz,
  },
  [DataLayerEvents.LIMIT_FA_SEEING]: {
    eventAction: eventActions.widgetView,
    eventLabel: eventLabels.mneOdobreno,
    actionGroup: actionGroups.nonInteractions,
    eventCategory: eventCategories.glavnayaPaymentsAz,
  },
  [DataLayerEvents.LIMIT_FA_CLICK]: {
    eventAction: eventActions.widgetClick,
    eventLabel: eventLabels.mneOdobreno,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.glavnayaPaymentsAz,
  },
  [DataLayerEvents.FANTOM_PRODUCT_SHOW]: {
    eventAction: eventActions.stikerShow,
    eventLabel: eventLabels.fantomnyiProdukt,
    actionGroup: actionGroups.nonInteractions,
  },
  [DataLayerEvents.FANTOM_PRODUCT_SEEING]: {
    eventAction: eventActions.stikerView,
    eventLabel: eventLabels.fantomnyiProdukt,
    actionGroup: actionGroups.nonInteractions,
  },
  [DataLayerEvents.FANTOM_PRODUCT_CLICK]: {
    eventAction: eventActions.stikerClick,
    eventLabel: eventLabels.fantomnyiProdukt,
    actionGroup: actionGroups.interactions,
  },
  [DataLayerEvents.FANTOM_PRODUCT_CLOSE_CLICK]: {
    eventAction: eventActions.stikerClose,
    eventLabel: eventLabels.fantomnyiProdukt,
    actionGroup: actionGroups.interactions,
  },
}

export const configParamsByProduct: Record<OfferProducGtm, Dictionary<string>> = {
  [OfferProducGtm.NCPK]: {
    eventCategory: MainEvenCategories.widgetNcpk,
  },
  [OfferProducGtm.CC]: {
    eventCategory: MainEvenCategories.widgetCc,
  },
  [OfferProducGtm.DEFAULT]: {
    eventCategory: eventCategories.glavnayaPaymentsAz,
  },
}
