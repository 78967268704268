// todo разбить константы по сущностям, в разные файлы
import { paymentHost } from '@api/dot-net/api'

const MAIN_PATH = '/' as const
const SPA = `${MAIN_PATH}spa` as const
const GOSUSLUGI_PATH = `${MAIN_PATH}gosuslugi` as const
const ONBOARDING_PATH = `${MAIN_PATH}onboarding` as const
const CREDIT_HOLIDAYS_PATH = `${MAIN_PATH}credit-holidays` as const
const CREDIT_HOLIDAYS_FORM_PATH = `${MAIN_PATH}credit-holidays/forms` as const
const LOAN_RESTRUCT_PATH = `${MAIN_PATH}restrukturizatsiya-kredita` as const
const LOANS_PATH = `${paymentHost}${MAIN_PATH}loans` as const
const LOAN_PATH = `${MAIN_PATH}loans/:loanId` as const
const SPA_EARLY_REPAYMENT_PATH = `${SPA}${LOAN_PATH}/early-repayment/:repaymentType/create` as const
const LOAN_ISSUE_PATH = `${MAIN_PATH}loans/issue` as const
const LOAN_ISSUE_LOAN_PATH = `${MAIN_PATH}loans/issue/:requestId` as const
const LOAN_FA_PATH = `${MAIN_PATH}loans/fa` as const
const LIMITS_PATH = `${MAIN_PATH}limits`
const SCHEDULE_PATH = `${LOAN_PATH}/schedule` as const
const SPA_SCHEDULE_PATH = `${SPA}${LOAN_PATH}/schedule` as const
const ARREST_PATH = `${LOAN_PATH}/arrest` as const
const EXPIRED_PATH = `${LOAN_PATH}/expired` as const
const FINAL_REQUIREMENT_PATH = `${LOAN_PATH}/final-requirement` as const
const EXPIRED_DEBT_PATH = `${LOAN_PATH}/expired-debt` as const
const SERVICE_PATH = `${LOAN_PATH}/services/:serviceCode` as const
const REFERENCES_PATH = `${MAIN_PATH}references` as const
const POS_PATH = `${MAIN_PATH}pos` as const
const PURCHASE_LIMIT = `purchase-limit` as const
const SPA_POS_PATH = `${SPA}${POS_PATH}` as const
const SPECIFIC_REFERENCE_PATH = `${REFERENCES_PATH}/:referenceType`
const GOSUSLUGI_INFO_PATH = `${MAIN_PATH}gosuslugi-info` as const
const EARLY_REPAYMENT_PATH = `${LOAN_PATH}/early-repayment/:repaymentType/create` as const
const REFILL_PATH = `${LOAN_PATH}/refill` as const
const AUTH_PATH = `${paymentHost}/Auth/SignIn/` as const
const LOGOUT_PATH = `${paymentHost}/Auth/SignOut` as const
const HISTORY_OPERATIONS_PATH = `${MAIN_PATH}operations` as const
const HISTORY_OPERATION_PATH = `${HISTORY_OPERATIONS_PATH}/:operationId` as const
const HISTORY_OPERATION_RECEIPT_PATH = `${HISTORY_OPERATION_PATH}/receipt` as const
const PASSPORT_UPDATE_PATH = `/passport-update` as const
const PAY = 'pay' as const
const SPA_PAY_URL = `${SPA}/${PAY}/` as const
const SPA_PAY_URL_RESULT = `${SPA}/${PAY}/result/:mdOrder` as const
const PAY_PHONE_PATH = `${MAIN_PATH}pay/phone` as const
const PAY_SERVICE_PATH = `${MAIN_PATH}pay/[id]` as const
const PAY_LOAN_PATH = `${MAIN_PATH}pay/loan` as const
const PERSONAL_OFFER_PATH = `${MAIN_PATH}personal_offer` as const
const WALLET_INFO = `${LIMITS_PATH}/wallet-info` as const
const PHONE_BILL = `${LIMITS_PATH}/phone-bill` as const
const TRANSFER_CARD_TO_CARD = `${LIMITS_PATH}/transfer-card-to-card` as const
const REPLENISHMENT_WALLET_ACCOUNT = `${LIMITS_PATH}/replenishment-wallet-account` as const
const NCPK = 'ncpk' as const
const NCPK_PATH = `${MAIN_PATH}${NCPK}` as const
const NCPK_PATH_V1 = `${MAIN_PATH}${NCPK}/v1` as const
const NCPK_PATH_V2 = `${MAIN_PATH}${NCPK}/v2` as const
const SPA_NCPK_PATH = `${SPA}/${NCPK}` as const
const SPA_NCPK_PATH_V1 = `${SPA}/${NCPK}/v1` as const
const SPA_NCPK_PATH_V2 = `${SPA}/${NCPK}/v2` as const
const ACCOUNT_OPENING_PATH = `${MAIN_PATH}accountsopen` as const
const PROMOTION_APPLY = `${MAIN_PATH}cards/promotion-apply` as const
const PARTNERS = `${MAIN_PATH}partners` as const
const TEMPLATE = 'template' as const
const TRANSFER = 'transfer' as const
const PAYMENT = 'payment' as const
const FREE_CONNECTION_PATH = `${MAIN_PATH}free-connection` as const
const FREE_CONNECTION_CARD_ID_PATH = `${MAIN_PATH}free-connection/[cardId]` as const
const FREE_CONNECTION_CARD_ID_COUNTER_PATH = `${MAIN_PATH}free-connection/[cardId]/counter` as const
const WAGE_BENEFITS_PATH = `${MAIN_PATH}wage/benefits` as const
const WAGE_HASH_PAN_ID = `${MAIN_PATH}wage/[id]` as const
const AGREEMENT_LOAN_USER = `${LOAN_PATH}/docs` as const

const CATEGORIES_PATH = `${MAIN_PATH}categories` as const
const BANK_PRODUCTS_PATH = `${MAIN_PATH}bank-products` as const
const MAIN_OFFERS_PATH = `${MAIN_PATH}offers` as const
const BANK_PRODUCT_DETAILS_PATH = `${BANK_PRODUCTS_PATH}/details/:productType` as const
const BANK_PRODUCT_DETAILS_LOAN = `${BANK_PRODUCTS_PATH}/details/loan` as const
const CARDS_PATH = `${MAIN_PATH}cards` as const
const CARDS_OPEN_PATH = `${CARDS_PATH}/open` as const
const CARDS_REQUEST_SIGNER = `${CARDS_PATH}/signDocument` as const
const CARDS_ADD_PATH = `${CARDS_PATH}/addissue` as const
const TEMPLATE_PAYMENT_PATH = `${MAIN_PATH}${TEMPLATE}/${PAYMENT}` as const
const TEMPLATE_TRANSFER_PATH = `${MAIN_PATH}${TEMPLATE}/${TRANSFER}` as const
const AUTOPAYMENT_PATH = `${MAIN_PATH}autopayment` as const
const SUBSCRIPTION_PATH = `${MAIN_PATH}subscription` as const
const ADD_CARD_PATH = `${paymentHost}${CARDS_PATH}/add` as const
const CARD_DISCOUNT_INFO_PATH = `${CARDS_PATH}/:hashPan/discount` as const

const OFFERS_PATH = `https://www.mtsbank.ru/offers` as const
const SBP_PATH = `${MAIN_PATH}transfer/sbp` as const
const SPA_SBP_PATH = `${SPA}/transfer/sbp` as const
const SBP_RESULT_PATH = `${MAIN_PATH}transfer/sbp/result` as const
const SPA_SBP_RESULT_PATH = `${SPA}/transfer/sbp/result` as const
const CARD_TO_CARD = 'CardToCard' as const
const ME_TO_ME = 'me2me' as const
const RESULT = 'result' as const
const ABROAD = 'abroad' as const
const PAY_CARD_TO_CARD = `${TRANSFER}/${CARD_TO_CARD}` as const
const PAY_CARD_TO_CARD_RESULT = `${SPA}/${TRANSFER}/${CARD_TO_CARD}/${RESULT}/:mdOrder` as const
const PAY_ME_TO_ME = `${TRANSFER}/${ME_TO_ME}` as const
const PAY_ME_TO_ME_RESULT = `${SPA}/${TRANSFER}/${ME_TO_ME}/${RESULT}/:mdOrder` as const
const PAY_ABROAD_RESULT = `${SPA}/${TRANSFER}/${ABROAD}/${RESULT}/:mdOrder` as const
const OFFICES_AND_ATMS = 'https://www.mtsbank.ru/ofisi-i-bankomati/'

// TODO требуется доработка RouteGuard чтобы он начал отрабатывать кейсы где :param может быть [param], [...param] и [[...param]]
// TODO требуется убрать
const DEPOSITS_OPEN_PATH = `${MAIN_PATH}deposits/open/:step`
const DEPOSIT_PATH = `${MAIN_PATH}deposits/:depositId` as const
const DEPOSIT_CONDITIONS_PATH = `${DEPOSIT_PATH}/conditions` as const
const DEPOSIT_DETAILS_PATH = `${DEPOSIT_PATH}/details` as const
const DEPOSIT_CLOSE_PATH = `${DEPOSIT_PATH}/close` as const
const DEPOSIT_REFERENCES_PATH = `${DEPOSIT_PATH}/references` as const
const DEPOSIT_REFERENCES_WITH_STEP_PATH = `${DEPOSIT_REFERENCES_PATH}/:step` as const

const DEPOSIT_CLOSE_WITH_STEP_PATH = `${DEPOSIT_CLOSE_PATH}/:step` as const
const DEPOSITS_OPEN_PATH_FOR_AUTH = `${MAIN_PATH}deposits/open/[[...step]]`

const EXCLUDED_NAVBAR_PAGES: string[] = [
  ONBOARDING_PATH,
  PAY_PHONE_PATH,
  PAY_SERVICE_PATH,
  CARDS_REQUEST_SIGNER,
  CARDS_OPEN_PATH,
  LOAN_FA_PATH,
  NCPK_PATH,
  LOAN_ISSUE_PATH,
  LOAN_ISSUE_LOAN_PATH,
]

const MILLISECONDS_PER_DAY = 86400000 // 24 * 60 * 60 * 1000

const ONBOARDING_VERSION = '1.0'

const ONBOARDING_API_NAME = 'pl-onboarding'

const STATIC_HOST_ORIGIN = 'https://staticpayment.ssl.mts.ru'

const CASHBACK_PATH = 'https://cashback.mts.ru'
const CASHBACK_REDIRECT = `https://login.mts.ru/amserver/oauth2/sso?at=:token&redirect_uri=${CASHBACK_PATH}`
const TRANSFER_GBI_SERVICE = 'js/fhp.js'
const MTSDENGI_APK_PATH = 'https://redirect.appmetrica.yandex.com/serve/893510404041385185?utm_source=button_install'
const SMS_DELIVERY_PATH = `${CARDS_PATH}/dst/[applicationid]` as const
const CARD_REQUEST_SIGNER_MLC_PATH = `${CARDS_PATH}/signDoc/[signID]` as const
const CAR_VALUATION_PATH = `/car-valuetion` as const
const LIMIT_PLUS_PATH = `${CARDS_PATH}/virtual-card-id-scan` as const

const PREMIUM = 'premium'
const PREMIUM_PATH = `${MAIN_PATH}${PREMIUM}`
const SPA_PREMIUM_PATH = `${SPA}/${PREMIUM}`
const PREMIUM_SERVICES = `${MAIN_PATH}${PREMIUM}/[page]`
const OFFER_PREMIUM = `${MAIN_PATH}${PREMIUM}/offer-premium`

const CONSENT_PROCESS_PERSONAL_DATA = 'https://www.mtsbank.ru/upload/static/soglashenie_ob_obrabotke_pd.htm'

const CATEGORY_PATH = `${MAIN_PATH}categories/:id` as const

const SERVICE_LIST_PATH = `/card/service-list` as const
const SUBSCRIPTION_FINTECH = `${CARDS_PATH}/subscription-fintech`

const localStorageFields = {
  isBalanceHidden: 'isBalanceHidden',
} as const

const CC_LOYALTY_APPLICATION_SIGNING = `${CARDS_PATH}/signLimitDocument`
const SPA_CREDIT_CARMA = `${MAIN_PATH}kreditnaya-karma`

export {
  SPA,
  ARREST_PATH,
  SCHEDULE_PATH,
  SPA_SCHEDULE_PATH,
  EXPIRED_PATH,
  FINAL_REQUIREMENT_PATH,
  SERVICE_PATH,
  EARLY_REPAYMENT_PATH,
  MAIN_PATH,
  LOANS_PATH,
  LOAN_PATH,
  SPA_EARLY_REPAYMENT_PATH,
  PAY_LOAN_PATH,
  LOAN_ISSUE_PATH,
  LOAN_ISSUE_LOAN_PATH,
  LOAN_FA_PATH,
  REFILL_PATH,
  STATIC_HOST_ORIGIN,
  GOSUSLUGI_INFO_PATH,
  GOSUSLUGI_PATH,
  ONBOARDING_PATH,
  REFERENCES_PATH,
  POS_PATH,
  PURCHASE_LIMIT,
  SPA_POS_PATH,
  SPECIFIC_REFERENCE_PATH,
  CREDIT_HOLIDAYS_PATH,
  CREDIT_HOLIDAYS_FORM_PATH,
  LOAN_RESTRUCT_PATH,
  AUTH_PATH,
  LOGOUT_PATH,
  EXCLUDED_NAVBAR_PAGES,
  SBP_PATH,
  SPA_SBP_PATH,
  SBP_RESULT_PATH,
  SPA_SBP_RESULT_PATH,
  HISTORY_OPERATIONS_PATH,
  HISTORY_OPERATION_PATH,
  HISTORY_OPERATION_RECEIPT_PATH,
  MILLISECONDS_PER_DAY,
  ONBOARDING_VERSION,
  ONBOARDING_API_NAME,
  localStorageFields,
  PAY,
  LIMITS_PATH,
  WALLET_INFO,
  PHONE_BILL,
  TRANSFER_CARD_TO_CARD,
  REPLENISHMENT_WALLET_ACCOUNT,
  NCPK_PATH,
  NCPK_PATH_V1,
  NCPK_PATH_V2,
  ADD_CARD_PATH,
  SPA_PAY_URL_RESULT,
  PAY_CARD_TO_CARD,
  PAY_CARD_TO_CARD_RESULT,
  PAY_ME_TO_ME,
  PAY_ME_TO_ME_RESULT,
  PERSONAL_OFFER_PATH,
  RESULT,
  TRANSFER_GBI_SERVICE,
  SPA_PAY_URL,
  CASHBACK_REDIRECT,
  CASHBACK_PATH,
  SPA_NCPK_PATH,
  SPA_NCPK_PATH_V1,
  SPA_NCPK_PATH_V2,
  ACCOUNT_OPENING_PATH,
  OFFERS_PATH,
  TEMPLATE_PAYMENT_PATH,
  TEMPLATE_TRANSFER_PATH,
  AUTOPAYMENT_PATH,
  SUBSCRIPTION_PATH,
  CARDS_PATH,
  CATEGORIES_PATH,
  BANK_PRODUCTS_PATH,
  BANK_PRODUCT_DETAILS_PATH,
  MAIN_OFFERS_PATH,
  BANK_PRODUCT_DETAILS_LOAN,
  PAY_ABROAD_RESULT,
  MTSDENGI_APK_PATH,
  CARDS_OPEN_PATH,
  CARDS_ADD_PATH,
  PROMOTION_APPLY,
  SMS_DELIVERY_PATH,
  CARDS_REQUEST_SIGNER,
  FREE_CONNECTION_PATH,
  FREE_CONNECTION_CARD_ID_PATH,
  FREE_CONNECTION_CARD_ID_COUNTER_PATH,
  CARD_DISCOUNT_INFO_PATH,
  DEPOSITS_OPEN_PATH,
  DEPOSITS_OPEN_PATH_FOR_AUTH,
  OFFICES_AND_ATMS,
  DEPOSIT_PATH,
  DEPOSIT_CLOSE_PATH,
  DEPOSIT_CLOSE_WITH_STEP_PATH,
  DEPOSIT_REFERENCES_PATH,
  DEPOSIT_REFERENCES_WITH_STEP_PATH,
  DEPOSIT_CONDITIONS_PATH,
  DEPOSIT_DETAILS_PATH,
  PARTNERS,
  WAGE_HASH_PAN_ID,
  PASSPORT_UPDATE_PATH,
  PREMIUM_PATH,
  SPA_PREMIUM_PATH,
  WAGE_BENEFITS_PATH,
  PREMIUM_SERVICES,
  LIMIT_PLUS_PATH,
  CARD_REQUEST_SIGNER_MLC_PATH,
  CONSENT_PROCESS_PERSONAL_DATA,
  EXPIRED_DEBT_PATH,
  CATEGORY_PATH,
  AGREEMENT_LOAN_USER,
  CC_LOYALTY_APPLICATION_SIGNING,
  SERVICE_LIST_PATH,
  SUBSCRIPTION_FINTECH,
  SPA_CREDIT_CARMA,
  CAR_VALUATION_PATH,
  OFFER_PREMIUM,
}

export enum EGibTypes {
  PAYMENT = 'PAYMENT',
  NCPK = 'NCPK',
  TRANSFER = 'TRANSFER',
}

export const additionalTheme = {
  spacings: {
    md: 36,
  },
}
