import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { window } from '@mtsbank/ui-kit'

import { authHelper } from '@root/utils/authHelper/AuthHelper'
import { routes } from '@root/routes'
import { AUTH_PATH } from '@root/constants'
import { Error403 } from '@root/old_pages/Error/Error403'
import { RouteProps } from '@root/types/route'

const convertedRoutes: { [key: string]: RouteProps } = routes.reduce((acc, value) => {
  const path = value.path?.replace(/:(\w+)(?=)/g, '[$1]')

  return { ...acc, [path]: value }
}, {})

export const RouteGuard = ({ children }) => {
  const router = useRouter()
  const [needAuth, setNeedAuth] = useState(false)
  const path = router.pathname

  useEffect(() => {
    const authNeedCheck = () => {
      if (convertedRoutes[path]?.authType) {
        setNeedAuth(true)

        if (!authHelper.isAuthenticated()) {
          authHelper.onSignIn()
          window.location.href = AUTH_PATH
        }
      } else {
        setNeedAuth(false)
      }
    }

    authNeedCheck()

    router.events.on('routeChangeComplete', authNeedCheck)

    return () => {
      router.events.off('routeChangeComplete', authNeedCheck)
    }
  }, [path, router.events])

  if (!needAuth) {
    return children
  }

  if (authHelper.isAuthenticated() && convertedRoutes[path]) {
    const { accessInfo, authType } = convertedRoutes[path]

    return authHelper.hasAccess(authType, authHelper.profileType) ? children : <Error403 accessInfo={accessInfo} />
  }
  if (authHelper.isAuthenticated() && !convertedRoutes[path]) {
    // todo временное решение чтоб пофиксить быстро краш страницы из-за convertedRoutes[path] - undefined
    // целевое надо править логику урлов вместе с convertedRoutes
    return null
  }

  return null
}
