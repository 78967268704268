import { SerializedError } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

export interface BaseState<T, E = SerializedError> {
  inProgress: boolean
  data?: T
  error?: E
}

export interface Params {
  isResent?: boolean
}

export const isAxiosError = <T>(error: unknown): error is AxiosError<T> => axios.isAxiosError(error)
