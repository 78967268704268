import { ButtonProps as ButtonPropsKit } from '@mtsbank/ui-kit/build/components/atoms/Button/Button'

export interface PropsButton extends ButtonPropsKit {
  text: string
}
export type AntifrodeErrorCodes = 1109 | 1110 | 3011 | 3010 | 3012
export type Errors = { [key in AntifrodeErrorCodes]: { title: Titles; buttons: [PropsButton, PropsButton?] } }
export type ButtonsConfig = { [key: string]: PropsButton }

export enum Titles {
  TEMPORARILY_UNAVAILABLE = 'Перевод временно недоступен',
  BLOCKED_ACCOUNT = 'Учетная запись заблокирована',
  SBERBANK_BANNED = 'Сбербанк запретил перевод',
}
