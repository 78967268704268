import { document } from '@mtsbank/ui-kit'
import { actionGroups, eventActions, eventCategories, eventLabels, events, sendGmtEvent } from '../baseFunctions'

// Клик на "Частным клиентам" в левом верхнем углу
export const privateClientsCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPaymentsMenu,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaChastnimKlientam,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// хз почему нет на реакте этой кнопки, есть только на .нет
// Клик на "Бизнесу" в левом верхнем углу
// sendGmtEvent({
//   event: events.mtsCross,
//   eventCategory: eventCategories.glavnayaPaymentsMenu,
//   eventAction: eventActions.buttonClick,
//   eventLabel: eventLabels.clickNaBisnesu,
//   screenName: document.location.pathname,
//   actionGroup: actionGroups.interactions,
// })

// Клик на "Все сайты" в левом верхнем углу
export const allSitesCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPaymentsMenu,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaVseSaity,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Бизнесу" в выпадающем меню "Все сайты"
export const allSitesBusinessCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPaymentsVypodauscheeMenu,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaBisnesu,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Госзаказчикам" в выпадающем меню "Все сайты"
export const allSitesGovernmentCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPaymentsVypodauscheeMenu,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaGoszakazchikam,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на "О компании" в выпадающем меню "Все сайты"
export const allSitesAboutCompanyCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPaymentsVypodauscheeMenu,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaOKompanii,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Поставщикам и партнерам" в выпадающем меню "Все сайты"
export const allSitesPartnersCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPaymentsVypodauscheeMenu,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaPostavschkam,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на "МТС/Медиа" в выпадающем меню "Все сайты"
export const allSitesMTSMediaCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPaymentsVypodauscheeMenu,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaMtsMedia,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на "Investor Relations" в выпадающем меню "Все сайты"
export const allSitesInvestorsCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPaymentsVypodauscheeMenu,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaInvestorRelations,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на логотип "🥚 МТС Банк" в строке меню
export const logoCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPayments,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaMtsBankLogo,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })

// Клик на кнопку "Главная" в строке меню
export const linkToMainCLickGtm = () =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: eventCategories.glavnayaPayments,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.clickNaMtsGlavnaya,
    screenName: document.location.pathname,
    actionGroup: actionGroups.interactions,
  })
