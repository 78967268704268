import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { paymentApi } from '@api/dot-net/api'

import { baseInitialState } from '@root/fetches/baseInitialState'
import { InitialState } from '@reducers/payment/type'

const NAMESPACE = 'payment'

const initialState: InitialState = {
  paymentRecommendation: { ...baseInitialState },
  cashbackBalance: { ...baseInitialState },
  mnc: { ...baseInitialState },
}

interface Props {
  phoneNum: string
}

export const getPaymentRecommendation = createAsyncThunk(
  `${NAMESPACE}/paymentRecommendation`,
  async ({ phoneNum }: Props) => {
    const result = await paymentApi.apiPaymentRecomendationGet(phoneNum)

    return result.data
  }
)

export const getCashbackBalance = createAsyncThunk(`${NAMESPACE}/cashBackBalance`, async () => {
  const result = await paymentApi.apiPaymentCashbakBalanceGet()

  return result.data
})

export const getPaymentMnc = createAsyncThunk(`${NAMESPACE}/mnc`, async ({ phoneNum }: Props) => {
  const result = await paymentApi.apiPaymentMncGet(phoneNum)

  return result.data
})

const payment = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentRecommendation.pending, (state) => {
      state.paymentRecommendation.error = null
      state.paymentRecommendation.inProgress = true
    })
    builder.addCase(getPaymentRecommendation.rejected, (state, action) => {
      state.paymentRecommendation.error = action.error
      state.paymentRecommendation.inProgress = false
    })
    builder.addCase(getPaymentRecommendation.fulfilled, (state, action) => {
      state.paymentRecommendation.data = action.payload
      state.paymentRecommendation.inProgress = false
    })

    builder.addCase(getCashbackBalance.pending, (state) => {
      state.cashbackBalance.error = null
      state.cashbackBalance.inProgress = true
    })
    builder.addCase(getCashbackBalance.rejected, (state, action) => {
      state.cashbackBalance.error = action.error
      state.cashbackBalance.inProgress = false
    })
    builder.addCase(getCashbackBalance.fulfilled, (state, action) => {
      state.cashbackBalance.data = action.payload
      state.cashbackBalance.inProgress = false
    })

    builder.addCase(getPaymentMnc.pending, (state) => {
      state.mnc.error = null
      state.mnc.inProgress = true
    })
    builder.addCase(getPaymentMnc.rejected, (state, action) => {
      state.mnc.error = action.error
      state.mnc.inProgress = false
    })
    builder.addCase(getPaymentMnc.fulfilled, (state, action) => {
      state.mnc.data = action.payload
      state.mnc.inProgress = false
    })
  },
})

export const paymentReducer = payment.reducer
