import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr'
import { AxiosResponse } from 'axios'
import { ErrorResponse } from '@open-api/ump/early-repayment'
import { GetFullInfoRes } from '@open-api/ump/ncpk-ps-gateway'
import { GatewayProtoErrorRsp } from '@open-api/ump/debit-cards'
import { BindingRs } from '@open-api/ump/ewallet-profile'
import {
  GetLoansListResponse,
  GetTransferTermsRequest,
  GetTransferTermsResponse,
  LoanInfo,
  OperationResponse,
  ServiceInfo,
  TransferRequest,
} from '@generated/api'
import { ncpkPsGatewayApi } from '@api/ump/ncpk-ps-gateway'
import { debitCardsCardDetailsApi } from '@api/ump/debit-cards-card-details'

import {
  GetFullInfoReq,
  BindingsRequest,
  ProcessingState,
  Tsps,
  PromiseSettledResult,
} from '@reducers/processing/types'
import { aftApi, processingApi } from '@api/dot-net/api'
import { isAxiosError } from '@root/types/reducers'
import { TspId } from '@reducers/recommendations/types'
import { ewalletProfileApi } from '@api/ump/ewallet-profile'

const NAMESPACE = 'processing'

const initialState: ProcessingState = {
  loans: {
    data: {},
    inProgress: true,
    error: null,
  },
  fullInfoLoan: {
    data: null,
    inProgress: true,
    error: null,
  },
  bindings: {
    data: [],
    inProgress: true,
    error: null,
  },
  transfer: {
    inProgress: false,
    data: {},
    error: null,
  },
  refill: {
    isEnabled: false,
  },
  servicesById: {
    inProgress: false,
    data: null,
    error: null,
  },
}

const fetchLoans = createAsyncThunk(`${NAMESPACE}/fetchLoans`, async (): Promise<GetLoansListResponse> => {
  const response = await processingApi.apiProcessingGetLoansListGet()

  return response.data
})

const fetchFullInfoLoan = createAsyncThunk<GetFullInfoRes, GetFullInfoReq>(
  `${NAMESPACE}/fetchFullInfoLoan`,
  async ({ id }) => {
    const response = await ncpkPsGatewayApi.getFullInfo(id)

    return response.data
  }
)

const fetchBindings = createAsyncThunk<
  BindingRs,
  BindingsRequest & { options?: object },
  { rejectValue: ErrorResponse }
>(
  `${NAMESPACE}/fetchBindings`,
  async ({ filter = () => true, withBalance = true, options, ...params }, { rejectWithValue }) => {
    try {
      const response = await ewalletProfileApi.getBindings1(params, options)
      const filteredBindings = response.data?.bindings.filter(filter)

      if (!withBalance) {
        return { bindings: filteredBindings }
      }

      const { data: aft } = await aftApi.apiAftGet()
      const bindingsWithBalancePromises = filteredBindings.map((binding) =>
        fetchBindingWithBalance(binding.bindingId, aft).then((balance) => ({
          ...binding,
          balance: Number.isNaN(balance) ? undefined : balance,
        }))
      )

      return await Promise.all(bindingsWithBalancePromises).then((bindingsWithBalance) => ({
        bindings: bindingsWithBalance,
      }))
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const fetchBindingWithBalance = async (bindingId: string, aft: string) => {
  try {
    const response = await processingApi.apiProcessingGetProductBindingWithBalanceGet(bindingId, {
      headers: { RequestVerificationToken: aft },
    })

    return Number(response.data.result.balance)
  } catch (error) {
    return NaN
  }
}

export const fetchCardAccountDetails = async (cardId: string) => {
  const platform = 'ANDROID'
  const response = await debitCardsCardDetailsApi.cardAccountDetails(
    'v1',
    { cardId },
    { headers: { Platform: platform } }
  )

  return response.data
}

// todo перегенерировать api, исключить параметр phone
const getTransferTerms = createAsyncThunk<GetTransferTermsResponse, { transferRequest: GetTransferTermsRequest }>(
  `${NAMESPACE}/getTransfer`,
  async ({ transferRequest }) => {
    const response = await processingApi.apiProcessingGetTransferTermsPost({ ...transferRequest })

    return response.data
  }
)

// todo перегенерировать api, исключить параметр phone
const makeTransfer = createAsyncThunk(
  `${NAMESPACE}/transfer`,
  async ({ transferRequest }: { transferRequest: TransferRequest }): Promise<OperationResponse> => {
    const response = await processingApi.apiProcessingTransferPost({ ...transferRequest })

    return response.data
  }
)

const getTransferById = createAsyncThunk<Tsps, TspId[]>(
  `${NAMESPACE}/getTransferById`,
  async (tspsIdArray: TspId[], { rejectWithValue }) => {
    try {
      const fetchTspParams = tspsIdArray.map((tsp) => processingApi.apiProcessingGetServiceByIdGet(tsp.tspId))

      const responses = await Promise.allSettled(fetchTspParams)
      const tsps = responses
        .filter((result) => result.status === 'fulfilled')
        .reduce((acc, result) => {
          const res = (result as PromiseSettledResult<AxiosResponse<ServiceInfo>>).value.data

          if (res) {
            return { ...acc, [res.serviceId]: res }
          }

          return acc
        }, {})

      return tsps
    } catch (err) {
      const errRes: GatewayProtoErrorRsp = err.response.data

      return rejectWithValue(errRes || { message: err.message })
    }
  }
)

// todo builder-ы возможно разнести по файлам
const processing = createSlice({
  name: 'processing',
  initialState,
  reducers: {
    refillAccount(state) {
      state.refill = { isEnabled: true }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLoans.pending, (state) => {
      state.loans.inProgress = true
      state.loans.error = null
    })
    builder.addCase(fetchLoans.rejected, (state, action) => {
      state.loans.error = action.error
      state.loans.inProgress = false
    })
    builder.addCase(fetchLoans.fulfilled, (state, action) => {
      const loanSchema = new schema.Entity('loans', {}, { idAttribute: 'loanId' })
      const { entities, result } = normalize<LoanInfo>(action.payload.loans, [loanSchema])

      state.loans.data = { ...action.payload, loans: entities.loans, loanIds: result }
      state.loans.inProgress = false
    })
    builder.addCase(fetchFullInfoLoan.pending, (state) => {
      state.fullInfoLoan.error = null
      state.fullInfoLoan.inProgress = true
    })
    builder.addCase(fetchFullInfoLoan.rejected, (state, action) => {
      state.fullInfoLoan.error = action.error
      state.fullInfoLoan.inProgress = false
    })
    builder.addCase(fetchFullInfoLoan.fulfilled, (state, action) => {
      state.fullInfoLoan.data = action.payload
      state.fullInfoLoan.inProgress = false
    })
    builder.addCase(fetchBindings.pending, (state) => {
      state.bindings.error = null
      state.bindings.inProgress = true
    })
    builder.addCase(fetchBindings.rejected, (state, action) => {
      state.bindings.error = action.error
      state.bindings.inProgress = false
    })
    builder.addCase(fetchBindings.fulfilled, (state, action) => {
      state.bindings.data = action.payload.bindings
      state.bindings.inProgress = false
    })
    builder.addCase(getTransferTerms.pending, (state) => {
      state.transfer.inProgress = true
      state.transfer.error = null
    })
    builder.addCase(getTransferTerms.rejected, (state, action) => {
      state.transfer.inProgress = false
      state.transfer.error = action.error
    })
    builder.addCase(getTransferTerms.fulfilled, (state, action) => {
      state.transfer.inProgress = false
      state.transfer.data = action.payload
    })
    builder.addCase(makeTransfer.pending, (state) => {
      state.transfer.error = null
      state.transfer.inProgress = true
    })
    builder.addCase(makeTransfer.rejected, (state, action) => {
      state.transfer.error = action.error
      state.transfer.inProgress = false
    })
    builder.addCase(makeTransfer.fulfilled, (state, action) => {
      state.transfer.data = action.payload
      state.transfer.inProgress = false
    })

    builder.addCase(getTransferById.pending, (state) => {
      state.servicesById.error = null
      state.servicesById.inProgress = true
    })
    builder.addCase(getTransferById.rejected, (state, action) => {
      state.servicesById.error = action.error
      state.servicesById.inProgress = false
    })
    builder.addCase(getTransferById.fulfilled, (state, action) => {
      state.servicesById.data = action.payload
      state.servicesById.inProgress = false
    })
  },
})

export const { refillAccount } = processing.actions
export { fetchLoans, fetchFullInfoLoan, fetchBindings, makeTransfer, getTransferTerms, getTransferById }
export default processing.reducer
