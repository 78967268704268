// tslint:disable
/**
 * Сервис ${app.name} (заявления на перечисление зп)
 * Owner: Дебетовые карты
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface BenefitInfo
 */
export interface BenefitInfo {
  /**
   *
   * @type {string}
   * @memberof BenefitInfo
   */
  title?: string
  /**
   *
   * @type {Array<Description>}
   * @memberof BenefitInfo
   */
  description?: Array<Description>
}
/**
 *
 * @export
 * @interface BenefitItem
 */
export interface BenefitItem {
  /**
   *
   * @type {string}
   * @memberof BenefitItem
   */
  iconType?: string
  /**
   *
   * @type {string}
   * @memberof BenefitItem
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof BenefitItem
   */
  subTitle?: string
  /**
   *
   * @type {BenefitInfo}
   * @memberof BenefitItem
   */
  info?: BenefitInfo
}
/**
 *
 * @export
 * @interface BenefitsAvailabilityResponse
 */
export interface BenefitsAvailabilityResponse {
  /**
   *
   * @type {boolean}
   * @memberof BenefitsAvailabilityResponse
   */
  isBenefitsAvailable?: boolean
}
/**
 *
 * @export
 * @interface BenefitsResponse
 */
export interface BenefitsResponse {
  /**
   *
   * @type {string}
   * @memberof BenefitsResponse
   */
  screenTitle?: string
  /**
   *
   * @type {boolean}
   * @memberof BenefitsResponse
   */
  isChatAvailable?: boolean
  /**
   *
   * @type {Array<CommonBenefitItem>}
   * @memberof BenefitsResponse
   */
  commonBenefitList?: Array<CommonBenefitItem>
  /**
   *
   * @type {Array<CardBenefitsItem>}
   * @memberof BenefitsResponse
   */
  cardBenefitsList?: Array<CardBenefitsItem>
}
/**
 *
 * @export
 * @interface BlockItem
 */
export interface BlockItem {
  /**
   *
   * @type {string}
   * @memberof BlockItem
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof BlockItem
   */
  subtitle?: string
  /**
   *
   * @type {string}
   * @memberof BlockItem
   */
  smallImgLink?: string
  /**
   *
   * @type {string}
   * @memberof BlockItem
   */
  largeImgLink?: string
  /**
   *
   * @type {Info}
   * @memberof BlockItem
   */
  info?: Info
}
/**
 *
 * @export
 * @interface Button
 */
export interface Button {
  /**
   *
   * @type {string}
   * @memberof Button
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Button
   */
  link?: string
}
/**
 *
 * @export
 * @interface CardBenefitsItem
 */
export interface CardBenefitsItem {
  /**
   *
   * @type {string}
   * @memberof CardBenefitsItem
   */
  title?: string
  /**
   *
   * @type {Array<BenefitItem>}
   * @memberof CardBenefitsItem
   */
  benefitList?: Array<BenefitItem>
}
/**
 *
 * @export
 * @interface CommonBenefitItem
 */
export interface CommonBenefitItem {
  /**
   *
   * @type {string}
   * @memberof CommonBenefitItem
   */
  title?: string
  /**
   *
   * @type {Array<BlockItem>}
   * @memberof CommonBenefitItem
   */
  blockList?: Array<BlockItem>
}
/**
 *
 * @export
 * @interface Description
 */
export interface Description {
  /**
   *
   * @type {string}
   * @memberof Description
   */
  type?: string
  /**
   *
   * @type {number}
   * @memberof Description
   */
  order?: number
  /**
   *
   * @type {string}
   * @memberof Description
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof Description
   */
  textLink?: string
  /**
   *
   * @type {string}
   * @memberof Description
   */
  link?: string
}
/**
 *
 * @export
 * @interface Detail
 */
export interface Detail {
  /**
   *
   * @type {string}
   * @memberof Detail
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof Detail
   */
  description?: string
  /**
   *
   * @type {number}
   * @memberof Detail
   */
  order?: number
}
/**
 *
 * @export
 * @interface Info
 */
export interface Info {
  /**
   *
   * @type {string}
   * @memberof Info
   */
  title?: string
  /**
   *
   * @type {Array<string>}
   * @memberof Info
   */
  description?: Array<string>
  /**
   *
   * @type {Button}
   * @memberof Info
   */
  button?: Button
}
/**
 *
 * @export
 * @interface LinkButton
 */
export interface LinkButton {
  /**
   *
   * @type {string}
   * @memberof LinkButton
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof LinkButton
   */
  link?: string
}
/**
 *
 * @export
 * @interface MtsBankException
 */
export interface MtsBankException {
  /**
   *
   * @type {MtsBankExceptionCause}
   * @memberof MtsBankException
   */
  cause?: MtsBankExceptionCause
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankException
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  httpStatus?: MtsBankExceptionHttpStatusEnum
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  uuid?: string
  /**
   *
   * @type {boolean}
   * @memberof MtsBankException
   */
  needLog?: boolean
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankExceptionCauseSuppressed>}
   * @memberof MtsBankException
   */
  suppressed?: Array<MtsBankExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  localizedMessage?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MtsBankExceptionHttpStatusEnum {
  _100CONTINUE = '100 CONTINUE',
  _101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  _102PROCESSING = '102 PROCESSING',
  _103CHECKPOINT = '103 CHECKPOINT',
  _200OK = '200 OK',
  _201CREATED = '201 CREATED',
  _202ACCEPTED = '202 ACCEPTED',
  _203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  _204NOCONTENT = '204 NO_CONTENT',
  _205RESETCONTENT = '205 RESET_CONTENT',
  _206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  _207MULTISTATUS = '207 MULTI_STATUS',
  _208ALREADYREPORTED = '208 ALREADY_REPORTED',
  _226IMUSED = '226 IM_USED',
  _300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  _301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  _302FOUND = '302 FOUND',
  _302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  _303SEEOTHER = '303 SEE_OTHER',
  _304NOTMODIFIED = '304 NOT_MODIFIED',
  _305USEPROXY = '305 USE_PROXY',
  _307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  _308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  _400BADREQUEST = '400 BAD_REQUEST',
  _401UNAUTHORIZED = '401 UNAUTHORIZED',
  _402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  _403FORBIDDEN = '403 FORBIDDEN',
  _404NOTFOUND = '404 NOT_FOUND',
  _405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  _406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  _407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  _408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  _409CONFLICT = '409 CONFLICT',
  _410GONE = '410 GONE',
  _411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  _412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  _413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  _413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  _414URITOOLONG = '414 URI_TOO_LONG',
  _414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  _415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  _416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  _417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  _418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  _419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  _420METHODFAILURE = '420 METHOD_FAILURE',
  _421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  _422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  _423LOCKED = '423 LOCKED',
  _424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  _425TOOEARLY = '425 TOO_EARLY',
  _426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  _428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  _429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  _431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  _451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  _500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  _501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  _502BADGATEWAY = '502 BAD_GATEWAY',
  _503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  _504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  _505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  _506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  _507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  _508LOOPDETECTED = '508 LOOP_DETECTED',
  _509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  _510NOTEXTENDED = '510 NOT_EXTENDED',
  _511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

/**
 *
 * @export
 * @interface MtsBankExceptionCause
 */
export interface MtsBankExceptionCause {
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankExceptionCause
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCause
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankExceptionCauseSuppressed>}
   * @memberof MtsBankExceptionCause
   */
  suppressed?: Array<MtsBankExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCause
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface MtsBankExceptionCauseStackTrace
 */
export interface MtsBankExceptionCauseStackTrace {
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  classLoaderName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  moduleName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  moduleVersion?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  methodName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  fileName?: string
  /**
   *
   * @type {number}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  lineNumber?: number
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  className?: string
  /**
   *
   * @type {boolean}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  nativeMethod?: boolean
}
/**
 *
 * @export
 * @interface MtsBankExceptionCauseSuppressed
 */
export interface MtsBankExceptionCauseSuppressed {
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface OfferButton
 */
export interface OfferButton {
  /**
   *
   * @type {string}
   * @memberof OfferButton
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof OfferButton
   */
  offerId?: string
  /**
   *
   * @type {string}
   * @memberof OfferButton
   */
  offerLink?: string
}
/**
 *
 * @export
 * @interface OfferScreenResponse
 */
export interface OfferScreenResponse {
  /**
   *
   * @type {string}
   * @memberof OfferScreenResponse
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof OfferScreenResponse
   */
  description?: string
  /**
   *
   * @type {OfferButton}
   * @memberof OfferScreenResponse
   */
  button?: OfferButton
}
/**
 *
 * @export
 * @interface PromoScreen
 */
export interface PromoScreen {
  /**
   *
   * @type {string}
   * @memberof PromoScreen
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof PromoScreen
   */
  description?: string
  /**
   *
   * @type {Array<Detail>}
   * @memberof PromoScreen
   */
  details?: Array<Detail>
  /**
   *
   * @type {LinkButton}
   * @memberof PromoScreen
   */
  linkButton?: LinkButton
  /**
   *
   * @type {string}
   * @memberof PromoScreen
   */
  buttonTitle?: string
}
/**
 *
 * @export
 * @interface SalaryCardPrintRequest
 */
export interface SalaryCardPrintRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryCardPrintRequest
   */
  cardId: string
}
/**
 *
 * @export
 * @interface SalaryCardPrintResponse
 */
export interface SalaryCardPrintResponse {
  /**
   *
   * @type {string}
   * @memberof SalaryCardPrintResponse
   */
  applicationData?: string
}
/**
 *
 * @export
 * @interface SalaryCardScreenRequest
 */
export interface SalaryCardScreenRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryCardScreenRequest
   */
  productCode: string
}
/**
 *
 * @export
 * @interface SalaryCardScreenResponse
 */
export interface SalaryCardScreenResponse {
  /**
   *
   * @type {PromoScreen}
   * @memberof SalaryCardScreenResponse
   */
  promoScreen?: PromoScreen
}

/**
 * BenefitsControllerApi - axios parameter creator
 * @export
 */
export const BenefitsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о доступности преимуществ для зарплатных клиентов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    availability(options: any = {}): RequestArgs {
      const localVarPath = `/dc-salary-card/v1/benefits/availability`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает описание преимуществ, доступных зарплатному клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    benefits(options: any = {}): RequestArgs {
      const localVarPath = `/dc-salary-card/v1/benefits`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BenefitsControllerApi - functional programming interface
 * @export
 */
export const BenefitsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о доступности преимуществ для зарплатных клиентов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    availability(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitsAvailabilityResponse> {
      const localVarAxiosArgs = BenefitsControllerApiAxiosParamCreator(configuration).availability(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает описание преимуществ, доступных зарплатному клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    benefits(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitsResponse> {
      const localVarAxiosArgs = BenefitsControllerApiAxiosParamCreator(configuration).benefits(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * BenefitsControllerApi - factory interface
 * @export
 */
export const BenefitsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о доступности преимуществ для зарплатных клиентов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    availability(options?: any): AxiosPromise<BenefitsAvailabilityResponse> {
      return BenefitsControllerApiFp(configuration).availability(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает описание преимуществ, доступных зарплатному клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    benefits(options?: any): AxiosPromise<BenefitsResponse> {
      return BenefitsControllerApiFp(configuration).benefits(options)(axios, basePath)
    },
  }
}

/**
 * BenefitsControllerApi - object-oriented interface
 * @export
 * @class BenefitsControllerApi
 * @extends {BaseAPI}
 */
export class BenefitsControllerApi extends BaseAPI {
  /**
   *
   * @summary Метод возвращает информацию о доступности преимуществ для зарплатных клиентов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenefitsControllerApi
   */
  public availability(options?: any) {
    return BenefitsControllerApiFp(this.configuration).availability(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает описание преимуществ, доступных зарплатному клиенту
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenefitsControllerApi
   */
  public benefits(options?: any) {
    return BenefitsControllerApiFp(this.configuration).benefits(options)(this.axios, this.basePath)
  }
}

/**
 * DcSalaryCardControllerApi - axios parameter creator
 * @export
 */
export const DcSalaryCardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает данные для промо-экрана с условиями при оформлении зарплатной карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerScreen(options: any = {}): RequestArgs {
      const localVarPath = `/dc-salary-card/v1/offerScreen`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод генерация печатной формы заявления клиента на перевод заработной платы на карту МТС Банка
     * @param {SalaryCardPrintRequest} salaryCardPrintRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryCardPrint(salaryCardPrintRequest: SalaryCardPrintRequest, options: any = {}): RequestArgs {
      // verify required parameter 'salaryCardPrintRequest' is not null or undefined
      if (salaryCardPrintRequest === null || salaryCardPrintRequest === undefined) {
        throw new RequiredError(
          'salaryCardPrintRequest',
          'Required parameter salaryCardPrintRequest was null or undefined when calling salaryCardPrint.'
        )
      }
      const localVarPath = `/dc-salary-card/v1/salaryCardPrint`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof salaryCardPrintRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(salaryCardPrintRequest !== undefined ? salaryCardPrintRequest : {})
        : salaryCardPrintRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает данные для промо-экрана с условиями при оформлении зарплатной карты
     * @param {SalaryCardScreenRequest} salaryCardScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryCardScreen(salaryCardScreenRequest: SalaryCardScreenRequest, options: any = {}): RequestArgs {
      // verify required parameter 'salaryCardScreenRequest' is not null or undefined
      if (salaryCardScreenRequest === null || salaryCardScreenRequest === undefined) {
        throw new RequiredError(
          'salaryCardScreenRequest',
          'Required parameter salaryCardScreenRequest was null or undefined when calling salaryCardScreen.'
        )
      }
      const localVarPath = `/dc-salary-card/v1/salaryCardScreen`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof salaryCardScreenRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(salaryCardScreenRequest !== undefined ? salaryCardScreenRequest : {})
        : salaryCardScreenRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DcSalaryCardControllerApi - functional programming interface
 * @export
 */
export const DcSalaryCardControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает данные для промо-экрана с условиями при оформлении зарплатной карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerScreen(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferScreenResponse> {
      const localVarAxiosArgs = DcSalaryCardControllerApiAxiosParamCreator(configuration).offerScreen(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод генерация печатной формы заявления клиента на перевод заработной платы на карту МТС Банка
     * @param {SalaryCardPrintRequest} salaryCardPrintRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryCardPrint(
      salaryCardPrintRequest: SalaryCardPrintRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalaryCardPrintResponse> {
      const localVarAxiosArgs = DcSalaryCardControllerApiAxiosParamCreator(configuration).salaryCardPrint(
        salaryCardPrintRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает данные для промо-экрана с условиями при оформлении зарплатной карты
     * @param {SalaryCardScreenRequest} salaryCardScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryCardScreen(
      salaryCardScreenRequest: SalaryCardScreenRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalaryCardScreenResponse> {
      const localVarAxiosArgs = DcSalaryCardControllerApiAxiosParamCreator(configuration).salaryCardScreen(
        salaryCardScreenRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DcSalaryCardControllerApi - factory interface
 * @export
 */
export const DcSalaryCardControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает данные для промо-экрана с условиями при оформлении зарплатной карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerScreen(options?: any): AxiosPromise<OfferScreenResponse> {
      return DcSalaryCardControllerApiFp(configuration).offerScreen(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод генерация печатной формы заявления клиента на перевод заработной платы на карту МТС Банка
     * @param {SalaryCardPrintRequest} salaryCardPrintRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryCardPrint(
      salaryCardPrintRequest: SalaryCardPrintRequest,
      options?: any
    ): AxiosPromise<SalaryCardPrintResponse> {
      return DcSalaryCardControllerApiFp(configuration).salaryCardPrint(salaryCardPrintRequest, options)(
        axios,
        basePath
      )
    },
    /**
     *
     * @summary Метод возвращает данные для промо-экрана с условиями при оформлении зарплатной карты
     * @param {SalaryCardScreenRequest} salaryCardScreenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryCardScreen(
      salaryCardScreenRequest: SalaryCardScreenRequest,
      options?: any
    ): AxiosPromise<SalaryCardScreenResponse> {
      return DcSalaryCardControllerApiFp(configuration).salaryCardScreen(salaryCardScreenRequest, options)(
        axios,
        basePath
      )
    },
  }
}

/**
 * DcSalaryCardControllerApi - object-oriented interface
 * @export
 * @class DcSalaryCardControllerApi
 * @extends {BaseAPI}
 */
export class DcSalaryCardControllerApi extends BaseAPI {
  /**
   *
   * @summary Метод возвращает данные для промо-экрана с условиями при оформлении зарплатной карты
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DcSalaryCardControllerApi
   */
  public offerScreen(options?: any) {
    return DcSalaryCardControllerApiFp(this.configuration).offerScreen(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод генерация печатной формы заявления клиента на перевод заработной платы на карту МТС Банка
   * @param {SalaryCardPrintRequest} salaryCardPrintRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DcSalaryCardControllerApi
   */
  public salaryCardPrint(salaryCardPrintRequest: SalaryCardPrintRequest, options?: any) {
    return DcSalaryCardControllerApiFp(this.configuration).salaryCardPrint(salaryCardPrintRequest, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Метод возвращает данные для промо-экрана с условиями при оформлении зарплатной карты
   * @param {SalaryCardScreenRequest} salaryCardScreenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DcSalaryCardControllerApi
   */
  public salaryCardScreen(salaryCardScreenRequest: SalaryCardScreenRequest, options?: any) {
    return DcSalaryCardControllerApiFp(this.configuration).salaryCardScreen(salaryCardScreenRequest, options)(
      this.axios,
      this.basePath
    )
  }
}
