import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsomorphicLayoutEffect } from '@mtsbank/ui-kit'
import { setIsWebView } from '@reducers/settings/settings'
import { selectSettingsState } from '@selectors/settings'
import { LayoutProps, LayoutType } from '@root/layouts/types'
import { AuthLayout } from '@root/layouts/AuthLayout'
import { BaseLayout } from '@root/layouts/BaseLayout'
import { Loading } from '@components/Loading/Loading'
import { authHelper } from '@utils/authHelper/AuthHelper'
import { statDataLoader } from '@utils/gtm/StatDataLoader'
import { fetchIncidentHandler } from '@root/hooks/useIncidendHandling'
import { selectIncidents } from '@selectors/incidents'
import { RouteGuard } from '@components/RouteGuard/RouteGuard'

export const Layout: FC<LayoutProps> = ({ withNps, children }) => {
  const dispatch = useDispatch()
  const { isWebView, isCheckedWebView } = useSelector(selectSettingsState)
  const { lastReqTime, inProgress: inProgressIncidents } = useSelector(selectIncidents)
  const layoutType = authHelper.isAuthenticated() ? LayoutType.AUTH : LayoutType.BASE

  useEffect(() => {
    dispatch(setIsWebView(window.location.search.includes('isWebView=true')))
  }, [dispatch])

  useIsomorphicLayoutEffect(() => {
    dispatch(() => {
      authHelper.initialize()
      statDataLoader.updateData()
      fetchIncidentHandler({ lastReqTime, inProgress: inProgressIncidents })
    })
  }, [dispatch])

  if (!isCheckedWebView) {
    return <Loading />
  }

  const LayoutComponent = {
    [LayoutType.BASE]: BaseLayout,
    [LayoutType.AUTH]: AuthLayout,
  }[layoutType]

  return (
    <LayoutComponent isWebView={isWebView} withNps={withNps}>
      <RouteGuard>{children}</RouteGuard>
    </LayoutComponent>
  )
}
