import { PersonalOffersProtoComponentProps, PersonalOffersProtoGetOfferRsp } from '@open-api/ump/st-personal-offers'
import { HistoryProtoGetResponseHistoryRsp, HistoryProtoResponseId } from '@open-api/ump/response-history'
import { BaseState } from '@root/types/reducers'

export type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${Lowercase<T>}${Capitalize<SnakeToCamelCase<U>>}`
  : S

export type SnakeToCamelCaseNested<T> = T extends T
  ? {
      [K in keyof T as SnakeToCamelCase<K & string>]: SnakeToCamelCaseNested<T[K]>
    }
  : T

interface ResponseHistory extends BaseState<HistoryProtoGetResponseHistoryRsp> {
  productsToTheHideID: string[]
}

export interface FullApproveState {
  getOffer: BaseState<PersonalOffersProtoGetOfferRsp>
  getOfferPage: BaseState<PersonalOffersProtoGetOfferRsp>
  getOfferPopup: BaseState<PersonalOffersProtoGetOfferRsp>
  banners: BaseState<PersonalOffersProtoGetOfferRsp>
  responseHistory: ResponseHistory
}

export interface GetOfferParams {
  os?: string
  appName?: string
  version?: string
  productType?: string
  additionalData?: boolean
  channel?: string
  siebelId?: string
  phoneNumb?: string
  offerID?: string
  componentType?: string
  countOffers?: string
  rboID?: string
  options?: Options
}

export interface Options {
  [key: string]: Options | string | number | boolean
}

export enum ComponentType {
  LIMIT = 'limit',
  OFFER_PAGE = 'offerPage',
  FANTOM_LOAN = 'fantomLoan',
  FANTOM_CARD = 'fantomCard',
  BANNER = 'banner',
  POPUP = 'popup',
  BANNERS = 'banner',
  MORE = 'more',
}

export interface ResponseHistoryParams {
  offer: PersonalOffersProtoComponentProps
  responseId: HistoryProtoResponseId
  componentType: ComponentType
  phoneNum: string
}
