import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useDidMount, useToast } from '@mtsbank/ui-kit'
import { ToastStorage } from '@mtsbank/ui-kit/build/components/organisms/Toast/types'
import { selectIncidents } from '@selectors/incidents'
import { fetchGetIncidents, pushViewedPage } from '@reducers/incidents/incidents'
import { IncidentItem, Place } from '@root/types/api/incident'
import { store } from '@store/index'
import { selectOnboardings } from '@selectors/onboarding'
import { authHelper, LOGOUT_TIME } from '@utils/authHelper/AuthHelper'
import { showMassIncidentsGtm } from '@utils/gtm/massIncidents/events'

const CLOSING_TIMER = 5000

interface IncidentHandlerProps {
  lastReqTime: number
  inProgress: boolean
}

type IncidentHandler = (props: IncidentHandlerProps) => void

export const fetchIncidentHandler: IncidentHandler = ({ inProgress, lastReqTime }) => {
  const { isAuthenticated } = authHelper
  const currentTime = Date.now()
  const isPassedLogoutTime = currentTime - lastReqTime >= LOGOUT_TIME

  if ((!lastReqTime || isPassedLogoutTime) && !inProgress && isAuthenticated()) {
    store.dispatch(fetchGetIncidents())
  }
}

interface Props {
  currentPlace: Place
}

export const useIncidentHandling = ({ currentPlace }: Props) => {
  const dispatch = useDispatch()
  const [incidentToastId, setIncidentToastId] = useState<string>(null)
  const { data, pagesViewed } = useSelector(selectIncidents)
  const currentIncident = data?.find(({ place }) => place === currentPlace)
  const isViewedCurrentIncident = pagesViewed?.find((place) => place === currentPlace)
  const [incident, setIncident] = useState<IncidentItem>(currentIncident)
  const { isOnboardingCompleted } = useSelector(selectOnboardings)

  const { toast, removeToast, toastStorage } = useToast()
  const [isAnIncidentAndViewed, setIsAnIncidentAndViewed] = useState(Boolean(isViewedCurrentIncident))

  const handleRemoveToast = useCallback(
    (toastStorage: ToastStorage) => {
      const keys = Object.keys(toastStorage)

      keys.forEach((key) => {
        removeToast(key)
      })
    },
    [removeToast]
  )

  useDidMount(() => {
    handleRemoveToast(toastStorage)
  })

  useEffect(() => {
    if (!isEmpty(data) && isOnboardingCompleted) {
      setIncident(currentIncident)

      if (currentIncident && !isViewedCurrentIncident) {
        let localToastId: string // todo удалить когда готово будет https://jira.mtsbank.ru/browse/UIKIT-533

        toast('error', currentIncident.message, '', {
          timeout: CLOSING_TIMER,
          // eslint-disable-next-line
          // @ts-ignore //todo пофиксим доработкой кита https://jira.mtsbank.ru/browse/UIKIT-542
          buttons: (id) => {
            localToastId = id // todo удалить когда готово будет https://jira.mtsbank.ru/browse/UIKIT-533
            setIncidentToastId(id)
          },
        }) // todo доработать когда готово будет https://jira.mtsbank.ru/browse/UIKIT-533
        dispatch(pushViewedPage(currentPlace))
        setIsAnIncidentAndViewed(true)
        showMassIncidentsGtm()

        const timerId = setTimeout(() => {
          // todo удалить timeOut когда готово будет https://jira.mtsbank.ru/browse/UIKIT-533
          removeToast(localToastId)

          return () => clearTimeout(timerId)
        }, CLOSING_TIMER)
      }
    }
  }, [
    currentIncident,
    currentPlace,
    data,
    dispatch,
    isOnboardingCompleted,
    isViewedCurrentIncident,
    removeToast,
    toast,
  ])

  useEffect(() => {
    if (incidentToastId) {
      return () => {
        removeToast(incidentToastId)
        setIncidentToastId(null)
      }
    }
  }, [incidentToastId, removeToast])

  return (isAnIncidentAndViewed || !incident) && isOnboardingCompleted
}
