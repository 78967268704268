import { events, sendGmtEvent } from '../baseFunctions'
import { DataLayerEvents, Values } from './types'
import { configParamsByEvent } from './config'
import { TabbarEventCategories } from './constants'
import { translit } from '../translit'

type TMobileMenuSendGtm = (event: DataLayerEvents, values?: Values) => void

export const mobileMenuSendGtm: TMobileMenuSendGtm = (event, { name }) =>
  sendGmtEvent({
    event: events.mtsCross,
    eventCategory: TabbarEventCategories.tabbar,
    eventLabel: translit(name ?? 'tabbar'),
    screenName: window.location.pathname,
    ...configParamsByEvent[event],
  })
