import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ArrestDto, LoanDto, PaymentScheduleDtoV2 as PaymentSchedule } from '@open-api/ump/dbo-loan'
import { dboLoanControllerApi1, accountControllerApi, scheduleControllerApi } from '@root/api/ump/dbo-loan'
import { DboLoanState, renameDboLoanParamsType } from './types'

export const NAMESPACE = 'dboLoan'

const initialState: DboLoanState = {
  fullInfoLoan: {
    data: null,
    inProgress: true,
    error: null,
  },
  arrestsInfo: {
    data: null,
    inProgress: true,
    error: null,
  },
  paymentSchedule: {
    data: null,
    inProgress: true,
    error: null,
  },
}

const fetchDboLoanInfo = createAsyncThunk(`${NAMESPACE}/fetchDboLoanInfo`, async (loanId: string): Promise<LoanDto> => {
  const response = await dboLoanControllerApi1.fetchLoan(loanId)

  return response.data
})

const renameDboLoan = createAsyncThunk(
  `${NAMESPACE}/renameDboLoan`,
  async ({ loanId, newDto }: renameDboLoanParamsType): Promise<void> => {
    await dboLoanControllerApi1.rename(loanId, { name: newDto })
  }
)

const fetchDboArrests = createAsyncThunk(
  `${NAMESPACE}/fetchDboArrests`,
  async (clientId: string): Promise<Array<ArrestDto>> => {
    const response = await accountControllerApi.fetchArrests(clientId)

    return response.data
  }
)

const fetchDboSchedule = createAsyncThunk(
  `${NAMESPACE}/fetchDboSchedule`,
  async (loandId: string): Promise<PaymentSchedule> => {
    const response = await scheduleControllerApi.fetch1(loandId)

    return response.data
  }
)

const dboLoan = createSlice({
  name: 'dboLoan',
  initialState,
  reducers: {
    loanUpdateDto: (state, action) => {
      state.fullInfoLoan.data.name = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDboLoanInfo.pending, (state) => {
      state.fullInfoLoan.inProgress = true
      state.fullInfoLoan.error = null
    })
    builder.addCase(fetchDboLoanInfo.rejected, (state, action) => {
      state.fullInfoLoan.inProgress = false
      state.fullInfoLoan.error = action.error
    })
    builder.addCase(fetchDboLoanInfo.fulfilled, (state, action) => {
      state.fullInfoLoan.inProgress = false
      state.fullInfoLoan.data = { ...action.payload }
    })
    builder.addCase(fetchDboArrests.pending, (state) => {
      state.arrestsInfo.inProgress = true
      state.arrestsInfo.error = null
    })
    builder.addCase(fetchDboArrests.rejected, (state, action) => {
      state.arrestsInfo.inProgress = false
      state.arrestsInfo.error = action.error
    })
    builder.addCase(fetchDboArrests.fulfilled, (state, action) => {
      state.arrestsInfo.inProgress = false
      state.arrestsInfo.data = [...action.payload]
    })
    builder.addCase(fetchDboSchedule.pending, (state) => {
      state.paymentSchedule.inProgress = true
      state.paymentSchedule.error = null
    })
    builder.addCase(fetchDboSchedule.rejected, (state, action) => {
      state.paymentSchedule.inProgress = false
      state.paymentSchedule.error = action.error
    })
    builder.addCase(fetchDboSchedule.fulfilled, (state, action) => {
      state.paymentSchedule.inProgress = false
      state.paymentSchedule.data = { ...action.payload }
    })
  },
})

export { fetchDboLoanInfo, renameDboLoan, fetchDboArrests, fetchDboSchedule }
export const { loanUpdateDto } = dboLoan.actions
export const dboLoanReducer = dboLoan.reducer
