import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { ReceiptDetailsRsp, ReceiptDetailsState } from '@reducers/mobilePayment/receipt/type'

const NAMESPACE = 'receiptDetails'

const initialState: ReceiptDetailsState = {
  inProgress: false,
  error: null,
  data: null,
}

const fetchReceiptDetails = createAsyncThunk(`${NAMESPACE}`, async (esppTransactionNumber: string) => {
  const response: AxiosResponse<ReceiptDetailsRsp> = await axios.get(
    `/phr/ewallet/3.4/mts/receipts/getReceiptDetails.do?esppTransactionNumber=${esppTransactionNumber}`
  )

  return response.data
})

const receiptDetails = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReceiptDetails.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchReceiptDetails.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(fetchReceiptDetails.fulfilled, (state, action) => {
      state.inProgress = false
      state.data = { ...action.payload }
    })
  },
})

export { fetchReceiptDetails }

export const receiptDetailsReducer = receiptDetails.reducer
