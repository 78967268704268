import { Dictionary } from '@reduxjs/toolkit'
import { OperationResponse, TransferType, BindingType } from '@generated/api'
import { ServiceParams } from '@components/MobilePayment/types'

export interface PaymentDetector {
  detect: () => Promise<number>
  pay: (paymentRequest) => Promise<string | OperationResponse>
  type: number
}
export enum PaymentMethods {
  Card = 1,
  SamsungPay,
  ApplePay,
  GooglePay,
}

export type PaymentDetails = {
  amount: number | string
  pan?: string
  expiry?: string
  cvc?: string
  currency: number
  callback_url: (mdOrder: string) => string
  userId?: string
  serviceParams?: ServiceParams
  serviceId?: string
  type?: TransferType
  fhpSessionId?: string
  fhpRequestId?: string
  mdOrder?: string
  targetBindingType?: BindingType
  targetPan?: string
  phoneNumber?: string
  paymentSource?: string
}

class AvailablePaymentMethodDetector {
  private providedDetectors: Dictionary<PaymentDetector> = {}

  constructor(providedDetectors: PaymentDetector[]) {
    this.add(providedDetectors)
  }

  add(providedDetectors: PaymentDetector[]) {
    this.providedDetectors = providedDetectors.reduce((result, item: PaymentDetector) => {
      if (!result[item.type]) {
        result[item.type] = item
      }

      return result
    }, this.providedDetectors)
  }

  detect() {
    return Promise.allSettled(
      Object.keys(this.providedDetectors).map((providerKey) => this.providedDetectors[providerKey].detect())
    )
  }

  get(id) {
    return this.providedDetectors[id]
  }
}

export default AvailablePaymentMethodDetector
