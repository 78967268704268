import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { paymentApi } from '@api/dot-net/api'
import { RecomendationAmountInfo } from '@generated/api'
import { RecommendationState } from '@reducers/mobilePayment/recomendations/type'
import { selectRecommendationAmountInfoByPhone } from '@selectors/mobilePayment'

const NAMESPACE = 'recomendation'

const initialState: RecommendationState = {
  amount: {
    list: {},
    default: {
      key: 'default',
      phoneNumber: '',
      inProgress: false,
      error: null,
      data: null,
    },
  },
}

export const getRecommendationAmountInfo = createAsyncThunk(`${NAMESPACE}_amount`, async (phone: string, thunkAPI) => {
  const { data: selectedAmountInfo } = selectRecommendationAmountInfoByPhone(phone).call(this, thunkAPI.getState())
  let result: AxiosResponse<RecomendationAmountInfo> = {
    config: undefined,
    headers: undefined,
    status: 0,
    statusText: '',
    data: selectedAmountInfo,
  }

  if (!result.data) {
    result = await paymentApi.apiPaymentRecomendationGet(phone)
  }

  return result
})

const recommendation = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRecommendationAmountInfo.pending, (state, action) => {
      const key = `phone${action.meta.arg}`

      state.amount.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: true,
        error: null,
        data: null,
      }
    })
    builder.addCase(getRecommendationAmountInfo.rejected, (state, action) => {
      const key = `phone${action.meta.arg}`

      state.amount.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: false,
        error: action.error,
        data: null,
      }
    })
    builder.addCase(getRecommendationAmountInfo.fulfilled, (state, action) => {
      const key = `phone${action.meta.arg}`

      state.amount.list[key] = {
        key,
        phoneNumber: action.meta.arg,
        inProgress: false,
        data: { ...action.payload.data },
        error: null,
      }
    })
  },
})

export { recommendation }
export const recommendationReducer = recommendation.reducer
