export const DEFAULT_REGION = 'MOW'
const REGION_ID_KEY_NAME = 'regionId'

export const getCurrentRegion = () => {
  // https://jira.mtsbank.ru/browse/MMWDEV-1009 задача на рамиля
  const region = typeof localStorage !== 'undefined' ? localStorage.getItem(REGION_ID_KEY_NAME) : ''

  if (region) {
    return region
  }

  return DEFAULT_REGION
}
