import { FieldsValues } from '@root/components/SBP/types'
import { actionGroups, eventActions, eventCategories, eventLabels, events, sendGmtEventPerPage } from '../baseFunctions'
import { configParamsByEvent, configParamsByEventAndField } from './config'
import { SbpEventLabels } from './constants'
import { EventFieldNames, FormFieldNames } from './types'

export const prepareInputValueGtm = (field: string, values: FieldsValues): string => {
  switch (field) {
    case FormFieldNames.AMOUNT:
      return values[field].split('.')[0]
    case FormFieldNames.RECIPIENT_BANK:
      return values[field].label ?? ''
    default:
      return values[field]
  }
}

/** Действия с полями */
export const sendFieldGtm = (event: EventFieldNames, field: string, value?: string) => {
  if (configParamsByEvent[event] && configParamsByEventAndField[event]) {
    sendGmtEventPerPage({
      ...configParamsByEvent[event](value),
      ...configParamsByEventAndField[event][field],
      eventCategory: eventCategories.perevody,
    })
  }
}

/** Перезагрузка страницы */
export const reloadPageGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.perezagruzka,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.perevody,
  })

/** Клик по элементу На главную на экране "Для перевода нужна карта МТС Банка" */
export const goToMainLinkClickGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.naGlavnuu,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.perevody,
  })

/** Клик по кнопке Продолжить  */
export const continueBtnClickGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.prodolzhit,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.perevody,
  })

/** Показ экрана проверки данных  */
export const showScreeConfirmGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.proverkaDannyh,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.perevody,
  })

/** Показ логотипа СБП  */
export const showLogoConfirmGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.elementShow,
    eventLabel: SbpEventLabels.sbp,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.perevody,
  })

/** Успешный перевод  */
export const successTransferResultGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.confirmed,
    eventLabel: eventLabels.uspeshnyiPerevod,
    actionGroup: actionGroups.conversions,
    eventCategory: eventCategories.perevody,
  })

/** Клик по кнопке Перевести по карте  */
export const transferBtnClickGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.perevesti,
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.perevody,
  })
