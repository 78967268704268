import { AxiosRequestConfig } from 'axios'
import { Dictionary } from '@reduxjs/toolkit'
import { aftApi } from '@api/dot-net/api'

export const AFT_KEYS_NAMES = {
  AFT_DEFAULT: 'AFT_DEFAULT',
}

interface AftEntry {
  data: string
  cookie: string
}

class AftStore {
  private store: Dictionary<Promise<AftEntry>> = {}

  public get(key: string): Promise<AftEntry> | undefined {
    return this.store[key]
  }

  public set(key: string, value: Promise<AftEntry>) {
    this.store[key] = value
  }

  public remove(key: string) {
    if (typeof this.store[key] !== 'undefined') {
      delete this.store[key]
    }
  }

  public autoClean() {
    this.remove(AFT_KEYS_NAMES.AFT_DEFAULT)
  }
}

export const aftStore = new AftStore()

const aftInterceptor = async (req: AxiosRequestConfig) => {
  if (!req.headers.RequestVerificationToken && (!req.params || (req.params && !req.params.disableAft))) {
    let aftKeyPromise: Promise<AftEntry> = aftStore.get(AFT_KEYS_NAMES.AFT_DEFAULT)

    if (!(aftKeyPromise instanceof Promise)) {
      aftKeyPromise = aftApi.apiAftGet().then((response) => ({
        data: response.data,
        cookie: ((response.headers['set-cookie'] as unknown as string[]) || []).join(';'),
      }))

      aftStore.set(AFT_KEYS_NAMES.AFT_DEFAULT, aftKeyPromise)
    }

    const { data: aftKey, cookie } = await aftKeyPromise

    req.headers.RequestVerificationToken = aftKey

    if (process.env.PRIVATE_PAYMENT_HOST) {
      req.headers.Cookie = `${req.headers.Cookie || ''}${cookie}`
    }
  }

  return req
}

export default aftInterceptor
