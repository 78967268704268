import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { theme, useDebouncedCallback, useDidMount } from '@mtsbank/ui-kit'

import { fetchGetCardListMain, setInProgressResend } from '@reducers/debitCards/cardList'
import { selectCardList } from '@selectors/newMain'
import { CardsData, HandleClick, OwnershipBank, Status } from '@components/NewMain/Cards/types'
import { DebitCards } from '@components/NewMain/Cards/DebitCards'
import { getSortedCards } from '@components/NewMain/Cards/utils'
import { ModalMapping } from '@components/Modals/ModalMapping'
import { ModalNames } from '@components/Modals/type'
import { config } from '@components/Modals/CardsStatuses/config'
import { classNames, StyledAccordion } from '@components/Styled/StyledAccordion'
import { ResendComponent } from '@components/NewMain/ResendComponent'
import { Skeleton } from '@components/NewMain/Skeleton'
import { BalanceState } from '@components/NewMain/types'
import { RequestedCards } from '@components/RequestCards/RequestedCards'
import {
  cardsHideClickGtm,
  cardPopupShowGtm,
  cardsErrorGtm,
  cardsReloadClickGtm,
  newMainWigetNames,
  showWidgetGtm,
} from '@root/utils/gtm/newMainPageEvents'
import { selectFantomCards, selectGetOffer } from '@selectors/fullApproveWidget'
import { cardsWidgetClickGtm, seeingCardsWidgetGtm, showCardsWidgetGtm } from '@root/utils/gtm/mainPage/events'
import { useBlockInVisionOnce } from '@root/hooks/useBlockInVision'

const DELAY = 400

export const Cards: FC<BalanceState> = ({ isHiddenBalance }) => {
  const dispatch = useDispatch()
  const fantomCards = useSelector(selectFantomCards)
  const { inProgress: inProgressFA } = useSelector(selectGetOffer)
  const [isOpenedAccordion, isSetOpenedAccordion] = useState(true)
  const [isOpenedModal, setIsOpenedModal] = useState(false)
  const [statusCardType, setStatusCardType] = useState<Status>(null)
  const [isVisibleCards, setIsVisibleCards] = useState(false)
  const [cardsData, setCardsData] = useState<CardsData>({
    visibleCards: [],
    invisibleCards: [],
  })

  const { data, inProgress, error, inProgressResend } = useSelector(selectCardList)

  const handleClick: HandleClick = ({ status, ownershipBank, id, isClickable }) => {
    if (!isClickable) {
      return
    }
    if (config[status]) {
      setStatusCardType(status)
      setIsOpenedModal(true)
      cardPopupShowGtm(config[status].gtmTitle)
    } else {
      const redirectUrl = `${window.location.origin}/${
        ownershipBank !== OwnershipBank.IMPORTEDCARDS ? 'cardmts' : 'cards'
      }/${id}`

      window.location.href = redirectUrl
    }
  }

  const debouncedResend = useDebouncedCallback(() => {
    dispatch(fetchGetCardListMain({ isResend: true }))
  }, DELAY)

  const resendHandleClick = () => {
    cardsReloadClickGtm()
    dispatch(setInProgressResend(true))
    debouncedResend()
  }

  const handleToggle = () => {
    cardsHideClickGtm(isOpenedAccordion)

    return isSetOpenedAccordion(!isOpenedAccordion)
  }

  useDidMount(() => {
    if (isEmpty(data) && isEmpty(error)) {
      dispatch(fetchGetCardListMain({ isResend: false }))

      showWidgetGtm(newMainWigetNames.karty)
    }
  })

  useEffect(() => {
    if (!isEmpty(data)) {
      setCardsData(getSortedCards(data))
    }
  }, [data])

  const cantSeeWidget =
    isEmpty(cardsData.invisibleCards) &&
    isEmpty(cardsData.visibleCards) &&
    isEmpty(data.openedRequests) &&
    isEmpty(error) &&
    !fantomCards

  const ref = useRef<HTMLDivElement | null>(null)

  const { isVisible } = useBlockInVisionOnce(ref)

  useEffect(() => {
    if (isVisible && !inProgress) {
      seeingCardsWidgetGtm()
    }
  }, [inProgress, isVisible])

  useEffect(() => {
    if (!cantSeeWidget && !error && !inProgress) {
      showCardsWidgetGtm()
    }
  }, [cantSeeWidget, error, inProgress])

  if (inProgress) {
    return <Skeleton marginBottom={theme.spacings.xs2} />
  }

  if (cantSeeWidget) {
    return null
  }

  if (error) {
    cardsErrorGtm()
  }

  const AccordionContent = error ? (
    <ResendComponent inProgress={inProgressResend} onClick={resendHandleClick} />
  ) : (
    <>
      <RequestedCards openedRequests={data.openedRequests} />
      <DebitCards
        isVisibleCards={isVisibleCards}
        setIsVisibleCards={setIsVisibleCards}
        cardsData={cardsData}
        onClick={handleClick}
        isHidden={isHiddenBalance}
        inProgressFA={inProgressFA}
        fantomCards={fantomCards}
      />
    </>
  )

  const accordionItem = [
    {
      opened: isOpenedAccordion,
      title: 'Карты',
      text: AccordionContent,
    },
  ]

  return (
    <div>
      <StyledAccordion
        contentRef={ref}
        onWrapperClick={cardsWidgetClickGtm}
        className={classNames.DISABLE_DIVIDER}
        items={accordionItem}
        onToggle={handleToggle}
        size="lg"
      />
      <ModalMapping
        isOpened={isOpenedModal}
        modalName={ModalNames.CARD_STATUSES}
        setModal={setIsOpenedModal}
        statusCardType={statusCardType}
      />
    </div>
  )
}
