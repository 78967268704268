const PHONE_COUNTRY_CODE_MASK = /^\+7/g
const defaultPhoneAlias = '+7 NNN NNN-NN-NN'

export enum FormatAliasPhone {
  DEFAULT = '+7 NNN NNN-NN-NN',
  DIGIT10 = '10_DIGIT', // 9012345678
  DIGIT11 = '11_DIGIT', // 79012345678
  SYMBOLS12 = '12_SYMBOLS', // +79012345678
  SYMBOLS16 = '16_SYMBOLS', // +7 NNN NNN NN NN
}

type ClearPhoneNumberOptions = {
  skipCountryCode?: boolean
}

export const formatPhoneNumber = (phoneNumber: string, formatAlias = FormatAliasPhone.DEFAULT, countryCode = '7') => {
  let clearPhone = clearPhoneNumber(phoneNumber)
  const isDefaultAlias = formatAlias === defaultPhoneAlias
  const isDigit10 = formatAlias === FormatAliasPhone.DIGIT10
  const isDigit11 = formatAlias === FormatAliasPhone.DIGIT11
  const isSymbols12 = formatAlias === FormatAliasPhone.SYMBOLS12
  const isSymbols16 = formatAlias === FormatAliasPhone.SYMBOLS16

  if (clearPhone[0] === '7' || clearPhone[0] === '8') {
    clearPhone = clearPhone.slice(1)
  }

  const match = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(clearPhone)

  if (match && isDefaultAlias) {
    return `+${countryCode}\u00a0${match[1]}\u00a0${match[2]}-${match[3]}-${match[4]}`
  }

  if (match && isSymbols16) {
    return `+${countryCode}\u00a0${match[1]}\u00a0${match[2]}\u00a0${match[3]}\u00a0${match[4]}`
  }

  if (match && (isDigit11 || isSymbols12)) {
    return `${isSymbols12 ? '+' : ''}${countryCode}${clearPhone}`
  }

  if (match && isDigit10) {
    return `${clearPhone}`
  }

  return null
}

export const clearPhoneNumber = (phoneNumber: string, options?: ClearPhoneNumberOptions) => {
  let preparedPhoneNumber = phoneNumber?.replace(/[^+\d]/g, '')

  if (!options?.skipCountryCode) {
    preparedPhoneNumber = preparedPhoneNumber?.replace(PHONE_COUNTRY_CODE_MASK, '')
  }

  return preparedPhoneNumber || ''
}
