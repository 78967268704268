import { Dictionary } from '@reduxjs/toolkit'

export enum DataLayerEvents {
  BANNER_CAROUSEL_SHOW,
  BANNER_CAROUSEL_SEEING,
  BANNER_CAROUSEL_CLICK,
  BANNER_CAROUSEL_CLOSE_CLICK,
  LIMIT_FA_SHOW,
  LIMIT_FA_SEEING,
  LIMIT_FA_CLICK,
  FANTOM_PRODUCT_SHOW,
  FANTOM_PRODUCT_SEEING,
  FANTOM_PRODUCT_CLICK,
  FANTOM_PRODUCT_CLOSE_CLICK,
}

export enum TypeProductGtm {
  LOAN = 'Loan',
  CARD = 'Card',
}

export enum OfferProducGtm {
  NCPK,
  CC,
  DEFAULT,
}

export type ConfigParamsByEvent = Record<DataLayerEvents, Dictionary<string>>
