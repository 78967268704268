import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import { AxiosResponse } from 'axios'
import { profileApi } from '@api/dot-net/api'
import { AuthState, SetAuthAction } from '@reducers/auth/types'
import { UserProfile } from '@utils/authHelper/types'
import { authSelector } from '@selectors/auth'
import { RootState } from '@root/store'

const NAMESPACE = 'auth'

const initialState: AuthState = {
  isAuthenticated: false,
  profile: {} as UserProfile,
  inProgress: true,
  error: null,
}

export const loadProfile = createAsyncThunk(
  `${NAMESPACE}/loadProfile`,
  async () => {
    const response = (await profileApi.apiProfileMtsProfileGet()) as AxiosResponse<UserProfile>

    return response.data
  },
  {
    condition(arg, { getState }): boolean | undefined {
      const response = authSelector(getState() as RootState)

      return isEmpty(response.profile) && !response.inProgress
    },
  }
)

const auth = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setAuthenticated(state, action: PayloadAction<SetAuthAction>) {
      state.isAuthenticated = action.payload
    },
    clearProfile(state) {
      state.profile = {} as UserProfile
      state.inProgress = false
    },
    setProfile(state, action) {
      state.profile = action.payload
    },
    setInProgress(state, action) {
      state.inProgress = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadProfile.pending, (state) => {
      state.inProgress = true
    })
    builder.addCase(loadProfile.rejected, (state) => {
      state.profile = {} as UserProfile
      state.inProgress = false
    })
    builder.addCase(loadProfile.fulfilled, (state, action) => {
      state.profile = action.payload
      state.inProgress = false
    })
  },
})

export const { setAuthenticated, clearProfile, setProfile, setInProgress, setError } = auth.actions

export default auth.reducer
