import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dboLoanControllerApi2 } from '@api/ump/dbo-loan'
import { InitialDboLoanListState } from '@reducers/credits/type'

const NAMESPACE = 'loanList'

const initialState: InitialDboLoanListState = {
  inProgress: false,
  error: null,
  data: [],
}

export const fetchDboLoanList = createAsyncThunk(`${NAMESPACE}/fetchGetDboLoans`, async () => {
  const timeOutPromise = new Promise<never>((_, reject) => {
    setTimeout(() => reject(new Error('Время ожидания истекло')), 10000)
  })

  const { data: dboLoans } = await Promise.race([dboLoanControllerApi2.fetchLoans(), timeOutPromise])

  return dboLoans
})

const dboLoanList = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDboLoanList.pending, (state) => {
      state.data = []
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchDboLoanList.rejected, (state, action) => {
      state.error = action.error
      state.inProgress = false
      state.data = []
    })
    builder.addCase(fetchDboLoanList.fulfilled, (state, action) => {
      state.inProgress = false
      state.error = null
      state.data = [...action.payload]
    })
  },
})

export const dboLoanListReducer = dboLoanList.reducer
