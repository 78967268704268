import React, { FC } from 'react'
import { GatewayProtoOpenedRequest } from '@open-api/ump/debit-cards'
import { Card } from './Card'
import { getStatusFilter, getStatusTranform } from './utils'

type RequestCardsProps = {
  openedRequests: GatewayProtoOpenedRequest[]
}

export const RequestedCards: FC<RequestCardsProps> = ({ openedRequests }) => (
  <>
    {getStatusFilter(openedRequests)?.map((request) => (
      <Card
        key={request.productId}
        name={request.productId}
        link={`/spa/cards/signDocument?requestId=${request.requestId}`}
        status={getStatusTranform(request.statusCode)}
        statusCode={request.statusCode}
      />
    ))}
  </>
)
