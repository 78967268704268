import { GatewayProtoBankCard, GatewayProtoImportedCard, GatewayProtoLinkedCard } from '@open-api/ump/debit-cards'

export enum OwnershipBank {
  BANKCARDS = 'bankCards',
  IMPORTEDCARDS = 'importedCards',
  LINKEDCARDS = 'linkedCards',
}

export enum CardType {
  GENERAL_CARD = 'GENERAL_CARD',
  EMONEY_ACCOUNT = 'EMONEY_ACCOUNT',
  BOUND_CARD = 'BOUND_CARD',
}

export type GatewayCardType =
  | (Omit<GatewayProtoBankCard, 'status'> & { status: Status })
  | GatewayProtoLinkedCard
  | GatewayProtoImportedCard

export type ResultCardData = GatewayCardType & {
  ownershipBank: OwnershipBank
  status?: Status
  showAccount?: boolean
  bindingId?: string
  name?: string
  id?: string
  type?: CardType | string
  isProductVisible?: boolean
  productCode?: string
}
export interface CardsData {
  visibleCards: ResultCardData[]
  invisibleCards: ResultCardData[]
}

export enum Status {
  'BLOCKED' = 'BLOCKED',
  'READY_TO_RECEIVE' = 'READY_TO_RECEIVE',
  'CLOSED' = 'CLOSED',
  'SOFTBLOCK' = 'SOFTBLOCK',
  'ACTIVE' = 'ACTIVE',
  'ON_REISSUE' = 'ON_REISSUE',
}

interface Props {
  status: Status
  id: string
  ownershipBank: OwnershipBank
  isClickable: boolean
}

export type HandleClick = (props: Props) => void

export type MarkOwnership = (cards: GatewayCardType[], ownershipBank: OwnershipBank) => ResultCardData[]

export type AddCardOfAnotherBankProps = { showBorder?: boolean }
