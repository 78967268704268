export enum DeviceType {
  IOS = 'ios',
  ANDROID = 'android',
  OTHER = 'other',
}

export const getDeviceType = (): DeviceType => {
  const userAgent = navigator.userAgent.toLowerCase()

  if (/iphone|ipad|ipod/.test(userAgent)) {
    return DeviceType.IOS
  }
  if (/android/.test(userAgent)) {
    return DeviceType.ANDROID
  }

  return DeviceType.OTHER
}
