import { ContextMenuChildProps } from '@mtsbank/ui-kit/build/components/molecules/ContextMenu'
import { Dependence, NavigationMenu, PaymentZone } from '@components/Sidebar/types'
import {
  BANK_PRODUCTS_PATH,
  CATEGORIES_PATH,
  HISTORY_OPERATIONS_PATH,
  LOANS_PATH,
  MAIN_PATH,
  MAIN_OFFERS_PATH,
} from '@root/constants'
import { paymentHost } from '@api/dot-net/api'
import { authHelper } from '@utils/authHelper/AuthHelper'
import {
  allSitesAboutCompanyCLickGtm,
  allSitesBusinessCLickGtm,
  allSitesGovernmentCLickGtm,
  allSitesInvestorsCLickGtm,
  allSitesMTSMediaCLickGtm,
  allSitesPartnersCLickGtm,
  linkToMainCLickGtm,
} from '@root/utils/gtm/header/events'
import {
  autoPaymentsCLickGtm,
  bankProductsClickGtm,
  historyClickGtm,
  offersProductsClickGtm,
  paymentsClickGtm,
} from '@root/utils/gtm/mainPage/events'

const AUTOPAYMENT_PATH = `${paymentHost}/autopayment` as const
const PROFILE_PATH = `${paymentHost}/profile` as const
const TEMPLATES_PATH = `${paymentHost}/aps` as const
const CARDS_PATH = `${paymentHost}/cards` as const
const SUPPORT_PATH = 'https://support.mts.ru' as const

// todo пемеименовать в меню и перенести в sidebar
export const navigationMenu: NavigationMenu[] = [
  { url: authHelper.isAuthenticated() ? MAIN_PATH : `${paymentHost}${MAIN_PATH}`, text: 'Главная' },
  { url: CATEGORIES_PATH, text: 'Переводы и платежи' },
  { url: TEMPLATES_PATH, text: 'Шаблоны и автоплатежи', display: PaymentZone.AUTHORIZED },
  { url: `${CARDS_PATH}`, text: 'Карты и счета', display: PaymentZone.AUTHORIZED },
  {
    url: `${LOANS_PATH}`,
    text: 'Кредиты',
    display: PaymentZone.AUTHORIZED,
    dependence: Dependence.LOANS,
  },
  {
    url: authHelper?.isBankClient ? HISTORY_OPERATIONS_PATH : `${paymentHost}${HISTORY_OPERATIONS_PATH}`,
    text: 'История операций',
    display: PaymentZone.AUTHORIZED,
  },
  { url: BANK_PRODUCTS_PATH, text: 'Банковские продукты' },
  { url: MAIN_OFFERS_PATH, text: 'Предложения' },
  { url: AUTOPAYMENT_PATH, text: 'Автоплатежи', display: PaymentZone.UNAUTHORIZED },
  { url: PROFILE_PATH, text: 'Профиль', display: PaymentZone.AUTHORIZED },
  { url: SUPPORT_PATH, text: 'Поддержка', target: '_blank' },
]

// todo нужно привести к единому виду навигациоонное меню для мобилки и десктопа
export const navigationMenuDesktop: NavigationMenu[] = [
  {
    url: authHelper?.isBankClient ? MAIN_PATH : `${paymentHost}${MAIN_PATH}`,
    text: 'Главная',
    gtmEvent: linkToMainCLickGtm,
  },
  {
    url: CATEGORIES_PATH,
    text: authHelper?.isBankClient ? 'Платежи' : 'Платежи и переводы',
    gtmEvent: paymentsClickGtm,
  },
  { url: AUTOPAYMENT_PATH, text: 'Автоплатежи', display: PaymentZone.UNAUTHORIZED, gtmEvent: autoPaymentsCLickGtm },
  {
    url: authHelper?.isBankClient ? HISTORY_OPERATIONS_PATH : `${paymentHost}${HISTORY_OPERATIONS_PATH}`,
    text: 'История',
    display: PaymentZone.AUTHORIZED,
    gtmEvent: historyClickGtm,
  },
  { url: BANK_PRODUCTS_PATH, text: 'Банковские продукты', gtmEvent: bankProductsClickGtm },
  { url: MAIN_OFFERS_PATH, text: 'Предложения', gtmEvent: offersProductsClickGtm },
]

export const siteListMenu: ({ gtmEvent: () => void } & ContextMenuChildProps)[] = [
  {
    id: 1,
    text: 'Бизнесу',
    link: 'https://mts.ru/business',
    gtmEvent: allSitesBusinessCLickGtm,
  },
  {
    id: 2,
    text: 'Госзаказчикам',
    link: 'https://mts.ru/gos',
    gtmEvent: allSitesGovernmentCLickGtm,
  },
  {
    id: 3,
    text: 'О компании',
    link: 'https://mts.ru/about',
    gtmEvent: allSitesAboutCompanyCLickGtm,
  },
  {
    id: 4,
    text: 'Поставщикам и партнерам',
    link: 'https://tenders.mts.ru/info',
    gtmEvent: allSitesPartnersCLickGtm,
  },
  {
    id: 5,
    text: 'МТС/Медиа',
    link: 'https://media.mts.ru',
    gtmEvent: allSitesMTSMediaCLickGtm,
  },
  {
    id: 6,
    text: 'Investor Relations',
    link: 'http://ir.mts.ru/home',
    gtmEvent: allSitesInvestorsCLickGtm,
  },
]

export { AUTOPAYMENT_PATH, PROFILE_PATH, TEMPLATES_PATH }
