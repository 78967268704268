import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import {
  CheckTransferRes,
  GetTransferListReq,
  GetTransferListRes,
  GetTransferStatusReq,
} from '@open-api/ump/transfer-by-phone'
import { GetTransferByPhoneConstraintsReq, TransferByPhoneConstraintsRes } from '@open-api/ump/reference-book'
import { referenceBookApi } from '@api/ump/reference-book'
import { transferByPhoneApi } from '@api/ump/transfer-by-phone'
import { SbpState, ParamsFetchCheckTransfer } from '@reducers/sbp/types'
import { fetchConfirmTransferApi, fetchInitiateTransferApi } from '@root/components/SBP/api'
import { InitialTransferRes } from '@components/SBP/types'
import { baseInitialState } from '@reducers/baseInitialState'
import { isAxiosError } from '@root/types/reducers'
import { processingTransferListResp } from '@components/SBP/utils'

const NAMESPACE = 'sbp'

const initialState: SbpState = {
  transferByPhone: {
    isTransferTypeSBP: false,
    transferList: { ...baseInitialState },
    checkTransfer: { ...baseInitialState, reqParams: null, isUsedTransferId: false },
    initiateTransfer: { ...baseInitialState },
    confirmTransfer: { ...baseInitialState },
    transferStatus: { ...baseInitialState },
  },
  referenceBook: {
    transferByPhoneConstraints: { ...baseInitialState },
  },
}

const fetchTransferList = createAsyncThunk<GetTransferListRes, GetTransferListReq>(
  `${NAMESPACE}/fetchTransferList`,
  async (params: GetTransferListReq, { rejectWithValue }) => {
    // const params2 = { recipientPhone: '+79652772945', senderPhone: '+79264720175' } //prod
    try {
      const response = await transferByPhoneApi.getTransferList('v1', {
        senderPhone: params.senderPhone,
        recipientPhone: params.recipientPhone,
      })

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }
    }
  }
)

const fetchTransferByPhoneConstraints = createAsyncThunk(
  `${NAMESPACE}/fetchTransferByPhoneConstraints`,
  async (params: GetTransferByPhoneConstraintsReq): Promise<TransferByPhoneConstraintsRes> => {
    const response = await referenceBookApi.getTransferByPhoneConstraints('v2', params)

    return response.data
  }
)

const fetchCheckTransfer = createAsyncThunk<CheckTransferRes, ParamsFetchCheckTransfer>(
  `${NAMESPACE}/fetchCheckTransfer`,
  async (params, { rejectWithValue }) => {
    try {
      const transferByPhoneParams = omit(params, ['signal'])

      const response = await transferByPhoneApi.checkTransfer('v1', transferByPhoneParams, { signal: params.signal })

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }
    }
  }
)

const fetchInitiateTransfer = createAsyncThunk<InitialTransferRes, string>(
  `${NAMESPACE}/fetchInitiateTransfer`,
  async (transferId, { rejectWithValue }) => {
    try {
      const response = await fetchInitiateTransferApi(transferId)

      return response.data
    } catch (error) {
      const errRes: SerializedError = error.response.data

      return rejectWithValue(errRes)
    }
  }
)

const fetchConfirmTransfer = createAsyncThunk(
  `${NAMESPACE}/fetchConfirmTransfer`,
  async ({ transferId, otpCode }: { transferId: string; otpCode: string }, { rejectWithValue }) => {
    // todo брать из сгенерированного api
    try {
      const response = await fetchConfirmTransferApi(transferId, otpCode)

      return response.data
    } catch (err) {
      const errRes: { code: number; details: string; message: string } = err.response.data

      return rejectWithValue(errRes)
    }
  }
)

const fetchGetTransfersStatus = createAsyncThunk(
  `${NAMESPACE}/fetchGetTransfersStatus`,
  async (getTransferStatusReq: GetTransferStatusReq) => {
    const result = await transferByPhoneApi.getTransferStatus('v1', getTransferStatusReq)

    return result.data
  }
)

const sbp = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetTransferList(state) {
      state.transferByPhone.transferList = { ...baseInitialState }
    },
    resetSBP(state) {
      state.transferByPhone = { ...initialState.transferByPhone }
      state.referenceBook.transferByPhoneConstraints = { ...baseInitialState }
    },
    resetCheckTransfer(state) {
      state.transferByPhone.checkTransfer = { ...baseInitialState, reqParams: null, isUsedTransferId: false }
    },

    setIsUsedTransferId(state, action) {
      state.transferByPhone.checkTransfer = {
        ...state.transferByPhone.checkTransfer,
        isUsedTransferId: action.payload,
      }
    },
    setIsTransferTypeSBP(state, action) {
      state.transferByPhone.isTransferTypeSBP = action.payload
    },
    runCheckTransfer(state) {
      state.transferByPhone.checkTransfer.inProgress = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransferList.pending, (state) => {
      state.transferByPhone.transferList = { ...baseInitialState }
    })
    builder.addCase(fetchTransferList.rejected, (state, action) => {
      state.transferByPhone.transferList.error = action.payload
      state.transferByPhone.transferList.inProgress = false
    })
    builder.addCase(fetchTransferList.fulfilled, (state, action) => {
      const response = action.payload

      state.transferByPhone.transferList.data = processingTransferListResp(response)
      state.transferByPhone.transferList.inProgress = false
    })

    builder.addCase(fetchTransferByPhoneConstraints.pending, (state) => {
      state.referenceBook.transferByPhoneConstraints = { ...baseInitialState }
    })
    builder.addCase(fetchTransferByPhoneConstraints.rejected, (state, action) => {
      state.referenceBook.transferByPhoneConstraints.error = action.error
      state.referenceBook.transferByPhoneConstraints.inProgress = false
    })
    builder.addCase(fetchTransferByPhoneConstraints.fulfilled, (state, action) => {
      state.referenceBook.transferByPhoneConstraints.data = action.payload
      state.referenceBook.transferByPhoneConstraints.inProgress = false
    })

    builder.addCase(fetchCheckTransfer.pending, (state) => {
      state.transferByPhone.checkTransfer = { ...baseInitialState, reqParams: null, isUsedTransferId: false }
    })
    builder.addCase(fetchCheckTransfer.rejected, (state, action) => {
      state.transferByPhone.checkTransfer.error = action.payload
      state.transferByPhone.checkTransfer.inProgress = false
    })
    builder.addCase(fetchCheckTransfer.fulfilled, (state, action) => {
      state.transferByPhone.checkTransfer.data = action.payload
      state.transferByPhone.checkTransfer.inProgress = false
      state.transferByPhone.checkTransfer.reqParams = action.meta.arg
    })

    builder.addCase(fetchInitiateTransfer.pending, (state) => {
      state.transferByPhone.initiateTransfer = { ...baseInitialState }
    })
    builder.addCase(fetchInitiateTransfer.rejected, (state, action) => {
      const errorData = action.payload as { error: SerializedError } /// todo Kshp исправит

      state.transferByPhone.initiateTransfer.error = errorData.error
      state.transferByPhone.initiateTransfer.inProgress = false
    })
    builder.addCase(fetchInitiateTransfer.fulfilled, (state, action) => {
      state.transferByPhone.initiateTransfer.data = action.payload
      state.transferByPhone.initiateTransfer.inProgress = false
    })

    builder.addCase(fetchConfirmTransfer.pending, (state) => {
      state.transferByPhone.confirmTransfer = { ...baseInitialState }
    })
    builder.addCase(fetchConfirmTransfer.rejected, (state, action) => {
      state.transferByPhone.confirmTransfer.error = action.payload
      state.transferByPhone.confirmTransfer.inProgress = false
    })
    builder.addCase(fetchConfirmTransfer.fulfilled, (state, action) => {
      state.transferByPhone.confirmTransfer.data = action.payload
      state.transferByPhone.confirmTransfer.inProgress = false
    })

    builder.addCase(fetchGetTransfersStatus.pending, (state) => {
      state.transferByPhone.transferStatus.inProgress = isEmpty(state.transferByPhone.transferStatus.data)
    })
    builder.addCase(fetchGetTransfersStatus.rejected, (state, action) => {
      state.transferByPhone.transferStatus.inProgress = false
      state.transferByPhone.transferStatus.error = action.payload
    })
    builder.addCase(fetchGetTransfersStatus.fulfilled, (state, action) => {
      state.transferByPhone.transferStatus.inProgress = false
      state.transferByPhone.transferStatus.data = action.payload
    })
  },
})

export {
  fetchTransferList,
  fetchTransferByPhoneConstraints,
  fetchCheckTransfer,
  fetchInitiateTransfer,
  fetchConfirmTransfer,
  fetchGetTransfersStatus,
}

export const { resetSBP, setIsUsedTransferId, setIsTransferTypeSBP, runCheckTransfer } = sbp.actions

export const sbpReducer = sbp.reducer
