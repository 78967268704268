import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { paymentApi, processingApi } from '@api/dot-net/api'
import {
  OperationDetailsQuery,
  OperationDetailsState,
  RequestFinish3DSQuery,
} from '@reducers/mobilePayment/operationDetails/type'
import { OperationDetails, OperationResponse } from '@generated/api'
import { setRefNumber } from '@reducers/mobilePayment/refNumber/refNumber'

const NAMESPACE = 'operationDetails'
const NAMESPACE_GET = `${NAMESPACE}/GET`
const NAMESPACE_FINISH3DS = `${NAMESPACE}/FINISH3DS`

interface Headers {
  ref_number?: string
}

const initialState: OperationDetailsState = {
  inProgress: false,
  error: null,
  data: null,
}

const fetchOperationDetails = createAsyncThunk(
  `${NAMESPACE_GET}`,
  async ({ mdOrder, refNum }: OperationDetailsQuery): Promise<OperationDetails> => {
    const headers: Headers = {}

    if (typeof refNum !== 'undefined') {
      headers.ref_number = refNum
    }

    const response = await processingApi.apiProcessingGetOperationDetailsGet(mdOrder, refNum || '', {
      headers,
    })

    return response.data
  }
)

const requestFinish3ds = createAsyncThunk(
  `${NAMESPACE_FINISH3DS}`,
  async ({ mdOrder, paRes, cRes }: RequestFinish3DSQuery, thunkAPI): Promise<OperationResponse> => {
    try {
      const response = paRes
        ? await paymentApi.apiPaymentFinish3dsPost({ mdOrder, xRes: paRes })
        : await paymentApi.apiPaymentFinish3ds2Post({ mdOrder, xRes: cRes })

      thunkAPI.dispatch(
        setRefNumber({
          mdOrder: response.data.mdOrder,
          refNum: response.data.refNumber,
        })
      )

      return response.data
    } catch (error) {
      if (error.response && error.response.mdOrder) {
        thunkAPI.dispatch(
          setRefNumber({
            mdOrder: error.response.mdOrder,
            refNum: error.response.refNumber,
          })
        )
      }
      thunkAPI.rejectWithValue(error.response.data)

      return error.response.data
    }
  }
)

const operationDetails = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setOperationDetails(state, action) {
      state.data = { ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOperationDetails.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchOperationDetails.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(fetchOperationDetails.fulfilled, (state, action) => {
      state.inProgress = false
      state.data = { ...action.payload }
    })

    builder.addCase(requestFinish3ds.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(requestFinish3ds.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
      state.data = action.payload
    })
    builder.addCase(requestFinish3ds.fulfilled, (state, action) => {
      state.inProgress = false
      state.data = { ...action.payload }
    })
  },
})

export { fetchOperationDetails, requestFinish3ds }

export const operationDetailsReducer = operationDetails.reducer
export const { setOperationDetails } = operationDetails.actions
