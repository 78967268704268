// tslint:disable
/**
 * Deposits API
 * Вклады – продукт, помогающий клиентам сохранять и увеличивать накопления на выгодных и комфортных условиях как в физических, так и дистанционных каналах
 *
 * The version of the OpenAPI document: 1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface ConfigurationInfo
 */
export interface ConfigurationInfo {
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isRequisitesVisible?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isDepositCloseAvailable?: boolean
  /**
   *
   * @type {number}
   * @memberof ConfigurationInfo
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof ConfigurationInfo
   */
  currency?: string
  /**
   *
   * @type {number}
   * @memberof ConfigurationInfo
   */
  term?: number
  /**
   *
   * @type {string}
   * @memberof ConfigurationInfo
   */
  payPeriod?: string
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  capitalization?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  addCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  getCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isPromocodeAvailable?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isGetMaxRateAvailable?: boolean
}
/**
 *
 * @export
 * @interface CurrencyDto
 */
export interface CurrencyDto {
  /**
   *
   * @type {string}
   * @memberof CurrencyDto
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof CurrencyDto
   */
  countryFlagUrl?: string
  /**
   *
   * @type {string}
   * @memberof CurrencyDto
   */
  fullName?: string
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {number}
   * @memberof ErrorResponse
   */
  errorCode?: number
  /**
   *
   * @type {number}
   * @memberof ErrorResponse
   */
  internalCode?: number
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  errorCause?: string
}
/**
 *
 * @export
 * @interface MtsBankException
 */
export interface MtsBankException {
  /**
   *
   * @type {MtsBankExceptionCause}
   * @memberof MtsBankException
   */
  cause?: MtsBankExceptionCause
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankException
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  httpStatus?: MtsBankExceptionHttpStatusEnum
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankExceptionCauseSuppressed>}
   * @memberof MtsBankException
   */
  suppressed?: Array<MtsBankExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  localizedMessage?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MtsBankExceptionHttpStatusEnum {
  _100CONTINUE = '100 CONTINUE',
  _101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  _102PROCESSING = '102 PROCESSING',
  _103CHECKPOINT = '103 CHECKPOINT',
  _200OK = '200 OK',
  _201CREATED = '201 CREATED',
  _202ACCEPTED = '202 ACCEPTED',
  _203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  _204NOCONTENT = '204 NO_CONTENT',
  _205RESETCONTENT = '205 RESET_CONTENT',
  _206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  _207MULTISTATUS = '207 MULTI_STATUS',
  _208ALREADYREPORTED = '208 ALREADY_REPORTED',
  _226IMUSED = '226 IM_USED',
  _300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  _301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  _302FOUND = '302 FOUND',
  _302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  _303SEEOTHER = '303 SEE_OTHER',
  _304NOTMODIFIED = '304 NOT_MODIFIED',
  _305USEPROXY = '305 USE_PROXY',
  _307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  _308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  _400BADREQUEST = '400 BAD_REQUEST',
  _401UNAUTHORIZED = '401 UNAUTHORIZED',
  _402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  _403FORBIDDEN = '403 FORBIDDEN',
  _404NOTFOUND = '404 NOT_FOUND',
  _405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  _406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  _407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  _408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  _409CONFLICT = '409 CONFLICT',
  _410GONE = '410 GONE',
  _411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  _412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  _413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  _413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  _414URITOOLONG = '414 URI_TOO_LONG',
  _414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  _415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  _416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  _417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  _418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  _419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  _420METHODFAILURE = '420 METHOD_FAILURE',
  _421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  _422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  _423LOCKED = '423 LOCKED',
  _424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  _425TOOEARLY = '425 TOO_EARLY',
  _426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  _428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  _429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  _431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  _451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  _500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  _501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  _502BADGATEWAY = '502 BAD_GATEWAY',
  _503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  _504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  _505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  _506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  _507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  _508LOOPDETECTED = '508 LOOP_DETECTED',
  _509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  _510NOTEXTENDED = '510 NOT_EXTENDED',
  _511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

/**
 *
 * @export
 * @interface MtsBankExceptionCause
 */
export interface MtsBankExceptionCause {
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankExceptionCause
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCause
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankExceptionCauseSuppressed>}
   * @memberof MtsBankExceptionCause
   */
  suppressed?: Array<MtsBankExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCause
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface MtsBankExceptionCauseStackTrace
 */
export interface MtsBankExceptionCauseStackTrace {
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  classLoaderName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  moduleName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  moduleVersion?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  methodName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  fileName?: string
  /**
   *
   * @type {number}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  lineNumber?: number
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  className?: string
  /**
   *
   * @type {boolean}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  nativeMethod?: boolean
}
/**
 *
 * @export
 * @interface MtsBankExceptionCauseSuppressed
 */
export interface MtsBankExceptionCauseSuppressed {
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface ProductConditionV2
 */
export interface ProductConditionV2 {
  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  depTypeCode?: string
  /**
   *
   * @type {boolean}
   * @memberof ProductConditionV2
   */
  enableAddCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductConditionV2
   */
  enableGetCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductConditionV2
   */
  enableProlongation?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductConditionV2
   */
  enableCapitalization?: boolean
  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  ratePayPeriod?: string
  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  terminationRules?: string
  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  currency?: string
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  term?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  minAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  maxAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  irreducibleAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  yieldRate?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  finalRate?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  increaseRate?: number
  /**
   *
   * @type {Array<Segment>}
   * @memberof ProductConditionV2
   */
  segments?: Array<Segment>
}
/**
 *
 * @export
 * @interface ProductResponseV2
 */
export interface ProductResponseV2 {
  /**
   *
   * @type {string}
   * @memberof ProductResponseV2
   */
  prodName?: string
  /**
   *
   * @type {string}
   * @memberof ProductResponseV2
   */
  offerUrl?: string
  /**
   *
   * @type {Array<CurrencyDto>}
   * @memberof ProductResponseV2
   */
  availableCurrencies?: Array<CurrencyDto>
  /**
   *
   * @type {Array<ProductConditionV2>}
   * @memberof ProductResponseV2
   */
  conditions?: Array<ProductConditionV2>
  /**
   *
   * @type {ConfigurationInfo}
   * @memberof ProductResponseV2
   */
  configurationInfo?: ConfigurationInfo
}
/**
 *
 * @export
 * @interface ResponseApiProductResponseV2
 */
export interface ResponseApiProductResponseV2 {
  /**
   *
   * @type {ProductResponseV2}
   * @memberof ResponseApiProductResponseV2
   */
  data?: ProductResponseV2
  /**
   *
   * @type {ErrorResponse}
   * @memberof ResponseApiProductResponseV2
   */
  error?: ErrorResponse
}
/**
 *
 * @export
 * @interface Segment
 */
export interface Segment {
  /**
   *
   * @type {string}
   * @memberof Segment
   */
  segmentName?: string
  /**
   *
   * @type {string}
   * @memberof Segment
   */
  segmentCode?: string
  /**
   *
   * @type {number}
   * @memberof Segment
   */
  intRate?: number
}

/**
 * DepositProductApi - axios parameter creator
 * @export
 */
export const DepositProductApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения условий по вкладу
     * @param {string} [prodCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsForClient(prodCode?: string, options: any = {}): RequestArgs {
      const localVarPath = `/invest-deposits-deposit/v2.0/product/condition-list`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      if (prodCode !== undefined) {
        localVarQueryParameter['prodCode'] = prodCode
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DepositProductApi - functional programming interface
 * @export
 */
export const DepositProductApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения условий по вкладу
     * @param {string} [prodCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsForClient(
      prodCode?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseApiProductResponseV2> {
      const localVarAxiosArgs = DepositProductApiAxiosParamCreator(configuration).getProductsForClient(
        prodCode,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DepositProductApi - factory interface
 * @export
 */
export const DepositProductApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод для получения условий по вкладу
     * @param {string} [prodCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsForClient(prodCode?: string, options?: any): AxiosPromise<ResponseApiProductResponseV2> {
      return DepositProductApiFp(configuration).getProductsForClient(prodCode, options)(axios, basePath)
    },
  }
}

/**
 * DepositProductApi - object-oriented interface
 * @export
 * @class DepositProductApi
 * @extends {BaseAPI}
 */
export class DepositProductApi extends BaseAPI {
  /**
   *
   * @summary Метод для получения условий по вкладу
   * @param {string} [prodCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepositProductApi
   */
  public getProductsForClient(prodCode?: string, options?: any) {
    return DepositProductApiFp(this.configuration).getProductsForClient(prodCode, options)(this.axios, this.basePath)
  }
}
