import React, { FC } from 'react'
import Link, { LinkProps as NextLinkProps } from 'next/link'
import { CustomLink, CustomLinkProps } from '@components/NextLink/CustomLink'

export const NextLink: FC<NextLinkProps & CustomLinkProps> = ({
  href,
  legacyBehavior,
  as,
  locale,
  onMouseEnter,
  onTouchStart,
  passHref,
  prefetch,
  replace,
  scroll,
  shallow,
  children,
  disableHover,
  disablePointerEvent,
  ...kitLinkProps
}) => {
  const nextLinkProps = {
    href,
    legacyBehavior,
    as,
    locale,
    onMouseEnter,
    onTouchStart,
    passHref,
    prefetch,
    replace,
    scroll,
    shallow,
  }

  return (
    <Link {...nextLinkProps} passHref>
      <CustomLink {...kitLinkProps}>{children}</CustomLink>
    </Link>
  )
}
