import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { CurrencySymbolsType, theme, useDidMount } from '@mtsbank/ui-kit'
import { fetchDepositList } from '@reducers/deposits/deposits'
import { selectDeposits } from '@selectors/deposits'
import { classNames, StyledAccordion } from '@components/Styled/StyledAccordion'
import { Skeleton } from '@components/NewMain/Skeleton'
import { ResendComponent } from '@components/NewMain/ResendComponent'
import { BalanceState, ItemType, ProductItem } from '@components/NewMain/types'
import { CardItemList } from '@components/NewMain/CardItemList'
import { iconCurrencyMapping } from '@components/NewMain/utils'
import {
  depositsErrorGtm,
  depositsHideClickGtm,
  depositsReloadClickGtm,
  newMainWigetNames,
  showWidgetGtm,
} from '@root/utils/gtm/newMainPageEvents'
import { depositsWidgetClickGtm, seeingDepositsWidgetGtm, showDepositsWidgetGtm } from '@root/utils/gtm/mainPage/events'
import { useBlockInVisionOnce } from '@root/hooks/useBlockInVision'

export const Deposits: FC<BalanceState> = ({ isHiddenBalance }) => {
  const dispatch = useDispatch()
  const { inProgress, error, isResent, data } = useSelector(selectDeposits)
  const deposits = data?.bankDeposits || []
  const [opened, setOpened] = useState(true)

  useDidMount(() => {
    if (isEmpty(data) && isEmpty(error)) {
      dispatch(fetchDepositList({ isResent: false }))
      showWidgetGtm(newMainWigetNames.vklady)
    }
  })

  const handleResend = useCallback(() => {
    depositsReloadClickGtm()
    dispatch(fetchDepositList({ isResent: true }))
  }, [dispatch])

  const toggleHandler = () => {
    depositsHideClickGtm(opened)

    setOpened(!opened)
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const { isVisible } = useBlockInVisionOnce(ref)

  useEffect(() => {
    if (isVisible && !inProgress) {
      seeingDepositsWidgetGtm()
    }
  }, [inProgress, isVisible])

  useEffect(() => {
    if (deposits.length > 0 && error && !inProgress) {
      showDepositsWidgetGtm()
    }
  }, [deposits.length, error, inProgress])

  if (inProgress) {
    return <Skeleton withCir marginBottom={theme.spacings.md} />
  }

  const depositItems = deposits.map(
    (deposit): ProductItem => ({
      id: deposit.id,
      title: deposit.name,
      amount: !isHiddenBalance && deposit.balanceInfo?.amount,
      currency: deposit.balanceInfo?.currency as CurrencySymbolsType,
      icon: iconCurrencyMapping[deposit.balanceInfo?.currency],
      urlDetails: `/spa/deposits/${deposit.number}`,
    })
  )

  if (error) {
    depositsErrorGtm()
  }

  const AccordionContent = error ? (
    <ResendComponent inProgress={isResent} onClick={handleResend} />
  ) : (
    depositItems.length > 0 && <CardItemList productItems={depositItems} itemType={ItemType.Deposits} />
  )

  return AccordionContent ? (
    <StyledAccordion
      contentRef={ref}
      onWrapperClick={depositsWidgetClickGtm}
      className={classNames.DISABLE_DIVIDER}
      items={[{ title: 'Вклады', text: AccordionContent, opened }]}
      onToggle={toggleHandler}
    />
  ) : null
}
