import { createAsyncThunk, createSlice, createAction, SerializedError } from '@reduxjs/toolkit'
import { ConfirmOtpRes, ModelError, SendOtpReq } from '@open-api/ump/sms-informer'
import { smsInformerApi } from '@api/ump/sms-informer'
import { SmsInformerState } from '@reducers/smsInformer/types'

const NAMESPACE = 'smsInformer'

const initialState: SmsInformerState = {
  inProgress: false,
  isCodeResend: false,
  enabled: false,
  codeChecked: false,
  error: null,
  type: null,
}

const sendCode = createAsyncThunk(
  `${NAMESPACE}/sendCode`,
  async ({ rboId, type }: SendOtpReq): Promise<ConfirmOtpRes> => {
    const response = await smsInformerApi.sendOtp('v1', {
      rboId,
      type,
    })

    return response.data
  }
)

const withPayloadType =
  <T>() =>
  (t: T) => ({ payload: t })

const checkCode = {
  action: `${NAMESPACE}/checkCode/`,
  get pending() {
    return createAction(`${this.action}/pending`)
  },
  get rejected() {
    return createAction(`${this.action}/rejected/`, withPayloadType<ModelError | SerializedError>())
  },
  get fulfilled() {
    return createAction(`${this.action}/fulfilled/`, withPayloadType<ConfirmOtpRes>())
  },
}

const smsInformer = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendCode.pending, (state) => {
      state.inProgress = true
      state.enabled = true
      state.error = null
      state.codeChecked = false
    })
    builder.addCase(sendCode.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(sendCode.fulfilled, (state, action) => {
      state.inProgress = false
      state.type = action.meta?.arg?.type
    })
    builder.addCase(checkCode.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(checkCode.rejected, (state, action) => {
      state.inProgress = false
      state.error = { ...action.payload }
    })
    builder.addCase(checkCode.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      enabled: false,
      inProgress: false,
      codeChecked: true,
    }))
  },
})

export { sendCode, checkCode }
export default smsInformer.reducer
