import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FieldProps } from 'formik'

import debounce from 'lodash.debounce'
import { MoneyInput, InputHelperText, MoneyInputProps } from '@mtsbank/ui-kit'
import { InputErrorMessage } from '@components/InputErrorMessage/InputErrorMessage'

interface MoneyProps extends FieldProps<string>, MoneyInputProps {
  hint: string
  shouldTouchOnChange?: boolean
  shouldTouchOnBlur?: boolean
  onChange?: (value: string) => void
  decimalLimit: number
  onBlur?: (event: ChangeEvent) => void
  onClear?: () => void
}

export const MoneyInputField: FC<MoneyProps> = ({
  field,
  form: { touched, errors, setFieldTouched, setFieldValue },
  onChange,
  onBlur,
  hint,
  shouldTouchOnChange = true,
  shouldTouchOnBlur = false,
  size = 'lg',
  onClear,
  ...rest
}) => {
  const touchedItem = touched[field.name]
  const errorItem = errors[field.name]
  const [hasError, setHasError] = useState<boolean>(touchedItem && Boolean(errorItem))
  const setHasErrorDebounced = useMemo(
    () =>
      debounce((hasError: boolean) => setHasError(hasError), 100, {
        leading: false,
        trailing: true,
      }),
    [setHasError]
  )
  const handleChange = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value)
      } else {
        setFieldValue(field.name, value)
        if (shouldTouchOnChange) {
          setFieldTouched(field.name, true, false)
        }
      }
    },
    [field.name, onChange, setFieldTouched, setFieldValue, shouldTouchOnChange]
  )

  const handleClear = useCallback(() => {
    if (onClear) {
      onClear()
    } else {
      setFieldValue(field.name, '')
    }
  }, [field.name, setFieldValue, onClear])

  useEffect(() => {
    setHasErrorDebounced(touchedItem && Boolean(errorItem))
  }, [touchedItem, errorItem, setHasErrorDebounced])

  const handleBlur = useCallback(
    (event: ChangeEvent) => {
      // todo вернуть если потребуется
      /* if (isNumber(rest.decimalLimit)) {
        const amountValue = field.value === '' ? '' : toNumber(field.value).toFixed(rest.decimalLimit)

        setFieldValue(field.name, amountValue, false)
        setFieldTouched(field.name, true, false)
      } */

      if (onBlur) {
        onBlur(event)
      }

      if (shouldTouchOnBlur) {
        setFieldTouched(field.name, true, false)
      }

      field.onBlur(event)
    },
    [field, onBlur, setFieldTouched, shouldTouchOnBlur]
  )

  return (
    <>
      <MoneyInput
        {...field}
        {...rest}
        size={size}
        autoComplete="off"
        data-testid={field.name}
        hasError={hasError}
        onClear={handleClear}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasError && <InputErrorMessage name={field.name} />}
      {hint && <InputHelperText>{hint}</InputHelperText>}
    </>
  )
}
