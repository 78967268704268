import React, { FC, useState } from 'react'
import { Modal } from '@mtsbank/ui-kit'
import { ModalMappingProps, ModalNames } from '@components/Modals/type'
import { WhySberCommission } from '@components/Modals/WhySberCommission'
import { AntifrodError } from '@components/Modals/AntifrodError/AntifrodError'
import { CardStatuses } from '@components/Modals/CardsStatuses/CardsStatuses'
import { Info } from '@components/Modals/Info/Info'
import { OtpConfirmation } from '@components/DepositConstructor/OtpConfirmation'

const modalMapping = {
  [ModalNames.WHY_SBER_COMMISSION]: WhySberCommission,
  [ModalNames.ANTIFROD_ERROR]: AntifrodError,
  [ModalNames.CARD_STATUSES]: CardStatuses,
  [ModalNames.INFO]: Info,
  [ModalNames.OTP_CONFIRMATION]: OtpConfirmation,
}

export const ModalMapping: FC<ModalMappingProps> = ({ modalName, setModal, isOpened, size = 'md', ...rest }) => {
  const Component = modalMapping[modalName] as React.ElementType
  const [isUnclosableModal, setIsUnclosableModal] = useState(false)

  const handleClose = () => {
    if (!isUnclosableModal) {
      setModal(false)
    }
  }

  return (
    <Modal size={size} removeCloseHandlers={isUnclosableModal} open={isOpened} onClose={handleClose}>
      <Component {...rest} setIsUnclosableModal={setIsUnclosableModal} />
    </Modal>
  )
}
