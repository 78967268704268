import { window } from '@mtsbank/ui-kit'
import { SPA } from '@root/constants'
import {
  actionGroups,
  buttonLocations,
  eventActions,
  eventCategories,
  eventContents,
  eventContexts,
  eventLabels,
  events,
  screenNames,
  sendGmtEvent,
  StoreMarkets,
} from './baseFunctions'

/** Клик по кнопке Войти */
export const loginBtnClickGtm = () =>
  sendGmtEvent({
    event: events.mtsLogin,
    eventCategory: eventCategories.avtorizaciya,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.voiti,
    screenName: screenNames.gosuslugiInfo,
    eventContent: eventContents.banner,
    actionGroup: actionGroups.interactions,
  })

/** Клик по вопросу или иконке раскрытия вопроса в блоке FAQ */
export const questionClickGtm = (text) =>
  sendGmtEvent({
    event: events.mtsGosU,
    eventCategory: eventCategories.faq,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.vopros,
    screenName: screenNames.gosuslugiInfo,
    eventContext: text,
    actionGroup: actionGroups.interactions,
  })

/** Клик по гиперссылке в вопросах в блоке FAQ */
export const questionLinkClickGtm = (text) =>
  sendGmtEvent({
    event: events.mtsGosU,
    eventCategory: eventCategories.faq,
    eventAction: eventActions.linkClick,
    eventLabel: eventLabels.mts,
    screenName: screenNames.gosuslugiInfo,
    eventContext: text,
    actionGroup: actionGroups.interactions,
  })

/** Клик по кнопкам приложений в футере */
export const appBtnClickGtm = (storeMarkets: StoreMarkets) => {
  const [, screenName] = window.location.href.split(SPA)

  return sendGmtEvent({
    eventLabel: storeMarkets,
    screenName: `${SPA}${screenName}`,
    event: events.mtsEvent,
    eventCategory: eventCategories.app,
    eventAction: eventActions.appClick,
    eventContext: eventContexts.mtsMoney,
    buttonLocation: buttonLocations.footer,
    actionGroup: actionGroups.interactions,
  })
}
