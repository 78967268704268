import isNumberLodash from 'lodash/isNumber'
import { declOfNum } from '@mtsbank/ui-kit'

export const formatDecimal = (value: number, options?: Intl.NumberFormatOptions, locale = 'ru-RU') =>
  new Intl.NumberFormat(locale, options).format(value)

export const formatTerm = (term: number): string => declOfNum(term, ['месяц', 'месяца', 'месяцев'])

export const toNumber = (value: string | number): number => {
  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'string') {
    return Number(value.replace(',', '.'))
  }

  return value
}

const removeMinus = (value: string): string => value.replace('-', '')

export const isNumber = (number: number | string) => isNumberLodash(number) && !Number.isNaN(number)

export const formatNumber = (value: string | number): number => Number(`${value}`.replace(',', '.'))

export const removeZeros = (value: string): string => {
  const rxLeadingZeros = /^[\s0]+/
  const rxEndingZeros = /[\s0]+$/

  value = removeMinus(value.replace(rxLeadingZeros, ''))
  const array = value.split('.')

  if (array.length > 1) {
    array[1] = array[1].replace(rxEndingZeros, '')
    if (array[1].length === 0) {
      return array[0]
    }

    return `${array[0]}.${array[1]}`
  }

  return value
}

export const getSign = (value: number | string): string => {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value

  switch (true) {
    case numberValue > 0:
      return '+'
    case numberValue < 0:
      return '-'
    default:
      return ''
  }
}
