import { PersonalOffersProtoComponentProps } from '@open-api/ump/st-personal-offers'

type ExcludeProductsToTheHide = (
  state: PersonalOffersProtoComponentProps[],
  productsToTheHideID: string[]
) => PersonalOffersProtoComponentProps[]

export const excludeProductsToTheHide: ExcludeProductsToTheHide = (state, productsToTheHideID) =>
  state.reduce((acc, item) => {
    if (!productsToTheHideID.includes(item.offerId)) {
      acc.push(item)
    }

    return acc
  }, [])
