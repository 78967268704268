import { Props as AntifrodErrorProps } from '@components/Modals/AntifrodError/AntifrodError'
import { Props as CardsStatusesProps } from '@components/Modals/CardsStatuses/CardsStatuses'
import { Props as InfoProps } from '@components/Modals/Info/Info'

export enum ModalNames {
  WHY_SBER_COMMISSION = 'WHY_SBER_COMMISSION',
  ANTIFROD_ERROR = 'ANTIFROD_ERROR',
  CARD_STATUSES = 'CARD_STATUSES',
  INFO = 'INFO',
  OTP_CONFIRMATION = 'OTP_CONFIRMATION',
}

interface BasicProps {
  modalName: ModalNames
  isOpened: boolean
  setModal: (param?: unknown) => void
  size?: 'sm' | 'md' | 'lg'
}

export type ModalProps = BasicProps & (AntifrodErrorProps | CardsStatusesProps | InfoProps)

export type ModalMappingProps = BasicProps | ModalProps
