import React, { FC } from 'react'
import { formatMoney, Text, CurrencySymbolsType } from '@mtsbank/ui-kit'
import { GatewayProtoAmountMsg } from '@open-api/ump/debit-cards'
import { FourStars } from '@components/Styled/FourStars'
import { isNumber } from '@utils/format'
import { POINT } from '@components/DebitCard/constants'

const mask = Array(3).fill(POINT.repeat(4)).join(' ')

interface Props {
  isHidden?: boolean
  balanceInfo?: GatewayProtoAmountMsg
  panForAnotherBank?: string
}

export const AmountMoneyOrPanForAnotherBank: FC<Props> = ({ isHidden, balanceInfo, panForAnotherBank }) => (
  <>
    {isHidden ? (
      <FourStars />
    ) : (
      <>
        {isNumber(balanceInfo?.amount) && (
          <Text fontWeight="medium">
            {formatMoney(balanceInfo?.amount, balanceInfo.currency as CurrencySymbolsType)}
          </Text>
        )}
      </>
    )}
    {panForAnotherBank && <Text fontWeight="medium">{`${mask} ${panForAnotherBank}`}</Text>}
  </>
)
