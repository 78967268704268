import { window } from '@mtsbank/ui-kit'
import { GeneratePath, StateParams } from '@utils/route/types'

const generatePath: GeneratePath = (path, params) => {
  try {
    const paramKeys = Object.keys(params)
    let targetPath = path

    paramKeys.forEach((key) => {
      targetPath = targetPath.replace(/:\w+/, params[key])
    })

    return targetPath
  } catch (error) {
    return path
  }
}

const pushState = (params: StateParams) => {
  window.history.pushState({ ...window.history.state, ...params }, '')
}

export const route = {
  generatePath,
  pushState,
}
