// tslint:disable
/**
 * Application
 * Owner: MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: undefined
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface MtsBankErrorInfo
 */
export interface MtsBankErrorInfo {
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorResponse
 */
export interface MtsBankErrorResponse {
  /**
   *
   * @type {MtsBankErrorInfo}
   * @memberof MtsBankErrorResponse
   */
  error?: MtsBankErrorInfo
}
/**
 *
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
  /**
   *
   * @type {string}
   * @memberof OfferDto
   */
  state?: OfferDtoStateEnum
  /**
   *
   * @type {string}
   * @memberof OfferDto
   */
  productCode?: string
  /**
   *
   * @type {number}
   * @memberof OfferDto
   */
  limit?: number
}

/**
 * @export
 * @enum {string}
 */
export enum OfferDtoStateEnum {
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINE',
  INPROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
}

/**
 * OfferControllerV1Api - axios parameter creator
 * @export
 */
export const OfferControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод, позволяющий получить актуальные персональные предложения по клиенту.
     * @param {string} [productCode]
     * @param {Array<'APPROVE' | 'DECLINE' | 'IN_PROGRESS' | 'ERROR'>} [state]
     * @param {boolean} [isRepeatClient]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(
      productCode?: string,
      state?: Array<'APPROVE' | 'DECLINE' | 'IN_PROGRESS' | 'ERROR'>,
      isRepeatClient?: boolean,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/pos-offer-public/v1/offers`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (productCode !== undefined) {
        localVarQueryParameter['productCode'] = productCode
      }

      if (state) {
        localVarQueryParameter['state'] = state
      }

      if (isRepeatClient !== undefined) {
        localVarQueryParameter['isRepeatClient'] = isRepeatClient
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OfferControllerV1Api - functional programming interface
 * @export
 */
export const OfferControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод, позволяющий получить актуальные персональные предложения по клиенту.
     * @param {string} [productCode]
     * @param {Array<'APPROVE' | 'DECLINE' | 'IN_PROGRESS' | 'ERROR'>} [state]
     * @param {boolean} [isRepeatClient]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(
      productCode?: string,
      state?: Array<'APPROVE' | 'DECLINE' | 'IN_PROGRESS' | 'ERROR'>,
      isRepeatClient?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfferDto>> {
      const localVarAxiosArgs = OfferControllerV1ApiAxiosParamCreator(configuration).fetch(
        productCode,
        state,
        isRepeatClient,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OfferControllerV1Api - factory interface
 * @export
 */
export const OfferControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод, позволяющий получить актуальные персональные предложения по клиенту.
     * @param {string} [productCode]
     * @param {Array<'APPROVE' | 'DECLINE' | 'IN_PROGRESS' | 'ERROR'>} [state]
     * @param {boolean} [isRepeatClient]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(
      productCode?: string,
      state?: Array<'APPROVE' | 'DECLINE' | 'IN_PROGRESS' | 'ERROR'>,
      isRepeatClient?: boolean,
      options?: any
    ): AxiosPromise<Array<OfferDto>> {
      return OfferControllerV1ApiFp(configuration).fetch(productCode, state, isRepeatClient, options)(axios, basePath)
    },
  }
}

/**
 * OfferControllerV1Api - object-oriented interface
 * @export
 * @class OfferControllerV1Api
 * @extends {BaseAPI}
 */
export class OfferControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Метод, позволяющий получить актуальные персональные предложения по клиенту.
   * @param {string} [productCode]
   * @param {Array<'APPROVE' | 'DECLINE' | 'IN_PROGRESS' | 'ERROR'>} [state]
   * @param {boolean} [isRepeatClient]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferControllerV1Api
   */
  public fetch(
    productCode?: string,
    state?: Array<'APPROVE' | 'DECLINE' | 'IN_PROGRESS' | 'ERROR'>,
    isRepeatClient?: boolean,
    options?: any
  ) {
    return OfferControllerV1ApiFp(this.configuration).fetch(
      productCode,
      state,
      isRepeatClient,
      options
    )(this.axios, this.basePath)
  }
}
