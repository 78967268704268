import isNumber from 'lodash/isNumber'
import { OperationStatusType } from '@generated/api'
import { PENNY } from '@components/SBP/FormikHandler'
import { toNumber } from '@utils/format'
import { NoticeType } from '@components/Notice/types'

type GetStatus = (params: NoticeType) => string
export const getStatusText: GetStatus = (status) => {
  if (status === NoticeType.WAIT) {
    return 'В обработке'
  }
  if (status === NoticeType.SUCCESS) {
    return 'Успешно'
  }

  return 'Отклонено'
}

export const getState: GetStatus = (status) => {
  if (status === NoticeType.WAIT) {
    return String(OperationStatusType.IN_PROGRESS)
  }
  if (status === NoticeType.SUCCESS) {
    return String(OperationStatusType.DEPOSITED)
  }

  return String(OperationStatusType.DECLINED)
}

/**
 * Преобразует сумму в копейках к сумме в рублях
 * @param amountPenny number - сумма в копейках
 */
export const convertPennyToBanknote = (amountPenny: number) =>
  isNumber(toNumber(amountPenny)) ? Number((amountPenny / PENNY).toFixed(2)) : null
