import { Dictionary } from '@reduxjs/toolkit'

export const SbpEventLabels: Dictionary<string> = {
  spisatS: 'spisat_s',
  bankPoluchatelya: 'bank_poluchatelya',
  summaPlatezha: 'summa_platezha',
  soobschenie: 'soobschenie',
  telefon: 'telefon',
  vyborKarty: 'vybor_karty',
  ochistitSummu: 'ochistit_summu',
  ochistitSoobschenie: 'ochistit_soobschenie',
  ochistitBank: 'ochistit_bank',
  ochistit: 'ochistit',
  sbp: 'sbp',
  ochistitNomerTelefona: 'ochistit_nomer_telefona',
}

export const SbpEventContents: Dictionary<string> = {
  summaPlatezha: 'summa_platezha',
  soobscheniePoluchatelu: 'soobschenie_poluchatelu',
  bankPoluchatelya: 'bank_poluchatelya',
  telefon: 'telefon',
  spisatS: 'spisat_s',
}
