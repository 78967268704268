import { useEffect, useState } from 'react'
import { addEventListenerRAF } from '@root/utils/addEventListenerRAF'

export const useViewportHeight = () => {
  const [height, setHeight] = useState(() => window.innerHeight)

  useEffect(() => {
    const heightHandler = () => setHeight(window.innerHeight)

    return addEventListenerRAF(window, 'resize', heightHandler)
  }, [])

  return height
}
