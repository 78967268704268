import React, { useEffect } from 'react';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { theme, Text, Flex, Icon, useDidMount } from '@mtsbank/ui-kit';
import { PREMIUM_PATH, OFFER_PREMIUM } from '@root/constants';
import { selectPremiumState } from '@selectors/premium';
import { fetchIsPremiumPackage } from '@reducers/premiumPackage/premium';
import { premiumClickGtm, premiumClickPotentialWidgetGtm, showPremiumGtm, showPremiumPotentialGtm } from '@utils/gtm/mainPage/events';
import { selectBanners } from '@root/selectors/fullApproveWidget';
import { premiumPotentialProductData } from '@utils/gtm/premium/events';
const imageUrl = 'https://staticpayment.ssl.mts.ru/img/premium/package/widget_bg.png';
const mocksPremiumPatterns = ['PMT-PremiumPotencialBagNew', 'PMT-PremiumPotencialTaxiNew', 'PMT-PremiumPotencialKeyNew', 'PMT-PremiumPotencialSafeNew'].map(pattern => new RegExp(`^${pattern}`));
export const Premium = () => {
  const dispatch = useDispatch();
  const {
    package: {
      data: dataPremiumPackage,
      error: errorPremiumPackage
    }
  } = useSelector(selectPremiumState);
  const banners = useSelector(selectBanners);
  const {
    route
  } = useRouter();
  const isPremium = dataPremiumPackage.exist;
  const isPremiumPotential = !isEmpty(dataPremiumPackage) && !isPremium && banners?.some(banner => mocksPremiumPatterns.some(pattern => pattern.test(banner.numberPattern)));
  useDidMount(() => {
    if (isEmpty(dataPremiumPackage) && isEmpty(errorPremiumPackage)) {
      dispatch(fetchIsPremiumPackage());
    }
  });
  useEffect(() => {
    if (isPremium) {
      showPremiumGtm();
    }
    if (isPremiumPotential) {
      showPremiumPotentialGtm(premiumPotentialProductData);
    }
  }, [isPremium, isPremiumPotential]);
  if (errorPremiumPackage || !isPremiumPotential && !isPremium) {
    return null;
  }
  const handleClick = () => {
    if (route === PREMIUM_PATH || route === OFFER_PREMIUM) {
      return;
    }
    if (isPremiumPotential) {
      premiumClickPotentialWidgetGtm(premiumPotentialProductData);
      return;
    }
    premiumClickGtm();
  };
  return <Link passHref href={isPremium ? PREMIUM_PATH : OFFER_PREMIUM}>
      <PremiumLink onClick={handleClick}>
        <Flex direction="column">
          <Text color="inherit" fontWeight="medium">
            {dataPremiumPackage.vipServiceName || 'Премиум'}
          </Text>
          <Text color="inherit" size="md">
            Подробнее о привилегиях
          </Text>
        </Flex>
        <PremiumIcon withWrapper icon="baseX32/arrowRight" color={theme.colors.white} />
      </PremiumLink>
    </Link>;
};
const PremiumLink = styled.div`
  background: no-repeat url(${imageUrl}) right / contain #3d3f3f;
  display: flex;
  align-items: center;
  height: 88px;
  border-radius: 24px;
  padding: ${theme.spacings.xs}px ${theme.spacings.xs3}px ${theme.spacings.xs}px ${theme.spacings.xs}px;
  color: ${theme.colors.white};
  cursor: pointer;

  @media (min-width: ${theme.breakpoints.sm}px) {
    order: -1;
    border-radius: 16px;
    height: 66px;
    padding: ${theme.spacings.xs}px ${theme.spacings.xs}px ${theme.spacings.xs}px ${theme.spacings.sm}px;
  }
`;
const PremiumIcon = styled(Icon)`
  margin-left: auto;
`;