import { matches } from '@utils/creditCardType/lib/matches'
import { clone } from '@utils/creditCardType/lib/clone'
import { CreditCardType } from '../type'

export function addMatchingCardsToResults(
  cardNumber: string,
  cardConfiguration: CreditCardType,
  results: Array<CreditCardType>
): void {
  let i
  let patternLength

  for (i = 0; i < cardConfiguration.patterns.length; i++) {
    const pattern = cardConfiguration.patterns[i]

    if (matches(cardNumber, pattern)) {
      const clonedCardConfiguration = clone(cardConfiguration)

      if (Array.isArray(pattern)) {
        patternLength = String(pattern[0]).length
      } else {
        patternLength = String(pattern).length
      }

      if (cardNumber.length >= patternLength) {
        clonedCardConfiguration.matchStrength = patternLength
      }

      results.push(clonedCardConfiguration)
      break
    }
  }
}
