export enum LayoutType {
  BASE = 'base',
  WEB_VIEW = 'webView',
  AUTH = 'auth',
}

export interface LayoutProps {
  isWebView?: boolean
  withNps?: boolean
}
