// tslint:disable
/**
 * Service for applications with a limit (pos-limit-application)
 * Owner: Pos Stream
 *
 * The version of the OpenAPI document: undefined
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface ApplicationDto
 */
export interface ApplicationDto {
  /**
   * Идентификатор заявки
   * @type {number}
   * @memberof ApplicationDto
   */
  id: number
  /**
   * Статус
   * @type {string}
   * @memberof ApplicationDto
   */
  status: ApplicationDtoStatusEnum
  /**
   *
   * @type {string}
   * @memberof ApplicationDto
   */
  expirationDate: string
  /**
   *
   * @type {ProductDto}
   * @memberof ApplicationDto
   */
  product?: ProductDto
  /**
   *
   * @type {ShopDto}
   * @memberof ApplicationDto
   */
  shop: ShopDto
  /**
   *
   * @type {DecisionDto}
   * @memberof ApplicationDto
   */
  decision: DecisionDto
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationDtoStatusEnum {
  LIMIT = 'LIMIT',
  LIMITACCEPTED = 'LIMIT_ACCEPTED',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  ISSUED = 'ISSUED',
  APPROVEDDELIVERY = 'APPROVED_DELIVERY',
  APPROVEDEXPORT = 'APPROVED_EXPORT',
  CLIENTDATAREQUIRED = 'CLIENT_DATA_REQUIRED',
  DBOSIGNED = 'DBO_SIGNED',
  DOCVERIFY = 'DOC_VERIFY',
  DOCVERIFYONLINE = 'DOC_VERIFY_ONLINE',
  DOCVERIFYSALON = 'DOC_VERIFY_SALON',
  DOCVERIFYDBO = 'DOC_VERIFY_DBO',
  SCORING = 'SCORING',
  LOADER = 'LOADER',
  SCORBASKET = 'SCOR_BASKET',
  EXPIRED = 'EXPIRED',
}

/**
 * Решение
 * @export
 * @interface DecisionDto
 */
export interface DecisionDto {
  /**
   * Ставка
   * @type {number}
   * @memberof DecisionDto
   */
  rate: number
  /**
   * Минимальный период
   * @type {number}
   * @memberof DecisionDto
   */
  term: number
  /**
   * Лимит
   * @type {number}
   * @memberof DecisionDto
   */
  limit: number
}
/**
 *
 * @export
 * @interface MtsBankErrorInfo
 */
export interface MtsBankErrorInfo {
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorResponse
 */
export interface MtsBankErrorResponse {
  /**
   *
   * @type {MtsBankErrorInfo}
   * @memberof MtsBankErrorResponse
   */
  error?: MtsBankErrorInfo
}
/**
 *
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
  /**
   *
   * @type {number}
   * @memberof ProductDto
   */
  rate?: number
}
/**
 *
 * @export
 * @interface ShopDto
 */
export interface ShopDto {
  /**
   *
   * @type {string}
   * @memberof ShopDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ShopDto
   */
  url: string
}

/**
 * ApplicationControllerV1Api - axios parameter creator
 * @export
 */
export const ApplicationControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получение информации по заявке
     * @param {Array<'LIMIT' | 'LIMIT_ACCEPTED' | 'NEW' | 'REJECTED' | 'CANCELED' | 'ISSUED' | 'APPROVED_DELIVERY' | 'APPROVED_EXPORT' | 'CLIENT_DATA_REQUIRED' | 'DBO_SIGNED' | 'DOC_VERIFY' | 'DOC_VERIFY_ONLINE' | 'DOC_VERIFY_SALON' | 'DOC_VERIFY_DBO' | 'SCORING' | 'LOADER' | 'SCOR_BASKET' | 'EXPIRED'>} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchApplications(
      status: Array<
        | 'LIMIT'
        | 'LIMIT_ACCEPTED'
        | 'NEW'
        | 'REJECTED'
        | 'CANCELED'
        | 'ISSUED'
        | 'APPROVED_DELIVERY'
        | 'APPROVED_EXPORT'
        | 'CLIENT_DATA_REQUIRED'
        | 'DBO_SIGNED'
        | 'DOC_VERIFY'
        | 'DOC_VERIFY_ONLINE'
        | 'DOC_VERIFY_SALON'
        | 'DOC_VERIFY_DBO'
        | 'SCORING'
        | 'LOADER'
        | 'SCOR_BASKET'
        | 'EXPIRED'
      >,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'status' is not null or undefined
      if (status === null || status === undefined) {
        throw new RequiredError(
          'status',
          'Required parameter status was null or undefined when calling fetchApplications.'
        )
      }
      const localVarPath = `/loan-service-pos/v1/applications`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (status) {
        localVarQueryParameter['status'] = status
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ApplicationControllerV1Api - functional programming interface
 * @export
 */
export const ApplicationControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получение информации по заявке
     * @param {Array<'LIMIT' | 'LIMIT_ACCEPTED' | 'NEW' | 'REJECTED' | 'CANCELED' | 'ISSUED' | 'APPROVED_DELIVERY' | 'APPROVED_EXPORT' | 'CLIENT_DATA_REQUIRED' | 'DBO_SIGNED' | 'DOC_VERIFY' | 'DOC_VERIFY_ONLINE' | 'DOC_VERIFY_SALON' | 'DOC_VERIFY_DBO' | 'SCORING' | 'LOADER' | 'SCOR_BASKET' | 'EXPIRED'>} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchApplications(
      status: Array<
        | 'LIMIT'
        | 'LIMIT_ACCEPTED'
        | 'NEW'
        | 'REJECTED'
        | 'CANCELED'
        | 'ISSUED'
        | 'APPROVED_DELIVERY'
        | 'APPROVED_EXPORT'
        | 'CLIENT_DATA_REQUIRED'
        | 'DBO_SIGNED'
        | 'DOC_VERIFY'
        | 'DOC_VERIFY_ONLINE'
        | 'DOC_VERIFY_SALON'
        | 'DOC_VERIFY_DBO'
        | 'SCORING'
        | 'LOADER'
        | 'SCOR_BASKET'
        | 'EXPIRED'
      >,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationDto>> {
      const localVarAxiosArgs = ApplicationControllerV1ApiAxiosParamCreator(configuration).fetchApplications(
        status,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ApplicationControllerV1Api - factory interface
 * @export
 */
export const ApplicationControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Получение информации по заявке
     * @param {Array<'LIMIT' | 'LIMIT_ACCEPTED' | 'NEW' | 'REJECTED' | 'CANCELED' | 'ISSUED' | 'APPROVED_DELIVERY' | 'APPROVED_EXPORT' | 'CLIENT_DATA_REQUIRED' | 'DBO_SIGNED' | 'DOC_VERIFY' | 'DOC_VERIFY_ONLINE' | 'DOC_VERIFY_SALON' | 'DOC_VERIFY_DBO' | 'SCORING' | 'LOADER' | 'SCOR_BASKET' | 'EXPIRED'>} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchApplications(
      status: Array<
        | 'LIMIT'
        | 'LIMIT_ACCEPTED'
        | 'NEW'
        | 'REJECTED'
        | 'CANCELED'
        | 'ISSUED'
        | 'APPROVED_DELIVERY'
        | 'APPROVED_EXPORT'
        | 'CLIENT_DATA_REQUIRED'
        | 'DBO_SIGNED'
        | 'DOC_VERIFY'
        | 'DOC_VERIFY_ONLINE'
        | 'DOC_VERIFY_SALON'
        | 'DOC_VERIFY_DBO'
        | 'SCORING'
        | 'LOADER'
        | 'SCOR_BASKET'
        | 'EXPIRED'
      >,
      options?: any
    ): AxiosPromise<Array<ApplicationDto>> {
      return ApplicationControllerV1ApiFp(configuration).fetchApplications(status, options)(axios, basePath)
    },
  }
}

/**
 * ApplicationControllerV1Api - object-oriented interface
 * @export
 * @class ApplicationControllerV1Api
 * @extends {BaseAPI}
 */
export class ApplicationControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Получение информации по заявке
   * @param {Array<'LIMIT' | 'LIMIT_ACCEPTED' | 'NEW' | 'REJECTED' | 'CANCELED' | 'ISSUED' | 'APPROVED_DELIVERY' | 'APPROVED_EXPORT' | 'CLIENT_DATA_REQUIRED' | 'DBO_SIGNED' | 'DOC_VERIFY' | 'DOC_VERIFY_ONLINE' | 'DOC_VERIFY_SALON' | 'DOC_VERIFY_DBO' | 'SCORING' | 'LOADER' | 'SCOR_BASKET' | 'EXPIRED'>} status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationControllerV1Api
   */
  public fetchApplications(
    status: Array<
      | 'LIMIT'
      | 'LIMIT_ACCEPTED'
      | 'NEW'
      | 'REJECTED'
      | 'CANCELED'
      | 'ISSUED'
      | 'APPROVED_DELIVERY'
      | 'APPROVED_EXPORT'
      | 'CLIENT_DATA_REQUIRED'
      | 'DBO_SIGNED'
      | 'DOC_VERIFY'
      | 'DOC_VERIFY_ONLINE'
      | 'DOC_VERIFY_SALON'
      | 'DOC_VERIFY_DBO'
      | 'SCORING'
      | 'LOADER'
      | 'SCOR_BASKET'
      | 'EXPIRED'
    >,
    options?: any
  ) {
    return ApplicationControllerV1ApiFp(this.configuration).fetchApplications(status, options)(
      this.axios,
      this.basePath
    )
  }
}
