// tslint:disable
/**
 * Application
 * Owner: MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: undefined
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface CancelOrderRequest
 */
export interface CancelOrderRequest {
  /**
   *
   * @type {number}
   * @memberof CancelOrderRequest
   */
  operationID: number
  /**
   *
   * @type {string}
   * @memberof CancelOrderRequest
   */
  otpToken?: string
}
/**
 *
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
  /**
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  prepaymentKind: string
  /**
   *
   * @type {number}
   * @memberof CreateOrderRequest
   */
  amount: number
  /**
   *
   * @type {number}
   * @memberof CreateOrderRequest
   */
  contractCreditID: number
  /**
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  contractCreditNumber: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  recalcMode?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  otpToken?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  clientId?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  channelSysName?: string
}
/**
 *
 * @export
 * @interface CreateOrderResponse
 */
export interface CreateOrderResponse {
  /**
   *
   * @type {string}
   * @memberof CreateOrderResponse
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof CreateOrderResponse
   */
  operationID?: number
  /**
   *
   * @type {string}
   * @memberof CreateOrderResponse
   */
  expiryDate?: string
}
/**
 *
 * @export
 * @interface EmptyBodyResponse
 */
export interface EmptyBodyResponse {
  /**
   *
   * @type {string}
   * @memberof EmptyBodyResponse
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {Error}
   * @memberof ErrorResponse
   */
  error?: Error
}
/**
 *
 * @export
 * @interface FetchOrdersLightResponse
 */
export interface FetchOrdersLightResponse {
  /**
   *
   * @type {string}
   * @memberof FetchOrdersLightResponse
   */
  requestId?: string
  /**
   *
   * @type {Array<OrderEntityLightDto>}
   * @memberof FetchOrdersLightResponse
   */
  orderList?: Array<OrderEntityLightDto>
}
/**
 *
 * @export
 * @interface FetchOrdersRequest
 */
export interface FetchOrdersRequest {
  /**
   *
   * @type {string}
   * @memberof FetchOrdersRequest
   */
  state?: string
  /**
   *
   * @type {number}
   * @memberof FetchOrdersRequest
   */
  contractCreditID?: number
}
/**
 *
 * @export
 * @interface FetchOrdersResponse
 */
export interface FetchOrdersResponse {
  /**
   *
   * @type {string}
   * @memberof FetchOrdersResponse
   */
  requestId?: string
  /**
   *
   * @type {Array<OrderEntityDto>}
   * @memberof FetchOrdersResponse
   */
  orderList?: Array<OrderEntityDto>
}
/**
 *
 * @export
 * @interface FetchScheduleRequest
 */
export interface FetchScheduleRequest {
  /**
   *
   * @type {number}
   * @memberof FetchScheduleRequest
   */
  contractCreditID: number
  /**
   *
   * @type {string}
   * @memberof FetchScheduleRequest
   */
  startDate: string
  /**
   *
   * @type {string}
   * @memberof FetchScheduleRequest
   */
  endDate: string
}
/**
 *
 * @export
 * @interface FetchScheduleResponse
 */
export interface FetchScheduleResponse {
  /**
   *
   * @type {string}
   * @memberof FetchScheduleResponse
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof FetchScheduleResponse
   */
  contractCreditID?: number
  /**
   *
   * @type {Array<OrderDto>}
   * @memberof FetchScheduleResponse
   */
  operationList?: Array<OrderDto>
  /**
   *
   * @type {Array<ScheduleDto>}
   * @memberof FetchScheduleResponse
   */
  pmtSchedList?: Array<ScheduleDto>
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  uuid?: string
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  code?: number
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  externalCode?: number
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  details?: string
}
/**
 *
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  operationID?: string
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  creationDate?: string
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  planDate?: string
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  operationAmount?: number
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  operationKind?: string
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  orderID?: number
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  currentDebt?: number
}
/**
 *
 * @export
 * @interface OrderEntityDto
 */
export interface OrderEntityDto {
  /**
   *
   * @type {number}
   * @memberof OrderEntityDto
   */
  orderID?: number
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  operationID?: string
  /**
   *
   * @type {number}
   * @memberof OrderEntityDto
   */
  contractCreditID?: number
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  contractCreditNumber?: string
  /**
   *
   * @type {number}
   * @memberof OrderEntityDto
   */
  ownerID?: number
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  channelSysName?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  prepaymentKind?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  recalcMode?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  creationDate?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  orderDate?: string
  /**
   *
   * @type {number}
   * @memberof OrderEntityDto
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  orderState?: string
  /**
   *
   * @type {number}
   * @memberof OrderEntityDto
   */
  newMontlyAmount?: number
  /**
   *
   * @type {string}
   * @memberof OrderEntityDto
   */
  newExpDate?: string
  /**
   *
   * @type {number}
   * @memberof OrderEntityDto
   */
  newTerm?: number
  /**
   *
   * @type {number}
   * @memberof OrderEntityDto
   */
  currentDebt?: number
}
/**
 *
 * @export
 * @interface OrderEntityLightDto
 */
export interface OrderEntityLightDto {
  /**
   *
   * @type {number}
   * @memberof OrderEntityLightDto
   */
  orderID?: number
  /**
   *
   * @type {string}
   * @memberof OrderEntityLightDto
   */
  operationID?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityLightDto
   */
  channel?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityLightDto
   */
  prepaymentKind?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityLightDto
   */
  recalcMode?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityLightDto
   */
  creationDate?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityLightDto
   */
  orderDate?: string
  /**
   *
   * @type {number}
   * @memberof OrderEntityLightDto
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof OrderEntityLightDto
   */
  orderState?: string
  /**
   *
   * @type {string}
   * @memberof OrderEntityLightDto
   */
  changeDate?: string
}
/**
 *
 * @export
 * @interface ScheduleDto
 */
export interface ScheduleDto {
  /**
   *
   * @type {string}
   * @memberof ScheduleDto
   */
  date?: string
  /**
   *
   * @type {number}
   * @memberof ScheduleDto
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof ScheduleDto
   */
  currencyCode?: string
}

/**
 * OrderControllerV1Api - axios parameter creator
 * @export
 */
export const OrderControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary отзыв ранее созданной заявки ЧДП/ПДП
     * @param {CancelOrderRequest} cancelOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel1(cancelOrderRequest: CancelOrderRequest, options: any = {}): RequestArgs {
      // verify required parameter 'cancelOrderRequest' is not null or undefined
      if (cancelOrderRequest === null || cancelOrderRequest === undefined) {
        throw new RequiredError(
          'cancelOrderRequest',
          'Required parameter cancelOrderRequest was null or undefined when calling cancel1.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v1/OrderCan`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof cancelOrderRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cancelOrderRequest !== undefined ? cancelOrderRequest : {})
        : cancelOrderRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary получение заявок оформленных в канале ДБО
     * @param {FetchOrdersRequest} fetchOrdersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(fetchOrdersRequest: FetchOrdersRequest, options: any = {}): RequestArgs {
      // verify required parameter 'fetchOrdersRequest' is not null or undefined
      if (fetchOrdersRequest === null || fetchOrdersRequest === undefined) {
        throw new RequiredError(
          'fetchOrdersRequest',
          'Required parameter fetchOrdersRequest was null or undefined when calling fetch.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v1/PmtOrders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof fetchOrdersRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fetchOrdersRequest !== undefined ? fetchOrdersRequest : {})
        : fetchOrdersRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает историю заявлений на ДП
     * @param {number} [loanId]
     * @param {Array<string>} [prepaymentKind]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch1(loanId?: number, prepaymentKind?: Array<string>, options: any = {}): RequestArgs {
      const localVarPath = `/dbo-early-repayment/v1/orders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (loanId !== undefined) {
        localVarQueryParameter['loanId'] = loanId
      }

      if (prepaymentKind) {
        localVarQueryParameter['prepaymentKind'] = prepaymentKind
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary формирование новой заявки ЧДП/ПДП
     * @param {CreateOrderRequest} createOrderRequest
     * @param {string} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    save(createOrderRequest: CreateOrderRequest, clientId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'createOrderRequest' is not null or undefined
      if (createOrderRequest === null || createOrderRequest === undefined) {
        throw new RequiredError(
          'createOrderRequest',
          'Required parameter createOrderRequest was null or undefined when calling save.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v1/NewOrders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (clientId !== undefined && clientId !== null) {
        localVarHeaderParameter['Client-Id'] = String(clientId)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof createOrderRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createOrderRequest !== undefined ? createOrderRequest : {})
        : createOrderRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OrderControllerV1Api - functional programming interface
 * @export
 */
export const OrderControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary отзыв ранее созданной заявки ЧДП/ПДП
     * @param {CancelOrderRequest} cancelOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel1(
      cancelOrderRequest: CancelOrderRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptyBodyResponse> {
      const localVarAxiosArgs = OrderControllerV1ApiAxiosParamCreator(configuration).cancel1(
        cancelOrderRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary получение заявок оформленных в канале ДБО
     * @param {FetchOrdersRequest} fetchOrdersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(
      fetchOrdersRequest: FetchOrdersRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchOrdersResponse> {
      const localVarAxiosArgs = OrderControllerV1ApiAxiosParamCreator(configuration).fetch(fetchOrdersRequest, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает историю заявлений на ДП
     * @param {number} [loanId]
     * @param {Array<string>} [prepaymentKind]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch1(
      loanId?: number,
      prepaymentKind?: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchOrdersLightResponse> {
      const localVarAxiosArgs = OrderControllerV1ApiAxiosParamCreator(configuration).fetch1(
        loanId,
        prepaymentKind,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary формирование новой заявки ЧДП/ПДП
     * @param {CreateOrderRequest} createOrderRequest
     * @param {string} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    save(
      createOrderRequest: CreateOrderRequest,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse> {
      const localVarAxiosArgs = OrderControllerV1ApiAxiosParamCreator(configuration).save(
        createOrderRequest,
        clientId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OrderControllerV1Api - factory interface
 * @export
 */
export const OrderControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary отзыв ранее созданной заявки ЧДП/ПДП
     * @param {CancelOrderRequest} cancelOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel1(cancelOrderRequest: CancelOrderRequest, options?: any): AxiosPromise<EmptyBodyResponse> {
      return OrderControllerV1ApiFp(configuration).cancel1(cancelOrderRequest, options)(axios, basePath)
    },
    /**
     *
     * @summary получение заявок оформленных в канале ДБО
     * @param {FetchOrdersRequest} fetchOrdersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(fetchOrdersRequest: FetchOrdersRequest, options?: any): AxiosPromise<FetchOrdersResponse> {
      return OrderControllerV1ApiFp(configuration).fetch(fetchOrdersRequest, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает историю заявлений на ДП
     * @param {number} [loanId]
     * @param {Array<string>} [prepaymentKind]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch1(loanId?: number, prepaymentKind?: Array<string>, options?: any): AxiosPromise<FetchOrdersLightResponse> {
      return OrderControllerV1ApiFp(configuration).fetch1(loanId, prepaymentKind, options)(axios, basePath)
    },
    /**
     *
     * @summary формирование новой заявки ЧДП/ПДП
     * @param {CreateOrderRequest} createOrderRequest
     * @param {string} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    save(createOrderRequest: CreateOrderRequest, clientId?: string, options?: any): AxiosPromise<CreateOrderResponse> {
      return OrderControllerV1ApiFp(configuration).save(createOrderRequest, clientId, options)(axios, basePath)
    },
  }
}

/**
 * OrderControllerV1Api - object-oriented interface
 * @export
 * @class OrderControllerV1Api
 * @extends {BaseAPI}
 */
export class OrderControllerV1Api extends BaseAPI {
  /**
   *
   * @summary отзыв ранее созданной заявки ЧДП/ПДП
   * @param {CancelOrderRequest} cancelOrderRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerV1Api
   */
  public cancel1(cancelOrderRequest: CancelOrderRequest, options?: any) {
    return OrderControllerV1ApiFp(this.configuration).cancel1(cancelOrderRequest, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary получение заявок оформленных в канале ДБО
   * @param {FetchOrdersRequest} fetchOrdersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerV1Api
   */
  public fetch(fetchOrdersRequest: FetchOrdersRequest, options?: any) {
    return OrderControllerV1ApiFp(this.configuration).fetch(fetchOrdersRequest, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает историю заявлений на ДП
   * @param {number} [loanId]
   * @param {Array<string>} [prepaymentKind]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerV1Api
   */
  public fetch1(loanId?: number, prepaymentKind?: Array<string>, options?: any) {
    return OrderControllerV1ApiFp(this.configuration).fetch1(loanId, prepaymentKind, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary формирование новой заявки ЧДП/ПДП
   * @param {CreateOrderRequest} createOrderRequest
   * @param {string} [clientId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerV1Api
   */
  public save(createOrderRequest: CreateOrderRequest, clientId?: string, options?: any) {
    return OrderControllerV1ApiFp(this.configuration).save(
      createOrderRequest,
      clientId,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * OrderControllerV2Api - axios parameter creator
 * @export
 */
export const OrderControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary отзыв ранее созданной заявки ЧДП/ПДП
     * @param {CancelOrderRequest} cancelOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(cancelOrderRequest: CancelOrderRequest, options: any = {}): RequestArgs {
      // verify required parameter 'cancelOrderRequest' is not null or undefined
      if (cancelOrderRequest === null || cancelOrderRequest === undefined) {
        throw new RequiredError(
          'cancelOrderRequest',
          'Required parameter cancelOrderRequest was null or undefined when calling cancel.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v2/OrderCan`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof cancelOrderRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cancelOrderRequest !== undefined ? cancelOrderRequest : {})
        : cancelOrderRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary получение заявок оформленных в канале ДБО
     * @param {FetchOrdersRequest} fetchOrdersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOrders(fetchOrdersRequest: FetchOrdersRequest, options: any = {}): RequestArgs {
      // verify required parameter 'fetchOrdersRequest' is not null or undefined
      if (fetchOrdersRequest === null || fetchOrdersRequest === undefined) {
        throw new RequiredError(
          'fetchOrdersRequest',
          'Required parameter fetchOrdersRequest was null or undefined when calling fetchOrders.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v2/PmtOrders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof fetchOrdersRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fetchOrdersRequest !== undefined ? fetchOrdersRequest : {})
        : fetchOrdersRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary формирование новой заявки ЧДП/ПДП
     * @param {CreateOrderRequest} createOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOrder(createOrderRequest: CreateOrderRequest, options: any = {}): RequestArgs {
      // verify required parameter 'createOrderRequest' is not null or undefined
      if (createOrderRequest === null || createOrderRequest === undefined) {
        throw new RequiredError(
          'createOrderRequest',
          'Required parameter createOrderRequest was null or undefined when calling saveOrder.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v2/NewOrders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof createOrderRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createOrderRequest !== undefined ? createOrderRequest : {})
        : createOrderRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OrderControllerV2Api - functional programming interface
 * @export
 */
export const OrderControllerV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary отзыв ранее созданной заявки ЧДП/ПДП
     * @param {CancelOrderRequest} cancelOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(
      cancelOrderRequest: CancelOrderRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptyBodyResponse> {
      const localVarAxiosArgs = OrderControllerV2ApiAxiosParamCreator(configuration).cancel(cancelOrderRequest, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary получение заявок оформленных в канале ДБО
     * @param {FetchOrdersRequest} fetchOrdersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOrders(
      fetchOrdersRequest: FetchOrdersRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchOrdersResponse> {
      const localVarAxiosArgs = OrderControllerV2ApiAxiosParamCreator(configuration).fetchOrders(
        fetchOrdersRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary формирование новой заявки ЧДП/ПДП
     * @param {CreateOrderRequest} createOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOrder(
      createOrderRequest: CreateOrderRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse> {
      const localVarAxiosArgs = OrderControllerV2ApiAxiosParamCreator(configuration).saveOrder(
        createOrderRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OrderControllerV2Api - factory interface
 * @export
 */
export const OrderControllerV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary отзыв ранее созданной заявки ЧДП/ПДП
     * @param {CancelOrderRequest} cancelOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(cancelOrderRequest: CancelOrderRequest, options?: any): AxiosPromise<EmptyBodyResponse> {
      return OrderControllerV2ApiFp(configuration).cancel(cancelOrderRequest, options)(axios, basePath)
    },
    /**
     *
     * @summary получение заявок оформленных в канале ДБО
     * @param {FetchOrdersRequest} fetchOrdersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOrders(fetchOrdersRequest: FetchOrdersRequest, options?: any): AxiosPromise<FetchOrdersResponse> {
      return OrderControllerV2ApiFp(configuration).fetchOrders(fetchOrdersRequest, options)(axios, basePath)
    },
    /**
     *
     * @summary формирование новой заявки ЧДП/ПДП
     * @param {CreateOrderRequest} createOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOrder(createOrderRequest: CreateOrderRequest, options?: any): AxiosPromise<CreateOrderResponse> {
      return OrderControllerV2ApiFp(configuration).saveOrder(createOrderRequest, options)(axios, basePath)
    },
  }
}

/**
 * OrderControllerV2Api - object-oriented interface
 * @export
 * @class OrderControllerV2Api
 * @extends {BaseAPI}
 */
export class OrderControllerV2Api extends BaseAPI {
  /**
   *
   * @summary отзыв ранее созданной заявки ЧДП/ПДП
   * @param {CancelOrderRequest} cancelOrderRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerV2Api
   */
  public cancel(cancelOrderRequest: CancelOrderRequest, options?: any) {
    return OrderControllerV2ApiFp(this.configuration).cancel(cancelOrderRequest, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary получение заявок оформленных в канале ДБО
   * @param {FetchOrdersRequest} fetchOrdersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerV2Api
   */
  public fetchOrders(fetchOrdersRequest: FetchOrdersRequest, options?: any) {
    return OrderControllerV2ApiFp(this.configuration).fetchOrders(fetchOrdersRequest, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary формирование новой заявки ЧДП/ПДП
   * @param {CreateOrderRequest} createOrderRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderControllerV2Api
   */
  public saveOrder(createOrderRequest: CreateOrderRequest, options?: any) {
    return OrderControllerV2ApiFp(this.configuration).saveOrder(createOrderRequest, options)(this.axios, this.basePath)
  }
}

/**
 * ScheduleControllerV1Api - axios parameter creator
 * @export
 */
export const ScheduleControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules2(fetchScheduleRequest: FetchScheduleRequest, options: any = {}): RequestArgs {
      // verify required parameter 'fetchScheduleRequest' is not null or undefined
      if (fetchScheduleRequest === null || fetchScheduleRequest === undefined) {
        throw new RequiredError(
          'fetchScheduleRequest',
          'Required parameter fetchScheduleRequest was null or undefined when calling fetchSchedules2.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v1/PmtSchedule`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof fetchScheduleRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fetchScheduleRequest !== undefined ? fetchScheduleRequest : {})
        : fetchScheduleRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScheduleControllerV1Api - functional programming interface
 * @export
 */
export const ScheduleControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules2(
      fetchScheduleRequest: FetchScheduleRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchScheduleResponse> {
      const localVarAxiosArgs = ScheduleControllerV1ApiAxiosParamCreator(configuration).fetchSchedules2(
        fetchScheduleRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ScheduleControllerV1Api - factory interface
 * @export
 */
export const ScheduleControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules2(fetchScheduleRequest: FetchScheduleRequest, options?: any): AxiosPromise<FetchScheduleResponse> {
      return ScheduleControllerV1ApiFp(configuration).fetchSchedules2(fetchScheduleRequest, options)(axios, basePath)
    },
  }
}

/**
 * ScheduleControllerV1Api - object-oriented interface
 * @export
 * @class ScheduleControllerV1Api
 * @extends {BaseAPI}
 */
export class ScheduleControllerV1Api extends BaseAPI {
  /**
   *
   * @summary получение доступных операционных дней для заявки ЧДП/ПДП
   * @param {FetchScheduleRequest} fetchScheduleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScheduleControllerV1Api
   */
  public fetchSchedules2(fetchScheduleRequest: FetchScheduleRequest, options?: any) {
    return ScheduleControllerV1ApiFp(this.configuration).fetchSchedules2(fetchScheduleRequest, options)(
      this.axios,
      this.basePath
    )
  }
}

/**
 * ScheduleControllerV2Api - axios parameter creator
 * @export
 */
export const ScheduleControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules1(
      fetchScheduleRequest: FetchScheduleRequest,
      platform?: string,
      appVersion?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'fetchScheduleRequest' is not null or undefined
      if (fetchScheduleRequest === null || fetchScheduleRequest === undefined) {
        throw new RequiredError(
          'fetchScheduleRequest',
          'Required parameter fetchScheduleRequest was null or undefined when calling fetchSchedules1.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v2/PmtSchedule`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (platform !== undefined && platform !== null) {
        localVarHeaderParameter['Platform'] = String(platform)
      }

      if (appVersion !== undefined && appVersion !== null) {
        localVarHeaderParameter['App-Version'] = String(appVersion)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof fetchScheduleRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fetchScheduleRequest !== undefined ? fetchScheduleRequest : {})
        : fetchScheduleRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScheduleControllerV2Api - functional programming interface
 * @export
 */
export const ScheduleControllerV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules1(
      fetchScheduleRequest: FetchScheduleRequest,
      platform?: string,
      appVersion?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchScheduleResponse> {
      const localVarAxiosArgs = ScheduleControllerV2ApiAxiosParamCreator(configuration).fetchSchedules1(
        fetchScheduleRequest,
        platform,
        appVersion,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ScheduleControllerV2Api - factory interface
 * @export
 */
export const ScheduleControllerV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules1(
      fetchScheduleRequest: FetchScheduleRequest,
      platform?: string,
      appVersion?: string,
      options?: any
    ): AxiosPromise<FetchScheduleResponse> {
      return ScheduleControllerV2ApiFp(configuration).fetchSchedules1(
        fetchScheduleRequest,
        platform,
        appVersion,
        options
      )(axios, basePath)
    },
  }
}

/**
 * ScheduleControllerV2Api - object-oriented interface
 * @export
 * @class ScheduleControllerV2Api
 * @extends {BaseAPI}
 */
export class ScheduleControllerV2Api extends BaseAPI {
  /**
   *
   * @summary получение доступных операционных дней для заявки ЧДП/ПДП
   * @param {FetchScheduleRequest} fetchScheduleRequest
   * @param {string} [platform]
   * @param {string} [appVersion]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScheduleControllerV2Api
   */
  public fetchSchedules1(
    fetchScheduleRequest: FetchScheduleRequest,
    platform?: string,
    appVersion?: string,
    options?: any
  ) {
    return ScheduleControllerV2ApiFp(this.configuration).fetchSchedules1(
      fetchScheduleRequest,
      platform,
      appVersion,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * ScheduleControllerV3Api - axios parameter creator
 * @export
 */
export const ScheduleControllerV3ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules(fetchScheduleRequest: FetchScheduleRequest, options: any = {}): RequestArgs {
      // verify required parameter 'fetchScheduleRequest' is not null or undefined
      if (fetchScheduleRequest === null || fetchScheduleRequest === undefined) {
        throw new RequiredError(
          'fetchScheduleRequest',
          'Required parameter fetchScheduleRequest was null or undefined when calling fetchSchedules.'
        )
      }
      const localVarPath = `/dbo-early-repayment/v3/PmtSchedule`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof fetchScheduleRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fetchScheduleRequest !== undefined ? fetchScheduleRequest : {})
        : fetchScheduleRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScheduleControllerV3Api - functional programming interface
 * @export
 */
export const ScheduleControllerV3ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules(
      fetchScheduleRequest: FetchScheduleRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchScheduleResponse> {
      const localVarAxiosArgs = ScheduleControllerV3ApiAxiosParamCreator(configuration).fetchSchedules(
        fetchScheduleRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ScheduleControllerV3Api - factory interface
 * @export
 */
export const ScheduleControllerV3ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary получение доступных операционных дней для заявки ЧДП/ПДП
     * @param {FetchScheduleRequest} fetchScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSchedules(fetchScheduleRequest: FetchScheduleRequest, options?: any): AxiosPromise<FetchScheduleResponse> {
      return ScheduleControllerV3ApiFp(configuration).fetchSchedules(fetchScheduleRequest, options)(axios, basePath)
    },
  }
}

/**
 * ScheduleControllerV3Api - object-oriented interface
 * @export
 * @class ScheduleControllerV3Api
 * @extends {BaseAPI}
 */
export class ScheduleControllerV3Api extends BaseAPI {
  /**
   *
   * @summary получение доступных операционных дней для заявки ЧДП/ПДП
   * @param {FetchScheduleRequest} fetchScheduleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScheduleControllerV3Api
   */
  public fetchSchedules(fetchScheduleRequest: FetchScheduleRequest, options?: any) {
    return ScheduleControllerV3ApiFp(this.configuration).fetchSchedules(fetchScheduleRequest, options)(
      this.axios,
      this.basePath
    )
  }
}
