import styled from 'styled-components';
import { Flex, theme } from '@mtsbank/ui-kit';
const Styled = {
  Wrapper: styled(Flex)`
    @media (min-width: ${theme.breakpoints.sm}px) {
      flex-direction: row-reverse;
    }
  `,
  BodyWrapper: styled.a`
    margin-left: ${theme.spacings.xs2}px;
    color: ${theme.colors.neutral.g100};
    text-align: left;

    @media (min-width: ${theme.breakpoints.sm}px) {
      margin-right: ${theme.spacings.xs2}px;
      text-align: right;
    }
  `,
  IconWrapper: styled.a`
    line-height: 0;
  `
};
export { Styled };