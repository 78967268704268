import { formatMoney } from '@mtsbank/ui-kit'

export const DEFAULT_MAX_MESSAGE_LENGTH = 40 as const
export const DEFAULT_MAX_AMOUNT_IN_PENNY = 59999900 as const

export const DEFAULT_LIMITS = {
  maxAmount: DEFAULT_MAX_AMOUNT_IN_PENNY,
  maxMessageLength: DEFAULT_MAX_MESSAGE_LENGTH,
}
export const DEFAULT_BANK = { label: '', value: '' }

export const MTS_BANK_ID = '100000000017'

export const MTC_BANK = {
  bankId: MTS_BANK_ID,
  bankName: 'МТС Банк',
  defaultBank: false,
}

export const PRODUCT_TYPE = 'GENERAL_CARD'

export const CREDIT_CARD_FEE_MESSAGE = `За\u00A0использование кредитных средств взимается комиссия в\u00A0размере 3,9% плюс ${formatMoney(
  590,
  'RUB'
)}`
