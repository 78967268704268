import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import base64Url from 'base64url'
import debounce from 'lodash/fp/debounce'
import isEmpty from 'lodash/isEmpty'
import { ewalletProfileApi } from '@api/ump/ewallet-profile'
import { store } from '@root/store'
import { clearProfile, setAuthenticated, setProfile, setError, setInProgress } from '@reducers/auth/auth'
import { TypedStorage } from '@utils/authHelper/typedStorage'
import { AuthData, AuthType, EventName, Headers, UserProfile } from '@utils/authHelper/types'
import { LOGOUT_PATH, SPA_PAY_URL } from '@root/constants'

import { FormatAliasPhone, formatPhoneNumber } from '@utils/formatPhoneNumber'
import { parseHeaders } from '@utils/axios/helper'
import { uuid } from '../generateUuid'

export const LOGOUT_TIME = 900000 // 15 minutes in ms
const DELAY_DEBOUNCE = 3000 // 3 seconds in ms

class AuthHelper {
  private activityTimerId: number

  private logoutTimerId: number

  private authData: AuthData

  private profile: UserProfile

  private resolveReady

  public ready: Promise<boolean> = new Promise((resolve) => {
    this.resolveReady = resolve
  })

  constructor() {
    this.authData = this.getAuthData()
  }

  /**
   * Время истечения токена
   * @private
   */
  private get expiry() {
    return new Date(this.authData.expiry).getTime()
  }

  /**
   * clientId для web dbo
   * @public
   */
  public get clientId() {
    return this.authData?.client_id
  }

  /**
   * Данные авторизованного пользователя
   * @private
   */
  private get user() {
    return this.authData?.user_data
  }

  /**
   * Конфигурация axios, добавляет cookie и токен в заголовки запроса
   */
  public configureAxios = () => {
    axios.defaults.withCredentials = true
    axios.defaults.headers.common = {
      'Content-Type': 'application/json;charset=UTF-8',
    }

    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      if (!this.isAuthenticated()) {
        return config
      }

      const headers = parseHeaders({
        Authorization: this.getAuthHeader(),
        'client-id': this.clientId,
        'request-ID': uuid(),
        platform: 'web',
        'app-version': '1.0.0',
        ...config.headers,
      })

      return Object.assign(config, {
        headers,
      })
    })

    axios.interceptors.response.use(
      (response) => {
        const {
          headers: { authorization },
        } = response

        if (authorization) {
          const authToken = authorization.split(' ').pop()

          this.setToken(authToken)
          this.authData = this.getAuthData()

          this.initLogout()
        }

        return response
      },
      (error: AxiosError) => {
        if (error?.response) {
          if (error.response.status === 401) {
            this.logOut()
          }
        }

        return Promise.reject(error)
      }
    )
  }

  /**
   * Возвращает заголовок вида 'Bearer {token}'
   */
  private getAuthHeader = (): string => `Bearer ${this.getToken()}`

  /**
   * Возвращает токен в случае его наличия в localStorage
   */
  private getToken = (): string | null => TypedStorage.getString('access_token')

  /**
   * Сохраняет токен в localStorage
   * @param token
   */
  private setToken = (token: string) => TypedStorage.setItem('access_token', token)

  /**
   * Удаление токена авторизации из localStorage
   */
  private removeToken = () => TypedStorage.removeItem('access_token')

  /**
   * Удаление mtsProfile
   */

  private removeMtsProfile = () => {
    store.dispatch(clearProfile())
  }

  /**
   * Получение данных из токена авторизации в виде объекта
   */
  public getAuthData = (): AuthData | null => {
    let authData: AuthData

    try {
      const authDataEncode = this.getToken().split('.').pop()

      authData = JSON.parse(base64Url.decode(authDataEncode))
    } catch (error) {
      authData = null
    }

    return authData
  }

  /**
   * Получение профиля пользователя
   */

  public get userProfile() {
    return this.profile
  }

  /**
   * Проверка статуса клиента
   * Полностью идентифицированный клиент
   */
  public get isFullIdentified() {
    return this.profile?.authType === AuthType.FULL_IDENTIFIED
  }

  /**
   * Получение профиля пользователя
   */
  private loadProfile = () => {
    if (!this.isAuthenticated()) {
      return Promise.resolve(false)
    }

    try {
      const { profile } = store.getState().auth

      if (!isEmpty(profile)) {
        this.profile = profile
      } else {
        return this.fetchProfile()
      }
    } catch {
      return this.fetchProfile()
    }
  }

  /**
   * Загрузка профиля пользователя
   */
  fetchProfile = async () => {
    const headers: Headers = {}
    const token = localStorage.getItem('access_token')

    if (token) {
      headers.Authorization = `Bearer ${token}`

      try {
        const response = await ewalletProfileApi.getWallet()

        const mobilePhone = formatPhoneNumber(response.data.phone, FormatAliasPhone.SYMBOLS12)

        this.profile = {
          mobilePhone,
          authType: AuthType[response.data?.walletIdentificationLevel],
          userId: response.data?.userId,
        }

        store.dispatch(setProfile(this.profile))
        store.dispatch(setInProgress(false))
      } catch (error) {
        store.dispatch(setInProgress(false))
        store.dispatch(setError(error))
      }
    }
  }

  /**
   * Осуществляется проверка активности пользователя
   * если пользователь проявил активность в установленное время, то время до разлогинивания продлевается
   *
   * @private
   */
  private checkActivity = debounce(DELAY_DEBOUNCE, () => {
    if (this.activityTimerId) {
      clearTimeout(this.activityTimerId)
    }

    this.activityTimerId = window.setTimeout(() => this.logOut(), LOGOUT_TIME)
  })

  /**
   * Инициализация отслеживания активности пользователя
   * в случае установленного времени бездействия  происходит разлогинивание
   */
  private initActivity = () => {
    if (this.isAuthenticated()) {
      this.checkActivity()

      document.addEventListener('keypress', this.checkActivity)
      document.addEventListener('mousedown', this.checkActivity)
      document.addEventListener('mousemove', this.checkActivity)
      document.addEventListener('touchstart', this.checkActivity)
    }
  }

  /**
   * Инициализация таймера для разлогинивания пользователя в случае, если истечёт время токена авторизации
   */
  private initLogout = () => {
    if (this.logoutTimerId) {
      clearTimeout(this.logoutTimerId)
    }

    if (this.isAuthenticated()) {
      const logoutTime = this.expiry - Date.now()

      this.logoutTimerId = window.setTimeout(() => this.logOut(), logoutTime)
    }
  }

  /**
   * Определение типа идентификации из токена авторизации
   *
   * @private
   */
  public get profileType(): AuthType {
    if (this.user?.rbo_id) {
      return AuthType.BANK_CLIENT
    }

    return AuthType.ANONYMOUS
  }

  public get isBankClient(): boolean {
    return this.profileType === AuthType.BANK_CLIENT
  }

  /**
   * Возвращает признак состояния аутентификации
   */
  public isAuthenticated = (): boolean => {
    if (!this.authData) {
      return false
    }

    return this.expiry > Date.now()
  }

  /**
   * Инициализация хелпера, желательно выполнить один раз при запуске приложения
   */
  public initialize = async () => {
    this.initActivity()
    this.initLogout()
    this.configureAxios()
    await this.loadProfile()
    this.resolveReady(true)
  }

  /**
   * Проверка на наличие доступа в зависимости от типа идентификации
   *
   * @param authType
   * @param profileType
   */
  public hasAccess = (authType: AuthType, profileType: AuthType) => authType <= profileType

  /**
   * Поолучения данных пользователя из токена авторизации
   */
  public get userData() {
    return this.user
  }

  /**
   * При авторизации устанавливается sso_returnUrl, после авторизации происходит редирект на указанный uri
   */
  public onSignIn = () => {
    /*
    Temp for testing spa/pay/
     */
    const url = window.location.href

    localStorage.setItem('sso_returnUrl', url)
  }

  /**
   * Выходит из системы, удаляет токен из localStorage
   */
  public logOut = () => {
    if (window.location.host === 'localhost:8081') {
      return
    }

    try {
      store.dispatch(setAuthenticated(false))
      this.removeMtsProfile()
      this.removeToken()

      dispatchEvent(new Event(EventName.LOGOUT_EVENT))

      window.location.href = LOGOUT_PATH
    } catch (ex) {
      /**/
    }
  }
}

const authHelper = new AuthHelper()

export { authHelper, AuthHelper }
