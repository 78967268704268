import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@root/reducers'
import { selectAccounts } from '@selectors/accounts'
import { excludeProductsToTheHide } from '@reducers/fullApproveWidget/utils'
import { FullApproveState } from '@reducers/fullApproveWidget/types'

export const selectFullApproveWidget = (state: RootState) => state.profile.fullApproveWidget
export const selectGetOffer = createSelector(selectFullApproveWidget, (state) => state.getOffer)
export const selectBankAccounts = createSelector(selectAccounts, (state) => state.data?.bankAccounts || [])

export const selectLimit = createSelector(
  selectFullApproveWidget,
  (state) => state.getOffer.data?.componentType?.limit || null
)
export const selectGetOfferPage = createSelector(
  selectFullApproveWidget,
  (state) => state.getOffer.data?.componentType?.offerPage || null
)
export const selectGetOfferPopup = createSelector(
  selectFullApproveWidget,
  (state: FullApproveState) => state.getOffer.data?.componentType?.popup || null
)

export const selectBanners = createSelector(
  selectFullApproveWidget,
  (state) => state.getOffer.data?.componentType?.banners || null
)

export const selectMores = createSelector(
  selectFullApproveWidget,
  (state) => state.getOffer.data?.componentType?.mores || null
)

export const selectFantomCards = createSelector(selectFullApproveWidget, (state) => {
  const fantomCards = state.getOffer.data?.componentType?.fantomCards || []
  const productsToTheHide = state.responseHistory.productsToTheHideID

  return excludeProductsToTheHide(fantomCards, productsToTheHide)
})
export const selectFantomLoans = createSelector(selectFullApproveWidget, (state) => {
  const fantomLoans = state.getOffer.data?.componentType?.fantomLoans || []
  const productsToTheHide = state.responseHistory.productsToTheHideID

  return excludeProductsToTheHide(fantomLoans, productsToTheHide)
})

export const selectDepositProductBanners = createSelector(selectFullApproveWidget, (state) => {
  const productStickers = state.getOffer.data?.componentType?.banners || []
  const productsToHide = state.responseHistory.productsToTheHideID

  return excludeProductsToTheHide(
    productStickers.filter((element) => element.productType === 'Deposit'),
    productsToHide
  )
})
