export const addEventListenerRAF = (target, eventName, handler) => {
  const listener = () =>
    requestAnimationFrame(() => {
      handler()
      target.addEventListener(eventName, listener, { once: true })
    })

  target.addEventListener(eventName, listener, { once: true })

  return () => {
    target.removeEventListener(eventName, listener)
  }
}
