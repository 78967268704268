import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { loanIssueApi } from '@api/ump/loan-issue'

import { baseInitialState } from '@root/fetches/baseInitialState'
import { InitialLoanIssueListState } from '@reducers/credits/type'

const NAMESPACE = 'loanIssueList'

const initialState: InitialLoanIssueListState = {
  ...baseInitialState,
}

type TAppVersion = 'v1' | 'v2' | 'v3'

export const fetchLoanIssueList = createAsyncThunk(
  `${NAMESPACE}/fetchGetLoanIssues`,
  async (apiVersion: TAppVersion = 'v2') => {
    const response = await loanIssueApi.loanIssueList(apiVersion)

    return response.data
  }
)

const loanIssueList = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoanIssueList.pending, (state) => {
      state.data = {}
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchLoanIssueList.rejected, (state, action) => {
      state.error = action.error
      state.inProgress = false
      state.data = {}
    })
    builder.addCase(fetchLoanIssueList.fulfilled, (state, action) => {
      state.data = { ...action.payload }
      state.inProgress = false
      state.error = null
    })
  },
})

export const loanIssueListReducer = loanIssueList.reducer
