import { PosOfferState } from '@reducers/posOffer/types'

export const NAMESPACE = 'posOffer'

export const PRODUCT_NAME = 'MTS.BUY.PS'
export const IS_REPEAT_CLIENT = true

export const initialState: PosOfferState = {
  data: [],
  inProgress: true,
  error: null,
  maxSumAmount: null,
}
