import React, { FC, useContext } from 'react'
import { useRouter } from 'next/router'

import { Icon, Text, theme } from '@mtsbank/ui-kit'
import { Issue } from '@open-api/ump/loan-issue'

import { route } from '@utils/route/route'
import { WrapperBox, WrapperCard, WrapperCircle } from '@root/components/Styled/Wrappers'
import { Content } from '@root/components/Content/Content'
import { LOAN_ISSUE_LOAN_PATH } from '@root/constants'
import { LENGTH_SIDE } from './constants'
import { NewMainContext } from '../newMainContext'
import { StatusCode } from '../types'

export const CreditIssue: FC<{ creditInfo: Issue }> = ({ creditInfo }) => {
  const router = useRouter()
  const { statusDisplay, requestId, creditPeriod, statusCode } = creditInfo
  const href = `${route.generatePath(LOAN_ISSUE_LOAN_PATH, { requestId: String(requestId) })}`
  const { setIsWidgetClicked } = useContext(NewMainContext)
  const hasWaitDboWithoutPeriod = statusCode === StatusCode.WAIT_DBO && !creditPeriod

  const handleCardClick = () => {
    if (!hasWaitDboWithoutPeriod) {
      router.push(href)

      return
    }

    setIsWidgetClicked((isWidgetClicked) => !isWidgetClicked)
  }

  return (
    <WrapperCard isClickable onClick={handleCardClick}>
      <WrapperBox>
        <Text size="md" color={theme.colors.neutral.g300}>
          Заявка на кредит
        </Text>
        <Content marginTop={theme.spacings.xs4}>
          <Text size="lg" fontWeight="medium">
            {statusDisplay}
          </Text>
        </Content>
      </WrapperBox>
      <WrapperBox>
        <WrapperCircle lengthSide={LENGTH_SIDE}>
          <Icon icon="baseX24/ic-money" />
        </WrapperCircle>
      </WrapperBox>
    </WrapperCard>
  )
}
