import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { investDepositsDepositApi } from '@api/ump/invest-deposits-deposit'
import { depositPromoCodeApi } from '@api/ump/invest-deposits-deposit-v1'

import { baseInitialState } from '@reducers/baseInitialState'
import { isAxiosError } from '@root/types/reducers'
import { PromoCodeAvailabilityRequest, ResponseApiProductConditionV2 } from '@open-api/ump/invest-deposits-deposit-v1'
import { DepositConstructorState } from '@reducers/depositOpening/types'
import { ResponseApiProductResponseV2 } from '@open-api/ump/invest-deposits-deposit'

const NAMESPACE = 'deposit-constructor'

const initialState: DepositConstructorState = {
  conditionList: { ...baseInitialState },
  promocode: { ...baseInitialState, inProgress: false, isFieldsChanged: false, isPromoCodeApplied: false },
  finalCondition: null,
  depositYield: null,
  wasCallBindings: null,
}

const fetchDepositConditionList = createAsyncThunk<ResponseApiProductResponseV2>(
  `${NAMESPACE}/fetchConditionList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await investDepositsDepositApi.getProductsForClient()

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }
    }
  }
)

const fetchCheckPromoCodeAvailability = createAsyncThunk<ResponseApiProductConditionV2, PromoCodeAvailabilityRequest>(
  `${NAMESPACE}/checkPromocodeAvailability`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await depositPromoCodeApi.getDepositPromoCodeAvailability(params)

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }
    }
  }
)

const depositConstructor = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setDepositYield(state, { payload }) {
      state.depositYield = payload
    },
    setFinalConditions(state, { payload }) {
      state.finalCondition = payload
    },
    setFieldsChanged(state) {
      state.promocode.isFieldsChanged = true
      state.promocode.inProgress = false
    },
    setIsPromoCodeApplied(state, { payload }) {
      state.promocode.isPromoCodeApplied = payload
    },
    setWasCallBindings(state, { payload }) {
      state.wasCallBindings = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDepositConditionList.pending, (state) => {
      state.conditionList.inProgress = true
    })
    builder.addCase(fetchDepositConditionList.rejected, (state, action) => {
      state.conditionList.error = action.payload
      state.conditionList.inProgress = false
    })
    builder.addCase(fetchDepositConditionList.fulfilled, (state, { payload }) => {
      state.conditionList.inProgress = false
      state.conditionList.data = payload.data
    })
    builder.addCase(fetchCheckPromoCodeAvailability.pending, (state) => {
      state.promocode.inProgress = true
      state.promocode.isPromoCodeApplied = false
      state.promocode.isFieldsChanged = false
    })
    builder.addCase(fetchCheckPromoCodeAvailability.rejected, (state, { payload }) => {
      if (!state.promocode.isFieldsChanged) {
        state.promocode.error = payload
        state.promocode.inProgress = false
      }
    })
    builder.addCase(fetchCheckPromoCodeAvailability.fulfilled, (state, { payload }) => {
      if (!state.promocode.isFieldsChanged) {
        state.promocode.inProgress = false
        state.promocode.data = payload.data
        state.promocode.error = payload.error
      }
    })
  },
})

export { fetchDepositConditionList, fetchCheckPromoCodeAvailability }

export const { setDepositYield, setFinalConditions, setFieldsChanged, setIsPromoCodeApplied, setWasCallBindings } =
  depositConstructor.actions

export const depositConstructorReducer = depositConstructor.reducer
