// tslint:disable
/**
 * Application
 * Owner: MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: undefined
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface ArrestDto
 */
export interface ArrestDto {
  /**
   * ID ИП(исполнительного производства) из внешней системы (РБО
   * @type {string}
   * @memberof ArrestDto
   */
  id: string
  /**
   * Вид ИД (исполнительного дела)
   * @type {string}
   * @memberof ArrestDto
   */
  type?: string
  /**
   * Номер Исполнительного дела
   * @type {string}
   * @memberof ArrestDto
   */
  number?: string
  /**
   * Дата Исполнительного дела
   * @type {string}
   * @memberof ArrestDto
   */
  date?: string
  /**
   * Кем вынесено постановление
   * @type {string}
   * @memberof ArrestDto
   */
  issuedBy?: string
  /**
   * ФИО судебного пристава
   * @type {string}
   * @memberof ArrestDto
   */
  bailiffName?: string
  /**
   * Сумма из исполнительного дела
   * @type {number}
   * @memberof ArrestDto
   */
  sum?: number
  /**
   * Неоплаченная сумма
   * @type {number}
   * @memberof ArrestDto
   */
  debtAmount?: number
  /**
   * Внутренний код. Если exfsspcode начинается с FSSP, то передать true, иначе false
   * @type {boolean}
   * @memberof ArrestDto
   */
  fssp?: boolean
  /**
   * Номера счета
   * @type {Array<string>}
   * @memberof ArrestDto
   */
  accountNumbers?: Array<string>
}
/**
 * Карта выдачи
 * @export
 * @interface Card
 */
export interface Card {
  /**
   *
   * @type {string}
   * @memberof Card
   */
  id: string
  /**
   * Маскированный номер
   * @type {string}
   * @memberof Card
   */
  maskedPan: string
  /**
   * Код продукта
   * @type {string}
   * @memberof Card
   */
  productCode: string
  /**
   * Платежная система
   * @type {string}
   * @memberof Card
   */
  paymentSystem: string
}
/**
 *
 * @export
 * @interface ClosedLoanDto
 */
export interface ClosedLoanDto {
  /**
   * Id кредита
   * @type {string}
   * @memberof ClosedLoanDto
   */
  id: string
  /**
   * Название кредита
   * @type {string}
   * @memberof ClosedLoanDto
   */
  name: string
  /**
   * Валюта
   * @type {string}
   * @memberof ClosedLoanDto
   */
  currency: string
  /**
   * Сумма договора
   * @type {number}
   * @memberof ClosedLoanDto
   */
  agreementAmount?: number
}
/**
 * Комиссии
 * @export
 * @interface CommissionDtoV1
 */
export interface CommissionDtoV1 {
  /**
   * Наименование услуги
   * @type {string}
   * @memberof CommissionDtoV1
   */
  name?: string
  /**
   * Комиссия за услугу
   * @type {number}
   * @memberof CommissionDtoV1
   */
  commission?: number
  /**
   * Услуга
   * @type {string}
   * @memberof CommissionDtoV1
   */
  service?: CommissionDtoV1ServiceEnum
}

/**
 * @export
 * @enum {string}
 */
export enum CommissionDtoV1ServiceEnum {
  SMSLIGHT = 'SMS_LIGHT',
  CREDDATECHANGE = 'CRED_DATE_CHANGE',
}

/**
 * Комиссии
 * @export
 * @interface CommissionDtoV2
 */
export interface CommissionDtoV2 {
  /**
   * Код услуги
   * @type {string}
   * @memberof CommissionDtoV2
   */
  code: CommissionDtoV2CodeEnum
  /**
   * Комиссия
   * @type {number}
   * @memberof CommissionDtoV2
   */
  sum: number
}

/**
 * @export
 * @enum {string}
 */
export enum CommissionDtoV2CodeEnum {
  SMSLIGHT = 'SMS_LIGHT',
  CREDDATECHANGE = 'CRED_DATE_CHANGE',
}

/**
 * Комиссии
 * @export
 * @interface CommissionDtoV3
 */
export interface CommissionDtoV3 {
  /**
   * Код услуги
   * @type {string}
   * @memberof CommissionDtoV3
   */
  code?: CommissionDtoV3CodeEnum
  /**
   * Сумма
   * @type {number}
   * @memberof CommissionDtoV3
   */
  sum?: number
  /**
   * Оплачено
   * @type {number}
   * @memberof CommissionDtoV3
   */
  paid?: number
  /**
   * Осталось оплатить
   * @type {number}
   * @memberof CommissionDtoV3
   */
  left?: number
}

/**
 * @export
 * @enum {string}
 */
export enum CommissionDtoV3CodeEnum {
  SMSLIGHT = 'SMS_LIGHT',
  CREDDATECHANGE = 'CRED_DATE_CHANGE',
}

/**
 * Осталось
 * @export
 * @interface DebtDto
 */
export interface DebtDto {
  /**
   * Основная задолжность
   * @type {number}
   * @memberof DebtDto
   */
  main: number
  /**
   * Задолжность по процентам
   * @type {number}
   * @memberof DebtDto
   */
  percent: number
  /**
   * Всего
   * @type {number}
   * @memberof DebtDto
   */
  total: number
  /**
   * Комиссии
   * @type {Array<CommissionDtoV2>}
   * @memberof DebtDto
   */
  commissions: Array<CommissionDtoV2>
}
/**
 * Осталось
 * @export
 * @interface DebtDtoV3
 */
export interface DebtDtoV3 {
  /**
   * Основная задолжность
   * @type {number}
   * @memberof DebtDtoV3
   */
  main: number
  /**
   * Задолжность по процентам
   * @type {number}
   * @memberof DebtDtoV3
   */
  percent: number
  /**
   * Отложенные проценты
   * @type {number}
   * @memberof DebtDtoV3
   */
  deferredInterest: number
  /**
   * Всего
   * @type {number}
   * @memberof DebtDtoV3
   */
  total: number
  /**
   * Комиссии
   * @type {Array<PaymentCommissionV3>}
   * @memberof DebtDtoV3
   */
  commissions: Array<PaymentCommissionV3>
}
/**
 *
 * @export
 * @interface LoanDto
 */
export interface LoanDto {
  /**
   * Id кредита
   * @type {string}
   * @memberof LoanDto
   */
  id: string
  /**
   * Название кредита
   * @type {string}
   * @memberof LoanDto
   */
  name: string
  /**
   * Валюта
   * @type {string}
   * @memberof LoanDto
   */
  currency: string
  /**
   * Сумма договора
   * @type {number}
   * @memberof LoanDto
   */
  agreementAmount?: number
  /**
   * Сумма задолженности
   * @type {number}
   * @memberof LoanDto
   */
  debtAmount: number
  /**
   * Выплачено
   * @type {number}
   * @memberof LoanDto
   */
  paidAmount?: number
  /**
   * Код программы кредитования
   * @type {string}
   * @memberof LoanDto
   */
  programCode?: string
  /**
   * Программа кредитования
   * @type {string}
   * @memberof LoanDto
   */
  programName?: string
  /**
   * Номер договора
   * @type {string}
   * @memberof LoanDto
   */
  agreementNumber: string
  /**
   * Дата заключения договора
   * @type {string}
   * @memberof LoanDto
   */
  startDate: string
  /**
   * Окончание действия договора
   * @type {string}
   * @memberof LoanDto
   */
  endDate: string
  /**
   * Срок кредита
   * @type {number}
   * @memberof LoanDto
   */
  termInMonth: number
  /**
   * Процентная ставка
   * @type {number}
   * @memberof LoanDto
   */
  rate: number
  /**
   * День ежемесячного платежа
   * @type {number}
   * @memberof LoanDto
   */
  payDay: number
  /**
   * ПСК по договору
   * @type {number}
   * @memberof LoanDto
   */
  psk?: number
  /**
   *
   * @type {RepaymentDto}
   * @memberof LoanDto
   */
  repayment?: RepaymentDto
  /**
   * Статус
   * @type {string}
   * @memberof LoanDto
   */
  statusCode: string
  /**
   *
   * @type {NextPaymentDto}
   * @memberof LoanDto
   */
  nextPayment?: NextPaymentDto
  /**
   * True, если сегодня была успешная плановая операция, иначе false
   * @type {boolean}
   * @memberof LoanDto
   */
  isLoanPayment: boolean
  /**
   * Тип продукта
   * @type {string}
   * @memberof LoanDto
   */
  typeOfProduct?: LoanDtoTypeOfProductEnum
}

/**
 * @export
 * @enum {string}
 */
export enum LoanDtoTypeOfProductEnum {
  NCPK = 'NCPK',
  POS = 'POS',
}

/**
 *
 * @export
 * @interface LoanInfoDtoV1
 */
export interface LoanInfoDtoV1 {
  /**
   * Id кредита
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  id: string
  /**
   * Название кредита
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  name: string
  /**
   * Валюта
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  currency: string
  /**
   * Сумма договора
   * @type {number}
   * @memberof LoanInfoDtoV1
   */
  agreementAmount: number
  /**
   * Сумма задолженности
   * @type {number}
   * @memberof LoanInfoDtoV1
   */
  debtAmount: number
  /**
   * Id карты
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  cardId?: string
  /**
   * Код продукта
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  productCode: string
  /**
   * Номер договора
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  agreementNumber: string
  /**
   * Дата открытия
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  startDate?: string
  /**
   * Статус
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  statusCode: string
  /**
   *
   * @type {NextPaymentDto}
   * @memberof LoanInfoDtoV1
   */
  nextPayment?: NextPaymentDto
  /**
   * Номер счета гашения
   * @type {string}
   * @memberof LoanInfoDtoV1
   */
  accountNumber?: string
}
/**
 *
 * @export
 * @interface LoanInfoDtoV2
 */
export interface LoanInfoDtoV2 {
  /**
   * Id кредита
   * @type {string}
   * @memberof LoanInfoDtoV2
   */
  id: string
  /**
   * Название кредита
   * @type {string}
   * @memberof LoanInfoDtoV2
   */
  name: string
  /**
   * Валюта
   * @type {string}
   * @memberof LoanInfoDtoV2
   */
  currency: string
  /**
   * Сумма договора
   * @type {number}
   * @memberof LoanInfoDtoV2
   */
  agreementAmount: number
  /**
   * Сумма задолженности
   * @type {number}
   * @memberof LoanInfoDtoV2
   */
  debtAmount: number
  /**
   * Код продукта
   * @type {string}
   * @memberof LoanInfoDtoV2
   */
  productCode: string
  /**
   * Номер договора
   * @type {string}
   * @memberof LoanInfoDtoV2
   */
  agreementNumber?: string
  /**
   * Дата открытия
   * @type {string}
   * @memberof LoanInfoDtoV2
   */
  startDate?: string
  /**
   *
   * @type {Card}
   * @memberof LoanInfoDtoV2
   */
  card?: Card
  /**
   *
   * @type {NextPaymentDtoV2}
   * @memberof LoanInfoDtoV2
   */
  nextPayment?: NextPaymentDtoV2
  /**
   * Номер счета гашения
   * @type {string}
   * @memberof LoanInfoDtoV2
   */
  accountNumber?: string
}
/**
 *
 * @export
 * @interface LoanUpdateDto
 */
export interface LoanUpdateDto {
  /**
   * Название кредита
   * @type {string}
   * @memberof LoanUpdateDto
   */
  name: string
}
/**
 *
 * @export
 * @interface MtsBankErrorInfo
 */
export interface MtsBankErrorInfo {
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorResponse
 */
export interface MtsBankErrorResponse {
  /**
   *
   * @type {MtsBankErrorInfo}
   * @memberof MtsBankErrorResponse
   */
  error?: MtsBankErrorInfo
}
/**
 * Информация о следующем платеже
 * @export
 * @interface NextPaymentDto
 */
export interface NextPaymentDto {
  /**
   * Дата след платежа
   * @type {string}
   * @memberof NextPaymentDto
   */
  date?: string
  /**
   * Сумма след платежа
   * @type {number}
   * @memberof NextPaymentDto
   */
  amount: number
}
/**
 * Информация о следующем платеже
 * @export
 * @interface NextPaymentDtoV2
 */
export interface NextPaymentDtoV2 {
  /**
   * Дата след платежа
   * @type {string}
   * @memberof NextPaymentDtoV2
   */
  date?: string
  /**
   * Сумма след платежа
   * @type {number}
   * @memberof NextPaymentDtoV2
   */
  amount?: number
  /**
   * Статус
   * @type {string}
   * @memberof NextPaymentDtoV2
   */
  state: NextPaymentDtoV2StateEnum
}

/**
 * @export
 * @enum {string}
 */
export enum NextPaymentDtoV2StateEnum {
  TOCLOSE = 'TO_CLOSE',
  EXPIRED = 'EXPIRED',
  DONE = 'DONE',
  ENOUGH = 'ENOUGH',
  DEADLINE = 'DEADLINE',
  DEFAULT = 'DEFAULT',
}

/**
 *
 * @export
 * @interface OverdueDto
 */
export interface OverdueDto {
  /**
   * Просроченная задолженность по основному долгу
   * @type {number}
   * @memberof OverdueDto
   */
  debt?: number
  /**
   * Просроченная задолженность по процентам
   * @type {number}
   * @memberof OverdueDto
   */
  percent?: number
  /**
   * Пени/штрафы
   * @type {number}
   * @memberof OverdueDto
   */
  fineSum?: number
  /**
   * Просроченные комиссии
   * @type {number}
   * @memberof OverdueDto
   */
  commissionSum?: number
  /**
   * debt+percent+fineSum+commissionSum
   * @type {number}
   * @memberof OverdueDto
   */
  total?: number
}
/**
 * Комиссии
 * @export
 * @interface PaymentCommissionV3
 */
export interface PaymentCommissionV3 {
  /**
   * Код услуги
   * @type {string}
   * @memberof PaymentCommissionV3
   */
  code: PaymentCommissionV3CodeEnum
  /**
   * Сумма
   * @type {number}
   * @memberof PaymentCommissionV3
   */
  sum: number
  /**
   * Оплачено
   * @type {number}
   * @memberof PaymentCommissionV3
   */
  paid: number
  /**
   * Осталось оплатить
   * @type {number}
   * @memberof PaymentCommissionV3
   */
  left: number
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentCommissionV3CodeEnum {
  SMSLIGHT = 'SMS_LIGHT',
  CREDDATECHANGE = 'CRED_DATE_CHANGE',
}

/**
 * Общая сумма платежа
 * @export
 * @interface PaymentDebtV3
 */
export interface PaymentDebtV3 {
  /**
   * Сумма
   * @type {number}
   * @memberof PaymentDebtV3
   */
  sum: number
  /**
   * Оплачено
   * @type {number}
   * @memberof PaymentDebtV3
   */
  paid: number
  /**
   * Осталось оплатить
   * @type {number}
   * @memberof PaymentDebtV3
   */
  left: number
}
/**
 *
 * @export
 * @interface PaymentScheduleDtoV1
 */
export interface PaymentScheduleDtoV1 {
  /**
   * Оплачено
   * @type {number}
   * @memberof PaymentScheduleDtoV1
   */
  debtPaid: number
  /**
   * Осталось
   * @type {number}
   * @memberof PaymentScheduleDtoV1
   */
  debtLeft: number
  /**
   * Массив с графиком платежей
   * @type {Array<PaymentScheduleItemDtoV1>}
   * @memberof PaymentScheduleDtoV1
   */
  loanSchedule?: Array<PaymentScheduleItemDtoV1>
}
/**
 *
 * @export
 * @interface PaymentScheduleDtoV2
 */
export interface PaymentScheduleDtoV2 {
  /**
   *
   * @type {DebtDto}
   * @memberof PaymentScheduleDtoV2
   */
  paidDebt: DebtDto
  /**
   *
   * @type {DebtDto}
   * @memberof PaymentScheduleDtoV2
   */
  leftDebt: DebtDto
  /**
   * Массив с графиком платежей
   * @type {Array<PaymentScheduleItemDtoV2>}
   * @memberof PaymentScheduleDtoV2
   */
  loanSchedule?: Array<PaymentScheduleItemDtoV2>
}
/**
 *
 * @export
 * @interface PaymentScheduleDtoV3
 */
export interface PaymentScheduleDtoV3 {
  /**
   *
   * @type {DebtDtoV3}
   * @memberof PaymentScheduleDtoV3
   */
  paidDebt: DebtDtoV3
  /**
   *
   * @type {DebtDtoV3}
   * @memberof PaymentScheduleDtoV3
   */
  leftDebt: DebtDtoV3
  /**
   * Массив с графиком платежей
   * @type {Array<PaymentScheduleItemDtoV3>}
   * @memberof PaymentScheduleDtoV3
   */
  loanSchedule?: Array<PaymentScheduleItemDtoV3>
}
/**
 * Массив с графиком платежей
 * @export
 * @interface PaymentScheduleItemDtoV1
 */
export interface PaymentScheduleItemDtoV1 {
  /**
   * Дата платежа
   * @type {string}
   * @memberof PaymentScheduleItemDtoV1
   */
  pmtDt: string
  /**
   * Статус
   * @type {string}
   * @memberof PaymentScheduleItemDtoV1
   */
  status: PaymentScheduleItemDtoV1StatusEnum
  /**
   * Сумма
   * @type {number}
   * @memberof PaymentScheduleItemDtoV1
   */
  debtMounth: number
  /**
   * Долг
   * @type {number}
   * @memberof PaymentScheduleItemDtoV1
   */
  debtMain: number
  /**
   * Проценты
   * @type {number}
   * @memberof PaymentScheduleItemDtoV1
   */
  debtPrc: number
  /**
   * Комиссии
   * @type {Array<CommissionDtoV1>}
   * @memberof PaymentScheduleItemDtoV1
   */
  services?: Array<CommissionDtoV1>
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentScheduleItemDtoV1StatusEnum {
  PAID = 'PAID',
  DELINQ = 'DELINQ',
  NOTPAID = 'NOT_PAID',
}

/**
 * Массив с графиком платежей
 * @export
 * @interface PaymentScheduleItemDtoV2
 */
export interface PaymentScheduleItemDtoV2 {
  /**
   * Дата платежа
   * @type {string}
   * @memberof PaymentScheduleItemDtoV2
   */
  pmtDt: string
  /**
   * Статус
   * @type {string}
   * @memberof PaymentScheduleItemDtoV2
   */
  status: PaymentScheduleItemDtoV2StatusEnum
  /**
   * Сумма
   * @type {number}
   * @memberof PaymentScheduleItemDtoV2
   */
  debtMounth: number
  /**
   * Долг
   * @type {number}
   * @memberof PaymentScheduleItemDtoV2
   */
  debtMain: number
  /**
   * Проценты
   * @type {number}
   * @memberof PaymentScheduleItemDtoV2
   */
  debtPrc: number
  /**
   * Комиссии
   * @type {Array<CommissionDtoV2>}
   * @memberof PaymentScheduleItemDtoV2
   */
  commissions?: Array<CommissionDtoV2>
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentScheduleItemDtoV2StatusEnum {
  PAID = 'PAID',
  DELINQ = 'DELINQ',
  NOTPAID = 'NOT_PAID',
}

/**
 * Массив с графиком платежей
 * @export
 * @interface PaymentScheduleItemDtoV3
 */
export interface PaymentScheduleItemDtoV3 {
  /**
   * Дата платежа
   * @type {string}
   * @memberof PaymentScheduleItemDtoV3
   */
  date: string
  /**
   * Статус
   * @type {string}
   * @memberof PaymentScheduleItemDtoV3
   */
  status: PaymentScheduleItemDtoV3StatusEnum
  /**
   * Отложенные проценты
   * @type {number}
   * @memberof PaymentScheduleItemDtoV3
   */
  deferredInterest: number
  /**
   *
   * @type {PaymentDebtV3}
   * @memberof PaymentScheduleItemDtoV3
   */
  main: PaymentDebtV3
  /**
   *
   * @type {PaymentDebtV3}
   * @memberof PaymentScheduleItemDtoV3
   */
  percent: PaymentDebtV3
  /**
   *
   * @type {PaymentDebtV3}
   * @memberof PaymentScheduleItemDtoV3
   */
  total: PaymentDebtV3
  /**
   * Комиссии
   * @type {Array<CommissionDtoV3>}
   * @memberof PaymentScheduleItemDtoV3
   */
  commissions?: Array<CommissionDtoV3>
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentScheduleItemDtoV3StatusEnum {
  PAID = 'PAID',
  DELINQ = 'DELINQ',
  NOTPAID = 'NOT_PAID',
}

/**
 * Информация о кредитном счете
 * @export
 * @interface RepaymentDto
 */
export interface RepaymentDto {
  /**
   * Номер
   * @type {string}
   * @memberof RepaymentDto
   */
  number?: string
  /**
   * Валюта
   * @type {string}
   * @memberof RepaymentDto
   */
  currency?: string
  /**
   * Текущий баланс на счете для погашения
   * @type {number}
   * @memberof RepaymentDto
   */
  sum?: number
  /**
   * Тип перерасчета суммы/срока кредита
   * @type {string}
   * @memberof RepaymentDto
   */
  type: RepaymentDtoTypeEnum
  /**
   * Признак произвольной даты погашения для частичного досрочного погашения
   * @type {boolean}
   * @memberof RepaymentDto
   */
  isAnyDate: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum RepaymentDtoTypeEnum {
  CANNOT = 'CANNOT',
  LESSTERM = 'LESS_TERM',
  LESSPAYMENT = 'LESS_PAYMENT',
  ALL = 'ALL',
}

/**
 *
 * @export
 * @interface RequisiteDto
 */
export interface RequisiteDto {
  /**
   * Получатель кредита
   * @type {string}
   * @memberof RequisiteDto
   */
  clientName: string
  /**
   * Номер счета погашения кредита
   * @type {string}
   * @memberof RequisiteDto
   */
  accNum?: string
  /**
   * Номер карты, на который был зачислен кредит
   * @type {string}
   * @memberof RequisiteDto
   */
  cardNum?: string
  /**
   * Валюта счета
   * @type {string}
   * @memberof RequisiteDto
   */
  currency: string
  /**
   * Банк получатель
   * @type {string}
   * @memberof RequisiteDto
   */
  bankName: string
  /**
   * Корреспондентский счет
   * @type {string}
   * @memberof RequisiteDto
   */
  corrAccount: string
  /**
   * БИК
   * @type {string}
   * @memberof RequisiteDto
   */
  BIK: string
  /**
   * ИНН
   * @type {string}
   * @memberof RequisiteDto
   */
  INN: string
  /**
   * КПП
   * @type {string}
   * @memberof RequisiteDto
   */
  KPP: string
}
/**
 *
 * @export
 * @interface ScheduleStatementDto
 */
export interface ScheduleStatementDto {
  /**
   * Документ в формате base64
   * @type {string}
   * @memberof ScheduleStatementDto
   */
  file: string
}

/**
 * AccountControllerV1Api - axios parameter creator
 * @export
 */
export const AccountControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает реквизиты клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch3(loanId: string, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError('loanId', 'Required parameter loanId was null or undefined when calling fetch3.')
      }
      const localVarPath = `/dbo-loan/v1/accounts/requisites/{loanId}`.replace(
        `{${'loanId'}}`,
        encodeURIComponent(String(loanId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает информацию о наличии арестов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchArrests(options: any = {}): RequestArgs {
      const localVarPath = `/dbo-loan/v1/accounts/arrests`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AccountControllerV1Api - functional programming interface
 * @export
 */
export const AccountControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает реквизиты клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch3(loanId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequisiteDto> {
      const localVarAxiosArgs = AccountControllerV1ApiAxiosParamCreator(configuration).fetch3(loanId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает информацию о наличии арестов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchArrests(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArrestDto>> {
      const localVarAxiosArgs = AccountControllerV1ApiAxiosParamCreator(configuration).fetchArrests(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * AccountControllerV1Api - factory interface
 * @export
 */
export const AccountControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает реквизиты клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch3(loanId: string, options?: any): AxiosPromise<RequisiteDto> {
      return AccountControllerV1ApiFp(configuration).fetch3(loanId, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает информацию о наличии арестов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchArrests(options?: any): AxiosPromise<Array<ArrestDto>> {
      return AccountControllerV1ApiFp(configuration).fetchArrests(options)(axios, basePath)
    },
  }
}

/**
 * AccountControllerV1Api - object-oriented interface
 * @export
 * @class AccountControllerV1Api
 * @extends {BaseAPI}
 */
export class AccountControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает реквизиты клиента
   * @param {string} loanId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerV1Api
   */
  public fetch3(loanId: string, options?: any) {
    return AccountControllerV1ApiFp(this.configuration).fetch3(loanId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает информацию о наличии арестов клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerV1Api
   */
  public fetchArrests(options?: any) {
    return AccountControllerV1ApiFp(this.configuration).fetchArrests(options)(this.axios, this.basePath)
  }
}

/**
 * LoanControllerV1Api - axios parameter creator
 * @export
 */
export const LoanControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchClosedLoans(options: any = {}): RequestArgs {
      const localVarPath = `/dbo-loan/v1/loans/closed`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает полную информацию по кредиту
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoan(loanId: string, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError('loanId', 'Required parameter loanId was null or undefined when calling fetchLoan.')
      }
      const localVarPath = `/dbo-loan/v1/loans/{loanId}`.replace(`{${'loanId'}}`, encodeURIComponent(String(loanId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoans1(options: any = {}): RequestArgs {
      const localVarPath = `/dbo-loan/v1/loans`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает информацию о просроченной задолженности клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOverdue(loanId: string, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError('loanId', 'Required parameter loanId was null or undefined when calling fetchOverdue.')
      }
      const localVarPath = `/dbo-loan/v1/loans/{loanId}/overdue`.replace(
        `{${'loanId'}}`,
        encodeURIComponent(String(loanId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод позволяет переименовать кредит
     * @param {string} loanId
     * @param {LoanUpdateDto} loanUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rename(loanId: string, loanUpdateDto: LoanUpdateDto, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError('loanId', 'Required parameter loanId was null or undefined when calling rename.')
      }
      // verify required parameter 'loanUpdateDto' is not null or undefined
      if (loanUpdateDto === null || loanUpdateDto === undefined) {
        throw new RequiredError(
          'loanUpdateDto',
          'Required parameter loanUpdateDto was null or undefined when calling rename.'
        )
      }
      const localVarPath = `/dbo-loan/v1/loans/{loanId}`.replace(`{${'loanId'}}`, encodeURIComponent(String(loanId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof loanUpdateDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(loanUpdateDto !== undefined ? loanUpdateDto : {})
        : loanUpdateDto || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoanControllerV1Api - functional programming interface
 * @export
 */
export const LoanControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchClosedLoans(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClosedLoanDto>> {
      const localVarAxiosArgs = LoanControllerV1ApiAxiosParamCreator(configuration).fetchClosedLoans(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает полную информацию по кредиту
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoan(loanId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanDto> {
      const localVarAxiosArgs = LoanControllerV1ApiAxiosParamCreator(configuration).fetchLoan(loanId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoans1(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoanInfoDtoV1>> {
      const localVarAxiosArgs = LoanControllerV1ApiAxiosParamCreator(configuration).fetchLoans1(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает информацию о просроченной задолженности клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOverdue(
      loanId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverdueDto> {
      const localVarAxiosArgs = LoanControllerV1ApiAxiosParamCreator(configuration).fetchOverdue(loanId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод позволяет переименовать кредит
     * @param {string} loanId
     * @param {LoanUpdateDto} loanUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rename(
      loanId: string,
      loanUpdateDto: LoanUpdateDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LoanControllerV1ApiAxiosParamCreator(configuration).rename(
        loanId,
        loanUpdateDto,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoanControllerV1Api - factory interface
 * @export
 */
export const LoanControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchClosedLoans(options?: any): AxiosPromise<Array<ClosedLoanDto>> {
      return LoanControllerV1ApiFp(configuration).fetchClosedLoans(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает полную информацию по кредиту
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoan(loanId: string, options?: any): AxiosPromise<LoanDto> {
      return LoanControllerV1ApiFp(configuration).fetchLoan(loanId, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoans1(options?: any): AxiosPromise<Array<LoanInfoDtoV1>> {
      return LoanControllerV1ApiFp(configuration).fetchLoans1(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает информацию о просроченной задолженности клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOverdue(loanId: string, options?: any): AxiosPromise<OverdueDto> {
      return LoanControllerV1ApiFp(configuration).fetchOverdue(loanId, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод позволяет переименовать кредит
     * @param {string} loanId
     * @param {LoanUpdateDto} loanUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rename(loanId: string, loanUpdateDto: LoanUpdateDto, options?: any): AxiosPromise<void> {
      return LoanControllerV1ApiFp(configuration).rename(loanId, loanUpdateDto, options)(axios, basePath)
    },
  }
}

/**
 * LoanControllerV1Api - object-oriented interface
 * @export
 * @class LoanControllerV1Api
 * @extends {BaseAPI}
 */
export class LoanControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает информацию о кредитах клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanControllerV1Api
   */
  public fetchClosedLoans(options?: any) {
    return LoanControllerV1ApiFp(this.configuration).fetchClosedLoans(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает полную информацию по кредиту
   * @param {string} loanId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanControllerV1Api
   */
  public fetchLoan(loanId: string, options?: any) {
    return LoanControllerV1ApiFp(this.configuration).fetchLoan(loanId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает информацию о кредитах клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanControllerV1Api
   */
  public fetchLoans1(options?: any) {
    return LoanControllerV1ApiFp(this.configuration).fetchLoans1(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает информацию о просроченной задолженности клиента
   * @param {string} loanId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanControllerV1Api
   */
  public fetchOverdue(loanId: string, options?: any) {
    return LoanControllerV1ApiFp(this.configuration).fetchOverdue(loanId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод позволяет переименовать кредит
   * @param {string} loanId
   * @param {LoanUpdateDto} loanUpdateDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanControllerV1Api
   */
  public rename(loanId: string, loanUpdateDto: LoanUpdateDto, options?: any) {
    return LoanControllerV1ApiFp(this.configuration).rename(loanId, loanUpdateDto, options)(this.axios, this.basePath)
  }
}

/**
 * LoanControllerV2Api - axios parameter creator
 * @export
 */
export const LoanControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoans(options: any = {}): RequestArgs {
      const localVarPath = `/dbo-loan/v2/loans`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoanControllerV2Api - functional programming interface
 * @export
 */
export const LoanControllerV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoans(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoanInfoDtoV2>> {
      const localVarAxiosArgs = LoanControllerV2ApiAxiosParamCreator(configuration).fetchLoans(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoanControllerV2Api - factory interface
 * @export
 */
export const LoanControllerV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLoans(options?: any): AxiosPromise<Array<LoanInfoDtoV2>> {
      return LoanControllerV2ApiFp(configuration).fetchLoans(options)(axios, basePath)
    },
  }
}

/**
 * LoanControllerV2Api - object-oriented interface
 * @export
 * @class LoanControllerV2Api
 * @extends {BaseAPI}
 */
export class LoanControllerV2Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает информацию о кредитах клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanControllerV2Api
   */
  public fetchLoans(options?: any) {
    return LoanControllerV2ApiFp(this.configuration).fetchLoans(options)(this.axios, this.basePath)
  }
}

/**
 * ScheduleControllerV1Api - axios parameter creator
 * @export
 */
export const ScheduleControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch2(loanId: string, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError('loanId', 'Required parameter loanId was null or undefined when calling fetch2.')
      }
      const localVarPath = `/dbo-loan/v1/loans/{loanId}/schedule`.replace(
        `{${'loanId'}}`,
        encodeURIComponent(String(loanId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает печатную форму графика платежей
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchStatement(loanId: string, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError(
          'loanId',
          'Required parameter loanId was null or undefined when calling fetchStatement.'
        )
      }
      const localVarPath = `/dbo-loan/v1/loans/{loanId}/statement`.replace(
        `{${'loanId'}}`,
        encodeURIComponent(String(loanId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScheduleControllerV1Api - functional programming interface
 * @export
 */
export const ScheduleControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch2(
      loanId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentScheduleDtoV1> {
      const localVarAxiosArgs = ScheduleControllerV1ApiAxiosParamCreator(configuration).fetch2(loanId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает печатную форму графика платежей
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchStatement(
      loanId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleStatementDto> {
      const localVarAxiosArgs = ScheduleControllerV1ApiAxiosParamCreator(configuration).fetchStatement(loanId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ScheduleControllerV1Api - factory interface
 * @export
 */
export const ScheduleControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch2(loanId: string, options?: any): AxiosPromise<PaymentScheduleDtoV1> {
      return ScheduleControllerV1ApiFp(configuration).fetch2(loanId, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает печатную форму графика платежей
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchStatement(loanId: string, options?: any): AxiosPromise<ScheduleStatementDto> {
      return ScheduleControllerV1ApiFp(configuration).fetchStatement(loanId, options)(axios, basePath)
    },
  }
}

/**
 * ScheduleControllerV1Api - object-oriented interface
 * @export
 * @class ScheduleControllerV1Api
 * @extends {BaseAPI}
 */
export class ScheduleControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает график платежей по кредиту клиента
   * @param {string} loanId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScheduleControllerV1Api
   */
  public fetch2(loanId: string, options?: any) {
    return ScheduleControllerV1ApiFp(this.configuration).fetch2(loanId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает печатную форму графика платежей
   * @param {string} loanId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScheduleControllerV1Api
   */
  public fetchStatement(loanId: string, options?: any) {
    return ScheduleControllerV1ApiFp(this.configuration).fetchStatement(loanId, options)(this.axios, this.basePath)
  }
}

/**
 * ScheduleControllerV2Api - axios parameter creator
 * @export
 */
export const ScheduleControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {string} [loanId2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch1(loanId: string, loanId2?: string, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError('loanId', 'Required parameter loanId was null or undefined when calling fetch1.')
      }
      const localVarPath = `/dbo-loan/v2/loans/{loanId}/schedule`.replace(
        `{${'loanId'}}`,
        encodeURIComponent(String(loanId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (loanId2 !== undefined) {
        localVarQueryParameter['loanId'] = loanId2
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScheduleControllerV2Api - functional programming interface
 * @export
 */
export const ScheduleControllerV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {string} [loanId2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch1(
      loanId: string,
      loanId2?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentScheduleDtoV2> {
      const localVarAxiosArgs = ScheduleControllerV2ApiAxiosParamCreator(configuration).fetch1(loanId, loanId2, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ScheduleControllerV2Api - factory interface
 * @export
 */
export const ScheduleControllerV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {string} [loanId2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch1(loanId: string, loanId2?: string, options?: any): AxiosPromise<PaymentScheduleDtoV2> {
      return ScheduleControllerV2ApiFp(configuration).fetch1(loanId, loanId2, options)(axios, basePath)
    },
  }
}

/**
 * ScheduleControllerV2Api - object-oriented interface
 * @export
 * @class ScheduleControllerV2Api
 * @extends {BaseAPI}
 */
export class ScheduleControllerV2Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает график платежей по кредиту клиента
   * @param {string} loanId
   * @param {string} [loanId2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScheduleControllerV2Api
   */
  public fetch1(loanId: string, loanId2?: string, options?: any) {
    return ScheduleControllerV2ApiFp(this.configuration).fetch1(loanId, loanId2, options)(this.axios, this.basePath)
  }
}

/**
 * ScheduleControllerV3Api - axios parameter creator
 * @export
 */
export const ScheduleControllerV3ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {string} [loanId2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(loanId: string, loanId2?: string, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError('loanId', 'Required parameter loanId was null or undefined when calling fetch.')
      }
      const localVarPath = `/dbo-loan/v3/loans/{loanId}/schedule`.replace(
        `{${'loanId'}}`,
        encodeURIComponent(String(loanId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (loanId2 !== undefined) {
        localVarQueryParameter['loanId'] = loanId2
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ScheduleControllerV3Api - functional programming interface
 * @export
 */
export const ScheduleControllerV3ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {string} [loanId2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(
      loanId: string,
      loanId2?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentScheduleDtoV3> {
      const localVarAxiosArgs = ScheduleControllerV3ApiAxiosParamCreator(configuration).fetch(loanId, loanId2, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ScheduleControllerV3Api - factory interface
 * @export
 */
export const ScheduleControllerV3ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает график платежей по кредиту клиента
     * @param {string} loanId
     * @param {string} [loanId2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(loanId: string, loanId2?: string, options?: any): AxiosPromise<PaymentScheduleDtoV3> {
      return ScheduleControllerV3ApiFp(configuration).fetch(loanId, loanId2, options)(axios, basePath)
    },
  }
}

/**
 * ScheduleControllerV3Api - object-oriented interface
 * @export
 * @class ScheduleControllerV3Api
 * @extends {BaseAPI}
 */
export class ScheduleControllerV3Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает график платежей по кредиту клиента
   * @param {string} loanId
   * @param {string} [loanId2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScheduleControllerV3Api
   */
  public fetch(loanId: string, loanId2?: string, options?: any) {
    return ScheduleControllerV3ApiFp(this.configuration).fetch(loanId, loanId2, options)(this.axios, this.basePath)
  }
}
