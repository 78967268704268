import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { autoPaymentsApi } from '@api/dot-net/api'
import { AutoPaymentReq, AutoPaymentState } from '@reducers/mobilePayment/autopayment/type'
import { AvailableTypesResult } from '@generated/api'

const NAMESPACE = 'autoPayment'

const initialState: AutoPaymentState = {
  inProgress: false,
  error: null,
  data: null,
}

const checkAvailableAutoPayment = createAsyncThunk(`${NAMESPACE}`, async (reqParams: AutoPaymentReq) => {
  const serviceParams = JSON.stringify(reqParams.serviceParams)

  const response: AxiosResponse<AvailableTypesResult> = await autoPaymentsApi.apiApAvailableTypesGet(
    reqParams.serviceId,
    serviceParams
  )

  return response.data
})

const autoPayment = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkAvailableAutoPayment.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(checkAvailableAutoPayment.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(checkAvailableAutoPayment.fulfilled, (state, action) => {
      state.inProgress = false
      state.data = { ...action.payload }
    })
  },
})

export { checkAvailableAutoPayment }

export const autoPaymentReducer = autoPayment.reducer
