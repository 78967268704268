// tslint:disable
/**
 * Card Account Details API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AccountDetails
 */
export interface AccountDetails {
  /**
   *
   * @type {string}
   * @memberof AccountDetails
   */
  BIC?: string
  /**
   *
   * @type {string}
   * @memberof AccountDetails
   */
  RRC?: string
  /**
   *
   * @type {string}
   * @memberof AccountDetails
   */
  TIN?: string
  /**
   *
   * @type {string}
   * @memberof AccountDetails
   */
  accountCurrency?: string
  /**
   *
   * @type {string}
   * @memberof AccountDetails
   */
  accountNumber?: string
  /**
   *
   * @type {string}
   * @memberof AccountDetails
   */
  bankName?: string
  /**
   *
   * @type {string}
   * @memberof AccountDetails
   */
  corrAccount?: string
  /**
   *
   * @type {string}
   * @memberof AccountDetails
   */
  owner?: string
}
/**
 *
 * @export
 * @interface CardIdRequest
 */
export interface CardIdRequest {
  /**
   *
   * @type {string}
   * @memberof CardIdRequest
   */
  cardId: string
}
/**
 *
 * @export
 * @interface FetchCardAccountDetailsRsp
 */
export interface FetchCardAccountDetailsRsp {
  /**
   *
   * @type {AccountDetails}
   * @memberof FetchCardAccountDetailsRsp
   */
  accountDetails?: AccountDetails
}

/**
 * DebitCardsApi - axios parameter creator
 * @export
 */
export const DebitCardsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Fetch Card Account Details
     * @param {'v1'} version
     * @param {CardIdRequest} cardIdRequest Card ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardAccountDetails(version: 'v1', cardIdRequest: CardIdRequest, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling cardAccountDetails.'
        )
      }
      // verify required parameter 'cardIdRequest' is not null or undefined
      if (cardIdRequest === null || cardIdRequest === undefined) {
        throw new RequiredError(
          'cardIdRequest',
          'Required parameter cardIdRequest was null or undefined when calling cardAccountDetails.'
        )
      }
      const localVarPath = `/debit-cards-card-details/{version}/cardAccountDetails`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof cardIdRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cardIdRequest !== undefined ? cardIdRequest : {})
        : cardIdRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DebitCardsApi - functional programming interface
 * @export
 */
export const DebitCardsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Fetch Card Account Details
     * @param {'v1'} version
     * @param {CardIdRequest} cardIdRequest Card ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardAccountDetails(
      version: 'v1',
      cardIdRequest: CardIdRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchCardAccountDetailsRsp> {
      const localVarAxiosArgs = DebitCardsApiAxiosParamCreator(configuration).cardAccountDetails(
        version,
        cardIdRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DebitCardsApi - factory interface
 * @export
 */
export const DebitCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Fetch Card Account Details
     * @param {'v1'} version
     * @param {CardIdRequest} cardIdRequest Card ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardAccountDetails(
      version: 'v1',
      cardIdRequest: CardIdRequest,
      options?: any
    ): AxiosPromise<FetchCardAccountDetailsRsp> {
      return DebitCardsApiFp(configuration).cardAccountDetails(version, cardIdRequest, options)(axios, basePath)
    },
  }
}

/**
 * DebitCardsApi - object-oriented interface
 * @export
 * @class DebitCardsApi
 * @extends {BaseAPI}
 */
export class DebitCardsApi extends BaseAPI {
  /**
   *
   * @summary Fetch Card Account Details
   * @param {'v1'} version
   * @param {CardIdRequest} cardIdRequest Card ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DebitCardsApi
   */
  public cardAccountDetails(version: 'v1', cardIdRequest: CardIdRequest, options?: any) {
    return DebitCardsApiFp(this.configuration).cardAccountDetails(
      version,
      cardIdRequest,
      options
    )(this.axios, this.basePath)
  }
}
