import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { Icon, Text, theme, useDidMount } from '@mtsbank/ui-kit'
import { CardAddProps } from '@components/NewMain/types'
import { getCardStikerAddEvent } from '@root/utils/gtm/functions'
import { WrapperCard, WrapperCircle } from '../Styled/Wrappers'

const LENGTH_SIDE = 48

export const CardAdd: FC<CardAddProps> = ({ item, itemType }) => {
  const router = useRouter()

  useDidMount(() => {
    getCardStikerAddEvent(itemType)?.show()
  })

  const handleClick = () => {
    getCardStikerAddEvent(itemType)?.click()

    router.push(item.urlDetails)
  }

  return (
    <WrapperCard isClickable isNonePaddingBottom onClick={handleClick} alignItems="center">
      <Text size="md" color={theme.colors.neutral.g300}>
        {item.text}
      </Text>
      <WrapperCircle lengthSide={LENGTH_SIDE} marginLeft={theme.spacings.xs4}>
        <Icon icon="baseX24/ic-plus" color={theme.colors.neutral.g300} />
      </WrapperCircle>
    </WrapperCard>
  )
}
