import React, { useState, useContext, ReactNode, ReactNodeArray } from 'react'
import { ContextMenuConfig } from '@components/Breadcrumbs/types'

const BreadCrumbsContext = React.createContext<{ currentPath: string; contextMenuConfig: ContextMenuConfig[] }>({
  currentPath: '',
  contextMenuConfig: null,
})
const BreadCrumbsActionsContext = React.createContext<{
  setBreadCrumbs: (currentPath: string, contextMenuConfig: ContextMenuConfig[]) => void
}>({ setBreadCrumbs: null })

const BreadCrumbsProvider = ({ children }: { children: ReactNode | ReactNodeArray }) => {
  const [breadCrumbs, setBreadCrumbs] = useState({ currentPath: '', contextMenuConfig: null })

  const [breadCrumbsActions] = useState({
    setBreadCrumbs: (currentPath: string, contextMenuConfig: ContextMenuConfig[]) =>
      setBreadCrumbs((prevState) => ({ ...prevState, currentPath, contextMenuConfig })),
  })

  return (
    <BreadCrumbsContext.Provider value={breadCrumbs}>
      <BreadCrumbsActionsContext.Provider value={breadCrumbsActions}>{children}</BreadCrumbsActionsContext.Provider>
    </BreadCrumbsContext.Provider>
  )
}

const useBreadCrumbsData = () => useContext(BreadCrumbsContext)
const useBreadCrumbs = () => useContext(BreadCrumbsActionsContext)

export { BreadCrumbsProvider, useBreadCrumbs, useBreadCrumbsData }
