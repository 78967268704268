import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { SmartText } from '@mtsbank/common'
import { theme } from '@mtsbank/ui-kit'
import { selectMores } from '@root/selectors/fullApproveWidget'
import { OfferItem } from '../types'
import { Circle, CircleWrapper, CounterWrapper, LinkWrapper } from './style'

export const LinkWithOffersCounter: FC = ({ children }) => (
  <LinkWrapper>
    {children}
    <CounterWrapper>
      <OffersCounter />
    </CounterWrapper>
  </LinkWrapper>
)

const LENGTH_SIDE = 18

export const OffersCounter: FC = () => {
  const offerMores = useSelector(selectMores) as OfferItem[]

  if (!offerMores || !offerMores.length) {
    return null
  }

  return (
    <Circle lengthSide={LENGTH_SIDE} color={theme.colors.red.light}>
      <CircleWrapper>
        <SmartText
          fontSizeMob={theme.fonts.font_sizes.par.sm}
          fontWeightMob={theme.fonts.font_weights.medium}
          color={theme.colors.neutral.g800}
          lineHeightMob={theme.fonts.line_heights.par.sm}
        >
          {offerMores.length}
        </SmartText>
      </CircleWrapper>
    </Circle>
  )
}
