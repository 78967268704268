import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import isEmpty from 'lodash/isEmpty'
import { baseInitialState } from '@reducers/baseInitialState'
import { onboardingApi } from '@api/ump/onboarding'
import { devicePlatforms, OnboardingPlaces } from '@components/Onboarding/types'
import { getDeviceType } from '@utils/getPlatorm'
import { InitialState, OnboardingState } from '@reducers/onboarding/types'

const NAMESPACE = 'onboarding'
const onboardingInitialState: OnboardingState = {
  ...baseInitialState,
  isHidden: true,
  isOnboardingCompleted: false,
}

const initialState: InitialState = { onboardings: onboardingInitialState, onboardingByNum: onboardingInitialState }

const fetchGetOnboardings = createAsyncThunk(`${NAMESPACE}/getOnboardings`, async () => {
  const response = await onboardingApi.getOnboardings(
    devicePlatforms[getDeviceType()],
    OnboardingPlaces.BEFORE_MAIN_SCREEN
  )

  return { ...response?.data, httpStatus: response.status }
})

// todo: во 2 итерации надо будет открывать онбординги по прямой ссылке
const fetchGetOnboardingByNum = createAsyncThunk(
  `${NAMESPACE}/getOnBoardingByNum`,
  async (onBoardingVersion: number) => {
    const response = await onboardingApi.getOnboarding(devicePlatforms[getDeviceType()], onBoardingVersion)

    return { ...response?.data, httpStatus: response.status }
  }
)

const onboarding = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setIsHiddenOnboardings(state, action: PayloadAction<boolean>) {
      state.onboardings.isHidden = action.payload
    },
    setIsOnboardingCompleted(state, action: PayloadAction<boolean>) {
      state.onboardings.isOnboardingCompleted = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetOnboardings.pending, (state) => {
      state.onboardings = { ...onboardingInitialState }
    })
    builder.addCase(fetchGetOnboardings.rejected, (state, action) => {
      state.onboardings.error = action.error
      state.onboardings.inProgress = false
      state.onboardings.isOnboardingCompleted = true
    })
    builder.addCase(fetchGetOnboardings.fulfilled, (state, action) => {
      state.onboardings.data = action.payload
      state.onboardings.inProgress = false
      state.onboardings.isOnboardingCompleted = isEmpty(action.payload?.slides)
    })

    builder.addCase(fetchGetOnboardingByNum.pending, (state) => {
      state.onboardingByNum = { ...onboardingInitialState }
    })
    builder.addCase(fetchGetOnboardingByNum.rejected, (state, action) => {
      state.onboardingByNum.error = action.error
      state.onboardingByNum.inProgress = false
    })
    builder.addCase(fetchGetOnboardingByNum.fulfilled, (state, action) => {
      state.onboardingByNum.data = action.payload
      state.onboardingByNum.inProgress = false
    })
  },
})

export { fetchGetOnboardings }
export const { setIsHiddenOnboardings, setIsOnboardingCompleted } = onboarding.actions
export const onboardingReducer = onboarding.reducer
