import {
  ControllerApi as EwalletProfileApi,
  ControllerV2Api as EwalletProfileApiV2,
} from '@open-api/ump/ewallet-profile'

export const ewalletProfileApi = new EwalletProfileApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const ewalletProfileApiV2 = new EwalletProfileApiV2({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})
