import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import { response } from 'express'

//todo до выполнения DCDBO-11891
const basePath = process.env.NEXT_PUBLIC_SSO_HOST

/**
 * Получение access токена WebSSO
 * @param config
 * @returns
 */
export const getTokenApi: (config: AxiosRequestConfig) => AxiosPromise<void> = (config) =>
  axios(`${basePath}/client-profile/get_mts_token`, config)

export const getCredentials = () =>
  axios.get<{ result: boolean }>(`${basePath}/webauthn/credentials`).then((response) => response.data)

export const deleteCredentials = () =>
  axios.delete<{ result: boolean }>(`${basePath}/webauthn/publickey`).then((response) => response.data)
