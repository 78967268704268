import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { processingApi } from '@api/dot-net/api'
import { SendReceiptState } from '@reducers/mobilePayment/receipt/type'
import { SendReceiptRequest, SendReceiptResult } from '@generated/api'

const NAMESPACE = 'sendReceipt'

const initialState: SendReceiptState = {
  inProgress: false,
  error: null,
  errorEmail: '',
  email: '',
  data: null,
}

const fetchSendReceipt = createAsyncThunk(
  `${NAMESPACE}`,
  async (data: SendReceiptRequest): Promise<SendReceiptResult> => {
    const response = await processingApi.apiProcessingSendReceiptPost(data)

    return response.data
  }
)

const sendReceipt = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setErrorEmail: (state, action) => {
      state.errorEmail = action.payload
    },
    setReset: (state) => {
      state.error = null
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSendReceipt.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchSendReceipt.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(fetchSendReceipt.fulfilled, (state, action) => {
      state.inProgress = false
      state.data = { ...action.payload }
    })
  },
})

export { fetchSendReceipt }

export const sendReceiptReducer = sendReceipt.reducer
export const { setEmail, setReset, setErrorEmail } = sendReceipt.actions
