import React, { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react'
import { MenuLevel, SideMenuContextProps } from '@components/SideMenu/types'

export const SideMenuContext = createContext<SideMenuContextProps>({
  menuLevel: {
    level: MenuLevel.ROOT,
  },
  setMenuLevel: () => {},
  menuOpened: false,
  setMenuOpened: () => {},
})

export const useSideMenuContext = () => useContext(SideMenuContext)

export const SideMenuProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [menuLevel, setMenuLevel] = useState({ level: MenuLevel.ROOT })
  const [menuOpened, setMenuOpened] = useState(false)

  const contextValue = useMemo(
    () => ({
      menuLevel,
      setMenuLevel,
      menuOpened,
      setMenuOpened,
    }),
    [menuLevel, menuOpened]
  )

  return <SideMenuContext.Provider value={contextValue}>{children}</SideMenuContext.Provider>
}
