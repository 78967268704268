import { createSelector } from 'reselect'
import { RootState } from '@root/reducers'
import { getServiceKeyByType, ServiceKeyType } from '@reducers/mobilePayment/paymentServiceInfo/paymentServiceInfo'

const selectMobilePayment = (state: RootState) => state.mobilePayment

export const selectSendReceipt = createSelector([selectMobilePayment], (mobilePayment) => mobilePayment.sendReceipt)
export const selectReceiptDetails = createSelector(
  [selectMobilePayment],
  (mobilePayment) => mobilePayment.receiptDetails
)
export const selectOperationDetails = createSelector(
  [selectMobilePayment],
  (mobilePayment) => mobilePayment.operationDetails
)

export const selectPaymentServiceByPhone = (phone: string) => (state: RootState) =>
  state.mobilePayment.paymentService.list[getServiceKeyByType(ServiceKeyType.PHONE, phone)] ||
  state.mobilePayment.paymentService.default

export const selectPaymentServiceByServiceId = (serviceId: string) => (state: RootState) =>
  state.mobilePayment.paymentService.list[getServiceKeyByType(ServiceKeyType.SERVICE_ID, serviceId)] ||
  state.mobilePayment.paymentService.default

export const selectPaymentServiceByKey = (key: string) => (state: RootState) =>
  state.mobilePayment.paymentService.list[key] || state.mobilePayment.paymentService.default

export const getSelectedServiceInfoKey = (state: RootState) => state.mobilePayment.paymentService.selected

export const selectBannerInfo = (state: RootState) => state.mobilePayment.bannerInfo

export const selectAutoPayment = (state: RootState) => state.mobilePayment.operationDetails

export const selectRecommendations = createSelector(
  [selectMobilePayment],
  (mobilePayment) => mobilePayment.recommendations
)

export const selectRecommendationAmountInfo = createSelector(
  [selectRecommendations],
  (recommendations) => recommendations.amount
)

export const selectRecommendationAmountInfoByPhone = (phone: string) =>
  createSelector([selectRecommendationAmountInfo], (state) => state.list[`phone${phone}`] || state.default)
