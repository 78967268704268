import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { InitialPosLimitState } from '@reducers/credits/type'
import { dboPosLimitControllerApi } from '@root/api/ump/dbo-pos-limit'
import { ApplicationDtoStatusEnum } from '@open-api/ump/dbo-pos-limit'

const NAMESPACE = 'posLimit'

const initialState: InitialPosLimitState = {
  inProgress: false,
  error: null,
  data: [],
}

export const fetchDboPosLimit = createAsyncThunk(`${NAMESPACE}/fetchGetDboPosLimit`, async () => {
  const { data } = await dboPosLimitControllerApi.fetchApplications([
    ApplicationDtoStatusEnum.LIMIT,
    ApplicationDtoStatusEnum.DOCVERIFYSALON,
  ])

  if (data.length !== 1) {
    throw new Error()
  }

  return data
})

const dboPosLimit = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDboPosLimit.pending, (state) => {
      state.data = []
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchDboPosLimit.rejected, (state, action) => {
      state.error = action.error
      state.inProgress = false
      state.data = []
    })
    builder.addCase(fetchDboPosLimit.fulfilled, (state, action) => {
      state.inProgress = false
      state.error = null
      state.data = action.payload
    })
  },
})

export const posLimitReducer = dboPosLimit.reducer
