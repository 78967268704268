import { useEffect, useState } from 'react'
import { addEventListenerRAF } from '@root/utils/addEventListenerRAF'

export const useViewportWidth = () => {
  const [width, setWidth] = useState(() => window.innerWidth)

  useEffect(() => {
    const widthHandler = () => setWidth(window.innerWidth)

    return addEventListenerRAF(window, 'resize', widthHandler)
  }, [])

  return width
}
