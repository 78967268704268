import React, { FC } from 'react'

import { theme, InputHelperText as BaseInputHelperText } from '@mtsbank/ui-kit'
import { Content } from '@components/Content/Content'

interface Props {
  hasError?: boolean
}

export const InputHelperText: FC<Props> = ({ hasError, children }) => (
  <Content marginTop={theme.spacings.xs4}>
    <BaseInputHelperText hasError={hasError}>{children}</BaseInputHelperText>
  </Content>
)
