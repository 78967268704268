import { ReactNode } from 'react'
import { MobileAppWindow } from '@root/components/NCPK/types'
import { EGibTypes } from '@root/constants'
import { uuid } from './generateUuid'

declare const window: MobileAppWindow

type TConstructorType = {
  type: EGibTypes
}

type TGroupIBInit = (values: {
  cid: string
  backUrl: string
  forceFirstAlive: boolean
  gafUrl?: string
  rsaModulus?: string
}) => void

// todo требуется заменить на один gib скрипт для всех страниц, пока продублирован скрипт
const GROUPIB_SCRIPT_URL = `${process.env.NEXT_PUBLIC_STATIC_HOST}/js/f7f85c40-b711-11ee-aa72-46fbad000000.js`
const GIB_NCPK_SCRIPT_URL = `${process.env.NEXT_PUBLIC_STATIC_HOST}/js/f7f85c40-b711-11ee-aa72-46fbad000000.js`

type TGroupIB = {
  init: TGroupIBInit
  setAuthStatus: (values: boolean) => void
  setSessionID: (values: string) => void
  IS_GUEST?: boolean
  IS_AUTHORIZED?: boolean
  addInput?: (title: string, inputElement: ReactNode) => void
  setIdentityAndEncrypt?: (loginValue: string) => void
  setAttribute?: (name: string, value: number | string, options: { persistent: boolean }) => void
}

declare global {
  interface Window {
    gib: TGroupIB
    onGIBLoad?: TGroupIBInit
    gibSessionId?: string
    gibRequestId?: string
    gibUserId?: string
  }
}

export const checkGibInitOnMobile = () => {
  const isAndroidSDK = window.AndroidSdk
  const isIosSDK = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.request_iOS_SDK

  return !isAndroidSDK && !isIosSDK
}

const getGibConfigByType = (type: EGibTypes) => {
  switch (type) {
    case EGibTypes.NCPK: {
      return {
        cid: window.location.host === 'payment.mts.ru' ? 'mtsb-w-loans' : 'mtsb-w-loans-t',
        backUrl: '//sb.mts.ru/api/fl',
        silentAlive: true,
        forceFirstAlive: true,
        rsaModulus:
          'B1A3EFBBF4A831C72485B0016269814CDBADF78E4ED11F3F0D79E488F212F2422BBB7930FB63749C3B33FD67ADB6223896078D969E81CEC5A1A09775D746A45B8C911E1DF14CFE27A4F6608F06833AAEBFC2C18CFC327243D78B2BD5853A6247A66BE2725C9484504D6EA311572283B5CF55E95E9C533858B23B770ABC9C38CE6F4575606B389858FFC1DF205D362D0BC786970BF7B77C7E5D0792A49BB93A6B9E60066F962B9F6013E1788607BA731EA7B3789C2D22B65162CB82DF7E5023187E1CB6FDFB35B47E548EAC6E3AE679D0F6DD1F60C016EE17B68E7CBFFE41E4D8F34DB129BDFF43910EE0BC217874DF7394455D405E1FB2B04308137810121635',
      }
    }
    default: {
      return {
        cid: process.env.NEXT_PUBLIC_BUILD_ENV === 'production' ? 'mts-w-payment' : 'mts-w-payment-t',
        backUrl: '//sb.mts.ru/api/fl',
        forceFirstAlive: true,
        gafUrl: '//ru.id.group-ib.com/id.html',
      }
    }
  }
}

class GroupIBService {
  public sessionId = uuid()

  public requestId = uuid()

  public gib: TGroupIB

  public type: EGibTypes

  constructor({ type }: TConstructorType) {
    this.type = type

    if (typeof window !== 'undefined') {
      this.gib = window.gib

      const initAndConfigureGib = () => {
        this.gib.init(getGibConfigByType(this.type))
        this.configureGib()
      }

      if (typeof this.gib === 'undefined') {
        window.onGIBLoad = () => {
          this.gib = window.gib
          initAndConfigureGib()
        }
      } else {
        initAndConfigureGib()
      }
    }
  }

  static addElement(elem: HTMLInputElement) {
    window.gib?.addInput(elem && elem.name, elem)
  }

  static setAttribute(issueId?: number) {
    const value = typeof issueId !== 'undefined' ? `Payment-${issueId}` : null

    window.gib?.setAttribute('Request_id', value, { persistent: true })
  }

  setIdentity(mobilePhone: string) {
    this.gib?.setIdentityAndEncrypt(mobilePhone)
  }

  updateCredentials() {
    this.sessionId = uuid()
    this.requestId = uuid()

    if (typeof window !== 'undefined') {
      window.gibRequestId = this.requestId
      window.gibSessionId = this.sessionId
      window.gib?.setSessionID(window.gibSessionId)
    }
  }

  configureGib() {
    if (!this.gib) {
      console.error('Gib is not initialized to configure')

      return
    }

    switch (this.type) {
      case EGibTypes.NCPK: {
        this.gib.setAuthStatus(true)
        this.gib.setSessionID(this.sessionId)
        break
      }
      case EGibTypes.PAYMENT:
        window.gibRequestId = this.requestId
        window.gibSessionId = this.sessionId
        this.gib.setAuthStatus(false)
        this.gib.setSessionID(this.sessionId)
        break
      default:
        break
    }
  }
}

export { GroupIBService, GROUPIB_SCRIPT_URL, GIB_NCPK_SCRIPT_URL }
