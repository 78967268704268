import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import mapKeys from 'lodash/fp/mapKeys'
import camelCase from 'lodash/fp/camelCase'
import { HistoryProtoNotificationType } from '@open-api/ump/response-history'
import { PersonalOffersProtoErrorRsp, PersonalOffersProtoGetOfferRsp } from '@open-api/ump/st-personal-offers'
import { stPersonalOffersApi } from '@api/ump/st-personal-offers'
import { FullApproveState, GetOfferParams, ResponseHistoryParams } from '@reducers/fullApproveWidget/types'
import { baseInitialState } from '@reducers/baseInitialState'
import { saveResponseHistory } from '@utils/api'

const NAMESPACE = 'fullApprove'

export const initialState: FullApproveState = {
  getOffer: { ...baseInitialState },
  getOfferPage: { ...baseInitialState },
  getOfferPopup: { ...baseInitialState },
  banners: { ...baseInitialState },
  responseHistory: { ...baseInitialState, productsToTheHideID: [] },
}

export const responseHistoryParams = {
  responseData: {
    channel: HistoryProtoNotificationType.PMT,
    effDt: new Date().toISOString().slice(0, -1),
    externalId: undefined,
    responseId: undefined,
    personalInfo: {
      rboId: undefined,
    },
    additionalData: {
      marketingRetail: {
        deviceOs: 'undefined',
        deviceType: 'Mobile',
        numberPattern: undefined,
      },
    },
  },
}

const asyncGetOffer = async ({ componentType, offerID }: GetOfferParams, { rejectWithValue }) => {
  try {
    const params: GetOfferParams = {
      os: undefined,
      appName: undefined,
      version: undefined,
      productType: undefined,
      additionalData: undefined,
      channel: HistoryProtoNotificationType.PMT,
      siebelId: undefined,
      phoneNumb: undefined,
      offerID,
      componentType,
      countOffers: undefined,
      rboID: undefined,
      options: {
        headers: {
          additionalData: true,
        },
      },
    }
    const response = await stPersonalOffersApi.getOffer(...(<string[]>Object.values(params)))

    return mapKeys(camelCase, response.data)
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
}

export const getOffer = createAsyncThunk<
  PersonalOffersProtoGetOfferRsp,
  GetOfferParams,
  { rejectValue: PersonalOffersProtoErrorRsp }
>(`${NAMESPACE}/getOffer`, asyncGetOffer)

export const responseHistorySave = createAsyncThunk<unknown, ResponseHistoryParams>(
  `${NAMESPACE}/save`,
  async ({ offer, responseId, phoneNum }) => {
    const response = await saveResponseHistory(offer, responseId, phoneNum)

    return mapKeys(camelCase, { ...response.data })
  }
)

const fullApproveWidget = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOffer.pending, (state) => {
      state.getOffer.inProgress = true
      state.getOffer.error = null
    })
    builder.addCase(getOffer.rejected, (state, action) => {
      state.getOffer.inProgress = false
      state.getOffer.error = action.error
    })
    builder.addCase(getOffer.fulfilled, (state, action) => {
      state.getOffer.data = action.payload
      state.getOffer.inProgress = false
      state.getOffer.error = null
    })

    builder.addCase(responseHistorySave.pending, (state, { meta, payload }) => {
      const offerId = meta.arg?.offer?.offerId

      state.responseHistory.productsToTheHideID = [...state.responseHistory.productsToTheHideID, offerId]
      state.responseHistory.data = payload
    })
  },
})

export const fullApproveWidgetReducer = fullApproveWidget.reducer
