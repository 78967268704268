import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Showcase } from '@open-api/ump/st-personal-offers'
import { stPersonalOfferApi } from '@api/ump/st-personal-offers'
import { BaseState, isAxiosError } from '@root/types/reducers'
import { HistoryProtoNotificationType } from '@open-api/ump/response-history'

const NAMESPACE = 'bankProducts'

const initialState: BaseState<Showcase[]> = {
  inProgress: true,
  data: null,
  error: null,
}

const fetchShowcase = createAsyncThunk<{ showcase: Showcase[] }>(
  `${NAMESPACE}/showсase`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await stPersonalOfferApi.stPersonalOffersVersionShowcaseGet('v1', {
        query: { channel: HistoryProtoNotificationType.PMT },
      })

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const bankProducts = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShowcase.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchShowcase.fulfilled, (state, action) => {
      state.inProgress = false
      state.data = action.payload.showcase || []
    })
    builder.addCase(fetchShowcase.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
  },
})

export { fetchShowcase }
export const bankProductsReducer = bankProducts.reducer
