import React, { FC } from 'react';
import { Icon, theme } from '@mtsbank/ui-kit';
import { SmartText } from '@mtsbank/common';
import { MTSDENGI_APK_PATH } from '@root/constants';
import { Styled } from './styled';
export const AppButton: FC = () => <Styled.Wrapper alignItems="center">
    <Styled.IconWrapper href={MTSDENGI_APK_PATH} target="_blank">
      <Icon icon="icon-52/mtsLogo" />
    </Styled.IconWrapper>
    <Styled.BodyWrapper href={MTSDENGI_APK_PATH} target="_blank">
      <SmartText fontSizeMob={theme.fonts.font_sizes.par.md} lineHeightMob={theme.fonts.line_heights.par.md}>
        Установите МТС Банк на&nbsp;смартфон
      </SmartText>
      <SmartText fontSizeMob={theme.fonts.font_sizes.par.sm} lineHeightMob={theme.fonts.line_heights.par.sm} color={theme.colors.neutral.g300} />
    </Styled.BodyWrapper>
  </Styled.Wrapper>;