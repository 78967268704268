import React, { useCallback } from 'react'
import useSWRImmutable from 'swr'
import { SkeletonRect, Text, theme } from '@mtsbank/ui-kit'
import { getCredentials } from '@api/sso'
import { useSideMenuContext } from '@components/SideMenu/SideMenuProvider'
import { MenuLevel } from '@components/SideMenu/types'
import { GET_CREDENTIALS_KEY } from '@components/SideMenu/swr'

export const LoginSettingsMenuItem = () => {
  const { setMenuLevel } = useSideMenuContext()
  const { data, isValidating } = useSWRImmutable(GET_CREDENTIALS_KEY, getCredentials, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })

  const handleSettingsClick = useCallback(() => {
    setMenuLevel({ level: MenuLevel.FIRST })
  }, [setMenuLevel])

  if (isValidating) {
    return <SkeletonRect />
  }

  if (data?.result) {
    return (
      <Text fontWeight="medium" color={theme.colors.black} onClick={handleSettingsClick}>
        Настройки входа
      </Text>
    )
  }

  return null
}
