// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AdditionalParams
 */
export interface AdditionalParams {
  /**
   *
   * @type {string}
   * @memberof AdditionalParams
   */
  clientExtId?: string
  /**
   *
   * @type {string}
   * @memberof AdditionalParams
   */
  sourceProductId?: string
  /**
   *
   * @type {number}
   * @memberof AdditionalParams
   */
  fee?: number
}
/**
 *
 * @export
 * @interface BankInfo
 */
export interface BankInfo {
  /**
   *
   * @type {string}
   * @memberof BankInfo
   */
  bankId?: string
  /**
   *
   * @type {string}
   * @memberof BankInfo
   */
  bankName?: string
  /**
   *
   * @type {boolean}
   * @memberof BankInfo
   */
  defaultBank?: boolean
  /**
   *
   * @type {string}
   * @memberof BankInfo
   */
  BIC?: string
}
/**
 *
 * @export
 * @interface BaseResponse
 */
export interface BaseResponse {
  /**
   *
   * @type {string}
   * @memberof BaseResponse
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface CheckReq
 */
export interface CheckReq {
  /**
   *
   * @type {string}
   * @memberof CheckReq
   */
  operationId?: string
  /**
   *
   * @type {number}
   * @memberof CheckReq
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof CheckReq
   */
  wallet?: string
  /**
   *
   * @type {string}
   * @memberof CheckReq
   */
  serviceId?: string
  /**
   *
   * @type {ServiceParams}
   * @memberof CheckReq
   */
  serviceParams?: ServiceParams
  /**
   *
   * @type {AdditionalParams}
   * @memberof CheckReq
   */
  additionalParams?: AdditionalParams
}
/**
 *
 * @export
 * @interface CheckRes
 */
export interface CheckRes {
  /**
   *
   * @type {number}
   * @memberof CheckRes
   */
  errorCode?: number
  /**
   *
   * @type {string}
   * @memberof CheckRes
   */
  errorCause?: string
  /**
   *
   * @type {string}
   * @memberof CheckRes
   */
  checkPaymentResult?: string
}
/**
 *
 * @export
 * @interface CheckTransferReq
 */
export interface CheckTransferReq {
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  srcPhone?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  rqid?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  clientId?: CheckTransferReqClientIdEnum
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  senderPhone?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  recipientPhone?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  hashedPAN?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  accountNumber?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  cardExpiry?: string
  /**
   *
   * @type {number}
   * @memberof CheckTransferReq
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  recipientBankId?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  recipientBankName?: string
  /**
   *
   * @type {string}
   * @memberof CheckTransferReq
   */
  userMessage?: string
  /**
   *
   * @type {number}
   * @memberof CheckTransferReq
   */
  rboId?: number
  /**
   *
   * @type {boolean}
   * @memberof CheckTransferReq
   */
  isInternalTransfer?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum CheckTransferReqClientIdEnum {
  IOS = 'MTS_MONEY_IOS',
  ANDROID = 'MTS_MONEY_ANDROID',
}

/**
 *
 * @export
 * @interface CheckTransferRes
 */
export interface CheckTransferRes {
  /**
   *
   * @type {Transfer}
   * @memberof CheckTransferRes
   */
  transfer?: Transfer
  /**
   *
   * @type {string}
   * @memberof CheckTransferRes
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface ConfirmTransferReq
 */
export interface ConfirmTransferReq {
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferReq
   */
  srcPhone?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferReq
   */
  rqid?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferReq
   */
  clientId?: ConfirmTransferReqClientIdEnum
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferReq
   */
  transferId?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferReq
   */
  otpCode?: string
  /**
   *
   * @type {number}
   * @memberof ConfirmTransferReq
   */
  rboId?: number
}

/**
 * @export
 * @enum {string}
 */
export enum ConfirmTransferReqClientIdEnum {
  IOS = 'MTS_MONEY_IOS',
  ANDROID = 'MTS_MONEY_ANDROID',
}

/**
 *
 * @export
 * @interface ConfirmTransferRes
 */
export interface ConfirmTransferRes {
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  status?: ConfirmTransferResStatusEnum
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  statusDateTime?: string
  /**
   *
   * @type {number}
   * @memberof ConfirmTransferRes
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  senderPam?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  recipientPAM?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  bindingType?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  cardType?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  mnemonic?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  maskedPAN?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  recipientPhone?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  recipientBankId?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  userMessage?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  transferType?: ConfirmTransferResTransferTypeEnum
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  sbpMdOrder?: string
  /**
   *
   * @type {Array<Detail>}
   * @memberof ConfirmTransferRes
   */
  details?: Array<Detail>
  /**
   *
   * @type {string}
   * @memberof ConfirmTransferRes
   */
  requestId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum ConfirmTransferResStatusEnum {
  INPROGRESS = 'IN_PROGRESS',
  EXECUTED = 'EXECUTED',
  REJECTED = 'REJECTED',
}
/**
 * @export
 * @enum {string}
 */
export enum ConfirmTransferResTransferTypeEnum {
  INTERNAL = 'INTERNAL',
  SBP = 'SBP',
  SBER = 'SBER',
}

/**
 *
 * @export
 * @interface Detail
 */
export interface Detail {
  /**
   *
   * @type {string}
   * @memberof Detail
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof Detail
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof Detail
   */
  value?: string
}
/**
 *
 * @export
 * @interface GetStatusesReq
 */
export interface GetStatusesReq {
  /**
   *
   * @type {Array<string>}
   * @memberof GetStatusesReq
   */
  ids?: Array<string>
}
/**
 *
 * @export
 * @interface GetStatusesRes
 */
export interface GetStatusesRes {
  /**
   *
   * @type {number}
   * @memberof GetStatusesRes
   */
  errorCode?: number
  /**
   *
   * @type {string}
   * @memberof GetStatusesRes
   */
  errorCause?: string
  /**
   *
   * @type {Array<PaymentStatus>}
   * @memberof GetStatusesRes
   */
  paymentStatuses?: Array<PaymentStatus>
}
/**
 *
 * @export
 * @interface GetTransferListReq
 */
export interface GetTransferListReq {
  /**
   *
   * @type {string}
   * @memberof GetTransferListReq
   */
  senderPhone?: string
  /**
   *
   * @type {string}
   * @memberof GetTransferListReq
   */
  recipientPhone?: string
}
/**
 *
 * @export
 * @interface GetTransferListRes
 */
export interface GetTransferListRes {
  /**
   *
   * @type {string}
   * @memberof GetTransferListRes
   */
  mtsBankClientPAM?: string
  /**
   *
   * @type {Array<BankInfo>}
   * @memberof GetTransferListRes
   */
  sbpBanksList?: Array<BankInfo>
  /**
   *
   * @type {string}
   * @memberof GetTransferListRes
   */
  requestId?: string
  /**
   *
   * @type {boolean}
   * @memberof GetTransferListRes
   */
  isRecipientMtsBankClient?: boolean
}
/**
 *
 * @export
 * @interface GetTransferStatusReq
 */
export interface GetTransferStatusReq {
  /**
   *
   * @type {string}
   * @memberof GetTransferStatusReq
   */
  transferId?: string
}
/**
 *
 * @export
 * @interface GetTransferStatusRes
 */
export interface GetTransferStatusRes {
  /**
   *
   * @type {string}
   * @memberof GetTransferStatusRes
   */
  status?: GetTransferStatusResStatusEnum
  /**
   *
   * @type {string}
   * @memberof GetTransferStatusRes
   */
  statusDateTime?: string
  /**
   *
   * @type {string}
   * @memberof GetTransferStatusRes
   */
  requestId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum GetTransferStatusResStatusEnum {
  INPROGRESS = 'IN_PROGRESS',
  EXECUTED = 'EXECUTED',
  REJECTED = 'REJECTED',
}

/**
 *
 * @export
 * @interface InitiateTransferReq
 */
export interface InitiateTransferReq {
  /**
   *
   * @type {string}
   * @memberof InitiateTransferReq
   */
  srcPhone?: string
  /**
   *
   * @type {string}
   * @memberof InitiateTransferReq
   */
  rqid?: string
  /**
   *
   * @type {string}
   * @memberof InitiateTransferReq
   */
  clientId?: InitiateTransferReqClientIdEnum
  /**
   *
   * @type {string}
   * @memberof InitiateTransferReq
   */
  transferId?: string
  /**
   *
   * @type {number}
   * @memberof InitiateTransferReq
   */
  rboId?: number
}

/**
 * @export
 * @enum {string}
 */
export enum InitiateTransferReqClientIdEnum {
  IOS = 'MTS_MONEY_IOS',
  ANDROID = 'MTS_MONEY_ANDROID',
}

/**
 *
 * @export
 * @interface PaymentStatus
 */
export interface PaymentStatus {
  /**
   *
   * @type {string}
   * @memberof PaymentStatus
   */
  paymentId?: string
  /**
   *
   * @type {string}
   * @memberof PaymentStatus
   */
  paymentStatus?: string
  /**
   *
   * @type {number}
   * @memberof PaymentStatus
   */
  errorCode?: number
}
/**
 *
 * @export
 * @interface ReceiveReq
 */
export interface ReceiveReq {
  /**
   *
   * @type {string}
   * @memberof ReceiveReq
   */
  approvalCode?: string
  /**
   *
   * @type {string}
   * @memberof ReceiveReq
   */
  RRN?: string
}
/**
 *
 * @export
 * @interface ReceiveRes
 */
export interface ReceiveRes {
  /**
   *
   * @type {number}
   * @memberof ReceiveRes
   */
  errorCode?: number
  /**
   *
   * @type {string}
   * @memberof ReceiveRes
   */
  errorCause?: string
  /**
   *
   * @type {string}
   * @memberof ReceiveRes
   */
  paymentStatus?: string
}
/**
 *
 * @export
 * @interface ResendOtpReq
 */
export interface ResendOtpReq {
  /**
   *
   * @type {string}
   * @memberof ResendOtpReq
   */
  srcPhone?: string
  /**
   *
   * @type {string}
   * @memberof ResendOtpReq
   */
  rqid?: string
  /**
   *
   * @type {string}
   * @memberof ResendOtpReq
   */
  clientId?: ResendOtpReqClientIdEnum
  /**
   *
   * @type {string}
   * @memberof ResendOtpReq
   */
  transferId?: string
  /**
   *
   * @type {number}
   * @memberof ResendOtpReq
   */
  rboId?: number
}

/**
 * @export
 * @enum {string}
 */
export enum ResendOtpReqClientIdEnum {
  IOS = 'MTS_MONEY_IOS',
  ANDROID = 'MTS_MONEY_ANDROID',
}

/**
 *
 * @export
 * @interface ServiceParams
 */
export interface ServiceParams {
  /**
   *
   * @type {string}
   * @memberof ServiceParams
   */
  transferId?: string
}
/**
 *
 * @export
 * @interface Transfer
 */
export interface Transfer {
  /**
   *
   * @type {string}
   * @memberof Transfer
   */
  transferId?: string
  /**
   *
   * @type {number}
   * @memberof Transfer
   */
  feeAmount?: number
  /**
   *
   * @type {number}
   * @memberof Transfer
   */
  totalAmount?: number
  /**
   *
   * @type {string}
   * @memberof Transfer
   */
  recipientPAM?: string
  /**
   *
   * @type {string}
   * @memberof Transfer
   */
  transferType?: TransferTransferTypeEnum
  /**
   *
   * @type {number}
   * @memberof Transfer
   */
  issuerFee?: number
  /**
   *
   * @type {boolean}
   * @memberof Transfer
   */
  isSberDirectTransfer?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum TransferTransferTypeEnum {
  INTERNAL = 'INTERNAL',
  SBP = 'SBP',
  SBER = 'SBER',
}

/**
 * TransferByPhoneApi - axios parameter creator
 * @export
 */
export const TransferByPhoneApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Проверка возможности платежа
     * @param {string} operationId
     * @param {CheckReq} [checkReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(operationId: string, checkReq?: CheckReq, options: any = {}): RequestArgs {
      // verify required parameter 'operationId' is not null or undefined
      if (operationId === null || operationId === undefined) {
        throw new RequiredError(
          'operationId',
          'Required parameter operationId was null or undefined when calling check.'
        )
      }
      const localVarPath = `/transfers-byphone/pcew/{operationId}/check`.replace(
        `{${'operationId'}}`,
        encodeURIComponent(String(operationId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof checkReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkReq !== undefined ? checkReq : {})
        : checkReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Проверка возможности осуществления транзакции перевода денежных средств с указанного ПИ на выбранное направление перевода, получение размера взимаемой комиссии.
     * @param {'v1'} version
     * @param {CheckTransferReq} [checkTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransfer(version: 'v1', checkTransferReq?: CheckTransferReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling checkTransfer.'
        )
      }
      const localVarPath = `/transfers-byphone/{version}/checkTransfer`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof checkTransferReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkTransferReq !== undefined ? checkTransferReq : {})
        : checkTransferReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Подтверждение транзакции.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {ConfirmTransferReq} [confirmTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransfer(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      confirmTransferReq?: ConfirmTransferReq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling confirmTransfer.'
        )
      }
      // verify required parameter 'requestID' is not null or undefined
      if (requestID === null || requestID === undefined) {
        throw new RequiredError(
          'requestID',
          'Required parameter requestID was null or undefined when calling confirmTransfer.'
        )
      }
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          'authorization',
          'Required parameter authorization was null or undefined when calling confirmTransfer.'
        )
      }
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling confirmTransfer.'
        )
      }
      const localVarPath = `/transfers-byphone/{version}/confirmTransfer`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboid !== undefined && rboid !== null) {
        localVarHeaderParameter['rboid'] = String(rboid)
      }

      if (requestID !== undefined && requestID !== null) {
        localVarHeaderParameter['Request-ID'] = String(requestID)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      if (clientId !== undefined && clientId !== null) {
        localVarHeaderParameter['Client-id'] = String(clientId)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof confirmTransferReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(confirmTransferReq !== undefined ? confirmTransferReq : {})
        : confirmTransferReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Получение статуса операции
     * @param {GetStatusesReq} [getStatusesReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatuses(getStatusesReq?: GetStatusesReq, options: any = {}): RequestArgs {
      const localVarPath = `/transfers-byphone/pcew/getStatuses`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof getStatusesReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getStatusesReq !== undefined ? getStatusesReq : {})
        : getStatusesReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Получение списка направлений переводов денежных средств. Осуществляются внутренние переводы в МТС Банк, переводы по СБП и переводы напрямую в Сбербанк.
     * @param {'v1'} version
     * @param {GetTransferListReq} [getTransferListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferList(version: 'v1', getTransferListReq?: GetTransferListReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTransferList.'
        )
      }
      const localVarPath = `/transfers-byphone/{version}/getTransferList`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof getTransferListReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getTransferListReq !== undefined ? getTransferListReq : {})
        : getTransferListReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Получение статуса перевода по транзакции.
     * @param {'v1'} version
     * @param {GetTransferStatusReq} [getTransferStatusReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferStatus(version: 'v1', getTransferStatusReq?: GetTransferStatusReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTransferStatus.'
        )
      }
      const localVarPath = `/transfers-byphone/{version}/getTransferStatus`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof getTransferStatusReq !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getTransferStatusReq !== undefined ? getTransferStatusReq : {})
        : getTransferStatusReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'v1' | 'v2'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion(version: 'v1' | 'v2', options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling getVersion.')
      }
      const localVarPath = `/transfers-byphone/{version}/version`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Запрос на инициацию перевода по ранее указанным параметрам.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {InitiateTransferReq} [initiateTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initiateTransfer(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      initiateTransferReq?: InitiateTransferReq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling initiateTransfer.'
        )
      }
      // verify required parameter 'requestID' is not null or undefined
      if (requestID === null || requestID === undefined) {
        throw new RequiredError(
          'requestID',
          'Required parameter requestID was null or undefined when calling initiateTransfer.'
        )
      }
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          'authorization',
          'Required parameter authorization was null or undefined when calling initiateTransfer.'
        )
      }
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling initiateTransfer.'
        )
      }
      const localVarPath = `/transfers-byphone/{version}/initiateTransfer`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboid !== undefined && rboid !== null) {
        localVarHeaderParameter['rboid'] = String(rboid)
      }

      if (requestID !== undefined && requestID !== null) {
        localVarHeaderParameter['Request-ID'] = String(requestID)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      if (clientId !== undefined && clientId !== null) {
        localVarHeaderParameter['Client-id'] = String(clientId)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof initiateTransferReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(initiateTransferReq !== undefined ? initiateTransferReq : {})
        : initiateTransferReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Зачисление принятого платежа
     * @param {string} paymentId
     * @param {ReceiveReq} [receiveReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    receive(paymentId: string, receiveReq?: ReceiveReq, options: any = {}): RequestArgs {
      // verify required parameter 'paymentId' is not null or undefined
      if (paymentId === null || paymentId === undefined) {
        throw new RequiredError('paymentId', 'Required parameter paymentId was null or undefined when calling receive.')
      }
      const localVarPath = `/transfers-byphone/pcew/{paymentId}/receive`.replace(
        `{${'paymentId'}}`,
        encodeURIComponent(String(paymentId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof receiveReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(receiveReq !== undefined ? receiveReq : {})
        : receiveReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Переотправка sms.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {ResendOtpReq} [resendOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendOtp(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      resendOtpReq?: ResendOtpReq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling resendOtp.')
      }
      // verify required parameter 'requestID' is not null or undefined
      if (requestID === null || requestID === undefined) {
        throw new RequiredError(
          'requestID',
          'Required parameter requestID was null or undefined when calling resendOtp.'
        )
      }
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          'authorization',
          'Required parameter authorization was null or undefined when calling resendOtp.'
        )
      }
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError('clientId', 'Required parameter clientId was null or undefined when calling resendOtp.')
      }
      const localVarPath = `/transfers-byphone/{version}/resendOtp`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboid !== undefined && rboid !== null) {
        localVarHeaderParameter['rboid'] = String(rboid)
      }

      if (requestID !== undefined && requestID !== null) {
        localVarHeaderParameter['Request-ID'] = String(requestID)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      if (clientId !== undefined && clientId !== null) {
        localVarHeaderParameter['Client-id'] = String(clientId)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof resendOtpReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(resendOtpReq !== undefined ? resendOtpReq : {})
        : resendOtpReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TransferByPhoneApi - functional programming interface
 * @export
 */
export const TransferByPhoneApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Проверка возможности платежа
     * @param {string} operationId
     * @param {CheckReq} [checkReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(
      operationId: string,
      checkReq?: CheckReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckRes> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).check(operationId, checkReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Проверка возможности осуществления транзакции перевода денежных средств с указанного ПИ на выбранное направление перевода, получение размера взимаемой комиссии.
     * @param {'v1'} version
     * @param {CheckTransferReq} [checkTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransfer(
      version: 'v1',
      checkTransferReq?: CheckTransferReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckTransferRes> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).checkTransfer(
        version,
        checkTransferReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Подтверждение транзакции.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {ConfirmTransferReq} [confirmTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransfer(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      confirmTransferReq?: ConfirmTransferReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmTransferRes> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).confirmTransfer(
        version,
        requestID,
        authorization,
        clientId,
        rboid,
        confirmTransferReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Получение статуса операции
     * @param {GetStatusesReq} [getStatusesReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatuses(
      getStatusesReq?: GetStatusesReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusesRes> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).getStatuses(getStatusesReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Получение списка направлений переводов денежных средств. Осуществляются внутренние переводы в МТС Банк, переводы по СБП и переводы напрямую в Сбербанк.
     * @param {'v1'} version
     * @param {GetTransferListReq} [getTransferListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferList(
      version: 'v1',
      getTransferListReq?: GetTransferListReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransferListRes> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).getTransferList(
        version,
        getTransferListReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Получение статуса перевода по транзакции.
     * @param {'v1'} version
     * @param {GetTransferStatusReq} [getTransferStatusReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferStatus(
      version: 'v1',
      getTransferStatusReq?: GetTransferStatusReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransferStatusRes> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).getTransferStatus(
        version,
        getTransferStatusReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'v1' | 'v2'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion(
      version: 'v1' | 'v2',
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).getVersion(version, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Запрос на инициацию перевода по ранее указанным параметрам.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {InitiateTransferReq} [initiateTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initiateTransfer(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      initiateTransferReq?: InitiateTransferReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).initiateTransfer(
        version,
        requestID,
        authorization,
        clientId,
        rboid,
        initiateTransferReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Зачисление принятого платежа
     * @param {string} paymentId
     * @param {ReceiveReq} [receiveReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    receive(
      paymentId: string,
      receiveReq?: ReceiveReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiveRes> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).receive(
        paymentId,
        receiveReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Переотправка sms.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {ResendOtpReq} [resendOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendOtp(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      resendOtpReq?: ResendOtpReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse> {
      const localVarAxiosArgs = TransferByPhoneApiAxiosParamCreator(configuration).resendOtp(
        version,
        requestID,
        authorization,
        clientId,
        rboid,
        resendOtpReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * TransferByPhoneApi - factory interface
 * @export
 */
export const TransferByPhoneApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Проверка возможности платежа
     * @param {string} operationId
     * @param {CheckReq} [checkReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(operationId: string, checkReq?: CheckReq, options?: any): AxiosPromise<CheckRes> {
      return TransferByPhoneApiFp(configuration).check(operationId, checkReq, options)(axios, basePath)
    },
    /**
     *
     * @summary Проверка возможности осуществления транзакции перевода денежных средств с указанного ПИ на выбранное направление перевода, получение размера взимаемой комиссии.
     * @param {'v1'} version
     * @param {CheckTransferReq} [checkTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransfer(version: 'v1', checkTransferReq?: CheckTransferReq, options?: any): AxiosPromise<CheckTransferRes> {
      return TransferByPhoneApiFp(configuration).checkTransfer(version, checkTransferReq, options)(axios, basePath)
    },
    /**
     *
     * @summary Подтверждение транзакции.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {ConfirmTransferReq} [confirmTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransfer(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      confirmTransferReq?: ConfirmTransferReq,
      options?: any
    ): AxiosPromise<ConfirmTransferRes> {
      return TransferByPhoneApiFp(configuration).confirmTransfer(
        version,
        requestID,
        authorization,
        clientId,
        rboid,
        confirmTransferReq,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Получение статуса операции
     * @param {GetStatusesReq} [getStatusesReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatuses(getStatusesReq?: GetStatusesReq, options?: any): AxiosPromise<GetStatusesRes> {
      return TransferByPhoneApiFp(configuration).getStatuses(getStatusesReq, options)(axios, basePath)
    },
    /**
     *
     * @summary Получение списка направлений переводов денежных средств. Осуществляются внутренние переводы в МТС Банк, переводы по СБП и переводы напрямую в Сбербанк.
     * @param {'v1'} version
     * @param {GetTransferListReq} [getTransferListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferList(
      version: 'v1',
      getTransferListReq?: GetTransferListReq,
      options?: any
    ): AxiosPromise<GetTransferListRes> {
      return TransferByPhoneApiFp(configuration).getTransferList(version, getTransferListReq, options)(axios, basePath)
    },
    /**
     *
     * @summary Получение статуса перевода по транзакции.
     * @param {'v1'} version
     * @param {GetTransferStatusReq} [getTransferStatusReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferStatus(
      version: 'v1',
      getTransferStatusReq?: GetTransferStatusReq,
      options?: any
    ): AxiosPromise<GetTransferStatusRes> {
      return TransferByPhoneApiFp(configuration).getTransferStatus(
        version,
        getTransferStatusReq,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'v1' | 'v2'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion(version: 'v1' | 'v2', options?: any): AxiosPromise<string> {
      return TransferByPhoneApiFp(configuration).getVersion(version, options)(axios, basePath)
    },
    /**
     *
     * @summary Запрос на инициацию перевода по ранее указанным параметрам.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {InitiateTransferReq} [initiateTransferReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initiateTransfer(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      initiateTransferReq?: InitiateTransferReq,
      options?: any
    ): AxiosPromise<BaseResponse> {
      return TransferByPhoneApiFp(configuration).initiateTransfer(
        version,
        requestID,
        authorization,
        clientId,
        rboid,
        initiateTransferReq,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Зачисление принятого платежа
     * @param {string} paymentId
     * @param {ReceiveReq} [receiveReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    receive(paymentId: string, receiveReq?: ReceiveReq, options?: any): AxiosPromise<ReceiveRes> {
      return TransferByPhoneApiFp(configuration).receive(paymentId, receiveReq, options)(axios, basePath)
    },
    /**
     *
     * @summary Переотправка sms.
     * @param {'v1'} version
     * @param {string} requestID
     * @param {string} authorization
     * @param {string} clientId
     * @param {string} [rboid]
     * @param {ResendOtpReq} [resendOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendOtp(
      version: 'v1',
      requestID: string,
      authorization: string,
      clientId: string,
      rboid?: string,
      resendOtpReq?: ResendOtpReq,
      options?: any
    ): AxiosPromise<BaseResponse> {
      return TransferByPhoneApiFp(configuration).resendOtp(
        version,
        requestID,
        authorization,
        clientId,
        rboid,
        resendOtpReq,
        options
      )(axios, basePath)
    },
  }
}

/**
 * TransferByPhoneApi - object-oriented interface
 * @export
 * @class TransferByPhoneApi
 * @extends {BaseAPI}
 */
export class TransferByPhoneApi extends BaseAPI {
  /**
   *
   * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Проверка возможности платежа
   * @param {string} operationId
   * @param {CheckReq} [checkReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public check(operationId: string, checkReq?: CheckReq, options?: any) {
    return TransferByPhoneApiFp(this.configuration).check(operationId, checkReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Проверка возможности осуществления транзакции перевода денежных средств с указанного ПИ на выбранное направление перевода, получение размера взимаемой комиссии.
   * @param {'v1'} version
   * @param {CheckTransferReq} [checkTransferReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public checkTransfer(version: 'v1', checkTransferReq?: CheckTransferReq, options?: any) {
    return TransferByPhoneApiFp(this.configuration).checkTransfer(
      version,
      checkTransferReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Подтверждение транзакции.
   * @param {'v1'} version
   * @param {string} requestID
   * @param {string} authorization
   * @param {string} clientId
   * @param {string} [rboid]
   * @param {ConfirmTransferReq} [confirmTransferReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public confirmTransfer(
    version: 'v1',
    requestID: string,
    authorization: string,
    clientId: string,
    rboid?: string,
    confirmTransferReq?: ConfirmTransferReq,
    options?: any
  ) {
    return TransferByPhoneApiFp(this.configuration).confirmTransfer(
      version,
      requestID,
      authorization,
      clientId,
      rboid,
      confirmTransferReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Получение статуса операции
   * @param {GetStatusesReq} [getStatusesReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public getStatuses(getStatusesReq?: GetStatusesReq, options?: any) {
    return TransferByPhoneApiFp(this.configuration).getStatuses(getStatusesReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получение списка направлений переводов денежных средств. Осуществляются внутренние переводы в МТС Банк, переводы по СБП и переводы напрямую в Сбербанк.
   * @param {'v1'} version
   * @param {GetTransferListReq} [getTransferListReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public getTransferList(version: 'v1', getTransferListReq?: GetTransferListReq, options?: any) {
    return TransferByPhoneApiFp(this.configuration).getTransferList(
      version,
      getTransferListReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получение статуса перевода по транзакции.
   * @param {'v1'} version
   * @param {GetTransferStatusReq} [getTransferStatusReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public getTransferStatus(version: 'v1', getTransferStatusReq?: GetTransferStatusReq, options?: any) {
    return TransferByPhoneApiFp(this.configuration).getTransferStatus(
      version,
      getTransferStatusReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'v1' | 'v2'} version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public getVersion(version: 'v1' | 'v2', options?: any) {
    return TransferByPhoneApiFp(this.configuration).getVersion(version, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Запрос на инициацию перевода по ранее указанным параметрам.
   * @param {'v1'} version
   * @param {string} requestID
   * @param {string} authorization
   * @param {string} clientId
   * @param {string} [rboid]
   * @param {InitiateTransferReq} [initiateTransferReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public initiateTransfer(
    version: 'v1',
    requestID: string,
    authorization: string,
    clientId: string,
    rboid?: string,
    initiateTransferReq?: InitiateTransferReq,
    options?: any
  ) {
    return TransferByPhoneApiFp(this.configuration).initiateTransfer(
      version,
      requestID,
      authorization,
      clientId,
      rboid,
      initiateTransferReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Процессинговый центр электронного кошелька (ПЦ ЭК). Зачисление принятого платежа
   * @param {string} paymentId
   * @param {ReceiveReq} [receiveReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public receive(paymentId: string, receiveReq?: ReceiveReq, options?: any) {
    return TransferByPhoneApiFp(this.configuration).receive(paymentId, receiveReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Переотправка sms.
   * @param {'v1'} version
   * @param {string} requestID
   * @param {string} authorization
   * @param {string} clientId
   * @param {string} [rboid]
   * @param {ResendOtpReq} [resendOtpReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferByPhoneApi
   */
  public resendOtp(
    version: 'v1',
    requestID: string,
    authorization: string,
    clientId: string,
    rboid?: string,
    resendOtpReq?: ResendOtpReq,
    options?: any
  ) {
    return TransferByPhoneApiFp(this.configuration).resendOtp(
      version,
      requestID,
      authorization,
      clientId,
      rboid,
      resendOtpReq,
      options
    )(this.axios, this.basePath)
  }
}
