export const currencyMapping = {
  156: 'CNY',
  188: 'CRC',
  356: 'INR',
  376: 'ILS',
  392: 'JPY',
  410: 'KRW',
  566: 'NGN',
  600: 'PYG',
  608: 'PHP',
  643: 'RUB',
  704: 'VND',
  764: 'THB',
  810: 'RUR',
  826: 'GBP',
  840: 'USD',
  978: 'EUR',
  980: 'UAH',
  985: 'PLN',
} as const

export const defaultCurrencyAlias = 'RUB'
