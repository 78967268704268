import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { BannerInfoResponse } from '@open-api/cms/bannerInfo'
import { bannerInfoApi } from '@api/cms/bannerInfo'
import { BannerInfoState } from '@reducers/mobilePayment/bannerInfo/type'

const NAMESPACE = 'bannerInfo'

const initialState: BannerInfoState = {
  inProgress: true,
  error: null,
  data: null,
}

const getBannerInfo = createAsyncThunk(`${NAMESPACE}`, async (placeId: string) => {
  const response: AxiosResponse<BannerInfoResponse> = await bannerInfoApi.getBannerInfo('v1', placeId)

  return response.data
})

const bannerInfo = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBannerInfo.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(getBannerInfo.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(getBannerInfo.fulfilled, (state, action) => {
      const [bannerInfo] = action.payload.data

      state.inProgress = false
      state.data = bannerInfo
    })
  },
})

export { getBannerInfo }

export const bannerInfoReducer = bannerInfo.reducer
