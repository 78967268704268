import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseInitialState } from '@root/fetches/baseInitialState'
import {
  SalaryCardPrintRequest,
  SalaryCardPrintResponse,
  SalaryCardScreenRequest,
  SalaryCardScreenResponse,
} from '@open-api/ump/dc-salary-card'
import { dcSalaryCardControllerApi, benefitsControllerApi } from '@api/ump/dc-salary-card'
import { isAxiosError } from '@root/types/reducers'
import { TInitialValues } from './types'
import { headerOptions } from './constants'

const NAMESPACE = 'salaryInforming'

const initialState: TInitialValues = {
  offerScreen: {
    ...baseInitialState,
  },
  salaryCardScreen: {
    ...baseInitialState,
  },
  salaryCardPrint: {
    ...baseInitialState,
  },
  benefitsAvailability: {
    ...baseInitialState,
  },
}

export const fetchGetOfferScreen = createAsyncThunk(`${NAMESPACE}/getOfferScreen`, async () => {
  const { data } = await dcSalaryCardControllerApi.offerScreen(headerOptions)

  return data
})

export const fetchSalaryCardScreen = createAsyncThunk<SalaryCardScreenResponse['promoScreen'], SalaryCardScreenRequest>(
  `${NAMESPACE}/salaryCardScreen`,
  async (reqData: SalaryCardScreenRequest, { rejectWithValue }) => {
    try {
      const { data } = await dcSalaryCardControllerApi.salaryCardScreen(reqData, headerOptions)

      return data.promoScreen
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

export const fetchSalaryCardPrint = createAsyncThunk<SalaryCardPrintResponse, SalaryCardPrintRequest>(
  `${NAMESPACE}/salaryCardPrint`,
  async (reqData: SalaryCardPrintRequest, { rejectWithValue }) => {
    try {
      const { data } = await dcSalaryCardControllerApi.salaryCardPrint(reqData, headerOptions)

      return data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

export const fetchGetBenefitsAvailability = createAsyncThunk(`${NAMESPACE}/getBenefitsAvailability`, async () => {
  const { data } = await benefitsControllerApi.availability(headerOptions)

  return data
})

const salaryInforming = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetOfferScreen.pending, (state) => {
      state.offerScreen.data = {}
      state.offerScreen.inProgress = true
      state.offerScreen.error = null
    })
    builder.addCase(fetchGetOfferScreen.rejected, (state, action) => {
      state.offerScreen.data = {}
      state.offerScreen.error = action.error
      state.offerScreen.inProgress = false
    })
    builder.addCase(fetchGetOfferScreen.fulfilled, (state, action) => {
      state.offerScreen.data = action.payload
      state.offerScreen.inProgress = false
      state.offerScreen.error = null
    })
    builder.addCase(fetchSalaryCardScreen.pending, (state) => {
      state.salaryCardScreen.data = {}
      state.salaryCardScreen.inProgress = true
      state.salaryCardScreen.error = null
    })
    builder.addCase(fetchSalaryCardScreen.rejected, (state, action) => {
      state.salaryCardScreen.data = {}
      state.salaryCardScreen.error = action.error
      state.salaryCardScreen.inProgress = false
    })
    builder.addCase(fetchSalaryCardScreen.fulfilled, (state, action) => {
      state.salaryCardScreen.data = action.payload
      state.salaryCardScreen.inProgress = false
      state.salaryCardScreen.error = null
    })
    builder.addCase(fetchSalaryCardPrint.pending, (state) => {
      state.salaryCardPrint.data = {}
      state.salaryCardPrint.inProgress = true
      state.salaryCardPrint.error = null
    })
    builder.addCase(fetchSalaryCardPrint.rejected, (state, action) => {
      state.salaryCardPrint.data = {}
      state.salaryCardPrint.error = action.error
      state.salaryCardPrint.inProgress = false
    })
    builder.addCase(fetchSalaryCardPrint.fulfilled, (state, action) => {
      state.salaryCardPrint.data = action.payload
      state.salaryCardPrint.inProgress = false
      state.salaryCardPrint.error = null
    })
    builder.addCase(fetchGetBenefitsAvailability.pending, (state) => {
      state.benefitsAvailability.data = {}
      state.benefitsAvailability.inProgress = true
      state.benefitsAvailability.error = null
    })
    builder.addCase(fetchGetBenefitsAvailability.rejected, (state, action) => {
      state.benefitsAvailability.data = {}
      state.benefitsAvailability.error = action.error
      state.benefitsAvailability.inProgress = false
    })
    builder.addCase(fetchGetBenefitsAvailability.fulfilled, (state, action) => {
      state.benefitsAvailability.data = action.payload
      state.benefitsAvailability.inProgress = false
      state.benefitsAvailability.error = null
    })
  },
})

export const salaryInformingReducer = salaryInforming.reducer
