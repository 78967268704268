import { useRouter } from 'next/router'
import { route } from '@utils/route/route'

export const useGoTo = () => {
  const router = useRouter()

  const getGoTo = (href: string, pathOptions?: Record<string, string>) => {
    if (href.startsWith('/spa')) {
      if (href === '/spa') {
        return () => {
          router.push(route.generatePath('/', pathOptions)).then()
        }
      }

      return () => {
        router.push(route.generatePath(href.slice(4), pathOptions)).then()
      }
    }
    if (!href.startsWith('/')) {
      return () => {
        router.push(route.generatePath(href, pathOptions)).then()
      }
    }

    return () => {
      document.location.href = href
    }
  }

  return { getGoTo } as const
}
