import axios, { AxiosResponse } from 'axios'
import { ADDRESS_AUTOCOMPLETE, ORGANIZATION_AUTOCOMPLETE } from '@root/constants/suggestions'
import { CountrySuggestionResponse, FindSuggestionsCountry } from 'src/types/api/suggestions'
import { suggestionsCountry } from '@root/__mocks__/suggestionsCountry'

type GetFullAddress = {
  registrationCity: string | undefined
  registrationDistrict: string | undefined
  registrationStreet: string
  registrationHouse: string
  registrationBuilding: string | undefined
  registrationBlock: string | undefined
  registrationFlat: string
}

export const getFullAddress = (data: GetFullAddress): Promise<AxiosResponse<{ suggestions: { value: string }[] }>> => {
  const {
    registrationCity: city,
    registrationDistrict: district,
    registrationStreet: street,
    registrationHouse: house,
    registrationBuilding: building,
    registrationBlock: block,
    registrationFlat: flat,
  } = data
  const settlement = city || district || ''
  const query = [settlement, street, house, building, block, flat].filter((item) => Boolean(item)).join(' ')

  return axios.post(ADDRESS_AUTOCOMPLETE, { query })
}

export const getAddressStructureByString = (addr: string): Promise<AxiosResponse> =>
  axios.post(ADDRESS_AUTOCOMPLETE, { query: addr }).then(({ data }) => data.suggestions.at(0)?.data)

export const getParty = async (name: string, count?: number) => {
  return await axios.post(ORGANIZATION_AUTOCOMPLETE, {
    count: count ?? 1,
    query: name,
    status: ['ACTIVE'],
  })
}

export const fetchCountry = async (query: string): Promise<AxiosResponse<CountrySuggestionResponse>> => {
  //todo временное ? проверить после https://jira.mtsbank.ru/browse/SITE-2707
  try {
    return await axios.post('/suggestions/api/4_1/rs/suggest/country', { query })
  } catch (error) {
    // Обработка ошибок
    console.error(error)
    throw error
  }
}

//todo временная ф-ия для поиска стран пока не перейдем ц целевому решению
export const getCountrySuggestions = (query: string): Promise<FindSuggestionsCountry> => {
  return new Promise((resolve) => {
    const sortedSuggestions = suggestionsCountry
      .filter((suggestion) => suggestion.value.toLowerCase().includes(query.toLowerCase()))
      .sort(
        (a, b) =>
          a.value.toLowerCase().indexOf(query.toLowerCase()) - b.value.toLowerCase().indexOf(query.toLowerCase())
      )
      .slice(0, 6)
    resolve({
      data: {
        suggestions: sortedSuggestions,
      },
    })
  })
}
