import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

import regionsReducer from '@reducers/regions/regions'
import smsInformerReducer from '@reducers/smsInformer/smsInformer'
import earlyRepaymentReducer from '@reducers/earlyRepayment/earlyRepayment'
import processingReducer from '@reducers/processing/processing'
import configReducer from '@reducers/config/config'
import authReducer from '@reducers/auth/auth'
import docReducer from '@reducers/docs/docList'
import creditHolidaysReducer from '@reducers/creditHolidays/creditHolidays'
import loanRestructReducer from '@reducers/loanRestruct/loanRestruct'
import { sendReceiptReducer } from '@reducers/mobilePayment/receipt/sendReceipt'
import { receiptDetailsReducer } from '@reducers/mobilePayment/receipt/receiptDetails'
import { operationDetailsReducer } from '@reducers/mobilePayment/operationDetails/operationDetails'
import { autoPaymentReducer } from '@reducers/mobilePayment/autopayment/autoPayment'
import { paymentServiceReducer } from '@reducers/mobilePayment/paymentServiceInfo/paymentServiceInfo'
import { mdOrderToRefNumberReducer } from '@reducers/mobilePayment/refNumber/refNumber'
import { bannerInfoReducer } from '@reducers/mobilePayment/bannerInfo/bannerInfo'
import { sbpReducer } from '@reducers/sbp/sbp'
import { recommendationReducer } from '@reducers/mobilePayment/recomendations/recomendations'
import { cardListReducer } from '@reducers/debitCards/cardList'
import { depositsReducer } from '@reducers/deposits/deposits'
import { paymentReducer } from '@reducers/payment/payment'
import { accountsReducer } from '@reducers/accounts/accounts'
import { fullApproveWidgetReducer } from '@reducers/fullApproveWidget/fullApproveWidget'
import { settingsReducer } from '@reducers/settings/settings'
import { multitransferReducer } from '@reducers/abroad/abroad'
import { loanListReducer, loanIssueListReducer, posLimitReducer } from '@reducers/credits'
import { recommendationsReducer } from '@reducers/recommendations/recommendations'
import { freeConnectionReducer } from '@reducers/freeConnection/freeConnection'
import { depositConstructorReducer } from '@reducers/depositOpening/depositConstructor'
import { depositAccountReducer } from '@reducers/depositOpening/depositAccount'
import { otpSenderReducer } from '@reducers/otpSender/otpSender'
import { salaryInformingReducer } from '@reducers/salaryInforming/salaryInforming'
import { incidentsReducer } from '@reducers/incidents/incidents'
import { onboardingReducer } from '@reducers/onboarding/onboarding'
import { premiumPackageReducer } from '@reducers/premiumPackage/premium'
import { otpPcekReducer } from '@reducers/otpPcek/otpPcek'
import { transferTermsReducer } from '@reducers/cardToCard/transferTerms/transferTerms'
import { transferReducer } from '@reducers/cardToCard/transfer/transfer'
import { operationDetailsReducer as operationDetailsC2CReducer } from '@reducers/cardToCard/operationDetails/operationDetails'
import { operationDetailsReducer as operationDetailsMe2MeReducer } from '@reducers/meToMe/operationDetails/operationDetails'
import { bankProductsReducer } from '@reducers/bankProducts/bankProducts'
import { dboLoanReducer } from '@reducers/dboLoan/dboLoan'
import { posOfferReducer } from '@reducers/posOffer/posOffer'
import { dboLoanListReducer } from './credits/dboLoanList'

const profileReducer = combineReducers({
  earlyRepayment: earlyRepaymentReducer,
  processing: processingReducer,
  holidays: creditHolidaysReducer,
  restruct: loanRestructReducer,
  cardList: cardListReducer,
  deposits: depositsReducer,
  payment: paymentReducer,
  accounts: accountsReducer,
  fullApproveWidget: fullApproveWidgetReducer,
  loanList: loanListReducer,
  dboLoan: dboLoanReducer,
  dboLoanList: dboLoanListReducer,
  posLimit: posLimitReducer,
  posOffer: posOfferReducer,
  loanIssueList: loanIssueListReducer,
  recommendations: recommendationsReducer,
  freeConnection: freeConnectionReducer,
  salaryInforming: salaryInformingReducer,
  onboardingSection: onboardingReducer,
  premium: premiumPackageReducer,
})

const mdOrderPersistConfig = {
  key: 'mdOrderToRefNumber',
  storage,
}

const sbpConfigPersist = {
  key: 'sbp',
  storage: storageSession,
}

const incidentsPersistConfig = {
  key: 'incidents',
  storage,
}

const mobilePaymentReducer = combineReducers({
  sendReceipt: sendReceiptReducer,
  receiptDetails: receiptDetailsReducer,
  operationDetails: operationDetailsReducer,
  autopayment: autoPaymentReducer,
  paymentService: paymentServiceReducer,
  mdOrderToRefNumber: persistReducer(mdOrderPersistConfig, mdOrderToRefNumberReducer),
  bannerInfo: bannerInfoReducer,
  recommendations: recommendationReducer,
})

const cardToCardReducer = combineReducers({
  transfer: transferReducer,
  transferTerms: transferTermsReducer,
  operationDetails: operationDetailsC2CReducer,
})

const meToMeReducer = combineReducers({
  operationDetails: operationDetailsMe2MeReducer,
})

const openDepositReducer = combineReducers({
  depositConstructor: depositConstructorReducer,
  depositAccount: depositAccountReducer,
})

const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  regions: regionsReducer,
  profile: profileReducer,
  smsInformer: smsInformerReducer,
  otpSender: otpSenderReducer,
  otpPcek: otpPcekReducer,
  doc: docReducer,
  mobilePayment: mobilePaymentReducer,
  settings: settingsReducer,
  sbp: persistReducer(sbpConfigPersist, sbpReducer),
  multitransfer: multitransferReducer,
  openDeposit: openDepositReducer, // todo нужно объединить deposits и openDeposit в deposits, разделив на сущности: список вкладов(list), открытие вклада(opening)
  incidents: persistReducer(incidentsPersistConfig, incidentsReducer),
  cardToCard: cardToCardReducer,
  meToMe: meToMeReducer,
  bankProducts: bankProductsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export { rootReducer }
