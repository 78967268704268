import { Dictionary } from '@reduxjs/toolkit'
import { actionGroups, eventActions, eventCategories, events } from '../baseFunctions'
import { translit } from '../translit'
import { SbpEventContents, SbpEventLabels } from './constants'
import { ConfigParamsByEventAndField, EventFieldNames, FormFieldNames } from './types'

export const configParamsByEvent: Record<EventFieldNames, (value?: string) => Dictionary<string>> = {
  [EventFieldNames.SHOW_ERROR_GTM]: (value?: string) => ({
    event: events.mtsEvent,
    eventCategory: eventCategories.perevody,
    eventAction: eventActions.errorShow,
    eventLabel: translit(value ?? '').toLowerCase(),
    actionGroup: actionGroups.interactions,
  }),
  [EventFieldNames.SELECT_GTM]: () => ({
    event: events.mtsEvent,
    eventCategory: eventCategories.perevody,
    eventAction: eventActions.elementClick,
    actionGroup: actionGroups.interactions,
  }),
  [EventFieldNames.SUCCESS_FILLED_GTM]: (value?: string) => ({
    event: events.mtsEvent,
    eventCategory: eventCategories.perevody,
    eventAction: eventActions.confirmed,
    actionGroup: actionGroups.interactions,
    eventValue: value ? translit(value.toString()).toLocaleLowerCase() : null,
    eventLabel: value ? translit(value.toString()).toLocaleLowerCase() : null,
  }),
  [EventFieldNames.CLEAR_GTM]: () => ({
    event: events.mtsEvent,
    eventCategorie: eventCategories.perevody,
    eventAction: eventActions.elementClick,
    actionGroup: actionGroups.interactions,
  }),
}

export const configParamsByEventAndField: ConfigParamsByEventAndField = {
  [EventFieldNames.SHOW_ERROR_GTM]: {
    [FormFieldNames.PAYMENT_SOURCE]: {
      eventContent: SbpEventContents.spisatS,
    },
    [FormFieldNames.AMOUNT]: {
      eventContent: SbpEventContents.summaPlatezha,
    },
    [FormFieldNames.USER_MESSAGE]: {
      eventContent: SbpEventContents.soobscheniePoluchatelu,
    },
    [FormFieldNames.RECIPIENT_BANK]: {
      eventContent: SbpEventContents.bankPoluchatelya,
    },
    [FormFieldNames.RECIPIENT_PHONE]: {
      eventContent: SbpEventContents.telefon,
    },
  },
  [EventFieldNames.SELECT_GTM]: {
    [FormFieldNames.PAYMENT_SOURCE]: {
      eventLabel: SbpEventLabels.spisatS,
    },
    [FormFieldNames.RECIPIENT_BANK]: {
      eventLabel: SbpEventLabels.bankPoluchatelya,
    },
  },
  [EventFieldNames.SUCCESS_FILLED_GTM]: {
    [FormFieldNames.AMOUNT]: {
      eventAction: eventActions.confirmed,
      eventLabel: SbpEventLabels.summaPlatezha,
    },
    [FormFieldNames.USER_MESSAGE]: {
      eventLabel: SbpEventLabels.soobschenie,
      eventValue: null,
    },
    [FormFieldNames.RECIPIENT_BANK]: {
      eventAction: eventActions.elementClick,
      eventValue: null,
    },
    [FormFieldNames.RECIPIENT_PHONE]: {
      eventLabel: SbpEventLabels.telefon,
    },
    [FormFieldNames.PAYMENT_SOURCE]: {
      eventAction: eventActions.elementClick,
      eventLabel: SbpEventLabels.vyborKarty,
    },
  },
  [EventFieldNames.CLEAR_GTM]: {
    [FormFieldNames.AMOUNT]: {
      eventLabel: SbpEventLabels.ochistitSummu,
    },
    [FormFieldNames.RECIPIENT_BANK]: {
      eventLabel: SbpEventLabels.ochistitBank,
    },
    [FormFieldNames.RECIPIENT_PHONE]: {
      eventLabel: SbpEventLabels.ochistitNomerTelefona,
    },
    [FormFieldNames.PAYMENT_SOURCE]: {
      eventLabel: SbpEventLabels.ochistit,
      eventContent: SbpEventContents.spisatS,
    },
    [FormFieldNames.USER_MESSAGE]: {
      eventLabel: SbpEventLabels.ochistitSoobschenie,
    },
  },
}
