import { FormConfig } from '@components/SBP/types'
import { BindingType, Currency } from '@root/types/bindings'
import { DEFAULT_BANK } from '@components/SBP/constants'

export const AMOUNT_MIN_VALUE = 1000

export const formConfig: FormConfig = {
  initialValues: {
    paymentSource: '',
    recipientPhone: '',
    recipientBank: DEFAULT_BANK,
    amount: '',
    userMessage: '',
  },
  fields: {
    paymentSource: {
      name: 'paymentSource',
      label: 'Списать с ',
      placeholder: 'Выберите счёт или карту',
      options: [],
      validators: ['requiredIsNil', { balance: { min: 10 } }],
      // hint: 'Вы выбрали для погашения кредитную карту. Комиссия за операцию будет взиматься в соответствии с {link href="http://mtsbank.ru"}тарифами{/link}',
      filter: (binding) => binding.bindingType === BindingType.GENERAL_CARD && binding.currency === Currency.RUB,
    },
    recipientPhone: {
      name: 'recipientPhone',
      label: 'Номер телефона',
      placeholder: '+7 000 000 00 00',
      validators: ['required', 'phone'],
      isClearable: true,
    },
    recipientBank: {
      name: 'recipientBank',
      label: 'Банк получателя',
      placeholder: 'Введите название банка',
      validators: ['required'],
    },
    userMessage: {
      name: 'userMessage',
      label: 'Сообщение получателю',
      placeholder: 'Введите сообщение',
      validators: [{ length: { max: '' } }],
      isClearable: true,
    },
    amount: {
      name: 'amount',
      label: 'Сумма перевода',
      decimalLimit: 2,
      placeholder: 'Введите сумму',
      allowDecimal: true,
      isClearable: true,
      alwaysReturnString: true,
      decimalSymbol: '.',
      validators: [
        'required',
        {
          amountValues: {
            min: AMOUNT_MIN_VALUE,
            max: null,
          },
        },
      ],
    },
  },
}
