// tslint:disable
/**
 * ЧДП/ПДП (dbo-sms-informer)
 * Автор: Березкова Елена Андреевна <br> Руководитель центра компетенций анализа и архитектуры фронтальной разработки <br>  Центр компетенций анализа и архитектуры фронтальной разработки
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface ConfirmOtpReq
 */
export interface ConfirmOtpReq {
  /**
   *
   * @type {number}
   * @memberof ConfirmOtpReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof ConfirmOtpReq
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmOtpReq
   */
  type?: string
}
/**
 *
 * @export
 * @interface ConfirmOtpRes
 */
export interface ConfirmOtpRes {
  /**
   *
   * @type {number}
   * @memberof ConfirmOtpRes
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof ConfirmOtpRes
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof ConfirmOtpRes
   */
  otpToken?: string
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {Error}
   * @memberof ErrorResponse
   */
  error?: Error
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  code?: number
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  externalCode?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  details?: string
}
/**
 *
 * @export
 * @interface SendOtpReq
 */
export interface SendOtpReq {
  /**
   *
   * @type {number}
   * @memberof SendOtpReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof SendOtpReq
   */
  type?: string
}
/**
 *
 * @export
 * @interface SendOtpRes
 */
export interface SendOtpRes {
  /**
   *
   * @type {number}
   * @memberof SendOtpRes
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof SendOtpRes
   */
  type?: string
}

/**
 * SmsInformerApi - axios parameter creator
 * @export
 */
export const SmsInformerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary валидация введённого кода
     * @param {string} version
     * @param {ConfirmOtpReq} [confirmOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmOtp(version: string, confirmOtpReq?: ConfirmOtpReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling confirmOtp.')
      }
      const localVarPath = `/dbo-sms-informer/{version}/confirmOtp`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof confirmOtpReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(confirmOtpReq !== undefined ? confirmOtpReq : {})
        : confirmOtpReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary генерация СМС
     * @param {string} version
     * @param {SendOtpReq} [sendOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendOtp(version: string, sendOtpReq?: SendOtpReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling sendOtp.')
      }
      const localVarPath = `/dbo-sms-informer/{version}/sendOtp`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof sendOtpReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendOtpReq !== undefined ? sendOtpReq : {})
        : sendOtpReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SmsInformerApi - functional programming interface
 * @export
 */
export const SmsInformerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary валидация введённого кода
     * @param {string} version
     * @param {ConfirmOtpReq} [confirmOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmOtp(
      version: string,
      confirmOtpReq?: ConfirmOtpReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmOtpRes> {
      const localVarAxiosArgs = SmsInformerApiAxiosParamCreator(configuration).confirmOtp(
        version,
        confirmOtpReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary генерация СМС
     * @param {string} version
     * @param {SendOtpReq} [sendOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendOtp(
      version: string,
      sendOtpReq?: SendOtpReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendOtpRes> {
      const localVarAxiosArgs = SmsInformerApiAxiosParamCreator(configuration).sendOtp(version, sendOtpReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SmsInformerApi - factory interface
 * @export
 */
export const SmsInformerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary валидация введённого кода
     * @param {string} version
     * @param {ConfirmOtpReq} [confirmOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmOtp(version: string, confirmOtpReq?: ConfirmOtpReq, options?: any): AxiosPromise<ConfirmOtpRes> {
      return SmsInformerApiFp(configuration).confirmOtp(version, confirmOtpReq, options)(axios, basePath)
    },
    /**
     *
     * @summary генерация СМС
     * @param {string} version
     * @param {SendOtpReq} [sendOtpReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendOtp(version: string, sendOtpReq?: SendOtpReq, options?: any): AxiosPromise<SendOtpRes> {
      return SmsInformerApiFp(configuration).sendOtp(version, sendOtpReq, options)(axios, basePath)
    },
  }
}

/**
 * SmsInformerApi - object-oriented interface
 * @export
 * @class SmsInformerApi
 * @extends {BaseAPI}
 */
export class SmsInformerApi extends BaseAPI {
  /**
   *
   * @summary валидация введённого кода
   * @param {string} version
   * @param {ConfirmOtpReq} [confirmOtpReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsInformerApi
   */
  public confirmOtp(version: string, confirmOtpReq?: ConfirmOtpReq, options?: any) {
    return SmsInformerApiFp(this.configuration).confirmOtp(version, confirmOtpReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary генерация СМС
   * @param {string} version
   * @param {SendOtpReq} [sendOtpReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsInformerApi
   */
  public sendOtp(version: string, sendOtpReq?: SendOtpReq, options?: any) {
    return SmsInformerApiFp(this.configuration).sendOtp(version, sendOtpReq, options)(this.axios, this.basePath)
  }
}
