// tslint:disable
/**
 * CMS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface BannerInfo
 */
export interface BannerInfo {
  /**
   *
   * @type {string}
   * @memberof BannerInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof BannerInfo
   */
  subtitle?: string
  /**
   *
   * @type {string}
   * @memberof BannerInfo
   */
  link?: string
  /**
   *
   * @type {string}
   * @memberof BannerInfo
   */
  image?: string
  /**
   *
   * @type {boolean}
   * @memberof BannerInfo
   */
  button?: boolean
  /**
   *
   * @type {string}
   * @memberof BannerInfo
   */
  button_text?: string
}
/**
 *
 * @export
 * @interface BannerInfoResponse
 */
export interface BannerInfoResponse {
  /**
   *
   * @type {Array<BannerInfo>}
   * @memberof BannerInfoResponse
   */
  data?: Array<BannerInfo>
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  message?: string
  /**
   *
   * @type {Array<ErrorResponseErrors>}
   * @memberof ErrorResponse
   */
  errors?: Array<ErrorResponseErrors>
}
/**
 *
 * @export
 * @interface ErrorResponseErrors
 */
export interface ErrorResponseErrors {
  /**
   *
   * @type {string}
   * @memberof ErrorResponseErrors
   */
  place_id?: string
}

/**
 * BannerApi - axios parameter creator
 * @export
 */
export const BannerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получение данных для баннера
     * @param {string} version
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBannerInfo(version: string, placeId: string, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getBannerInfo.'
        )
      }
      // verify required parameter 'placeId' is not null or undefined
      if (placeId === null || placeId === undefined) {
        throw new RequiredError(
          'placeId',
          'Required parameter placeId was null or undefined when calling getBannerInfo.'
        )
      }
      const localVarPath = `/api/{version}/banners`.replace(`{${'version'}}`, encodeURIComponent(String(version)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (placeId !== undefined) {
        localVarQueryParameter['place_id'] = placeId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BannerApi - functional programming interface
 * @export
 */
export const BannerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получение данных для баннера
     * @param {string} version
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBannerInfo(
      version: string,
      placeId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerInfoResponse> {
      const localVarAxiosArgs = BannerApiAxiosParamCreator(configuration).getBannerInfo(version, placeId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * BannerApi - factory interface
 * @export
 */
export const BannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Получение данных для баннера
     * @param {string} version
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBannerInfo(version: string, placeId: string, options?: any): AxiosPromise<BannerInfoResponse> {
      return BannerApiFp(configuration).getBannerInfo(version, placeId, options)(axios, basePath)
    },
  }
}

/**
 * BannerApi - object-oriented interface
 * @export
 * @class BannerApi
 * @extends {BaseAPI}
 */
export class BannerApi extends BaseAPI {
  /**
   *
   * @summary Получение данных для баннера
   * @param {string} version
   * @param {string} placeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BannerApi
   */
  public getBannerInfo(version: string, placeId: string, options?: any) {
    return BannerApiFp(this.configuration).getBannerInfo(version, placeId, options)(this.axios, this.basePath)
  }
}
