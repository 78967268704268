// tslint:disable
/**
 * GUI Service for Premium Client Page in Mobile Application
 * Owner: Premium Clients Stream
 *
 * The version of the OpenAPI document: undefined
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface CommissionDescItemDto
 */
export interface CommissionDescItemDto {
  /**
   *
   * @type {string}
   * @memberof CommissionDescItemDto
   */
  comTitle?: string
  /**
   *
   * @type {string}
   * @memberof CommissionDescItemDto
   */
  comTitleStyle?: string
  /**
   *
   * @type {string}
   * @memberof CommissionDescItemDto
   */
  comText?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CommissionDescItemDto
   */
  features?: Array<string>
}
/**
 *
 * @export
 * @interface CommissionsDto
 */
export interface CommissionsDto {
  /**
   *
   * @type {string}
   * @memberof CommissionsDto
   */
  commissionName?: string
  /**
   *
   * @type {string}
   * @memberof CommissionsDto
   */
  subName?: string
  /**
   *
   * @type {Array<CommissionDescItemDto>}
   * @memberof CommissionsDto
   */
  descCom?: Array<CommissionDescItemDto>
}
/**
 *
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  patronymicName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  surName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  mainMobilePhone?: string
}
/**
 *
 * @export
 * @interface MtsBankPremiumClientException
 */
export interface MtsBankPremiumClientException {
  /**
   *
   * @type {MtsBankPremiumClientExceptionCause}
   * @memberof MtsBankPremiumClientException
   */
  cause?: MtsBankPremiumClientExceptionCause
  /**
   *
   * @type {Array<MtsBankPremiumClientExceptionCauseStackTrace>}
   * @memberof MtsBankPremiumClientException
   */
  stackTrace?: Array<MtsBankPremiumClientExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientException
   */
  httpStatus?: MtsBankPremiumClientExceptionHttpStatusEnum
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientException
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientException
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientException
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientException
   */
  uuid?: string
  /**
   *
   * @type {boolean}
   * @memberof MtsBankPremiumClientException
   */
  needLog?: boolean
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientException
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankPremiumClientExceptionCauseSuppressed>}
   * @memberof MtsBankPremiumClientException
   */
  suppressed?: Array<MtsBankPremiumClientExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientException
   */
  localizedMessage?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MtsBankPremiumClientExceptionHttpStatusEnum {
  _100CONTINUE = '100 CONTINUE',
  _101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  _102PROCESSING = '102 PROCESSING',
  _103CHECKPOINT = '103 CHECKPOINT',
  _200OK = '200 OK',
  _201CREATED = '201 CREATED',
  _202ACCEPTED = '202 ACCEPTED',
  _203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  _204NOCONTENT = '204 NO_CONTENT',
  _205RESETCONTENT = '205 RESET_CONTENT',
  _206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  _207MULTISTATUS = '207 MULTI_STATUS',
  _208ALREADYREPORTED = '208 ALREADY_REPORTED',
  _226IMUSED = '226 IM_USED',
  _300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  _301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  _302FOUND = '302 FOUND',
  _302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  _303SEEOTHER = '303 SEE_OTHER',
  _304NOTMODIFIED = '304 NOT_MODIFIED',
  _305USEPROXY = '305 USE_PROXY',
  _307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  _308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  _400BADREQUEST = '400 BAD_REQUEST',
  _401UNAUTHORIZED = '401 UNAUTHORIZED',
  _402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  _403FORBIDDEN = '403 FORBIDDEN',
  _404NOTFOUND = '404 NOT_FOUND',
  _405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  _406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  _407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  _408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  _409CONFLICT = '409 CONFLICT',
  _410GONE = '410 GONE',
  _411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  _412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  _413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  _413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  _414URITOOLONG = '414 URI_TOO_LONG',
  _414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  _415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  _416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  _417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  _418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  _419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  _420METHODFAILURE = '420 METHOD_FAILURE',
  _421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  _422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  _423LOCKED = '423 LOCKED',
  _424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  _425TOOEARLY = '425 TOO_EARLY',
  _426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  _428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  _429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  _431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  _451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  _500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  _501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  _502BADGATEWAY = '502 BAD_GATEWAY',
  _503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  _504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  _505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  _506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  _507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  _508LOOPDETECTED = '508 LOOP_DETECTED',
  _509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  _510NOTEXTENDED = '510 NOT_EXTENDED',
  _511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

/**
 *
 * @export
 * @interface MtsBankPremiumClientExceptionCause
 */
export interface MtsBankPremiumClientExceptionCause {
  /**
   *
   * @type {Array<MtsBankPremiumClientExceptionCauseStackTrace>}
   * @memberof MtsBankPremiumClientExceptionCause
   */
  stackTrace?: Array<MtsBankPremiumClientExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCause
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankPremiumClientExceptionCauseSuppressed>}
   * @memberof MtsBankPremiumClientExceptionCause
   */
  suppressed?: Array<MtsBankPremiumClientExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCause
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface MtsBankPremiumClientExceptionCauseStackTrace
 */
export interface MtsBankPremiumClientExceptionCauseStackTrace {
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCauseStackTrace
   */
  classLoaderName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCauseStackTrace
   */
  moduleName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCauseStackTrace
   */
  moduleVersion?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCauseStackTrace
   */
  methodName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCauseStackTrace
   */
  fileName?: string
  /**
   *
   * @type {number}
   * @memberof MtsBankPremiumClientExceptionCauseStackTrace
   */
  lineNumber?: number
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCauseStackTrace
   */
  className?: string
  /**
   *
   * @type {boolean}
   * @memberof MtsBankPremiumClientExceptionCauseStackTrace
   */
  nativeMethod?: boolean
}
/**
 *
 * @export
 * @interface MtsBankPremiumClientExceptionCauseSuppressed
 */
export interface MtsBankPremiumClientExceptionCauseSuppressed {
  /**
   *
   * @type {Array<MtsBankPremiumClientExceptionCauseStackTrace>}
   * @memberof MtsBankPremiumClientExceptionCauseSuppressed
   */
  stackTrace?: Array<MtsBankPremiumClientExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCauseSuppressed
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankPremiumClientExceptionCauseSuppressed
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface PackageDto
 */
export interface PackageDto {
  /**
   *
   * @type {string}
   * @memberof PackageDto
   */
  typePack?: string
  /**
   *
   * @type {string}
   * @memberof PackageDto
   */
  namePack?: string
  /**
   *
   * @type {string}
   * @memberof PackageDto
   */
  levelInfo?: string
  /**
   *
   * @type {string}
   * @memberof PackageDto
   */
  descPack?: string
  /**
   *
   * @type {string}
   * @memberof PackageDto
   */
  shortPack?: string
}
/**
 *
 * @export
 * @interface PersonalManagerDto
 */
export interface PersonalManagerDto {
  /**
   *
   * @type {string}
   * @memberof PersonalManagerDto
   */
  nameManager?: string
  /**
   *
   * @type {string}
   * @memberof PersonalManagerDto
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof PersonalManagerDto
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof PersonalManagerDto
   */
  photo?: string
}
/**
 *
 * @export
 * @interface PremiumAvailabilityResponse
 */
export interface PremiumAvailabilityResponse {
  /**
   *
   * @type {boolean}
   * @memberof PremiumAvailabilityResponse
   */
  exist?: boolean
  /**
   *
   * @type {string}
   * @memberof PremiumAvailabilityResponse
   */
  vipServiceType?: string
  /**
   *
   * @type {string}
   * @memberof PremiumAvailabilityResponse
   */
  vipServiceName?: string
}
/**
 *
 * @export
 * @interface PremiumDetailResponse
 */
export interface PremiumDetailResponse {
  /**
   *
   * @type {CustomerDto}
   * @memberof PremiumDetailResponse
   */
  customer?: CustomerDto
  /**
   *
   * @type {PackageDto}
   * @memberof PremiumDetailResponse
   */
  activePackage?: PackageDto
  /**
   *
   * @type {PersonalManagerDto}
   * @memberof PremiumDetailResponse
   */
  personalManager?: PersonalManagerDto
  /**
   *
   * @type {Array<PremiumServiceInfoDto>}
   * @memberof PremiumDetailResponse
   */
  services?: Array<PremiumServiceInfoDto>
  /**
   *
   * @type {ServiceConditionDto}
   * @memberof PremiumDetailResponse
   */
  serviceCondition?: ServiceConditionDto
  /**
   *
   * @type {ServiceTaxDto}
   * @memberof PremiumDetailResponse
   */
  tax?: ServiceTaxDto
}
/**
 *
 * @export
 * @interface PremiumServiceDescriptionResponse
 */
export interface PremiumServiceDescriptionResponse {
  /**
   *
   * @type {string}
   * @memberof PremiumServiceDescriptionResponse
   */
  serviceName?: string
  /**
   *
   * @type {string}
   * @memberof PremiumServiceDescriptionResponse
   */
  backgroundImgURL?: string
  /**
   *
   * @type {WebImageBundleDto}
   * @memberof PremiumServiceDescriptionResponse
   */
  backgroundImgURLWeb?: WebImageBundleDto
  /**
   *
   * @type {ServiceActionDto}
   * @memberof PremiumServiceDescriptionResponse
   */
  action?: ServiceActionDto
  /**
   *
   * @type {string}
   * @memberof PremiumServiceDescriptionResponse
   */
  descFull?: string
}
/**
 *
 * @export
 * @interface PremiumServiceInfoDto
 */
export interface PremiumServiceInfoDto {
  /**
   *
   * @type {string}
   * @memberof PremiumServiceInfoDto
   */
  serviceName?: string
  /**
   *
   * @type {string}
   * @memberof PremiumServiceInfoDto
   */
  serviceId?: string
  /**
   *
   * @type {string}
   * @memberof PremiumServiceInfoDto
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof PremiumServiceInfoDto
   */
  subTitle?: string
  /**
   *
   * @type {string}
   * @memberof PremiumServiceInfoDto
   */
  serviceURL?: string
  /**
   *
   * @type {string}
   * @memberof PremiumServiceInfoDto
   */
  serviceTileImgURL?: string
  /**
   *
   * @type {string}
   * @memberof PremiumServiceInfoDto
   */
  serviceTileImgURLWeb?: string
}
/**
 *
 * @export
 * @interface ServiceActionDto
 */
export interface ServiceActionDto {
  /**
   *
   * @type {string}
   * @memberof ServiceActionDto
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof ServiceActionDto
   */
  actionLink?: string
}
/**
 *
 * @export
 * @interface ServiceConditionDto
 */
export interface ServiceConditionDto {
  /**
   *
   * @type {CommissionsDto}
   * @memberof ServiceConditionDto
   */
  commission?: CommissionsDto
}
/**
 *
 * @export
 * @interface ServiceTaxDto
 */
export interface ServiceTaxDto {
  /**
   *
   * @type {string}
   * @memberof ServiceTaxDto
   */
  nameTax?: string
  /**
   *
   * @type {string}
   * @memberof ServiceTaxDto
   */
  link?: string
}
/**
 *
 * @export
 * @interface WebImageBundleDto
 */
export interface WebImageBundleDto {
  /**
   *
   * @type {string}
   * @memberof WebImageBundleDto
   */
  imageMdUrl?: string
  /**
   *
   * @type {string}
   * @memberof WebImageBundleDto
   */
  imageSmUrl?: string
  /**
   *
   * @type {string}
   * @memberof WebImageBundleDto
   */
  imageMobUrl?: string
  /**
   *
   * @type {string}
   * @memberof WebImageBundleDto
   */
  altText?: string
}

/**
 * PremiumInfoControllerV8Api - axios parameter creator
 * @export
 */
export const PremiumInfoControllerV8ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает признак \"премиальности\" клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isPremium(options: any = {}): RequestArgs {
      const localVarPath = `/premium-clients-info/v8/info/premium/availability`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает данные для построения раздела \"Премиум\" в мобильном приложении
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    premiumDetail(options: any = {}): RequestArgs {
      const localVarPath = `/premium-clients-info/v8/info/premium/detail`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает данные для отображения описания одного нефинансового сервиса
     * @param {'business-hall' | 'taxi' | 'concierge' | 'insurance' | 'transfer' | 'cashback'} serviceId Идентификатор сервиса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    serviceDescription(
      serviceId: 'business-hall' | 'taxi' | 'concierge' | 'insurance' | 'transfer' | 'cashback',
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'serviceId' is not null or undefined
      if (serviceId === null || serviceId === undefined) {
        throw new RequiredError(
          'serviceId',
          'Required parameter serviceId was null or undefined when calling serviceDescription.'
        )
      }
      const localVarPath = `/premium-clients-info/v8/info/premium/services/{service-id}/desc`.replace(
        `{${'service-id'}}`,
        encodeURIComponent(String(serviceId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает разметку для экрана нефинансового сервиса
     * @param {string} serviceId
     * @param {number} v
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalDescription(serviceId: string, v: number, options: any = {}): RequestArgs {
      // verify required parameter 'serviceId' is not null or undefined
      if (serviceId === null || serviceId === undefined) {
        throw new RequiredError(
          'serviceId',
          'Required parameter serviceId was null or undefined when calling universalDescription.'
        )
      }
      // verify required parameter 'v' is not null or undefined
      if (v === null || v === undefined) {
        throw new RequiredError('v', 'Required parameter v was null or undefined when calling universalDescription.')
      }
      const localVarPath = `/premium-clients-info/v8/info/premium/universal`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (serviceId !== undefined) {
        localVarQueryParameter['serviceId'] = serviceId
      }

      if (v !== undefined) {
        localVarQueryParameter['v'] = v
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PremiumInfoControllerV8Api - functional programming interface
 * @export
 */
export const PremiumInfoControllerV8ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает признак \"премиальности\" клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isPremium(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PremiumAvailabilityResponse> {
      const localVarAxiosArgs = PremiumInfoControllerV8ApiAxiosParamCreator(configuration).isPremium(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает данные для построения раздела \"Премиум\" в мобильном приложении
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    premiumDetail(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PremiumDetailResponse> {
      const localVarAxiosArgs = PremiumInfoControllerV8ApiAxiosParamCreator(configuration).premiumDetail(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает данные для отображения описания одного нефинансового сервиса
     * @param {'business-hall' | 'taxi' | 'concierge' | 'insurance' | 'transfer' | 'cashback'} serviceId Идентификатор сервиса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    serviceDescription(
      serviceId: 'business-hall' | 'taxi' | 'concierge' | 'insurance' | 'transfer' | 'cashback',
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PremiumServiceDescriptionResponse> {
      const localVarAxiosArgs = PremiumInfoControllerV8ApiAxiosParamCreator(configuration).serviceDescription(
        serviceId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает разметку для экрана нефинансового сервиса
     * @param {string} serviceId
     * @param {number} v
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalDescription(
      serviceId: string,
      v: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = PremiumInfoControllerV8ApiAxiosParamCreator(configuration).universalDescription(
        serviceId,
        v,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PremiumInfoControllerV8Api - factory interface
 * @export
 */
export const PremiumInfoControllerV8ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает признак \"премиальности\" клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isPremium(options?: any): AxiosPromise<PremiumAvailabilityResponse> {
      return PremiumInfoControllerV8ApiFp(configuration).isPremium(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает данные для построения раздела \"Премиум\" в мобильном приложении
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    premiumDetail(options?: any): AxiosPromise<PremiumDetailResponse> {
      return PremiumInfoControllerV8ApiFp(configuration).premiumDetail(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает данные для отображения описания одного нефинансового сервиса
     * @param {'business-hall' | 'taxi' | 'concierge' | 'insurance' | 'transfer' | 'cashback'} serviceId Идентификатор сервиса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    serviceDescription(
      serviceId: 'business-hall' | 'taxi' | 'concierge' | 'insurance' | 'transfer' | 'cashback',
      options?: any
    ): AxiosPromise<PremiumServiceDescriptionResponse> {
      return PremiumInfoControllerV8ApiFp(configuration).serviceDescription(serviceId, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает разметку для экрана нефинансового сервиса
     * @param {string} serviceId
     * @param {number} v
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalDescription(serviceId: string, v: number, options?: any): AxiosPromise<string> {
      return PremiumInfoControllerV8ApiFp(configuration).universalDescription(serviceId, v, options)(axios, basePath)
    },
  }
}

/**
 * PremiumInfoControllerV8Api - object-oriented interface
 * @export
 * @class PremiumInfoControllerV8Api
 * @extends {BaseAPI}
 */
export class PremiumInfoControllerV8Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает признак \"премиальности\" клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PremiumInfoControllerV8Api
   */
  public isPremium(options?: any) {
    return PremiumInfoControllerV8ApiFp(this.configuration).isPremium(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает данные для построения раздела \"Премиум\" в мобильном приложении
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PremiumInfoControllerV8Api
   */
  public premiumDetail(options?: any) {
    return PremiumInfoControllerV8ApiFp(this.configuration).premiumDetail(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает данные для отображения описания одного нефинансового сервиса
   * @param {'business-hall' | 'taxi' | 'concierge' | 'insurance' | 'transfer' | 'cashback'} serviceId Идентификатор сервиса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PremiumInfoControllerV8Api
   */
  public serviceDescription(
    serviceId: 'business-hall' | 'taxi' | 'concierge' | 'insurance' | 'transfer' | 'cashback',
    options?: any
  ) {
    return PremiumInfoControllerV8ApiFp(this.configuration).serviceDescription(serviceId, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Метод возвращает разметку для экрана нефинансового сервиса
   * @param {string} serviceId
   * @param {number} v
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PremiumInfoControllerV8Api
   */
  public universalDescription(serviceId: string, v: number, options?: any) {
    return PremiumInfoControllerV8ApiFp(this.configuration).universalDescription(
      serviceId,
      v,
      options
    )(this.axios, this.basePath)
  }
}
