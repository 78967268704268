import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { baseInitialState } from '@reducers/baseInitialState'
import { CountrySuggestionsParams, DepositAccountState } from '@reducers/depositOpening/types'

import { getCountrySuggestions } from '@api/suggestions'
import { CountryState, OpenDepositParams } from '@components/DepositConstructor/types'
import { openDepositApi } from '@api/ump/invest-deposits-deposit-v1'

const NAMESPACE = 'deposit-account'

const initialState: DepositAccountState = {
  suggestionsCountryBirth: { ...baseInitialState },
  suggestionsCountryResident: { ...baseInitialState },
  openDeposit: { ...baseInitialState, inProgress: false },
}

const fetchCountrySuggestions = createAsyncThunk(
  `${NAMESPACE}/fetchCountry`,
  async (params: CountrySuggestionsParams) => {
    const response = await getCountrySuggestions(params.query)

    return response.data.suggestions
  }
)

const fetchOpenDeposit = createAsyncThunk(`${NAMESPACE}/fetchOpenDeposit`, async (params: OpenDepositParams) => {
  const response = await openDepositApi.openDeposit(params)

  return response.data
})

const depositAccount = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetDepositAccountState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountrySuggestions.pending, (state, action) => {
      state.suggestionsCountryBirth.inProgress = action.meta?.arg?.countryState === CountryState.BIRTH
      state.suggestionsCountryResident.inProgress = action.meta?.arg?.countryState === CountryState.RESIDENT
    })
    builder.addCase(fetchCountrySuggestions.rejected, (state, action) => {
      const isBirthState = action.meta?.arg?.countryState === CountryState.BIRTH
      const isResidentState = action.meta?.arg?.countryState === CountryState.RESIDENT

      state.suggestionsCountryBirth.error = isBirthState ? action.payload : state.suggestionsCountryBirth.error
      state.suggestionsCountryBirth.inProgress = isBirthState ? false : state.suggestionsCountryResident.inProgress

      state.suggestionsCountryResident.error = isResidentState ? action.payload : state.suggestionsCountryResident.error
      state.suggestionsCountryResident.inProgress = isResidentState
        ? false
        : state.suggestionsCountryResident.inProgress
    })
    builder.addCase(fetchCountrySuggestions.fulfilled, (state, { payload, meta }) => {
      const isBirthState = meta?.arg?.countryState === CountryState.BIRTH
      const isResidentState = meta?.arg?.countryState === CountryState.RESIDENT

      state.suggestionsCountryBirth.data = isBirthState ? payload : state.suggestionsCountryBirth.data
      state.suggestionsCountryBirth.inProgress = isBirthState ? false : state.suggestionsCountryResident.inProgress

      state.suggestionsCountryResident.data = isResidentState ? payload : state.suggestionsCountryResident.data
      state.suggestionsCountryResident.inProgress = isResidentState
        ? false
        : state.suggestionsCountryResident.inProgress
    })

    builder.addCase(fetchOpenDeposit.pending, (state) => {
      state.openDeposit.inProgress = true
    })
    builder.addCase(fetchOpenDeposit.rejected, (state, action) => {
      state.openDeposit.error = action.error
      state.openDeposit.inProgress = false
    })
    builder.addCase(fetchOpenDeposit.fulfilled, (state, { payload }) => {
      state.openDeposit.inProgress = false
      state.openDeposit.data = payload
    })
  },
})

export const { resetDepositAccountState } = depositAccount.actions

export { fetchCountrySuggestions, fetchOpenDeposit }

export const depositAccountReducer = depositAccount.reducer
