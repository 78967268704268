/**
 * Загрузка данных об инцидентах { incidents: IncidentItem[] }
 **/

import axios from 'axios'
import { IncidentsResponse } from '@root/types/api/incident'

/**
 *  для данного метода contentType обязателен несмотря что это Get, но для get axios его удаляет и получаем 400
 openApi то-же ругается на data для get запросов,
 { data: null } - хак чтоб axios не удалял
 */

type GetIncidents = () => Promise<IncidentsResponse>

export const getIncidents: GetIncidents = async () => {
  try {
    const response = await axios.get<IncidentsResponse>(
      `${process.env.NEXT_PUBLIC_UMP_HOST}/pl-incident-alert/v1/incidents`,
      {
        data: null,
      }
    )

    return JSON.parse(JSON.stringify(response.data))
  } catch (error) {
    throw new Error(error)
  }
}
