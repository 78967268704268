import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { setRefNumber } from '@root/reducers/abroad/refNumber/refNumber'
import { paymentApi, processingApi } from '@api/dot-net/api'
import { multiTransferApi } from '@api/ump/multitransfer'
import { OperationDetails, OperationResponse } from '@generated/api'
import { baseInitialState } from '@reducers/baseInitialState'
import {
  AdditionalFieldsRequest,
  CommissionRequest,
  ErrorResponse,
  OperationDetailsQuery,
  RequestFinish3DSQuery,
} from '@reducers/abroad/types'

interface Headers {
  ref_number?: string
}

const NAMESPACE = 'multitransfer'
const NAMESPACE_FINISH3DS = `${NAMESPACE}/FINISH3DS`

const initialState = {
  countries: { ...baseInitialState, inProgress: false },
  currency: { ...baseInitialState, inProgress: false },
  client: { ...baseInitialState, inProgress: false },
  additionalFields: { ...baseInitialState, inProgress: false },
  commissionTransfer: { ...baseInitialState, inProgress: false },
  createTransfer: { ...baseInitialState, inProgress: false },
  saveClient: { ...baseInitialState, inProgress: false },
  finish3ds: { ...baseInitialState, inProgress: false },
  operationDetails: { ...baseInitialState, inProgress: false },
}

const fetchCountries = createAsyncThunk(`${NAMESPACE}/getCountry`, async (params, { rejectWithValue }) => {
  try {
    const response = await multiTransferApi.getCountriesList('v2')

    return response.data
  } catch (err) {
    const errRes: ErrorResponse = err.response.data

    return rejectWithValue(errRes.error || { message: err.message })
  }
})

const fetchCurrencyCountries = createAsyncThunk(`${NAMESPACE}/getCurrency`, async (params, { rejectWithValue }) => {
  try {
    const response = await multiTransferApi.getCurrencyCountries('v2')

    return response.data
  } catch (err) {
    const errRes: ErrorResponse = err.response.data

    return rejectWithValue(errRes.error || { message: err.message })
  }
})

const fetchClient = createAsyncThunk(`${NAMESPACE}/getClientData`, async (params, { rejectWithValue }) => {
  try {
    const response = await multiTransferApi.getClientData('v2')

    return response.data
  } catch (err) {
    const errRes: ErrorResponse = err.response.data

    return rejectWithValue(errRes.error || { message: err.message })
  }
})

const fetchSaveClient = createAsyncThunk(`${NAMESPACE}/saveClientData`, async (clientReq: any, { rejectWithValue }) => {
  try {
    const response = await multiTransferApi.getSaveClientData(clientReq)

    return response.data
  } catch (err) {
    const errRes: ErrorResponse = err.response.data

    return rejectWithValue(errRes.error || { message: err.message })
  }
})

const fetchAdditionalFields = createAsyncThunk(
  `${NAMESPACE}/getAdditionalFields`,
  async (fieldsRequest: AdditionalFieldsRequest, { rejectWithValue }) => {
    try {
      const response = await multiTransferApi.getAdditionalFields(fieldsRequest)

      return response.data
    } catch (err) {
      const errRes: ErrorResponse = err.response.data

      return rejectWithValue(errRes.error || { message: err.message })
    }
  }
)

const fetchCommissionTransfer = createAsyncThunk(
  `${NAMESPACE}/getCommissionTransfer`,
  async (commissionReq: CommissionRequest, { rejectWithValue }) => {
    try {
      const response = await multiTransferApi.getCommissionTransfer(commissionReq)

      return response.data
    } catch (err) {
      const errRes: ErrorResponse = err.response.data

      return rejectWithValue(errRes.error || { message: err.message })
    }
  }
)

const fetchCreateTransfer = createAsyncThunk(
  `${NAMESPACE}/createTransfer`,
  async (clientReq: any, { rejectWithValue }) => {
    try {
      const response = await multiTransferApi.getTransferCreate(clientReq)

      return response.data
    } catch (err) {
      const errRes: ErrorResponse = err.response.data

      return rejectWithValue(errRes.error || { message: err.message })
    }
  }
)

const fetchOperationDetails = createAsyncThunk(
  `${NAMESPACE}/getOperationDetails`,
  async ({ mdOrder, refNum }: OperationDetailsQuery): Promise<OperationDetails> => {
    const headers: Headers = {}

    if (typeof refNum !== 'undefined') {
      headers.ref_number = refNum
    }

    const response = await processingApi.apiProcessingGetOperationDetailsGet(mdOrder, refNum || '')

    return response.data
  }
)

const requestFinish3ds = createAsyncThunk(
  `${NAMESPACE_FINISH3DS}`,
  async ({ mdOrder, paRes, cRes }: RequestFinish3DSQuery, thunkAPI): Promise<OperationResponse> => {
    try {
      const response = paRes
        ? await paymentApi.apiPaymentFinish3dsPost({ mdOrder, xRes: paRes })
        : await paymentApi.apiPaymentFinish3ds2Post({ mdOrder, xRes: cRes })

      thunkAPI.dispatch(
        setRefNumber({
          mdOrder: response.data.mdOrder,
          refNum: response.data.refNumber,
        })
      )

      return response.data
    } catch (error) {
      if (error.response && error.response.mdOrder) {
        thunkAPI.dispatch(
          setRefNumber({
            mdOrder: error.response.mdOrder,
            refNum: error.response.refNumber,
          })
        )
      }
      thunkAPI.rejectWithValue(error.response.data)

      return error.response.data
    }
  }
)

const multitransfer = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state) => {
      state.countries.inProgress = true
    })
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.countries.error = action.payload
      state.countries.inProgress = false
    })
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries.inProgress = false
      state.countries.data = action.payload
      state.countries.error = null
    })

    builder.addCase(fetchCurrencyCountries.pending, (state) => {
      state.currency.inProgress = true
    })
    builder.addCase(fetchCurrencyCountries.rejected, (state, action) => {
      state.currency.error = action.payload
      state.currency.inProgress = false
    })
    builder.addCase(fetchCurrencyCountries.fulfilled, (state, action) => {
      state.currency.inProgress = false
      state.currency.data = action.payload
      state.currency.error = null
    })

    builder.addCase(fetchClient.pending, (state) => {
      state.client.inProgress = true
    })
    builder.addCase(fetchClient.rejected, (state, action) => {
      state.client.error = action.payload
      state.client.inProgress = false
    })
    builder.addCase(fetchClient.fulfilled, (state, action) => {
      state.client.inProgress = false
      state.client.data = action.payload
      state.client.error = null
    })

    builder.addCase(fetchAdditionalFields.pending, (state) => {
      state.additionalFields.inProgress = true
    })
    builder.addCase(fetchAdditionalFields.rejected, (state, action) => {
      state.additionalFields.error = action.payload
      state.additionalFields.inProgress = false
    })
    builder.addCase(fetchAdditionalFields.fulfilled, (state, action) => {
      state.additionalFields.inProgress = false
      state.additionalFields.data = action.payload
      state.additionalFields.error = null
    })

    builder.addCase(fetchCommissionTransfer.pending, (state) => {
      state.commissionTransfer.inProgress = true
    })
    builder.addCase(fetchCommissionTransfer.rejected, (state, action) => {
      state.commissionTransfer.error = action.payload
      state.commissionTransfer.inProgress = false
    })
    builder.addCase(fetchCommissionTransfer.fulfilled, (state, action) => {
      state.commissionTransfer.inProgress = false
      state.commissionTransfer.data = action.payload
      state.commissionTransfer.error = null
    })

    builder.addCase(fetchCreateTransfer.pending, (state) => {
      state.createTransfer.inProgress = true
    })
    builder.addCase(fetchCreateTransfer.rejected, (state, action) => {
      state.createTransfer.error = action.payload
      state.createTransfer.inProgress = false
    })
    builder.addCase(fetchCreateTransfer.fulfilled, (state, action) => {
      state.createTransfer.inProgress = false
      state.createTransfer.data = action.payload
      state.createTransfer.error = null
    })

    builder.addCase(fetchSaveClient.pending, (state) => {
      state.saveClient.inProgress = true
    })
    builder.addCase(fetchSaveClient.rejected, (state, action) => {
      state.saveClient.error = action.payload
      state.saveClient.inProgress = false
    })
    builder.addCase(fetchSaveClient.fulfilled, (state, action) => {
      state.saveClient.inProgress = false
      state.saveClient.data = action.payload
      state.saveClient.error = null
    })

    builder.addCase(requestFinish3ds.pending, (state) => {
      state.finish3ds.inProgress = true
      state.finish3ds.error = null
    })
    builder.addCase(requestFinish3ds.rejected, (state, action) => {
      state.finish3ds.inProgress = false
      state.finish3ds.error = action.error
      state.finish3ds.data = action.payload
    })
    builder.addCase(requestFinish3ds.fulfilled, (state, action) => {
      state.finish3ds.inProgress = false
      state.finish3ds.data = { ...action.payload }
    })

    builder.addCase(fetchOperationDetails.pending, (state) => {
      state.operationDetails.inProgress = true
      state.operationDetails.error = null
    })
    builder.addCase(fetchOperationDetails.rejected, (state, action) => {
      state.operationDetails.inProgress = false
      state.operationDetails.error = action.error
    })
    builder.addCase(fetchOperationDetails.fulfilled, (state, action) => {
      state.operationDetails.inProgress = false
      state.operationDetails.data = { ...action.payload }
    })
  },
})

export {
  fetchCountries,
  fetchCurrencyCountries,
  fetchClient,
  fetchAdditionalFields,
  fetchCommissionTransfer,
  fetchCreateTransfer,
  fetchSaveClient,
  requestFinish3ds,
  fetchOperationDetails,
}
export const multitransferReducer = multitransfer.reducer
