export const plural = (n: number, wordForms: string[]) => {
  const [a, b, c] = wordForms

  if (n % 10 === 1 && n % 100 !== 11) {
    return a
  }

  if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    return b
  }

  return c
}
