export type NiceType = 'Maestro' | 'Mastercard' | 'Mir' | 'UnionPay' | 'Visa'
type CreditCardTypeSecurityCodeLabel = 'CVV' | 'CVC' | 'CID' | 'CVN' | 'CVE' | 'CVP2'

export interface CardCollection {
  [propName: string]: CreditCardType
}

export enum CardType {
  MIR = 'mir',
  MAESTRO = 'maestro',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  UNIONPAY = 'unionpay',
}

export interface BuiltInCreditCardType extends CreditCardType {
  niceType: NiceType
  type: CardType
  code: {
    size: 3 | 4
    name: CreditCardTypeSecurityCodeLabel
  }
}

export type CreditCardType = {
  niceType: string
  type: string
  patterns: number[] | [number[]]
  gaps: number[]
  lengths: number[]
  code: {
    size: number
    name: string
  }
  matchStrength?: number
}
