import React, { FC } from 'react'
import { Icon, theme } from '@mtsbank/ui-kit'
import { Binding } from '@open-api/ump/ewallet-profile'
import { BindingType, CardType } from '@root/types/bindings'

export const BindingIcon: FC<{ binding: Binding }> = ({ binding }) => {
  if (binding.cardType) {
    const cardIconMapping = {
      [CardType.UNKNOWN]: 'icon-24/Card',
      [CardType.VISA]: 'payment/visa',
      [CardType.MIR]: 'payment/mir',
      [CardType.MASTERCARD]: 'payment/masterCard',
      [CardType.MAESTRO]: 'payment/maestro',
      [CardType.UNIONPAY]: 'payment/unionPay',
      [CardType.VIRTUAL]: 'icon-24/Card',
    }

    return <Icon withWrapper icon={cardIconMapping[binding.cardType] || cardIconMapping[CardType.UNKNOWN]} />
  }

  if (binding.bindingType === BindingType.BANK_ACCOUNT) {
    const accountIcon = '/content/i/icon-transfer.svg'

    return <Icon withWrapper isCustomIcon icon={accountIcon} width={theme.spacings.sm} />
  }

  return null
}
