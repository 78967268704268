import { timeFormatDefaultLocale, timeFormatLocale, TimeLocaleDefinition, TimeLocaleObject } from 'd3-time-format'
import { Dictionary } from '@reduxjs/toolkit'
import { DateFormatOptionConfig, LocaleNames } from '@utils/formatDate/types'

const defaultLocale: TimeLocaleDefinition = {
  days: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
  shortDays: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  months: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ],
  shortMonths: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
  date: '%a %b %e %X %Y',
  dateTime: '%m/%d/%Y',
  periods: ['AM', 'PM'],
  time: '%H:%M:%S',
}

export const LOCALE_LIST: Dictionary<TimeLocaleObject> = {
  [LocaleNames.DEFAULT]: timeFormatDefaultLocale(defaultLocale),
  [LocaleNames.GENITIVE]: timeFormatLocale({
    ...defaultLocale,
    months: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
  }),
}

export const optionConfig: DateFormatOptionConfig = {
  'DD.MM.YYYY': '%d.%m.%Y',
  'YYYY-MM-DD hh:mm:ss': '%Y-%m-%d %H:%M:%S',
  'DD Month YYYY': {
    format: '%d %B %Y г.',
    locale: LocaleNames.GENITIVE,
  },
  'DD Month YYYY H:M': {
    format: '%d %B %Y %H:%M',
    locale: LocaleNames.GENITIVE,
  },
  'Month YYYY': {
    format: '%B %Y г.',
  },
  'DD Month': {
    format: '%d %B',
    locale: LocaleNames.GENITIVE,
  },
  'MM/YY': '%m/%y',
  'DD/MM/YYYY HH:MM:SS': {
    format: '%d/%m/%Y %H:%M:%S',
    locale: LocaleNames.GENITIVE,
  },
  'DD.MM.YYYY HH:MM': {
    format: '%d.%m.%Y %H:%M',
    locale: LocaleNames.GENITIVE,
  },
  'DD.MM': {
    format: '%d.%m.',
    locale: LocaleNames.GENITIVE,
  },
  YYYYMM: {
    format: '%Y%m',
    locale: LocaleNames.GENITIVE,
  },
  'YYYY-MM-DD': {
    format: '%Y-%m-%d',
    locale: LocaleNames.GENITIVE,
  },
}
