import {
  ScheduleControllerV2Api as ScheduleControllerApi,
  OrderControllerV2Api as OrderControllerApi,
} from '@open-api/ump/early-repayment'

export const scheduleControllerApi = new ScheduleControllerApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const orderControllerApi = new OrderControllerApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})
