export enum PromiseStatus {
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

// todo дополнять по необходимости
export enum UnicodeSymbols {
  SPACE = '\u00a0',
}

export enum HtmlEntities {
  SPACE = '&nbsp;',
}
