import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GatewayProtoErrorRsp, GatewayProtoGetShortDepositListRsp } from '@open-api/ump/debit-cards'
import { debitCardsApi } from '@api/ump/debit-cards'

import { DepositsState } from '@reducers/deposits/types'
import { Params } from '@root/types/reducers'
import { baseInitialState } from '@reducers/baseInitialState'

const NAMESPACE = 'deposits'

const initialState: DepositsState = {
  ...baseInitialState,
  isResent: false,
}

const fetchDepositList = createAsyncThunk<GatewayProtoGetShortDepositListRsp, Params>(
  `${NAMESPACE}/fetchDepositList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await debitCardsApi.getShortDepositList('v1')

      return response.data
    } catch (err) {
      const errRes: GatewayProtoErrorRsp = err.response.data

      return rejectWithValue(errRes || { message: err.message })
    }
  }
)

const deposits = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDepositList.pending, (state, action) => {
      if (action.meta?.arg?.isResent) {
        state.isResent = true

        return
      }

      state.inProgress = true
    })
    builder.addCase(fetchDepositList.rejected, (state, action) => {
      if (action.meta?.arg?.isResent) {
        state.isResent = false

        return
      }

      state.error = action.payload
      state.inProgress = false
    })
    builder.addCase(fetchDepositList.fulfilled, (state, action) => {
      if (action.meta?.arg?.isResent) {
        state.isResent = false

        return
      }

      state.inProgress = false
      state.data = action.payload
    })
  },
})

export { fetchDepositList }
export const depositsReducer = deposits.reducer
