import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions'
import aftInterceptor from '@utils/axios/aftInterceptor'

const axiosWithAftInstance = axios.create({
  adapter: process.env.PRIVATE_UMP_HOST ? cacheAdapterEnhancer(axios.defaults.adapter) : axios.defaults.adapter,
})

axiosWithAftInstance.interceptors.request.use(aftInterceptor)

export { axiosWithAftInstance }
