import thunk, { ThunkDispatch } from 'redux-thunk'
import { Action, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { useDispatch } from 'react-redux'
import { rootReducer } from '../reducers'

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk.withExtraArgument({})],
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>()
