// todo не удалять пока не перейдем к целевому решению!!!
export const suggestionsCountry = [
  {
    value: 'Абхазия',
    unrestricted_value: 'Республика Абхазия',
    data: { code: '895', alfa2: 'AB', alfa3: 'ABH', name_short: 'Абхазия', name: 'Республика Абхазия' },
  },
  {
    value: 'Австралия',
    unrestricted_value: null,
    data: { code: '036', alfa2: 'AU', alfa3: 'AUS', name_short: 'Австралия', name: null },
  },
  {
    value: 'Австрия',
    unrestricted_value: 'Австрийская Республика',
    data: { code: '040', alfa2: 'AT', alfa3: 'AUT', name_short: 'Австрия', name: 'Австрийская Республика' },
  },
  {
    value: 'Азербайджан',
    unrestricted_value: 'Республика Азербайджан',
    data: { code: '031', alfa2: 'AZ', alfa3: 'AZE', name_short: 'Азербайджан', name: 'Республика Азербайджан' },
  },
  {
    value: 'Албания',
    unrestricted_value: 'Республика Албания',
    data: { code: '008', alfa2: 'AL', alfa3: 'ALB', name_short: 'Албания', name: 'Республика Албания' },
  },
  {
    value: 'Алжир',
    unrestricted_value: 'Алжирская Народная Демократическая Республика',
    data: {
      code: '012',
      alfa2: 'DZ',
      alfa3: 'DZA',
      name_short: 'Алжир',
      name: 'Алжирская Народная Демократическая Республика',
    },
  },
  {
    value: 'Американское Самоа',
    unrestricted_value: null,
    data: { code: '016', alfa2: 'AS', alfa3: 'ASM', name_short: 'Американское Самоа', name: null },
  },
  {
    value: 'Ангилья',
    unrestricted_value: null,
    data: { code: '660', alfa2: 'AI', alfa3: 'AIA', name_short: 'Ангилья', name: null },
  },
  {
    value: 'Ангола',
    unrestricted_value: 'Республика Ангола',
    data: { code: '024', alfa2: 'AO', alfa3: 'AGO', name_short: 'Ангола', name: 'Республика Ангола' },
  },
  {
    value: 'Андорра',
    unrestricted_value: 'Княжество Андорра',
    data: { code: '020', alfa2: 'AD', alfa3: 'AND', name_short: 'Андорра', name: 'Княжество Андорра' },
  },
  {
    value: 'Антигуа и Барбуда',
    unrestricted_value: null,
    data: { code: '028', alfa2: 'AG', alfa3: 'ATG', name_short: 'Антигуа и Барбуда', name: null },
  },
  {
    value: 'Аргентина',
    unrestricted_value: 'Аргентинская Республика',
    data: { code: '032', alfa2: 'AR', alfa3: 'ARG', name_short: 'Аргентина', name: 'Аргентинская Республика' },
  },
  {
    value: 'Армения',
    unrestricted_value: 'Республика Армения',
    data: { code: '051', alfa2: 'AM', alfa3: 'ARM', name_short: 'Армения', name: 'Республика Армения' },
  },
  {
    value: 'Афганистан',
    unrestricted_value: 'Переходное Исламское Государство Афганистан',
    data: {
      code: '004',
      alfa2: 'AF',
      alfa3: 'AFG',
      name_short: 'Афганистан',
      name: 'Переходное Исламское Государство Афганистан',
    },
  },
  {
    value: 'Багамы',
    unrestricted_value: 'Содружество Багамы',
    data: { code: '044', alfa2: 'BS', alfa3: 'BHS', name_short: 'Багамы', name: 'Содружество Багамы' },
  },
  {
    value: 'Бангладеш',
    unrestricted_value: 'Народная Республика Бангладеш',
    data: { code: '050', alfa2: 'BD', alfa3: 'BGD', name_short: 'Бангладеш', name: 'Народная Республика Бангладеш' },
  },
  {
    value: 'Барбадос',
    unrestricted_value: null,
    data: { code: '052', alfa2: 'BB', alfa3: 'BRB', name_short: 'Барбадос', name: null },
  },
  {
    value: 'Бахрейн',
    unrestricted_value: 'Королевство Бахрейн',
    data: { code: '048', alfa2: 'BH', alfa3: 'BHR', name_short: 'Бахрейн', name: 'Королевство Бахрейн' },
  },
  {
    value: 'Беларусь',
    unrestricted_value: 'Республика Беларусь',
    data: { code: '112', alfa2: 'BY', alfa3: 'BLR', name_short: 'Беларусь', name: 'Республика Беларусь' },
  },
  {
    value: 'Белиз',
    unrestricted_value: null,
    data: { code: '084', alfa2: 'BZ', alfa3: 'BLZ', name_short: 'Белиз', name: null },
  },
  {
    value: 'Бельгия',
    unrestricted_value: 'Королевство Бельгии',
    data: { code: '056', alfa2: 'BE', alfa3: 'BEL', name_short: 'Бельгия', name: 'Королевство Бельгии' },
  },
  {
    value: 'Бенин',
    unrestricted_value: 'Республика Бенин',
    data: { code: '204', alfa2: 'BJ', alfa3: 'BEN', name_short: 'Бенин', name: 'Республика Бенин' },
  },
  {
    value: 'Бермуды',
    unrestricted_value: 'Бермудские острова',
    data: { code: '060', alfa2: 'BM', alfa3: 'BMU', name_short: 'Бермуды', name: 'Бермудские острова' },
  },
  {
    value: 'Боливия',
    unrestricted_value: 'Многонациональное Государство Боливия',
    data: {
      code: '068',
      alfa2: 'BO',
      alfa3: 'BOL',
      name_short: 'Боливия',
      name: 'Многонациональное Государство Боливия',
    },
  },
  {
    value: 'Бонэйр, Синт-Эстатиус и Саба',
    unrestricted_value: null,
    data: { code: '535', alfa2: 'BQ', alfa3: 'BES', name_short: 'Бонэйр, Синт-Эстатиус и Саба', name: null },
  },
  {
    value: 'Босния и Герцеговина',
    unrestricted_value: null,
    data: { code: '070', alfa2: 'BA', alfa3: 'BIH', name_short: 'Босния и Герцеговина', name: null },
  },
  {
    value: 'Бразилия',
    unrestricted_value: 'Федеративная Республика Бразилия',
    data: { code: '076', alfa2: 'BR', alfa3: 'BRA', name_short: 'Бразилия', name: 'Федеративная Республика Бразилия' },
  },
  {
    value: 'Британская территория в Индийском океане',
    unrestricted_value: null,
    data: {
      code: '086',
      alfa2: 'IO',
      alfa3: 'IOT',
      name_short: 'Британская территория в Индийском океане',
      name: null,
    },
  },
  {
    value: 'Бруней-Даруссалам',
    unrestricted_value: null,
    data: { code: '096', alfa2: 'BN', alfa3: 'BRN', name_short: 'Бруней-Даруссалам', name: null },
  },
  {
    value: 'Буркина-Фасо',
    unrestricted_value: null,
    data: { code: '854', alfa2: 'BF', alfa3: 'BFA', name_short: 'Буркина-Фасо', name: null },
  },
  {
    value: 'Бутан',
    unrestricted_value: 'Королевство Бутан',
    data: { code: '064', alfa2: 'BT', alfa3: 'BTN', name_short: 'Бутан', name: 'Королевство Бутан' },
  },
  {
    value: 'Вануату',
    unrestricted_value: 'Республика Вануату',
    data: { code: '548', alfa2: 'VU', alfa3: 'VUT', name_short: 'Вануату', name: 'Республика Вануату' },
  },
  {
    value: 'Ватикан',
    unrestricted_value: 'Папский престол (государство-город Ватикан)',
    data: {
      code: '336',
      alfa2: 'VA',
      alfa3: 'VAT',
      name_short: 'Ватикан',
      name: 'Папский престол (государство-город Ватикан)',
    },
  },
  {
    value: 'Венгрия',
    unrestricted_value: 'Венгрия',
    data: { code: '348', alfa2: 'HU', alfa3: 'HUN', name_short: 'Венгрия', name: 'Венгрия' },
  },
  {
    value: 'Венесуэла',
    unrestricted_value: 'Боливарианская Республика Венесуэла',
    data: {
      code: '862',
      alfa2: 'VE',
      alfa3: 'VEN',
      name_short: 'Венесуэла',
      name: 'Боливарианская Республика Венесуэла',
    },
  },
  {
    value: 'Виргинские острова (британские)',
    unrestricted_value: 'Британские Виргинские острова',
    data: {
      code: '092',
      alfa2: 'VG',
      alfa3: 'VGB',
      name_short: 'Виргинские острова (британские)',
      name: 'Британские Виргинские острова',
    },
  },
  {
    value: 'Виргинские острова (США)',
    unrestricted_value: 'Виргинские острова Соединенных Штатов',
    data: {
      code: '850',
      alfa2: 'VI',
      alfa3: 'VIR',
      name_short: 'Виргинские острова (США)',
      name: 'Виргинские острова Соединенных Штатов',
    },
  },
  {
    value: 'Внешние малые острова США',
    unrestricted_value: 'Малые тихоокеанские отдаленные острова Соединенных Штатов',
    data: {
      code: '581',
      alfa2: 'UM',
      alfa3: 'UMI',
      name_short: 'Внешние малые острова США',
      name: 'Малые тихоокеанские отдаленные острова Соединенных Штатов',
    },
  },
  {
    value: 'Вьетнам',
    unrestricted_value: 'Социалистическая Республика Вьетнам',
    data: {
      code: '704',
      alfa2: 'VN',
      alfa3: 'VNM',
      name_short: 'Вьетнам',
      name: 'Социалистическая Республика Вьетнам',
    },
  },
  {
    value: 'Габон',
    unrestricted_value: 'Габонская Республика',
    data: { code: '266', alfa2: 'GA', alfa3: 'GAB', name_short: 'Габон', name: 'Габонская Республика' },
  },
  {
    value: 'Гаити',
    unrestricted_value: 'Республика Гаити',
    data: { code: '332', alfa2: 'HT', alfa3: 'HTI', name_short: 'Гаити', name: 'Республика Гаити' },
  },
  {
    value: 'Гайана',
    unrestricted_value: 'Республика Гайана',
    data: { code: '328', alfa2: 'GY', alfa3: 'GUY', name_short: 'Гайана', name: 'Республика Гайана' },
  },
  {
    value: 'Гамбия',
    unrestricted_value: 'Исламская Республика Гамбия',
    data: { code: '270', alfa2: 'GM', alfa3: 'GMB', name_short: 'Гамбия', name: 'Исламская Республика Гамбия' },
  },
  {
    value: 'Гана',
    unrestricted_value: 'Республика Гана',
    data: { code: '288', alfa2: 'GH', alfa3: 'GHA', name_short: 'Гана', name: 'Республика Гана' },
  },
  {
    value: 'Гваделупа',
    unrestricted_value: null,
    data: { code: '312', alfa2: 'GP', alfa3: 'GLP', name_short: 'Гваделупа', name: null },
  },
  {
    value: 'Германия',
    unrestricted_value: 'Федеративная Республика Германия',
    data: { code: '276', alfa2: 'DE', alfa3: 'DEU', name_short: 'Германия', name: 'Федеративная Республика Германия' },
  },
  {
    value: 'Гонконг',
    unrestricted_value: 'Специальный административный регион Китая Гонконг',
    data: {
      code: '344',
      alfa2: 'HK',
      alfa3: 'HKG',
      name_short: 'Гонконг',
      name: 'Специальный административный регион Китая Гонконг',
    },
  },
  {
    value: 'Гренада',
    unrestricted_value: null,
    data: { code: '308', alfa2: 'GD', alfa3: 'GRD', name_short: 'Гренада', name: null },
  },
  {
    value: 'Гренландия',
    unrestricted_value: null,
    data: { code: '304', alfa2: 'GL', alfa3: 'GRL', name_short: 'Гренландия', name: null },
  },
  {
    value: 'Греция',
    unrestricted_value: 'Греческая Республика',
    data: { code: '300', alfa2: 'GR', alfa3: 'GRC', name_short: 'Греция', name: 'Греческая Республика' },
  },
  {
    value: 'Грузия',
    unrestricted_value: null,
    data: { code: '268', alfa2: 'GE', alfa3: 'GEO', name_short: 'Грузия', name: null },
  },
  {
    value: 'Дания',
    unrestricted_value: 'Королевство Дания',
    data: { code: '208', alfa2: 'DK', alfa3: 'DNK', name_short: 'Дания', name: 'Королевство Дания' },
  },
  {
    value: 'Джерси',
    unrestricted_value: null,
    data: { code: '832', alfa2: 'JE', alfa3: 'JEY', name_short: 'Джерси', name: null },
  },
  {
    value: 'Джибути',
    unrestricted_value: 'Республика Джибути',
    data: { code: '262', alfa2: 'DJ', alfa3: 'DJI', name_short: 'Джибути', name: 'Республика Джибути' },
  },
  {
    value: 'Доминика',
    unrestricted_value: 'Содружество Доминики',
    data: { code: '212', alfa2: 'DM', alfa3: 'DMA', name_short: 'Доминика', name: 'Содружество Доминики' },
  },
  {
    value: 'Доминиканская Республика',
    unrestricted_value: null,
    data: { code: '214', alfa2: 'DO', alfa3: 'DOM', name_short: 'Доминиканская Республика', name: null },
  },
  {
    value: 'Египет',
    unrestricted_value: 'Арабская Республика Египет',
    data: { code: '818', alfa2: 'EG', alfa3: 'EGY', name_short: 'Египет', name: 'Арабская Республика Египет' },
  },
  {
    value: 'Замбия',
    unrestricted_value: 'Республика Замбия',
    data: { code: '894', alfa2: 'ZM', alfa3: 'ZMB', name_short: 'Замбия', name: 'Республика Замбия' },
  },
  {
    value: 'Западная Сахара',
    unrestricted_value: null,
    data: { code: '732', alfa2: 'EH', alfa3: 'ESH', name_short: 'Западная Сахара', name: null },
  },
  {
    value: 'Зимбабве',
    unrestricted_value: 'Республика Зимбабве',
    data: { code: '716', alfa2: 'ZW', alfa3: 'ZWE', name_short: 'Зимбабве', name: 'Республика Зимбабве' },
  },
  {
    value: 'Израиль',
    unrestricted_value: 'Государство Израиль',
    data: { code: '376', alfa2: 'IL', alfa3: 'ISR', name_short: 'Израиль', name: 'Государство Израиль' },
  },
  {
    value: 'Индия',
    unrestricted_value: 'Республика Индия',
    data: { code: '356', alfa2: 'IN', alfa3: 'IND', name_short: 'Индия', name: 'Республика Индия' },
  },
  {
    value: 'Индонезия',
    unrestricted_value: 'Республика Индонезия',
    data: { code: '360', alfa2: 'ID', alfa3: 'IDN', name_short: 'Индонезия', name: 'Республика Индонезия' },
  },
  {
    value: 'Иордания',
    unrestricted_value: 'Иорданское Хашимитское Королевство',
    data: {
      code: '400',
      alfa2: 'JO',
      alfa3: 'JOR',
      name_short: 'Иордания',
      name: 'Иорданское Хашимитское Королевство',
    },
  },
  {
    value: 'Иран',
    unrestricted_value: 'Исламская Республика Иран',
    data: { code: '364', alfa2: 'IR', alfa3: 'IRN', name_short: 'Иран', name: 'Исламская Республика Иран' },
  },
  {
    value: 'Исландия',
    unrestricted_value: 'Республика Исландия',
    data: { code: '352', alfa2: 'IS', alfa3: 'ISL', name_short: 'Исландия', name: 'Республика Исландия' },
  },
  {
    value: 'Испания',
    unrestricted_value: 'Королевство Испания',
    data: { code: '724', alfa2: 'ES', alfa3: 'ESP', name_short: 'Испания', name: 'Королевство Испания' },
  },
  {
    value: 'Йемен',
    unrestricted_value: 'Йеменская Республика',
    data: { code: '887', alfa2: 'YE', alfa3: 'YEM', name_short: 'Йемен', name: 'Йеменская Республика' },
  },
  {
    value: 'Кабо-Верде',
    unrestricted_value: 'Республика Кабо-Верде',
    data: { code: '132', alfa2: 'CV', alfa3: 'CPV', name_short: 'Кабо-Верде', name: 'Республика Кабо-Верде' },
  },
  {
    value: 'Казахстан',
    unrestricted_value: 'Республика Казахстан',
    data: { code: '398', alfa2: 'KZ', alfa3: 'KAZ', name_short: 'Казахстан', name: 'Республика Казахстан' },
  },
  {
    value: 'Китай',
    unrestricted_value: 'Китайская Народная Республика',
    data: { code: '156', alfa2: 'CN', alfa3: 'CHN', name_short: 'Китай', name: 'Китайская Народная Республика' },
  },
  {
    value: 'КНДР',
    unrestricted_value: 'Корейская Народно-Демократическая Республика',
    data: {
      code: '408',
      alfa2: 'KP',
      alfa3: 'PRK',
      name_short: 'КНДР',
      name: 'Корейская Народно-Демократическая Республика',
    },
  },
  {
    value: 'Кокосовые острова',
    unrestricted_value: 'Кокосовые (Килинг) острова',
    data: {
      code: '166',
      alfa2: 'CC',
      alfa3: 'CCK',
      name_short: 'Кокосовые острова',
      name: 'Кокосовые (Килинг) острова',
    },
  },
  {
    value: 'Коморы',
    unrestricted_value: 'Союз Коморы',
    data: { code: '174', alfa2: 'KM', alfa3: 'COM', name_short: 'Коморы', name: 'Союз Коморы' },
  },
  {
    value: 'Конго (Демократическая Республика)',
    unrestricted_value: 'Демократическая Республика Конго',
    data: {
      code: '180',
      alfa2: 'CD',
      alfa3: 'COD',
      name_short: 'Конго (Демократическая Республика)',
      name: 'Демократическая Республика Конго',
    },
  },
  {
    value: 'Кот Д`Ивуар',
    unrestricted_value: 'Республика Кот д`Ивуар',
    data: { code: '384', alfa2: 'CI', alfa3: 'CIV', name_short: 'Кот Д`Ивуар', name: 'Республика Кот д`Ивуар' },
  },
  {
    value: 'Лаос',
    unrestricted_value: 'Лаосская Народно-Демократическая Республика',
    data: {
      code: '418',
      alfa2: 'LA',
      alfa3: 'LAO',
      name_short: 'Лаос',
      name: 'Лаосская Народно-Демократическая Республика',
    },
  },
  {
    value: 'Латвия',
    unrestricted_value: 'Латвийская Республика',
    data: { code: '428', alfa2: 'LV', alfa3: 'LVA', name_short: 'Латвия', name: 'Латвийская Республика' },
  },
  {
    value: 'Лесото',
    unrestricted_value: 'Королевство Лесото',
    data: { code: '426', alfa2: 'LS', alfa3: 'LSO', name_short: 'Лесото', name: 'Королевство Лесото' },
  },
  {
    value: 'Либерия',
    unrestricted_value: 'Республика Либерия',
    data: { code: '430', alfa2: 'LR', alfa3: 'LBR', name_short: 'Либерия', name: 'Республика Либерия' },
  },
  {
    value: 'Ливан',
    unrestricted_value: 'Ливанская Республика',
    data: { code: '422', alfa2: 'LB', alfa3: 'LBN', name_short: 'Ливан', name: 'Ливанская Республика' },
  },
  {
    value: 'Ливия',
    unrestricted_value: 'Ливия',
    data: { code: '434', alfa2: 'LY', alfa3: 'LBY', name_short: 'Ливия', name: 'Ливия' },
  },
  {
    value: 'Литва',
    unrestricted_value: 'Литовская Республика',
    data: { code: '440', alfa2: 'LT', alfa3: 'LTU', name_short: 'Литва', name: 'Литовская Республика' },
  },
  {
    value: 'Лихтенштейн',
    unrestricted_value: 'Княжество Лихтенштейн',
    data: { code: '438', alfa2: 'LI', alfa3: 'LIE', name_short: 'Лихтенштейн', name: 'Княжество Лихтенштейн' },
  },
  {
    value: 'Люксембург',
    unrestricted_value: 'Великое Герцогство Люксембург',
    data: { code: '442', alfa2: 'LU', alfa3: 'LUX', name_short: 'Люксембург', name: 'Великое Герцогство Люксембург' },
  },
  {
    value: 'Маврикий',
    unrestricted_value: 'Республика Маврикий',
    data: { code: '480', alfa2: 'MU', alfa3: 'MUS', name_short: 'Маврикий', name: 'Республика Маврикий' },
  },
  {
    value: 'Мавритания',
    unrestricted_value: 'Исламская Республика Мавритания',
    data: { code: '478', alfa2: 'MR', alfa3: 'MRT', name_short: 'Мавритания', name: 'Исламская Республика Мавритания' },
  },
  {
    value: 'Мадагаскар',
    unrestricted_value: 'Республика Мадагаскар',
    data: { code: '450', alfa2: 'MG', alfa3: 'MDG', name_short: 'Мадагаскар', name: 'Республика Мадагаскар' },
  },
  {
    value: 'Майотта',
    unrestricted_value: null,
    data: { code: '175', alfa2: 'YT', alfa3: 'MYT', name_short: 'Майотта', name: null },
  },
  {
    value: 'Макао',
    unrestricted_value: 'Специальный административный регион Китая Макао',
    data: {
      code: '446',
      alfa2: 'MO',
      alfa3: 'MAC',
      name_short: 'Макао',
      name: 'Специальный административный регион Китая Макао',
    },
  },
  {
    value: 'Македония',
    unrestricted_value: 'Республика Македония',
    data: { code: '807', alfa2: 'MK', alfa3: 'MKD', name_short: 'Македония', name: 'Республика Македония' },
  },
  {
    value: 'Малави',
    unrestricted_value: 'Республика Малави',
    data: { code: '454', alfa2: 'MW', alfa3: 'MWI', name_short: 'Малави', name: 'Республика Малави' },
  },
  {
    value: 'Малайзия',
    unrestricted_value: null,
    data: { code: '458', alfa2: 'MY', alfa3: 'MYS', name_short: 'Малайзия', name: null },
  },
  {
    value: 'Маршалловы острова',
    unrestricted_value: 'Республика Маршалловы Острова',
    data: {
      code: '584',
      alfa2: 'MH',
      alfa3: 'MHL',
      name_short: 'Маршалловы острова',
      name: 'Республика Маршалловы Острова',
    },
  },
  {
    value: 'Мексика',
    unrestricted_value: 'Мексиканские Соединенные Штаты',
    data: { code: '484', alfa2: 'MX', alfa3: 'MEX', name_short: 'Мексика', name: 'Мексиканские Соединенные Штаты' },
  },
  {
    value: 'Микронезия',
    unrestricted_value: 'Федеративные Штаты Микронезии',
    data: { code: '583', alfa2: 'FM', alfa3: 'FSM', name_short: 'Микронезия', name: 'Федеративные Штаты Микронезии' },
  },
  {
    value: 'Мозамбик',
    unrestricted_value: 'Республика Мозамбик',
    data: { code: '508', alfa2: 'MZ', alfa3: 'MOZ', name_short: 'Мозамбик', name: 'Республика Мозамбик' },
  },
  {
    value: 'Молдова',
    unrestricted_value: 'Республика Молдова',
    data: { code: '498', alfa2: 'MD', alfa3: 'MDA', name_short: 'Молдова', name: 'Республика Молдова' },
  },
  {
    value: 'Монако',
    unrestricted_value: 'Княжество Монако',
    data: { code: '492', alfa2: 'MC', alfa3: 'MCO', name_short: 'Монако', name: 'Княжество Монако' },
  },
  {
    value: 'Монголия',
    unrestricted_value: null,
    data: { code: '496', alfa2: 'MN', alfa3: 'MNG', name_short: 'Монголия', name: null },
  },
  {
    value: 'Монтсеррат',
    unrestricted_value: null,
    data: { code: '500', alfa2: 'MS', alfa3: 'MSR', name_short: 'Монтсеррат', name: null },
  },
  {
    value: 'Намибия',
    unrestricted_value: 'Республика Намибия',
    data: { code: '516', alfa2: 'NA', alfa3: 'NAM', name_short: 'Намибия', name: 'Республика Намибия' },
  },
  {
    value: 'Науру',
    unrestricted_value: 'Республика Науру',
    data: { code: '520', alfa2: 'NR', alfa3: 'NRU', name_short: 'Науру', name: 'Республика Науру' },
  },
  {
    value: 'Непал',
    unrestricted_value: 'Федеративная Демократическая Республика Непал',
    data: {
      code: '524',
      alfa2: 'NP',
      alfa3: 'NPL',
      name_short: 'Непал',
      name: 'Федеративная Демократическая Республика Непал',
    },
  },
  {
    value: 'Нигер',
    unrestricted_value: 'Республика Нигер',
    data: { code: '562', alfa2: 'NE', alfa3: 'NER', name_short: 'Нигер', name: 'Республика Нигер' },
  },
  {
    value: 'Нигерия',
    unrestricted_value: 'Федеративная Республика Нигерия',
    data: { code: '566', alfa2: 'NG', alfa3: 'NGA', name_short: 'Нигерия', name: 'Федеративная Республика Нигерия' },
  },
  {
    value: 'Новая Зеландия',
    unrestricted_value: null,
    data: { code: '554', alfa2: 'NZ', alfa3: 'NZL', name_short: 'Новая Зеландия', name: null },
  },
  {
    value: 'Объединенные Арабские Эмираты',
    unrestricted_value: 'Объединенные Арабские Эмираты',
    data: {
      code: '784',
      alfa2: 'AE',
      alfa3: 'ARE',
      name_short: 'Объединенные Арабские Эмираты',
      name: 'Объединенные Арабские Эмираты',
    },
  },
  {
    value: 'Оман',
    unrestricted_value: 'Султанат Оман',
    data: { code: '512', alfa2: 'OM', alfa3: 'OMN', name_short: 'Оман', name: 'Султанат Оман' },
  },
  {
    value: 'Остров Буве',
    unrestricted_value: null,
    data: { code: '074', alfa2: 'BV', alfa3: 'BVT', name_short: 'Остров Буве', name: null },
  },
  {
    value: 'Остров Херд и острова Макдональд',
    unrestricted_value: null,
    data: { code: '334', alfa2: 'HM', alfa3: 'HMD', name_short: 'Остров Херд и острова Макдональд', name: null },
  },
  {
    value: 'Острова Теркс И Кайкос',
    unrestricted_value: null,
    data: { code: '796', alfa2: 'TC', alfa3: 'TCA', name_short: 'Острова Теркс И Кайкос', name: null },
  },
  {
    value: 'Пакистан',
    unrestricted_value: 'Исламская Республика Пакистан',
    data: { code: '586', alfa2: 'PK', alfa3: 'PAK', name_short: 'Пакистан', name: 'Исламская Республика Пакистан' },
  },
  {
    value: 'Палау',
    unrestricted_value: 'Республика Палау',
    data: { code: '585', alfa2: 'PW', alfa3: 'PLW', name_short: 'Палау', name: 'Республика Палау' },
  },
  {
    value: 'Палестина',
    unrestricted_value: 'Государство Палестина',
    data: { code: '275', alfa2: 'PS', alfa3: 'PSE', name_short: 'Палестина', name: 'Государство Палестина' },
  },
  {
    value: 'Панама',
    unrestricted_value: 'Республика Панама',
    data: { code: '591', alfa2: 'PA', alfa3: 'PAN', name_short: 'Панама', name: 'Республика Панама' },
  },
  {
    value: 'Папуа-Новая Гвинея',
    unrestricted_value: 'Независимое Государство Папуа Новая Гвинея',
    data: {
      code: '598',
      alfa2: 'PG',
      alfa3: 'PNG',
      name_short: 'Папуа-Новая Гвинея',
      name: 'Независимое Государство Папуа Новая Гвинея',
    },
  },
  {
    value: 'Парагвай',
    unrestricted_value: 'Республика Парагвай',
    data: { code: '600', alfa2: 'PY', alfa3: 'PRY', name_short: 'Парагвай', name: 'Республика Парагвай' },
  },
  {
    value: 'Перу',
    unrestricted_value: 'Республика Перу',
    data: { code: '604', alfa2: 'PE', alfa3: 'PER', name_short: 'Перу', name: 'Республика Перу' },
  },
  {
    value: 'Питкерн',
    unrestricted_value: null,
    data: { code: '612', alfa2: 'PN', alfa3: 'PCN', name_short: 'Питкерн', name: null },
  },
  {
    value: 'Россия',
    unrestricted_value: 'Российская Федерация',
    data: { code: '643', alfa2: 'RU', alfa3: 'RUS', name_short: 'Россия', name: 'Российская Федерация' },
  },
  {
    value: 'Руанда',
    unrestricted_value: 'Руандийская Республика',
    data: { code: '646', alfa2: 'RW', alfa3: 'RWA', name_short: 'Руанда', name: 'Руандийская Республика' },
  },
  {
    value: 'Румыния',
    unrestricted_value: null,
    data: { code: '642', alfa2: 'RO', alfa3: 'ROU', name_short: 'Румыния', name: null },
  },
  {
    value: 'Сан-Томе и Принсипи',
    unrestricted_value: 'Демократическая Республика Сан-Томе и Принсипи',
    data: {
      code: '678',
      alfa2: 'ST',
      alfa3: 'STP',
      name_short: 'Сан-Томе и Принсипи',
      name: 'Демократическая Республика Сан-Томе и Принсипи',
    },
  },
  {
    value: 'Святая Елена, остров Вознесения, Тристан-да-Кунья',
    unrestricted_value: null,
    data: {
      code: '654',
      alfa2: 'SH',
      alfa3: 'SHN',
      name_short: 'Святая Елена, остров Вознесения, Тристан-да-Кунья',
      name: null,
    },
  },
  {
    value: 'Сен-Мартен (Нидерландская часть)',
    unrestricted_value: null,
    data: { code: '534', alfa2: 'SX', alfa3: 'SXM', name_short: 'Сен-Мартен (Нидерландская часть)', name: null },
  },
  {
    value: 'Сент-Винсент и Гренадины',
    unrestricted_value: null,
    data: { code: '670', alfa2: 'VC', alfa3: 'VCT', name_short: 'Сент-Винсент и Гренадины', name: null },
  },
  {
    value: 'Сент-Люсия',
    unrestricted_value: null,
    data: { code: '662', alfa2: 'LC', alfa3: 'LCA', name_short: 'Сент-Люсия', name: null },
  },
  {
    value: 'Соединенное Королевство',
    unrestricted_value: 'Соединенное Королевство Великобритании и Северной Ирландии',
    data: {
      code: '826',
      alfa2: 'GB',
      alfa3: 'GBR',
      name_short: 'Соединенное Королевство',
      name: 'Соединенное Королевство Великобритании и Северной Ирландии',
    },
  },
  {
    value: 'Соединенные Штаты',
    unrestricted_value: 'Соединенные Штаты Америки',
    data: {
      code: '840',
      alfa2: 'US',
      alfa3: 'USA',
      name_short: 'Соединенные Штаты',
      name: 'Соединенные Штаты Америки',
    },
  },
  {
    value: 'Сомали',
    unrestricted_value: 'Федеративная Республика Сомали',
    data: { code: '706', alfa2: 'SO', alfa3: 'SOM', name_short: 'Сомали', name: 'Федеративная Республика Сомали' },
  },
  {
    value: 'Таджикистан',
    unrestricted_value: 'Республика Таджикистан',
    data: { code: '762', alfa2: 'TJ', alfa3: 'TJK', name_short: 'Таджикистан', name: 'Республика Таджикистан' },
  },
  {
    value: 'Таиланд',
    unrestricted_value: 'Королевство Таиланд',
    data: { code: '764', alfa2: 'TH', alfa3: 'THA', name_short: 'Таиланд', name: 'Королевство Таиланд' },
  },
  {
    value: 'Тайвань',
    unrestricted_value: 'Тайвань (китай)',
    data: { code: '158', alfa2: 'TW', alfa3: 'TWN', name_short: 'Тайвань', name: 'Тайвань (китай)' },
  },
  {
    value: 'Танзания',
    unrestricted_value: 'Объединенная Республика Танзания',
    data: { code: '834', alfa2: 'TZ', alfa3: 'TZA', name_short: 'Танзания', name: 'Объединенная Республика Танзания' },
  },
  {
    value: 'Тимор-Лесте',
    unrestricted_value: 'Демократическая Республика Тимор-Лесте',
    data: {
      code: '626',
      alfa2: 'TL',
      alfa3: 'TLS',
      name_short: 'Тимор-Лесте',
      name: 'Демократическая Республика Тимор-Лесте',
    },
  },
  {
    value: 'Тувалу',
    unrestricted_value: null,
    data: { code: '798', alfa2: 'TV', alfa3: 'TUV', name_short: 'Тувалу', name: null },
  },
  {
    value: 'Тунис',
    unrestricted_value: 'Тунисская Республика',
    data: { code: '788', alfa2: 'TN', alfa3: 'TUN', name_short: 'Тунис', name: 'Тунисская Республика' },
  },
  {
    value: 'Туркмения',
    unrestricted_value: 'Туркменистан',
    data: { code: '795', alfa2: 'TM', alfa3: 'TKM', name_short: 'Туркмения', name: 'Туркменистан' },
  },
  {
    value: 'Турция',
    unrestricted_value: 'Турецкая Республика',
    data: { code: '792', alfa2: 'TR', alfa3: 'TUR', name_short: 'Турция', name: 'Турецкая Республика' },
  },
  {
    value: 'Уганда',
    unrestricted_value: 'Республика Уганда',
    data: { code: '800', alfa2: 'UG', alfa3: 'UGA', name_short: 'Уганда', name: 'Республика Уганда' },
  },
  {
    value: 'Узбекистан',
    unrestricted_value: 'Республика Узбекистан',
    data: { code: '860', alfa2: 'UZ', alfa3: 'UZB', name_short: 'Узбекистан', name: 'Республика Узбекистан' },
  },
  {
    value: 'Украина',
    unrestricted_value: null,
    data: { code: '804', alfa2: 'UA', alfa3: 'UKR', name_short: 'Украина', name: null },
  },
  {
    value: 'Уоллис и Футуна',
    unrestricted_value: null,
    data: { code: '876', alfa2: 'WF', alfa3: 'WLF', name_short: 'Уоллис и Футуна', name: null },
  },
  {
    value: 'Уругвай',
    unrestricted_value: 'Восточная Республика Уругвай',
    data: { code: '858', alfa2: 'UY', alfa3: 'URY', name_short: 'Уругвай', name: 'Восточная Республика Уругвай' },
  },
  {
    value: 'Фарерские острова',
    unrestricted_value: null,
    data: { code: '234', alfa2: 'FO', alfa3: 'FRO', name_short: 'Фарерские острова', name: null },
  },
  {
    value: 'Франция',
    unrestricted_value: 'Французская Республика',
    data: { code: '250', alfa2: 'FR', alfa3: 'FRA', name_short: 'Франция', name: 'Французская Республика' },
  },
  {
    value: 'Французская Гвиана',
    unrestricted_value: null,
    data: { code: '254', alfa2: 'GF', alfa3: 'GUF', name_short: 'Французская Гвиана', name: null },
  },
  {
    value: 'Французская Полинезия',
    unrestricted_value: null,
    data: { code: '258', alfa2: 'PF', alfa3: 'PYF', name_short: 'Французская Полинезия', name: null },
  },
  {
    value: 'Французские Южные территории',
    unrestricted_value: null,
    data: { code: '260', alfa2: 'TF', alfa3: 'ATF', name_short: 'Французские Южные территории', name: null },
  },
  {
    value: 'Хорватия',
    unrestricted_value: 'Республика Хорватия',
    data: { code: '191', alfa2: 'HR', alfa3: 'HRV', name_short: 'Хорватия', name: 'Республика Хорватия' },
  },
  {
    value: 'Центральноафриканская Республика',
    unrestricted_value: null,
    data: { code: '140', alfa2: 'CF', alfa3: 'CAF', name_short: 'Центральноафриканская Республика', name: null },
  },
  {
    value: 'Чад',
    unrestricted_value: 'Республика Чад',
    data: { code: '148', alfa2: 'TD', alfa3: 'TCD', name_short: 'Чад', name: 'Республика Чад' },
  },
  {
    value: 'Черногория',
    unrestricted_value: null,
    data: { code: '499', alfa2: 'ME', alfa3: 'MNE', name_short: 'Черногория', name: null },
  },
  {
    value: 'Чехия',
    unrestricted_value: 'Чешская Республика',
    data: { code: '203', alfa2: 'CZ', alfa3: 'CZE', name_short: 'Чехия', name: 'Чешская Республика' },
  },
  {
    value: 'Чили',
    unrestricted_value: 'Республика Чили',
    data: { code: '152', alfa2: 'CL', alfa3: 'CHL', name_short: 'Чили', name: 'Республика Чили' },
  },
  {
    value: 'Швейцария',
    unrestricted_value: 'Швейцарская Конфедерация',
    data: { code: '756', alfa2: 'CH', alfa3: 'CHE', name_short: 'Швейцария', name: 'Швейцарская Конфедерация' },
  },
  {
    value: 'Швеция',
    unrestricted_value: 'Королевство Швеция',
    data: { code: '752', alfa2: 'SE', alfa3: 'SWE', name_short: 'Швеция', name: 'Королевство Швеция' },
  },
  {
    value: 'Шпицберген и Ян-Майен',
    unrestricted_value: null,
    data: { code: '744', alfa2: 'SJ', alfa3: 'SJM', name_short: 'Шпицберген и Ян-Майен', name: null },
  },
  {
    value: 'Шри-Ланка',
    unrestricted_value: 'Демократическая Социалистическая Республика Шри-Ланка',
    data: {
      code: '144',
      alfa2: 'LK',
      alfa3: 'LKA',
      name_short: 'Шри-Ланка',
      name: 'Демократическая Социалистическая Республика Шри-Ланка',
    },
  },
  {
    value: 'Эквадор',
    unrestricted_value: 'Республика Эквадор',
    data: { code: '218', alfa2: 'EC', alfa3: 'ECU', name_short: 'Эквадор', name: 'Республика Эквадор' },
  },
  {
    value: 'Экваториальная Гвинея',
    unrestricted_value: 'Республика Экваториальная Гвинея',
    data: {
      code: '226',
      alfa2: 'GQ',
      alfa3: 'GNQ',
      name_short: 'Экваториальная Гвинея',
      name: 'Республика Экваториальная Гвинея',
    },
  },
  {
    value: 'Эландские острова',
    unrestricted_value: null,
    data: { code: '248', alfa2: 'AX', alfa3: 'ALA', name_short: 'Эландские острова', name: null },
  },
  {
    value: 'Эль-Сальвадор',
    unrestricted_value: 'Республика Эль-Сальвадор',
    data: { code: '222', alfa2: 'SV', alfa3: 'SLV', name_short: 'Эль-Сальвадор', name: 'Республика Эль-Сальвадор' },
  },
  {
    value: 'Эритрея',
    unrestricted_value: 'Государство Эритрея',
    data: { code: '232', alfa2: 'ER', alfa3: 'ERI', name_short: 'Эритрея', name: 'Государство Эритрея' },
  },
  {
    value: 'Эстония',
    unrestricted_value: 'Эстонская Республика',
    data: { code: '233', alfa2: 'EE', alfa3: 'EST', name_short: 'Эстония', name: 'Эстонская Республика' },
  },
  {
    value: 'Эфиопия',
    unrestricted_value: 'Федеративная Демократическая Республика Эфиопия',
    data: {
      code: '231',
      alfa2: 'ET',
      alfa3: 'ETH',
      name_short: 'Эфиопия',
      name: 'Федеративная Демократическая Республика Эфиопия',
    },
  },
  {
    value: 'ЮАР',
    unrestricted_value: 'Южно-Африканская Республика',
    data: { code: '710', alfa2: 'ZA', alfa3: 'ZAF', name_short: 'ЮАР', name: 'Южно-Африканская Республика' },
  },
  {
    value: 'Южная Джорджия и Южные Сандвичевы острова',
    unrestricted_value: null,
    data: {
      code: '239',
      alfa2: 'GS',
      alfa3: 'SGS',
      name_short: 'Южная Джорджия и Южные Сандвичевы острова',
      name: null,
    },
  },
  {
    value: 'Южная Корея',
    unrestricted_value: 'Республика Корея',
    data: { code: '410', alfa2: 'KR', alfa3: 'KOR', name_short: 'Южная Корея', name: 'Республика Корея' },
  },
  {
    value: 'Южная Осетия',
    unrestricted_value: 'Республика Южная Осетия',
    data: { code: '896', alfa2: 'OS', alfa3: 'OST', name_short: 'Южная Осетия', name: 'Республика Южная Осетия' },
  },
  {
    value: 'Южный Судан',
    unrestricted_value: 'Республика Южный Судан',
    data: { code: '728', alfa2: 'SS', alfa3: 'SSD', name_short: 'Южный Судан', name: 'Республика Южный Судан' },
  },
  {
    value: 'Ямайка',
    unrestricted_value: null,
    data: { code: '388', alfa2: 'JM', alfa3: 'JAM', name_short: 'Ямайка', name: null },
  },
  {
    value: 'Япония',
    unrestricted_value: null,
    data: { code: '392', alfa2: 'JP', alfa3: 'JPN', name_short: 'Япония', name: null },
  },
]
