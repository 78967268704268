// tslint:disable
/**
 * (dc-bundle-mts)
 * Owner: Дебетовые карты
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface BundleStatusRequest
 */
export interface BundleStatusRequest {
  /**
   *
   * @type {string}
   * @memberof BundleStatusRequest
   */
  cardId: string
}
/**
 *
 * @export
 * @interface BundleStatusResponse
 */
export interface BundleStatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof BundleStatusResponse
   */
  isClientParticipating?: boolean
  /**
   *
   * @type {boolean}
   * @memberof BundleStatusResponse
   */
  isCounterRunning?: boolean
}
/**
 *
 * @export
 * @interface CardInfo
 */
export interface CardInfo {
  /**
   *
   * @type {string}
   * @memberof CardInfo
   */
  cardId: string
  /**
   *
   * @type {string}
   * @memberof CardInfo
   */
  hashedPAN?: string
}
/**
 *
 * @export
 * @interface CardsForActivationResponse
 */
export interface CardsForActivationResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof CardsForActivationResponse
   */
  cardList?: Array<string>
}
/**
 *
 * @export
 * @interface CounterActivateRequest
 */
export interface CounterActivateRequest {
  /**
   *
   * @type {string}
   * @memberof CounterActivateRequest
   */
  phone: string
  /**
   *
   * @type {CardInfo}
   * @memberof CounterActivateRequest
   */
  cardInfo: CardInfo
}
/**
 *
 * @export
 * @interface CounterBlock
 */
export interface CounterBlock {
  /**
   *
   * @type {string}
   * @memberof CounterBlock
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof CounterBlock
   */
  description?: string
  /**
   *
   * @type {number}
   * @memberof CounterBlock
   */
  targetValue?: number
  /**
   *
   * @type {number}
   * @memberof CounterBlock
   */
  currentValue?: number
  /**
   *
   * @type {string}
   * @memberof CounterBlock
   */
  endDate?: string
}
/**
 *
 * @export
 * @interface CounterResponse
 */
export interface CounterResponse {
  /**
   *
   * @type {UnfulfilledConditionBlock}
   * @memberof CounterResponse
   */
  unfulfilledConditionBlock?: UnfulfilledConditionBlock
  /**
   *
   * @type {FulfilledConditionBlock}
   * @memberof CounterResponse
   */
  fulfilledConditionBlock?: FulfilledConditionBlock
  /**
   *
   * @type {CounterBlock}
   * @memberof CounterResponse
   */
  counterBlock?: CounterBlock
  /**
   *
   * @type {Array<string>}
   * @memberof CounterResponse
   */
  additionalInfoList?: Array<string>
}
/**
 *
 * @export
 * @interface CounterShortResponse
 */
export interface CounterShortResponse {
  /**
   *
   * @type {number}
   * @memberof CounterShortResponse
   */
  targetValue?: number
  /**
   *
   * @type {number}
   * @memberof CounterShortResponse
   */
  currentValue?: number
  /**
   *
   * @type {string}
   * @memberof CounterShortResponse
   */
  endDate?: string
}
/**
 *
 * @export
 * @interface Description
 */
export interface Description {
  /**
   *
   * @type {string}
   * @memberof Description
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof Description
   */
  link?: string
}
/**
 *
 * @export
 * @interface FulfilledConditionBlock
 */
export interface FulfilledConditionBlock {
  /**
   *
   * @type {Array<Description>}
   * @memberof FulfilledConditionBlock
   */
  description?: Array<Description>
}
/**
 *
 * @export
 * @interface GetSubscribersResponse
 */
export interface GetSubscribersResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof GetSubscribersResponse
   */
  phoneNumbers?: Array<string>
}
/**
 *
 * @export
 * @interface MtsBankException
 */
export interface MtsBankException {
  /**
   *
   * @type {MtsBankExceptionCause}
   * @memberof MtsBankException
   */
  cause?: MtsBankExceptionCause
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankException
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  httpStatus?: MtsBankExceptionHttpStatusEnum
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  uuid?: string
  /**
   *
   * @type {boolean}
   * @memberof MtsBankException
   */
  needLog?: boolean
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankExceptionCauseSuppressed>}
   * @memberof MtsBankException
   */
  suppressed?: Array<MtsBankExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  localizedMessage?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MtsBankExceptionHttpStatusEnum {
  _100CONTINUE = '100 CONTINUE',
  _101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  _102PROCESSING = '102 PROCESSING',
  _103CHECKPOINT = '103 CHECKPOINT',
  _200OK = '200 OK',
  _201CREATED = '201 CREATED',
  _202ACCEPTED = '202 ACCEPTED',
  _203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  _204NOCONTENT = '204 NO_CONTENT',
  _205RESETCONTENT = '205 RESET_CONTENT',
  _206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  _207MULTISTATUS = '207 MULTI_STATUS',
  _208ALREADYREPORTED = '208 ALREADY_REPORTED',
  _226IMUSED = '226 IM_USED',
  _300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  _301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  _302FOUND = '302 FOUND',
  _302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  _303SEEOTHER = '303 SEE_OTHER',
  _304NOTMODIFIED = '304 NOT_MODIFIED',
  _305USEPROXY = '305 USE_PROXY',
  _307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  _308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  _400BADREQUEST = '400 BAD_REQUEST',
  _401UNAUTHORIZED = '401 UNAUTHORIZED',
  _402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  _403FORBIDDEN = '403 FORBIDDEN',
  _404NOTFOUND = '404 NOT_FOUND',
  _405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  _406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  _407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  _408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  _409CONFLICT = '409 CONFLICT',
  _410GONE = '410 GONE',
  _411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  _412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  _413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  _413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  _414URITOOLONG = '414 URI_TOO_LONG',
  _414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  _415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  _416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  _417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  _418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  _419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  _420METHODFAILURE = '420 METHOD_FAILURE',
  _421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  _422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  _423LOCKED = '423 LOCKED',
  _424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  _425TOOEARLY = '425 TOO_EARLY',
  _426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  _428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  _429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  _431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  _451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  _500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  _501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  _502BADGATEWAY = '502 BAD_GATEWAY',
  _503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  _504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  _505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  _506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  _507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  _508LOOPDETECTED = '508 LOOP_DETECTED',
  _509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  _510NOTEXTENDED = '510 NOT_EXTENDED',
  _511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

/**
 *
 * @export
 * @interface MtsBankExceptionCause
 */
export interface MtsBankExceptionCause {
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankExceptionCause
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCause
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankExceptionCauseSuppressed>}
   * @memberof MtsBankExceptionCause
   */
  suppressed?: Array<MtsBankExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCause
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface MtsBankExceptionCauseStackTrace
 */
export interface MtsBankExceptionCauseStackTrace {
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  classLoaderName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  moduleName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  moduleVersion?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  methodName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  fileName?: string
  /**
   *
   * @type {number}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  lineNumber?: number
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  className?: string
  /**
   *
   * @type {boolean}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  nativeMethod?: boolean
}
/**
 *
 * @export
 * @interface MtsBankExceptionCauseSuppressed
 */
export interface MtsBankExceptionCauseSuppressed {
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface SaveSubscribersRequest
 */
export interface SaveSubscribersRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof SaveSubscribersRequest
   */
  phoneNumbers: Array<string>
}
/**
 *
 * @export
 * @interface UnfulfilledConditionBlock
 */
export interface UnfulfilledConditionBlock {
  /**
   *
   * @type {Array<Description>}
   * @memberof UnfulfilledConditionBlock
   */
  description?: Array<Description>
}

/**
 * BundleControllerApi - axios parameter creator
 * @export
 */
export const BundleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для проверки статуса участия Клиента в акции и факта запуска счетчика по его карте
     * @param {BundleStatusRequest} bundleStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBundleStatus(bundleStatusRequest: BundleStatusRequest, options: any = {}): RequestArgs {
      // verify required parameter 'bundleStatusRequest' is not null or undefined
      if (bundleStatusRequest === null || bundleStatusRequest === undefined) {
        throw new RequiredError(
          'bundleStatusRequest',
          'Required parameter bundleStatusRequest was null or undefined when calling checkBundleStatus.'
        )
      }
      const localVarPath = `/dc-bundle-mts/v1/checkBundleStatus`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof bundleStatusRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(bundleStatusRequest !== undefined ? bundleStatusRequest : {})
        : bundleStatusRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BundleControllerApi - functional programming interface
 * @export
 */
export const BundleControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для проверки статуса участия Клиента в акции и факта запуска счетчика по его карте
     * @param {BundleStatusRequest} bundleStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBundleStatus(
      bundleStatusRequest: BundleStatusRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BundleStatusResponse> {
      const localVarAxiosArgs = BundleControllerApiAxiosParamCreator(configuration).checkBundleStatus(
        bundleStatusRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * BundleControllerApi - factory interface
 * @export
 */
export const BundleControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод для проверки статуса участия Клиента в акции и факта запуска счетчика по его карте
     * @param {BundleStatusRequest} bundleStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBundleStatus(bundleStatusRequest: BundleStatusRequest, options?: any): AxiosPromise<BundleStatusResponse> {
      return BundleControllerApiFp(configuration).checkBundleStatus(bundleStatusRequest, options)(axios, basePath)
    },
  }
}

/**
 * BundleControllerApi - object-oriented interface
 * @export
 * @class BundleControllerApi
 * @extends {BaseAPI}
 */
export class BundleControllerApi extends BaseAPI {
  /**
   *
   * @summary Метод для проверки статуса участия Клиента в акции и факта запуска счетчика по его карте
   * @param {BundleStatusRequest} bundleStatusRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleControllerApi
   */
  public checkBundleStatus(bundleStatusRequest: BundleStatusRequest, options?: any) {
    return BundleControllerApiFp(this.configuration).checkBundleStatus(bundleStatusRequest, options)(
      this.axios,
      this.basePath
    )
  }
}

/**
 * CardsControllerApi - axios parameter creator
 * @export
 */
export const CardsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения массива карт, по которым доступна активация программы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBundleStatus1(options: any = {}): RequestArgs {
      const localVarPath = `/dc-bundle-mts/v1/cardsForActivation`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CardsControllerApi - functional programming interface
 * @export
 */
export const CardsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения массива карт, по которым доступна активация программы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBundleStatus1(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardsForActivationResponse> {
      const localVarAxiosArgs = CardsControllerApiAxiosParamCreator(configuration).checkBundleStatus1(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * CardsControllerApi - factory interface
 * @export
 */
export const CardsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод для получения массива карт, по которым доступна активация программы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBundleStatus1(options?: any): AxiosPromise<CardsForActivationResponse> {
      return CardsControllerApiFp(configuration).checkBundleStatus1(options)(axios, basePath)
    },
  }
}

/**
 * CardsControllerApi - object-oriented interface
 * @export
 * @class CardsControllerApi
 * @extends {BaseAPI}
 */
export class CardsControllerApi extends BaseAPI {
  /**
   *
   * @summary Метод для получения массива карт, по которым доступна активация программы
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CardsControllerApi
   */
  public checkBundleStatus1(options?: any) {
    return CardsControllerApiFp(this.configuration).checkBundleStatus1(options)(this.axios, this.basePath)
  }
}

/**
 * CounterControllerApi - axios parameter creator
 * @export
 */
export const CounterControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод получения данных счетчика авторизаций для предоставления бесплатной связи
     * @param {string} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counter(cardId: string, options: any = {}): RequestArgs {
      // verify required parameter 'cardId' is not null or undefined
      if (cardId === null || cardId === undefined) {
        throw new RequiredError('cardId', 'Required parameter cardId was null or undefined when calling counter.')
      }
      const localVarPath = `/dc-bundle-mts/v1/counter`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cardId !== undefined) {
        localVarQueryParameter['cardId'] = cardId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод активации счетчика авторизаций для предоставления бесплатной связи
     * @param {CounterActivateRequest} counterActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterActivate(counterActivateRequest: CounterActivateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'counterActivateRequest' is not null or undefined
      if (counterActivateRequest === null || counterActivateRequest === undefined) {
        throw new RequiredError(
          'counterActivateRequest',
          'Required parameter counterActivateRequest was null or undefined when calling counterActivate.'
        )
      }
      const localVarPath = `/dc-bundle-mts/v1/counter/activate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof counterActivateRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(counterActivateRequest !== undefined ? counterActivateRequest : {})
        : counterActivateRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод получения данных счетчика авторизаций для предоставления бесплатной связи
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterShort(phone: string, options: any = {}): RequestArgs {
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling counterShort.')
      }
      const localVarPath = `/dc-bundle-mts/v1/counter/short`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CounterControllerApi - functional programming interface
 * @export
 */
export const CounterControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод получения данных счетчика авторизаций для предоставления бесплатной связи
     * @param {string} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counter(
      cardId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounterResponse> {
      const localVarAxiosArgs = CounterControllerApiAxiosParamCreator(configuration).counter(cardId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод активации счетчика авторизаций для предоставления бесплатной связи
     * @param {CounterActivateRequest} counterActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterActivate(
      counterActivateRequest: CounterActivateRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = CounterControllerApiAxiosParamCreator(configuration).counterActivate(
        counterActivateRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод получения данных счетчика авторизаций для предоставления бесплатной связи
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterShort(
      phone: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounterShortResponse> {
      const localVarAxiosArgs = CounterControllerApiAxiosParamCreator(configuration).counterShort(phone, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * CounterControllerApi - factory interface
 * @export
 */
export const CounterControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод получения данных счетчика авторизаций для предоставления бесплатной связи
     * @param {string} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counter(cardId: string, options?: any): AxiosPromise<CounterResponse> {
      return CounterControllerApiFp(configuration).counter(cardId, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод активации счетчика авторизаций для предоставления бесплатной связи
     * @param {CounterActivateRequest} counterActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterActivate(counterActivateRequest: CounterActivateRequest, options?: any): AxiosPromise<string> {
      return CounterControllerApiFp(configuration).counterActivate(counterActivateRequest, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод получения данных счетчика авторизаций для предоставления бесплатной связи
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    counterShort(phone: string, options?: any): AxiosPromise<CounterShortResponse> {
      return CounterControllerApiFp(configuration).counterShort(phone, options)(axios, basePath)
    },
  }
}

/**
 * CounterControllerApi - object-oriented interface
 * @export
 * @class CounterControllerApi
 * @extends {BaseAPI}
 */
export class CounterControllerApi extends BaseAPI {
  /**
   *
   * @summary Метод получения данных счетчика авторизаций для предоставления бесплатной связи
   * @param {string} cardId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CounterControllerApi
   */
  public counter(cardId: string, options?: any) {
    return CounterControllerApiFp(this.configuration).counter(cardId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод активации счетчика авторизаций для предоставления бесплатной связи
   * @param {CounterActivateRequest} counterActivateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CounterControllerApi
   */
  public counterActivate(counterActivateRequest: CounterActivateRequest, options?: any) {
    return CounterControllerApiFp(this.configuration).counterActivate(counterActivateRequest, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Метод получения данных счетчика авторизаций для предоставления бесплатной связи
   * @param {string} phone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CounterControllerApi
   */
  public counterShort(phone: string, options?: any) {
    return CounterControllerApiFp(this.configuration).counterShort(phone, options)(this.axios, this.basePath)
  }
}

/**
 * SubscribesControllerApi - axios parameter creator
 * @export
 */
export const SubscribesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения телефонов абонентов МТС, участвующих в акции (в частности выполнивших условиях акции за определенный период)
     * @param {string} dateOfFulfillmentCondition Дата выполнения Клиентом условия по сумме транзакций
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribes(dateOfFulfillmentCondition: string, options: any = {}): RequestArgs {
      // verify required parameter 'dateOfFulfillmentCondition' is not null or undefined
      if (dateOfFulfillmentCondition === null || dateOfFulfillmentCondition === undefined) {
        throw new RequiredError(
          'dateOfFulfillmentCondition',
          'Required parameter dateOfFulfillmentCondition was null or undefined when calling getSubscribes.'
        )
      }
      const localVarPath = `/dc-bundle-mts/v1/subscribers`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (dateOfFulfillmentCondition !== undefined) {
        localVarQueryParameter['dateOfFulfillmentCondition'] = dateOfFulfillmentCondition
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод для добавления абонентов МТС, которые участвуют в акции по получению бесплатной связи
     * @param {SaveSubscribersRequest} saveSubscribersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSubscribes(saveSubscribersRequest: SaveSubscribersRequest, options: any = {}): RequestArgs {
      // verify required parameter 'saveSubscribersRequest' is not null or undefined
      if (saveSubscribersRequest === null || saveSubscribersRequest === undefined) {
        throw new RequiredError(
          'saveSubscribersRequest',
          'Required parameter saveSubscribersRequest was null or undefined when calling saveSubscribes.'
        )
      }
      const localVarPath = `/dc-bundle-mts/v1/subscribers`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof saveSubscribersRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(saveSubscribersRequest !== undefined ? saveSubscribersRequest : {})
        : saveSubscribersRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SubscribesControllerApi - functional programming interface
 * @export
 */
export const SubscribesControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения телефонов абонентов МТС, участвующих в акции (в частности выполнивших условиях акции за определенный период)
     * @param {string} dateOfFulfillmentCondition Дата выполнения Клиентом условия по сумме транзакций
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribes(
      dateOfFulfillmentCondition: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubscribersResponse> {
      const localVarAxiosArgs = SubscribesControllerApiAxiosParamCreator(configuration).getSubscribes(
        dateOfFulfillmentCondition,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод для добавления абонентов МТС, которые участвуют в акции по получению бесплатной связи
     * @param {SaveSubscribersRequest} saveSubscribersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSubscribes(
      saveSubscribersRequest: SaveSubscribersRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = SubscribesControllerApiAxiosParamCreator(configuration).saveSubscribes(
        saveSubscribersRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SubscribesControllerApi - factory interface
 * @export
 */
export const SubscribesControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод для получения телефонов абонентов МТС, участвующих в акции (в частности выполнивших условиях акции за определенный период)
     * @param {string} dateOfFulfillmentCondition Дата выполнения Клиентом условия по сумме транзакций
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribes(dateOfFulfillmentCondition: string, options?: any): AxiosPromise<GetSubscribersResponse> {
      return SubscribesControllerApiFp(configuration).getSubscribes(dateOfFulfillmentCondition, options)(
        axios,
        basePath
      )
    },
    /**
     *
     * @summary Метод для добавления абонентов МТС, которые участвуют в акции по получению бесплатной связи
     * @param {SaveSubscribersRequest} saveSubscribersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSubscribes(saveSubscribersRequest: SaveSubscribersRequest, options?: any): AxiosPromise<string> {
      return SubscribesControllerApiFp(configuration).saveSubscribes(saveSubscribersRequest, options)(axios, basePath)
    },
  }
}

/**
 * SubscribesControllerApi - object-oriented interface
 * @export
 * @class SubscribesControllerApi
 * @extends {BaseAPI}
 */
export class SubscribesControllerApi extends BaseAPI {
  /**
   *
   * @summary Метод для получения телефонов абонентов МТС, участвующих в акции (в частности выполнивших условиях акции за определенный период)
   * @param {string} dateOfFulfillmentCondition Дата выполнения Клиентом условия по сумме транзакций
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscribesControllerApi
   */
  public getSubscribes(dateOfFulfillmentCondition: string, options?: any) {
    return SubscribesControllerApiFp(this.configuration).getSubscribes(dateOfFulfillmentCondition, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Метод для добавления абонентов МТС, которые участвуют в акции по получению бесплатной связи
   * @param {SaveSubscribersRequest} saveSubscribersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscribesControllerApi
   */
  public saveSubscribes(saveSubscribersRequest: SaveSubscribersRequest, options?: any) {
    return SubscribesControllerApiFp(this.configuration).saveSubscribes(saveSubscribersRequest, options)(
      this.axios,
      this.basePath
    )
  }
}
