import { authHelper } from './authHelper/AuthHelper'

const APP_ID = 'showcase_d74f9d52-dac7-4d2e-884d-7c697f5e09f3'
const QUESTIONNAIRE_SOURCE = 'https://mtsopros.mts.ru/object/buck-0/sdk.v5.js'

export const getQuestionnaire = async () => {
  const DeviceDetector = (await import('device-detector-js')).default
  const deviceDetector = new DeviceDetector()
  const device = deviceDetector.parse(navigator.userAgent)

  const { userProfile } = authHelper
  const mobilePhone = userProfile?.mobilePhone.replace('+', '')

  // eslint-disable-next-line no-console
  console.log(`Номер который передали в нпс "${mobilePhone}"`)

  const appMetaData = {
    ...(mobilePhone && { msisdnMain: mobilePhone }),
    appId: APP_ID,
    token: localStorage.getItem('feedback-session'),
    operatingSystem: device.os.name,
    operatingSystemVersion: device.os.version,
    appVersion: '1',
  }

  const launchPoll = (url: string) => {
    document.getElementById('player').setAttribute('src', url)
  }

  const script = document.createElement('script')

  script.src = QUESTIONNAIRE_SOURCE
  script.async = true

  script.onload = () => {
    window.tNPS.start(launchPoll, appMetaData)
  }
  document.head.append(script)
}
