import type { CardCollection, BuiltInCreditCardType } from './type'
import { CardType } from './type'

export const cardTypes: CardCollection = {
  visa: {
    niceType: 'Visa',
    type: CardType.VISA,
    patterns: [4],
    gaps: [4, 8, 12],
    lengths: [16, 18, 19],
    code: {
      name: 'CVV',
      size: 3,
    },
  } as BuiltInCreditCardType,
  mastercard: {
    niceType: 'Mastercard',
    type: CardType.MASTERCARD,
    patterns: [[51, 55], [2221, 2229], [223, 229], [23, 26], [270, 271], 2720],
    gaps: [4, 8, 12],
    lengths: [16],
    code: {
      name: 'CVC',
      size: 3,
    },
  } as BuiltInCreditCardType,
  unionpay: {
    niceType: 'UnionPay',
    type: CardType.UNIONPAY,
    patterns: [
      620,
      [624, 626],
      [62100, 62182],
      [62184, 62187],
      [62185, 62197],
      [62200, 62205],
      [622010, 622999],
      622018,
      [622019, 622999],
      [62207, 62209],
      [622126, 622925],
      [623, 626],
      6270,
      6272,
      6276,
      [627700, 627779],
      [627781, 627799],
      [6282, 6289],
      6291,
      6292,
      810,
      [8110, 8131],
      [8132, 8151],
      [8152, 8163],
      [8164, 8171],
    ],
    gaps: [4, 8, 12],
    lengths: [14, 15, 16, 17, 18, 19],
    code: {
      name: 'CVN',
      size: 3,
    },
  } as BuiltInCreditCardType,
  maestro: {
    niceType: 'Maestro',
    type: CardType.MAESTRO,
    patterns: [493698, [500000, 504174], [504176, 506698], [506779, 508999], [56, 59], 63, 67, 6],
    gaps: [4, 8, 12],
    lengths: [12, 13, 14, 15, 16, 17, 18, 19],
    code: {
      name: 'CVC',
      size: 3,
    },
  } as BuiltInCreditCardType,
  mir: {
    niceType: 'Mir',
    type: CardType.MIR,
    patterns: [[2200, 2204], 35651401, 35629960],
    gaps: [4, 8, 12],
    lengths: [16, 17, 18, 19],
    code: {
      name: 'CVP2',
      size: 3,
    },
  } as BuiltInCreditCardType,
}
