import { validateField } from '@utils/formValidators/formValidators'
import { GetRecommendedBank } from '@components/SBP/types'
import { DEFAULT_BANK, MTC_BANK } from '@components/SBP/constants'
import { GetTransferListRes } from '@open-api/ump/transfer-by-phone'
import { toPenny } from '@components/BindigsField/utils'

export const validateAmountWithFee = (totalAmount: number, balance: number | string) =>
  validateField(totalAmount, [
    {
      amountValues: {
        withFee: true,
        balance: toPenny(balance),
      },
    },
  ])

export const getRecommendedBank: GetRecommendedBank = (transferListRes, recommendedBankId) => {
  let recipientBank = DEFAULT_BANK
  const { isRecipientMtsBankClient, sbpBanksList } = transferListRes

  if (isRecipientMtsBankClient && !recommendedBankId) {
    return { label: MTC_BANK.bankName, value: MTC_BANK.bankId }
  }

  if (sbpBanksList.length > 0) {
    const recommendedBank = recommendedBankId && sbpBanksList.find((bank) => bank.bankId === recommendedBankId)
    const defaultBank = sbpBanksList.find((bank) => bank.defaultBank === true)

    if (recommendedBank || defaultBank) {
      recipientBank = {
        label: recommendedBank?.bankName || defaultBank.bankName,
        value: recommendedBank?.bankId || defaultBank.bankId,
      }
    }

    return recipientBank
  }
}

// todo выпилить processingTransferListResp: когда доработают бэк и MTS будет приходить в response.sbpBanksList
export const processingTransferListResp = (transferList: GetTransferListRes) => {
  const isIncludeMtsBank = transferList.isRecipientMtsBankClient

  if (!isIncludeMtsBank) {
    return transferList
  }
  const mtsBankData = { ...MTC_BANK, defaultBank: Boolean(transferList.isRecipientMtsBankClient) }

  return { ...transferList, sbpBanksList: [mtsBankData, ...transferList.sbpBanksList] }
}
