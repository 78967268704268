import { SerializedError } from '@reduxjs/toolkit'
import {
  LoanDto,
  InfoForProgramsDto,
  ReasonDto,
  ActivationServiceResponse,
  CheckAvailabilityResponse,
  CheckAvailabilityRequestV2,
  DocumentDto,
} from '@open-api/ump/credit-holidays'

export interface BaseState<T> {
  inProgress: boolean
  data?: T
}

export enum CreditHolidaysPage {
  SELECT_CREDIT = 'selectCredit',
  SELECT_PROGRAM = 'selectProgram',
  GRACE_PERIOD = 'gracePeriod',
  DEFERMENT = 'defermentPayment',
  GRACE_PERIOD_FZ_353 = 'gracePeriodFZ353',
  SUCCESS = 'success',
  SUCCESS_GRACE = 'successGrace',
  ERROR = 'error',
}

export enum CreditHolidaysProgramStatus {
  SUCCESS = 'AvailableForConnection',
  DEFERMENT = 'Deferment_377FL',
  FAIL = 'Refuse',
}

export enum LoanStatus {
  STOP = 'Stop',
  NO_PROBLEM = 'NoProblem',
  DEFERMENT = 'Deferment',
  DEFERMENT377 = 'Deferment_377FL',
  REFUSE = 'Refuse',
}

export enum CreditHolidaysProgramName {
  GRACE_PERIOD = 'KK.SVO',
  DEFERMENT_PAYMENT = 'KK.MTSB',
  GRACE_PERIOD_FZ_353 = 'KK.353',
  EMERGENCY_FZ_353 = 'KK.353_ES',
  REDUCED_INCOME = 'KK.353_RI',
}

export enum CreditHolidaysCreditType {
  NCPK = 'NCPK',
  CC = 'CC',
}

export enum CreditHolidaysReasons {
  EMERGENCY = 'ES',
  REDUCED_INCOME = 'RI',
  DAMAGE_PROPERTY = 'DP',
  HEALTH_HARM = 'HH',
  EVACUATION = 'Evacuation',
}

export interface LoanDtoWithCheckedField extends LoanDto {
  checked?: boolean
}

export interface CreditHolidaysError {
  heading: string
  text: string
  reasons?: string[]
  disclaimerText?: string
  eventLabel?: string
  screenName?: string
}

export interface CreditHolidaysState {
  step: CreditHolidaysPage
  inProgress: boolean
  error: CreditHolidaysError
  originalError: SerializedError
}

export type LoansState = BaseState<LoanDtoWithCheckedField[]>

export interface InfoForPrograms extends InfoForProgramsDto {
  loanId: string
  delay: boolean
  svcCodeOne?: string
  svcCodeTwo?: string
  svcCodeThree?: string
  militaryDoc?: string
  startInSVO?: number
  startDate?: number
  activationDate?: number
  statusText?: string
  status?: string
  emergencyTerm?: number
  reducedIncomeTerm?: number
  attachedDocument?: DocumentDto[]
  terms?: {
    term: number
    checked?: boolean
  }[]
}

export type ProgramsState = BaseState<(InfoForPrograms & { checked?: boolean })[]>

export type ProgramsResponseState = BaseState<{ programs?: InfoForPrograms[] }>

export type AvailabilityState = BaseState<{
  activationId?: string
  requestNum?: string
  reasons?: string[]
}>

export type DocumentsState = BaseState<ActivationServiceResponse['status']>

export type ReasonsState = BaseState<(ReasonDto & { checked?: boolean })[]>

export type ActivationState = BaseState<ActivationServiceResponse>

export type CheckAvailabilityRequestWithMultipleCodes = CheckAvailabilityRequestV2 & {
  svcCodes?: string[]
  status?: string
}

export interface CheckAvailabilityResponseWithSvcCode {
  data: CheckAvailabilityResponse & {
    activationId?: string
    requestNum?: string
  }
  svcCode: string
}

export interface CheckAvailabilitySVO extends CheckAvailabilityResponse {
  term?: number
  activationDate?: string
  startInSVO?: string
  svoDoc?: string
}

export interface CreditHolidaysReducer extends CreditHolidaysState {
  step: CreditHolidaysPage
  inProgress: boolean
  error: CreditHolidaysError
  originalError: SerializedError
  loans: LoansState
  programs: ProgramsState
  documents: DocumentsState
  availability: AvailabilityState
  availabilitySVO: AvailabilityState
  reasons: ReasonsState
  activation: ActivationState
  isOpenModal: boolean
}
