import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CounterActivateRequest, CounterResponse } from '@open-api/ump/dc-bundle-mts'
import { dcBundleMtsCounterApi, dcBundleMtsCardsApi } from '@api/ump/dc-bundle-mts'
import { baseInitialState } from '@root/fetches/baseInitialState'
import { isAxiosError } from '@root/types/reducers'
import { TBodyError, TInitialValues } from './types'

const NAMESPACE = 'freeConnection'

const initialState: TInitialValues = {
  cardsForActivation: {
    ...baseInitialState,
  },
  activateCounter: {
    ...baseInitialState,
  },
  counter: {
    ...baseInitialState,
    bodyError: null,
  },
}

export const fetchGetCardsForActivation = createAsyncThunk(`${NAMESPACE}/getCardsForActivation`, async () => {
  const { data } = await dcBundleMtsCardsApi.checkBundleStatus1({ headers: { Platform: 'WEB' } })

  return data
})

export const fetchActivateCounter = createAsyncThunk<string, CounterActivateRequest, { rejectValue: TBodyError }>(
  `${NAMESPACE}/activateCounter`,
  async (reqData: CounterActivateRequest, { rejectWithValue }) => {
    try {
      const { data } = await dcBundleMtsCounterApi.counterActivate(reqData, { headers: { Platform: 'WEB' } })

      return data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

export const fetchCounter = createAsyncThunk<CounterResponse, string, { rejectValue: TBodyError }>(
  `${NAMESPACE}/counter`,
  async (cardId: string, { rejectWithValue }) => {
    try {
      const { data } = await dcBundleMtsCounterApi.counter(cardId, { headers: { Platform: 'WEB' } })

      return data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const freeConnection = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetCardsForActivation.pending, (state) => {
      state.cardsForActivation.data = {}
      state.cardsForActivation.inProgress = true
      state.cardsForActivation.error = null
    })
    builder.addCase(fetchGetCardsForActivation.rejected, (state, action) => {
      state.cardsForActivation.data = {}
      state.cardsForActivation.error = action.error
      state.cardsForActivation.inProgress = false
    })
    builder.addCase(fetchGetCardsForActivation.fulfilled, (state, action) => {
      state.cardsForActivation.data = action.payload
      state.cardsForActivation.inProgress = false
      state.cardsForActivation.error = null
    })
    builder.addCase(fetchActivateCounter.pending, (state) => {
      state.activateCounter.data = {}
      state.activateCounter.error = null
      state.activateCounter.inProgress = true
    })
    builder.addCase(fetchActivateCounter.rejected, (state, action) => {
      state.activateCounter.data = action.payload
      state.activateCounter.error = action.error
      state.activateCounter.inProgress = false
    })
    builder.addCase(fetchActivateCounter.fulfilled, (state, action) => {
      state.activateCounter.data = action.payload
      state.activateCounter.error = null
      state.activateCounter.inProgress = false
    })
    builder.addCase(fetchCounter.pending, (state) => {
      state.counter.data = {}
      state.counter.error = null
      state.counter.bodyError = null
      state.counter.inProgress = true
    })
    builder.addCase(fetchCounter.rejected, (state, action) => {
      state.counter.data = {}
      state.counter.bodyError = action.payload
      state.counter.error = action.error
      state.counter.inProgress = false
    })
    builder.addCase(fetchCounter.fulfilled, (state, action) => {
      state.counter.data = action.payload
      state.counter.error = null
      state.counter.bodyError = null
      state.counter.inProgress = false
    })
  },
})

export const freeConnectionReducer = freeConnection.reducer
