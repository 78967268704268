import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { formatMoney, Text, theme } from '@mtsbank/ui-kit'
import { HistoryProtoResponseId } from '@open-api/ump/response-history'
import { PersonalOffersProtoComponentProps } from '@open-api/ump/st-personal-offers'
import { WrapperCard } from '@components/Styled/Wrappers'

import { saveResponseHistory } from '@utils/api'
import { Content } from '@components/Content/Content'
import { mobilePhoneSelector } from '@selectors/auth'
import { mainPageOfferGtm } from '@root/utils/gtm/mainPage/events'
import { useBlockInVisionOnce } from '@root/hooks/useBlockInVision'
import { MAIN_PATH, MAIN_OFFERS_PATH } from '@root/constants'
import { DataLayerEvents } from '@root/utils/gtm/mainPage/types'
import { selectMores } from '@root/selectors/fullApproveWidget'
import { OfferItem } from '../Offers/types'

export const LimitFA: FC<{ offer: PersonalOffersProtoComponentProps }> = ({ offer }) => {
  const router = useRouter()
  const phoneNum = useSelector(mobilePhoneSelector)
  const offerMores = useSelector(selectMores) as OfferItem[]

  const handleClick = useCallback(() => {
    mainPageOfferGtm(DataLayerEvents.LIMIT_FA_CLICK, offer)
    saveResponseHistory(offer, HistoryProtoResponseId.Id10211, phoneNum)

    const moreThanOneOffer = offerMores?.length > 1

    if (offerMores && moreThanOneOffer) {
      router.push(MAIN_OFFERS_PATH)
    } else {
      const { urlForAction } = offer

      try {
        window.location.href = new URL(urlForAction).href
      } catch (err) {
        router.push(`${MAIN_PATH}${urlForAction}`)
      }
    }
  }, [offer, offerMores, phoneNum, router])

  const ref = useRef<HTMLDivElement | null>(null)
  const { isVisible } = useBlockInVisionOnce(ref)

  useEffect(() => {
    if (isVisible && offer) {
      mainPageOfferGtm(DataLayerEvents.LIMIT_FA_SEEING, offer)
    }
  }, [isVisible, offer])

  useEffect(() => {
    if (offer) {
      mainPageOfferGtm(DataLayerEvents.LIMIT_FA_SHOW, offer)
    }
  }, [offer])

  if (!offer) {
    return null
  }

  return (
    <WrapperCard isClickable isRounded onClick={handleClick} ref={ref}>
      <div>
        <Text size="md">Мне одобрено</Text>
        <Content marginTop={theme.spacings.xs3}>
          <Text size="xl" fontWeight="medium">
            {formatMoney(offer?.sum, 'RUR')}
          </Text>
        </Content>
      </div>
    </WrapperCard>
  )
}
