import { config } from '@configs/gosuslugi-info-config'
import { ItemType } from '@root/components/NewMain/types'
import { questionClickGtm, questionLinkClickGtm } from './events'
import {
  accountClickGtm,
  accountStikerAddClickGtm,
  accountStikerAddShowGtm,
  creditStikerAddClickGtm,
  creditStikerAddShowGtm,
  depositClickGtm,
} from './newMainPageEvents'

interface GetCardStikerAddEvent {
  show: () => void
  click: () => void
}

const {
  questionsAndAnswers: { items },
} = config

const sendGtmQuestionClicks = (i: number) => questionClickGtm(items[i].title4gtm)
const sendQuestionLinkClickGtm = (i: number) => questionLinkClickGtm(items[i].title4gtm)

export const gosuslugiQuestionsAndAnswersGtm = () => {
  const questions = document.querySelectorAll('[class^="AccordionButton"],[class*=" AccordionButton"]')

  questions.forEach((question, i) => question.addEventListener('click', () => sendGtmQuestionClicks(i)))
  questions.forEach((question, i) =>
    question.parentNode.addEventListener('click', (e) => {
      if ((e.target as HTMLElement).tagName !== 'A') {
        return
      }

      return sendQuestionLinkClickGtm(i)
    })
  )
}

export const getGtmFnCardList = (itemType) =>
  ({ [ItemType.Accounts]: accountClickGtm, [ItemType.Deposits]: depositClickGtm }[itemType])

export const getCardStikerAddEvent = (type: ItemType): GetCardStikerAddEvent | null => {
  switch (type) {
    case ItemType.Accounts:
      return { show: accountStikerAddShowGtm, click: accountStikerAddClickGtm }
    case ItemType.Credits:
      return { show: creditStikerAddShowGtm, click: creditStikerAddClickGtm }
    default:
      return null
  }
}
