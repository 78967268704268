import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GatewayProtoErrorRsp } from '@open-api/ump/debit-cards'
import { tspPersonalFinanceApi } from '@api/ump/tsp-personal-finance'

import { getCurrentRegion } from '@utils/region'
import { RecomendationsV2Res, RecommendationsState } from '@reducers/recommendations/types'

const NAMESPACE = 'recommendations'

const initialState: RecommendationsState = { inProgress: null, error: null, data: null }

const fetchGetTspV2 = createAsyncThunk<RecomendationsV2Res>(`${NAMESPACE}/getTspV2`, async (_, { rejectWithValue }) => {
  try {
    const region = getCurrentRegion()
    const response = await tspPersonalFinanceApi.getTspV2(region)

    return response.data
  } catch (err) {
    const errRes: GatewayProtoErrorRsp = err.response.data

    return rejectWithValue(errRes || { message: err.message })
  }
})

const recommendations = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetTspV2.pending, (state) => {
      state.error = null
      state.inProgress = true
    })
    builder.addCase(fetchGetTspV2.rejected, (state, action) => {
      state.error = action.payload
      state.inProgress = false
    })
    builder.addCase(fetchGetTspV2.fulfilled, (state, action) => {
      state.inProgress = false
      state.data = action.payload
    })
  },
})

export { fetchGetTspV2 }
export const recommendationsReducer = recommendations.reducer
