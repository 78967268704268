import { authHelper } from '@utils/authHelper/AuthHelper'
import { store } from '@root/store'
import { fetchRegions, selectRegion } from '@reducers/regions/regions'
import { getSelectedRegion } from '@selectors/regions'
import { Region } from '@generated/api'
import { EventType } from '@utils/gtm/baseFunctions'
import { translit } from '@utils/gtm/translit'
import { getCurrentRegion } from '@utils/region'

class StatDataLoader {
  private data: EventType = {}

  private resolveReady

  private queue = []

  private ready = new Promise<boolean>((resolve) => {
    this.resolveReady = resolve
  })

  constructor() {
    this.ready.finally(() => {
      this.dequeue()
    })
  }

  async updateData() {
    this.updateRegionData()

    await this.updateProfileData()

    this.resolveReady(true)
    this.ready.finally(() => {
      this.dequeue()
    })
  }

  updateRegionData() {
    const regionId = getCurrentRegion()

    let currentSelectedRegion = getSelectedRegion(store.getState())

    if (regionId && !currentSelectedRegion) {
      store
        .dispatch(fetchRegions())
        .then((data) => {
          const regions: Region[] = Array.isArray(data.payload) ? data.payload : []

          if (regions && regions.length && regionId) {
            const region = regions.find((region: Region) => region.regionCode === regionId)

            store.dispatch(selectRegion(region))
            currentSelectedRegion = getSelectedRegion(store.getState())
          }
        })
        .catch(() => {})
    }

    if (currentSelectedRegion) {
      this.data.region = translit(currentSelectedRegion.regionName)
      this.data.regionId = currentSelectedRegion.regionCentreCode
    }
  }

  updateProfileData() {
    this.data.userAuth = Number(authHelper.isAuthenticated())

    return new Promise((resolve) => {
      authHelper.ready.finally(() => {
        if (authHelper.userProfile) {
          this.data.numberType = authHelper.userProfile.type
          this.data.accountType = authHelper.userProfile.accountType
        }
        resolve(true)
      })
    })
  }

  setRegion(selectedRegion: Region) {
    this.data.region = translit(selectedRegion.regionName)
    this.data.regionId = selectedRegion.regionCentreCode
  }

  dequeue() {
    this.queue.forEach((fn: (data: EventType) => void, index: number, arr) => {
      if (typeof fn === 'function') {
        fn({
          ...this.data,
          // timeStamp: new Date().getTime(),
        })
      }
      arr.splice(index, 1)
    })
  }

  push(fn) {
    this.queue.push(fn)
    this.ready.finally(() => {
      this.dequeue()
    })
  }
}

const statDataLoader = new StatDataLoader()

export { statDataLoader }
