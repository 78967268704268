import { TypeProductGtm, OfferProducGtm } from './types'

export const getProductTypeOfferGtm = (productType, product): OfferProducGtm => {
  if (productType === TypeProductGtm.LOAN || product?.indexOf('.NCPK') > -1) {
    return OfferProducGtm.NCPK
  }
  if (productType === TypeProductGtm.CARD || product?.indexOf('.CC') > -1) {
    return OfferProducGtm.CC
  }

  return OfferProducGtm.DEFAULT
}
