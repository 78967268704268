import map from 'lodash/fp/map'

const matchingLettersRuEng: { [key: string]: string | string } = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'j',
  з: 'z',
  и: 'i',
  й: 'j',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'x',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'shh',
  ы: 'y',
  э: 'e',
  ю: 'yu',
  я: 'ya',
}

// по требованиям аналитиков из текста вырезаем ъ/ь и все знаки препинания
const removingСharactersRegex = /[ьъ!"#$%&'()*+,-./:;<=>?@[\]^`{|}~]/g

export const translit = (str: string): string =>
  map((char) => {
    if (char === ' ') {
      return '_'
    }

    return matchingLettersRuEng[char.toLowerCase()] || char
  }, str)
    .join('')
    .replace(removingСharactersRegex, '')
