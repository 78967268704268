import { ArrestDto } from '@open-api/ump/dbo-loan'

export const filterArrests = (arrests: ArrestDto[], accountNumber) => {
  if (!arrests) {
    return []
  }

  return arrests.filter(
    ({ fssp = false, accountNumbers = [] }) =>
      fssp || !accountNumbers.length || !!accountNumbers.filter((number) => number === accountNumber).length
  )
}
