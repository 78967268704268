import React, { FC } from 'react';
import { Button, Flex, Modal, Text } from '@mtsbank/ui-kit';
import { CustomHeading } from '@components/NewMain/styled';
export interface Props {
  title: string;
  text: string;
  button?: {
    text: string;
    href: string;
    target?: string;
  };
}
export const Info: FC<Props> = ({
  title,
  text,
  button
}) => <div>
    <Modal.Body>
      <CustomHeading h={3} sizemob="sm">
        {title}
      </CustomHeading>
      <Text>{text}</Text>
    </Modal.Body>
    {button && <Modal.Footer>
        <Flex justify="center">
          <Button fluidmob as="a" href={button.href} target={button.target}>
            {button.text}
          </Button>
        </Flex>
      </Modal.Footer>}
  </div>;