import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CanOrderDraft, CreateOrderDraft, EarlyRepaymentState } from '@reducers/earlyRepayment/types'
import { isAxiosError } from '@root/types/reducers'
import {
  CancelOrderRequest,
  CreateOrderRequest,
  CreateOrderResponse,
  EmptyBodyResponse,
  FetchOrdersRequest,
  FetchOrdersResponse,
  FetchScheduleRequest,
  FetchScheduleResponse,
} from '@open-api/ump/early-repayment'
import { ErrorResponse } from '@root/types/generated'
import { orderControllerApi, scheduleControllerApi } from '@root/api/ump/early-repayment'

const initialState: EarlyRepaymentState = {
  paymentSchedule: {
    data: null,
    inProgress: true,
    error: null,
  },
  pmtOrders: {
    data: null,
    inProgress: true,
    error: null,
  },
  order: null,
  canOrder: null,
  inProgress: true,
  error: null,
}

const fetchSchedule = createAsyncThunk(
  'earlyRepayment/fetchSchedule',
  async (params: FetchScheduleRequest): Promise<FetchScheduleResponse> => {
    const response = await scheduleControllerApi.fetchSchedules1(params)

    return response.data
  }
)

const fetchPmtOrders = createAsyncThunk(
  'earlyRepayment/pmtOrders',
  async (params: FetchOrdersRequest): Promise<FetchOrdersResponse> => {
    const response = await orderControllerApi.fetchOrders(params)

    return response.data
  }
)

const createOrder = createAsyncThunk<CreateOrderResponse, CreateOrderRequest, { rejectValue: ErrorResponse }>(
  'earlyRepayment/createOrder',
  async (order: CreateOrderRequest, { rejectWithValue }) => {
    try {
      const response = await orderControllerApi.saveOrder(order)

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const cancelOrder = createAsyncThunk<EmptyBodyResponse, CancelOrderRequest, { rejectValue: ErrorResponse }>(
  'earlyRepayment/cancelOrder',
  async (order: CancelOrderRequest, { rejectWithValue }) => {
    try {
      const response = await orderControllerApi.cancel(order)

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const earlyRepayment = createSlice({
  name: 'earlyRepayment',
  initialState,
  reducers: {
    createOrderDraft(state, action: PayloadAction<CreateOrderDraft>) {
      state.order = { draft: { ...action.payload } }
    },
    cancelOrderDraft(state, action: PayloadAction<CanOrderDraft>) {
      state.canOrder = { draft: { ...action.payload } }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchedule.pending, (state) => {
      state.inProgress = true
      state.paymentSchedule.inProgress = true
      state.error = null
      state.paymentSchedule.error = null
    })
    builder.addCase(fetchSchedule.rejected, (state, action) => {
      state.error = action.error
      state.paymentSchedule.error = action.error
      state.inProgress = false
      state.paymentSchedule.inProgress = false
    })
    builder.addCase(fetchSchedule.fulfilled, (state, action) => {
      state.paymentSchedule.data = { data: { ...action.payload } }
      state.paymentSchedule.inProgress = false
      state.inProgress = false
    })
    builder.addCase(fetchPmtOrders.pending, (state) => {
      state.inProgress = true
      state.pmtOrders.inProgress = true
      state.error = null
      state.pmtOrders.error = null
    })
    builder.addCase(fetchPmtOrders.rejected, (state, action) => {
      state.error = action.error
      state.pmtOrders.error = action.error
      state.inProgress = false
      state.pmtOrders.inProgress = false
    })
    builder.addCase(fetchPmtOrders.fulfilled, (state, action) => {
      state.pmtOrders.data = { ...action.payload }
      state.pmtOrders.inProgress = false
      state.inProgress = false
    })
    builder.addCase(createOrder.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(createOrder.rejected, (state, action) => {
      state.error = action.payload.error
      state.inProgress = false
    })
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.order = { ...state.order, data: { ...action.payload } }
      state.inProgress = false
    })
    builder.addCase(cancelOrder.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(cancelOrder.rejected, (state, action) => {
      state.error = action.payload.error
      state.inProgress = false
    })
    builder.addCase(cancelOrder.fulfilled, (state) => {
      state.canOrder = { data: { type: 'success-cancel' } }
      state.error = null
      state.inProgress = false
    })
  },
})

export const { createOrderDraft, cancelOrderDraft } = earlyRepayment.actions
export { fetchSchedule, createOrder, fetchPmtOrders, cancelOrder }

export default earlyRepayment.reducer
