import { Status } from '@components/NewMain/Cards/types'
import { cardStatuses } from '@utils/gtm/baseFunctions'
import { CardStatusConfig } from '@components/Modals/CardsStatuses/types'

export const config: CardStatusConfig = {
  [Status.BLOCKED]: {
    title: 'Карта заблокирована',
    message:
      'Действия с\u00A0картой сейчас недоступны. Пожалуйста, обратитесь в\u00A0отделение МТС Банка или салон связи МТС, чтобы её\u00A0разблокировать.',
    gtmTitle: cardStatuses.kartaZablokirovana,
  },
  [Status.ON_REISSUE]: {
    title: 'Карта на\u00A0перевыпуске',
    message: 'Пришлем СМС, когда карта будет готова',
    gtmTitle: cardStatuses.kartaNaPerevypuske,
  },
  [Status.READY_TO_RECEIVE]: {
    title: 'Новая карта готова',
    message: 'Её\u00A0можно забрать в\u00A0офисе МТС Банка',
    gtmTitle: cardStatuses.novayaKartaGotova,
  },
  [Status.CLOSED]: {
    title: 'Карта закрыта',
    message: 'Пополнить карту или перевести с\u00A0нее деньги можно по\u00A0счету карты в\u00A0разделе «Счета»',
    gtmTitle: cardStatuses.kartaZakryta,
  },
} as const
