import { createSlice } from '@reduxjs/toolkit'
import { InitialState } from '@reducers/settings/types'

const NAME_SPACE = 'settings'

const initialState: InitialState = {
  isWebView: false,
  isCheckedWebView: false,
}

const settings = createSlice({
  name: NAME_SPACE,
  initialState,
  reducers: {
    setIsWebView(state, action) {
      state.isWebView = action.payload
      state.isCheckedWebView = true
    },
  },
})

export const { setIsWebView } = settings.actions
export const settingsReducer = settings.reducer
