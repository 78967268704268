import React, { FC } from 'react'
import { ComponentType } from '@reducers/fullApproveWidget/types'
import { FantomProduct } from '@components/FullApproveWidget/FantomProduct'
import { FAOffers } from '@components/FullApproveWidget/types'
import { LimitFA } from '@components/FullApproveWidget/LimitFA'
import { Skeleton } from '@components/NewMain/Skeleton'

export const FullApproveWidget: FC<FAOffers> = ({ offers, componentType, isLoading, offerLimit }) => {
  if (isLoading) {
    return <Skeleton />
  }

  return (
    <>
      {
        {
          [ComponentType.LIMIT]: <LimitFA offer={offerLimit} />,
          [ComponentType.FANTOM_LOAN]: <FantomProduct componentType={componentType} offers={offers} />,
          [ComponentType.FANTOM_CARD]: (
            <FantomProduct isNonePaddingBottom componentType={componentType} offers={offers} />
          ),
        }[componentType]
      }
    </>
  )
}
