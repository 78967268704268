import isEmpty from 'lodash/isEmpty'
import { GatewayProtoGetCardListMainRsp } from '@open-api/ump/debit-cards'
import { CardsData, CardType, MarkOwnership, OwnershipBank, ResultCardData } from '@components/NewMain/Cards/types'

const markOwnership: MarkOwnership = (cards, ownershipBank) => cards.map((card) => ({ ...card, ownershipBank }))

export const getSortedCards = (data: GatewayProtoGetCardListMainRsp) => {
  const { bankCards, linkedCards, importedCards } = data

  const cardResult: CardsData = {
    visibleCards: [],
    invisibleCards: [],
  }
  const bankCardsResult = (!isEmpty(bankCards) && markOwnership(bankCards, OwnershipBank.BANKCARDS)) || []
  const linkedCardsResult = (!isEmpty(linkedCards) && markOwnership(linkedCards, OwnershipBank.LINKEDCARDS)) || []
  const importedResult = (!isEmpty(importedCards) && markOwnership(importedCards, OwnershipBank.IMPORTEDCARDS)) || []

  const allCards: ResultCardData[] = [].concat(bankCardsResult, linkedCardsResult, importedResult)
  const sortedCards = allCards.sort((a, b) => (b.type === CardType.EMONEY_ACCOUNT ? -1 : 1))

  sortedCards.forEach((card) => {
    if (card.isProductVisible || card.ownershipBank === OwnershipBank.IMPORTEDCARDS) {
      cardResult.visibleCards.push(card)
    } else {
      cardResult.invisibleCards.push(card)
    }
  })

  return cardResult
}
