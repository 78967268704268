// tslint:disable
/**
 * Сервис кредитных каникул 2022 (credit-holiday)
 * Owner: ncpk-sales-n2b
 *
 * The version of the OpenAPI document: undefined
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface ActivationServiceRequest
 */
export interface ActivationServiceRequest {
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequest
   */
  loanId: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequest
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof ActivationServiceRequest
   */
  term: number
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequest
   */
  svcCode: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequest
   */
  clientReason?: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequest
   */
  militaryDate?: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequest
   */
  militaryDoc?: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequest
   */
  creditType?: string
}
/**
 *
 * @export
 * @interface ActivationServiceRequestV2
 */
export interface ActivationServiceRequestV2 {
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequestV2
   */
  activationId: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequestV2
   */
  loanId: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequestV2
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof ActivationServiceRequestV2
   */
  term: number
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequestV2
   */
  svcCode: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequestV2
   */
  clientReason?: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequestV2
   */
  militaryDate?: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequestV2
   */
  militaryDoc?: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceRequestV2
   */
  creditType?: string
}
/**
 *
 * @export
 * @interface ActivationServiceResponse
 */
export interface ActivationServiceResponse {
  /**
   *
   * @type {string}
   * @memberof ActivationServiceResponse
   */
  umpRequestId?: string
  /**
   *
   * @type {string}
   * @memberof ActivationServiceResponse
   */
  requestNum?: string
  /**
   *
   * @type {Array<ReasonForRefusalDto>}
   * @memberof ActivationServiceResponse
   */
  reasonsForRefusal?: Array<ReasonForRefusalDto>
  /**
   *
   * @type {string}
   * @memberof ActivationServiceResponse
   */
  status?: string
}
/**
 *
 * @export
 * @interface CheckAvailabilityRequest
 */
export interface CheckAvailabilityRequest {
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequest
   */
  loanId: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequest
   */
  requestDate: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequest
   */
  agreementNum: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequest
   */
  svcCode: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequest
   */
  militaryDate?: string
}
/**
 *
 * @export
 * @interface CheckAvailabilityRequestV2
 */
export interface CheckAvailabilityRequestV2 {
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  loanId: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  requestDate: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  agreementNum: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  svcCode: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  militaryDate?: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  militaryDoc?: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  clientReason?: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof CheckAvailabilityRequestV2
   */
  term: number
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityRequestV2
   */
  creditType?: string
}
/**
 *
 * @export
 * @interface CheckAvailabilityResponse
 */
export interface CheckAvailabilityResponse {
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityResponse
   */
  umpRequestId?: string
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityResponse
   */
  svcCode?: string
  /**
   *
   * @type {Array<ReasonForRefusalDto>}
   * @memberof CheckAvailabilityResponse
   */
  reasonsForRefusal?: Array<ReasonForRefusalDto>
  /**
   *
   * @type {string}
   * @memberof CheckAvailabilityResponse
   */
  status?: string
}
/**
 *
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
  /**
   *
   * @type {string}
   * @memberof DocumentDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof DocumentDto
   */
  file: string
}
/**
 *
 * @export
 * @interface DocumentsRequest
 */
export interface DocumentsRequest {
  /**
   *
   * @type {string}
   * @memberof DocumentsRequest
   */
  activationId: string
  /**
   *
   * @type {Array<DocumentDto>}
   * @memberof DocumentsRequest
   */
  documents: Array<DocumentDto>
}
/**
 *
 * @export
 * @interface InfoForProgramsDto
 */
export interface InfoForProgramsDto {
  /**
   *
   * @type {string}
   * @memberof InfoForProgramsDto
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof InfoForProgramsDto
   */
  textHint?: string
  /**
   *
   * @type {string}
   * @memberof InfoForProgramsDto
   */
  svcCode?: string
  /**
   *
   * @type {number}
   * @memberof InfoForProgramsDto
   */
  term?: number
}
/**
 *
 * @export
 * @interface InfoForProgramsResponse
 */
export interface InfoForProgramsResponse {
  /**
   *
   * @type {string}
   * @memberof InfoForProgramsResponse
   */
  umpRequestId?: string
  /**
   *
   * @type {Array<TextForProgramsDto>}
   * @memberof InfoForProgramsResponse
   */
  texts?: Array<TextForProgramsDto>
  /**
   *
   * @type {Array<InfoForProgramsDto>}
   * @memberof InfoForProgramsResponse
   */
  info?: Array<InfoForProgramsDto>
}
/**
 *
 * @export
 * @interface InfoResponse
 */
export interface InfoResponse {
  /**
   *
   * @type {string}
   * @memberof InfoResponse
   */
  umpRequestId?: string
  /**
   *
   * @type {Array<LoanDto>}
   * @memberof InfoResponse
   */
  loan?: Array<LoanDto>
}
/**
 *
 * @export
 * @interface LoanDto
 */
export interface LoanDto {
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  loanId?: string
  /**
   *
   * @type {number}
   * @memberof LoanDto
   */
  sumContract?: number
  /**
   *
   * @type {number}
   * @memberof LoanDto
   */
  sumContractLeft?: number
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  startDt?: string
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  endDt?: string
  /**
   *
   * @type {number}
   * @memberof LoanDto
   */
  sumOverDue?: number
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  agreementNum?: string
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  statusDescription?: string
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  status?: string
  /**
   *
   * @type {Array<string>}
   * @memberof LoanDto
   */
  svcCodes?: Array<string>
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  statusDetails?: string
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  nextPmtDate?: string
  /**
   *
   * @type {string}
   * @memberof LoanDto
   */
  creditType?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorInfo
 */
export interface MtsBankErrorInfo {
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorResponse
 */
export interface MtsBankErrorResponse {
  /**
   *
   * @type {MtsBankErrorInfo}
   * @memberof MtsBankErrorResponse
   */
  error?: MtsBankErrorInfo
}
/**
 *
 * @export
 * @interface ReasonDto
 */
export interface ReasonDto {
  /**
   *
   * @type {string}
   * @memberof ReasonDto
   */
  reason?: string
  /**
   *
   * @type {string}
   * @memberof ReasonDto
   */
  reasonCode?: string
  /**
   *
   * @type {number}
   * @memberof ReasonDto
   */
  code?: number
}
/**
 *
 * @export
 * @interface ReasonForRefusalDto
 */
export interface ReasonForRefusalDto {
  /**
   *
   * @type {string}
   * @memberof ReasonForRefusalDto
   */
  reason?: string
}
/**
 *
 * @export
 * @interface ReasonResponse
 */
export interface ReasonResponse {
  /**
   *
   * @type {string}
   * @memberof ReasonResponse
   */
  umpRequestId?: string
  /**
   *
   * @type {Array<ReasonDto>}
   * @memberof ReasonResponse
   */
  reasons?: Array<ReasonDto>
}
/**
 *
 * @export
 * @interface TextForArrearsDto
 */
export interface TextForArrearsDto {
  /**
   *
   * @type {string}
   * @memberof TextForArrearsDto
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof TextForArrearsDto
   */
  svcCode?: string
  /**
   *
   * @type {string}
   * @memberof TextForArrearsDto
   */
  date?: string
}
/**
 *
 * @export
 * @interface TextForArrearsResponse
 */
export interface TextForArrearsResponse {
  /**
   *
   * @type {string}
   * @memberof TextForArrearsResponse
   */
  umpRequestId?: string
  /**
   *
   * @type {Array<TextForArrearsDto>}
   * @memberof TextForArrearsResponse
   */
  texts?: Array<TextForArrearsDto>
}
/**
 *
 * @export
 * @interface TextForProgramsDto
 */
export interface TextForProgramsDto {
  /**
   *
   * @type {string}
   * @memberof TextForProgramsDto
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof TextForProgramsDto
   */
  svcCode?: string
  /**
   *
   * @type {string}
   * @memberof TextForProgramsDto
   */
  date?: string
}

/**
 * CreditHolidayServiceApi - axios parameter creator
 * @export
 */
export const CreditHolidayServiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Активирует запрос кредитных каникул
     * @param {ActivationServiceRequestV2} activationServiceRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activationService(activationServiceRequestV2: ActivationServiceRequestV2, options: any = {}): RequestArgs {
      // verify required parameter 'activationServiceRequestV2' is not null or undefined
      if (activationServiceRequestV2 === null || activationServiceRequestV2 === undefined) {
        throw new RequiredError(
          'activationServiceRequestV2',
          'Required parameter activationServiceRequestV2 was null or undefined when calling activationService.'
        )
      }
      const localVarPath = `/credit-holiday/v2/activationService`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof activationServiceRequestV2 !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(activationServiceRequestV2 !== undefined ? activationServiceRequestV2 : {})
        : activationServiceRequestV2 || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Активирует запрос кредитных каникул
     * @param {ActivationServiceRequest} activationServiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activationService1(activationServiceRequest: ActivationServiceRequest, options: any = {}): RequestArgs {
      // verify required parameter 'activationServiceRequest' is not null or undefined
      if (activationServiceRequest === null || activationServiceRequest === undefined) {
        throw new RequiredError(
          'activationServiceRequest',
          'Required parameter activationServiceRequest was null or undefined when calling activationService1.'
        )
      }
      const localVarPath = `/credit-holiday/v1/activationService`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof activationServiceRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(activationServiceRequest !== undefined ? activationServiceRequest : {})
        : activationServiceRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Проверяет доступность запроса кредитных каникул
     * @param {CheckAvailabilityRequestV2} checkAvailabilityRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAvailability(checkAvailabilityRequestV2: CheckAvailabilityRequestV2, options: any = {}): RequestArgs {
      // verify required parameter 'checkAvailabilityRequestV2' is not null or undefined
      if (checkAvailabilityRequestV2 === null || checkAvailabilityRequestV2 === undefined) {
        throw new RequiredError(
          'checkAvailabilityRequestV2',
          'Required parameter checkAvailabilityRequestV2 was null or undefined when calling checkAvailability.'
        )
      }
      const localVarPath = `/credit-holiday/v2/checkAvailability`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof checkAvailabilityRequestV2 !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkAvailabilityRequestV2 !== undefined ? checkAvailabilityRequestV2 : {})
        : checkAvailabilityRequestV2 || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Проверяет доступность запроса кредитных каникул
     * @param {CheckAvailabilityRequest} checkAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAvailability1(checkAvailabilityRequest: CheckAvailabilityRequest, options: any = {}): RequestArgs {
      // verify required parameter 'checkAvailabilityRequest' is not null or undefined
      if (checkAvailabilityRequest === null || checkAvailabilityRequest === undefined) {
        throw new RequiredError(
          'checkAvailabilityRequest',
          'Required parameter checkAvailabilityRequest was null or undefined when calling checkAvailability1.'
        )
      }
      const localVarPath = `/credit-holiday/v1/checkAvailability`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof checkAvailabilityRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkAvailabilityRequest !== undefined ? checkAvailabilityRequest : {})
        : checkAvailabilityRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Сохраняет докуенты на кредитные каникулы
     * @param {DocumentsRequest} documentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documents(documentsRequest: DocumentsRequest, options: any = {}): RequestArgs {
      // verify required parameter 'documentsRequest' is not null or undefined
      if (documentsRequest === null || documentsRequest === undefined) {
        throw new RequiredError(
          'documentsRequest',
          'Required parameter documentsRequest was null or undefined when calling documents.'
        )
      }
      const localVarPath = `/credit-holiday/v1/documents`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof documentsRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(documentsRequest !== undefined ? documentsRequest : {})
        : documentsRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo(options: any = {}): RequestArgs {
      const localVarPath = `/credit-holiday/v3/info`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo1(options: any = {}): RequestArgs {
      const localVarPath = `/credit-holiday/v2/info`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo2(options: any = {}): RequestArgs {
      const localVarPath = `/credit-holiday/v1/info`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает информацию о программах кредитных каникул
     * @param {string} loanId
     * @param {string} delay
     * @param {string} svcCodeOne
     * @param {string} [svcCodeTwo]
     * @param {string} [svcCodeThree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForPrograms(
      loanId: string,
      delay: string,
      svcCodeOne: string,
      svcCodeTwo?: string,
      svcCodeThree?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError(
          'loanId',
          'Required parameter loanId was null or undefined when calling getInfoForPrograms.'
        )
      }
      // verify required parameter 'delay' is not null or undefined
      if (delay === null || delay === undefined) {
        throw new RequiredError(
          'delay',
          'Required parameter delay was null or undefined when calling getInfoForPrograms.'
        )
      }
      // verify required parameter 'svcCodeOne' is not null or undefined
      if (svcCodeOne === null || svcCodeOne === undefined) {
        throw new RequiredError(
          'svcCodeOne',
          'Required parameter svcCodeOne was null or undefined when calling getInfoForPrograms.'
        )
      }
      const localVarPath = `/credit-holiday/v2/infoForPrograms`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      if (loanId !== undefined) {
        localVarQueryParameter['loanId'] = loanId
      }

      if (delay !== undefined) {
        localVarQueryParameter['delay'] = delay
      }

      if (svcCodeOne !== undefined) {
        localVarQueryParameter['svcCodeOne'] = svcCodeOne
      }

      if (svcCodeTwo !== undefined) {
        localVarQueryParameter['svcCodeTwo'] = svcCodeTwo
      }

      if (svcCodeThree !== undefined) {
        localVarQueryParameter['svcCodeThree'] = svcCodeThree
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает информацию о программах кредитных каникул
     * @param {string} loanId
     * @param {string} delay
     * @param {string} svcCodeOne
     * @param {string} [svcCodeTwo]
     * @param {string} [svcCodeThree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForPrograms1(
      loanId: string,
      delay: string,
      svcCodeOne: string,
      svcCodeTwo?: string,
      svcCodeThree?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError(
          'loanId',
          'Required parameter loanId was null or undefined when calling getInfoForPrograms1.'
        )
      }
      // verify required parameter 'delay' is not null or undefined
      if (delay === null || delay === undefined) {
        throw new RequiredError(
          'delay',
          'Required parameter delay was null or undefined when calling getInfoForPrograms1.'
        )
      }
      // verify required parameter 'svcCodeOne' is not null or undefined
      if (svcCodeOne === null || svcCodeOne === undefined) {
        throw new RequiredError(
          'svcCodeOne',
          'Required parameter svcCodeOne was null or undefined when calling getInfoForPrograms1.'
        )
      }
      const localVarPath = `/credit-holiday/v1/infoForPrograms`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      if (loanId !== undefined) {
        localVarQueryParameter['loanId'] = loanId
      }

      if (delay !== undefined) {
        localVarQueryParameter['delay'] = delay
      }

      if (svcCodeOne !== undefined) {
        localVarQueryParameter['svcCodeOne'] = svcCodeOne
      }

      if (svcCodeTwo !== undefined) {
        localVarQueryParameter['svcCodeTwo'] = svcCodeTwo
      }

      if (svcCodeThree !== undefined) {
        localVarQueryParameter['svcCodeThree'] = svcCodeThree
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает информацию о причинах запроса кредитных каникул
     * @param {string} [svcCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReasons(svcCode?: string, options: any = {}): RequestArgs {
      const localVarPath = `/credit-holiday/v1/reasonsForGracePeriod`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      if (svcCode !== undefined) {
        localVarQueryParameter['svcCode'] = svcCode
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает информацию о причинах отказа на запрос кредитных каникул
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTextForArrears(loanId: string, options: any = {}): RequestArgs {
      // verify required parameter 'loanId' is not null or undefined
      if (loanId === null || loanId === undefined) {
        throw new RequiredError(
          'loanId',
          'Required parameter loanId was null or undefined when calling getTextForArrears.'
        )
      }
      const localVarPath = `/credit-holiday/v1/textForArrears`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer-jwt required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      if (loanId !== undefined) {
        localVarQueryParameter['loanId'] = loanId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CreditHolidayServiceApi - functional programming interface
 * @export
 */
export const CreditHolidayServiceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Активирует запрос кредитных каникул
     * @param {ActivationServiceRequestV2} activationServiceRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activationService(
      activationServiceRequestV2: ActivationServiceRequestV2,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationServiceResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).activationService(
        activationServiceRequestV2,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Активирует запрос кредитных каникул
     * @param {ActivationServiceRequest} activationServiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activationService1(
      activationServiceRequest: ActivationServiceRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationServiceResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).activationService1(
        activationServiceRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Проверяет доступность запроса кредитных каникул
     * @param {CheckAvailabilityRequestV2} checkAvailabilityRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAvailability(
      checkAvailabilityRequestV2: CheckAvailabilityRequestV2,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckAvailabilityResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).checkAvailability(
        checkAvailabilityRequestV2,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Проверяет доступность запроса кредитных каникул
     * @param {CheckAvailabilityRequest} checkAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAvailability1(
      checkAvailabilityRequest: CheckAvailabilityRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckAvailabilityResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).checkAvailability1(
        checkAvailabilityRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Сохраняет докуенты на кредитные каникулы
     * @param {DocumentsRequest} documentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documents(
      documentsRequest: DocumentsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationServiceResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).documents(
        documentsRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).getInfo(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo1(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).getInfo1(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo2(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).getInfo2(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Возвращает информацию о программах кредитных каникул
     * @param {string} loanId
     * @param {string} delay
     * @param {string} svcCodeOne
     * @param {string} [svcCodeTwo]
     * @param {string} [svcCodeThree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForPrograms(
      loanId: string,
      delay: string,
      svcCodeOne: string,
      svcCodeTwo?: string,
      svcCodeThree?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoForProgramsResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).getInfoForPrograms(
        loanId,
        delay,
        svcCodeOne,
        svcCodeTwo,
        svcCodeThree,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Возвращает информацию о программах кредитных каникул
     * @param {string} loanId
     * @param {string} delay
     * @param {string} svcCodeOne
     * @param {string} [svcCodeTwo]
     * @param {string} [svcCodeThree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForPrograms1(
      loanId: string,
      delay: string,
      svcCodeOne: string,
      svcCodeTwo?: string,
      svcCodeThree?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoForProgramsResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).getInfoForPrograms1(
        loanId,
        delay,
        svcCodeOne,
        svcCodeTwo,
        svcCodeThree,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Возвращает информацию о причинах запроса кредитных каникул
     * @param {string} [svcCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReasons(
      svcCode?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReasonResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).getReasons(svcCode, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Возвращает информацию о причинах отказа на запрос кредитных каникул
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTextForArrears(
      loanId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextForArrearsResponse> {
      const localVarAxiosArgs = CreditHolidayServiceApiAxiosParamCreator(configuration).getTextForArrears(
        loanId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * CreditHolidayServiceApi - factory interface
 * @export
 */
export const CreditHolidayServiceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Активирует запрос кредитных каникул
     * @param {ActivationServiceRequestV2} activationServiceRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activationService(
      activationServiceRequestV2: ActivationServiceRequestV2,
      options?: any
    ): AxiosPromise<ActivationServiceResponse> {
      return CreditHolidayServiceApiFp(configuration).activationService(activationServiceRequestV2, options)(
        axios,
        basePath
      )
    },
    /**
     *
     * @summary Активирует запрос кредитных каникул
     * @param {ActivationServiceRequest} activationServiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activationService1(
      activationServiceRequest: ActivationServiceRequest,
      options?: any
    ): AxiosPromise<ActivationServiceResponse> {
      return CreditHolidayServiceApiFp(configuration).activationService1(activationServiceRequest, options)(
        axios,
        basePath
      )
    },
    /**
     *
     * @summary Проверяет доступность запроса кредитных каникул
     * @param {CheckAvailabilityRequestV2} checkAvailabilityRequestV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAvailability(
      checkAvailabilityRequestV2: CheckAvailabilityRequestV2,
      options?: any
    ): AxiosPromise<CheckAvailabilityResponse> {
      return CreditHolidayServiceApiFp(configuration).checkAvailability(checkAvailabilityRequestV2, options)(
        axios,
        basePath
      )
    },
    /**
     *
     * @summary Проверяет доступность запроса кредитных каникул
     * @param {CheckAvailabilityRequest} checkAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAvailability1(
      checkAvailabilityRequest: CheckAvailabilityRequest,
      options?: any
    ): AxiosPromise<CheckAvailabilityResponse> {
      return CreditHolidayServiceApiFp(configuration).checkAvailability1(checkAvailabilityRequest, options)(
        axios,
        basePath
      )
    },
    /**
     *
     * @summary Сохраняет докуенты на кредитные каникулы
     * @param {DocumentsRequest} documentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documents(documentsRequest: DocumentsRequest, options?: any): AxiosPromise<ActivationServiceResponse> {
      return CreditHolidayServiceApiFp(configuration).documents(documentsRequest, options)(axios, basePath)
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo(options?: any): AxiosPromise<InfoResponse> {
      return CreditHolidayServiceApiFp(configuration).getInfo(options)(axios, basePath)
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo1(options?: any): AxiosPromise<InfoResponse> {
      return CreditHolidayServiceApiFp(configuration).getInfo1(options)(axios, basePath)
    },
    /**
     *
     * @summary Возвращает информацию о кредитах клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo2(options?: any): AxiosPromise<InfoResponse> {
      return CreditHolidayServiceApiFp(configuration).getInfo2(options)(axios, basePath)
    },
    /**
     *
     * @summary Возвращает информацию о программах кредитных каникул
     * @param {string} loanId
     * @param {string} delay
     * @param {string} svcCodeOne
     * @param {string} [svcCodeTwo]
     * @param {string} [svcCodeThree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForPrograms(
      loanId: string,
      delay: string,
      svcCodeOne: string,
      svcCodeTwo?: string,
      svcCodeThree?: string,
      options?: any
    ): AxiosPromise<InfoForProgramsResponse> {
      return CreditHolidayServiceApiFp(configuration).getInfoForPrograms(
        loanId,
        delay,
        svcCodeOne,
        svcCodeTwo,
        svcCodeThree,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary Возвращает информацию о программах кредитных каникул
     * @param {string} loanId
     * @param {string} delay
     * @param {string} svcCodeOne
     * @param {string} [svcCodeTwo]
     * @param {string} [svcCodeThree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForPrograms1(
      loanId: string,
      delay: string,
      svcCodeOne: string,
      svcCodeTwo?: string,
      svcCodeThree?: string,
      options?: any
    ): AxiosPromise<InfoForProgramsResponse> {
      return CreditHolidayServiceApiFp(configuration).getInfoForPrograms1(
        loanId,
        delay,
        svcCodeOne,
        svcCodeTwo,
        svcCodeThree,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary Возвращает информацию о причинах запроса кредитных каникул
     * @param {string} [svcCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReasons(svcCode?: string, options?: any): AxiosPromise<ReasonResponse> {
      return CreditHolidayServiceApiFp(configuration).getReasons(svcCode, options)(axios, basePath)
    },
    /**
     *
     * @summary Возвращает информацию о причинах отказа на запрос кредитных каникул
     * @param {string} loanId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTextForArrears(loanId: string, options?: any): AxiosPromise<TextForArrearsResponse> {
      return CreditHolidayServiceApiFp(configuration).getTextForArrears(loanId, options)(axios, basePath)
    },
  }
}

/**
 * CreditHolidayServiceApi - object-oriented interface
 * @export
 * @class CreditHolidayServiceApi
 * @extends {BaseAPI}
 */
export class CreditHolidayServiceApi extends BaseAPI {
  /**
   *
   * @summary Активирует запрос кредитных каникул
   * @param {ActivationServiceRequestV2} activationServiceRequestV2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public activationService(activationServiceRequestV2: ActivationServiceRequestV2, options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).activationService(activationServiceRequestV2, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Активирует запрос кредитных каникул
   * @param {ActivationServiceRequest} activationServiceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public activationService1(activationServiceRequest: ActivationServiceRequest, options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).activationService1(activationServiceRequest, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Проверяет доступность запроса кредитных каникул
   * @param {CheckAvailabilityRequestV2} checkAvailabilityRequestV2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public checkAvailability(checkAvailabilityRequestV2: CheckAvailabilityRequestV2, options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).checkAvailability(checkAvailabilityRequestV2, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Проверяет доступность запроса кредитных каникул
   * @param {CheckAvailabilityRequest} checkAvailabilityRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public checkAvailability1(checkAvailabilityRequest: CheckAvailabilityRequest, options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).checkAvailability1(checkAvailabilityRequest, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Сохраняет докуенты на кредитные каникулы
   * @param {DocumentsRequest} documentsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public documents(documentsRequest: DocumentsRequest, options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).documents(documentsRequest, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Возвращает информацию о кредитах клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public getInfo(options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).getInfo(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Возвращает информацию о кредитах клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public getInfo1(options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).getInfo1(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Возвращает информацию о кредитах клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public getInfo2(options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).getInfo2(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Возвращает информацию о программах кредитных каникул
   * @param {string} loanId
   * @param {string} delay
   * @param {string} svcCodeOne
   * @param {string} [svcCodeTwo]
   * @param {string} [svcCodeThree]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public getInfoForPrograms(
    loanId: string,
    delay: string,
    svcCodeOne: string,
    svcCodeTwo?: string,
    svcCodeThree?: string,
    options?: any
  ) {
    return CreditHolidayServiceApiFp(this.configuration).getInfoForPrograms(
      loanId,
      delay,
      svcCodeOne,
      svcCodeTwo,
      svcCodeThree,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Возвращает информацию о программах кредитных каникул
   * @param {string} loanId
   * @param {string} delay
   * @param {string} svcCodeOne
   * @param {string} [svcCodeTwo]
   * @param {string} [svcCodeThree]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public getInfoForPrograms1(
    loanId: string,
    delay: string,
    svcCodeOne: string,
    svcCodeTwo?: string,
    svcCodeThree?: string,
    options?: any
  ) {
    return CreditHolidayServiceApiFp(this.configuration).getInfoForPrograms1(
      loanId,
      delay,
      svcCodeOne,
      svcCodeTwo,
      svcCodeThree,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Возвращает информацию о причинах запроса кредитных каникул
   * @param {string} [svcCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public getReasons(svcCode?: string, options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).getReasons(svcCode, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Возвращает информацию о причинах отказа на запрос кредитных каникул
   * @param {string} loanId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditHolidayServiceApi
   */
  public getTextForArrears(loanId: string, options?: any) {
    return CreditHolidayServiceApiFp(this.configuration).getTextForArrears(loanId, options)(this.axios, this.basePath)
  }
}
