/** send code errors */
const INTERNAL_ERROR = 1 as const
const BAD_REQUEST = 10 as const
// const INCORRECT_PHONE = 11 as const
// const ERROR_ON_SENDING = 16 as const
// const EMPTY_MESSAGE_FIELD = 17 as const
// const TEMPLATE_NOT_FOUND = 18 as const
// const BLACKLISTED_NUMBER = 19 as const

export const RESEND_LIMIT_EXCEEDED_ERROR = 20 as const
// const CODE_LENGTH_EXCEEDED = 22 as const

/** check code errors */
const INVALID_CODE_ERROR = 12 as const

export const INCORRECT_CODE_ERROR = 13 as const
const CODE_CHECK_EXCEED_ERROR = 14 as const
const CODE_LIFETIME_EXCEEDED_ERROR = 15 as const
const CODE_NOT_FOUND = 21 as const

export const defaultSendCodeErrorMapping = {
  [RESEND_LIMIT_EXCEEDED_ERROR]: 'Слишком много попыток. Попробуйте позже',
}

export const defaultCheckCodeErrorMapping = {
  ...[INTERNAL_ERROR, BAD_REQUEST, INVALID_CODE_ERROR, INCORRECT_CODE_ERROR, CODE_NOT_FOUND].reduce(
    (result, errorCode) => ({ ...result, [errorCode]: 'Введен неверный код' }),
    {}
  ),
  [CODE_CHECK_EXCEED_ERROR]: 'Слишком много попыток. Попробуйте позже',
  [CODE_LIFETIME_EXCEEDED_ERROR]: 'Срок подтверждения истек',
}
