import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ewalletApi } from '@api/ump/ewallet'
import { TransferBtoCardRq, TransferResponse, TransferCardToCardRq } from '@open-api/ump/ewallet'
import { isAxiosError } from '@root/types/reducers'
import { TransferState } from './types'
import { Options, ZoneRequest } from '../types'

const NAMESPACE = 'transfer'

const initialState: TransferState = {
  data: null,
  inProgress: false,
  error: null,
}

const transferBToCard = createAsyncThunk<TransferResponse, TransferBtoCardRq>(
  `${NAMESPACE}/transferBToCard`,
  async (params, { rejectWithValue }) => {
    try {
      const {
        userId,
        mdOrder,
        srcBindingId,
        cvc,
        targetPan,
        amount,
        currency,
        comment,
        dstPhone,
        masterpassOperationId,
        is3dsRequired,
      } = params

      const response = await ewalletApi.transferBindingToCard(
        userId,
        mdOrder,
        srcBindingId,
        cvc,
        targetPan,
        amount,
        currency,
        comment,
        dstPhone,
        masterpassOperationId,
        is3dsRequired
      )

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const transferCardToCard = createAsyncThunk<TransferResponse, TransferCardToCardRq & ZoneRequest & Options>(
  `${NAMESPACE}/transferCardToCard`,
  async (params, { rejectWithValue }) => {
    try {
      const {
        zone,
        userId,
        mdOrder,
        pan,
        expiry,
        cardholderName,
        cvc,
        targetPan,
        amount,
        currency,
        comment,
        srcPhone,
        dstPhone,
        masterpassOperationId,
        is3dsRequired,
        tokenizedTargetPan,
        options,
      } = params

      const response = await ewalletApi.transferCardToCard(
        zone,
        userId,
        mdOrder,
        pan,
        expiry,
        cardholderName,
        cvc,
        targetPan,
        amount,
        currency,
        comment,
        srcPhone,
        dstPhone,
        masterpassOperationId,
        is3dsRequired,
        tokenizedTargetPan,
        options
      )

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const transfer = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(transferBToCard.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(transferBToCard.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(transferBToCard.fulfilled, (state, action) => {
      state.data = action.payload
      state.inProgress = false
    })

    builder.addCase(transferCardToCard.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(transferCardToCard.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(transferCardToCard.fulfilled, (state, action) => {
      state.data = action.payload
      state.inProgress = false
    })
  },
})

export { transferBToCard, transferCardToCard }
export const transferReducer = transfer.reducer
