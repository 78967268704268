import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'formik'
import indexOf from 'lodash/fp/indexOf'

import { Text, theme } from '@mtsbank/ui-kit'
import { Binding } from '@open-api/ump/ewallet-profile'

import { RootState } from '@root/reducers'
import { selectBindings } from '@selectors/processing'
import { SelectField } from '@components/FormFields/SelectField'

import { SelectProps } from '@root/types/form/types'
import { validateField } from '@utils/formValidators/formValidators'

import { Content } from '@components/Content/Content'

import { OptionItem } from '@components/BindigsField/OptionItem'
import { MtsBankCardType } from '@root/types/bindings'
import { isNumber } from '@utils/format'

interface BindingsFieldProps extends SelectProps {
  initialValue: string
  options?: SelectProps['options']
  isHideNullBalance?: boolean
  isInMobileContainer?: boolean
  customHtmlField?: ReactNode // TODO: дать возможность прокидывать сверху отрендеренные опшенсы или customHtmlInput (необходимо для передачи не только биндингов, но и для "Новая карта")
  customOptions?: Binding[]
}

export const BindingsField: FC<BindingsFieldProps> = ({
  label,
  validators,
  initialValue,
  isHideNullBalance = false,
  onChange,
  options = [],
  isInMobileContainer,
  customHtmlField,
  customOptions,
  ...paymentSource
}) => {
  const data = useSelector<RootState, Binding[]>(selectBindings)

  const sortedData = [].concat(data).sort((a, b) => Number(b.balance) - Number(a.balance)) as Binding[]

  const bindings =
    customOptions ||
    (isHideNullBalance
      ? data.filter(({ balance }) => isNumber(Number(balance))).sort((a, b) => Number(b.balance) - Number(a.balance))
      : sortedData)
  const bindingsHint = {}

  const creditTypes = [MtsBankCardType.CREDIT_CARD, MtsBankCardType.VIRT_CREDIT_CARD]

  const nonCreditBindings = bindings.filter((binding) => {
    const cardType = binding.bindingParams?.MTS_BANK_CARD_TYPE

    return indexOf(cardType, creditTypes) === -1
  })

  const defaultBindingId = nonCreditBindings.length > 0 ? nonCreditBindings[0].bindingId : initialValue
  const [selectedBindingId, setBindingId] = useState(defaultBindingId)

  const bindingsOptions: SelectProps['options'] = bindings.map((binding) => {
    const { bindingId, bindingParams } = binding
    const cardType = bindingParams?.MTS_BANK_CARD_TYPE
    const creditTypes = [MtsBankCardType.CREDIT_CARD, MtsBankCardType.VIRT_CREDIT_CARD]

    if (indexOf(cardType, creditTypes) > -1) {
      bindingsHint[binding.bindingId] = cardType
    }

    return {
      value: bindingId,
      customOptionHtml: <OptionItem key={bindingId} binding={binding} isInMobileContainer={isInMobileContainer} />,
    }
  })

  const hint = bindingsHint[selectedBindingId] ? paymentSource.hint : ''

  const handleValidate = useCallback(
    (value: string) => {
      const bindingsSelected = bindings.find(({ bindingId }) => bindingId === value)
      const balance = bindingsSelected?.balance

      return validateField(balance, validators)
    },
    [bindings, validators]
  )

  const handleChange = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value)
      }

      setBindingId(value)
    },
    [onChange]
  )

  const selectedBinding = bindings.find((binding) => binding.bindingId === selectedBindingId)
  const CustomHtmlInput =
    (selectedBinding && <OptionItem binding={selectedBinding} isInMobileContainer={isInMobileContainer} />) ||
    customHtmlField

  useEffect(() => {
    handleChange(selectedBinding.bindingId)
  }, [])

  return (
    <>
      <Text size="md">{label}</Text>
      <Content.Margin top={theme.spacings.xs3} mobTop={theme.spacings.xs4}>
        <Field
          {...paymentSource}
          component={SelectField}
          hint={hint}
          // TODO вынести bindingsOptions в компонент где вызываем <BindingsField /> и в нем уже передеавать options
          options={[...bindingsOptions, ...options]}
          validate={handleValidate}
          onChange={handleChange}
          customHtmlInput={CustomHtmlInput}
        />
      </Content.Margin>
    </>
  )
}
