// tslint:disable
/**
 * Application
 * Owner: MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: undefined
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 * Информация кредитном счете
 * @export
 * @interface Account
 */
export interface Account {
  /**
   * Номер
   * @type {string}
   * @memberof Account
   */
  numAccount?: string
  /**
   * Валюта
   * @type {string}
   * @memberof Account
   */
  currencyAccount?: string
  /**
   * Текущий баланс на счете для погашения
   * @type {number}
   * @memberof Account
   */
  sumAccount?: number
}
/**
 *
 * @export
 * @interface AccountNumber
 */
export interface AccountNumber {
  /**
   * Номер счета
   * @type {string}
   * @memberof AccountNumber
   */
  accNum?: string
}
/**
 *
 * @export
 * @interface Arrest
 */
export interface Arrest {
  /**
   * ID ИП(исполнительного производства) из внешней системы (РБО)
   * @type {string}
   * @memberof Arrest
   */
  exextid?: string
  /**
   * Вид ИД (исполнительного дела)
   * @type {string}
   * @memberof Arrest
   */
  exType?: string
  /**
   * Номер Исполнительного дела
   * @type {string}
   * @memberof Arrest
   */
  exNumber?: string
  /**
   * Дата Исполнительного дела
   * @type {string}
   * @memberof Arrest
   */
  exDate?: string
  /**
   * Кем вынесено постановление
   * @type {string}
   * @memberof Arrest
   */
  issuedBy?: string
  /**
   * ФИО судебного пристава
   * @type {string}
   * @memberof Arrest
   */
  bailiffName?: string
  /**
   * Сумма из исполнительного дела
   * @type {number}
   * @memberof Arrest
   */
  exSum?: number
  /**
   * Неоплаченная сумма
   * @type {number}
   * @memberof Arrest
   */
  exDebt?: number
  /**
   * Внутренний код
   * @type {boolean}
   * @memberof Arrest
   */
  exFSSP?: boolean
  /**
   *
   * @type {Array<AccountNumber>}
   * @memberof Arrest
   */
  exAccounts?: Array<AccountNumber>
}
/**
 *
 * @export
 * @interface DiffParam
 */
export interface DiffParam {
  /**
   * Платёж для второго периода продукта с creditSubType = diff
   * @type {number}
   * @memberof DiffParam
   */
  diffPayment?: number
  /**
   * Ставка для второго периода продукта с creditSubType = diff
   * @type {number}
   * @memberof DiffParam
   */
  diffRate?: number
}
/**
 *
 * @export
 * @interface GetArrestRes
 */
export interface GetArrestRes {
  /**
   *
   * @type {Array<Arrest>}
   * @memberof GetArrestRes
   */
  arrests?: Array<Arrest>
}
/**
 *
 * @export
 * @interface GetClosedLoansResp
 */
export interface GetClosedLoansResp {
  /**
   * Массив закрытых кредитов пользователя
   * @type {Array<LoanClosed>}
   * @memberof GetClosedLoansResp
   */
  closedLoans?: Array<LoanClosed>
}
/**
 *
 * @export
 * @interface GetFullInfoRes
 */
export interface GetFullInfoRes {
  /**
   *
   * @type {string}
   * @memberof GetFullInfoRes
   */
  name?: string
  /**
   * Сумма задолженности
   * @type {number}
   * @memberof GetFullInfoRes
   */
  sum?: number
  /**
   * Валюта
   * @type {string}
   * @memberof GetFullInfoRes
   */
  currency?: string
  /**
   * Статус кредита
   * @type {string}
   * @memberof GetFullInfoRes
   */
  state?: string
  /**
   * Сегодня была успешная плановая операция
   * @type {boolean}
   * @memberof GetFullInfoRes
   */
  isLoanPayment?: boolean
  /**
   *
   * @type {NextPmtInfo}
   * @memberof GetFullInfoRes
   */
  nextPmtInfo?: NextPmtInfo
  /**
   * Код программы кредитования
   * @type {string}
   * @memberof GetFullInfoRes
   */
  credProgramCode?: string
  /**
   * Программа кредитования
   * @type {string}
   * @memberof GetFullInfoRes
   */
  credProgramName?: string
  /**
   *
   * @type {TypeOfProduct}
   * @memberof GetFullInfoRes
   */
  typeOfProduct?: TypeOfProduct
  /**
   * Номер договора
   * @type {string}
   * @memberof GetFullInfoRes
   */
  agreementNum?: string
  /**
   * Сумма по договору
   * @type {number}
   * @memberof GetFullInfoRes
   */
  sumContract?: number
  /**
   * Выплачено
   * @type {number}
   * @memberof GetFullInfoRes
   */
  sumContractPaid?: number
  /**
   * Осталось оплатить
   * @type {number}
   * @memberof GetFullInfoRes
   */
  sumContractLeft?: number
  /**
   * Дата заключения договора
   * @type {string}
   * @memberof GetFullInfoRes
   */
  startDt?: string
  /**
   * Срок кредита
   * @type {number}
   * @memberof GetFullInfoRes
   */
  termInMonth?: number
  /**
   * Окончание действия договора
   * @type {string}
   * @memberof GetFullInfoRes
   */
  endDt?: string
  /**
   * Процентная ставка
   * @type {number}
   * @memberof GetFullInfoRes
   */
  rate?: number
  /**
   * Дата ежемесячного платежа
   * @type {number}
   * @memberof GetFullInfoRes
   */
  date?: number
  /**
   *
   * @type {Account}
   * @memberof GetFullInfoRes
   */
  account?: Account
  /**
   *
   * @type {RepaymentInfo}
   * @memberof GetFullInfoRes
   */
  repaymentInfo?: RepaymentInfo
  /**
   * ФИО
   * @type {string}
   * @memberof GetFullInfoRes
   */
  FIO?: string
}
/**
 *
 * @export
 * @interface GetLoanScheduleResp
 */
export interface GetLoanScheduleResp {
  /**
   * Оплачено
   * @type {number}
   * @memberof GetLoanScheduleResp
   */
  debtPaid?: number
  /**
   * Осталось
   * @type {number}
   * @memberof GetLoanScheduleResp
   */
  debtLeft?: number
  /**
   * Массив с графиком платежей
   * @type {Array<LoanSchedule>}
   * @memberof GetLoanScheduleResp
   */
  loanSchedule?: Array<LoanSchedule>
}
/**
 *
 * @export
 * @interface GetLoansResp
 */
export interface GetLoansResp {
  /**
   *
   * @type {Array<LoanBrief>}
   * @memberof GetLoansResp
   */
  bankLoans?: Array<LoanBrief>
  /**
   *
   * @type {Array<object>}
   * @memberof GetLoansResp
   */
  linkedLoans?: Array<object>
  /**
   *
   * @type {Array<LoanIssue>}
   * @memberof GetLoansResp
   */
  issueLoans?: Array<LoanIssue>
}
/**
 *
 * @export
 * @interface GetRefinRestuctInfoResp
 */
export interface GetRefinRestuctInfoResp {
  /**
   *
   * @type {Summary}
   * @memberof GetRefinRestuctInfoResp
   */
  summary?: Summary
  /**
   * Кредиты
   * @type {Array<Loan>}
   * @memberof GetRefinRestuctInfoResp
   */
  loans?: Array<Loan>
}
/**
 *
 * @export
 * @interface GetScheduleStatementRes
 */
export interface GetScheduleStatementRes {
  /**
   * Документ в формате base64
   * @type {string}
   * @memberof GetScheduleStatementRes
   */
  file?: string
}
/**
 *
 * @export
 * @interface GraceLoanBalance
 */
export interface GraceLoanBalance {
  /**
   * Платёж для второго периода продукта с creditSubType = grace
   * @type {number}
   * @memberof GraceLoanBalance
   */
  balancePayment?: number
  /**
   * Ставка для второго периода продукта с creditSubType = grace
   * @type {number}
   * @memberof GraceLoanBalance
   */
  balanceRate?: number
}
/**
 * Кредиты
 * @export
 * @interface Loan
 */
export interface Loan {
  /**
   * ИД кредита в РБО
   * @type {string}
   * @memberof Loan
   */
  loanId?: string
  /**
   * Сумма договора
   * @type {number}
   * @memberof Loan
   */
  loanAmount?: number
  /**
   * Сумма задолженности
   * @type {number}
   * @memberof Loan
   */
  debtAmount?: number
  /**
   * Дата начала действия договора
   * @type {string}
   * @memberof Loan
   */
  startDt?: string
  /**
   * Дата окончания
   * @type {string}
   * @memberof Loan
   */
  endDt?: string
  /**
   * Сумма просрочки
   * @type {number}
   * @memberof Loan
   */
  loanOverdueAmt?: number
  /**
   * Ежемесячный платеж
   * @type {number}
   * @memberof Loan
   */
  monthlyPmt?: number
  /**
   * Номер договора
   * @type {string}
   * @memberof Loan
   */
  agreementNum?: string
  /**
   * Наименование программы кредитования
   * @type {string}
   * @memberof Loan
   */
  credProgramName?: string
  /**
   * Код программы кредитования
   * @type {string}
   * @memberof Loan
   */
  credProgramCode?: string
  /**
   * Признак карточного кредита
   * @type {boolean}
   * @memberof Loan
   */
  isCard?: boolean
}
/**
 *
 * @export
 * @interface LoanBrief
 */
export interface LoanBrief {
  /**
   * Название кредита
   * @type {string}
   * @memberof LoanBrief
   */
  name: string
  /**
   * ИД
   * @type {string}
   * @memberof LoanBrief
   */
  id: string
  /**
   * Статус
   * @type {string}
   * @memberof LoanBrief
   */
  state: string
  /**
   * Остаток ОД
   * @type {number}
   * @memberof LoanBrief
   */
  sum?: number
  /**
   * Валюта
   * @type {string}
   * @memberof LoanBrief
   */
  currency: string
  /**
   * Сумма договора
   * @type {string}
   * @memberof LoanBrief
   */
  loanAmt?: string
  /**
   * Номер карты
   * @type {string}
   * @memberof LoanBrief
   */
  cardId?: string
  /**
   *
   * @type {NextPmtInfo}
   * @memberof LoanBrief
   */
  nextPmtInfo?: NextPmtInfo
  /**
   * ИД связанного договора гашений
   * @type {string}
   * @memberof LoanBrief
   */
  repayAcc?: string
}
/**
 * Массив закрытых кредитов пользователя
 * @export
 * @interface LoanClosed
 */
export interface LoanClosed {
  /**
   * Идентификатор кредита
   * @type {string}
   * @memberof LoanClosed
   */
  loanId?: string
  /**
   * Имя кредита
   * @type {string}
   * @memberof LoanClosed
   */
  name?: string
  /**
   * Сумма долга
   * @type {number}
   * @memberof LoanClosed
   */
  sum?: number
  /**
   * Валюта
   * @type {string}
   * @memberof LoanClosed
   */
  currency?: string
}
/**
 *
 * @export
 * @interface LoanIssue
 */
export interface LoanIssue {
  /**
   * Сиквенс в issues
   * @type {string}
   * @memberof LoanIssue
   */
  id?: string
  /**
   * Сиквенс в issues
   * @type {string}
   * @memberof LoanIssue
   */
  issueId?: string
  /**
   * Код статуса во внутренней таблице сервиса
   * @type {string}
   * @memberof LoanIssue
   */
  statusCode?: string
  /**
   * Статус в Siebel
   * @type {string}
   * @memberof LoanIssue
   */
  statusSysName?: string
  /**
   * Статус
   * @type {string}
   * @memberof LoanIssue
   */
  state?: string
  /**
   * Сумма
   * @type {number}
   * @memberof LoanIssue
   */
  sum?: number
  /**
   * Период
   * @type {number}
   * @memberof LoanIssue
   */
  creditPeriod?: number
  /**
   * № заявки в Зибеле
   * @type {number}
   * @memberof LoanIssue
   */
  requestId?: number
  /**
   * Признак \"показывать промо\"
   * @type {boolean}
   * @memberof LoanIssue
   */
  showPromo?: boolean
  /**
   * Дата заявки
   * @type {string}
   * @memberof LoanIssue
   */
  issueDate?: string
  /**
   * Отображаемый статус
   * @type {string}
   * @memberof LoanIssue
   */
  statusDisplay?: string
  /**
   * Возможность дистанционной выдачи
   * @type {boolean}
   * @memberof LoanIssue
   */
  possIssueDBO?: boolean
  /**
   * Клиент согласен на доставку
   * @type {boolean}
   * @memberof LoanIssue
   */
  clientAppDeliveryInHand?: boolean
  /**
   * Ежемесячный платеж
   * @type {number}
   * @memberof LoanIssue
   */
  monthlyPayment?: number
  /**
   * Процентная ставка
   * @type {string}
   * @memberof LoanIssue
   */
  interestRate?: string
  /**
   * День платежа
   * @type {string}
   * @memberof LoanIssue
   */
  payDay?: string
  /**
   * Код получения в салоне МТС
   * @type {string}
   * @memberof LoanIssue
   */
  codeRTK?: string
  /**
   * Заголовок PopUp
   * @type {string}
   * @memberof LoanIssue
   */
  popUpTitle?: string
  /**
   * Текст PopUp
   * @type {string}
   * @memberof LoanIssue
   */
  popUpText?: string
  /**
   * Изображение PopUp
   * @type {string}
   * @memberof LoanIssue
   */
  popUpPicture?: string
  /**
   *
   * @type {DiffParam}
   * @memberof LoanIssue
   */
  diffParam?: DiffParam
  /**
   *
   * @type {GraceLoanBalance}
   * @memberof LoanIssue
   */
  graceLoanBalance?: GraceLoanBalance
  /**
   * Доступность калькулятора
   * @type {boolean}
   * @memberof LoanIssue
   */
  useCalc?: boolean
}
/**
 * Массив с графиком платежей
 * @export
 * @interface LoanSchedule
 */
export interface LoanSchedule {
  /**
   * Статус платежа
   * @type {string}
   * @memberof LoanSchedule
   */
  status?: string
  /**
   * Остаток ОД после платежа
   * @type {number}
   * @memberof LoanSchedule
   */
  debtAfter?: number
  /**
   * Сумма услуги SMS_LIGHT
   * @type {number}
   * @memberof LoanSchedule
   */
  smsLight?: number
  /**
   * Сумма услуги - \"Изменить дату платежа\"
   * @type {number}
   * @memberof LoanSchedule
   */
  createDateChange?: number
  /**
   * Информация об услугах
   * @type {Array<LoanScheduleService>}
   * @memberof LoanSchedule
   */
  services?: Array<LoanScheduleService>
  /**
   * Дата платежа
   * @type {string}
   * @memberof LoanSchedule
   */
  pmtDt: string
  /**
   * Общая сумма платежа
   * @type {number}
   * @memberof LoanSchedule
   */
  debtMounth: number
  /**
   * Сумма погашения ОД
   * @type {number}
   * @memberof LoanSchedule
   */
  debtMain: number
  /**
   * Сумма погашения процентов
   * @type {number}
   * @memberof LoanSchedule
   */
  debtPrc?: number
}
/**
 * Информация об услугах
 * @export
 * @interface LoanScheduleService
 */
export interface LoanScheduleService {
  /**
   * Наименование услуги
   * @type {string}
   * @memberof LoanScheduleService
   */
  name: string
  /**
   * Услуга
   * @type {string}
   * @memberof LoanScheduleService
   */
  service: string
  /**
   * Комиссия за услугу
   * @type {number}
   * @memberof LoanScheduleService
   */
  commission: number
}
/**
 *
 * @export
 * @interface MtsBankErrorInfo
 */
export interface MtsBankErrorInfo {
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorResponse
 */
export interface MtsBankErrorResponse {
  /**
   *
   * @type {MtsBankErrorInfo}
   * @memberof MtsBankErrorResponse
   */
  error?: MtsBankErrorInfo
}
/**
 *
 * @export
 * @interface NextPmtInfo
 */
export interface NextPmtInfo {
  /**
   * Дата платежа
   * @type {string}
   * @memberof NextPmtInfo
   */
  pmtDt?: string
  /**
   * Сумма платежа
   * @type {number}
   * @memberof NextPmtInfo
   */
  amount?: number
}
/**
 *
 * @export
 * @interface RepaymentInfo
 */
export interface RepaymentInfo {
  /**
   * Тип перерасчета суммы/срока кредита
   * @type {string}
   * @memberof RepaymentInfo
   */
  repaymentType?: string
  /**
   * Признак произвольной даты погашения для частичного досрочного погашения
   * @type {boolean}
   * @memberof RepaymentInfo
   */
  isAnyDate?: boolean
}
/**
 * Суммарная информация по кредитам клиента
 * @export
 * @interface Summary
 */
export interface Summary {
  /**
   * Общая сумма рефинансирования
   * @type {number}
   * @memberof Summary
   */
  totalAmount?: number
  /**
   * Сумма остаточной задолжности по всем договорам клиента
   * @type {number}
   * @memberof Summary
   */
  totalDebtAmount?: number
  /**
   * Сумма просроченной задолжности по всем договорам клиента
   * @type {number}
   * @memberof Summary
   */
  totalOverdueAmount?: number
  /**
   * Суммарный платеж по всем рефинансируемым кредитам
   * @type {number}
   * @memberof Summary
   */
  totalMonthlyPmt?: number
  /**
   * Статус доступности программы
   * @type {string}
   * @memberof Summary
   */
  statusCode?: string
  /**
   * Описание статуса доступности программы
   * @type {string}
   * @memberof Summary
   */
  statusDesc?: string
}
/**
 *
 * @export
 * @enum {string}
 */
export enum TypeOfProduct {
  NCPK = 'NCPK',
  POS = 'POS',
}

/**
 * NcpkProductSupportGatewayV1Api - axios parameter creator
 * @export
 */
export const NcpkProductSupportGatewayV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о наличии арестов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArrest(options: any = {}): RequestArgs {
      const localVarPath = `/ncpk-ps-gateway/v1/arrest`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Получение списка закрытых кредитов по клиенту.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClosedLoans(options: any = {}): RequestArgs {
      const localVarPath = `/ncpk-ps-gateway/v1/getClosedLoans`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает основную информацию по банковскому кредиту
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFullInfo(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getFullInfo.')
      }
      const localVarPath = `/ncpk-ps-gateway/v1/loans/{id}/fullInfo`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает график погашения по кредиту.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoanSchedule(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getLoanSchedule.')
      }
      const localVarPath = `/ncpk-ps-gateway/v1/loans/{id}/schedule`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Получение краткой информации по кредитам клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoans(options: any = {}): RequestArgs {
      const localVarPath = `/ncpk-ps-gateway/v1/loans`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает информацию о наличии кредитов доступных для рефинансирования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefinRestuctInfo(options: any = {}): RequestArgs {
      const localVarPath = `/ncpk-ps-gateway/v1/refinRestuctInfo`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает печатную форму графика платежей
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScheduleStatement(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getScheduleStatement.')
      }
      const localVarPath = `/ncpk-ps-gateway/v1/loans/{id}/statement`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * NcpkProductSupportGatewayV1Api - functional programming interface
 * @export
 */
export const NcpkProductSupportGatewayV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о наличии арестов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArrest(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetArrestRes> {
      const localVarAxiosArgs = NcpkProductSupportGatewayV1ApiAxiosParamCreator(configuration).getArrest(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Получение списка закрытых кредитов по клиенту.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClosedLoans(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClosedLoansResp> {
      const localVarAxiosArgs = NcpkProductSupportGatewayV1ApiAxiosParamCreator(configuration).getClosedLoans(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает основную информацию по банковскому кредиту
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFullInfo(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFullInfoRes> {
      const localVarAxiosArgs = NcpkProductSupportGatewayV1ApiAxiosParamCreator(configuration).getFullInfo(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает график погашения по кредиту.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoanSchedule(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoanScheduleResp> {
      const localVarAxiosArgs = NcpkProductSupportGatewayV1ApiAxiosParamCreator(configuration).getLoanSchedule(
        id,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Получение краткой информации по кредитам клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoans(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoansResp> {
      const localVarAxiosArgs = NcpkProductSupportGatewayV1ApiAxiosParamCreator(configuration).getLoans(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает информацию о наличии кредитов доступных для рефинансирования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefinRestuctInfo(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRefinRestuctInfoResp> {
      const localVarAxiosArgs =
        NcpkProductSupportGatewayV1ApiAxiosParamCreator(configuration).getRefinRestuctInfo(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает печатную форму графика платежей
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScheduleStatement(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScheduleStatementRes> {
      const localVarAxiosArgs = NcpkProductSupportGatewayV1ApiAxiosParamCreator(configuration).getScheduleStatement(
        id,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * NcpkProductSupportGatewayV1Api - factory interface
 * @export
 */
export const NcpkProductSupportGatewayV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает информацию о наличии арестов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArrest(options?: any): AxiosPromise<GetArrestRes> {
      return NcpkProductSupportGatewayV1ApiFp(configuration).getArrest(options)(axios, basePath)
    },
    /**
     *
     * @summary Получение списка закрытых кредитов по клиенту.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClosedLoans(options?: any): AxiosPromise<GetClosedLoansResp> {
      return NcpkProductSupportGatewayV1ApiFp(configuration).getClosedLoans(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает основную информацию по банковскому кредиту
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFullInfo(id: string, options?: any): AxiosPromise<GetFullInfoRes> {
      return NcpkProductSupportGatewayV1ApiFp(configuration).getFullInfo(id, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает график погашения по кредиту.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoanSchedule(id: string, options?: any): AxiosPromise<GetLoanScheduleResp> {
      return NcpkProductSupportGatewayV1ApiFp(configuration).getLoanSchedule(id, options)(axios, basePath)
    },
    /**
     *
     * @summary Получение краткой информации по кредитам клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoans(options?: any): AxiosPromise<GetLoansResp> {
      return NcpkProductSupportGatewayV1ApiFp(configuration).getLoans(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает информацию о наличии кредитов доступных для рефинансирования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefinRestuctInfo(options?: any): AxiosPromise<GetRefinRestuctInfoResp> {
      return NcpkProductSupportGatewayV1ApiFp(configuration).getRefinRestuctInfo(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает печатную форму графика платежей
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScheduleStatement(id: string, options?: any): AxiosPromise<GetScheduleStatementRes> {
      return NcpkProductSupportGatewayV1ApiFp(configuration).getScheduleStatement(id, options)(axios, basePath)
    },
  }
}

/**
 * NcpkProductSupportGatewayV1Api - object-oriented interface
 * @export
 * @class NcpkProductSupportGatewayV1Api
 * @extends {BaseAPI}
 */
export class NcpkProductSupportGatewayV1Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает информацию о наличии арестов клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NcpkProductSupportGatewayV1Api
   */
  public getArrest(options?: any) {
    return NcpkProductSupportGatewayV1ApiFp(this.configuration).getArrest(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получение списка закрытых кредитов по клиенту.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NcpkProductSupportGatewayV1Api
   */
  public getClosedLoans(options?: any) {
    return NcpkProductSupportGatewayV1ApiFp(this.configuration).getClosedLoans(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает основную информацию по банковскому кредиту
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NcpkProductSupportGatewayV1Api
   */
  public getFullInfo(id: string, options?: any) {
    return NcpkProductSupportGatewayV1ApiFp(this.configuration).getFullInfo(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает график погашения по кредиту.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NcpkProductSupportGatewayV1Api
   */
  public getLoanSchedule(id: string, options?: any) {
    return NcpkProductSupportGatewayV1ApiFp(this.configuration).getLoanSchedule(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получение краткой информации по кредитам клиента.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NcpkProductSupportGatewayV1Api
   */
  public getLoans(options?: any) {
    return NcpkProductSupportGatewayV1ApiFp(this.configuration).getLoans(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает информацию о наличии кредитов доступных для рефинансирования
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NcpkProductSupportGatewayV1Api
   */
  public getRefinRestuctInfo(options?: any) {
    return NcpkProductSupportGatewayV1ApiFp(this.configuration).getRefinRestuctInfo(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает печатную форму графика платежей
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NcpkProductSupportGatewayV1Api
   */
  public getScheduleStatement(id: string, options?: any) {
    return NcpkProductSupportGatewayV1ApiFp(this.configuration).getScheduleStatement(id, options)(
      this.axios,
      this.basePath
    )
  }
}
