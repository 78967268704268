import {
  OpenDepositApi,
  DepositPromocodeApi,
  DepositDetailsApi,
  CloseDepositApi,
} from '@open-api/ump/invest-deposits-deposit-v1'

export const openDepositApi = new OpenDepositApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const depositPromoCodeApi = new DepositPromocodeApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const investDepositsDepositApiV1 = new DepositDetailsApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const closeDepositApi = new CloseDepositApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})
