import { ConvertSelectedBindingLabel } from '@components/BindigsField/types'

const MAX_LENGTH_SELECTED_MNEMONIC = 16
const MAX_LENGTH_ALL_LABEL = 19

export const convertSelectedBindingLabel: ConvertSelectedBindingLabel = ({ maskedPan, mnemonic }) => {
  let convertedMnemonic = ''
  const lastFourNumbers = maskedPan ? maskedPan.substring(maskedPan.length - 4) : ''
  const convertedMaskedPan = `${lastFourNumbers && ` \u00b7\u00b7 ${lastFourNumbers}`}`
  const isNeedCropMnemonic = mnemonic.length > MAX_LENGTH_ALL_LABEL

  if (isNeedCropMnemonic) {
    convertedMnemonic = `${mnemonic.slice(0, MAX_LENGTH_SELECTED_MNEMONIC).trim()}...`
  } else {
    convertedMnemonic = mnemonic
  }

  return `${convertedMnemonic}${convertedMaskedPan}`
}
