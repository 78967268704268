export const POINT = '\u00B7'

export const excludedProducts = [
  'premier',
  'supreme',
  '81_UP_Classic_Premium',
  '81_UP_Platinum_Premium',
  '81_UP_Platinum_Premium_EUR',
  '81_UP_Platinum_Premium_USD',
  '81_WBE_Premium',
  '69_MC_WBE_Премиум',
  '69_UP_Classic_Premium',
  '69_UP_Platinum_Premium',
] as const
