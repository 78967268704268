import { actionGroups, eventActions } from '../baseFunctions'
import { ConfigParamsByEvent, DataLayerEvents } from './types'

export const configParamsByEvent: ConfigParamsByEvent = {
  [DataLayerEvents.TABBAR_SHOW]: {
    eventAction: eventActions.elementShow,
    actionGroup: actionGroups.nonInteractions,
  },
  [DataLayerEvents.TABBAR_PRODUCT_SHOW]: {
    eventAction: eventActions.buttonShow,
    actionGroup: actionGroups.nonInteractions,
  },
  [DataLayerEvents.TABBAR_PRODUCT_SEEING]: {
    eventAction: eventActions.buttonView,
    actionGroup: actionGroups.nonInteractions,
  },
  [DataLayerEvents.TABBAR_PRODUCT_CLICK]: {
    eventAction: eventActions.buttonClick,
    actionGroup: actionGroups.interactions,
  },
}
