import { creditCardType } from '@utils/creditCardType/lib/creditCardType'
import { CardType } from '@utils/creditCardType/type'

const ONE_POSSIBLE_OPTION = 1
const MIN_FILLED_NUMBERS = 8

export const getCardType = (value: string) => {
  const cardNumbers = value.replace(/\s/g, '')

  const cardType = creditCardType(cardNumbers).filter(
    (card) =>
      card.type === CardType.MASTERCARD ||
      card.type === CardType.VISA ||
      card.type === CardType.MAESTRO ||
      card.type === CardType.MIR
  )

  if (cardType.length === ONE_POSSIBLE_OPTION && cardNumbers.length >= MIN_FILLED_NUMBERS) {
    return cardType[0].type
  }

  return null
}
