import { LoanIssueControllerV2Api, LoanIssueControllerV1Api, LoanIssueApi } from '@open-api/ump/loan-issue'

export const loanIssueApi = new LoanIssueApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const loanIssueV2Api = new LoanIssueControllerV2Api({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const loanIssueV1Api = new LoanIssueControllerV1Api({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})
