import React from 'react'
import Script from 'next/script'
import Head from 'next/head'
import { authHelper } from '@utils/authHelper/AuthHelper'

export const YaContextCb = () => {
  if (authHelper.isAuthenticated()) {
    return null
  }

  return (
    <>
      <Head>
        <script id="yaContextCb-window" type="text/javascript">
          window.yaContextCb = window.yaContextCb || []
        </script>
        <script async id="yaContextCb-src" type="text/javascript" src="https://yandex.ru/ads/system/context.js" />
      </Head>
      {/* <!--AdFox START-->
          <!--yandex_asovga@mtsbank.ru-->
          <!--Площадка: МТС Банк / промо блок / промо блок-->
          <!--Категория: <не задана>-->
          <!--Тип баннера: Перетяжка 100%--> */}
      <div id="adfox_170660399047312854" />
      <Script id="yaContextCb-script" type="text/javascript">
        {`window.yaContextCb.push(()=>{
            Ya.adfoxCode.create({
              ownerId: 3730095,
              containerId: 'adfox_170660399047312854',
              params: {
                p1: 'daxcx',
                p2: 'y'
              }
            })
          })`}
      </Script>
    </>
  )
}
