import { OnboardingModelResponse } from '@open-api/ump/onboarding'
import { DeviceType } from '@root/utils/getPlatorm'

export enum OnboardingPlaces {
  BEFORE_MAIN_SCREEN = 'before_the_main_screen',
  BEFORE_THE_LOAN_SCREEN = 'before_the_loan_screen',
  BEFORE_THE_CARD_SCREEN = 'before_the_card_screen',
  AFTER_AUTHORIZATION = 'after_authorization',
}

enum PlatformType {
  WEB_ANDROID = 'WEB_ANDROID',
  WEB_IOS = 'WEB_IOS',
  DESKTOP = 'WEB_DESKTOP',
}

export const devicePlatforms = {
  [DeviceType.IOS]: PlatformType.WEB_IOS,
  [DeviceType.ANDROID]: PlatformType.WEB_ANDROID,
  [DeviceType.OTHER]: PlatformType.DESKTOP,
} as const

export type SlideInfoType = OnboardingModelResponse['slides'][number]

export type ClickableAreaProps = {
  left?: boolean
  right?: boolean
}

/**
 * все возможные типы слайдов онбордингов, в первой итерации поддержка только для basic_light
 */
export enum TemplateTypes {
  BASIC_LIGHT = 'basic_light',
  CHECK_BOX = 'check_box',
  QUIZ = 'quiz',
}

/**
 * @property color Шестизначный HEX-код цвета, который будет являться фоном слайда (Формат: #FFFFFF)
 */
export type BackgroundParams = {
  color: string
}

/**
 * @property image Данному параметру соответствует основная картинка слайда, которая располагается по центру.
 * *  пример: https://api-test.mtsbank.ru/pl-onboarding/v1/slides/47/views/2/params/2
 */
export type MainImageParams = {
  image: string
}

/**
 * @property text Текст заголовка слайда.
 * @property text_color Цвет шрифта заголовка слайда. Шестизначный HEX-код цвета (Формат: #FFFFFF)
 */
export type TitleParams = {
  text: string
  text_color: string
}

/**
 * @property big_text Текст заголовка слайда.
 * @property text_color Шестизначный HEX-код цвета, который будет являться фоном слайда (Формат: #FFFFFF)
 */
export type MainTextParams = {
  big_text: string
  text_color: string
}

/**
 * @property text Текст кнопки внизу экрана.
 * @property action
 * * next Переход к следующему слайду
 * * close Закрытие онбординга (Возвращение на экран, с которого был открыт онбординг)
 * * go_by_deeplink Переход на другой экран по диплинку
 * @property deeplink Значение данного параметра только в случае  action: deeplink. Поле заполняется диплинком, который уводит пользователя в целевой сценарий.
 */
export type BottomButtonParams = {
  text: string
  action: 'next' | 'close' | 'go_by_deeplink'
  deeplink?: string
}

/**
 * @property cross_to_close Крестик для закрытия экрана (Возвращение на экран, с которого был открыт онбординг)
 */
export type BasicLightViewsType = [
  { viewName: 'background'; params: BackgroundParams },
  { viewName: 'main_image'; params: MainImageParams },
  { viewName: 'title'; params: TitleParams },
  { viewName: 'main_text'; params: MainTextParams }?,
  { viewName: 'bottom_button'; params: BottomButtonParams }?,
  { viewName: 'cross_to_close'; params: undefined }?
]

type ParamsBasicLight = {
  background: BackgroundParams
  main_image: MainImageParams
  title: TitleParams
  main_text?: MainTextParams
  bottom_button?: BottomButtonParams
  cross_to_close?: undefined
}

export type ParsedSlideInfoType = TemplateTypes.BASIC_LIGHT extends infer T
  ? T extends TemplateTypes.BASIC_LIGHT
    ? {
        [K in keyof ParamsBasicLight]: { params?: ParamsBasicLight[K] }
      }
    : never
  : never
