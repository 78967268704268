import { TBanner, TImageProps } from '@mtsbank/common'

export enum LoanStatus {
  STOP = 'Stop',
  NO_PROBLEM = 'NoProblem',
  DEFERMENT = 'Deferment',
  DEFERMENT377 = 'Deferment_377FL',
}

export enum ButtonType {
  NEXT_STEP = 'nextStep',
  MAIN_PAGE = 'mainPage',
  SEND = 'send',
  CONFIRMATION = 'confirmation',
}

export enum HolidaysFieldsLabels {
  ActivationDate = 'Дата активации программы',
  EmergencyTermMobile = 'Выберите срок',
  EmergencyTerm = 'Срок',
  StartInSVO = 'Дата начала участия в\u00a0СВО',
  YearIncome = 'Среднемесячный доход за\u00a0год',
  MonthIncome = 'Доход за\u00a02\u00a0месяца',
  TypeOfDocument = 'Тип документа',
}

export enum HolidaysProgramsName {
  'KK.353' = 'Льготный период по\u00a0закону \u2116\u00a0353-ФЗ',
  'KK.SVO' = 'Льготный период для участников CВО по\u00a0закону \u2116\u00a0377-ФЗ',
  'KK.MTSB' = 'Отсрочка платежа',
}

export interface SvoFormValue {
  term: number
  activationDateSVO?: Date
  startInSVO?: Date
  svoDoc: string
}

export interface FZ353FormValues {
  startDate: string
  term: string
  termFromProgram: number
  typeOfDocument: string
  attachedDocument: File[]
  emergencyTerm: string
  reducedIncomeTerm: number
  activationDate: Date
  activationDateEmergency: Date
  reasonCode: string
  monthIncome: number
  yearIncome: number
  reason: string
}

export interface QRImage {
  imgUrl: string
  altText: string
  fallbackImgExt: string
}

export interface QRList {
  id: number
  text: string
}

export interface QRSection {
  image: QRImage
  title: string
  subtitle: string
  list: QRList[]
}

export interface Bullet {
  id: string
  text: string
}

export interface ListItemsWithHeading {
  title: string
  steps: Bullet[]
}

export interface InformationHint {
  icon: string
  text: string
}

export interface Condition {
  title: string
  text: string
}

export interface ListData {
  id: number
  text: string
  title?: string
}

export interface AboutMaxSumModal {
  modalTitle: string
  modalSubtutle: string
  creditTypes: {
    id: string
    text: string
  }[]
  mortgageTypes: {
    id: string
    title: string
  }[]
}

export interface ConditionsData {
  id: number
  title: string
  value: string
  linkText?: string
  hasButton: boolean
  icon?: string
}

export interface InfoSection {
  title: string
  subtitle: string
  conditions: ConditionsData[]
  titleList: string
  listData: ListData[]
  svoNoteLink: string
  svoNote: string
}

export interface IGracePeriodFZ353 {
  title: string
  subTitle: string
  issueInfo: ListItemsWithHeading
  refusalReasons: ListItemsWithHeading
  programAvailability: ListItemsWithHeading
  informationHint: InformationHint
  conditions: Condition
  aboutMaxSumModal: AboutMaxSumModal
  imgDeskSm: TImageProps
  imgDesk: TImageProps
}

export interface CreditHolidaysPageData {
  banner: TBanner
  creditHolidays: {
    title: string
    gracePeriodFZ353: IGracePeriodFZ353
    infoSection: InfoSection
    contacts: string
    contactsInfo: string
    contactsInfoIcon: string
    formsPath: string
    qrSection?: QRSection
  }
}

export interface CreditLoanData {
  sumContract?: string | number
  sumContractLeft?: string | number
  startDt?: string
  endDt?: string
  agreementNum?: string
  checked?: boolean
  status?: string
  creditType?: string
  statusDescription?: string
  statusDetails?: string
}

export enum ECreditHolidaysStepName {
  LENDING = 'lending',
  CHOOSE_CREDIT = 'vyberite_kredit',
  AVAILABLE_PROGRAMS = 'dostypnye_programmy',
  PROGRAM_377FZ = 'programma_377-FZ',
  PROGRAM_353FZ = 'programma_353-FZ',
  SUCCESS = 'success',
  REJECT = 'reject',
  ERROR = 'error',
}
