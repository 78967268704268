import axios, { AxiosResponse } from 'axios'
import { BaseResponse, ConfirmTransferRes } from '@open-api/ump/transfer-by-phone'
import { InitialTransferRes } from './types'

// рабочий номер 9376162332

// 460
// code: 1107
// message: "Отсутствует финансовый инструмент для получения платежа"
// uuid: "a3592d37-cdf2-4688-b79a-a66677c26dd7"

// хттп 460
// code: 1011
// message: "Сервис временно недоступен. Попробуйте позже"
// uuid: "a3592d37-cdf2-4688-b79a-a66677c26dd7"

// хттп 200
// isRecipientMtsBankClient: true
// mtsBankClientPAM: "Мария В."
// sbpBanksList: []

// хттп 460
//  {
//     "code": 1117,
//     "message": "Клиент не найден",
//     "uuid": "a3592d37-cdf2-4688-b79a-a66677c26dd7"
// }

export const fetchInitiateTransferApi = (transferId: string): Promise<AxiosResponse<InitialTransferRes>> =>
  axios.post(`${process.env.NEXT_PUBLIC_UMP_HOST}/transfers-byphone/v1/initiateTransfer`, { transferId })

export const fetchConfirmTransferApi = (
  transferId: string,
  otpCode: string
): Promise<AxiosResponse<ConfirmTransferRes>> =>
  axios.post(`${process.env.NEXT_PUBLIC_UMP_HOST}/transfers-byphone/v1/confirmTransfer`, { transferId, otpCode })

export const fetchResendOtp = (transferId: string): Promise<AxiosResponse<BaseResponse>> =>
  axios.post(`${process.env.NEXT_PUBLIC_UMP_HOST}/transfers-byphone/v1/resendOtp`, { transferId })
