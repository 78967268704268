import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@root/reducers'

const getAuthState = (state: RootState) => state.auth
const getSsoConfigState = (state: RootState) => state.config

export const authSelector = createSelector(getAuthState, (authState) => authState)
export const profileSelector = createSelector(getAuthState, (authState) => authState.profile)
export const mobilePhoneSelector = createSelector(getAuthState, (authState) => authState.profile?.mobilePhone)
export const authTypeSelector = createSelector(getAuthState, (authState) => authState.profile?.authType)

export const ssoConfigSelector = createSelector(getSsoConfigState, (configState) => configState.ssoConfig)
