import { actionGroups, eventActions, eventLabels, events, sendGmtEventPerPage } from '../baseFunctions'
import { translit } from '../translit'

/** Показ ошибки  */
export const showErrorGtm = (text: string) =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.errorShow,
    eventLabel: translit(text).toLowerCase(),
    actionGroup: actionGroups.interactions,
    eventContent: translit(text).toLowerCase(),
  })

/** Клик по кнопке повторить в popup ошибки */
export const repeatClickGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.povtorit,
    actionGroup: actionGroups.interactions,
  })
