import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { InitialValues } from '@reducers/premiumPackage/types'
import { baseInitialState } from '@root/fetches/baseInitialState'
import { premiumInfoApiV8 } from '@api/ump/premium-clients-info'

const NAMESPACE = 'premium'

const initialState: InitialValues = {
  package: {
    ...baseInitialState,
  },
}

export const fetchIsPremiumPackage = createAsyncThunk(`${NAMESPACE}/fetchIsPremiumPackage`, async () => {
  const { data } = await premiumInfoApiV8.isPremium()

  return data
})

const premiumPackage = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIsPremiumPackage.pending, (state) => {
      state.package.data = {}
      state.package.inProgress = true
      state.package.error = null
    })
    builder.addCase(fetchIsPremiumPackage.rejected, (state, action) => {
      state.package.data = {}
      state.package.error = action.error
      state.package.inProgress = false
    })
    builder.addCase(fetchIsPremiumPackage.fulfilled, (state, action) => {
      state.package.data = action.payload
      state.package.inProgress = false
      state.package.error = null
    })
  },
})

export const premiumPackageReducer = premiumPackage.reducer
