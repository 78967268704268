export enum MenuLevel {
  ROOT = 0,
  FIRST = 1,
}

export interface SideMenuContextProps {
  menuLevel: {
    level: MenuLevel
  }
  setMenuLevel: (value: { level: MenuLevel }) => void
  menuOpened: boolean
  setMenuOpened: (value: boolean) => void
}
