import styled from 'styled-components';
import { theme } from '@mtsbank/ui-kit';
import { CustomNotification } from '../CustomNotification';
export const CardWrapper = styled.div`
  padding: ${theme.spacings.xs}px;

  &:not(:last-child) {
    border-bottom: ${theme.colors.neutral.g700} 1px solid;
  }
`;
export const StyledNotification = styled(CustomNotification)`
  display: flex;
  align-items: center;
  padding: ${theme.spacings.xs3}px ${theme.spacings.xs2}px;
  margin-top: ${theme.spacings.xs}px;
  cursor: pointer;

  div:last-of-type {
    flex: 1;
  }
`;
export const NotificationContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;