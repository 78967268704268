// tslint:disable
/**
 * Регистрация заявки на получение POS кредита (dbo-pos-issue)
 * Owner: POS Credit Team
 *
 * The version of the OpenAPI document: undefined
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface CalcPaymentRequest
 */
export interface CalcPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof CalcPaymentRequest
   */
  sum: number
  /**
   *
   * @type {number}
   * @memberof CalcPaymentRequest
   */
  term: number
  /**
   *
   * @type {number}
   * @memberof CalcPaymentRequest
   */
  rate: number
}
/**
 *
 * @export
 * @interface CalcPaymentResponse
 */
export interface CalcPaymentResponse {
  /**
   *
   * @type {string}
   * @memberof CalcPaymentResponse
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof CalcPaymentResponse
   */
  payment?: number
}
/**
 *
 * @export
 * @interface Card
 */
export interface Card {
  /**
   *
   * @type {string}
   * @memberof Card
   */
  cardId?: string
}
/**
 *
 * @export
 * @interface CardDto
 */
export interface CardDto {
  /**
   *
   * @type {string}
   * @memberof CardDto
   */
  cardId?: string
  /**
   *
   * @type {string}
   * @memberof CardDto
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof CardDto
   */
  paymentSystem?: string
  /**
   *
   * @type {string}
   * @memberof CardDto
   */
  name?: string
}
/**
 *
 * @export
 * @interface Consent
 */
export interface Consent {
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowProccesPersDataBIO: boolean
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowPassBKI: boolean
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowReceiveInfoFromBank: boolean
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowPassPFR: boolean
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowCollectThirdPerson: boolean
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowCollectOtherInfo: boolean
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowCessionThirdPerson: boolean
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowCollectSignNotary: boolean
  /**
   *
   * @type {boolean}
   * @memberof Consent
   */
  allowCardIssue?: boolean
}
/**
 *
 * @export
 * @interface CreateOrderDtoV1
 */
export interface CreateOrderDtoV1 {
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV1
   */
  sum: number
  /**
   *
   * @type {string}
   * @memberof CreateOrderDtoV1
   */
  purpose?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderDtoV1
   */
  purposeTranscript?: string
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV1
   */
  term: number
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV1
   */
  rate: number
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV1
   */
  payment: number
  /**
   *
   * @type {Consent}
   * @memberof CreateOrderDtoV1
   */
  consents: Consent
  /**
   *
   * @type {Card}
   * @memberof CreateOrderDtoV1
   */
  card: Card
}
/**
 *
 * @export
 * @interface CreateOrderDtoV2
 */
export interface CreateOrderDtoV2 {
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV2
   */
  sum: number
  /**
   *
   * @type {string}
   * @memberof CreateOrderDtoV2
   */
  purpose?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderDtoV2
   */
  purposeTranscript?: string
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV2
   */
  term: number
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV2
   */
  rate: number
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV2
   */
  payment: number
  /**
   *
   * @type {Consent}
   * @memberof CreateOrderDtoV2
   */
  consents: Consent
  /**
   *
   * @type {number}
   * @memberof CreateOrderDtoV2
   */
  income?: number
  /**
   *
   * @type {boolean}
   * @memberof CreateOrderDtoV2
   */
  identification?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateOrderDtoV2
   */
  source?: string
  /**
   *
   * @type {Card}
   * @memberof CreateOrderDtoV2
   */
  card?: Card
}
/**
 *
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
  /**
   *
   * @type {number}
   * @memberof DocumentDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof DocumentDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DocumentDto
   */
  extension?: string
  /**
   *
   * @type {number}
   * @memberof DocumentDto
   */
  size?: number
}
/**
 *
 * @export
 * @interface IncompleteOrderDtoV1
 */
export interface IncompleteOrderDtoV1 {
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV1
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof IncompleteOrderDtoV1
   */
  issueId?: number
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV1
   */
  status?: IncompleteOrderDtoV1StatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum IncompleteOrderDtoV1StatusEnum {
  QUEUED = 'QUEUED',
  QUEUINGERROR = 'QUEUING_ERROR',
  QUEUEDDEADLINEERROR = 'QUEUED_DEADLINE_ERROR',
  WAITSIGN = 'WAIT_SIGN',
  WAITSIGNDEADLINEERROR = 'WAIT_SIGN_DEADLINE_ERROR',
  CANCELED = 'CANCELED',
  BANKREFUSE = 'BANK_REFUSE',
  REQCLOSEDCREDITISSUED = 'REQ_CLOSED_CREDIT_ISSUED',
  EXPECTIDENTIFICATION = 'EXPECT_IDENTIFICATION',
}

/**
 *
 * @export
 * @interface IncompleteOrderDtoV2
 */
export interface IncompleteOrderDtoV2 {
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof IncompleteOrderDtoV2
   */
  issueId?: number
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  status?: IncompleteOrderDtoV2StatusEnum
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  statusName?: string
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  productCode?: string
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  reqAmount?: string
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  amount?: string
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  reqTerm?: string
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  term?: string
  /**
   *
   * @type {string}
   * @memberof IncompleteOrderDtoV2
   */
  closeDate?: string
}

/**
 * @export
 * @enum {string}
 */
export enum IncompleteOrderDtoV2StatusEnum {
  QUEUED = 'QUEUED',
  QUEUINGERROR = 'QUEUING_ERROR',
  QUEUEDDEADLINEERROR = 'QUEUED_DEADLINE_ERROR',
  WAITSIGN = 'WAIT_SIGN',
  WAITSIGNDEADLINEERROR = 'WAIT_SIGN_DEADLINE_ERROR',
  CANCELED = 'CANCELED',
  BANKREFUSE = 'BANK_REFUSE',
  REQCLOSEDCREDITISSUED = 'REQ_CLOSED_CREDIT_ISSUED',
  EXPECTIDENTIFICATION = 'EXPECT_IDENTIFICATION',
}

/**
 *
 * @export
 * @interface InternalOrderInfoDto
 */
export interface InternalOrderInfoDto {
  /**
   *
   * @type {number}
   * @memberof InternalOrderInfoDto
   */
  sum?: number
}
/**
 *
 * @export
 * @interface LoanOrderDto
 */
export interface LoanOrderDto {
  /**
   *
   * @type {string}
   * @memberof LoanOrderDto
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof LoanOrderDto
   */
  sum?: string
  /**
   *
   * @type {string}
   * @memberof LoanOrderDto
   */
  term?: string
  /**
   *
   * @type {string}
   * @memberof LoanOrderDto
   */
  payment?: string
  /**
   *
   * @type {string}
   * @memberof LoanOrderDto
   */
  rate?: string
  /**
   *
   * @type {string}
   * @memberof LoanOrderDto
   */
  paymentDay?: string
  /**
   *
   * @type {string}
   * @memberof LoanOrderDto
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof LoanOrderDto
   */
  partnerName?: string
  /**
   *
   * @type {CardDto}
   * @memberof LoanOrderDto
   */
  card?: CardDto
  /**
   *
   * @type {InternalOrderInfoDto}
   * @memberof LoanOrderDto
   */
  requestedInfo?: InternalOrderInfoDto
  /**
   *
   * @type {Array<DocumentDto>}
   * @memberof LoanOrderDto
   */
  docs?: Array<DocumentDto>
}
/**
 *
 * @export
 * @interface MtsBankErrorInfo
 */
export interface MtsBankErrorInfo {
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorResponse
 */
export interface MtsBankErrorResponse {
  /**
   *
   * @type {MtsBankErrorInfo}
   * @memberof MtsBankErrorResponse
   */
  error?: MtsBankErrorInfo
}
/**
 *
 * @export
 * @interface OrderCreatedDto
 */
export interface OrderCreatedDto {
  /**
   *
   * @type {string}
   * @memberof OrderCreatedDto
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof OrderCreatedDto
   */
  issueId?: number
}
/**
 *
 * @export
 * @interface OrderDocumentDto
 */
export interface OrderDocumentDto {
  /**
   *
   * @type {string}
   * @memberof OrderDocumentDto
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof OrderDocumentDto
   */
  docData?: string
}
/**
 *
 * @export
 * @interface OrderStateDto
 */
export interface OrderStateDto {
  /**
   *
   * @type {string}
   * @memberof OrderStateDto
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof OrderStateDto
   */
  status?: OrderStateDtoStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrderStateDto
   */
  statusDesc?: string
  /**
   *
   * @type {string}
   * @memberof OrderStateDto
   */
  closeDate?: string
  /**
   *
   * @type {string}
   * @memberof OrderStateDto
   */
  sum?: string
}

/**
 * @export
 * @enum {string}
 */
export enum OrderStateDtoStatusEnum {
  QUEUED = 'QUEUED',
  QUEUINGERROR = 'QUEUING_ERROR',
  QUEUEDDEADLINEERROR = 'QUEUED_DEADLINE_ERROR',
  WAITSIGN = 'WAIT_SIGN',
  WAITSIGNDEADLINEERROR = 'WAIT_SIGN_DEADLINE_ERROR',
  CANCELED = 'CANCELED',
  BANKREFUSE = 'BANK_REFUSE',
  REQCLOSEDCREDITISSUED = 'REQ_CLOSED_CREDIT_ISSUED',
  EXPECTIDENTIFICATION = 'EXPECT_IDENTIFICATION',
}

/**
 *
 * @export
 * @interface SmsSendingDto
 */
export interface SmsSendingDto {
  /**
   *
   * @type {number}
   * @memberof SmsSendingDto
   */
  issueId: number
  /**
   *
   * @type {string}
   * @memberof SmsSendingDto
   */
  statusCode: SmsSendingDtoStatusCodeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum SmsSendingDtoStatusCodeEnum {
  CHECKCODE = 'CHECK_CODE',
  ASPSIGNEDBYCLIENT = 'ASP_SIGNED_BY_CLIENT',
  ASPREQUESTNEWCODE = 'ASP_REQUEST_NEW_CODE',
}

/**
 *
 * @export
 * @interface SmsSigningDto
 */
export interface SmsSigningDto {
  /**
   *
   * @type {number}
   * @memberof SmsSigningDto
   */
  issueId: number
  /**
   *
   * @type {string}
   * @memberof SmsSigningDto
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof SmsSigningDto
   */
  email?: string
}

/**
 * LoanOrderControllerV1Api - axios parameter creator
 * @export
 */
export const LoanOrderControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод отменяет заявку на POS кредит
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling cancel.')
      }
      const localVarPath = `/dbo-pos-issue/v1/orders/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод создает заявку на POS кредит в Siebel
     * @param {CreateOrderDtoV1} createOrderDtoV1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1(createOrderDtoV1: CreateOrderDtoV1, options: any = {}): RequestArgs {
      // verify required parameter 'createOrderDtoV1' is not null or undefined
      if (createOrderDtoV1 === null || createOrderDtoV1 === undefined) {
        throw new RequiredError(
          'createOrderDtoV1',
          'Required parameter createOrderDtoV1 was null or undefined when calling create1.'
        )
      }
      const localVarPath = `/dbo-pos-issue/v1/orders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof createOrderDtoV1 !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createOrderDtoV1 !== undefined ? createOrderDtoV1 : {})
        : createOrderDtoV1 || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает финальные параметры предложения
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling fetch.')
      }
      const localVarPath = `/dbo-pos-issue/v1/orders/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает незавершенные заявки на оформление pos кредита
     * @param {Array<'QUEUED' | 'QUEUING_ERROR' | 'QUEUED_DEADLINE_ERROR' | 'WAIT_SIGN' | 'WAIT_SIGN_DEADLINE_ERROR' | 'CANCELED' | 'BANK_REFUSE' | 'REQ_CLOSED_CREDIT_ISSUED' | 'EXPECT_IDENTIFICATION'>} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    incomplete(
      status: Array<
        | 'QUEUED'
        | 'QUEUING_ERROR'
        | 'QUEUED_DEADLINE_ERROR'
        | 'WAIT_SIGN'
        | 'WAIT_SIGN_DEADLINE_ERROR'
        | 'CANCELED'
        | 'BANK_REFUSE'
        | 'REQ_CLOSED_CREDIT_ISSUED'
        | 'EXPECT_IDENTIFICATION'
      >,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'status' is not null or undefined
      if (status === null || status === undefined) {
        throw new RequiredError('status', 'Required parameter status was null or undefined when calling incomplete.')
      }
      const localVarPath = `/dbo-pos-issue/v1/orders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (status) {
        localVarQueryParameter['status'] = status
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Периодическая проверка статуса заявки
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    status(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling status.')
      }
      const localVarPath = `/dbo-pos-issue/v1/orders/{id}/status`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoanOrderControllerV1Api - functional programming interface
 * @export
 */
export const LoanOrderControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод отменяет заявку на POS кредит
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LoanOrderControllerV1ApiAxiosParamCreator(configuration).cancel(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод создает заявку на POS кредит в Siebel
     * @param {CreateOrderDtoV1} createOrderDtoV1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1(
      createOrderDtoV1: CreateOrderDtoV1,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCreatedDto> {
      const localVarAxiosArgs = LoanOrderControllerV1ApiAxiosParamCreator(configuration).create1(
        createOrderDtoV1,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает финальные параметры предложения
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanOrderDto> {
      const localVarAxiosArgs = LoanOrderControllerV1ApiAxiosParamCreator(configuration).fetch(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает незавершенные заявки на оформление pos кредита
     * @param {Array<'QUEUED' | 'QUEUING_ERROR' | 'QUEUED_DEADLINE_ERROR' | 'WAIT_SIGN' | 'WAIT_SIGN_DEADLINE_ERROR' | 'CANCELED' | 'BANK_REFUSE' | 'REQ_CLOSED_CREDIT_ISSUED' | 'EXPECT_IDENTIFICATION'>} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    incomplete(
      status: Array<
        | 'QUEUED'
        | 'QUEUING_ERROR'
        | 'QUEUED_DEADLINE_ERROR'
        | 'WAIT_SIGN'
        | 'WAIT_SIGN_DEADLINE_ERROR'
        | 'CANCELED'
        | 'BANK_REFUSE'
        | 'REQ_CLOSED_CREDIT_ISSUED'
        | 'EXPECT_IDENTIFICATION'
      >,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncompleteOrderDtoV1> {
      const localVarAxiosArgs = LoanOrderControllerV1ApiAxiosParamCreator(configuration).incomplete(status, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Периодическая проверка статуса заявки
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    status(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStateDto> {
      const localVarAxiosArgs = LoanOrderControllerV1ApiAxiosParamCreator(configuration).status(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoanOrderControllerV1Api - factory interface
 * @export
 */
export const LoanOrderControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод отменяет заявку на POS кредит
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(id: number, options?: any): AxiosPromise<void> {
      return LoanOrderControllerV1ApiFp(configuration).cancel(id, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод создает заявку на POS кредит в Siebel
     * @param {CreateOrderDtoV1} createOrderDtoV1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1(createOrderDtoV1: CreateOrderDtoV1, options?: any): AxiosPromise<OrderCreatedDto> {
      return LoanOrderControllerV1ApiFp(configuration).create1(createOrderDtoV1, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает финальные параметры предложения
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetch(id: number, options?: any): AxiosPromise<LoanOrderDto> {
      return LoanOrderControllerV1ApiFp(configuration).fetch(id, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает незавершенные заявки на оформление pos кредита
     * @param {Array<'QUEUED' | 'QUEUING_ERROR' | 'QUEUED_DEADLINE_ERROR' | 'WAIT_SIGN' | 'WAIT_SIGN_DEADLINE_ERROR' | 'CANCELED' | 'BANK_REFUSE' | 'REQ_CLOSED_CREDIT_ISSUED' | 'EXPECT_IDENTIFICATION'>} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    incomplete(
      status: Array<
        | 'QUEUED'
        | 'QUEUING_ERROR'
        | 'QUEUED_DEADLINE_ERROR'
        | 'WAIT_SIGN'
        | 'WAIT_SIGN_DEADLINE_ERROR'
        | 'CANCELED'
        | 'BANK_REFUSE'
        | 'REQ_CLOSED_CREDIT_ISSUED'
        | 'EXPECT_IDENTIFICATION'
      >,
      options?: any
    ): AxiosPromise<IncompleteOrderDtoV1> {
      return LoanOrderControllerV1ApiFp(configuration).incomplete(status, options)(axios, basePath)
    },
    /**
     *
     * @summary Периодическая проверка статуса заявки
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    status(id: number, options?: any): AxiosPromise<OrderStateDto> {
      return LoanOrderControllerV1ApiFp(configuration).status(id, options)(axios, basePath)
    },
  }
}

/**
 * LoanOrderControllerV1Api - object-oriented interface
 * @export
 * @class LoanOrderControllerV1Api
 * @extends {BaseAPI}
 */
export class LoanOrderControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Метод отменяет заявку на POS кредит
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanOrderControllerV1Api
   */
  public cancel(id: number, options?: any) {
    return LoanOrderControllerV1ApiFp(this.configuration).cancel(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод создает заявку на POS кредит в Siebel
   * @param {CreateOrderDtoV1} createOrderDtoV1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanOrderControllerV1Api
   */
  public create1(createOrderDtoV1: CreateOrderDtoV1, options?: any) {
    return LoanOrderControllerV1ApiFp(this.configuration).create1(createOrderDtoV1, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает финальные параметры предложения
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanOrderControllerV1Api
   */
  public fetch(id: number, options?: any) {
    return LoanOrderControllerV1ApiFp(this.configuration).fetch(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает незавершенные заявки на оформление pos кредита
   * @param {Array<'QUEUED' | 'QUEUING_ERROR' | 'QUEUED_DEADLINE_ERROR' | 'WAIT_SIGN' | 'WAIT_SIGN_DEADLINE_ERROR' | 'CANCELED' | 'BANK_REFUSE' | 'REQ_CLOSED_CREDIT_ISSUED' | 'EXPECT_IDENTIFICATION'>} status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanOrderControllerV1Api
   */
  public incomplete(
    status: Array<
      | 'QUEUED'
      | 'QUEUING_ERROR'
      | 'QUEUED_DEADLINE_ERROR'
      | 'WAIT_SIGN'
      | 'WAIT_SIGN_DEADLINE_ERROR'
      | 'CANCELED'
      | 'BANK_REFUSE'
      | 'REQ_CLOSED_CREDIT_ISSUED'
      | 'EXPECT_IDENTIFICATION'
    >,
    options?: any
  ) {
    return LoanOrderControllerV1ApiFp(this.configuration).incomplete(status, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Периодическая проверка статуса заявки
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanOrderControllerV1Api
   */
  public status(id: number, options?: any) {
    return LoanOrderControllerV1ApiFp(this.configuration).status(id, options)(this.axios, this.basePath)
  }
}

/**
 * LoanOrderControllerV2Api - axios parameter creator
 * @export
 */
export const LoanOrderControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод создает заявку на POS кредит в Siebel
     * @param {CreateOrderDtoV2} createOrderDtoV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createOrderDtoV2: CreateOrderDtoV2, options: any = {}): RequestArgs {
      // verify required parameter 'createOrderDtoV2' is not null or undefined
      if (createOrderDtoV2 === null || createOrderDtoV2 === undefined) {
        throw new RequiredError(
          'createOrderDtoV2',
          'Required parameter createOrderDtoV2 was null or undefined when calling create.'
        )
      }
      const localVarPath = `/dbo-pos-issue/v2/orders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof createOrderDtoV2 !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createOrderDtoV2 !== undefined ? createOrderDtoV2 : {})
        : createOrderDtoV2 || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает незавершенные заявки на оформление pos кредита
     * @param {Array<'QUEUED' | 'QUEUING_ERROR' | 'QUEUED_DEADLINE_ERROR' | 'WAIT_SIGN' | 'WAIT_SIGN_DEADLINE_ERROR' | 'CANCELED' | 'BANK_REFUSE' | 'REQ_CLOSED_CREDIT_ISSUED' | 'EXPECT_IDENTIFICATION'>} [status]
     * @param {string} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchIncompleteOrders(
      status?: Array<
        | 'QUEUED'
        | 'QUEUING_ERROR'
        | 'QUEUED_DEADLINE_ERROR'
        | 'WAIT_SIGN'
        | 'WAIT_SIGN_DEADLINE_ERROR'
        | 'CANCELED'
        | 'BANK_REFUSE'
        | 'REQ_CLOSED_CREDIT_ISSUED'
        | 'EXPECT_IDENTIFICATION'
      >,
      product?: string,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/dbo-pos-issue/v2/orders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (status) {
        localVarQueryParameter['status'] = status
      }

      if (product !== undefined) {
        localVarQueryParameter['product'] = product
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoanOrderControllerV2Api - functional programming interface
 * @export
 */
export const LoanOrderControllerV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод создает заявку на POS кредит в Siebel
     * @param {CreateOrderDtoV2} createOrderDtoV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      createOrderDtoV2: CreateOrderDtoV2,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCreatedDto> {
      const localVarAxiosArgs = LoanOrderControllerV2ApiAxiosParamCreator(configuration).create(
        createOrderDtoV2,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает незавершенные заявки на оформление pos кредита
     * @param {Array<'QUEUED' | 'QUEUING_ERROR' | 'QUEUED_DEADLINE_ERROR' | 'WAIT_SIGN' | 'WAIT_SIGN_DEADLINE_ERROR' | 'CANCELED' | 'BANK_REFUSE' | 'REQ_CLOSED_CREDIT_ISSUED' | 'EXPECT_IDENTIFICATION'>} [status]
     * @param {string} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchIncompleteOrders(
      status?: Array<
        | 'QUEUED'
        | 'QUEUING_ERROR'
        | 'QUEUED_DEADLINE_ERROR'
        | 'WAIT_SIGN'
        | 'WAIT_SIGN_DEADLINE_ERROR'
        | 'CANCELED'
        | 'BANK_REFUSE'
        | 'REQ_CLOSED_CREDIT_ISSUED'
        | 'EXPECT_IDENTIFICATION'
      >,
      product?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncompleteOrderDtoV2>> {
      const localVarAxiosArgs = LoanOrderControllerV2ApiAxiosParamCreator(configuration).fetchIncompleteOrders(
        status,
        product,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoanOrderControllerV2Api - factory interface
 * @export
 */
export const LoanOrderControllerV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод создает заявку на POS кредит в Siebel
     * @param {CreateOrderDtoV2} createOrderDtoV2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createOrderDtoV2: CreateOrderDtoV2, options?: any): AxiosPromise<OrderCreatedDto> {
      return LoanOrderControllerV2ApiFp(configuration).create(createOrderDtoV2, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод возвращает незавершенные заявки на оформление pos кредита
     * @param {Array<'QUEUED' | 'QUEUING_ERROR' | 'QUEUED_DEADLINE_ERROR' | 'WAIT_SIGN' | 'WAIT_SIGN_DEADLINE_ERROR' | 'CANCELED' | 'BANK_REFUSE' | 'REQ_CLOSED_CREDIT_ISSUED' | 'EXPECT_IDENTIFICATION'>} [status]
     * @param {string} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchIncompleteOrders(
      status?: Array<
        | 'QUEUED'
        | 'QUEUING_ERROR'
        | 'QUEUED_DEADLINE_ERROR'
        | 'WAIT_SIGN'
        | 'WAIT_SIGN_DEADLINE_ERROR'
        | 'CANCELED'
        | 'BANK_REFUSE'
        | 'REQ_CLOSED_CREDIT_ISSUED'
        | 'EXPECT_IDENTIFICATION'
      >,
      product?: string,
      options?: any
    ): AxiosPromise<Array<IncompleteOrderDtoV2>> {
      return LoanOrderControllerV2ApiFp(configuration).fetchIncompleteOrders(status, product, options)(axios, basePath)
    },
  }
}

/**
 * LoanOrderControllerV2Api - object-oriented interface
 * @export
 * @class LoanOrderControllerV2Api
 * @extends {BaseAPI}
 */
export class LoanOrderControllerV2Api extends BaseAPI {
  /**
   *
   * @summary Метод создает заявку на POS кредит в Siebel
   * @param {CreateOrderDtoV2} createOrderDtoV2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanOrderControllerV2Api
   */
  public create(createOrderDtoV2: CreateOrderDtoV2, options?: any) {
    return LoanOrderControllerV2ApiFp(this.configuration).create(createOrderDtoV2, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод возвращает незавершенные заявки на оформление pos кредита
   * @param {Array<'QUEUED' | 'QUEUING_ERROR' | 'QUEUED_DEADLINE_ERROR' | 'WAIT_SIGN' | 'WAIT_SIGN_DEADLINE_ERROR' | 'CANCELED' | 'BANK_REFUSE' | 'REQ_CLOSED_CREDIT_ISSUED' | 'EXPECT_IDENTIFICATION'>} [status]
   * @param {string} [product]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanOrderControllerV2Api
   */
  public fetchIncompleteOrders(
    status?: Array<
      | 'QUEUED'
      | 'QUEUING_ERROR'
      | 'QUEUED_DEADLINE_ERROR'
      | 'WAIT_SIGN'
      | 'WAIT_SIGN_DEADLINE_ERROR'
      | 'CANCELED'
      | 'BANK_REFUSE'
      | 'REQ_CLOSED_CREDIT_ISSUED'
      | 'EXPECT_IDENTIFICATION'
    >,
    product?: string,
    options?: any
  ) {
    return LoanOrderControllerV2ApiFp(this.configuration).fetchIncompleteOrders(
      status,
      product,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * LoanPaymentControllerApi - axios parameter creator
 * @export
 */
export const LoanPaymentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает сумму ежемесячного платежа
     * @param {CalcPaymentRequest} calcPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calcPayment(calcPaymentRequest: CalcPaymentRequest, options: any = {}): RequestArgs {
      // verify required parameter 'calcPaymentRequest' is not null or undefined
      if (calcPaymentRequest === null || calcPaymentRequest === undefined) {
        throw new RequiredError(
          'calcPaymentRequest',
          'Required parameter calcPaymentRequest was null or undefined when calling calcPayment.'
        )
      }
      const localVarPath = `/dbo-pos-issue/v1/payments/calculator`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof calcPaymentRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(calcPaymentRequest !== undefined ? calcPaymentRequest : {})
        : calcPaymentRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoanPaymentControllerApi - functional programming interface
 * @export
 */
export const LoanPaymentControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает сумму ежемесячного платежа
     * @param {CalcPaymentRequest} calcPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calcPayment(
      calcPaymentRequest: CalcPaymentRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalcPaymentResponse> {
      const localVarAxiosArgs = LoanPaymentControllerApiAxiosParamCreator(configuration).calcPayment(
        calcPaymentRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoanPaymentControllerApi - factory interface
 * @export
 */
export const LoanPaymentControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает сумму ежемесячного платежа
     * @param {CalcPaymentRequest} calcPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calcPayment(calcPaymentRequest: CalcPaymentRequest, options?: any): AxiosPromise<CalcPaymentResponse> {
      return LoanPaymentControllerApiFp(configuration).calcPayment(calcPaymentRequest, options)(axios, basePath)
    },
  }
}

/**
 * LoanPaymentControllerApi - object-oriented interface
 * @export
 * @class LoanPaymentControllerApi
 * @extends {BaseAPI}
 */
export class LoanPaymentControllerApi extends BaseAPI {
  /**
   *
   * @summary Метод возвращает сумму ежемесячного платежа
   * @param {CalcPaymentRequest} calcPaymentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanPaymentControllerApi
   */
  public calcPayment(calcPaymentRequest: CalcPaymentRequest, options?: any) {
    return LoanPaymentControllerApiFp(this.configuration).calcPayment(calcPaymentRequest, options)(
      this.axios,
      this.basePath
    )
  }
}

/**
 * OrderDocumentControllerV1Api - axios parameter creator
 * @export
 */
export const OrderDocumentControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает документ в формате base64
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    document(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling document.')
      }
      const localVarPath = `/dbo-pos-issue/v1/docs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OrderDocumentControllerV1Api - functional programming interface
 * @export
 */
export const OrderDocumentControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращает документ в формате base64
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    document(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDocumentDto> {
      const localVarAxiosArgs = OrderDocumentControllerV1ApiAxiosParamCreator(configuration).document(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OrderDocumentControllerV1Api - factory interface
 * @export
 */
export const OrderDocumentControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод возвращает документ в формате base64
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    document(id: number, options?: any): AxiosPromise<OrderDocumentDto> {
      return OrderDocumentControllerV1ApiFp(configuration).document(id, options)(axios, basePath)
    },
  }
}

/**
 * OrderDocumentControllerV1Api - object-oriented interface
 * @export
 * @class OrderDocumentControllerV1Api
 * @extends {BaseAPI}
 */
export class OrderDocumentControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Метод возвращает документ в формате base64
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDocumentControllerV1Api
   */
  public document(id: number, options?: any) {
    return OrderDocumentControllerV1ApiFp(this.configuration).document(id, options)(this.axios, this.basePath)
  }
}

/**
 * OrderStatusControllerV1Api - axios parameter creator
 * @export
 */
export const OrderStatusControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод отправляет код подтверждения клиенту по смс
     * @param {SmsSendingDto} smsSendingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    send(smsSendingDto: SmsSendingDto, options: any = {}): RequestArgs {
      // verify required parameter 'smsSendingDto' is not null or undefined
      if (smsSendingDto === null || smsSendingDto === undefined) {
        throw new RequiredError(
          'smsSendingDto',
          'Required parameter smsSendingDto was null or undefined when calling send.'
        )
      }
      const localVarPath = `/dbo-pos-issue/v1/sms/send`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof smsSendingDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(smsSendingDto !== undefined ? smsSendingDto : {})
        : smsSendingDto || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод выполняет проверку кода подтверждения и отправляет документы на email клиенту
     * @param {SmsSigningDto} smsSigningDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sign(smsSigningDto: SmsSigningDto, options: any = {}): RequestArgs {
      // verify required parameter 'smsSigningDto' is not null or undefined
      if (smsSigningDto === null || smsSigningDto === undefined) {
        throw new RequiredError(
          'smsSigningDto',
          'Required parameter smsSigningDto was null or undefined when calling sign.'
        )
      }
      const localVarPath = `/dbo-pos-issue/v1/sms/check`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof smsSigningDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(smsSigningDto !== undefined ? smsSigningDto : {})
        : smsSigningDto || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OrderStatusControllerV1Api - functional programming interface
 * @export
 */
export const OrderStatusControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод отправляет код подтверждения клиенту по смс
     * @param {SmsSendingDto} smsSendingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    send(
      smsSendingDto: SmsSendingDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsSendingDto> {
      const localVarAxiosArgs = OrderStatusControllerV1ApiAxiosParamCreator(configuration).send(smsSendingDto, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод выполняет проверку кода подтверждения и отправляет документы на email клиенту
     * @param {SmsSigningDto} smsSigningDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sign(
      smsSigningDto: SmsSigningDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsSigningDto> {
      const localVarAxiosArgs = OrderStatusControllerV1ApiAxiosParamCreator(configuration).sign(smsSigningDto, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OrderStatusControllerV1Api - factory interface
 * @export
 */
export const OrderStatusControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод отправляет код подтверждения клиенту по смс
     * @param {SmsSendingDto} smsSendingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    send(smsSendingDto: SmsSendingDto, options?: any): AxiosPromise<SmsSendingDto> {
      return OrderStatusControllerV1ApiFp(configuration).send(smsSendingDto, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод выполняет проверку кода подтверждения и отправляет документы на email клиенту
     * @param {SmsSigningDto} smsSigningDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sign(smsSigningDto: SmsSigningDto, options?: any): AxiosPromise<SmsSigningDto> {
      return OrderStatusControllerV1ApiFp(configuration).sign(smsSigningDto, options)(axios, basePath)
    },
  }
}

/**
 * OrderStatusControllerV1Api - object-oriented interface
 * @export
 * @class OrderStatusControllerV1Api
 * @extends {BaseAPI}
 */
export class OrderStatusControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Метод отправляет код подтверждения клиенту по смс
   * @param {SmsSendingDto} smsSendingDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStatusControllerV1Api
   */
  public send(smsSendingDto: SmsSendingDto, options?: any) {
    return OrderStatusControllerV1ApiFp(this.configuration).send(smsSendingDto, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод выполняет проверку кода подтверждения и отправляет документы на email клиенту
   * @param {SmsSigningDto} smsSigningDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStatusControllerV1Api
   */
  public sign(smsSigningDto: SmsSigningDto, options?: any) {
    return OrderStatusControllerV1ApiFp(this.configuration).sign(smsSigningDto, options)(this.axios, this.basePath)
  }
}
