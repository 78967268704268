import { createSelector } from 'reselect'

import { RootState } from '@root/reducers'
import { LoansListResponse } from '@reducers/processing/types'

export const selectLoansState = (state: RootState) => state.profile.processing.loans
export const selectFullInfoLoan = (state: RootState) => state.profile.processing.fullInfoLoan
export const selectBindingsState = (state: RootState) => state.profile.processing.bindings
export const selectRefillState = (state: RootState) => state.profile.processing.refill
export const selectTransferState = (state: RootState) => state.profile.processing.transfer
export const selectServicesById = (state: RootState) => state.profile.processing.servicesById // todo выпилить и заменить на ump

export const selectLoansData = createSelector(selectLoansState, (state) => state.data)

export const selectLoan = (loanId: string) =>
  createSelector(selectLoansData, (state: LoansListResponse) => state.loans && state.loans[Number(loanId)])

export const selectBindings = createSelector(selectBindingsState, (state) => state.data)
