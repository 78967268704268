import React, { FC, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { Binding } from '@open-api/ump/ewallet-profile'
import { BindingsField } from '@components/BindigsField/BindingsField'
import { RootState } from '@root/reducers'
import { selectBindings } from '@selectors/processing'
import { TransferByPhoneConstraints } from '@reducers/sbp/types'
import { selectTransferByPhoneConstraints } from '@selectors/sbp'
import { DEFAULT_LIMITS } from '@components/SBP/constants'
import { FieldsValues, FormFields } from '@components/SBP/types'
import { sendFieldGtm } from '@root/utils/gtm/sbp/events'
import { EventFieldNames } from '@root/utils/gtm/sbp/types'
import { getCurrentBalance, toPenny, updateAmountValidator } from '@components/BindigsField/utils'

interface BindingsFieldHandlerProps {
  formFields: FormFields
  onClick?: () => void
  onBlur?: () => void
}

export const BindingsFieldHandler: FC<BindingsFieldHandlerProps> = ({ formFields, onClick, onBlur }) => {
  const { setFieldTouched, setFieldValue, values, errors, validateField } = useFormikContext<FieldsValues>()

  const bindings = useSelector<RootState, Binding[]>(selectBindings)
  const { data: transferByPhoneConstraints } = useSelector<RootState, TransferByPhoneConstraints>(
    selectTransferByPhoneConstraints
  )

  useEffect(() => {
    if (errors.paymentSource) {
      sendFieldGtm(EventFieldNames.SHOW_ERROR_GTM, formFields.paymentSource.name, errors.paymentSource)
    }
  }, [errors.paymentSource, formFields.paymentSource.name])

  const handleChange = useCallback(
    (value: string) => {
      formFields.amount.validators = updateAmountValidator(formFields.amount.validators, {
        max: transferByPhoneConstraints?.maxAmount || DEFAULT_LIMITS.maxAmount,
        balance: toPenny(getCurrentBalance(value, bindings)),
      })

      validateField(formFields.amount.name)

      setFieldValue(formFields.paymentSource.name, value)
      setFieldTouched(formFields.paymentSource.name, true)
      if (value) {
        sendFieldGtm(EventFieldNames.SUCCESS_FILLED_GTM, formFields.paymentSource.name)
      }
    },
    [
      bindings,
      formFields.amount,
      formFields.paymentSource.name,
      setFieldTouched,
      setFieldValue,
      transferByPhoneConstraints?.maxAmount,
      validateField,
    ]
  )

  return (
    <BindingsField
      {...formFields.paymentSource}
      initialValue={values.paymentSource}
      onChange={handleChange}
      onClick={onClick}
      onBlur={onBlur}
    />
  )
}
