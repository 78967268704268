import {
  actionGroups,
  cardStatusesTypes,
  eventActions,
  eventCategories,
  eventContexts,
  eventLabels,
  events,
  EventType,
  screenNames,
  sendGmtEvent,
  TemplatesAndAutoPaymentsButtonTypes,
} from './baseFunctions'
import { statDataLoader } from './StatDataLoader'
import { translit } from './translit'

export const newMainWigetNames = {
  shablonyIAvtoplatezhy: 'shablony_i_avtoplatezhy',
  scheta: 'scheta',
  vklady: 'vklady',
  kredity: 'kredity',
  karty: 'karty',
  mtsCashback: 'mts_cashback',
  moiTelefon: 'moi_telefon',
  izbrannoe: 'izbrannoe',
  moiKoshelek: 'moi_koshelek',
  vsegoSredstv: 'vsego_sredstv',
} as const

type newMainWigetNamesTypes = typeof newMainWigetNames

// Показ любого виджета на главной
export const showWidgetGtm = (widget: newMainWigetNamesTypes[keyof newMainWigetNamesTypes]) =>
  sendGmtEvent({
    event: events.vntMain,
    eventCategory: eventCategories.main,
    eventAction: eventActions.elementShow,
    eventLabel: widget,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по кнопке "Переводы и платежи" на главной
export const transfersAndPaymentsClickGtm = () =>
  sendGmtEvent({
    event: events.vntMain,
    eventCategory: eventCategories.main,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.perevodyIPlatezhi,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Пока убрали этот виджет
// Клик по элементу скрытия средств
export const hidePaymentsClickGtm = (isOpened: boolean) =>
  sendGmtEvent({
    event: events.vntVsego,
    eventCategory: eventCategories.vsegoSredstv,
    eventAction: eventActions.elementClick,
    eventLabel: isOpened ? eventLabels.skryt : eventLabels.pokazat,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Пока убрали этот виджет
// Ошибка при загрузке баланса моего кошелька
export const totalFundsLoadErrorGtm = () =>
  sendGmtEvent({
    event: events.vntKosh,
    eventCategory: eventCategories.moiKoshelek,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.oshibkaZagruzkiBalansa,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по элементам в избранном на главной
export const recommendationClickGtm = (label: string) =>
  sendGmtEvent({
    event: events.vntIzbr,
    eventCategory: eventCategories.izbrannoe,
    eventAction: eventActions.elementClick,
    eventLabel: translit(label),
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Отображение ошибки при загрузке Избранного на главной
export const recommendationsErrorGtm = () =>
  sendGmtEvent({
    event: events.vntIzbr,
    eventCategory: eventCategories.izbrannoe,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.oshibka,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по кнопке "Пополнить" в виджете Мой телефон
export const myPhoneClickGtm = () =>
  sendGmtEvent({
    event: events.vntTelef,
    eventCategory: eventCategories.moiTelefon,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.popolnit,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по виджету МТС Cashback
export const cashbackClickGtm = () =>
  sendGmtEvent({
    event: events.vntCash,
    eventCategory: eventCategories.mtsCashback,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.cashback,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ ошибки загрузки виджета МТС Cashback
export const cashbackErrorGtm = () =>
  sendGmtEvent({
    event: events.vntCash,
    eventCategory: eventCategories.mtsCashback,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.oshibka,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по карте в виджете Карты
export const cardClickGtm = (cardName: string) =>
  sendGmtEvent({
    event: events.vntKart,
    eventCategory: eventCategories.karty,
    eventAction: eventActions.cardClick,
    eventLabel: translit(cardName),
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по элементу скрыть на виджете Карты
export const cardsHideClickGtm = (isOpened: boolean) =>
  sendGmtEvent({
    event: events.vntKart,
    eventCategory: eventCategories.karty,
    eventAction: eventActions.elementClick,
    eventLabel: isOpened ? eventLabels.skryt : eventLabels.pokazat,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ попапов при клике по картам
export const cardPopupShowGtm = (cardStatus: cardStatusesTypes) =>
  sendGmtEvent({
    event: events.vntKart,
    eventCategory: eventCategories.karty,
    eventAction: eventActions.popupShow,
    eventLabel: cardStatus,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ ошибки при загрузке виджета Карты
export const cardsErrorGtm = () =>
  sendGmtEvent({
    event: events.vntKart,
    eventCategory: eventCategories.karty,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.oshibka,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по кнопке "Перезагрузить" при ошибке загрузки виджета Карты
export const cardsReloadClickGtm = () =>
  sendGmtEvent({
    event: events.vntKart,
    eventCategory: eventCategories.karty,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.perezagruzit,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по карточке кредита
export const creditClickGtm = (creditType: string) =>
  sendGmtEvent({
    event: events.vntKred,
    eventCategory: eventCategories.kredity,
    eventAction: eventActions.cardClick,
    eventLabel: translit(creditType),
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по элементу скрыть на виджете кредита
export const creditsHideClickGtm = (isOpened: boolean) =>
  sendGmtEvent({
    event: events.vntKred,
    eventCategory: eventCategories.kredity,
    eventAction: eventActions.elementClick,
    eventLabel: isOpened ? eventLabels.skryt : eventLabels.pokazat,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ ошибки загрузки кредита
export const creditsErrorGtm = () =>
  sendGmtEvent({
    event: events.vntKred,
    eventCategory: eventCategories.kredity,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.oshibka,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по кнопке "перезагрузить" при ошибке загрузки кредита
export const creditsReloadClickGtm = () =>
  sendGmtEvent({
    event: events.vntKred,
    eventCategory: eventCategories.kredity,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.perezagruzit,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по элементу скрыть на деньги на покупку
export const MoneyForPurchaseHideClickGtm = (isOpened: boolean) =>
  sendGmtEvent({
    event: events.vntDeng,
    eventCategory: eventCategories.dengiNaPokupku,
    eventAction: eventActions.elementClick,
    eventLabel: isOpened ? eventLabels.skryt : eventLabels.pokazat,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по вкладу
export const depositClickGtm = (depositType: string) =>
  sendGmtEvent({
    event: events.vntVklad,
    eventCategory: eventCategories.vklady,
    eventAction: eventActions.cardClick,
    eventLabel: translit(depositType),
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по элементу скрыть на виджете Вклады
export const depositsHideClickGtm = (isOpened: boolean) =>
  sendGmtEvent({
    event: events.vntVklad,
    eventCategory: eventCategories.vklady,
    eventAction: eventActions.elementClick,
    eventLabel: isOpened ? eventLabels.skryt : eventLabels.pokazat,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ ошибки при загрузке вклада
export const depositsErrorGtm = () =>
  sendGmtEvent({
    event: events.vntVklad,
    eventCategory: eventCategories.vklady,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.oshibka,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по кнопке "Перезагрузить" при ошибке загрузки вклада
export const depositsReloadClickGtm = () =>
  sendGmtEvent({
    event: events.vntVklad,
    eventCategory: eventCategories.vklady,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.perezagruzit,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по счёту
export const accountClickGtm = (accountType: string) =>
  sendGmtEvent({
    event: events.vntSchet,
    eventCategory: eventCategories.scheta,
    eventAction: eventActions.cardClick,
    eventLabel: translit(accountType),
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по элементу скрыть на виджете Счета
export const accountsHideClickGtm = (isOpened: boolean) =>
  sendGmtEvent({
    event: events.vntSchet,
    eventCategory: eventCategories.scheta,
    eventAction: eventActions.elementClick,
    eventLabel: isOpened ? eventLabels.skryt : eventLabels.pokazat,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ ошибки при загрузке Счёта
export const accountsErrorGtm = () =>
  sendGmtEvent({
    event: events.vntSchet,
    eventCategory: eventCategories.scheta,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.oshibka,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по кнопке "Перезагрузить" при ошибке загрузки Счёта
export const accountsReloadClickGtm = () =>
  sendGmtEvent({
    event: events.vntSchet,
    eventCategory: eventCategories.scheta,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.perezagruzit,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// такого у нас пока нет
// Показ попапа при клике по счёту
export const accountPopupShowGtm = (accountTitle: string) =>
  sendGmtEvent({
    event: events.vntSchet,
    eventCategory: eventCategories.scheta,
    eventAction: eventActions.popupShow,
    eventLabel: translit(accountTitle),
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// - templatesAndAutoPayments
// Клики по элементам в виджете Шаблоны и автоплатежи
export const templatesAndAutoPaymentsClickGtm = (button: TemplatesAndAutoPaymentsButtonTypes) =>
  sendGmtEvent({
    event: events.vntShabl,
    eventCategory: eventCategories.shablonyIAvtoplatezhy,
    eventAction: eventActions.elementClick,
    eventLabel: button,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по эжлементу свернуть в виджете Шаблоны и автоплатежи
export const templatesAndAutoPaymentsHideClickGtm = (isOpened: boolean) =>
  sendGmtEvent({
    event: events.vntShabl,
    eventCategory: eventCategories.shablonyIAvtoplatezhy,
    eventAction: eventActions.elementClick,
    eventLabel: isOpened ? eventLabels.skryt : eventLabels.pokazat,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Клик по кнопке "Открыть новый продукт" внизу страницы на главной
export const openNewProductCLickGtm = () =>
  sendGmtEvent({
    event: events.vntMain,
    eventCategory: eventCategories.main,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.otkrytNovyiProdukt,
    screenName: screenNames.newMain,
    actionGroup: actionGroups.interactions,
  })

// Показ стикера с предложением оформить Счет
export const accountStikerAddShowGtm = () =>
  statDataLoader.push((event: EventType) =>
    sendGmtEvent({
      ...event,
      event: events.vntSchet,
      eventCategory: eventCategories.scheta,
      eventAction: eventActions.elementShow,
      eventLabel: eventLabels.stikery,
      eventContext: eventContexts.otkrytSchet,
      screenName: screenNames.newMain,
      actionGroup: actionGroups.nonInteractions,
    })
  )

// Показ стикера с предложением оформить Кредит
export const creditStikerAddShowGtm = () =>
  statDataLoader.push((event: EventType) =>
    sendGmtEvent({
      ...event,
      event: events.vntKred,
      eventCategory: eventCategories.kredity,
      eventAction: eventActions.elementShow,
      eventLabel: eventLabels.stikery,
      eventContext: eventContexts.oformiteZayavkuNaKredit,
      screenName: screenNames.newMain,
      actionGroup: actionGroups.nonInteractions,
    })
  )

// Клик по стикеру с предложением оформить Счет
export const accountStikerAddClickGtm = () =>
  statDataLoader.push((event: EventType) =>
    sendGmtEvent({
      ...event,
      event: events.vntSchet,
      eventCategory: eventCategories.scheta,
      eventAction: eventActions.elementClick,
      eventLabel: eventLabels.stikery,
      eventContext: eventContexts.otkrytSchet,
      screenName: screenNames.newMain,
      actionGroup: actionGroups.nonInteractions,
    })
  )

// Клик по стикеру с предложением оформить Кредит
export const creditStikerAddClickGtm = () =>
  statDataLoader.push((event: EventType) =>
    sendGmtEvent({
      ...event,
      event: events.vntKred,
      eventCategory: eventCategories.kredity,
      eventAction: eventActions.elementClick,
      eventLabel: eventLabels.stikery,
      eventContext: eventContexts.oformiteZayavkuNaKredit,
      screenName: screenNames.newMain,
      actionGroup: actionGroups.nonInteractions,
    })
  )

// Клик по кнопке Выгодные предложения
export const openOfferCLickGtm = () =>
  statDataLoader.push((event: EventType) =>
    sendGmtEvent({
      ...event,
      event: events.vntKred,
      eventCategory: eventCategories.kredity,
      eventAction: eventActions.elementClick,
      eventLabel: eventLabels.stikery,
      eventContext: eventContexts.oformiteZayavkuNaKredit,
      screenName: screenNames.newMain,
      actionGroup: actionGroups.nonInteractions,
    })
  )
