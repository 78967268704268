// tslint:disable
/**
 * dbo-client-info
 * MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  countryName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  countryCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  regionName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  regionCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  regionSocr?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  districtName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  localityName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  cityName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  streetName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  house?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  building?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  block?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  flat?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zipCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  addr?: string
}
/**
 *
 * @export
 * @interface Employment
 */
export interface Employment {
  /**
   *
   * @type {string}
   * @memberof Employment
   */
  activityType?: string
  /**
   *
   * @type {string}
   * @memberof Employment
   */
  orgFullName?: string
  /**
   *
   * @type {string}
   * @memberof Employment
   */
  startDt?: string
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  requestId?: string
  /**
   *
   * @type {Error}
   * @memberof ErrorResponse
   */
  error?: Error
}
/**
 *
 * @export
 * @interface Fields
 */
export interface Fields {
  /**
   *
   * @type {string}
   * @memberof Fields
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Fields
   */
  appVersion?: string
  /**
   *
   * @type {string}
   * @memberof Fields
   */
  accountName?: string
  /**
   *
   * @type {string}
   * @memberof Fields
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof Fields
   */
  isVIP?: string
  /**
   *
   * @type {string}
   * @memberof Fields
   */
  display_name?: string
  /**
   *
   * @type {string}
   * @memberof Fields
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof Fields
   */
  info?: string
}
/**
 *
 * @export
 * @interface FullClientInfoReq
 */
export interface FullClientInfoReq {
  /**
   *
   * @type {string}
   * @memberof FullClientInfoReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof FullClientInfoReq
   */
  rboId?: number
}
/**
 *
 * @export
 * @interface FullClientInfoRes
 */
export interface FullClientInfoRes {
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  secondName?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  mobilePhone?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  email?: string
  /**
   *
   * @type {number}
   * @memberof FullClientInfoRes
   */
  sex?: number
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  birthPlace?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  serial?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  issueOrgCode?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  issueOrgName?: string
  /**
   *
   * @type {string}
   * @memberof FullClientInfoRes
   */
  issueDate?: string
  /**
   *
   * @type {Array<Address>}
   * @memberof FullClientInfoRes
   */
  addresses?: Array<Address>
  /**
   *
   * @type {Address}
   * @memberof FullClientInfoRes
   */
  address?: Address
  /**
   *
   * @type {Employment}
   * @memberof FullClientInfoRes
   */
  employment?: Employment
  /**
   *
   * @type {Income}
   * @memberof FullClientInfoRes
   */
  totalIncome?: Income
}
/**
 *
 * @export
 * @interface Income
 */
export interface Income {
  /**
   *
   * @type {number}
   * @memberof Income
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof Income
   */
  currency?: string
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  code?: number
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  externalCode?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  details?: string
}
/**
 *
 * @export
 * @interface RequestInfoReq
 */
export interface RequestInfoReq {
  /**
   *
   * @type {string}
   * @memberof RequestInfoReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof RequestInfoReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof RequestInfoReq
   */
  mobileOS?: string
  /**
   *
   * @type {string}
   * @memberof RequestInfoReq
   */
  appVersion?: string
}
/**
 *
 * @export
 * @interface RequestInfoRes
 */
export interface RequestInfoRes {
  /**
   *
   * @type {string}
   * @memberof RequestInfoRes
   */
  requestId?: string
  /**
   *
   * @type {Fields}
   * @memberof RequestInfoRes
   */
  fields?: Fields
  /**
   *
   * @type {string}
   * @memberof RequestInfoRes
   */
  hash?: string
}

/**
 * ClientInfoApi - axios parameter creator
 * @export
 */
export const ClientInfoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение инфо по клиенту
     * @param {'v1'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fullClientInfo(
      version: 'v1',
      fullClientInfoReq: FullClientInfoReq,
      rboId?: string,
      authorization?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling fullClientInfo.'
        )
      }
      // verify required parameter 'fullClientInfoReq' is not null or undefined
      if (fullClientInfoReq === null || fullClientInfoReq === undefined) {
        throw new RequiredError(
          'fullClientInfoReq',
          'Required parameter fullClientInfoReq was null or undefined when calling fullClientInfo.'
        )
      }
      const localVarPath = `/dbo-client-info/{version}/fullClientInfo`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboId !== undefined && rboId !== null) {
        localVarHeaderParameter['rbo_id'] = String(rboId)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof fullClientInfoReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fullClientInfoReq !== undefined ? fullClientInfoReq : {})
        : fullClientInfoReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary получение инфо по клиенту для чата
     * @param {'v1'} version
     * @param {RequestInfoReq} requestInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {string} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestInfo(
      version: 'v1',
      requestInfoReq: RequestInfoReq,
      rboId?: string,
      authorization?: string,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling requestInfo.')
      }
      // verify required parameter 'requestInfoReq' is not null or undefined
      if (requestInfoReq === null || requestInfoReq === undefined) {
        throw new RequiredError(
          'requestInfoReq',
          'Required parameter requestInfoReq was null or undefined when calling requestInfo.'
        )
      }
      const localVarPath = `/dbo-client-info/{version}/requestInfo`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboId !== undefined && rboId !== null) {
        localVarHeaderParameter['rbo_id'] = String(rboId)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      if (clientId !== undefined && clientId !== null) {
        localVarHeaderParameter['client-id'] = String(clientId)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestInfoReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestInfoReq !== undefined ? requestInfoReq : {})
        : requestInfoReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ClientInfoApi - functional programming interface
 * @export
 */
export const ClientInfoApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение инфо по клиенту
     * @param {'v1'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fullClientInfo(
      version: 'v1',
      fullClientInfoReq: FullClientInfoReq,
      rboId?: string,
      authorization?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullClientInfoRes> {
      const localVarAxiosArgs = ClientInfoApiAxiosParamCreator(configuration).fullClientInfo(
        version,
        fullClientInfoReq,
        rboId,
        authorization,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary получение инфо по клиенту для чата
     * @param {'v1'} version
     * @param {RequestInfoReq} requestInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {string} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestInfo(
      version: 'v1',
      requestInfoReq: RequestInfoReq,
      rboId?: string,
      authorization?: string,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestInfoRes> {
      const localVarAxiosArgs = ClientInfoApiAxiosParamCreator(configuration).requestInfo(
        version,
        requestInfoReq,
        rboId,
        authorization,
        clientId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ClientInfoApi - factory interface
 * @export
 */
export const ClientInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary получение инфо по клиенту
     * @param {'v1'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fullClientInfo(
      version: 'v1',
      fullClientInfoReq: FullClientInfoReq,
      rboId?: string,
      authorization?: string,
      options?: any
    ): AxiosPromise<FullClientInfoRes> {
      return ClientInfoApiFp(configuration).fullClientInfo(
        version,
        fullClientInfoReq,
        rboId,
        authorization,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary получение инфо по клиенту для чата
     * @param {'v1'} version
     * @param {RequestInfoReq} requestInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {string} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestInfo(
      version: 'v1',
      requestInfoReq: RequestInfoReq,
      rboId?: string,
      authorization?: string,
      clientId?: string,
      options?: any
    ): AxiosPromise<RequestInfoRes> {
      return ClientInfoApiFp(configuration).requestInfo(
        version,
        requestInfoReq,
        rboId,
        authorization,
        clientId,
        options
      )(axios, basePath)
    },
  }
}

/**
 * ClientInfoApi - object-oriented interface
 * @export
 * @class ClientInfoApi
 * @extends {BaseAPI}
 */
export class ClientInfoApi extends BaseAPI {
  /**
   *
   * @summary получение инфо по клиенту
   * @param {'v1'} version
   * @param {FullClientInfoReq} fullClientInfoReq
   * @param {string} [rboId]
   * @param {string} [authorization]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientInfoApi
   */
  public fullClientInfo(
    version: 'v1',
    fullClientInfoReq: FullClientInfoReq,
    rboId?: string,
    authorization?: string,
    options?: any
  ) {
    return ClientInfoApiFp(this.configuration).fullClientInfo(
      version,
      fullClientInfoReq,
      rboId,
      authorization,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary получение инфо по клиенту для чата
   * @param {'v1'} version
   * @param {RequestInfoReq} requestInfoReq
   * @param {string} [rboId]
   * @param {string} [authorization]
   * @param {string} [clientId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientInfoApi
   */
  public requestInfo(
    version: 'v1',
    requestInfoReq: RequestInfoReq,
    rboId?: string,
    authorization?: string,
    clientId?: string,
    options?: any
  ) {
    return ClientInfoApiFp(this.configuration).requestInfo(
      version,
      requestInfoReq,
      rboId,
      authorization,
      clientId,
      options
    )(this.axios, this.basePath)
  }
}
