import {
  LoanControllerV1Api as DboLoanApi,
  LoanControllerV2Api as DboLoanApi2,
  AccountControllerV1Api as AccountControllerApi,
  ScheduleControllerV2Api as ScheduleControllerApi,
  ScheduleControllerV1Api,
  ScheduleControllerV3Api,
} from '@open-api/ump/dbo-loan'

export const dboLoanControllerApi1 = new DboLoanApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const dboLoanControllerApi2 = new DboLoanApi2({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const accountControllerApi = new AccountControllerApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const scheduleControllerV1Api = new ScheduleControllerV1Api({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const scheduleControllerApi = new ScheduleControllerApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const scheduleControllerApi3 = new ScheduleControllerV3Api({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})
