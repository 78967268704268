import { window } from '@mtsbank/ui-kit'
import {
  PromoApi,
  RegionApi,
  ProcessingApi,
  ConfigApi,
  AuthApi,
  ProfileApi,
  AntiForgeryTokenApi,
  AutopaymentsApi,
  CmsApi,
  PaymentApi,
  ThreeDs2Api,
} from '@generated/api'
import { axiosWithAftInstance } from '@utils/axios/axiosWithAft'

export const paymentHost = window.location.origin as string
const basePath = process.env.PRIVATE_PAYMENT_HOST || ''
const aftApi = new AntiForgeryTokenApi(undefined, basePath)
const authApi = new AuthApi(undefined, basePath)
const profileApi = new ProfileApi(undefined, basePath, axiosWithAftInstance)
const configApi = new ConfigApi(undefined, basePath)
const promoApi = new PromoApi(undefined, basePath)
const regionApi = new RegionApi(undefined, basePath)
const processingApi = new ProcessingApi(undefined, basePath, axiosWithAftInstance)
const autoPaymentsApi = new AutopaymentsApi(undefined, basePath, axiosWithAftInstance)
const cmsApi = new CmsApi(undefined, basePath)
const paymentApi = new PaymentApi(undefined, basePath, axiosWithAftInstance)
const threeDs2Api = new ThreeDs2Api(undefined, basePath, axiosWithAftInstance)

export {
  promoApi,
  regionApi,
  processingApi,
  configApi,
  authApi,
  profileApi,
  aftApi,
  autoPaymentsApi,
  cmsApi,
  paymentApi,
  threeDs2Api,
}
