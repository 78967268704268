import { timeFormat, timeParse } from 'd3-time-format'

import { LocaleConfig, LocaleNames } from '@utils/formatDate/types'
import { LOCALE_LIST, optionConfig } from './optionConfig'

export const invalidDate = 'ошибка даты'

export type FormatDate = Date | string | number

export const formatDate = (
  date: FormatDate,
  formatAlias = 'DD.MM.YYYY',
  locale: LocaleNames = LocaleNames.DEFAULT
): string => {
  // todo вопрос к дизайнерам/аналитики что выводить при отсутствии date || Invalid Date, так же зафиксировать в тестах
  const newDate = new Date(date)
  const config: LocaleConfig = optionConfig[formatAlias]
  let format: string = formatAlias
  let selectedLocaleType = locale && LOCALE_LIST[locale] ? locale : LocaleNames.DEFAULT

  if (config) {
    if (typeof config === 'string') {
      format = config
    } else {
      format = config.format
      selectedLocaleType = config.locale && LOCALE_LIST[config.locale] ? config.locale : selectedLocaleType
    }
  }
  const selectedLocale = LOCALE_LIST[selectedLocaleType]

  if (!date) {
    return invalidDate
  }

  if (newDate.toString() !== 'Invalid Date') {
    return selectedLocale ? selectedLocale.format(format)(newDate) : timeFormat(format)(newDate)
  }

  return invalidDate
}

export const parseDate = (value: string, format = '%d.%m.%Y'): Date | null => timeParse(format)(value)

export const smartFormatDate = (value: string) => {
  const date = new Date(value)
  const today = new Date()
  const yesterday = new Date()
  const tomorrow = new Date()

  date.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)
  yesterday.setHours(0, 0, 0, 0)
  yesterday.setDate(yesterday.getDate() - 1)
  tomorrow.setHours(0, 0, 0, 0)
  tomorrow.setDate(tomorrow.getDate() + 1)

  if (today.getTime() === date.getTime()) {
    return 'Сегодня'
  }

  if (yesterday.getTime() === date.getTime()) {
    return 'Вчера'
  }

  if (tomorrow.getTime() === date.getTime()) {
    return 'Завтра'
  }

  if (today.getFullYear() !== date.getFullYear()) {
    return formatDate(value, 'DD Month YYYY')
  }

  return formatDate(value, 'DD Month')
}

export const formatDateWithTimeZoneOffset = (date: FormatDate) => {
  const formattedDate = formatDate(date, '%Y-%m-%dT%H:%M:%S%Z')

  if (formattedDate === invalidDate) {
    return invalidDate
  }

  const formattedDateArray = [...formattedDate]

  const offset = formattedDateArray.splice(-5)

  // https://github.com/d3/d3-time-format/issues/35
  offset.splice(3, 0, ':')

  return [...formattedDateArray, ...offset].join('')
}

export const getDateWithPeriod = (date: Date, years = 0, months = 0, days = 0) => {
  const newDate = new Date(date)

  newDate.setFullYear(date.getFullYear() + years)
  newDate.setMonth(date.getMonth() + months)
  newDate.setDate(date.getDate() + days)

  return newDate
}
