import {
  actionGroups,
  eventActions,
  eventCategories,
  eventLabels,
  events,
  sendGmtEvent,
} from '@utils/gtm/baseFunctions'

export const showMassIncidentsGtm = () =>
  sendGmtEvent({
    event: events.mtsMassInc,
    eventCategory: eventCategories.incidentNotification,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.incidentToast,
    screenName: window.location.pathname,
    actionGroup: actionGroups.nonInteractions,
  })
