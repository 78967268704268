import { ErrorGtm } from './types'

export const convertErrorToGtmString = ({ method, path, xrequestId, code, body }: ErrorGtm): string => {
  const convertedError = [`"method": "${method}"`, `"path": "${path}"`]

  if (code) {
    convertedError.push(`"code": "${code}"`)
  }

  if (xrequestId) {
    convertedError.push(`"x-request-id": "${xrequestId}"`)
  }

  if (body) {
    convertedError.push(`'"body": "${body}"`)
  }

  return convertedError.join(',')
}

export const getCapitalizeText = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const convertBooleanToStringGtm = (value: boolean | undefined): string | null => {
  if (typeof value === 'undefined') {
    return null
  }

  return value ? '1' : '0'
}
