// tslint:disable
/**
 * Deposits API
 * Вклады – продукт, помогающий клиентам сохранять и увеличивать накопления на выгодных и комфортных условиях как в физических, так и дистанционных каналах
 *
 * The version of the OpenAPI document: 1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AccountNumberRequest
 */
export interface AccountNumberRequest {
  /**
   *
   * @type {string}
   * @memberof AccountNumberRequest
   */
  accountNumber?: string
}
/**
 *
 * @export
 * @interface AmountInfo
 */
export interface AmountInfo {
  /**
   *
   * @type {number}
   * @memberof AmountInfo
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof AmountInfo
   */
  accountNumber?: string
  /**
   *
   * @type {string}
   * @memberof AmountInfo
   */
  hashedPAN?: string
}
/**
 *
 * @export
 * @interface BankInfo
 */
export interface BankInfo {
  /**
   *
   * @type {string}
   * @memberof BankInfo
   */
  bankBic?: string
  /**
   *
   * @type {string}
   * @memberof BankInfo
   */
  bankName?: string
  /**
   *
   * @type {string}
   * @memberof BankInfo
   */
  bankCorrAccount?: string
  /**
   *
   * @type {string}
   * @memberof BankInfo
   */
  bankTin?: string
  /**
   *
   * @type {string}
   * @memberof BankInfo
   */
  bankRrc?: string
}
/**
 *
 * @export
 * @interface ButtonAction
 */
export interface ButtonAction {
  /**
   *
   * @type {string}
   * @memberof ButtonAction
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof ButtonAction
   */
  actionName?: ButtonActionActionNameEnum
}

/**
 * @export
 * @enum {string}
 */
export enum ButtonActionActionNameEnum {
  HIDEDECLINEDCLOSETASK = 'HIDE_DECLINED_CLOSE_TASK',
}

/**
 *
 * @export
 * @interface CloseDepositDetailsResponse
 */
export interface CloseDepositDetailsResponse {
  /**
   *
   * @type {string}
   * @memberof CloseDepositDetailsResponse
   */
  accountNumber?: string
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  delinqRate?: number
  /**
   *
   * @type {string}
   * @memberof CloseDepositDetailsResponse
   */
  currency?: string
  /**
   *
   * @type {string}
   * @memberof CloseDepositDetailsResponse
   */
  expiryDate?: string
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  planIncome?: number
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  planAmount?: number
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  delinqIncome?: number
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  delinqAmount?: number
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  lostProfit?: number
  /**
   *
   * @type {number}
   * @memberof CloseDepositDetailsResponse
   */
  remainingTerm?: number
  /**
   *
   * @type {string}
   * @memberof CloseDepositDetailsResponse
   */
  refundAccountNumber?: string
}
/**
 *
 * @export
 * @interface CloseDepositRequest
 */
export interface CloseDepositRequest {
  /**
   *
   * @type {string}
   * @memberof CloseDepositRequest
   */
  rqId?: string
  /**
   *
   * @type {string}
   * @memberof CloseDepositRequest
   */
  account?: string
  /**
   *
   * @type {string}
   * @memberof CloseDepositRequest
   */
  rcvAccount?: string
  /**
   *
   * @type {string}
   * @memberof CloseDepositRequest
   */
  rcvHashPan?: string
}
/**
 *
 * @export
 * @interface CloseDepositResponse
 */
export interface CloseDepositResponse {
  /**
   *
   * @type {string}
   * @memberof CloseDepositResponse
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof CloseDepositResponse
   */
  rqId?: string
}
/**
 *
 * @export
 * @interface ConfigurationInfo
 */
export interface ConfigurationInfo {
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isRequisitesVisible?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isStatementsVisible?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isDepositCloseAvailable?: boolean
  /**
   *
   * @type {number}
   * @memberof ConfigurationInfo
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof ConfigurationInfo
   */
  currency?: string
  /**
   *
   * @type {number}
   * @memberof ConfigurationInfo
   */
  term?: number
  /**
   *
   * @type {string}
   * @memberof ConfigurationInfo
   */
  payPeriod?: string
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  capitalization?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  addCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  getCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isPromocodeAvailable?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigurationInfo
   */
  isGetMaxRateAvailable?: boolean
}
/**
 *
 * @export
 * @interface Deposit
 */
export interface Deposit {
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  accountNumber?: string
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof Deposit
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof Deposit
   */
  rate?: number
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  currency?: string
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  expiryDate?: string
  /**
   *
   * @type {number}
   * @memberof Deposit
   */
  currentRateAmount?: number
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  closingStatus?: string
}
/**
 *
 * @export
 * @interface DepositDetailsResponse
 */
export interface DepositDetailsResponse {
  /**
   *
   * @type {DepositInfo}
   * @memberof DepositDetailsResponse
   */
  depositInfo?: DepositInfo
  /**
   *
   * @type {BankInfo}
   * @memberof DepositDetailsResponse
   */
  bankInfo?: BankInfo
  /**
   *
   * @type {NotifyInfo}
   * @memberof DepositDetailsResponse
   */
  notifyInfo?: NotifyInfo
  /**
   *
   * @type {ConfigurationInfo}
   * @memberof DepositDetailsResponse
   */
  configurationInfo?: ConfigurationInfo
}
/**
 *
 * @export
 * @interface DepositInfo
 */
export interface DepositInfo {
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  accountNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DepositInfo
   */
  autoProlongation?: boolean
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  iban?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof DepositInfo
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof DepositInfo
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof DepositInfo
   */
  delinqRate?: number
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  currency?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  currencyName?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  expiryDate?: string
  /**
   *
   * @type {boolean}
   * @memberof DepositInfo
   */
  enableAddCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DepositInfo
   */
  enableGetCash?: boolean
  /**
   *
   * @type {number}
   * @memberof DepositInfo
   */
  irreducibleAmount?: number
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  depTypeName?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  agreementNumber?: string
  /**
   *
   * @type {number}
   * @memberof DepositInfo
   */
  initialAmount?: number
  /**
   *
   * @type {number}
   * @memberof DepositInfo
   */
  planIncome?: number
  /**
   *
   * @type {number}
   * @memberof DepositInfo
   */
  term?: number
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  startDate?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  ratePayDate?: string
  /**
   *
   * @type {number}
   * @memberof DepositInfo
   */
  capitalizationRate?: number
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  rateAccountNumber?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  refundAccountNumber?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  maxReplenishmentDate?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  walletId?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  payPeriodName?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  lastProlongationDate?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  lastRatePayDate?: string
  /**
   *
   * @type {string}
   * @memberof DepositInfo
   */
  systemDate?: string
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {number}
   * @memberof ErrorResponse
   */
  errorCode?: number
  /**
   *
   * @type {number}
   * @memberof ErrorResponse
   */
  internalCode?: number
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  errorCause?: string
}
/**
 *
 * @export
 * @interface MtsBankException
 */
export interface MtsBankException {
  /**
   *
   * @type {MtsBankExceptionCause}
   * @memberof MtsBankException
   */
  cause?: MtsBankExceptionCause
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankException
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  httpStatus?: MtsBankExceptionHttpStatusEnum
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankExceptionCauseSuppressed>}
   * @memberof MtsBankException
   */
  suppressed?: Array<MtsBankExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankException
   */
  localizedMessage?: string
}

/**
 * @export
 * @enum {string}
 */
export enum MtsBankExceptionHttpStatusEnum {
  _100CONTINUE = '100 CONTINUE',
  _101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  _102PROCESSING = '102 PROCESSING',
  _103CHECKPOINT = '103 CHECKPOINT',
  _200OK = '200 OK',
  _201CREATED = '201 CREATED',
  _202ACCEPTED = '202 ACCEPTED',
  _203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  _204NOCONTENT = '204 NO_CONTENT',
  _205RESETCONTENT = '205 RESET_CONTENT',
  _206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  _207MULTISTATUS = '207 MULTI_STATUS',
  _208ALREADYREPORTED = '208 ALREADY_REPORTED',
  _226IMUSED = '226 IM_USED',
  _300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  _301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  _302FOUND = '302 FOUND',
  _302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  _303SEEOTHER = '303 SEE_OTHER',
  _304NOTMODIFIED = '304 NOT_MODIFIED',
  _305USEPROXY = '305 USE_PROXY',
  _307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  _308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  _400BADREQUEST = '400 BAD_REQUEST',
  _401UNAUTHORIZED = '401 UNAUTHORIZED',
  _402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  _403FORBIDDEN = '403 FORBIDDEN',
  _404NOTFOUND = '404 NOT_FOUND',
  _405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  _406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  _407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  _408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  _409CONFLICT = '409 CONFLICT',
  _410GONE = '410 GONE',
  _411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  _412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  _413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  _413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  _414URITOOLONG = '414 URI_TOO_LONG',
  _414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  _415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  _416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  _417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  _418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  _419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  _420METHODFAILURE = '420 METHOD_FAILURE',
  _421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  _422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  _423LOCKED = '423 LOCKED',
  _424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  _425TOOEARLY = '425 TOO_EARLY',
  _426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  _428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  _429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  _431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  _451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  _500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  _501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  _502BADGATEWAY = '502 BAD_GATEWAY',
  _503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  _504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  _505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  _506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  _507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  _508LOOPDETECTED = '508 LOOP_DETECTED',
  _509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  _510NOTEXTENDED = '510 NOT_EXTENDED',
  _511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

/**
 *
 * @export
 * @interface MtsBankExceptionCause
 */
export interface MtsBankExceptionCause {
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankExceptionCause
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCause
   */
  message?: string
  /**
   *
   * @type {Array<MtsBankExceptionCauseSuppressed>}
   * @memberof MtsBankExceptionCause
   */
  suppressed?: Array<MtsBankExceptionCauseSuppressed>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCause
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface MtsBankExceptionCauseStackTrace
 */
export interface MtsBankExceptionCauseStackTrace {
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  classLoaderName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  moduleName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  moduleVersion?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  methodName?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  fileName?: string
  /**
   *
   * @type {number}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  lineNumber?: number
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  className?: string
  /**
   *
   * @type {boolean}
   * @memberof MtsBankExceptionCauseStackTrace
   */
  nativeMethod?: boolean
}
/**
 *
 * @export
 * @interface MtsBankExceptionCauseSuppressed
 */
export interface MtsBankExceptionCauseSuppressed {
  /**
   *
   * @type {Array<MtsBankExceptionCauseStackTrace>}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  stackTrace?: Array<MtsBankExceptionCauseStackTrace>
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankExceptionCauseSuppressed
   */
  localizedMessage?: string
}
/**
 *
 * @export
 * @interface NotifyInfo
 */
export interface NotifyInfo {
  /**
   *
   * @type {string}
   * @memberof NotifyInfo
   */
  type?: NotifyInfoTypeEnum
  /**
   *
   * @type {string}
   * @memberof NotifyInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof NotifyInfo
   */
  description?: string
  /**
   *
   * @type {ButtonAction}
   * @memberof NotifyInfo
   */
  primaryButtonAction?: ButtonAction
}

/**
 * @export
 * @enum {string}
 */
export enum NotifyInfoTypeEnum {
  WARNING = 'WARNING',
  INFO = 'INFO',
  ERROR = 'ERROR',
}

/**
 *
 * @export
 * @interface OpenDeposit
 */
export interface OpenDeposit {
  /**
   *
   * @type {number}
   * @memberof OpenDeposit
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof OpenDeposit
   */
  srcPhone?: string
  /**
   *
   * @type {string}
   * @memberof OpenDeposit
   */
  bankClientId?: string
  /**
   *
   * @type {string}
   * @memberof OpenDeposit
   */
  rqId?: string
  /**
   *
   * @type {string}
   * @memberof OpenDeposit
   */
  depTypeCode?: string
  /**
   *
   * @type {number}
   * @memberof OpenDeposit
   */
  term?: number
  /**
   *
   * @type {string}
   * @memberof OpenDeposit
   */
  promoCode?: string
  /**
   *
   * @type {AmountInfo}
   * @memberof OpenDeposit
   */
  amountInfo?: AmountInfo
  /**
   *
   * @type {RateInfo}
   * @memberof OpenDeposit
   */
  rateInfo?: RateInfo
  /**
   *
   * @type {RefundInfo}
   * @memberof OpenDeposit
   */
  refundInfo?: RefundInfo
  /**
   *
   * @type {TaxResidentInfo}
   * @memberof OpenDeposit
   */
  taxResidentInfo?: TaxResidentInfo
}
/**
 *
 * @export
 * @interface OpenDepositResponse
 */
export interface OpenDepositResponse {
  /**
   *
   * @type {string}
   * @memberof OpenDepositResponse
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof OpenDepositResponse
   */
  rqId?: string
}
/**
 *
 * @export
 * @interface ProductConditionV1
 */
export interface ProductConditionV1 {
  /**
   *
   * @type {string}
   * @memberof ProductConditionV1
   */
  depTypeCode?: string
  /**
   *
   * @type {string}
   * @memberof ProductConditionV1
   */
  currency?: string
  /**
   *
   * @type {number}
   * @memberof ProductConditionV1
   */
  term?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV1
   */
  minAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV1
   */
  maxAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV1
   */
  irreducibleAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV1
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV1
   */
  finalRate?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV1
   */
  increase?: number
  /**
   *
   * @type {Array<Segment>}
   * @memberof ProductConditionV1
   */
  segments?: Array<Segment>
}
/**
 *
 * @export
 * @interface ProductConditionV2
 */
export interface ProductConditionV2 {
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  addCashPeriodBegin?: number

  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  addCashText?: string
  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  depTypeCode?: string
  /**
   *
   * @type {boolean}
   * @memberof ProductConditionV2
   */
  enableAddCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductConditionV2
   */
  enableGetCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductConditionV2
   */
  enableProlongation?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductConditionV2
   */
  enableCapitalization?: boolean
  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  ratePayPeriod?: string
  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  terminationRules?: string
  /**
   *
   * @type {string}
   * @memberof ProductConditionV2
   */
  currency?: string
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  term?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  minAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  maxAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  irreducibleAmount?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  yieldRate?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  finalRate?: number
  /**
   *
   * @type {number}
   * @memberof ProductConditionV2
   */
  increaseRate?: number
  /**
   *
   * @type {Array<Segment>}
   * @memberof ProductConditionV2
   */
  segments?: Array<Segment>
}
/**
 *
 * @export
 * @interface ProductResponseV1
 */
export interface ProductResponseV1 {
  /**
   *
   * @type {string}
   * @memberof ProductResponseV1
   */
  prodName?: string
  /**
   *
   * @type {Array<string>}
   * @memberof ProductResponseV1
   */
  availableCurrency?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof ProductResponseV1
   */
  enableAddCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductResponseV1
   */
  enableGetCash?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductResponseV1
   */
  enableProlongation?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductResponseV1
   */
  enableCapitalization?: boolean
  /**
   *
   * @type {string}
   * @memberof ProductResponseV1
   */
  ratePayPeriod?: string
  /**
   *
   * @type {string}
   * @memberof ProductResponseV1
   */
  offerUrl?: string
  /**
   *
   * @type {string}
   * @memberof ProductResponseV1
   */
  terminationRules?: string
  /**
   *
   * @type {Array<ProductConditionV1>}
   * @memberof ProductResponseV1
   */
  conditions?: Array<ProductConditionV1>
}
/**
 *
 * @export
 * @interface PromoCodeAvailabilityRequest
 */
export interface PromoCodeAvailabilityRequest {
  /**
   *
   * @type {string}
   * @memberof PromoCodeAvailabilityRequest
   */
  rqId?: string
  /**
   *
   * @type {string}
   * @memberof PromoCodeAvailabilityRequest
   */
  depTypeCode?: string
  /**
   *
   * @type {number}
   * @memberof PromoCodeAvailabilityRequest
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof PromoCodeAvailabilityRequest
   */
  curCode?: string
  /**
   *
   * @type {string}
   * @memberof PromoCodeAvailabilityRequest
   */
  promoCode?: string
}
/**
 *
 * @export
 * @interface RateAmount
 */
export interface RateAmount {
  /**
   *
   * @type {number}
   * @memberof RateAmount
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof RateAmount
   */
  cur?: string
}
/**
 *
 * @export
 * @interface RateInfo
 */
export interface RateInfo {
  /**
   *
   * @type {string}
   * @memberof RateInfo
   */
  payType?: RateInfoPayTypeEnum
  /**
   *
   * @type {string}
   * @memberof RateInfo
   */
  accountNumber?: string
  /**
   *
   * @type {number}
   * @memberof RateInfo
   */
  rate?: number
  /**
   *
   * @type {RateAmount}
   * @memberof RateInfo
   */
  minAmount?: RateAmount
  /**
   *
   * @type {RateAmount}
   * @memberof RateInfo
   */
  maxAmount?: RateAmount
  /**
   *
   * @type {RateAmount}
   * @memberof RateInfo
   */
  irreducibleAmount?: RateAmount
  /**
   *
   * @type {string}
   * @memberof RateInfo
   */
  hashedPAN?: string
}

/**
 * @export
 * @enum {string}
 */
export enum RateInfoPayTypeEnum {
  ACCEPTED = 'ACCEPTED',
  SUCCESS = 'SUCCESS',
  DECLINED = 'DECLINED',
  TOREPEAT = 'TO_REPEAT',
  CAPITALIZATION = 'CAPITALIZATION',
  PAYMENT = 'PAYMENT',
}

/**
 *
 * @export
 * @interface RefundInfo
 */
export interface RefundInfo {
  /**
   *
   * @type {string}
   * @memberof RefundInfo
   */
  refundType?: RefundInfoRefundTypeEnum
  /**
   *
   * @type {string}
   * @memberof RefundInfo
   */
  accountNumber?: string
  /**
   *
   * @type {string}
   * @memberof RefundInfo
   */
  hashedPAN?: string
}

/**
 * @export
 * @enum {string}
 */
export enum RefundInfoRefundTypeEnum {
  ACTIVECARD = 'ACTIVECARD',
  ACTIVEACCOUNT = 'ACTIVEACCOUNT',
}

/**
 *
 * @export
 * @interface Request
 */
export interface Request {
  /**
   *
   * @type {string}
   * @memberof Request
   */
  rqId?: string
  /**
   *
   * @type {string}
   * @memberof Request
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof Request
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof Request
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof Request
   */
  currency?: string
  /**
   *
   * @type {string}
   * @memberof Request
   */
  declineReasonCode?: string
}
/**
 *
 * @export
 * @interface ResponseApiCloseDepositDetailsResponse
 */
export interface ResponseApiCloseDepositDetailsResponse {
  /**
   *
   * @type {CloseDepositDetailsResponse}
   * @memberof ResponseApiCloseDepositDetailsResponse
   */
  data?: CloseDepositDetailsResponse
  /**
   *
   * @type {ErrorResponse}
   * @memberof ResponseApiCloseDepositDetailsResponse
   */
  error?: ErrorResponse
}
/**
 *
 * @export
 * @interface ResponseApiCloseDepositResponse
 */
export interface ResponseApiCloseDepositResponse {
  /**
   *
   * @type {CloseDepositResponse}
   * @memberof ResponseApiCloseDepositResponse
   */
  data?: CloseDepositResponse
  /**
   *
   * @type {ErrorResponse}
   * @memberof ResponseApiCloseDepositResponse
   */
  error?: ErrorResponse
}
/**
 *
 * @export
 * @interface ResponseApiDepositDetailsResponse
 */
export interface ResponseApiDepositDetailsResponse {
  /**
   *
   * @type {DepositDetailsResponse}
   * @memberof ResponseApiDepositDetailsResponse
   */
  data?: DepositDetailsResponse
  /**
   *
   * @type {ErrorResponse}
   * @memberof ResponseApiDepositDetailsResponse
   */
  error?: ErrorResponse
}
/**
 *
 * @export
 * @interface ResponseApiOpenDepositResponse
 */
export interface ResponseApiOpenDepositResponse {
  /**
   *
   * @type {OpenDepositResponse}
   * @memberof ResponseApiOpenDepositResponse
   */
  data?: OpenDepositResponse
  /**
   *
   * @type {ErrorResponse}
   * @memberof ResponseApiOpenDepositResponse
   */
  error?: ErrorResponse
}
/**
 *
 * @export
 * @interface ResponseApiProductConditionV2
 */
export interface ResponseApiProductConditionV2 {
  /**
   *
   * @type {ProductConditionV2}
   * @memberof ResponseApiProductConditionV2
   */
  data?: ProductConditionV2
  /**
   *
   * @type {ErrorResponse}
   * @memberof ResponseApiProductConditionV2
   */
  error?: ErrorResponse
}
/**
 *
 * @export
 * @interface ResponseApiProductResponseV1
 */
export interface ResponseApiProductResponseV1 {
  /**
   *
   * @type {ProductResponseV1}
   * @memberof ResponseApiProductResponseV1
   */
  data?: ProductResponseV1
  /**
   *
   * @type {ErrorResponse}
   * @memberof ResponseApiProductResponseV1
   */
  error?: ErrorResponse
}
/**
 *
 * @export
 * @interface ResponseApiWidgetListResponse
 */
export interface ResponseApiWidgetListResponse {
  /**
   *
   * @type {WidgetListResponse}
   * @memberof ResponseApiWidgetListResponse
   */
  data?: WidgetListResponse
  /**
   *
   * @type {ErrorResponse}
   * @memberof ResponseApiWidgetListResponse
   */
  error?: ErrorResponse
}
/**
 *
 * @export
 * @interface RqIdRequest
 */
export interface RqIdRequest {
  /**
   *
   * @type {string}
   * @memberof RqIdRequest
   */
  rqId?: string
}
/**
 *
 * @export
 * @interface Segment
 */
export interface Segment {
  /**
   *
   * @type {string}
   * @memberof Segment
   */
  segmentName?: string
  /**
   *
   * @type {string}
   * @memberof Segment
   */
  segmentCode?: string
  /**
   *
   * @type {number}
   * @memberof Segment
   */
  intRate?: number
}
/**
 *
 * @export
 * @interface TaxResidentInfo
 */
export interface TaxResidentInfo {
  /**
   *
   * @type {boolean}
   * @memberof TaxResidentInfo
   */
  noResidentCountry?: boolean
  /**
   *
   * @type {string}
   * @memberof TaxResidentInfo
   */
  residentCountryCode?: string
  /**
   *
   * @type {string}
   * @memberof TaxResidentInfo
   */
  countryOfBirthCode?: string
  /**
   *
   * @type {string}
   * @memberof TaxResidentInfo
   */
  tin?: string
  /**
   *
   * @type {boolean}
   * @memberof TaxResidentInfo
   */
  residentRF?: boolean
  /**
   *
   * @type {boolean}
   * @memberof TaxResidentInfo
   */
  residentUSA?: boolean
}
/**
 *
 * @export
 * @interface WidgetListResponse
 */
export interface WidgetListResponse {
  /**
   *
   * @type {Array<Deposit>}
   * @memberof WidgetListResponse
   */
  deposits?: Array<Deposit>
  /**
   *
   * @type {Array<Request>}
   * @memberof WidgetListResponse
   */
  requests?: Array<Request>
}

/**
 * CloseDepositApi - axios parameter creator
 * @export
 */
export const CloseDepositApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создать заявку на закрытие вклада
     * @param {CloseDepositRequest} closeDepositRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeDeposit(closeDepositRequest: CloseDepositRequest, options: any = {}): RequestArgs {
      // verify required parameter 'closeDepositRequest' is not null or undefined
      if (closeDepositRequest === null || closeDepositRequest === undefined) {
        throw new RequiredError(
          'closeDepositRequest',
          'Required parameter closeDepositRequest was null or undefined when calling closeDeposit.'
        )
      }
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/close-deposit-task`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof closeDepositRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(closeDepositRequest !== undefined ? closeDepositRequest : {})
        : closeDepositRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод для получения статуса заявки на закрытие вклада
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGet1(options: any = {}): RequestArgs {
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/close-deposit-task`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CloseDepositApi - functional programming interface
 * @export
 */
export const CloseDepositApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создать заявку на закрытие вклада
     * @param {CloseDepositRequest} closeDepositRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeDeposit(
      closeDepositRequest: CloseDepositRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseApiCloseDepositResponse> {
      const localVarAxiosArgs = CloseDepositApiAxiosParamCreator(configuration).closeDeposit(
        closeDepositRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод для получения статуса заявки на закрытие вклада
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGet1(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = CloseDepositApiAxiosParamCreator(configuration).handleGet1(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * CloseDepositApi - factory interface
 * @export
 */
export const CloseDepositApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Создать заявку на закрытие вклада
     * @param {CloseDepositRequest} closeDepositRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeDeposit(
      closeDepositRequest: CloseDepositRequest,
      options?: any
    ): AxiosPromise<ResponseApiCloseDepositResponse> {
      return CloseDepositApiFp(configuration).closeDeposit(closeDepositRequest, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод для получения статуса заявки на закрытие вклада
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGet1(options?: any): AxiosPromise<object> {
      return CloseDepositApiFp(configuration).handleGet1(options)(axios, basePath)
    },
  }
}

/**
 * CloseDepositApi - object-oriented interface
 * @export
 * @class CloseDepositApi
 * @extends {BaseAPI}
 */
export class CloseDepositApi extends BaseAPI {
  /**
   *
   * @summary Создать заявку на закрытие вклада
   * @param {CloseDepositRequest} closeDepositRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CloseDepositApi
   */
  public closeDeposit(closeDepositRequest: CloseDepositRequest, options?: any) {
    return CloseDepositApiFp(this.configuration).closeDeposit(closeDepositRequest, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод для получения статуса заявки на закрытие вклада
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CloseDepositApi
   */
  public handleGet1(options?: any) {
    return CloseDepositApiFp(this.configuration).handleGet1(options)(this.axios, this.basePath)
  }
}

/**
 * DepositDetailsApi - axios parameter creator
 * @export
 */
export const DepositDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения деталей вклада при попытке закрытия вклада клиентом
     * @param {string} accountNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCloseDepositDetails(accountNumber: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountNumber' is not null or undefined
      if (accountNumber === null || accountNumber === undefined) {
        throw new RequiredError(
          'accountNumber',
          'Required parameter accountNumber was null or undefined when calling getCloseDepositDetails.'
        )
      }
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/close-deposit-details`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      if (accountNumber !== undefined) {
        localVarQueryParameter['accountNumber'] = accountNumber
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод для получения деталей вклада
     * @param {string} accountNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepositDetails(accountNumber: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountNumber' is not null or undefined
      if (accountNumber === null || accountNumber === undefined) {
        throw new RequiredError(
          'accountNumber',
          'Required parameter accountNumber was null or undefined when calling getDepositDetails.'
        )
      }
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/deposit-details`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      if (accountNumber !== undefined) {
        localVarQueryParameter['accountNumber'] = accountNumber
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DepositDetailsApi - functional programming interface
 * @export
 */
export const DepositDetailsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения деталей вклада при попытке закрытия вклада клиентом
     * @param {string} accountNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCloseDepositDetails(
      accountNumber: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseApiCloseDepositDetailsResponse> {
      const localVarAxiosArgs = DepositDetailsApiAxiosParamCreator(configuration).getCloseDepositDetails(
        accountNumber,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод для получения деталей вклада
     * @param {string} accountNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepositDetails(
      accountNumber: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseApiDepositDetailsResponse> {
      const localVarAxiosArgs = DepositDetailsApiAxiosParamCreator(configuration).getDepositDetails(
        accountNumber,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DepositDetailsApi - factory interface
 * @export
 */
export const DepositDetailsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод для получения деталей вклада при попытке закрытия вклада клиентом
     * @param {string} accountNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCloseDepositDetails(accountNumber: string, options?: any): AxiosPromise<ResponseApiCloseDepositDetailsResponse> {
      return DepositDetailsApiFp(configuration).getCloseDepositDetails(accountNumber, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод для получения деталей вклада
     * @param {string} accountNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepositDetails(accountNumber: string, options?: any): AxiosPromise<ResponseApiDepositDetailsResponse> {
      return DepositDetailsApiFp(configuration).getDepositDetails(accountNumber, options)(axios, basePath)
    },
  }
}

/**
 * DepositDetailsApi - object-oriented interface
 * @export
 * @class DepositDetailsApi
 * @extends {BaseAPI}
 */
export class DepositDetailsApi extends BaseAPI {
  /**
   *
   * @summary Метод для получения деталей вклада при попытке закрытия вклада клиентом
   * @param {string} accountNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepositDetailsApi
   */
  public getCloseDepositDetails(accountNumber: string, options?: any) {
    return DepositDetailsApiFp(this.configuration).getCloseDepositDetails(accountNumber, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Метод для получения деталей вклада
   * @param {string} accountNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepositDetailsApi
   */
  public getDepositDetails(accountNumber: string, options?: any) {
    return DepositDetailsApiFp(this.configuration).getDepositDetails(accountNumber, options)(this.axios, this.basePath)
  }
}

/**
 * DepositProductApi - axios parameter creator
 * @export
 */
export const DepositProductApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения условий по вкладу
     * @param {string} prodCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsForClient(prodCode: string, options: any = {}): RequestArgs {
      // verify required parameter 'prodCode' is not null or undefined
      if (prodCode === null || prodCode === undefined) {
        throw new RequiredError(
          'prodCode',
          'Required parameter prodCode was null or undefined when calling getProductsForClient.'
        )
      }
      const localVarPath = `/invest-deposits-deposit/v1.0/product/condition-list`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      if (prodCode !== undefined) {
        localVarQueryParameter['prodCode'] = prodCode
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DepositProductApi - functional programming interface
 * @export
 */
export const DepositProductApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения условий по вкладу
     * @param {string} prodCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsForClient(
      prodCode: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseApiProductResponseV1> {
      const localVarAxiosArgs = DepositProductApiAxiosParamCreator(configuration).getProductsForClient(
        prodCode,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DepositProductApi - factory interface
 * @export
 */
export const DepositProductApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод для получения условий по вкладу
     * @param {string} prodCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsForClient(prodCode: string, options?: any): AxiosPromise<ResponseApiProductResponseV1> {
      return DepositProductApiFp(configuration).getProductsForClient(prodCode, options)(axios, basePath)
    },
  }
}

/**
 * DepositProductApi - object-oriented interface
 * @export
 * @class DepositProductApi
 * @extends {BaseAPI}
 */
export class DepositProductApi extends BaseAPI {
  /**
   *
   * @summary Метод для получения условий по вкладу
   * @param {string} prodCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepositProductApi
   */
  public getProductsForClient(prodCode: string, options?: any) {
    return DepositProductApiFp(this.configuration).getProductsForClient(prodCode, options)(this.axios, this.basePath)
  }
}

/**
 * DepositPromocodeApi - axios parameter creator
 * @export
 */
export const DepositPromocodeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для валидации промокода с текущими условиями
     * @param {PromoCodeAvailabilityRequest} promoCodeAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepositPromoCodeAvailability(
      promoCodeAvailabilityRequest: PromoCodeAvailabilityRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'promoCodeAvailabilityRequest' is not null or undefined
      if (promoCodeAvailabilityRequest === null || promoCodeAvailabilityRequest === undefined) {
        throw new RequiredError(
          'promoCodeAvailabilityRequest',
          'Required parameter promoCodeAvailabilityRequest was null or undefined when calling getDepositPromoCodeAvailability.'
        )
      }
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/check-promocode-availability`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof promoCodeAvailabilityRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(promoCodeAvailabilityRequest !== undefined ? promoCodeAvailabilityRequest : {})
        : promoCodeAvailabilityRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DepositPromocodeApi - functional programming interface
 * @export
 */
export const DepositPromocodeApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для валидации промокода с текущими условиями
     * @param {PromoCodeAvailabilityRequest} promoCodeAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepositPromoCodeAvailability(
      promoCodeAvailabilityRequest: PromoCodeAvailabilityRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseApiProductConditionV2> {
      const localVarAxiosArgs = DepositPromocodeApiAxiosParamCreator(configuration).getDepositPromoCodeAvailability(
        promoCodeAvailabilityRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DepositPromocodeApi - factory interface
 * @export
 */
export const DepositPromocodeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод для валидации промокода с текущими условиями
     * @param {PromoCodeAvailabilityRequest} promoCodeAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepositPromoCodeAvailability(
      promoCodeAvailabilityRequest: PromoCodeAvailabilityRequest,
      options?: any
    ): AxiosPromise<ResponseApiProductConditionV2> {
      return DepositPromocodeApiFp(configuration).getDepositPromoCodeAvailability(
        promoCodeAvailabilityRequest,
        options
      )(axios, basePath)
    },
  }
}

/**
 * DepositPromocodeApi - object-oriented interface
 * @export
 * @class DepositPromocodeApi
 * @extends {BaseAPI}
 */
export class DepositPromocodeApi extends BaseAPI {
  /**
   *
   * @summary Метод для валидации промокода с текущими условиями
   * @param {PromoCodeAvailabilityRequest} promoCodeAvailabilityRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepositPromocodeApi
   */
  public getDepositPromoCodeAvailability(promoCodeAvailabilityRequest: PromoCodeAvailabilityRequest, options?: any) {
    return DepositPromocodeApiFp(this.configuration).getDepositPromoCodeAvailability(
      promoCodeAvailabilityRequest,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * OpenDepositApi - axios parameter creator
 * @export
 */
export const OpenDepositApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения статуса заявки на открытие вклада
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGet(options: any = {}): RequestArgs {
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Создать заявку на открытие вклада
     * @param {OpenDeposit} openDeposit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openDeposit(openDeposit: OpenDeposit, options: any = {}): RequestArgs {
      // verify required parameter 'openDeposit' is not null or undefined
      if (openDeposit === null || openDeposit === undefined) {
        throw new RequiredError(
          'openDeposit',
          'Required parameter openDeposit was null or undefined when calling openDeposit.'
        )
      }
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/open-deposit-task`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof openDeposit !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(openDeposit !== undefined ? openDeposit : {})
        : openDeposit || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OpenDepositApi - functional programming interface
 * @export
 */
export const OpenDepositApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения статуса заявки на открытие вклада
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = OpenDepositApiAxiosParamCreator(configuration).handleGet(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Создать заявку на открытие вклада
     * @param {OpenDeposit} openDeposit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openDeposit(
      openDeposit: OpenDeposit,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseApiOpenDepositResponse> {
      const localVarAxiosArgs = OpenDepositApiAxiosParamCreator(configuration).openDeposit(openDeposit, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OpenDepositApi - factory interface
 * @export
 */
export const OpenDepositApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод для получения статуса заявки на открытие вклада
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGet(options?: any): AxiosPromise<object> {
      return OpenDepositApiFp(configuration).handleGet(options)(axios, basePath)
    },
    /**
     *
     * @summary Создать заявку на открытие вклада
     * @param {OpenDeposit} openDeposit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openDeposit(openDeposit: OpenDeposit, options?: any): AxiosPromise<ResponseApiOpenDepositResponse> {
      return OpenDepositApiFp(configuration).openDeposit(openDeposit, options)(axios, basePath)
    },
  }
}

/**
 * OpenDepositApi - object-oriented interface
 * @export
 * @class OpenDepositApi
 * @extends {BaseAPI}
 */
export class OpenDepositApi extends BaseAPI {
  /**
   *
   * @summary Метод для получения статуса заявки на открытие вклада
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenDepositApi
   */
  public handleGet(options?: any) {
    return OpenDepositApiFp(this.configuration).handleGet(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Создать заявку на открытие вклада
   * @param {OpenDeposit} openDeposit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenDepositApi
   */
  public openDeposit(openDeposit: OpenDeposit, options?: any) {
    return OpenDepositApiFp(this.configuration).openDeposit(openDeposit, options)(this.axios, this.basePath)
  }
}

/**
 * WidgetApi - axios parameter creator
 * @export
 */
export const WidgetApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения списка активных вкладов и актуальных заявок на вклад
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWidgetDepositList(options: any = {}): RequestArgs {
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/widget-list`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод для отключения отображения отклоненной заявки на закрытие вклада
     * @param {AccountNumberRequest} accountNumberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDeclinedCloseVisibility(accountNumberRequest: AccountNumberRequest, options: any = {}): RequestArgs {
      // verify required parameter 'accountNumberRequest' is not null or undefined
      if (accountNumberRequest === null || accountNumberRequest === undefined) {
        throw new RequiredError(
          'accountNumberRequest',
          'Required parameter accountNumberRequest was null or undefined when calling updateDeclinedCloseVisibility.'
        )
      }
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/hide-declined-close-task`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof accountNumberRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountNumberRequest !== undefined ? accountNumberRequest : {})
        : accountNumberRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод для отключения отображения отклоненной заявки на открытие вклада
     * @param {RqIdRequest} rqIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDeclinedOpenVisibility(rqIdRequest: RqIdRequest, options: any = {}): RequestArgs {
      // verify required parameter 'rqIdRequest' is not null or undefined
      if (rqIdRequest === null || rqIdRequest === undefined) {
        throw new RequiredError(
          'rqIdRequest',
          'Required parameter rqIdRequest was null or undefined when calling updateDeclinedOpenVisibility.'
        )
      }
      const localVarPath = `/invest-deposits-deposit/v1.0/deposit/hide-declined-task`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof rqIdRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(rqIdRequest !== undefined ? rqIdRequest : {})
        : rqIdRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WidgetApi - functional programming interface
 * @export
 */
export const WidgetApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод для получения списка активных вкладов и актуальных заявок на вклад
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWidgetDepositList(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseApiWidgetListResponse> {
      const localVarAxiosArgs = WidgetApiAxiosParamCreator(configuration).getWidgetDepositList(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод для отключения отображения отклоненной заявки на закрытие вклада
     * @param {AccountNumberRequest} accountNumberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDeclinedCloseVisibility(
      accountNumberRequest: AccountNumberRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = WidgetApiAxiosParamCreator(configuration).updateDeclinedCloseVisibility(
        accountNumberRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод для отключения отображения отклоненной заявки на открытие вклада
     * @param {RqIdRequest} rqIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDeclinedOpenVisibility(
      rqIdRequest: RqIdRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = WidgetApiAxiosParamCreator(configuration).updateDeclinedOpenVisibility(
        rqIdRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * WidgetApi - factory interface
 * @export
 */
export const WidgetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Метод для получения списка активных вкладов и актуальных заявок на вклад
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWidgetDepositList(options?: any): AxiosPromise<ResponseApiWidgetListResponse> {
      return WidgetApiFp(configuration).getWidgetDepositList(options)(axios, basePath)
    },
    /**
     *
     * @summary Метод для отключения отображения отклоненной заявки на закрытие вклада
     * @param {AccountNumberRequest} accountNumberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDeclinedCloseVisibility(accountNumberRequest: AccountNumberRequest, options?: any): AxiosPromise<void> {
      return WidgetApiFp(configuration).updateDeclinedCloseVisibility(accountNumberRequest, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод для отключения отображения отклоненной заявки на открытие вклада
     * @param {RqIdRequest} rqIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDeclinedOpenVisibility(rqIdRequest: RqIdRequest, options?: any): AxiosPromise<void> {
      return WidgetApiFp(configuration).updateDeclinedOpenVisibility(rqIdRequest, options)(axios, basePath)
    },
  }
}

/**
 * WidgetApi - object-oriented interface
 * @export
 * @class WidgetApi
 * @extends {BaseAPI}
 */
export class WidgetApi extends BaseAPI {
  /**
   *
   * @summary Метод для получения списка активных вкладов и актуальных заявок на вклад
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WidgetApi
   */
  public getWidgetDepositList(options?: any) {
    return WidgetApiFp(this.configuration).getWidgetDepositList(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод для отключения отображения отклоненной заявки на закрытие вклада
   * @param {AccountNumberRequest} accountNumberRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WidgetApi
   */
  public updateDeclinedCloseVisibility(accountNumberRequest: AccountNumberRequest, options?: any) {
    return WidgetApiFp(this.configuration).updateDeclinedCloseVisibility(accountNumberRequest, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Метод для отключения отображения отклоненной заявки на открытие вклада
   * @param {RqIdRequest} rqIdRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WidgetApi
   */
  public updateDeclinedOpenVisibility(rqIdRequest: RqIdRequest, options?: any) {
    return WidgetApiFp(this.configuration).updateDeclinedOpenVisibility(rqIdRequest, options)(this.axios, this.basePath)
  }
}
