import { window } from '@mtsbank/ui-kit'

const getInstanaKey = (): string =>
  window.location.host === 'payment.mts.ru' ? 'I7JkfkCWThKeft5xVHjwXw' : '0X-iGCWPQCyLL2rOOfDffg'
const INSTANA_KEY = getInstanaKey()

const getInstanaUrl = () =>
  window.location.host === 'payment.mts.ru' ? 'https://apm.mtsbank.ru' : 'https://apmt.mtsbank.ru'
const INSTANA_URL = getInstanaUrl()

export const INSTANA_SCRIPT = `(function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
    n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");
    ineum('reportingUrl', '${INSTANA_URL}/eum/');
    ineum('key', '${INSTANA_KEY}');
    ineum('trackSessions')`

export const INSTANA_SCRIPT_URL = `${INSTANA_URL}/eum/eum.min.js`
