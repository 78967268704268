// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Amount
 */
export interface Amount {
  /**
   * Итоговая сумма транзакции, возвращенная процессингом, включая сумму комиссии
   * @type {number}
   * @memberof Amount
   */
  total: number
  /**
   * Запрошенная клиентом сумма операции без учёта комиссий
   * @type {number}
   * @memberof Amount
   */
  base: number
  /**
   * Сумма комиссии Возвращается, если комиссия была посчитана в процессинге
   * @type {number}
   * @memberof Amount
   */
  fee?: number
  /**
   * Валюта заказа
   * @type {number}
   * @memberof Amount
   */
  currency: number
  /**
   * Буквенный код валюты заказа
   * @type {string}
   * @memberof Amount
   */
  currencyCode: string
  /**
   * Сумма комиссии, взимаемой с карты МТС Банком (эмитент). Возвращается, если ПЦ банка вернул данные в ответ на запрос расчета комиссии.
   * @type {number}
   * @memberof Amount
   */
  issuerFee?: number
  /**
   * Числовой ISO-код валюты комиссии, взимаемой с карты МТС Банком (эмитент). Возвращается, если ПЦ банка вернул данные в ответ на запрос расчета комиссии.
   * @type {number}
   * @memberof Amount
   */
  issuerFeeCurrency?: number
  /**
   * Бууквенный ISO-код валюты комиссии, взимаемой с карты МТС Банком (эмитент). Возвращается, если ПЦ банка вернул данные в ответ на запрос расчета комиссии.
   * @type {string}
   * @memberof Amount
   */
  issuerFeeCurrencyCode?: string
  /**
   * Общая сумма всех проведенных возвратов по данной операции
   * @type {number}
   * @memberof Amount
   */
  refunded?: number
  /**
   * Сумма, доступная к возврату для данной операции
   * @type {number}
   * @memberof Amount
   */
  availableForRefund?: number
}
/**
 *
 * @export
 * @interface BToCardTransferTermsRq
 */
export interface BToCardTransferTermsRq {
  /**
   * Уникальный идентификатор Платежного инструмента, источника перевода
   * @type {string}
   * @memberof BToCardTransferTermsRq
   */
  srcBindingId: string
  /**
   * Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны.
   * @type {string}
   * @memberof BToCardTransferTermsRq
   */
  targetPan: string
  /**
   * Сумма перевода (без учета комиссии).
   * @type {number}
   * @memberof BToCardTransferTermsRq
   */
  amount: number
  /**
   * Валюта перевода
   * @type {number}
   * @memberof BToCardTransferTermsRq
   */
  currency: number
  /**
   * Пользовательский комментарий для операции
   * @type {string}
   * @memberof BToCardTransferTermsRq
   */
  comment?: string
  /**
   * Номер телефона получателя перевода
   * @type {number}
   * @memberof BToCardTransferTermsRq
   */
  dstPhone?: number
  /**
   * Идентификатор операции в MasterPass
   * @type {string}
   * @memberof BToCardTransferTermsRq
   */
  masterpassOperationId?: string
}
/**
 *
 * @export
 * @interface BalanceLimit
 */
export interface BalanceLimit {
  /**
   *
   * @type {string}
   * @memberof BalanceLimit
   */
  amount?: string
  /**
   *
   * @type {string}
   * @memberof BalanceLimit
   */
  currency?: string
}
/**
 *
 * @export
 * @interface Binding
 */
export interface Binding {
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  mnemonic?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  createdDate?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  updatedDate?: string
  /**
   *
   * @type {boolean}
   * @memberof Binding
   */
  isDefaultBinding?: boolean
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  bindingType?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  bindingStatus?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  bankStatus?: string
  /**
   *
   * @type {number}
   * @memberof Binding
   */
  balance?: number
  /**
   *
   * @type {number}
   * @memberof Binding
   */
  currency?: number
  /**
   *
   * @type {object}
   * @memberof Binding
   */
  bindingParams?: object
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  color?: string
  /**
   *
   * @type {number}
   * @memberof Binding
   */
  minAmount?: number
  /**
   *
   * @type {number}
   * @memberof Binding
   */
  maxAmount?: number
  /**
   *
   * @type {number}
   * @memberof Binding
   */
  cardId?: number
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  maskedPan?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  maskedPhoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  phoneNumber?: string
  /**
   *
   * @type {LimitsInfo}
   * @memberof Binding
   */
  limitsInfo?: LimitsInfo
}
/**
 *
 * @export
 * @interface BindingOTP
 */
export interface BindingOTP {
  /**
   *
   * @type {string}
   * @memberof BindingOTP
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof BindingOTP
   */
  mnemonic?: string
  /**
   *
   * @type {string}
   * @memberof BindingOTP
   */
  createdDate?: string
  /**
   *
   * @type {string}
   * @memberof BindingOTP
   */
  updatedDate?: string
  /**
   *
   * @type {boolean}
   * @memberof BindingOTP
   */
  isDefaultBinding?: boolean
  /**
   *
   * @type {string}
   * @memberof BindingOTP
   */
  bindingType?: string
  /**
   *
   * @type {string}
   * @memberof BindingOTP
   */
  bindingStatus?: string
  /**
   *
   * @type {string}
   * @memberof BindingOTP
   */
  bankStatus?: string
  /**
   *
   * @type {number}
   * @memberof BindingOTP
   */
  balance?: number
  /**
   *
   * @type {number}
   * @memberof BindingOTP
   */
  currency?: number
  /**
   *
   * @type {object}
   * @memberof BindingOTP
   */
  bindingParams?: object
  /**
   *
   * @type {string}
   * @memberof BindingOTP
   */
  color?: string
  /**
   *
   * @type {number}
   * @memberof BindingOTP
   */
  minAmount?: number
  /**
   *
   * @type {number}
   * @memberof BindingOTP
   */
  maxAmount?: number
  /**
   *
   * @type {number}
   * @memberof BindingOTP
   */
  cardId?: number
  /**
   *
   * @type {LimitsInfo}
   * @memberof BindingOTP
   */
  limitsInfo?: LimitsInfo
}
/**
 *
 * @export
 * @interface CardToCardTransferTermsRq
 */
export interface CardToCardTransferTermsRq {
  /**
   *
   * @type {string}
   * @memberof CardToCardTransferTermsRq
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof CardToCardTransferTermsRq
   */
  pan?: string
  /**
   *
   * @type {number}
   * @memberof CardToCardTransferTermsRq
   */
  expiry?: number
  /**
   *
   * @type {string}
   * @memberof CardToCardTransferTermsRq
   */
  cardholderName?: string
  /**
   *
   * @type {string}
   * @memberof CardToCardTransferTermsRq
   */
  targetPan?: string
  /**
   *
   * @type {number}
   * @memberof CardToCardTransferTermsRq
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof CardToCardTransferTermsRq
   */
  currency?: number
  /**
   *
   * @type {string}
   * @memberof CardToCardTransferTermsRq
   */
  comment?: string
  /**
   *
   * @type {number}
   * @memberof CardToCardTransferTermsRq
   */
  srcPhone?: number
  /**
   *
   * @type {number}
   * @memberof CardToCardTransferTermsRq
   */
  dstPhone?: number
  /**
   *
   * @type {string}
   * @memberof CardToCardTransferTermsRq
   */
  masterpassOperationId?: string
  /**
   *
   * @type {string}
   * @memberof CardToCardTransferTermsRq
   */
  tokenizedTargetPan?: string
}
/**
 *
 * @export
 * @interface ConfirmTransactionByOtpRq
 */
export interface ConfirmTransactionByOtpRq {
  /**
   * Уникальный идентификатор пользователя
   * @type {string}
   * @memberof ConfirmTransactionByOtpRq
   */
  userId?: string
  /**
   * Уникальный номер операции на ПЦ
   * @type {string}
   * @memberof ConfirmTransactionByOtpRq
   */
  mdOrder: string
  /**
   * Одноразовый код-пароль
   * @type {string}
   * @memberof ConfirmTransactionByOtpRq
   */
  otp: string
}
/**
 *
 * @export
 * @interface ConfirmTransferByOtpRq
 */
export interface ConfirmTransferByOtpRq {
  /**
   * Уникальный идентификатор пользователя
   * @type {string}
   * @memberof ConfirmTransferByOtpRq
   */
  userId?: string
  /**
   * Уникальный номер операции на ПЦ
   * @type {string}
   * @memberof ConfirmTransferByOtpRq
   */
  mdOrder: string
  /**
   * Одноразовый код-пароль
   * @type {string}
   * @memberof ConfirmTransferByOtpRq
   */
  otp: string
}
/**
 *
 * @export
 * @interface ConvertedAmount
 */
export interface ConvertedAmount {
  /**
   * Сумма в целевой валюте
   * @type {number}
   * @memberof ConvertedAmount
   */
  amount?: number
  /**
   * Цифровой код целевой валюты
   * @type {string}
   * @memberof ConvertedAmount
   */
  currency?: string
  /**
   * Курс конвертации
   * @type {number}
   * @memberof ConvertedAmount
   */
  rate?: number
  /**
   * Единица конвертации
   * @type {number}
   * @memberof ConvertedAmount
   */
  unit?: number
}
/**
 *
 * @export
 * @interface CreateBindingAfterPaymentRq
 */
export interface CreateBindingAfterPaymentRq {
  /**
   * Уникальный идентификатор пользователя
   * @type {string}
   * @memberof CreateBindingAfterPaymentRq
   */
  userId: string
  /**
   * Уникальный номер операции на ПЦ
   * @type {string}
   * @memberof CreateBindingAfterPaymentRq
   */
  mdOrder: string
  /**
   * Мнемоническое имя карты
   * @type {string}
   * @memberof CreateBindingAfterPaymentRq
   */
  mnemonic: string
}
/**
 *
 * @export
 * @interface CreateBindingAfterPaymentRs
 */
export interface CreateBindingAfterPaymentRs {
  /**
   * Код ответа 0 если ошибок нет
   * @type {number}
   * @memberof CreateBindingAfterPaymentRs
   */
  errorCode: number
  /**
   * Расшифровка кода ответа
   * @type {string}
   * @memberof CreateBindingAfterPaymentRs
   */
  errorCause?: string
  /**
   * Информационное сообщение об ошибке для Пользователя
   * @type {string}
   * @memberof CreateBindingAfterPaymentRs
   */
  errorMessage?: string
  /**
   *
   * @type {string}
   * @memberof CreateBindingAfterPaymentRs
   */
  confirmationType?: string
  /**
   * Уникальный номер операции в ПЦ ЭК
   * @type {string}
   * @memberof CreateBindingAfterPaymentRs
   */
  mdOrder?: string
  /**
   * Уникальный идентификатор ПИ
   * @type {string}
   * @memberof CreateBindingAfterPaymentRs
   */
  createdBindingId?: string
  /**
   * Параметр для проведения процедуры 3DSecure Если присутствует в ответе, то карта вовлечена в 3DSecure
   * @type {string}
   * @memberof CreateBindingAfterPaymentRs
   */
  paReq?: string
  /**
   * URL-адрес банка эмитента (сервер ACS), на который клиент должен быть перенаправлен
   * @type {string}
   * @memberof CreateBindingAfterPaymentRs
   */
  acsUrl?: string
  /**
   * Параметры платежных инструментов в кошельке. Возвращаются записи по всем инструментам в кошельке.
   * @type {Array<Binding>}
   * @memberof CreateBindingAfterPaymentRs
   */
  bindings?: Array<Binding>
}
/**
 *
 * @export
 * @interface LimitsInfo
 */
export interface LimitsInfo {
  /**
   *
   * @type {BalanceLimit}
   * @memberof LimitsInfo
   */
  balanceLimit?: BalanceLimit
}
/**
 *
 * @export
 * @interface Model3DS2FinishRq
 */
export interface Model3DS2FinishRq {
  /**
   *
   * @type {string}
   * @memberof Model3DS2FinishRq
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2FinishRq
   */
  mdOrder?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2FinishRq
   */
  cRes?: string
}
/**
 *
 * @export
 * @interface Model3DS2NotifyRq
 */
export interface Model3DS2NotifyRq {
  /**
   *
   * @type {string}
   * @memberof Model3DS2NotifyRq
   */
  mdOrder?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2NotifyRq
   */
  threeDSMethodData?: string
}
/**
 *
 * @export
 * @interface Model3DS2ProceedRq
 */
export interface Model3DS2ProceedRq {
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  mdOrder?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  threeDsRequestorUrl?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  browserAcceptHeader?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  browserIP?: string
  /**
   *
   * @type {boolean}
   * @memberof Model3DS2ProceedRq
   */
  browserJavaEnabled?: boolean
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  browserJavascriptEnabled?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  browserLanguage?: string
  /**
   *
   * @type {number}
   * @memberof Model3DS2ProceedRq
   */
  browserColorDepth?: number
  /**
   *
   * @type {number}
   * @memberof Model3DS2ProceedRq
   */
  browserScreenHeight?: number
  /**
   *
   * @type {number}
   * @memberof Model3DS2ProceedRq
   */
  browserScreenWidth?: number
  /**
   *
   * @type {number}
   * @memberof Model3DS2ProceedRq
   */
  browserTZ?: number
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  browserUserAgent?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  notificationUrl?: string
  /**
   *
   * @type {string}
   * @memberof Model3DS2ProceedRq
   */
  challengeWindowSize?: string
}
/**
 *
 * @export
 * @interface Model3DSFinishRq
 */
export interface Model3DSFinishRq {
  /**
   *
   * @type {string}
   * @memberof Model3DSFinishRq
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof Model3DSFinishRq
   */
  mdOrder?: string
  /**
   *
   * @type {string}
   * @memberof Model3DSFinishRq
   */
  PaRes?: string
}
/**
 *
 * @export
 * @interface OperationDetailsRq
 */
export interface OperationDetailsRq {
  /**
   * Уникальный идентификатор пользователя
   * @type {string}
   * @memberof OperationDetailsRq
   */
  userId?: string
  /**
   * Уникальный номер операции на ПЦ
   * @type {string}
   * @memberof OperationDetailsRq
   */
  mdOrder?: string
}
/**
 *
 * @export
 * @interface OperationDetailsRs
 */
export interface OperationDetailsRs {
  /**
   *
   * @type {number}
   * @memberof OperationDetailsRs
   */
  errorCode?: number
  /**
   *
   * @type {string}
   * @memberof OperationDetailsRs
   */
  errorCause?: string
  /**
   *
   * @type {string}
   * @memberof OperationDetailsRs
   */
  errorMessage?: string
  /**
   * Уникальный номер операции в ПЦ ЭК
   * @type {string}
   * @memberof OperationDetailsRs
   */
  mdOrder?: string
  /**
   * Состояние заказа
   * @type {number}
   * @memberof OperationDetailsRs
   */
  state?: number
  /**
   * Код завершения обработки операции
   * @type {number}
   * @memberof OperationDetailsRs
   */
  transactionErrorCode?: number
  /**
   * Дата и время совершения операции
   * @type {string}
   * @memberof OperationDetailsRs
   */
  date?: string
  /**
   * Номер ссылки из процессинга, формируется в ПЦ ЭК для внутренних операций, для операций по банковским картам используется параметр   authorizationRrn
   * @type {string}
   * @memberof OperationDetailsRs
   */
  refNum?: string
  /**
   * Номер ссылки из процессинга, формируется в ПЦ ЭК для внутренних операций, для операций по банковским картам используется параметр   authorizationRrn
   * @type {string}
   * @memberof OperationDetailsRs
   */
  refnum?: string
  /**
   * Код авторизации
   * @type {string}
   * @memberof OperationDetailsRs
   */
  approvalCode?: string
  /**
   * Код авторизации
   * @type {string}
   * @memberof OperationDetailsRs
   */
  authCode?: string
  /**
   * Тип операции:PAYMENT TRANSFER PURCHASE TOP_UP TRANSFER_ON_DEMAND VERIFY_PURCHASE
   * @type {string}
   * @memberof OperationDetailsRs
   */
  operationType?: string
  /**
   * Направление операции относительно кошелька. OUT – на чужие ПИ. IN – с чужих ПИ. INNER – между своми ПИ.
   * @type {string}
   * @memberof OperationDetailsRs
   */
  direction?: string
  /**
   *
   * @type {Amount}
   * @memberof OperationDetailsRs
   */
  amount?: Amount
  /**
   * RRN – Retrieval Reference Number – уникальный ссылочный номер транзакции по операциям с банковскими картами (Банковский RRN) В транзакциях reversal (отмена), refund (полный или частичный возврат) всегда передается RRN оригинальной операции.
   * @type {string}
   * @memberof OperationDetailsRs
   */
  authorizationRrn?: string
  /**
   *
   * @type {Binding}
   * @memberof OperationDetailsRs
   */
  srcBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof OperationDetailsRs
   */
  srcParentBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof OperationDetailsRs
   */
  dstBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof OperationDetailsRs
   */
  dstParentBinding?: Binding
  /**
   * Тип перевода
   * @type {string}
   * @memberof OperationDetailsRs
   */
  transferType?: string
  /**
   *
   * @type {TransferOnDemandData}
   * @memberof OperationDetailsRs
   */
  transferOnDemandData?: TransferOnDemandData
  /**
   *
   * @type {ServiceData}
   * @memberof OperationDetailsRs
   */
  service?: ServiceData
  /**
   * Пользовательский комментарий для операции
   * @type {string}
   * @memberof OperationDetailsRs
   */
  comment?: string
  /**
   * Количество бонусных баллов, начисленных за операцию
   * @type {number}
   * @memberof OperationDetailsRs
   */
  bonusPoints?: number
  /**
   * Способ подтверждения операции: FINISH_3DS FINISH_3DS2 OTP_PAYMENT_PAGE OTP_SMS_CALLBACK
   * @type {string}
   * @memberof OperationDetailsRs
   */
  confirmationType?: string
  /**
   * Дата и время, до которых операция может быть подтверждена пользователем.
   * @type {string}
   * @memberof OperationDetailsRs
   */
  otpExpiresAt?: string
  /**
   * Параметр для проведения процедуры 3DSecure Присутствует если confirmationType= FINISH_3DS и означает, что карта вовлечена в 3DSecure
   * @type {string}
   * @memberof OperationDetailsRs
   */
  PaReq?: string
  /**
   * URL-адрес банка эмитента (сервер ACS), на который клиент должен быть перенаправлен.
   * @type {string}
   * @memberof OperationDetailsRs
   */
  acsUrl?: string
  /**
   *
   * @type {ConvertedAmount}
   * @memberof OperationDetailsRs
   */
  convertedAmount?: ConvertedAmount
  /**
   * Идентификатор операции   MasterPass
   * @type {string}
   * @memberof OperationDetailsRs
   */
  masterpassOperationId?: string
  /**
   * Тип маркировки авторизационного запроса для операций MasterPass
   * @type {string}
   * @memberof OperationDetailsRs
   */
  masterPassMarkType?: string
  /**
   * Флаг, показывающий, что тразакция проводилась с использованием 3DS. Значение true будет возвращаться, если карта-источник вовлечена в 3DS.
   * @type {boolean}
   * @memberof OperationDetailsRs
   */
  is3dsPerformed?: boolean
  /**
   * Флаг, показывающий, что при вызове API было передано значение CVV2/CVC2.
   * @type {boolean}
   * @memberof OperationDetailsRs
   */
  isCvcProvided?: boolean
  /**
   * Клиентское название партнера, через которого была инициирована операция. Если по каким-либо причинам партнера найти не удалось, будет возвращено значение “UNKNOWN”
   * @type {string}
   * @memberof OperationDetailsRs
   */
  operationPartnerName?: string
}
/**
 *
 * @export
 * @interface PaymentRq
 */
export interface PaymentRq {
  /**
   *
   * @type {string}
   * @memberof PaymentRq
   */
  userId?: string
  /**
   * Уникальный номер операции в ПЦ
   * @type {string}
   * @memberof PaymentRq
   */
  mdOrder?: string
  /**
   *
   * @type {string}
   * @memberof PaymentRq
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof PaymentRq
   */
  pan?: string
  /**
   * Год и месяц действия карты.
   * @type {string}
   * @memberof PaymentRq
   */
  expiry?: string
  /**
   * Имя держателя карты
   * @type {string}
   * @memberof PaymentRq
   */
  cardholderName?: string
  /**
   * СVC-код карты, с которой осуществляется перевод
   * @type {number}
   * @memberof PaymentRq
   */
  cvc?: number
  /**
   * Уникальный идентификатор услуги поставщика
   * @type {string}
   * @memberof PaymentRq
   */
  serviceId?: string
  /**
   *
   * @type {ServiceParams}
   * @memberof PaymentRq
   */
  serviceParams?: ServiceParams
  /**
   * Сумма перевода (без учета комиссии).
   * @type {number}
   * @memberof PaymentRq
   */
  amount?: number
  /**
   * Валюта перевода
   * @type {number}
   * @memberof PaymentRq
   */
  currency?: number
  /**
   * Пользовательский комментарий для операции
   * @type {string}
   * @memberof PaymentRq
   */
  comment?: string
  /**
   * Идентификатор операции в MasterPass
   * @type {string}
   * @memberof PaymentRq
   */
  masterpassOperationId?: string
  /**
   * Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
   * @type {boolean}
   * @memberof PaymentRq
   */
  is3dsRequired?: boolean
}
/**
 *
 * @export
 * @interface ProviderReceiptParams
 */
export interface ProviderReceiptParams {
  /**
   * Идентификатор параметра, уникален в рамках используемого сервиса
   * @type {string}
   * @memberof ProviderReceiptParams
   */
  name?: string
  /**
   * Значение параметра
   * @type {string}
   * @memberof ProviderReceiptParams
   */
  value?: string
}
/**
 *
 * @export
 * @interface ReSendOtpRq
 */
export interface ReSendOtpRq {
  /**
   * Уникальный идентификатор пользователя
   * @type {string}
   * @memberof ReSendOtpRq
   */
  userId?: string
  /**
   * Уникальный номер операции на ПЦ
   * @type {string}
   * @memberof ReSendOtpRq
   */
  mdOrder?: string
}
/**
 *
 * @export
 * @interface ReSendOtpRs
 */
export interface ReSendOtpRs {
  /**
   *
   * @type {number}
   * @memberof ReSendOtpRs
   */
  errorCode?: number
  /**
   *
   * @type {string}
   * @memberof ReSendOtpRs
   */
  errorCause?: string
  /**
   *
   * @type {string}
   * @memberof ReSendOtpRs
   */
  errorMessage?: string
  /**
   *
   * @type {string}
   * @memberof ReSendOtpRs
   */
  errorMessageDesc?: string
  /**
   *
   * @type {number}
   * @memberof ReSendOtpRs
   */
  minAmount?: number
  /**
   *
   * @type {number}
   * @memberof ReSendOtpRs
   */
  maxAmount?: number
  /**
   * Уникальный номер операции в ПЦ ЭК
   * @type {string}
   * @memberof ReSendOtpRs
   */
  mdOrder?: string
  /**
   * Состояние заказа
   * @type {number}
   * @memberof ReSendOtpRs
   */
  state?: number
  /**
   * Код завершения обработки операции
   * @type {number}
   * @memberof ReSendOtpRs
   */
  transactionErrorCode?: number
  /**
   * Дата и время совершения операции
   * @type {string}
   * @memberof ReSendOtpRs
   */
  date?: string
  /**
   * Номер ссылки из процессинга, формируется в ПЦ ЭК для внутренних операций, для операций по банковским картам используется параметр   authorizationRrn
   * @type {string}
   * @memberof ReSendOtpRs
   */
  refNum?: string
  /**
   * Номер ссылки из процессинга, формируется в ПЦ ЭК для внутренних операций, для операций по банковским картам используется параметр   authorizationRrn
   * @type {string}
   * @memberof ReSendOtpRs
   */
  refnum?: string
  /**
   * Код авторизации
   * @type {string}
   * @memberof ReSendOtpRs
   */
  approvalCode?: string
  /**
   * Код авторизации
   * @type {string}
   * @memberof ReSendOtpRs
   */
  authCode?: string
  /**
   * Тип операции:PAYMENT TRANSFER PURCHASE TOP_UP TRANSFER_ON_DEMAND VERIFY_PURCHASE
   * @type {string}
   * @memberof ReSendOtpRs
   */
  operationType?: string
  /**
   * Направление операции относительно кошелька. OUT – на чужие ПИ. IN – с чужих ПИ. INNER – между своми ПИ.
   * @type {string}
   * @memberof ReSendOtpRs
   */
  direction?: string
  /**
   *
   * @type {Amount}
   * @memberof ReSendOtpRs
   */
  amount?: Amount
  /**
   * RRN – Retrieval Reference Number – уникальный ссылочный номер транзакции по операциям с банковскими картами (Банковский RRN) В транзакциях reversal (отмена), refund (полный или частичный возврат) всегда передается RRN оригинальной операции.
   * @type {string}
   * @memberof ReSendOtpRs
   */
  authorizationRrn?: string
  /**
   *
   * @type {Binding}
   * @memberof ReSendOtpRs
   */
  srcBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof ReSendOtpRs
   */
  srcParentBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof ReSendOtpRs
   */
  dstBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof ReSendOtpRs
   */
  dstParentBinding?: Binding
  /**
   * Тип перевода
   * @type {string}
   * @memberof ReSendOtpRs
   */
  transferType?: string
  /**
   *
   * @type {TransferOnDemandData}
   * @memberof ReSendOtpRs
   */
  transferOnDemandData?: TransferOnDemandData
  /**
   *
   * @type {ServiceData}
   * @memberof ReSendOtpRs
   */
  service?: ServiceData
  /**
   * Пользовательский комментарий для операции
   * @type {string}
   * @memberof ReSendOtpRs
   */
  comment?: string
  /**
   * Количество бонусных баллов, начисленных за операцию
   * @type {number}
   * @memberof ReSendOtpRs
   */
  bonusPoints?: number
  /**
   * Способ подтверждения операции: FINISH_3DS FINISH_3DS2 OTP_PAYMENT_PAGE OTP_SMS_CALLBACK
   * @type {string}
   * @memberof ReSendOtpRs
   */
  confirmationType?: string
  /**
   * Дата и время, до которых операция может быть подтверждена пользователем.
   * @type {string}
   * @memberof ReSendOtpRs
   */
  otpExpiresAt?: string
  /**
   * Параметр для проведения процедуры 3DSecure Присутствует если confirmationType= FINISH_3DS и означает, что карта вовлечена в 3DSecure
   * @type {string}
   * @memberof ReSendOtpRs
   */
  PaReq?: string
  /**
   * URL-адрес банка эмитента (сервер ACS), на который клиент должен быть перенаправлен.
   * @type {string}
   * @memberof ReSendOtpRs
   */
  acsUrl?: string
  /**
   *
   * @type {ConvertedAmount}
   * @memberof ReSendOtpRs
   */
  convertedAmount?: ConvertedAmount
  /**
   * Идентификатор операции   MasterPass
   * @type {string}
   * @memberof ReSendOtpRs
   */
  masterpassOperationId?: string
  /**
   * Тип маркировки авторизационного запроса для операций MasterPass
   * @type {string}
   * @memberof ReSendOtpRs
   */
  masterPassMarkType?: string
  /**
   * Флаг, показывающий, что тразакция проводилась с использованием 3DS. Значение true будет возвращаться, если карта-источник вовлечена в 3DS.
   * @type {boolean}
   * @memberof ReSendOtpRs
   */
  is3dsPerformed?: boolean
  /**
   * Флаг, показывающий, что при вызове API было передано значение CVV2/CVC2.
   * @type {boolean}
   * @memberof ReSendOtpRs
   */
  isCvcProvided?: boolean
  /**
   * Клиентское название партнера, через которого была инициирована операция. Если по каким-либо причинам партнера найти не удалось, будет возвращено значение “UNKNOWN”
   * @type {string}
   * @memberof ReSendOtpRs
   */
  operationPartnerName?: string
}
/**
 *
 * @export
 * @interface ServiceData
 */
export interface ServiceData {
  /**
   *
   * @type {string}
   * @memberof ServiceData
   */
  serviceId?: string
  /**
   *
   * @type {string}
   * @memberof ServiceData
   */
  serviceName?: string
  /**
   *
   * @type {string}
   * @memberof ServiceData
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof ServiceData
   */
  categoryName?: string
  /**
   *
   * @type {Array<ServiceParams>}
   * @memberof ServiceData
   */
  serviceParams?: Array<ServiceParams>
  /**
   *
   * @type {Array<ProviderReceiptParams>}
   * @memberof ServiceData
   */
  providerReceiptParams?: Array<ProviderReceiptParams>
  /**
   *
   * @type {string}
   * @memberof ServiceData
   */
  providerExternalId?: string
  /**
   *
   * @type {number}
   * @memberof ServiceData
   */
  providerServiceProviderId?: number
}
/**
 *
 * @export
 * @interface ServiceParams
 */
export interface ServiceParams {
  /**
   * Идентификатор параметра, уникален в рамках используемого сервиса
   * @type {string}
   * @memberof ServiceParams
   */
  name?: string
  /**
   * Значение параметра
   * @type {string}
   * @memberof ServiceParams
   */
  value?: string
}
/**
 *
 * @export
 * @interface TransactionResponsRs
 */
export interface TransactionResponsRs {
  /**
   *
   * @type {number}
   * @memberof TransactionResponsRs
   */
  errorCode?: number
  /**
   *
   * @type {string}
   * @memberof TransactionResponsRs
   */
  errorDesc?: string
  /**
   *
   * @type {string}
   * @memberof TransactionResponsRs
   */
  errorMessage?: string
  /**
   *
   * @type {string}
   * @memberof TransactionResponsRs
   */
  errorMessageDesc?: string
  /**
   * Уникальный номер операции в ПЦ ЭК
   * @type {string}
   * @memberof TransactionResponsRs
   */
  mdOrder?: string
  /**
   * Состояние заказа
   * @type {number}
   * @memberof TransactionResponsRs
   */
  state?: number
  /**
   * Код завершения обработки операции
   * @type {number}
   * @memberof TransactionResponsRs
   */
  transactionErrorCode?: number
  /**
   * Дата и время совершения операции
   * @type {string}
   * @memberof TransactionResponsRs
   */
  date?: string
  /**
   * Номер ссылки из процессинга, формируется в ПЦ ЭК для внутренних операций, для операций по банковским картам используется параметр   authorizationRrn
   * @type {string}
   * @memberof TransactionResponsRs
   */
  refNum?: string
  /**
   * Номер ссылки из процессинга, формируется в ПЦ ЭК для внутренних операций, для операций по банковским картам используется параметр   authorizationRrn
   * @type {string}
   * @memberof TransactionResponsRs
   */
  refnum?: string
  /**
   * Код авторизации
   * @type {string}
   * @memberof TransactionResponsRs
   */
  approvalCode?: string
  /**
   * Код авторизации
   * @type {string}
   * @memberof TransactionResponsRs
   */
  authCode?: string
  /**
   * Тип операции:PAYMENT TRANSFER PURCHASE TOP_UP TRANSFER_ON_DEMAND VERIFY_PURCHASE
   * @type {string}
   * @memberof TransactionResponsRs
   */
  operationType?: string
  /**
   * Направление операции относительно кошелька. OUT – на чужие ПИ. IN – с чужих ПИ. INNER – между своми ПИ.
   * @type {string}
   * @memberof TransactionResponsRs
   */
  direction?: string
  /**
   *
   * @type {Amount}
   * @memberof TransactionResponsRs
   */
  amount?: Amount
  /**
   * RRN – Retrieval Reference Number – уникальный ссылочный номер транзакции по операциям с банковскими картами (Банковский RRN) В транзакциях reversal (отмена), refund (полный или частичный возврат) всегда передается RRN оригинальной операции.
   * @type {string}
   * @memberof TransactionResponsRs
   */
  authorizationRrn?: string
  /**
   *
   * @type {BindingOTP}
   * @memberof TransactionResponsRs
   */
  srcBinding?: BindingOTP
  /**
   *
   * @type {BindingOTP}
   * @memberof TransactionResponsRs
   */
  srcParentBinding?: BindingOTP
  /**
   *
   * @type {BindingOTP}
   * @memberof TransactionResponsRs
   */
  dstBinding?: BindingOTP
  /**
   *
   * @type {BindingOTP}
   * @memberof TransactionResponsRs
   */
  dstParentBinding?: BindingOTP
  /**
   * Тип перевода
   * @type {string}
   * @memberof TransactionResponsRs
   */
  transferType?: string
  /**
   *
   * @type {TransferOnDemandData}
   * @memberof TransactionResponsRs
   */
  transferOnDemandData?: TransferOnDemandData
  /**
   *
   * @type {ServiceData}
   * @memberof TransactionResponsRs
   */
  service?: ServiceData
  /**
   * Пользовательский комментарий для операции
   * @type {string}
   * @memberof TransactionResponsRs
   */
  comment?: string
  /**
   * Количество бонусных баллов, начисленных за операцию
   * @type {number}
   * @memberof TransactionResponsRs
   */
  bonusPoints?: number
  /**
   * Способ подтверждения операции: FINISH_3DS FINISH_3DS2 OTP_PAYMENT_PAGE OTP_SMS_CALLBACK
   * @type {string}
   * @memberof TransactionResponsRs
   */
  confirmationType?: string
  /**
   * Дата и время, до которых операция может быть подтверждена пользователем.
   * @type {string}
   * @memberof TransactionResponsRs
   */
  otpExpiresAt?: string
  /**
   * Параметр для проведения процедуры 3DSecure Присутствует если confirmationType= FINISH_3DS и означает, что карта вовлечена в 3DSecure
   * @type {string}
   * @memberof TransactionResponsRs
   */
  PaReq?: string
  /**
   * Параметр для проведения процедуры 3DSecure Присутствует если confirmationType= FINISH_3DS2 и означает, что карта вовлечена в 3DSecure
   * @type {string}
   * @memberof TransactionResponsRs
   */
  cReq?: string
  /**
   * URL-адрес банка эмитента (сервер ACS), на который клиент должен быть перенаправлен.
   * @type {string}
   * @memberof TransactionResponsRs
   */
  acsUrl?: string
  /**
   *
   * @type {ConvertedAmount}
   * @memberof TransactionResponsRs
   */
  convertedAmount?: ConvertedAmount
  /**
   * Идентификатор операции   MasterPass
   * @type {string}
   * @memberof TransactionResponsRs
   */
  masterpassOperationId?: string
  /**
   * Тип маркировки авторизационного запроса для операций MasterPass
   * @type {string}
   * @memberof TransactionResponsRs
   */
  masterPassMarkType?: string
  /**
   * Флаг, показывающий, что тразакция проводилась с использованием 3DS. Значение true будет возвращаться, если карта-источник вовлечена в 3DS.
   * @type {boolean}
   * @memberof TransactionResponsRs
   */
  is3dsPerformed?: boolean
  /**
   * Флаг, показывающий, что при вызове API было передано значение CVV2/CVC2.
   * @type {boolean}
   * @memberof TransactionResponsRs
   */
  isCvcProvided?: boolean
  /**
   * Клиентское название партнера, через которого была инициирована операция. Если по каким-либо причинам партнера найти не удалось, будет возвращено значение “UNKNOWN”
   * @type {string}
   * @memberof TransactionResponsRs
   */
  operationPartnerName?: string
}
/**
 *
 * @export
 * @interface TransferBtoCardRq
 */
export interface TransferBtoCardRq {
  /**
   *
   * @type {string}
   * @memberof TransferBtoCardRq
   */
  userId?: string
  /**
   * Уникальный номер операции в ПЦ
   * @type {string}
   * @memberof TransferBtoCardRq
   */
  mdOrder?: string
  /**
   * Уникальный идентификатор Платежного инструмента, источника перевода
   * @type {string}
   * @memberof TransferBtoCardRq
   */
  srcBindingId?: string
  /**
   * СVC-код карты, с которой осуществляется перевод
   * @type {string}
   * @memberof TransferBtoCardRq
   */
  cvc?: string
  /**
   * Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
   * @type {string}
   * @memberof TransferBtoCardRq
   */
  targetPan?: string
  /**
   * Сумма перевода (без учета комиссии).
   * @type {number}
   * @memberof TransferBtoCardRq
   */
  amount?: number
  /**
   * Валюта перевода
   * @type {number}
   * @memberof TransferBtoCardRq
   */
  currency?: number
  /**
   * Пользовательский комментарий для операции
   * @type {string}
   * @memberof TransferBtoCardRq
   */
  comment?: string
  /**
   * Номер телефона получателя перевода
   * @type {number}
   * @memberof TransferBtoCardRq
   */
  dstPhone?: number
  /**
   * Идентификатор операции в MasterPass
   * @type {string}
   * @memberof TransferBtoCardRq
   */
  masterpassOperationId?: string
  /**
   * Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
   * @type {boolean}
   * @memberof TransferBtoCardRq
   */
  is3dsRequired?: boolean
}
/**
 *
 * @export
 * @interface TransferCardToCardRq
 */
export interface TransferCardToCardRq {
  /**
   *
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  userId?: string
  /**
   * Уникальный номер операции в ПЦ
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  mdOrder?: string
  /**
   *
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  pan?: string
  /**
   *
   * @type {number}
   * @memberof TransferCardToCardRq
   */
  expiry?: number
  /**
   *
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  cardholderName?: string
  /**
   * СVC-код карты, с которой осуществляется перевод
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  cvc?: string
  /**
   * Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  targetPan?: string
  /**
   * Сумма перевода (без учета комиссии).
   * @type {number}
   * @memberof TransferCardToCardRq
   */
  amount?: number
  /**
   * Валюта перевода
   * @type {number}
   * @memberof TransferCardToCardRq
   */
  currency?: number
  /**
   * Пользовательский комментарий для операции
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  comment?: string
  /**
   *
   * @type {number}
   * @memberof TransferCardToCardRq
   */
  srcPhone?: number
  /**
   * Номер телефона получателя перевода
   * @type {number}
   * @memberof TransferCardToCardRq
   */
  dstPhone?: number
  /**
   * Идентификатор операции в MasterPass
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  masterpassOperationId?: string
  /**
   * Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
   * @type {boolean}
   * @memberof TransferCardToCardRq
   */
  is3dsRequired?: boolean
  /**
   *
   * @type {string}
   * @memberof TransferCardToCardRq
   */
  tokenizedTargetPan?: string
}
/**
 *
 * @export
 * @interface TransferOnDemandData
 */
export interface TransferOnDemandData {
  /**
   *
   * @type {string}
   * @memberof TransferOnDemandData
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof TransferOnDemandData
   */
  date?: string
  /**
   *
   * @type {string}
   * @memberof TransferOnDemandData
   */
  expiresAt?: string
  /**
   *
   * @type {string}
   * @memberof TransferOnDemandData
   */
  status?: string
  /**
   *
   * @type {number}
   * @memberof TransferOnDemandData
   */
  sourcePhoneNumber?: number
  /**
   *
   * @type {number}
   * @memberof TransferOnDemandData
   */
  targetPhoneNumber?: number
  /**
   *
   * @type {number}
   * @memberof TransferOnDemandData
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof TransferOnDemandData
   */
  currency?: number
  /**
   *
   * @type {string}
   * @memberof TransferOnDemandData
   */
  comment?: string
}
/**
 *
 * @export
 * @interface TransferResponse
 */
export interface TransferResponse {
  /**
   *
   * @type {number}
   * @memberof TransferResponse
   */
  errorCode?: number
  /**
   *
   * @type {string}
   * @memberof TransferResponse
   */
  errorCause?: string
  /**
   *
   * @type {string}
   * @memberof TransferResponse
   */
  errorMessage?: string
  /**
   *
   * @type {string}
   * @memberof TransferResponse
   */
  errorMessageDesc?: string
  /**
   *
   * @type {number}
   * @memberof TransferResponse
   */
  minAmount?: number
  /**
   *
   * @type {number}
   * @memberof TransferResponse
   */
  maxAmount?: number
  /**
   * Уникальный номер операции в ПЦ ЭК
   * @type {string}
   * @memberof TransferResponse
   */
  mdOrder?: string
  /**
   * Состояние заказа
   * @type {number}
   * @memberof TransferResponse
   */
  state?: number
  /**
   * Код завершения обработки операции
   * @type {number}
   * @memberof TransferResponse
   */
  transactionErrorCode?: number
  /**
   * Дата и время совершения операции
   * @type {string}
   * @memberof TransferResponse
   */
  date?: string
  /**
   * Номер ссылки из процессинга, формируется в ПЦ ЭК для внутренних операций, для операций по банковским картам используется параметр   authorizationRrn
   * @type {string}
   * @memberof TransferResponse
   */
  refNum?: string
  /**
   * Номер ссылки из процессинга, формируется в ПЦ ЭК для внутренних операций, для операций по банковским картам используется параметр   authorizationRrn
   * @type {string}
   * @memberof TransferResponse
   */
  refnum?: string
  /**
   * Код авторизации
   * @type {string}
   * @memberof TransferResponse
   */
  approvalCode?: string
  /**
   * Код авторизации
   * @type {string}
   * @memberof TransferResponse
   */
  authCode?: string
  /**
   * Тип операции:PAYMENT TRANSFER PURCHASE TOP_UP TRANSFER_ON_DEMAND VERIFY_PURCHASE
   * @type {string}
   * @memberof TransferResponse
   */
  operationType?: string
  /**
   * Направление операции относительно кошелька. OUT – на чужие ПИ. IN – с чужих ПИ. INNER – между своми ПИ.
   * @type {string}
   * @memberof TransferResponse
   */
  direction?: string
  /**
   *
   * @type {Amount}
   * @memberof TransferResponse
   */
  amount?: Amount
  /**
   * RRN – Retrieval Reference Number – уникальный ссылочный номер транзакции по операциям с банковскими картами (Банковский RRN) В транзакциях reversal (отмена), refund (полный или частичный возврат) всегда передается RRN оригинальной операции.
   * @type {string}
   * @memberof TransferResponse
   */
  authorizationRrn?: string
  /**
   *
   * @type {Binding}
   * @memberof TransferResponse
   */
  srcBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof TransferResponse
   */
  srcParentBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof TransferResponse
   */
  dstBinding?: Binding
  /**
   *
   * @type {Binding}
   * @memberof TransferResponse
   */
  dstParentBinding?: Binding
  /**
   * Тип перевода
   * @type {string}
   * @memberof TransferResponse
   */
  transferType?: string
  /**
   *
   * @type {TransferOnDemandData}
   * @memberof TransferResponse
   */
  transferOnDemandData?: TransferOnDemandData
  /**
   *
   * @type {ServiceData}
   * @memberof TransferResponse
   */
  service?: ServiceData
  /**
   * Пользовательский комментарий для операции
   * @type {string}
   * @memberof TransferResponse
   */
  comment?: string
  /**
   * Количество бонусных баллов, начисленных за операцию
   * @type {number}
   * @memberof TransferResponse
   */
  bonusPoints?: number
  /**
   * Способ подтверждения операции: FINISH_3DS FINISH_3DS2 OTP_PAYMENT_PAGE OTP_SMS_CALLBACK
   * @type {string}
   * @memberof TransferResponse
   */
  confirmationType?: string
  /**
   * Дата и время, до которых операция может быть подтверждена пользователем.
   * @type {string}
   * @memberof TransferResponse
   */
  otpExpiresAt?: string
  /**
   * Параметр для проведения процедуры 3DSecure Присутствует если confirmationType= FINISH_3DS и означает, что карта вовлечена в 3DSecure
   * @type {string}
   * @memberof TransferResponse
   */
  PaReq?: string
  /**
   * Параметр для проведения процедуры 3DSecure Присутствует если confirmationType= FINISH_3DS2 и означает, что карта вовлечена в 3DSecure
   * @type {string}
   * @memberof TransferResponse
   */
  cReq?: string
  /**
   * URL-адрес банка эмитента (сервер ACS), на который клиент должен быть перенаправлен.
   * @type {string}
   * @memberof TransferResponse
   */
  acsUrl?: string
  /**
   *
   * @type {ConvertedAmount}
   * @memberof TransferResponse
   */
  convertedAmount?: ConvertedAmount
  /**
   * Идентификатор операции   MasterPass
   * @type {string}
   * @memberof TransferResponse
   */
  masterpassOperationId?: string
  /**
   * Тип маркировки авторизационного запроса для операций MasterPass
   * @type {string}
   * @memberof TransferResponse
   */
  masterPassMarkType?: string
  /**
   * Флаг, показывающий, что тразакция проводилась с использованием 3DS. Значение true будет возвращаться, если карта-источник вовлечена в 3DS.
   * @type {boolean}
   * @memberof TransferResponse
   */
  is3dsPerformed?: boolean
  /**
   * Флаг, показывающий, что при вызове API было передано значение CVV2/CVC2.
   * @type {boolean}
   * @memberof TransferResponse
   */
  isCvcProvided?: boolean
  /**
   * Клиентское название партнера, через которого была инициирована операция. Если по каким-либо причинам партнера найти не удалось, будет возвращено значение “UNKNOWN”
   * @type {string}
   * @memberof TransferResponse
   */
  operationPartnerName?: string
  /**
   *
   * @type {string}
   * @memberof TransferResponse
   */
  threeDsMethodUrl?: string
  /**
   *
   * @type {string}
   * @memberof TransferResponse
   */
  threeDsMethodData?: string
  /**
   *
   * @type {string}
   * @memberof TransferResponse
   */
  threeDsServerTransId?: string
}
/**
 *
 * @export
 * @interface TransferTermsRq
 */
export interface TransferTermsRq {
  /**
   *
   * @type {string}
   * @memberof TransferTermsRq
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof TransferTermsRq
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof TransferTermsRq
   */
  pan?: string
  /**
   * Год и месяц действия карты.
   * @type {string}
   * @memberof TransferTermsRq
   */
  expiry?: string
  /**
   * Имя держателя карты
   * @type {string}
   * @memberof TransferTermsRq
   */
  cardholderName?: string
  /**
   * СVC-код
   * @type {string}
   * @memberof TransferTermsRq
   */
  cvc?: string
  /**
   * Уникальный идентификатор услуги поставщика
   * @type {string}
   * @memberof TransferTermsRq
   */
  serviceId: string
  /**
   * Сумма перевода (без учета комиссии).
   * @type {number}
   * @memberof TransferTermsRq
   */
  amount: number
  /**
   * Валюта перевода
   * @type {number}
   * @memberof TransferTermsRq
   */
  currency: number
  /**
   *
   * @type {ServiceParams}
   * @memberof TransferTermsRq
   */
  serviceParams?: ServiceParams
  /**
   *
   * @type {string}
   * @memberof TransferTermsRq
   */
  comment?: string
  /**
   * Идентификатор операции в MasterPass
   * @type {string}
   * @memberof TransferTermsRq
   */
  masterpassOperationId?: string
}
/**
 *
 * @export
 * @interface TransferTermsRs
 */
export interface TransferTermsRs {
  /**
   *
   * @type {number}
   * @memberof TransferTermsRs
   */
  errorCode: number
  /**
   *
   * @type {string}
   * @memberof TransferTermsRs
   */
  mdOrder: string
  /**
   *
   * @type {Binding}
   * @memberof TransferTermsRs
   */
  srcBinding: Binding
  /**
   *
   * @type {Binding}
   * @memberof TransferTermsRs
   */
  dstBinding: Binding
  /**
   *
   * @type {Amount}
   * @memberof TransferTermsRs
   */
  amount?: Amount
  /**
   *
   * @type {string}
   * @memberof TransferTermsRs
   */
  errorCause?: string
  /**
   *
   * @type {string}
   * @memberof TransferTermsRs
   */
  errorMessage?: string
  /**
   *
   * @type {number}
   * @memberof TransferTermsRs
   */
  minAmount?: number
  /**
   *
   * @type {number}
   * @memberof TransferTermsRs
   */
  maxAmount?: number
}

/**
 * ControllerApi - axios parameter creator
 * @export
 */
export const ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DS2FinishRq} [model3DS2FinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2finish(zone: 'b' | 'anonymous', model3DS2FinishRq?: Model3DS2FinishRq, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling _3ds2finish.')
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/3ds2/finish.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof model3DS2FinishRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model3DS2FinishRq !== undefined ? model3DS2FinishRq : {})
        : model3DS2FinishRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [mdOrder]
     * @param {string} [threeDSMethodData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2notifyMethod(
      zone: 'b' | 'anonymous',
      mdOrder?: string,
      threeDSMethodData?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling _3ds2notifyMethod.')
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/3ds2/notifyThreeDsMethod.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (mdOrder !== undefined) {
        localVarFormParams.set('mdOrder', mdOrder as any)
      }

      if (threeDSMethodData !== undefined) {
        localVarFormParams.set('threeDSMethodData', threeDSMethodData as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [mdOrder]
     * @param {Model3DS2ProceedRq} [model3DS2ProceedRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2proceed(
      zone: 'b' | 'anonymous',
      mdOrder?: string,
      model3DS2ProceedRq?: Model3DS2ProceedRq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling _3ds2proceed.')
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/3ds2/proceed.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (mdOrder !== undefined) {
        localVarQueryParameter['mdOrder'] = mdOrder
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof model3DS2ProceedRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model3DS2ProceedRq !== undefined ? model3DS2ProceedRq : {})
        : model3DS2ProceedRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DSFinishRq} [model3DSFinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3dsFinish(zone: 'b' | 'anonymous', model3DSFinishRq?: Model3DSFinishRq, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling _3dsFinish.')
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/finish3dsTransfer.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof model3DSFinishRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model3DSFinishRq !== undefined ? model3DSFinishRq : {})
        : model3DSFinishRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DSFinishRq} [model3DSFinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3dsTransactionFinish(
      zone: 'b' | 'anonymous',
      model3DSFinishRq?: Model3DSFinishRq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError(
          'zone',
          'Required parameter zone was null or undefined when calling _3dsTransactionFinish.'
        )
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/finish3ds.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof model3DSFinishRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model3DSFinishRq !== undefined ? model3DSFinishRq : {})
        : model3DSFinishRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ConfirmTransactionByOtpRq} [confirmTransactionByOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransactionByOtp(
      zone: 'b' | 'anonymous',
      confirmTransactionByOtpRq?: ConfirmTransactionByOtpRq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError(
          'zone',
          'Required parameter zone was null or undefined when calling confirmTransactionByOtp.'
        )
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/confirmTransactionByOtp.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof confirmTransactionByOtpRq !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(confirmTransactionByOtpRq !== undefined ? confirmTransactionByOtpRq : {})
        : confirmTransactionByOtpRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ConfirmTransferByOtpRq} [confirmTransferByOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransferByOtp(
      zone: 'b' | 'anonymous',
      confirmTransferByOtpRq?: ConfirmTransferByOtpRq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError(
          'zone',
          'Required parameter zone was null or undefined when calling confirmTransferByOtp.'
        )
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/confirmTransferByOtp.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof confirmTransferByOtpRq !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(confirmTransferByOtpRq !== undefined ? confirmTransferByOtpRq : {})
        : confirmTransferByOtpRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Выполняется после успешного перевода если карта, использованная для перевода, не привязана к картам пользователя.
     * @summary Привязка карты использованной при оплате
     * @param {CreateBindingAfterPaymentRq} [createBindingAfterPaymentRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCardBindingAfterPayment(
      createBindingAfterPaymentRq?: CreateBindingAfterPaymentRq,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/ewallet/3.4/mts/createCardBindingAfterPayment.do`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof createBindingAfterPaymentRq !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createBindingAfterPaymentRq !== undefined ? createBindingAfterPaymentRq : {})
        : createBindingAfterPaymentRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Запрос предназначен для проверки допустимости перевода на основании лимитов, доступности платежных инструментов и других проверок. Также в рамках данного запроса рассчитывается комиссия за операцию. В случае возможности проведения операции в ответе возвращается mdOrder – уникальный номер операции в ПЦ ЭК. mdOrder можно использовать как входящий параметр запроса перевода, не указывая при этом параметры операции. Время жизни mdOrder на ПЦ ЭК ограничено (20 минут)
     * @summary Расчет комиссии
     * @param {string} srcBindingId Уникальный идентификатор Платежного инструмента, источника перевода
     * @param {string} targetPan Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны.
     * @param {number} amount Сумма перевода (без учета комиссии).
     * @param {number} currency Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBToCardTransferTermsDo(
      srcBindingId: string,
      targetPan: string,
      amount: number,
      currency: number,
      comment?: string,
      dstPhone?: number,
      masterpassOperationId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'srcBindingId' is not null or undefined
      if (srcBindingId === null || srcBindingId === undefined) {
        throw new RequiredError(
          'srcBindingId',
          'Required parameter srcBindingId was null or undefined when calling getBToCardTransferTermsDo.'
        )
      }
      // verify required parameter 'targetPan' is not null or undefined
      if (targetPan === null || targetPan === undefined) {
        throw new RequiredError(
          'targetPan',
          'Required parameter targetPan was null or undefined when calling getBToCardTransferTermsDo.'
        )
      }
      // verify required parameter 'amount' is not null or undefined
      if (amount === null || amount === undefined) {
        throw new RequiredError(
          'amount',
          'Required parameter amount was null or undefined when calling getBToCardTransferTermsDo.'
        )
      }
      // verify required parameter 'currency' is not null or undefined
      if (currency === null || currency === undefined) {
        throw new RequiredError(
          'currency',
          'Required parameter currency was null or undefined when calling getBToCardTransferTermsDo.'
        )
      }
      const localVarPath = `/b/bpc/ewallet/3.4/getBToCardTransferTerms.do`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (srcBindingId !== undefined) {
        localVarFormParams.set('srcBindingId', srcBindingId as any)
      }

      if (targetPan !== undefined) {
        localVarFormParams.set('targetPan', targetPan as any)
      }

      if (amount !== undefined) {
        localVarFormParams.set('amount', amount as any)
      }

      if (currency !== undefined) {
        localVarFormParams.set('currency', currency as any)
      }

      if (comment !== undefined) {
        localVarFormParams.set('comment', comment as any)
      }

      if (dstPhone !== undefined) {
        localVarFormParams.set('dstPhone', dstPhone as any)
      }

      if (masterpassOperationId !== undefined) {
        localVarFormParams.set('masterpassOperationId', masterpassOperationId as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Запрос предназначен для проверки допустимости перевода на основании лимитов, доступности платежных инструментов и других проверок. Также в рамках данного запроса рассчитывается комиссия за операцию.
     * @summary Расчет комиссии
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [pan]
     * @param {number} [expiry]
     * @param {string} [cardholderName]
     * @param {string} [targetPan]
     * @param {number} [amount]
     * @param {number} [currency]
     * @param {string} [comment]
     * @param {number} [srcPhone]
     * @param {number} [dstPhone]
     * @param {string} [masterpassOperationId]
     * @param {string} [tokenizedTargetPan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardToCardTransferTerms(
      zone: 'b' | 'anonymous',
      userId?: string,
      pan?: string,
      expiry?: number,
      cardholderName?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      srcPhone?: number,
      dstPhone?: number,
      masterpassOperationId?: string,
      tokenizedTargetPan?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError(
          'zone',
          'Required parameter zone was null or undefined when calling getCardToCardTransferTerms.'
        )
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/getCardToCardTransferTerms.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (userId !== undefined) {
        localVarFormParams.set('userId', userId as any)
      }

      if (pan !== undefined) {
        localVarFormParams.set('pan', pan as any)
      }

      if (expiry !== undefined) {
        localVarFormParams.set('expiry', expiry as any)
      }

      if (cardholderName !== undefined) {
        localVarFormParams.set('cardholderName', cardholderName as any)
      }

      if (targetPan !== undefined) {
        localVarFormParams.set('targetPan', targetPan as any)
      }

      if (amount !== undefined) {
        localVarFormParams.set('amount', amount as any)
      }

      if (currency !== undefined) {
        localVarFormParams.set('currency', currency as any)
      }

      if (comment !== undefined) {
        localVarFormParams.set('comment', comment as any)
      }

      if (srcPhone !== undefined) {
        localVarFormParams.set('srcPhone', srcPhone as any)
      }

      if (dstPhone !== undefined) {
        localVarFormParams.set('dstPhone', dstPhone as any)
      }

      if (masterpassOperationId !== undefined) {
        localVarFormParams.set('masterpassOperationId', masterpassOperationId as any)
      }

      if (tokenizedTargetPan !== undefined) {
        localVarFormParams.set('tokenizedTargetPan', tokenizedTargetPan as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId] Уникальный идентификатор пользователя
     * @param {string} [mdOrder] Уникальный номер операции на ПЦ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationDetailsDo(zone: 'b' | 'anonymous', userId?: string, mdOrder?: string, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError(
          'zone',
          'Required parameter zone was null or undefined when calling getOperationDetailsDo.'
        )
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/getOperationDetails.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (userId !== undefined) {
        localVarFormParams.set('userId', userId as any)
      }

      if (mdOrder !== undefined) {
        localVarFormParams.set('mdOrder', mdOrder as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Данным запросом Витрина передает в ПЦ ЭК информацию о платеже и получает в ответ информацию о размере комиссий и лимитах в зависимости от платежного инструмента, выбранного для оплаты
     * @summary Расчет комиссии
     * @param {'b' | 'anonymous'} zone
     * @param {string} serviceId Уникальный идентификатор услуги поставщика
     * @param {number} amount Сумма перевода (без учета комиссии).
     * @param {number} currency Валюта перевода
     * @param {string} [userId]
     * @param {string} [bindingId]
     * @param {string} [pan]
     * @param {string} [expiry] Год и месяц действия карты.
     * @param {string} [cardholderName] Имя держателя карты
     * @param {string} [cvc] СVC-код
     * @param {ServiceParams} [serviceParams]
     * @param {string} [comment]
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentsTermsDo(
      zone: 'b' | 'anonymous',
      serviceId: string,
      amount: number,
      currency: number,
      userId?: string,
      bindingId?: string,
      pan?: string,
      expiry?: string,
      cardholderName?: string,
      cvc?: string,
      serviceParams?: ServiceParams,
      comment?: string,
      masterpassOperationId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError(
          'zone',
          'Required parameter zone was null or undefined when calling getPaymentsTermsDo.'
        )
      }
      // verify required parameter 'serviceId' is not null or undefined
      if (serviceId === null || serviceId === undefined) {
        throw new RequiredError(
          'serviceId',
          'Required parameter serviceId was null or undefined when calling getPaymentsTermsDo.'
        )
      }
      // verify required parameter 'amount' is not null or undefined
      if (amount === null || amount === undefined) {
        throw new RequiredError(
          'amount',
          'Required parameter amount was null or undefined when calling getPaymentsTermsDo.'
        )
      }
      // verify required parameter 'currency' is not null or undefined
      if (currency === null || currency === undefined) {
        throw new RequiredError(
          'currency',
          'Required parameter currency was null or undefined when calling getPaymentsTermsDo.'
        )
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/getPaymentTerms.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (userId !== undefined) {
        localVarFormParams.set('userId', userId as any)
      }

      if (bindingId !== undefined) {
        localVarFormParams.set('bindingId', bindingId as any)
      }

      if (pan !== undefined) {
        localVarFormParams.set('pan', pan as any)
      }

      if (expiry !== undefined) {
        localVarFormParams.set('expiry', expiry as any)
      }

      if (cardholderName !== undefined) {
        localVarFormParams.set('cardholderName', cardholderName as any)
      }

      if (cvc !== undefined) {
        localVarFormParams.set('cvc', cvc as any)
      }

      if (serviceId !== undefined) {
        localVarFormParams.set('serviceId', serviceId as any)
      }

      if (amount !== undefined) {
        localVarFormParams.set('amount', amount as any)
      }

      if (currency !== undefined) {
        localVarFormParams.set('currency', currency as any)
      }

      if (serviceParams !== undefined) {
        localVarFormParams.set('serviceParams', serviceParams as any)
      }

      if (comment !== undefined) {
        localVarFormParams.set('comment', comment as any)
      }

      if (masterpassOperationId !== undefined) {
        localVarFormParams.set('masterpassOperationId', masterpassOperationId as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Данный запрос выполняется Витриной после запроса getPaymentTerms и подтверждения пользователем параметров операции.
     * @summary Произвести платеж или перевод
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [bindingId]
     * @param {string} [pan]
     * @param {string} [expiry] Год и месяц действия карты.
     * @param {string} [cardholderName] Имя держателя карты
     * @param {number} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [serviceId] Уникальный идентификатор услуги поставщика
     * @param {ServiceParams} [serviceParams]
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payment(
      zone: 'b' | 'anonymous',
      userId?: string,
      mdOrder?: string,
      bindingId?: string,
      pan?: string,
      expiry?: string,
      cardholderName?: string,
      cvc?: number,
      serviceId?: string,
      serviceParams?: ServiceParams,
      amount?: number,
      currency?: number,
      comment?: string,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling payment.')
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/payment.do`.replace(`{${'zone'}}`, encodeURIComponent(String(zone)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (userId !== undefined) {
        localVarFormParams.set('userId', userId as any)
      }

      if (mdOrder !== undefined) {
        localVarFormParams.set('mdOrder', mdOrder as any)
      }

      if (bindingId !== undefined) {
        localVarFormParams.set('bindingId', bindingId as any)
      }

      if (pan !== undefined) {
        localVarFormParams.set('pan', pan as any)
      }

      if (expiry !== undefined) {
        localVarFormParams.set('expiry', expiry as any)
      }

      if (cardholderName !== undefined) {
        localVarFormParams.set('cardholderName', cardholderName as any)
      }

      if (cvc !== undefined) {
        localVarFormParams.set('cvc', cvc as any)
      }

      if (serviceId !== undefined) {
        localVarFormParams.set('serviceId', serviceId as any)
      }

      if (serviceParams !== undefined) {
        localVarFormParams.set('serviceParams', serviceParams as any)
      }

      if (amount !== undefined) {
        localVarFormParams.set('amount', amount as any)
      }

      if (currency !== undefined) {
        localVarFormParams.set('currency', currency as any)
      }

      if (comment !== undefined) {
        localVarFormParams.set('comment', comment as any)
      }

      if (masterpassOperationId !== undefined) {
        localVarFormParams.set('masterpassOperationId', masterpassOperationId as any)
      }

      if (is3dsRequired !== undefined) {
        localVarFormParams.set('is3dsRequired', is3dsRequired as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ReSendOtpRq} [reSendOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reSendOtp(zone: 'b' | 'anonymous', reSendOtpRq?: ReSendOtpRq, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling reSendOtp.')
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/reSendOtp.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof reSendOtpRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(reSendOtpRq !== undefined ? reSendOtpRq : {})
        : reSendOtpRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Перевод с ПИ на номер карты
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [srcBindingId] Уникальный идентификатор Платежного инструмента, источника перевода
     * @param {string} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [targetPan] Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferBindingToCard(
      userId?: string,
      mdOrder?: string,
      srcBindingId?: string,
      cvc?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      dstPhone?: number,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/b/bpc/ewallet/3.4/transferBindingToCard.do`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (userId !== undefined) {
        localVarFormParams.set('userId', userId as any)
      }

      if (mdOrder !== undefined) {
        localVarFormParams.set('mdOrder', mdOrder as any)
      }

      if (srcBindingId !== undefined) {
        localVarFormParams.set('srcBindingId', srcBindingId as any)
      }

      if (cvc !== undefined) {
        localVarFormParams.set('cvc', cvc as any)
      }

      if (targetPan !== undefined) {
        localVarFormParams.set('targetPan', targetPan as any)
      }

      if (amount !== undefined) {
        localVarFormParams.set('amount', amount as any)
      }

      if (currency !== undefined) {
        localVarFormParams.set('currency', currency as any)
      }

      if (comment !== undefined) {
        localVarFormParams.set('comment', comment as any)
      }

      if (dstPhone !== undefined) {
        localVarFormParams.set('dstPhone', dstPhone as any)
      }

      if (masterpassOperationId !== undefined) {
        localVarFormParams.set('masterpassOperationId', masterpassOperationId as any)
      }

      if (is3dsRequired !== undefined) {
        localVarFormParams.set('is3dsRequired', is3dsRequired as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Перевод с карты на карту
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [pan]
     * @param {number} [expiry]
     * @param {string} [cardholderName]
     * @param {string} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [targetPan] Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [srcPhone]
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {string} [tokenizedTargetPan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCardToCard(
      zone: 'b' | 'anonymous',
      userId?: string,
      mdOrder?: string,
      pan?: string,
      expiry?: number,
      cardholderName?: string,
      cvc?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      srcPhone?: number,
      dstPhone?: number,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      tokenizedTargetPan?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError(
          'zone',
          'Required parameter zone was null or undefined when calling transferCardToCard.'
        )
      }
      const localVarPath = `/{zone}/bpc/ewallet/3.4/transferCardToCard.do`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (userId !== undefined) {
        localVarFormParams.set('userId', userId as any)
      }

      if (mdOrder !== undefined) {
        localVarFormParams.set('mdOrder', mdOrder as any)
      }

      if (pan !== undefined) {
        localVarFormParams.set('pan', pan as any)
      }

      if (expiry !== undefined) {
        localVarFormParams.set('expiry', expiry as any)
      }

      if (cardholderName !== undefined) {
        localVarFormParams.set('cardholderName', cardholderName as any)
      }

      if (cvc !== undefined) {
        localVarFormParams.set('cvc', cvc as any)
      }

      if (targetPan !== undefined) {
        localVarFormParams.set('targetPan', targetPan as any)
      }

      if (amount !== undefined) {
        localVarFormParams.set('amount', amount as any)
      }

      if (currency !== undefined) {
        localVarFormParams.set('currency', currency as any)
      }

      if (comment !== undefined) {
        localVarFormParams.set('comment', comment as any)
      }

      if (srcPhone !== undefined) {
        localVarFormParams.set('srcPhone', srcPhone as any)
      }

      if (dstPhone !== undefined) {
        localVarFormParams.set('dstPhone', dstPhone as any)
      }

      if (masterpassOperationId !== undefined) {
        localVarFormParams.set('masterpassOperationId', masterpassOperationId as any)
      }

      if (is3dsRequired !== undefined) {
        localVarFormParams.set('is3dsRequired', is3dsRequired as any)
      }

      if (tokenizedTargetPan !== undefined) {
        localVarFormParams.set('tokenizedTargetPan', tokenizedTargetPan as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ControllerApi - functional programming interface
 * @export
 */
export const ControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DS2FinishRq} [model3DS2FinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2finish(
      zone: 'b' | 'anonymous',
      model3DS2FinishRq?: Model3DS2FinishRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferResponse> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration)._3ds2finish(
        zone,
        model3DS2FinishRq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [mdOrder]
     * @param {string} [threeDSMethodData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2notifyMethod(
      zone: 'b' | 'anonymous',
      mdOrder?: string,
      threeDSMethodData?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration)._3ds2notifyMethod(
        zone,
        mdOrder,
        threeDSMethodData,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [mdOrder]
     * @param {Model3DS2ProceedRq} [model3DS2ProceedRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2proceed(
      zone: 'b' | 'anonymous',
      mdOrder?: string,
      model3DS2ProceedRq?: Model3DS2ProceedRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferResponse> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration)._3ds2proceed(
        zone,
        mdOrder,
        model3DS2ProceedRq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DSFinishRq} [model3DSFinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3dsFinish(
      zone: 'b' | 'anonymous',
      model3DSFinishRq?: Model3DSFinishRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferResponse> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration)._3dsFinish(
        zone,
        model3DSFinishRq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DSFinishRq} [model3DSFinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3dsTransactionFinish(
      zone: 'b' | 'anonymous',
      model3DSFinishRq?: Model3DSFinishRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponsRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration)._3dsTransactionFinish(
        zone,
        model3DSFinishRq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ConfirmTransactionByOtpRq} [confirmTransactionByOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransactionByOtp(
      zone: 'b' | 'anonymous',
      confirmTransactionByOtpRq?: ConfirmTransactionByOtpRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponsRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).confirmTransactionByOtp(
        zone,
        confirmTransactionByOtpRq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ConfirmTransferByOtpRq} [confirmTransferByOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransferByOtp(
      zone: 'b' | 'anonymous',
      confirmTransferByOtpRq?: ConfirmTransferByOtpRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferResponse> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).confirmTransferByOtp(
        zone,
        confirmTransferByOtpRq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Выполняется после успешного перевода если карта, использованная для перевода, не привязана к картам пользователя.
     * @summary Привязка карты использованной при оплате
     * @param {CreateBindingAfterPaymentRq} [createBindingAfterPaymentRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCardBindingAfterPayment(
      createBindingAfterPaymentRq?: CreateBindingAfterPaymentRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBindingAfterPaymentRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).createCardBindingAfterPayment(
        createBindingAfterPaymentRq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Запрос предназначен для проверки допустимости перевода на основании лимитов, доступности платежных инструментов и других проверок. Также в рамках данного запроса рассчитывается комиссия за операцию. В случае возможности проведения операции в ответе возвращается mdOrder – уникальный номер операции в ПЦ ЭК. mdOrder можно использовать как входящий параметр запроса перевода, не указывая при этом параметры операции. Время жизни mdOrder на ПЦ ЭК ограничено (20 минут)
     * @summary Расчет комиссии
     * @param {string} srcBindingId Уникальный идентификатор Платежного инструмента, источника перевода
     * @param {string} targetPan Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны.
     * @param {number} amount Сумма перевода (без учета комиссии).
     * @param {number} currency Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBToCardTransferTermsDo(
      srcBindingId: string,
      targetPan: string,
      amount: number,
      currency: number,
      comment?: string,
      dstPhone?: number,
      masterpassOperationId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferTermsRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getBToCardTransferTermsDo(
        srcBindingId,
        targetPan,
        amount,
        currency,
        comment,
        dstPhone,
        masterpassOperationId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Запрос предназначен для проверки допустимости перевода на основании лимитов, доступности платежных инструментов и других проверок. Также в рамках данного запроса рассчитывается комиссия за операцию.
     * @summary Расчет комиссии
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [pan]
     * @param {number} [expiry]
     * @param {string} [cardholderName]
     * @param {string} [targetPan]
     * @param {number} [amount]
     * @param {number} [currency]
     * @param {string} [comment]
     * @param {number} [srcPhone]
     * @param {number} [dstPhone]
     * @param {string} [masterpassOperationId]
     * @param {string} [tokenizedTargetPan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardToCardTransferTerms(
      zone: 'b' | 'anonymous',
      userId?: string,
      pan?: string,
      expiry?: number,
      cardholderName?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      srcPhone?: number,
      dstPhone?: number,
      masterpassOperationId?: string,
      tokenizedTargetPan?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferTermsRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getCardToCardTransferTerms(
        zone,
        userId,
        pan,
        expiry,
        cardholderName,
        targetPan,
        amount,
        currency,
        comment,
        srcPhone,
        dstPhone,
        masterpassOperationId,
        tokenizedTargetPan,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId] Уникальный идентификатор пользователя
     * @param {string} [mdOrder] Уникальный номер операции на ПЦ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationDetailsDo(
      zone: 'b' | 'anonymous',
      userId?: string,
      mdOrder?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationDetailsRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getOperationDetailsDo(
        zone,
        userId,
        mdOrder,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Данным запросом Витрина передает в ПЦ ЭК информацию о платеже и получает в ответ информацию о размере комиссий и лимитах в зависимости от платежного инструмента, выбранного для оплаты
     * @summary Расчет комиссии
     * @param {'b' | 'anonymous'} zone
     * @param {string} serviceId Уникальный идентификатор услуги поставщика
     * @param {number} amount Сумма перевода (без учета комиссии).
     * @param {number} currency Валюта перевода
     * @param {string} [userId]
     * @param {string} [bindingId]
     * @param {string} [pan]
     * @param {string} [expiry] Год и месяц действия карты.
     * @param {string} [cardholderName] Имя держателя карты
     * @param {string} [cvc] СVC-код
     * @param {ServiceParams} [serviceParams]
     * @param {string} [comment]
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentsTermsDo(
      zone: 'b' | 'anonymous',
      serviceId: string,
      amount: number,
      currency: number,
      userId?: string,
      bindingId?: string,
      pan?: string,
      expiry?: string,
      cardholderName?: string,
      cvc?: string,
      serviceParams?: ServiceParams,
      comment?: string,
      masterpassOperationId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferTermsRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getPaymentsTermsDo(
        zone,
        serviceId,
        amount,
        currency,
        userId,
        bindingId,
        pan,
        expiry,
        cardholderName,
        cvc,
        serviceParams,
        comment,
        masterpassOperationId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Данный запрос выполняется Витриной после запроса getPaymentTerms и подтверждения пользователем параметров операции.
     * @summary Произвести платеж или перевод
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [bindingId]
     * @param {string} [pan]
     * @param {string} [expiry] Год и месяц действия карты.
     * @param {string} [cardholderName] Имя держателя карты
     * @param {number} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [serviceId] Уникальный идентификатор услуги поставщика
     * @param {ServiceParams} [serviceParams]
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payment(
      zone: 'b' | 'anonymous',
      userId?: string,
      mdOrder?: string,
      bindingId?: string,
      pan?: string,
      expiry?: string,
      cardholderName?: string,
      cvc?: number,
      serviceId?: string,
      serviceParams?: ServiceParams,
      amount?: number,
      currency?: number,
      comment?: string,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponsRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).payment(
        zone,
        userId,
        mdOrder,
        bindingId,
        pan,
        expiry,
        cardholderName,
        cvc,
        serviceId,
        serviceParams,
        amount,
        currency,
        comment,
        masterpassOperationId,
        is3dsRequired,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ReSendOtpRq} [reSendOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reSendOtp(
      zone: 'b' | 'anonymous',
      reSendOtpRq?: ReSendOtpRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReSendOtpRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).reSendOtp(zone, reSendOtpRq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Перевод с ПИ на номер карты
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [srcBindingId] Уникальный идентификатор Платежного инструмента, источника перевода
     * @param {string} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [targetPan] Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferBindingToCard(
      userId?: string,
      mdOrder?: string,
      srcBindingId?: string,
      cvc?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      dstPhone?: number,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferResponse> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).transferBindingToCard(
        userId,
        mdOrder,
        srcBindingId,
        cvc,
        targetPan,
        amount,
        currency,
        comment,
        dstPhone,
        masterpassOperationId,
        is3dsRequired,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Перевод с карты на карту
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [pan]
     * @param {number} [expiry]
     * @param {string} [cardholderName]
     * @param {string} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [targetPan] Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [srcPhone]
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {string} [tokenizedTargetPan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCardToCard(
      zone: 'b' | 'anonymous',
      userId?: string,
      mdOrder?: string,
      pan?: string,
      expiry?: number,
      cardholderName?: string,
      cvc?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      srcPhone?: number,
      dstPhone?: number,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      tokenizedTargetPan?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferResponse> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).transferCardToCard(
        zone,
        userId,
        mdOrder,
        pan,
        expiry,
        cardholderName,
        cvc,
        targetPan,
        amount,
        currency,
        comment,
        srcPhone,
        dstPhone,
        masterpassOperationId,
        is3dsRequired,
        tokenizedTargetPan,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ControllerApi - factory interface
 * @export
 */
export const ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DS2FinishRq} [model3DS2FinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2finish(
      zone: 'b' | 'anonymous',
      model3DS2FinishRq?: Model3DS2FinishRq,
      options?: any
    ): AxiosPromise<TransferResponse> {
      return ControllerApiFp(configuration)._3ds2finish(zone, model3DS2FinishRq, options)(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [mdOrder]
     * @param {string} [threeDSMethodData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2notifyMethod(
      zone: 'b' | 'anonymous',
      mdOrder?: string,
      threeDSMethodData?: string,
      options?: any
    ): AxiosPromise<void> {
      return ControllerApiFp(configuration)._3ds2notifyMethod(
        zone,
        mdOrder,
        threeDSMethodData,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [mdOrder]
     * @param {Model3DS2ProceedRq} [model3DS2ProceedRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3ds2proceed(
      zone: 'b' | 'anonymous',
      mdOrder?: string,
      model3DS2ProceedRq?: Model3DS2ProceedRq,
      options?: any
    ): AxiosPromise<TransferResponse> {
      return ControllerApiFp(configuration)._3ds2proceed(zone, mdOrder, model3DS2ProceedRq, options)(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DSFinishRq} [model3DSFinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3dsFinish(
      zone: 'b' | 'anonymous',
      model3DSFinishRq?: Model3DSFinishRq,
      options?: any
    ): AxiosPromise<TransferResponse> {
      return ControllerApiFp(configuration)._3dsFinish(zone, model3DSFinishRq, options)(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {Model3DSFinishRq} [model3DSFinishRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _3dsTransactionFinish(
      zone: 'b' | 'anonymous',
      model3DSFinishRq?: Model3DSFinishRq,
      options?: any
    ): AxiosPromise<TransactionResponsRs> {
      return ControllerApiFp(configuration)._3dsTransactionFinish(zone, model3DSFinishRq, options)(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ConfirmTransactionByOtpRq} [confirmTransactionByOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransactionByOtp(
      zone: 'b' | 'anonymous',
      confirmTransactionByOtpRq?: ConfirmTransactionByOtpRq,
      options?: any
    ): AxiosPromise<TransactionResponsRs> {
      return ControllerApiFp(configuration).confirmTransactionByOtp(
        zone,
        confirmTransactionByOtpRq,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ConfirmTransferByOtpRq} [confirmTransferByOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTransferByOtp(
      zone: 'b' | 'anonymous',
      confirmTransferByOtpRq?: ConfirmTransferByOtpRq,
      options?: any
    ): AxiosPromise<TransferResponse> {
      return ControllerApiFp(configuration).confirmTransferByOtp(zone, confirmTransferByOtpRq, options)(axios, basePath)
    },
    /**
     * Выполняется после успешного перевода если карта, использованная для перевода, не привязана к картам пользователя.
     * @summary Привязка карты использованной при оплате
     * @param {CreateBindingAfterPaymentRq} [createBindingAfterPaymentRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCardBindingAfterPayment(
      createBindingAfterPaymentRq?: CreateBindingAfterPaymentRq,
      options?: any
    ): AxiosPromise<CreateBindingAfterPaymentRs> {
      return ControllerApiFp(configuration).createCardBindingAfterPayment(createBindingAfterPaymentRq, options)(
        axios,
        basePath
      )
    },
    /**
     * Запрос предназначен для проверки допустимости перевода на основании лимитов, доступности платежных инструментов и других проверок. Также в рамках данного запроса рассчитывается комиссия за операцию. В случае возможности проведения операции в ответе возвращается mdOrder – уникальный номер операции в ПЦ ЭК. mdOrder можно использовать как входящий параметр запроса перевода, не указывая при этом параметры операции. Время жизни mdOrder на ПЦ ЭК ограничено (20 минут)
     * @summary Расчет комиссии
     * @param {string} srcBindingId Уникальный идентификатор Платежного инструмента, источника перевода
     * @param {string} targetPan Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны.
     * @param {number} amount Сумма перевода (без учета комиссии).
     * @param {number} currency Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBToCardTransferTermsDo(
      srcBindingId: string,
      targetPan: string,
      amount: number,
      currency: number,
      comment?: string,
      dstPhone?: number,
      masterpassOperationId?: string,
      options?: any
    ): AxiosPromise<TransferTermsRs> {
      return ControllerApiFp(configuration).getBToCardTransferTermsDo(
        srcBindingId,
        targetPan,
        amount,
        currency,
        comment,
        dstPhone,
        masterpassOperationId,
        options
      )(axios, basePath)
    },
    /**
     * Запрос предназначен для проверки допустимости перевода на основании лимитов, доступности платежных инструментов и других проверок. Также в рамках данного запроса рассчитывается комиссия за операцию.
     * @summary Расчет комиссии
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [pan]
     * @param {number} [expiry]
     * @param {string} [cardholderName]
     * @param {string} [targetPan]
     * @param {number} [amount]
     * @param {number} [currency]
     * @param {string} [comment]
     * @param {number} [srcPhone]
     * @param {number} [dstPhone]
     * @param {string} [masterpassOperationId]
     * @param {string} [tokenizedTargetPan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardToCardTransferTerms(
      zone: 'b' | 'anonymous',
      userId?: string,
      pan?: string,
      expiry?: number,
      cardholderName?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      srcPhone?: number,
      dstPhone?: number,
      masterpassOperationId?: string,
      tokenizedTargetPan?: string,
      options?: any
    ): AxiosPromise<TransferTermsRs> {
      return ControllerApiFp(configuration).getCardToCardTransferTerms(
        zone,
        userId,
        pan,
        expiry,
        cardholderName,
        targetPan,
        amount,
        currency,
        comment,
        srcPhone,
        dstPhone,
        masterpassOperationId,
        tokenizedTargetPan,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId] Уникальный идентификатор пользователя
     * @param {string} [mdOrder] Уникальный номер операции на ПЦ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationDetailsDo(
      zone: 'b' | 'anonymous',
      userId?: string,
      mdOrder?: string,
      options?: any
    ): AxiosPromise<OperationDetailsRs> {
      return ControllerApiFp(configuration).getOperationDetailsDo(zone, userId, mdOrder, options)(axios, basePath)
    },
    /**
     * Данным запросом Витрина передает в ПЦ ЭК информацию о платеже и получает в ответ информацию о размере комиссий и лимитах в зависимости от платежного инструмента, выбранного для оплаты
     * @summary Расчет комиссии
     * @param {'b' | 'anonymous'} zone
     * @param {string} serviceId Уникальный идентификатор услуги поставщика
     * @param {number} amount Сумма перевода (без учета комиссии).
     * @param {number} currency Валюта перевода
     * @param {string} [userId]
     * @param {string} [bindingId]
     * @param {string} [pan]
     * @param {string} [expiry] Год и месяц действия карты.
     * @param {string} [cardholderName] Имя держателя карты
     * @param {string} [cvc] СVC-код
     * @param {ServiceParams} [serviceParams]
     * @param {string} [comment]
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentsTermsDo(
      zone: 'b' | 'anonymous',
      serviceId: string,
      amount: number,
      currency: number,
      userId?: string,
      bindingId?: string,
      pan?: string,
      expiry?: string,
      cardholderName?: string,
      cvc?: string,
      serviceParams?: ServiceParams,
      comment?: string,
      masterpassOperationId?: string,
      options?: any
    ): AxiosPromise<TransferTermsRs> {
      return ControllerApiFp(configuration).getPaymentsTermsDo(
        zone,
        serviceId,
        amount,
        currency,
        userId,
        bindingId,
        pan,
        expiry,
        cardholderName,
        cvc,
        serviceParams,
        comment,
        masterpassOperationId,
        options
      )(axios, basePath)
    },
    /**
     * Данный запрос выполняется Витриной после запроса getPaymentTerms и подтверждения пользователем параметров операции.
     * @summary Произвести платеж или перевод
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [bindingId]
     * @param {string} [pan]
     * @param {string} [expiry] Год и месяц действия карты.
     * @param {string} [cardholderName] Имя держателя карты
     * @param {number} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [serviceId] Уникальный идентификатор услуги поставщика
     * @param {ServiceParams} [serviceParams]
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payment(
      zone: 'b' | 'anonymous',
      userId?: string,
      mdOrder?: string,
      bindingId?: string,
      pan?: string,
      expiry?: string,
      cardholderName?: string,
      cvc?: number,
      serviceId?: string,
      serviceParams?: ServiceParams,
      amount?: number,
      currency?: number,
      comment?: string,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      options?: any
    ): AxiosPromise<TransactionResponsRs> {
      return ControllerApiFp(configuration).payment(
        zone,
        userId,
        mdOrder,
        bindingId,
        pan,
        expiry,
        cardholderName,
        cvc,
        serviceId,
        serviceParams,
        amount,
        currency,
        comment,
        masterpassOperationId,
        is3dsRequired,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {ReSendOtpRq} [reSendOtpRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reSendOtp(zone: 'b' | 'anonymous', reSendOtpRq?: ReSendOtpRq, options?: any): AxiosPromise<ReSendOtpRs> {
      return ControllerApiFp(configuration).reSendOtp(zone, reSendOtpRq, options)(axios, basePath)
    },
    /**
     *
     * @summary Перевод с ПИ на номер карты
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [srcBindingId] Уникальный идентификатор Платежного инструмента, источника перевода
     * @param {string} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [targetPan] Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferBindingToCard(
      userId?: string,
      mdOrder?: string,
      srcBindingId?: string,
      cvc?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      dstPhone?: number,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      options?: any
    ): AxiosPromise<TransferResponse> {
      return ControllerApiFp(configuration).transferBindingToCard(
        userId,
        mdOrder,
        srcBindingId,
        cvc,
        targetPan,
        amount,
        currency,
        comment,
        dstPhone,
        masterpassOperationId,
        is3dsRequired,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary Перевод с карты на карту
     * @param {'b' | 'anonymous'} zone
     * @param {string} [userId]
     * @param {string} [mdOrder] Уникальный номер операции в ПЦ
     * @param {string} [pan]
     * @param {number} [expiry]
     * @param {string} [cardholderName]
     * @param {string} [cvc] СVC-код карты, с которой осуществляется перевод
     * @param {string} [targetPan] Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
     * @param {number} [amount] Сумма перевода (без учета комиссии).
     * @param {number} [currency] Валюта перевода
     * @param {string} [comment] Пользовательский комментарий для операции
     * @param {number} [srcPhone]
     * @param {number} [dstPhone] Номер телефона получателя перевода
     * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
     * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
     * @param {string} [tokenizedTargetPan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCardToCard(
      zone: 'b' | 'anonymous',
      userId?: string,
      mdOrder?: string,
      pan?: string,
      expiry?: number,
      cardholderName?: string,
      cvc?: string,
      targetPan?: string,
      amount?: number,
      currency?: number,
      comment?: string,
      srcPhone?: number,
      dstPhone?: number,
      masterpassOperationId?: string,
      is3dsRequired?: boolean,
      tokenizedTargetPan?: string,
      options?: any
    ): AxiosPromise<TransferResponse> {
      return ControllerApiFp(configuration).transferCardToCard(
        zone,
        userId,
        mdOrder,
        pan,
        expiry,
        cardholderName,
        cvc,
        targetPan,
        amount,
        currency,
        comment,
        srcPhone,
        dstPhone,
        masterpassOperationId,
        is3dsRequired,
        tokenizedTargetPan,
        options
      )(axios, basePath)
    },
  }
}

/**
 * ControllerApi - object-oriented interface
 * @export
 * @class ControllerApi
 * @extends {BaseAPI}
 */
export class ControllerApi extends BaseAPI {
  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {Model3DS2FinishRq} [model3DS2FinishRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public _3ds2finish(zone: 'b' | 'anonymous', model3DS2FinishRq?: Model3DS2FinishRq, options?: any) {
    return ControllerApiFp(this.configuration)._3ds2finish(zone, model3DS2FinishRq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} [mdOrder]
   * @param {string} [threeDSMethodData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public _3ds2notifyMethod(zone: 'b' | 'anonymous', mdOrder?: string, threeDSMethodData?: string, options?: any) {
    return ControllerApiFp(this.configuration)._3ds2notifyMethod(
      zone,
      mdOrder,
      threeDSMethodData,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} [mdOrder]
   * @param {Model3DS2ProceedRq} [model3DS2ProceedRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public _3ds2proceed(
    zone: 'b' | 'anonymous',
    mdOrder?: string,
    model3DS2ProceedRq?: Model3DS2ProceedRq,
    options?: any
  ) {
    return ControllerApiFp(this.configuration)._3ds2proceed(
      zone,
      mdOrder,
      model3DS2ProceedRq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {Model3DSFinishRq} [model3DSFinishRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public _3dsFinish(zone: 'b' | 'anonymous', model3DSFinishRq?: Model3DSFinishRq, options?: any) {
    return ControllerApiFp(this.configuration)._3dsFinish(zone, model3DSFinishRq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {Model3DSFinishRq} [model3DSFinishRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public _3dsTransactionFinish(zone: 'b' | 'anonymous', model3DSFinishRq?: Model3DSFinishRq, options?: any) {
    return ControllerApiFp(this.configuration)._3dsTransactionFinish(
      zone,
      model3DSFinishRq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {ConfirmTransactionByOtpRq} [confirmTransactionByOtpRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public confirmTransactionByOtp(
    zone: 'b' | 'anonymous',
    confirmTransactionByOtpRq?: ConfirmTransactionByOtpRq,
    options?: any
  ) {
    return ControllerApiFp(this.configuration).confirmTransactionByOtp(
      zone,
      confirmTransactionByOtpRq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {ConfirmTransferByOtpRq} [confirmTransferByOtpRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public confirmTransferByOtp(zone: 'b' | 'anonymous', confirmTransferByOtpRq?: ConfirmTransferByOtpRq, options?: any) {
    return ControllerApiFp(this.configuration).confirmTransferByOtp(
      zone,
      confirmTransferByOtpRq,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Выполняется после успешного перевода если карта, использованная для перевода, не привязана к картам пользователя.
   * @summary Привязка карты использованной при оплате
   * @param {CreateBindingAfterPaymentRq} [createBindingAfterPaymentRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public createCardBindingAfterPayment(createBindingAfterPaymentRq?: CreateBindingAfterPaymentRq, options?: any) {
    return ControllerApiFp(this.configuration).createCardBindingAfterPayment(createBindingAfterPaymentRq, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   * Запрос предназначен для проверки допустимости перевода на основании лимитов, доступности платежных инструментов и других проверок. Также в рамках данного запроса рассчитывается комиссия за операцию. В случае возможности проведения операции в ответе возвращается mdOrder – уникальный номер операции в ПЦ ЭК. mdOrder можно использовать как входящий параметр запроса перевода, не указывая при этом параметры операции. Время жизни mdOrder на ПЦ ЭК ограничено (20 минут)
   * @summary Расчет комиссии
   * @param {string} srcBindingId Уникальный идентификатор Платежного инструмента, источника перевода
   * @param {string} targetPan Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны.
   * @param {number} amount Сумма перевода (без учета комиссии).
   * @param {number} currency Валюта перевода
   * @param {string} [comment] Пользовательский комментарий для операции
   * @param {number} [dstPhone] Номер телефона получателя перевода
   * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getBToCardTransferTermsDo(
    srcBindingId: string,
    targetPan: string,
    amount: number,
    currency: number,
    comment?: string,
    dstPhone?: number,
    masterpassOperationId?: string,
    options?: any
  ) {
    return ControllerApiFp(this.configuration).getBToCardTransferTermsDo(
      srcBindingId,
      targetPan,
      amount,
      currency,
      comment,
      dstPhone,
      masterpassOperationId,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Запрос предназначен для проверки допустимости перевода на основании лимитов, доступности платежных инструментов и других проверок. Также в рамках данного запроса рассчитывается комиссия за операцию.
   * @summary Расчет комиссии
   * @param {'b' | 'anonymous'} zone
   * @param {string} [userId]
   * @param {string} [pan]
   * @param {number} [expiry]
   * @param {string} [cardholderName]
   * @param {string} [targetPan]
   * @param {number} [amount]
   * @param {number} [currency]
   * @param {string} [comment]
   * @param {number} [srcPhone]
   * @param {number} [dstPhone]
   * @param {string} [masterpassOperationId]
   * @param {string} [tokenizedTargetPan]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getCardToCardTransferTerms(
    zone: 'b' | 'anonymous',
    userId?: string,
    pan?: string,
    expiry?: number,
    cardholderName?: string,
    targetPan?: string,
    amount?: number,
    currency?: number,
    comment?: string,
    srcPhone?: number,
    dstPhone?: number,
    masterpassOperationId?: string,
    tokenizedTargetPan?: string,
    options?: any
  ) {
    return ControllerApiFp(this.configuration).getCardToCardTransferTerms(
      zone,
      userId,
      pan,
      expiry,
      cardholderName,
      targetPan,
      amount,
      currency,
      comment,
      srcPhone,
      dstPhone,
      masterpassOperationId,
      tokenizedTargetPan,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} [userId] Уникальный идентификатор пользователя
   * @param {string} [mdOrder] Уникальный номер операции на ПЦ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getOperationDetailsDo(zone: 'b' | 'anonymous', userId?: string, mdOrder?: string, options?: any) {
    return ControllerApiFp(this.configuration).getOperationDetailsDo(
      zone,
      userId,
      mdOrder,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Данным запросом Витрина передает в ПЦ ЭК информацию о платеже и получает в ответ информацию о размере комиссий и лимитах в зависимости от платежного инструмента, выбранного для оплаты
   * @summary Расчет комиссии
   * @param {'b' | 'anonymous'} zone
   * @param {string} serviceId Уникальный идентификатор услуги поставщика
   * @param {number} amount Сумма перевода (без учета комиссии).
   * @param {number} currency Валюта перевода
   * @param {string} [userId]
   * @param {string} [bindingId]
   * @param {string} [pan]
   * @param {string} [expiry] Год и месяц действия карты.
   * @param {string} [cardholderName] Имя держателя карты
   * @param {string} [cvc] СVC-код
   * @param {ServiceParams} [serviceParams]
   * @param {string} [comment]
   * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getPaymentsTermsDo(
    zone: 'b' | 'anonymous',
    serviceId: string,
    amount: number,
    currency: number,
    userId?: string,
    bindingId?: string,
    pan?: string,
    expiry?: string,
    cardholderName?: string,
    cvc?: string,
    serviceParams?: ServiceParams,
    comment?: string,
    masterpassOperationId?: string,
    options?: any
  ) {
    return ControllerApiFp(this.configuration).getPaymentsTermsDo(
      zone,
      serviceId,
      amount,
      currency,
      userId,
      bindingId,
      pan,
      expiry,
      cardholderName,
      cvc,
      serviceParams,
      comment,
      masterpassOperationId,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Данный запрос выполняется Витриной после запроса getPaymentTerms и подтверждения пользователем параметров операции.
   * @summary Произвести платеж или перевод
   * @param {'b' | 'anonymous'} zone
   * @param {string} [userId]
   * @param {string} [mdOrder] Уникальный номер операции в ПЦ
   * @param {string} [bindingId]
   * @param {string} [pan]
   * @param {string} [expiry] Год и месяц действия карты.
   * @param {string} [cardholderName] Имя держателя карты
   * @param {number} [cvc] СVC-код карты, с которой осуществляется перевод
   * @param {string} [serviceId] Уникальный идентификатор услуги поставщика
   * @param {ServiceParams} [serviceParams]
   * @param {number} [amount] Сумма перевода (без учета комиссии).
   * @param {number} [currency] Валюта перевода
   * @param {string} [comment] Пользовательский комментарий для операции
   * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
   * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public payment(
    zone: 'b' | 'anonymous',
    userId?: string,
    mdOrder?: string,
    bindingId?: string,
    pan?: string,
    expiry?: string,
    cardholderName?: string,
    cvc?: number,
    serviceId?: string,
    serviceParams?: ServiceParams,
    amount?: number,
    currency?: number,
    comment?: string,
    masterpassOperationId?: string,
    is3dsRequired?: boolean,
    options?: any
  ) {
    return ControllerApiFp(this.configuration).payment(
      zone,
      userId,
      mdOrder,
      bindingId,
      pan,
      expiry,
      cardholderName,
      cvc,
      serviceId,
      serviceParams,
      amount,
      currency,
      comment,
      masterpassOperationId,
      is3dsRequired,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {ReSendOtpRq} [reSendOtpRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public reSendOtp(zone: 'b' | 'anonymous', reSendOtpRq?: ReSendOtpRq, options?: any) {
    return ControllerApiFp(this.configuration).reSendOtp(zone, reSendOtpRq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Перевод с ПИ на номер карты
   * @param {string} [userId]
   * @param {string} [mdOrder] Уникальный номер операции в ПЦ
   * @param {string} [srcBindingId] Уникальный идентификатор Платежного инструмента, источника перевода
   * @param {string} [cvc] СVC-код карты, с которой осуществляется перевод
   * @param {string} [targetPan] Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
   * @param {number} [amount] Сумма перевода (без учета комиссии).
   * @param {number} [currency] Валюта перевода
   * @param {string} [comment] Пользовательский комментарий для операции
   * @param {number} [dstPhone] Номер телефона получателя перевода
   * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
   * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public transferBindingToCard(
    userId?: string,
    mdOrder?: string,
    srcBindingId?: string,
    cvc?: string,
    targetPan?: string,
    amount?: number,
    currency?: number,
    comment?: string,
    dstPhone?: number,
    masterpassOperationId?: string,
    is3dsRequired?: boolean,
    options?: any
  ) {
    return ControllerApiFp(this.configuration).transferBindingToCard(
      userId,
      mdOrder,
      srcBindingId,
      cvc,
      targetPan,
      amount,
      currency,
      comment,
      dstPhone,
      masterpassOperationId,
      is3dsRequired,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Перевод с карты на карту
   * @param {'b' | 'anonymous'} zone
   * @param {string} [userId]
   * @param {string} [mdOrder] Уникальный номер операции в ПЦ
   * @param {string} [pan]
   * @param {number} [expiry]
   * @param {string} [cardholderName]
   * @param {string} [cvc] СVC-код карты, с которой осуществляется перевод
   * @param {string} [targetPan] Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
   * @param {number} [amount] Сумма перевода (без учета комиссии).
   * @param {number} [currency] Валюта перевода
   * @param {string} [comment] Пользовательский комментарий для операции
   * @param {number} [srcPhone]
   * @param {number} [dstPhone] Номер телефона получателя перевода
   * @param {string} [masterpassOperationId] Идентификатор операции в MasterPass
   * @param {boolean} [is3dsRequired] Флаг необходимости проведения 3ds вне зависимости от настроек способа подтверждения операции для партнёра
   * @param {string} [tokenizedTargetPan]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public transferCardToCard(
    zone: 'b' | 'anonymous',
    userId?: string,
    mdOrder?: string,
    pan?: string,
    expiry?: number,
    cardholderName?: string,
    cvc?: string,
    targetPan?: string,
    amount?: number,
    currency?: number,
    comment?: string,
    srcPhone?: number,
    dstPhone?: number,
    masterpassOperationId?: string,
    is3dsRequired?: boolean,
    tokenizedTargetPan?: string,
    options?: any
  ) {
    return ControllerApiFp(this.configuration).transferCardToCard(
      zone,
      userId,
      mdOrder,
      pan,
      expiry,
      cardholderName,
      cvc,
      targetPan,
      amount,
      currency,
      comment,
      srcPhone,
      dstPhone,
      masterpassOperationId,
      is3dsRequired,
      tokenizedTargetPan,
      options
    )(this.axios, this.basePath)
  }
}
