import { useEffect } from 'react'

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade'

import { useViewportWidth } from '@root/hooks/useViewportWidth'

/**
 * @param target In some scenarios, when scrolling is prohibited, some elements still need to scroll, at this point, pass the targetElement.
 */
export const ScrollLockComponent = ({ target }: { target: HTMLElement }) => {
  const viewportWidth = useViewportWidth()

  useEffect(() => {
    if (!target) {
      return
    }
    clearAllBodyScrollLocks()
    disableBodyScroll(target)

    return () => clearAllBodyScrollLocks()
  }, [target, viewportWidth])

  return null
}
