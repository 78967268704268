import React, { FC } from 'react'
import { Heading, Modal, Text } from '@mtsbank/ui-kit'

import { config } from './config'

export interface Props {
  statusCardType: string
}

export const CardStatuses: FC<Props> = ({ statusCardType }) => {
  if (!statusCardType || !config[statusCardType]) {
    return null
  }

  const { title, message } = config[statusCardType]

  return (
    <Modal.Body>
      <Heading h={3} align="center" sizemob="sm">
        {title}
      </Heading>
      <Text sizemob="md" align="center">
        {message}
      </Text>
    </Modal.Body>
  )
}
