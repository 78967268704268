import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getIncidents } from '@api/incidents'
import { IncidentsState } from '@reducers/incidents/types'
import { Place } from '@root/types/api/incident'
import { baseInitialState } from '@reducers/baseInitialState'

const NAMESPACE = 'incidents'

const initialState: IncidentsState = { ...baseInitialState, inProgress: false, lastReqTime: null, pagesViewed: [] }

const fetchGetIncidents = createAsyncThunk(`${NAMESPACE}/getIncidents`, async () => {
  const response = await getIncidents()

  return response?.incidents
})

const incidents = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    pushViewedPage(state, action: PayloadAction<Place>) {
      state.pagesViewed = state.pagesViewed.concat(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetIncidents.pending, () => ({
      ...initialState,
      lastReqTime: Date.now(),
      inProgress: true,
    }))
    builder.addCase(fetchGetIncidents.rejected, (state, action) => {
      state.error = action.error
      state.inProgress = false
    })
    builder.addCase(fetchGetIncidents.fulfilled, (state, action) => {
      state.data = action.payload
      state.inProgress = false
    })
  },
})

export { fetchGetIncidents }
export const { pushViewedPage } = incidents.actions
export const incidentsReducer = incidents.reducer
