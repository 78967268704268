// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AuthRs
 */
export interface AuthRs {
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  bankUserId?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  walletIdentificationLevel?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  msisdn?: string
  /**
   *
   * @type {number}
   * @memberof AuthRs
   */
  region?: number
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  regionCode?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  operator?: string
  /**
   *
   * @type {boolean}
   * @memberof AuthRs
   */
  bonusForOperation?: boolean
  /**
   *
   * @type {boolean}
   * @memberof AuthRs
   */
  canIdentification?: boolean
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  profileName?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  surname?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  patronymic?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  userToken?: string
  /**
   *
   * @type {string}
   * @memberof AuthRs
   */
  syncResult?: string
}
/**
 *
 * @export
 * @interface BalanceLimit
 */
export interface BalanceLimit {
  /**
   *
   * @type {string}
   * @memberof BalanceLimit
   */
  amount?: string
  /**
   *
   * @type {string}
   * @memberof BalanceLimit
   */
  currency?: string
}
/**
 *
 * @export
 * @interface Binding
 */
export interface Binding {
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  mnemonic?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  createdDate?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  updatedDate?: string
  /**
   *
   * @type {boolean}
   * @memberof Binding
   */
  isDefaultBinding?: boolean
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  bindingType?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  bindingStatus?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  balance?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  currency?: string
  /**
   *
   * @type {BindingParams}
   * @memberof Binding
   */
  bindingParams?: BindingParams
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  minAmount?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  maxAmount?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  cardId?: string
  /**
   *
   * @type {LimitsInfo}
   * @memberof Binding
   */
  limitsInfo?: LimitsInfo
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  phoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  maskedPhoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  maskedPan?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  expiry?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  expiresInLessThan?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  cardType?: string
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  cardStatus?: string
  /**
   *
   * @type {boolean}
   * @memberof Binding
   */
  is3DSecureBinding?: boolean
  /**
   *
   * @type {string}
   * @memberof Binding
   */
  bankStatus?: string
  /**
   *
   * @type {Array<string>}
   * @memberof Binding
   */
  tokenization_system?: Array<string>
}
/**
 *
 * @export
 * @interface BindingParams
 */
export interface BindingParams {
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  MTS_BANK_ACCOUNT_TYPE?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  TOKEN_CREATION_MODE?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  COLOR?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  MTS_BANK_CARD_PAN_HASH?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  MTS_BANK_CARD_ID?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  TOKEN_EXPIRY_MONTH?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  LABEL?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  CARD_ART_URL?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  MTS_BANK_ACCOUNT_NUMBER?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  MTS_BANK_CARD_TYPE?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  TOKEN_EXPIRY_YEAR?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  MTS_BANK_ACCOUNT_STATE?: string
  /**
   *
   * @type {string}
   * @memberof BindingParams
   */
  TOKEN_LAST4?: string
}
/**
 *
 * @export
 * @interface BindingRq
 */
export interface BindingRq {
  /**
   *
   * @type {string}
   * @memberof BindingRq
   */
  walletId?: string
  /**
   *
   * @type {string}
   * @memberof BindingRq
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof BindingRq
   */
  bindingStatus?: string
  /**
   *
   * @type {string}
   * @memberof BindingRq
   */
  bindingParams?: string
  /**
   *
   * @type {boolean}
   * @memberof BindingRq
   */
  includeBankAccount?: boolean
  /**
   *
   * @type {boolean}
   * @memberof BindingRq
   */
  includeTokenCard?: boolean
}
/**
 *
 * @export
 * @interface BindingRs
 */
export interface BindingRs {
  /**
   *
   * @type {string}
   * @memberof BindingRs
   */
  userId?: string
  /**
   *
   * @type {Array<Binding>}
   * @memberof BindingRs
   */
  bindings?: Array<Binding>
  /**
   *
   * @type {ErrorRs}
   * @memberof BindingRs
   */
  error?: ErrorRs
}
/**
 *
 * @export
 * @interface DeleteBindingRq
 */
export interface DeleteBindingRq {
  /**
   *
   * @type {string}
   * @memberof DeleteBindingRq
   */
  bindingId?: string
}
/**
 *
 * @export
 * @interface DeleteBindingRs
 */
export interface DeleteBindingRs {
  /**
   *
   * @type {ErrorRs}
   * @memberof DeleteBindingRs
   */
  error?: ErrorRs
}
/**
 *
 * @export
 * @interface ErrorRs
 */
export interface ErrorRs {
  /**
   *
   * @type {number}
   * @memberof ErrorRs
   */
  code?: number
  /**
   *
   * @type {string}
   * @memberof ErrorRs
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof ErrorRs
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof ErrorRs
   */
  details?: string
}
/**
 *
 * @export
 * @interface LimitsInfo
 */
export interface LimitsInfo {
  /**
   *
   * @type {BalanceLimit}
   * @memberof LimitsInfo
   */
  balanceLimit?: BalanceLimit
}
/**
 *
 * @export
 * @interface TokenizationCardDataRs
 */
export interface TokenizationCardDataRs {
  /**
   *
   * @type {string}
   * @memberof TokenizationCardDataRs
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof TokenizationCardDataRs
   */
  encryptedCardData?: string
  /**
   *
   * @type {ErrorRs}
   * @memberof TokenizationCardDataRs
   */
  error?: ErrorRs
}
/**
 *
 * @export
 * @interface UpdateBindingRq
 */
export interface UpdateBindingRq {
  /**
   *
   * @type {string}
   * @memberof UpdateBindingRq
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof UpdateBindingRq
   */
  bindingId?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateBindingRq
   */
  isDefaultBinding?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateBindingRq
   */
  mnemonic?: string
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof UpdateBindingRq
   */
  bindingParams?: { [key: string]: object }
}
/**
 *
 * @export
 * @interface UpdateBindingRs
 */
export interface UpdateBindingRs {
  /**
   *
   * @type {ErrorRs}
   * @memberof UpdateBindingRs
   */
  error?: ErrorRs
}
/**
 *
 * @export
 * @interface WalletRq
 */
export interface WalletRq {
  /**
   *
   * @type {string}
   * @memberof WalletRq
   */
  phone?: string
}
/**
 *
 * @export
 * @interface WalletRs
 */
export interface WalletRs {
  /**
   *
   * @type {string}
   * @memberof WalletRs
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof WalletRs
   */
  walletIdentificationLevel?: string
  /**
   *
   * @type {string}
   * @memberof WalletRs
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof WalletRs
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof WalletRs
   */
  createdDate?: string
  /**
   *
   * @type {string}
   * @memberof WalletRs
   */
  bankUserId?: string
  /**
   *
   * @type {ErrorRs}
   * @memberof WalletRs
   */
  error?: ErrorRs
}
/**
 *
 * @export
 * @interface WalletSyncRs
 */
export interface WalletSyncRs {
  /**
   *
   * @type {string}
   * @memberof WalletSyncRs
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof WalletSyncRs
   */
  bankUserId?: string
  /**
   *
   * @type {string}
   * @memberof WalletSyncRs
   */
  walletIdentificationLevel?: string
  /**
   *
   * @type {string}
   * @memberof WalletSyncRs
   */
  syncResult?: string
  /**
   *
   * @type {ErrorRs}
   * @memberof WalletSyncRs
   */
  error?: ErrorRs
}

/**
 * ControllerApi - axios parameter creator
 * @export
 */
export const ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    auth(options: any = {}): RequestArgs {
      const localVarPath = `/ewallet-profile/api/v1/auth`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {DeleteBindingRq} deleteBindingRq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBinding(deleteBindingRq: DeleteBindingRq, options: any = {}): RequestArgs {
      // verify required parameter 'deleteBindingRq' is not null or undefined
      if (deleteBindingRq === null || deleteBindingRq === undefined) {
        throw new RequiredError(
          'deleteBindingRq',
          'Required parameter deleteBindingRq was null or undefined when calling deleteBinding.'
        )
      }
      const localVarPath = `/ewallet-profile/api/v1/deleteBinding`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof deleteBindingRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(deleteBindingRq !== undefined ? deleteBindingRq : {})
        : deleteBindingRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ewalletSync(options: any = {}): RequestArgs {
      const localVarPath = `/ewallet-profile/api/v1/sync`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {BindingRq} [bindingRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindings1(bindingRq?: BindingRq, options: any = {}): RequestArgs {
      const localVarPath = `/ewallet-profile/api/v1/getBindings`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof bindingRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(bindingRq !== undefined ? bindingRq : {})
        : bindingRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardDataForTokenization(options: any = {}): RequestArgs {
      const localVarPath = `/ewallet-profile/api/v1/getCardDataForTokenization`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {WalletRq} [walletRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet(walletRq?: WalletRq, options: any = {}): RequestArgs {
      const localVarPath = `/ewallet-profile/api/v1/getWallet`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof walletRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(walletRq !== undefined ? walletRq : {})
        : walletRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateBindingRq} updateBindingRq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBinding(updateBindingRq: UpdateBindingRq, options: any = {}): RequestArgs {
      // verify required parameter 'updateBindingRq' is not null or undefined
      if (updateBindingRq === null || updateBindingRq === undefined) {
        throw new RequiredError(
          'updateBindingRq',
          'Required parameter updateBindingRq was null or undefined when calling updateBinding.'
        )
      }
      const localVarPath = `/ewallet-profile/api/v1/updateBinding`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof updateBindingRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateBindingRq !== undefined ? updateBindingRq : {})
        : updateBindingRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ControllerApi - functional programming interface
 * @export
 */
export const ControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    auth(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).auth(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {DeleteBindingRq} deleteBindingRq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBinding(
      deleteBindingRq: DeleteBindingRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteBindingRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).deleteBinding(deleteBindingRq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ewalletSync(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletSyncRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).ewalletSync(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {BindingRq} [bindingRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindings1(
      bindingRq?: BindingRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BindingRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getBindings1(bindingRq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardDataForTokenization(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenizationCardDataRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getCardDataForTokenization(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {WalletRq} [walletRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet(
      walletRq?: WalletRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getWallet(walletRq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {UpdateBindingRq} updateBindingRq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBinding(
      updateBindingRq: UpdateBindingRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBindingRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).updateBinding(updateBindingRq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ControllerApi - factory interface
 * @export
 */
export const ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    auth(options?: any): AxiosPromise<AuthRs> {
      return ControllerApiFp(configuration).auth(options)(axios, basePath)
    },
    /**
     *
     * @param {DeleteBindingRq} deleteBindingRq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBinding(deleteBindingRq: DeleteBindingRq, options?: any): AxiosPromise<DeleteBindingRs> {
      return ControllerApiFp(configuration).deleteBinding(deleteBindingRq, options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ewalletSync(options?: any): AxiosPromise<WalletSyncRs> {
      return ControllerApiFp(configuration).ewalletSync(options)(axios, basePath)
    },
    /**
     *
     * @param {BindingRq} [bindingRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindings1(bindingRq?: BindingRq, options?: any): AxiosPromise<BindingRs> {
      return ControllerApiFp(configuration).getBindings1(bindingRq, options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardDataForTokenization(options?: any): AxiosPromise<TokenizationCardDataRs> {
      return ControllerApiFp(configuration).getCardDataForTokenization(options)(axios, basePath)
    },
    /**
     *
     * @param {WalletRq} [walletRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet(walletRq?: WalletRq, options?: any): AxiosPromise<WalletRs> {
      return ControllerApiFp(configuration).getWallet(walletRq, options)(axios, basePath)
    },
    /**
     *
     * @param {UpdateBindingRq} updateBindingRq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBinding(updateBindingRq: UpdateBindingRq, options?: any): AxiosPromise<UpdateBindingRs> {
      return ControllerApiFp(configuration).updateBinding(updateBindingRq, options)(axios, basePath)
    },
  }
}

/**
 * ControllerApi - object-oriented interface
 * @export
 * @class ControllerApi
 * @extends {BaseAPI}
 */
export class ControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public auth(options?: any) {
    return ControllerApiFp(this.configuration).auth(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {DeleteBindingRq} deleteBindingRq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public deleteBinding(deleteBindingRq: DeleteBindingRq, options?: any) {
    return ControllerApiFp(this.configuration).deleteBinding(deleteBindingRq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public ewalletSync(options?: any) {
    return ControllerApiFp(this.configuration).ewalletSync(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {BindingRq} [bindingRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getBindings1(bindingRq?: BindingRq, options?: any) {
    return ControllerApiFp(this.configuration).getBindings1(bindingRq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getCardDataForTokenization(options?: any) {
    return ControllerApiFp(this.configuration).getCardDataForTokenization(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {WalletRq} [walletRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getWallet(walletRq?: WalletRq, options?: any) {
    return ControllerApiFp(this.configuration).getWallet(walletRq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {UpdateBindingRq} updateBindingRq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public updateBinding(updateBindingRq: UpdateBindingRq, options?: any) {
    return ControllerApiFp(this.configuration).updateBinding(updateBindingRq, options)(this.axios, this.basePath)
  }
}

/**
 * ControllerV2Api - axios parameter creator
 * @export
 */
export const ControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {BindingRq} [bindingRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindings(bindingRq?: BindingRq, options: any = {}): RequestArgs {
      const localVarPath = `/ewallet-profile/api/v2/getBindings`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof bindingRq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(bindingRq !== undefined ? bindingRq : {})
        : bindingRq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ControllerV2Api - functional programming interface
 * @export
 */
export const ControllerV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {BindingRq} [bindingRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindings(
      bindingRq?: BindingRq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BindingRs> {
      const localVarAxiosArgs = ControllerV2ApiAxiosParamCreator(configuration).getBindings(bindingRq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ControllerV2Api - factory interface
 * @export
 */
export const ControllerV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {BindingRq} [bindingRq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBindings(bindingRq?: BindingRq, options?: any): AxiosPromise<BindingRs> {
      return ControllerV2ApiFp(configuration).getBindings(bindingRq, options)(axios, basePath)
    },
  }
}

/**
 * ControllerV2Api - object-oriented interface
 * @export
 * @class ControllerV2Api
 * @extends {BaseAPI}
 */
export class ControllerV2Api extends BaseAPI {
  /**
   *
   * @param {BindingRq} [bindingRq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerV2Api
   */
  public getBindings(bindingRq?: BindingRq, options?: any) {
    return ControllerV2ApiFp(this.configuration).getBindings(bindingRq, options)(this.axios, this.basePath)
  }
}
