import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SpaConfig } from '@generated/api'

const initialState: SpaConfig = {}

const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig(state: SpaConfig, action: PayloadAction<SpaConfig>) {
      return { ...state, ...action.payload }
    },
  },
})

export const { setConfig } = config.actions
export default config.reducer
