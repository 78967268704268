import { actionGroups, eventActions, eventLabels, events, sendGmtEventPerPage } from '../baseFunctions'
import { translit } from '../translit'
import { EventCategoryByType, ModalTypes } from './types'

/** Показ ошибки на экране */
export const showModalErrorGtm = (code: number, title: string, text: string) =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventAction: eventActions.errorShow,
    eventLabel: translit(title).toLowerCase(),
    actionGroup: actionGroups.interactions,
    eventContext: code.toString(),
    eventContent: translit(text).toLowerCase(),
  })

/** Клик по кнопке Отделения на карте */
export const departmentOnMapClickGtm = (type: ModalTypes) =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventCategory: EventCategoryByType[type],
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.otdeleniyaNaKarte,
    actionGroup: actionGroups.interactions,
  })

/** Клик по элементу На главную на экране с ошибкой */
export const goToMainClickGtm = (type: ModalTypes) =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventCategory: EventCategoryByType[type],
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.naGlavnuu,
    actionGroup: actionGroups.interactions,
  })

/** Закрыть модальное окно */
export const closeModalGtm = (type: ModalTypes) =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventCategory: EventCategoryByType[type],
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.zakryt,
    actionGroup: actionGroups.interactions,
  })

/** Клик по кнопке Перевести по карте на экране ошибки */
export const transferByCardClickGtm = () =>
  sendGmtEventPerPage({
    event: events.mtsEvent,
    eventCategory: EventCategoryByType[ModalTypes.ERROR],
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.perevestiPoKarte,
    actionGroup: actionGroups.interactions,
  })
