import React, { FC, useCallback } from 'react'
import { Button, theme, useToast, Spacer } from '@mtsbank/ui-kit'

interface ToastButtonProps {
  toastId: string
  handler: () => void
}

export const ToastButton: FC<ToastButtonProps> = ({ toastId, handler }) => {
  const { removeToast } = useToast()

  const handleClick = useCallback(() => {
    removeToast(toastId)
    handler()
  }, [removeToast, toastId, handler])

  return (
    <>
      <Spacer space={theme.spacings.sm} />
      <Button fluidmob variant="secondary" size="md" onClick={handleClick}>
        Повторить
      </Button>
    </>
  )
}
