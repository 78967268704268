export class Timer {
  private timerId: number

  private startTime: Date

  private remaining: number

  private callback: () => void

  constructor(delay: number) {
    this.remaining = delay
  }

  start = (callback: () => void) => {
    this.callback = callback
    this.timerId = window.setTimeout(callback, this.remaining)
  }

  pause = () => {
    window.clearTimeout(this.timerId)
    this.remaining = this.remaining - +new Date() - +this.startTime
  }

  resume = () => {
    this.startTime = new Date()
    window.clearTimeout(this.timerId)
    this.timerId = window.setTimeout(this.callback, this.remaining)
  }

  clearTimeout = () => {
    window.clearTimeout(this.timerId)
  }
}
