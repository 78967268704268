import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { theme, useDidMount } from '@mtsbank/ui-kit'

import { classNames, StyledAccordion } from '@components/Styled/StyledAccordion'
import { Skeleton } from '@components/NewMain/Skeleton'
import { ResendComponent } from '@components/NewMain/ResendComponent'
import { CardItemList } from '@components/NewMain/CardItemList'
import { selectAccounts, selectBankAccounts } from '@selectors/accounts'
import { BalanceState, ItemType, ProductItem } from '@components/NewMain/types'
import { fetchShortAccountList } from '@reducers/accounts/accounts'
import { iconCurrencyMapping } from '@components/NewMain/utils'
import {
  accountsErrorGtm,
  accountsHideClickGtm,
  accountsReloadClickGtm,
  newMainWigetNames,
  showWidgetGtm,
} from '@root/utils/gtm/newMainPageEvents'
import { config } from '@root/configs/newMain'
import { authHelper } from '@root/utils/authHelper/AuthHelper'
import { accountsWidgetClickGtm, seeingAccountsWidgetGtm, showAccountsWidgetGtm } from '@root/utils/gtm/mainPage/events'
import { useBlockInVisionOnce } from '@root/hooks/useBlockInVision'

export const Accounts: FC<BalanceState> = ({ isHiddenBalance }) => {
  const dispatch = useDispatch()
  const { inProgress, error, isResent, data } = useSelector(selectAccounts)
  const accounts = useSelector(selectBankAccounts)
  const [opened, setOpened] = useState(true)
  const { isFullIdentified } = authHelper
  const productClearanceButton = isFullIdentified && config?.account?.add

  useDidMount(() => {
    if (isEmpty(error) && isEmpty(data)) {
      dispatch(fetchShortAccountList({ isResent: false }))
      showWidgetGtm(newMainWigetNames.scheta)
    }
  })

  const handleResend = useCallback(() => {
    accountsReloadClickGtm()
    dispatch(fetchShortAccountList({ isResent: true }))
  }, [dispatch])

  const handleToggle = () => {
    accountsHideClickGtm(opened)

    setOpened(!opened)
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const { isVisible } = useBlockInVisionOnce(ref)

  useEffect(() => {
    if (isVisible && !inProgress) {
      seeingAccountsWidgetGtm()
    }
  }, [inProgress, isVisible])

  useEffect(() => {
    if (accounts.length > 0 || (Boolean(productClearanceButton) && !error && !inProgress)) {
      showAccountsWidgetGtm()
    }
  }, [accounts.length, error, inProgress, productClearanceButton])

  if (inProgress) {
    return <Skeleton withCir marginBottom={theme.spacings.xs2} />
  }

  const accountItems = accounts.map(
    (account): ProductItem => ({
      id: account.id,
      title: account.name,
      amount: !isHiddenBalance && account.balanceInfo?.amount,
      currency: account.balanceInfo?.currency,
      icon: iconCurrencyMapping[account.balanceInfo?.currency],
      urlDetails: `/accounts/${account.number}`,
    })
  )

  if (error) {
    accountsErrorGtm()
  }

  const AccordionContent = error ? (
    <ResendComponent inProgress={isResent} onClick={handleResend} />
  ) : (
    (accountItems.length > 0 || Boolean(productClearanceButton)) && (
      <CardItemList
        productItems={accountItems}
        itemType={ItemType.Accounts}
        productClearanceButton={productClearanceButton}
      />
    )
  )

  return AccordionContent ? (
    <StyledAccordion
      contentRef={ref}
      onWrapperClick={accountsWidgetClickGtm}
      className={classNames.DISABLE_DIVIDER}
      items={[{ title: 'Счета', text: AccordionContent, opened }]}
      onToggle={handleToggle}
    />
  ) : null
}
