import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ewalletApi } from '@api/ump/ewallet'
import { BToCardTransferTermsRq, CardToCardTransferTermsRq, TransferTermsRs } from '@open-api/ump/ewallet'
import { isAxiosError } from '@root/types/reducers'
import { TransferTermsState } from './types'
import { Options, ZoneRequest } from '../types'

const NAMESPACE = 'transferTerms'

const initialState: TransferTermsState = {
  data: null,
  inProgress: false,
  error: null,
}

const fetchBToCardTransferTerms = createAsyncThunk<TransferTermsRs, BToCardTransferTermsRq>(
  `${NAMESPACE}/fetchBToCardTransferTerms`,
  async (params, { rejectWithValue }) => {
    try {
      const { srcBindingId, targetPan, amount, currency, comment, dstPhone, masterpassOperationId } = params

      const response = await ewalletApi.getBToCardTransferTermsDo(
        srcBindingId,
        targetPan,
        amount,
        currency,
        comment,
        dstPhone,
        masterpassOperationId
      )

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const fetchCardToCardTransferTerms = createAsyncThunk<
  TransferTermsRs,
  CardToCardTransferTermsRq & ZoneRequest & Options
>(`${NAMESPACE}/fetchCardToCardTransferTerms`, async (params, { rejectWithValue }) => {
  try {
    const {
      zone,
      userId,
      pan,
      expiry,
      cardholderName,
      targetPan,
      amount,
      currency,
      comment,
      srcPhone,
      dstPhone,
      masterpassOperationId,
      tokenizedTargetPan,
      options,
    } = params

    const response = await ewalletApi.getCardToCardTransferTerms(
      zone,
      userId,
      pan,
      expiry,
      cardholderName,
      targetPan,
      amount,
      currency,
      comment,
      srcPhone,
      dstPhone,
      masterpassOperationId,
      tokenizedTargetPan,
      options
    )

    return response.data
  } catch (error) {
    if (isAxiosError(error)) {
      return rejectWithValue(error.response.data)
    }

    return error
  }
})

const transferTerms = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBToCardTransferTerms.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchBToCardTransferTerms.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(fetchBToCardTransferTerms.fulfilled, (state, action) => {
      state.data = action.payload
      state.inProgress = false
    })

    builder.addCase(fetchCardToCardTransferTerms.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchCardToCardTransferTerms.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(fetchCardToCardTransferTerms.fulfilled, (state, action) => {
      state.data = action.payload
      state.inProgress = false
    })
  },
})

export { fetchBToCardTransferTerms, fetchCardToCardTransferTerms }
export const transferTermsReducer = transferTerms.reducer
