import formatISO from 'date-fns/formatISO'

export const dateFromString = (date: string) => {
  try {
    const dateParts = date.split('.')

    if (dateParts.length > 2) {
      const [day, month, year] = dateParts.map(Number)

      return new Date(year, month - 1, day)
    }

    return new Date(date)
  } catch (e) {
    return new Date()
  }
}

export const dateWithoutTime = (date: string | Date): number => new Date(date).setHours(0, 0, 0, 0)

export const formatISOWithoutColon = (date: Date) => {
  const formattedDate = formatISO(date, { representation: 'date' })

  return formattedDate
}
