import maxBy from 'lodash/fp/maxBy'
import isNaN from 'lodash/isNaN'
import { Binding } from '@open-api/ump/ewallet-profile'
import { toNumber } from '@utils/format'
import { PENNY } from '@components/SBP/FormikHandler'
import { Validator, Validators } from '@utils/formValidators/types'

export const getInitialBinding = (bindings: Binding[]) => maxBy((binding) => binding.balance, bindings)?.bindingId

export const getCurrentBalance = (value: string, bindings: Binding[]): number => {
  const selectedBinding = bindings.find(({ bindingId }) => bindingId === value)

  return isNaN(Number(selectedBinding?.balance)) ? undefined : Number(selectedBinding?.balance)
}

export const toPenny = (value: number | string) => (value ? Number((toNumber(value) * PENNY).toFixed(2)) : 0)

export const updateAmountValidator = (initialValidators: Validators, updatedAmountValues: Validator) =>
  initialValidators.map((validator: string | Validator) => {
    if (typeof validator === 'object') {
      const { amountValues } = validator

      if (typeof amountValues === 'object') {
        return {
          amountValues: {
            ...amountValues,
            max: updatedAmountValues.max,
            balance: updatedAmountValues.balance,
          },
        }
      }
    }

    return validator
  })
