import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ewalletApi } from '@api/ump/ewallet'
import { isAxiosError } from '@root/types/reducers'
import { ConfirmTransferByOtpRq, ReSendOtpRq, ReSendOtpRs, TransferResponse } from '@open-api/ump/ewallet'
import { OtpPcekState, ZoneRequest } from './types'

const NAMESPACE = 'OTP'

const initialState: OtpPcekState = {
  data: {},
  inProgress: false,
  error: null,
}

const confirmTransferByOtp = createAsyncThunk<TransferResponse, ConfirmTransferByOtpRq & ZoneRequest>(
  `${NAMESPACE}/confirmTransferByOtp`,
  async ({ zone, options, ...params }, { rejectWithValue }) => {
    try {
      const response = await ewalletApi.confirmTransferByOtp(
        zone,
        params,
        zone === 'anonymous'
          ? {
              headers: { 'client-Id': 'mts-money-web-mtsid' },
            }
          : options
      )

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const reSendOtp = createAsyncThunk<ReSendOtpRs, ReSendOtpRq & ZoneRequest>(
  `${NAMESPACE}/reSendOtp`,
  async ({ zone, options, ...params }, { rejectWithValue }) => {
    try {
      const response = await ewalletApi.reSendOtp(
        zone,
        params,
        zone === 'anonymous'
          ? {
              headers: { 'client-Id': 'mts-money-web-mtsid' },
            }
          : options
      )

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const otpPcek = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(confirmTransferByOtp.pending, (state) => {
      state.data = {}
      state.inProgress = true
      state.error = null
    })
    builder.addCase(confirmTransferByOtp.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(confirmTransferByOtp.fulfilled, (state, action) => {
      state.data = action.payload
      state.inProgress = false
    })

    builder.addCase(reSendOtp.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(reSendOtp.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(reSendOtp.fulfilled, (state) => {
      state.inProgress = false
    })
  },
})

export { confirmTransferByOtp, reSendOtp }
export const otpPcekReducer = otpPcek.reducer
