import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoanIssueDocListRes, OpenDocReq } from '@open-api/ump/loan-issue'
import { loanIssueApi } from '@api/ump/loan-issue'
import { DocListState } from '@reducers/docs/types'

const initialState: DocListState = {
  isLoading: false,
  docList: null,
  error: null,
}

interface Props {
  rboId: number
}

const fetchDocList = createAsyncThunk('docs/fetchDocList', async ({ rboId }: Props): Promise<LoanIssueDocListRes> => {
  const response = await loanIssueApi.loanIssueDocList('v1', rboId.toString())

  return response.data
})

interface OpenDocReqProps extends OpenDocReq {
  docName: string
}

const fetchOpenDoc = createAsyncThunk('docs/openDocs', async (openDocReq: OpenDocReqProps): Promise<void> => {
  const response = await loanIssueApi.openDoc('v1', openDocReq)
  const { docData } = response.data
  const link = document.createElement('a')

  link.href = `data:application/octet-stream;base64,${docData}`
  link.download = openDocReq.docName
  link.click()
})

const docList = createSlice({
  name: 'docList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocList.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(fetchDocList.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(fetchDocList.fulfilled, (state, action) => {
      const newDocList = action.payload.docList.map((doc) => ({ error: null, isLoading: false, ...doc }))

      state.isLoading = false
      state.error = null

      state.docList = newDocList
    })
    builder.addCase(fetchOpenDoc.pending, (state, action) => {
      const index = state.docList.findIndex(({ docId }) => docId === action.meta.arg.docId)

      state.docList[index].isLoading = true
      state.docList[index].error = null
    })
    builder.addCase(fetchOpenDoc.rejected, (state, action) => {
      const index = state.docList.findIndex(({ docId }) => docId === action.meta.arg.docId)

      state.docList[index].isLoading = false
      state.docList[index].error = action.error
    })
    builder.addCase(fetchOpenDoc.fulfilled, (state, action) => {
      const index = state.docList.findIndex(({ docId }) => docId === action.meta.arg.docId)

      state.docList[index].isLoading = false
      state.docList[index].error = null
    })
  },
})

export { fetchDocList, fetchOpenDoc }
export default docList.reducer
