import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MdOrderToRefNumberQuery, RefNumberState } from '@reducers/mobilePayment/refNumber/type'

const NAMESPACE = 'mdOrderToRefNum'
const initialState: RefNumberState = {
  data: {},
}

const mdOrderToRefNumber = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    clearOrderToRefNum(state) {
      state.data = {}
    },
    setRefNumber(state, action: PayloadAction<MdOrderToRefNumberQuery>) {
      state.data[action.payload.mdOrder] = action.payload.refNum
    },
  },
})

export { mdOrderToRefNumber }
export const { setRefNumber, clearOrderToRefNum } = mdOrderToRefNumber.actions
export const mdOrderToRefNumberReducer = mdOrderToRefNumber.reducer
