import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ewalletApi } from '@api/ump/ewallet'
import { OperationDetailsRs, OperationDetailsRq } from '@open-api/ump/ewallet'
import { isAxiosError } from '@root/types/reducers'
import { OperationDetailsState } from './types'
import { Options, ZoneRequest } from '../types'

const NAMESPACE = 'operationDetails'

const initialState: OperationDetailsState = {
  data: null,
  inProgress: false,
  error: null,
}

const fetchOperationDetails = createAsyncThunk<OperationDetailsRs, OperationDetailsRq & ZoneRequest & Options>(
  `${NAMESPACE}/fetchOperationDetails`,
  async ({ zone, ...params }, { rejectWithValue }) => {
    try {
      const { userId, mdOrder, options } = params

      const response = await ewalletApi.getOperationDetailsDo(zone, userId, mdOrder, options)

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data)
      }

      return error
    }
  }
)

const operationDetails = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOperationDetails.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchOperationDetails.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(fetchOperationDetails.fulfilled, (state, action) => {
      state.data = action.payload
      state.inProgress = false
    })
  },
})

export { fetchOperationDetails }
export const operationDetailsReducer = operationDetails.reducer
