import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { OfferDto, OfferDtoStateEnum } from '@open-api/ump/pos-offer'
import { posOfferApi } from '@root/api/ump/pos-offer'
import { IS_REPEAT_CLIENT, NAMESPACE, PRODUCT_NAME, initialState } from '@root/reducers/posOffer/constants'

const fetchPosOfferInfo = createAsyncThunk(`${NAMESPACE}/fetchPosOfferInfo`, async (): Promise<OfferDto[]> => {
  const response = await posOfferApi.fetch(PRODUCT_NAME, [OfferDtoStateEnum.APPROVE], IS_REPEAT_CLIENT)

  return response.data
})

const posOffer = createSlice({
  name: 'posOffer',
  initialState,
  reducers: {
    addMaxAmount(state, action) {
      state.maxSumAmount = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPosOfferInfo.pending, (state) => {
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchPosOfferInfo.rejected, (state, action) => {
      state.inProgress = false
      state.error = action.error
    })
    builder.addCase(fetchPosOfferInfo.fulfilled, (state, action) => {
      state.inProgress = false
      state.data = action.payload
    })
  },
})

export { fetchPosOfferInfo }
export const { addMaxAmount } = posOffer.actions
export const posOfferReducer = posOffer.reducer
