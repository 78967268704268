import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ncpkPsGatewayApi } from '@api/ump/ncpk-ps-gateway'
import { baseInitialState } from '@root/fetches/baseInitialState'
import { InitialLoanListState } from '@reducers/credits/type'

const NAMESPACE = 'loanList'

const initialState: InitialLoanListState = {
  ...baseInitialState,
}

export const fetchLoanList = createAsyncThunk(`${NAMESPACE}/fetchGetLoans`, async () => {
  const { data: loansData } = await ncpkPsGatewayApi.getLoans()

  return { ...loansData }
})

const loanList = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoanList.pending, (state) => {
      state.data = {}
      state.inProgress = true
      state.error = null
    })
    builder.addCase(fetchLoanList.rejected, (state, action) => {
      state.error = action.error
      state.inProgress = false
      state.data = {}
    })
    builder.addCase(fetchLoanList.fulfilled, (state, action) => {
      state.inProgress = false
      state.error = null
      state.data = { ...action.payload }
    })
  },
})

export const loanListReducer = loanList.reducer
