import { createSelector } from 'reselect'
import { RootState } from '@root/reducers'
import { BankSuggestion } from '@components/SBP/types'

export const selectSbpState = (state: RootState) => state.sbp
export const selectTransferByPhone = createSelector(selectSbpState, (state) => state.transferByPhone)
export const selectReferenceBook = createSelector(selectSbpState, (state) => state.referenceBook)

export const selectTransferListState = createSelector(selectTransferByPhone, (state) => state.transferList)
export const selectTransferList = createSelector(selectTransferByPhone, (state) => state.transferList.data || {})
export const selectBankList = createSelector(selectTransferList, (state) => state?.sbpBanksList || [])
export const selectBankListSuggestions = createSelector(selectBankList, (state): BankSuggestion[] =>
  state.map((bankInfo) => ({ label: bankInfo.bankName, value: bankInfo.bankId }))
)

export const selectConfirmTransfer = createSelector(selectTransferByPhone, (state) => state.confirmTransfer)
export const selectInitiateTransfer = createSelector(selectTransferByPhone, (state) => state.initiateTransfer)
export const selectTransferStatus = createSelector(selectTransferByPhone, (state) => state.transferStatus)

export const selectCheckTransferState = createSelector(selectTransferByPhone, (state) => state.checkTransfer)
export const selectTransfer = createSelector(selectCheckTransferState, (state) => state.data?.transfer || {})

export const selectIsTransferTypeSBP = createSelector(selectTransferByPhone, (state) => state.isTransferTypeSBP)

export const selectTransferByPhoneConstraints = createSelector(
  selectReferenceBook,
  (state) => state.transferByPhoneConstraints
)
