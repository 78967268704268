import { MutableRefObject, useEffect, useRef } from 'react'
import { useDidMount } from '@mtsbank/ui-kit'
import { useIntersectionObserver } from './useIntersectionObserver'

export const useShowViewElement = <T extends HTMLElement>(
  onShow?: () => void,
  onView?: () => void,
  isOnceVisible?: false,
  threshold = 0.4
): MutableRefObject<T | null> => {
  const ref = useRef<T | null>(null)
  const block = useIntersectionObserver(ref, { freezeOnceVisible: isOnceVisible, threshold })
  const isVisible = Boolean(block?.isIntersecting)

  useDidMount(() => {
    onShow?.()
  })

  useEffect(() => {
    if (isVisible) {
      onView?.()
    }
  }, [isVisible, onView])

  return ref
}
